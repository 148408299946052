import moment from '../../../../utils/moment';
import React, { useMemo } from 'react';
import { compareDates, inRange } from '../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';

interface Props {
  weekNumberMode?: boolean,
  isWeekNumber?: boolean,
  date?: any,
  dateRange?: any,
  start?: any,
  end?: any,
  presetDateRange?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.range': {
      backgroundColor: '#fffaca',
    },
    '&.start': {
      backgroundColor: '#fffaca',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '&.end': {
      backgroundColor: '#fffaca',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    '&.single': {
      backgroundColor: '#fffaca',
    },
    '&.weekNumber': {
      backgroundColor: '#ececec',
      borderRadius: 'none',
      '& span': {
        color: '#bcbcbc',
      }
    }
  },
  disabled: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    cursor: 'not-allowed',
    justifyContent: 'center',
    alignItems: 'center',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.weekNumber': {
      borderRadius: '0%',
      '& span': {
        backgroundColor: '#ececec',
        color: theme.colors.black,
        fontWeight: 'normal',
      }
    }
  },
  date: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: (props: Props) => !props.isWeekNumber ?  '12px' : '11px',
    backgroundColor: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? '#F6F6F6' : theme.colors.white, 
    color: (props: Props) => ((props.start && moment(props.date).isSameOrAfter(moment(),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day')) || (props.end && moment(props.date).isSameOrAfter(moment(props.dateRange.start),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day'))) ? theme.colors.black: theme.colors.grey[570],
    cursor: (props: Props) => props.isWeekNumber ? 'auto' : ((props.start && moment(props.date).isSameOrAfter(moment(),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day')) || (props.end && moment(props.date).isSameOrAfter(moment(props.dateRange.start),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day'))) ? "pointer": "not-allowed",
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '100%',
    },
    '&:hover::before': {
      border:  (props: Props) => ((props.start && moment(props.date).isSameOrAfter(moment(),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day')) || (props.end && moment(props.date).isSameOrAfter(moment(props.dateRange.start),'day') && moment(props.date).isSameOrBefore(moment().add(180,'days'),'day'))) ? '1px solid ' + theme.colors.primaryBlue[500]: "",
    },
    '&.today::before': {
      backgroundColor: '#E5F4FF',
    },
    '&.today': {
      color: theme.colors.black,
      fontWeight: 'bold',
    },
    '&.selected::before': {
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '&.selected': {
      color: theme.colors.white,
      fontWeight: 'bold',
    },
    '& p': {
      position: 'relative',
      zIndex: '2',
      marginBottom: '0',
      color: 'inherit',
    },
  },
}));

type DateItemType = {
  index?: any;
  weekNumberMode?: any;
  date?: any;
  setDate?: any;
  currentSelectedDate?: any;
  presetDateRange?: any;
  dateRange?: any;
  start?: any;
  end?: any;
  handleClose: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, weekNumberMode, date, setDate, currentSelectedDate, presetDateRange, dateRange, start=false, end=false, handleClose}) => {
  const isWeekNumber = useMemo(() => !weekNumberMode ? false : index % 8 === 0, [index, weekNumberMode]);
  const isInDateRange = useMemo(() => inRange(date, dateRange), [date, dateRange]);
  const classes = useStyles({weekNumberMode, isWeekNumber, start, date, presetDateRange, dateRange, end});

  const handleClick = () => {
    if(start) {
      if(moment(date).isSameOrAfter(moment(),'day') && moment(date).isSameOrBefore(moment().add(180,'days'),'day')) {
        if(!isWeekNumber){
          setDate(date);
          handleClose();
        }
      }
    } else {
      if(moment(date).isSameOrAfter(moment(dateRange.start),'day') && moment(date).isSameOrBefore(moment().add(180,'days'),'day')) {
        if(!isWeekNumber){
          setDate(date);
          handleClose();
        }
      }      
    }
  };

  return (
    <div className={`${classes.root} ${(weekNumberMode && isWeekNumber) ? 'weekNumber' : null} ${(moment(currentSelectedDate).isSame(moment(date),'day')) ? 'selected' : null} ${((isInDateRange === 'SINGLE' || isInDateRange === 'ERR') && compareDates(date, currentSelectedDate)) ? 'single' : null} ${isInDateRange === 'IN' ? 'range' : null} ${((isInDateRange === 'IN' || isInDateRange === 'START' || isInDateRange === 'END') && date.day() === 0) ? 'start' : null} ${((isInDateRange === 'IN' || isInDateRange === 'END' || isInDateRange === 'START') && date.day() === 6) ? 'end' : null} ${isInDateRange === 'START' ? 'start' : null} ${isInDateRange === 'END' ? 'end' : null}`} onClick={handleClick} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      <span className={`${classes.date} ${(weekNumberMode && isWeekNumber) ? 'weekNumber' : null} ${((compareDates(moment(), date)) && !isWeekNumber) ? 'today' : null} ${((moment(currentSelectedDate).isSame(moment(date),'day')) && !isWeekNumber) ? 'selected' : null}`}>
        <p data-clarity-unmask="true">{(weekNumberMode && isWeekNumber) ? date.week() : date.date()}</p>
      </span>
    </div>
  );
};

export default DateItem;