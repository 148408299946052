import AuthenticatedImage from '../../../components/Items/AuthenticatedImage';
import Input from 'src/components/Forms/Input';
import React, { useMemo, useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setPostCreateModal } from '../../../store/actions/modals.actions';
import { Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  timelineCardHeader: {
    overflow: "hidden",
    display: "flex",
    padding: '8px 16px',
    width: 'calc(100% - 32px)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  inputContainer: {
    width: '100%',
  },
  inputWrapper: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '0.25rem',
    },
  },
  profileImage: {
    minWidth: "58px",
    minHeight: "58px",
    width: "58px",
    height: "58px",
    maxWidth: "58px",
    maxHeight: "58px",
    borderRadius: "12px",
    backgroundColor: theme.colors.white,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minWidth: "35px",
      minHeight: "35px",
      width: "35px",
      height: "35px",
      maxWidth: "35px",
      maxHeight: "35px",
    },
  },
  input: {
    display: 'block',
    width: '100%',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '1.5',
    outline: 'none',
    padding: '0.175rem 0.75rem',
    color: '#495057',
    backgroundColor: theme.colors.white,
    backgroundClip: "padding-box",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.grey[325],
    borderRadius: "10px",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    height: "2rem",
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0.175rem 0.5rem',
      fontSize: '14px',
      height: '1.75rem',
    },
    '&:active': {
      borderColor: theme.colors.primaryBlue[500],
    },
    '&:focus': {
      borderColor: theme.colors.primaryBlue[500],
    },
  },
  postPollWrapper: {
    display: 'flex',
    marginTop: '10px',
  },
  postPollItems: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    flex: '0 1 100%',
    width: '100%',
  },
  postPoll: {
    width: 'auto',
    whiteSpace: 'nowrap',
    padding: '2px 8px',
    borderRadius: '20px',
    backgroundColor: theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.5s',
    cursor: "pointer",
    fontWeight: 'normal',
    fontSize: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    '& svg': {
      width: '16px',
      height: '16px',
      marginRight: '5px',
    },
    '&.active': {
      backgroundColor: theme.colors.grey[250],
      color: theme.colors.primaryBlue[500],
      borderColor: theme.colors.grey[250],
    },
    '&.inactive' : {
      backgroundColor: theme.colors.chip,
      color: theme.colors.grey[800],
      borderColor: theme.colors.grey[350],
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      '& svg': {
        width: '14px',
        height: '14px',
        marginRight: '3px',
      },
    },
  },
  counter: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.colors.grey[550],
    fontSize: '12px',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
    paddingTop: '4px',
    paddingRight: '2px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2px',
    },
  },
}));

const PostForm: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  
  const [state, setState] = useStates({
    placeholder: "post",
    title: "",
    titleCount: 0,
  });

  const activeClasses = useMemo(() => dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active === true).length, [dataData.classes]);
  const inputWrapperRef: any = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handlePostPoll = (value: any) => {
    setState("title", value);
    setState("titleCount", value.length ? value.length : 0);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(state.title.length > 0) {
        const postType = state.placeholder === "poll" ? 2 : (state.placeholder === "post_video" ? 3 : 1);
        const settings = {
          isOpen: true,
          postType: postType,
          title: state.title,
        };
        dispatch(setPostCreateModal(settings));
        inputWrapperRef.current.querySelector("input").value = "";
        setState("titleCount", 0);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [state.title, dispatch, setState, state.placeholder], [state.title]);

  const setPlaceholder = (value: any) => {
    setState("placeholder", value);
  };
  
  return activeClasses > 0 ? (
    <div className={classes.timelineCardHeader} data-cy={isCypress() ? `timelineCardHeader` : null}> 
      {
        layoutData.isDashboardEditable ? (
          <div className={classes.inputContainer}>
            <div className={classes.inputWrapper} ref={inputWrapperRef}>
              <Skeleton variant="rectangular" animation="wave" width={58} height={58}/>
              <Skeleton variant="rectangular" animation="wave" width={'100%'} height={38}/>
            </div>
            <div className={classes.postPollWrapper}>
              <div className={classes.postPollItems}>
                <Skeleton variant="rectangular" animation="wave" width={105} height={27}/>
                <Skeleton variant="rectangular" animation="wave" width={105} height={27}/>
                <Skeleton variant="rectangular" animation="wave" width={105} height={27}/>
              </div>
              <Skeleton className={classes.counter} variant="text" animation="wave" width={37} height={27}/>
            </div>
          </div>
        ) : (
          <div className={classes.inputContainer}>
            <div className={classes.inputWrapper} ref={inputWrapperRef}>
              <AuthenticatedImage className={classes.profileImage} thumbLink={typeof userData.userObject.photo === "string" ? userData.userObject.photo : userData.userObject.photo.thumbLink} fullsizeLink={userData.userObject.photo.fullsizeLink} isClickable={true} dataCy='userPhoto'/>
              <Input useName={false} onKeyDown={handlePostPoll} onKeyUp={handlePostPoll} maxLength={255} placeholder={`${t(`please_start_${state.placeholder}`)}...`} dataCy='timelineCardHeaderInput'/>
            </div>
            <div className={classes.postPollWrapper}>
              <div className={classes.postPollItems}>
                <div className={`${state.placeholder === "post" ? 'active' : 'inactive'} ${classes.postPoll}`} onClick={() => setPlaceholder("post")} data-cy={isCypress() ? `timelineCardPostType` : null}>
                  <SVG src="post"/>{t('post')}
                </div>
                <div className={`${state.placeholder === "poll" ? 'active' : 'inactive'} ${classes.postPoll}`} onClick={() => setPlaceholder("poll")} data-cy={isCypress() ? `timelineCardPollType` : null}>
                  <SVG src="poll"/>{t('poll')}
                </div>
                <div className={`${state.placeholder === "post_video" ? 'active' : 'inactive'} ${classes.postPoll}`} onClick={() => setPlaceholder("post_video")} data-cy={isCypress() ? `timelineCardPostVideoType` : null}>
                  <SVG src="video"/>{t('post_video')}
                </div>
              </div>
              <span className={classes.counter} data-cy={isCypress() ? `timelineCardHeaderInputCounter` : null} data-clarity-unmask="true">{state.titleCount}/255</span>
            </div>
          </div>
        )
      }
    </div>  
  ) : null;
};

const PostFormTitle: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return t('create_post');
};

export { PostForm, PostFormTitle };