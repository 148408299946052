import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import ClassesSelect from 'src/components/Selects/ClassesSelect';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import htmlParse from 'html-react-parser';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setTimetableChild, setTimetableClass, setTimetableData, setTimetableEmployee, setTimetableRoom, setTimetableType, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { setItemPickerModal } from 'src/store/actions/modals.actions';

interface Props {
  isEdit: any;
};

const useStyles = createUseStyles((theme: any) => ({
  timetableDetailTable: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    maxWidth: '100%',
    width: '100%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[300],
    cursor: (props: Props) => {
      if(props.isEdit) return '';
      else return '';
    },
    '& > span': {
      fontWeight: '500',
      whiteSpace: 'nowrap',
      '&:last-of-type': {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        color: '#0085FF',
        maxWidth: '100%',
        cursor: (props: Props) => {
          if(props.isEdit) return 'pointer';
          else return '';
        },
        '& > p': {
          display: 'block',
          fontWeight: '600',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '50vw',
        },
        '& > svg': {
          width: '20px',
          height: '20px',
        }
      },
    },
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: '8px',
    fontSize: '14px',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    padding: '0 10px',
    '& > span': {
      textAlign: 'center',
    },
    '& > p': {
      textAlign: 'center',
      fontWeight: '700',
    },
  },
}));

type TimetableDetailTableType = {
  mode: any;
};

const TimetableDetailTable: React.FunctionComponent<TimetableDetailTableType> = ({ mode }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const schoolID = timetableData.school.schoolID;
  const timetableService = useAppSelector((state: any) => state.services).timetableService;

  const getHourCount = (type: any) => {
    return timetableData.data.timetables.filter((item: any) => item.type === type).length;
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const [state, setState] = useStates({
    isClassesModalOpen: false,
    isEmployeesModalOpen: false,
    isChildrenModalOpen: false,
  });

  const list = {
    classes: schoolID === null ? [] : dataData.classes.filter((item: any) => item.schoolID === schoolID && item.childrenID.length > 0).map((item: any) => { return { classID: item.classID, schoolID: schoolID }; }),
    employees: schoolID === null ? [] : dataData.employees.filter((item: any) => item.schoolID === schoolID && item.enableForTimetable).map((item: any) => { return { employeeID: item.employeeID }; }),
    children: schoolID === null ? [] : dataData.children.filter((item: any) => item.schoolsID.indexOf(schoolID) !== -1).map((item: any) => { return { childID: item.childID, classID: item.classID }; }),
    breaks: timetableData.rooms.filter((item: any) => item.type === 2),
    rooms: timetableData.rooms.filter((item: any) => item.type === 1),
  };

  const dataSet: any = {
    class: {
      text: t('timetable_selected_class'),
      name: timetableData.class !== null ? getClassData(timetableData.class.classID).name : '',
      isEdit: true,
      items: [
        {
          id: 1,
          name: t('timetable_lesson'),
        },
      ],
    },
    employee: {
      text: t('timetable_selected_teacher'),
      name: timetableData.employee !== null ? (getEmployeeData(timetableData.employee.employeeID).firstname + ' ' + getEmployeeData(timetableData.employee.employeeID).surname) : '',
      isEdit: true,
      items: [
        {
          id: 1,
          name: t('timetable_lesson'),
        },
        {
          id: 2,
          name: t('timetable_teacher_hour'),
        },
        {
          id: 3,
          name: t('timetable_supervision'),
        },
      ],
    },
    child: {
      text: t('timetable_selected_child'),
      name: timetableData.child !== null ? getChildData(timetableData.child.childID).displayName : '',
      isEdit: true,
      items: [
        {
          id: 1,
          name: t('timetable_lesson'),
        },
      ],
    },
    break: {
      text: t('timetable_selected_break_room'),
      name: timetableData.room !== null ? timetableData.room.name : '',
      isEdit: true,
      items: [
        {
          id: 3,
          name: t('timetable_breaks'),
        },
      ],
    },
    room: {
      text: t('timetable_selected_room'),
      name: timetableData.room !== null ? timetableData.room.name : '',
      isEdit: true,
      items: [
        {
          id: 1,
          name: t('timetable_lesson'),
        },
        {
          id: 2,
          name: t('timetable_teacher_hour'),
        },
      ],
    },
  };

    
  const classes = useStyles({
    isEdit: dataSet[mode].isEdit,
  });

  const handleChangeClass = (e: any, data: any, value: any) => {
    setState("isClassesModalOpen", false);
    dispatch(setTimetableClass({ classID: value[0].classID }));
    dispatch(setTimetableType("class"));
    const params = {
      classID: value[0].classID,
      schoolID: schoolID,
      type: [1],
    };
    getHours(params);
  };

  const handleChangeEmployee = (e: any, data: any, value: any) => {
    setState("isEmployeesModalOpen", false);
    dispatch(setTimetableEmployee(value[0]));
    dispatch(setTimetableType("employee"));
    const params = {
      teacherID: value[0].employeeID,
      schoolID: schoolID,
      type: [1, 2, 3],
    };
    getHours(params);
  };

  const handleChangeChild = (e: any, data: any, value: any) => {
    setState("isChildrenModalOpen", false);
    dispatch(setTimetableChild(value[0]));
    dispatch(setTimetableType("child"));
    const params = {
      childID: value[0].childID,
      schoolID: schoolID,
      type: [1],
    };
    getHours(params);
  };

  const handleChangeBreak = (value: any) => {
    dispatch(setTimetableRoom(value));
    dispatch(setTimetableType("break"));
    const params = {
      roomID: value.roomID,
      schoolID: schoolID,
      type: [3],
    };
    getHours(params);
  };

  const handleChangeRoom = (value: any) => {
    dispatch(setTimetableRoom(value));
    dispatch(setTimetableType("room"));
    const params = {
      roomID: value.roomID,
      schoolID: schoolID,
      type: [1, 2],
    };
    getHours(params);
  };

  const getHours = useCallback((params: any) => {
    timetableService && timetableService.listHours(params).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableData(result.data));
          dispatch(setTimetableViewMode("timetableEdit"));
        } else {
          createNotification(t("timetable_failed_load"), "error");
        }
      } else {
        createNotification(t("timetable_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_failed_load"), "error");
    });
  }, [dispatch, t, timetableService]);

  const handleEditClick = () => {
    if(mode === "class") {
      setState("isClassesModalOpen", true);
    } else if(mode === "employee") {
      setState("isEmployeesModalOpen", true);
    } else if(mode === "child") {
      setState("isChildrenModalOpen", true);
    } else if(mode === "break") {
      const settings = {
        isOpen: true,
        items: list.breaks,
        value: timetableData.room,
        valueCompare: 'roomID',
        onChange: handleChangeBreak,
        closeOnChange: true,
        title: t('timetable_select_break_room'),
      };
      dispatch(setItemPickerModal(settings));
    } else if(mode === "room") {
      const settings = {
        isOpen: true,
        items: list.rooms,
        value: timetableData.room,
        valueCompare: 'roomID',
        onChange: handleChangeRoom,
        closeOnChange: true,
        title: t('timetable_select_room'),
      };
      dispatch(setItemPickerModal(settings));
    }
  };

  return dataSet[mode] ? (
    <div className={classes.timetableDetailTable}>
      <div className={classes.header}>
        <span>{dataSet[mode].text}:</span>
        <span onClick={handleEditClick}>
          <p>{htmlParse(dataSet[mode].name)}</p>
          {
            dataSet[mode].isEdit ? (
              <SVG src="repick"/>
            ) : null
          }
        </span>
      </div>
      <div className={classes.body}>
        {
          dataSet[mode].items ? dataSet[mode].items.map((item: any, key: any) => (
            <div className={classes.item} key={`k_${key}`}>
              <span>{item.name}</span>
              <p>{getHourCount(item.id)}</p>
            </div>
          )) : null
        }
      </div>
      {
        (mode === "class" && state.isClassesModalOpen) ? (
          <ClassesSelect
            defaultSchools={[{schoolID: schoolID}]}
            defaultClasses={list.classes}
            selectedClasses={[{classID: timetableData.class.classID}]}
            customSort={[{classID: timetableData.class.classID, customSortOrder: 1}]}
            onClickClass={handleChangeClass}
            isSelectAll={false}
            isAllowOnlyOneClassAtOnce={true}
            isShowChildrenCounts={true}
            mode="select"
            isInModal={true}
            isModalOpen={true}
            modalTitle="classes"
            modalAllowClassesCount={false}
            modalAllowClose={true}
            modalOnClose={() => setState("isClassesModalOpen", false)}
          />
        ) : null
      }
      {
        (mode === "employee" && state.isEmployeesModalOpen) ? (
          <EmployeesSelect
            selectedEmployees={[timetableData.employee]}
            defaultEmployees={list.employees}
            mode="select"
            isSelectAll={false}
            isMultipleSelect={false}
            isAllowOnlyOneEmployeeAtOnce={true}
            onClickEmployee={handleChangeEmployee}
            isInModal={true}
            isModalOpen={true}
            modalTitle="timetable_teachers"
            modalAllowEmployeesCount={false}
            modalAllowClose={true}
            modalOnClose={() => setState("isEmployeesModalOpen", false)}
          />
        ) : null
      }
      {
        (mode === "child" && state.isChildrenModalOpen) ? (
          <ChildrenSelect
            selectedChildren={[timetableData.child]}
            defaultChildren={list.children}
            customSort={getChildData(timetableData.child.childID).classID.map((item: any, key: any) => { return { classID: item, customSortOrder: key }; })}
            mode="select"
            isSelectAll={false}
            isMultipleSelect={false}
            isSelectInAllClass={true}
            isAllowOnlyOneChildAtOnce={true}
            onClickChild={handleChangeChild}
            isInModal={true}
            isModalOpen={true}
            modalTitle="timetable_children"
            modalAllowChildrenCount={false}
            modalAllowClose={true}
            modalOnClose={() => setState("isChildrenModalOpen", false)}
          />
        ) : null
      }
    </div>
  ) : null;
}

export default TimetableDetailTable;