import IconButton from 'src/components/Buttons/IconButton';
import React, { useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import SVG from 'src/components/Images/SvgRenderer';
import usePreventBodyScroll from 'src/utils/usePreventBodyScroll';
import { createUseStyles } from 'react-jss';
import { isMobile } from 'react-device-detect';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';

interface Props {
  layout?: any;
  isArrowFloat?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  scrollMenuWrapper: {
    display: 'flex',
    flexDirection: (props: Props) => {
      if(props.layout === "horizontal") return "row";
      else return "column";
    },
    alignItems: 'center',
    gap: '4px',
    width: '100%',
    height: (props: Props) => {
      if(props.layout === "vertical" && props.isArrowFloat) return "100%";
      else if(props.layout === "vertical" && props.isArrowFloat) return "calc(100% - 80px)";
      else return "";
    },
    position: 'relative',
  },
  scrollMenu: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    alignItems: 'center',
  },
  scrollButton: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
    color: theme.colors.primaryBlue[500],
    position: (props: Props) => {
      if(props.isArrowFloat) return "absolute";
      else return "";
    },
    '&.first': {
      top: (props: Props) => {
        if(props.layout === "vertical" ) return "4px";
        else return "";
      },
    },
    '&.last': {
      bottom: (props: Props) => {
        if(props.layout === "vertical" ) return "4px";
        else return "";
      },
    },
  },
}));

type ItemsType = {
  children: any;
  layout?: 'horizontal' | 'vertical';
  nativeMobileScroll?: boolean;
  useArrows?: boolean;
  useWheel?: boolean;
  isArrowFloat?: boolean;
  onClick?: any;
  className?: any;
  classNameWrapper?: any;
  classNameButton?: any;
  customRef?: any;
  customWrapperRef?: any;
  prependContent?: any;
  appendContent?: any;
};

const ScrollMenu: React.FunctionComponent<ItemsType> = ({children, layout = "horizontal", nativeMobileScroll, useArrows = true, useWheel = false, isArrowFloat = false, onClick, className, classNameWrapper, classNameButton, customRef, customWrapperRef, prependContent, appendContent}) => {

  const classes = useStyles({
    layout: layout,
    isArrowFloat: isArrowFloat
  });
  const scrollMenuRefDefault: any = useRef(null);
  const scrollMenuRef: any = customRef ? customRef : scrollMenuRefDefault;
  const browserData = useAppSelector((state: any) => state.browser);
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  const [state, setState] = useStates({
    visibleArrows: false,
    isOnStart: false,
    isOnEnd: false,
    movePoints: 0,
  });

  useEffect(() => {
    if(scrollMenuRef && scrollMenuRef.current) {
      const container = scrollMenuRef.current.container.current;
      const { scrollLeft, scrollWidth, clientWidth, scrollTop, scrollHeight, clientHeight }: any = container;
      const scrollDirection = layout === "horizontal" ? scrollLeft : scrollTop;
      const scrollSize = layout === "horizontal" ? scrollWidth : scrollHeight;
      const clientSize = layout === "horizontal" ? clientWidth : clientHeight;
      setState("visibleArrows", clientSize < scrollSize);
      if(Math.ceil(scrollDirection + clientSize) >= scrollSize) {
        setState("isOnStart", false);
        setState("isOnEnd", true);
      } else {
        if(scrollDirection === 0) {
          setState("isOnStart", true);
          setState("isOnEnd", false);
        } else {
          setState("isOnStart", false);
          setState("isOnEnd", false);
        }
      }
      setState("movePoints", clientSize / 2);
    }
  }, [setState, layout, scrollMenuRef], [browserData.width, browserData.height, scrollMenuRef.current, children, layout]);

  const onScroll = () => {
    if(scrollMenuRef && scrollMenuRef.current) {
      const container = scrollMenuRef.current.container.current;
      const { scrollLeft, scrollWidth, clientWidth, scrollTop, scrollHeight, clientHeight }: any = container;
      const scrollDirection = layout === "horizontal" ? scrollLeft : scrollTop;
      const scrollSize = layout === "horizontal" ? scrollWidth : scrollHeight;
      const clientSize = layout === "horizontal" ? clientWidth : clientHeight;
      if(Math.ceil(scrollDirection + clientSize) >= scrollSize) {
        setState("isOnStart", false);
        setState("isOnEnd", true);
      } else {
        if(scrollDirection === 0) {
          setState("isOnStart", true);
          setState("isOnEnd", false);
        } else {
          setState("isOnStart", false);
          setState("isOnEnd", false);
        }
      }
    }
  };

  const scrollPrev = (e: any) => {
    if(e.defaultPrevented) {
      e.preventDefault();
      e.stopPropagation();
    }
    if(scrollMenuRef && scrollMenuRef.current) {
      const container = scrollMenuRef.current.container.current;
      const { scrollLeft, scrollTop }: any = container;
      const scrollDirection = layout === "horizontal" ? scrollLeft : scrollTop;
      const newValue = (scrollDirection - state.movePoints);
      if(layout === "horizontal") {
        container.scrollTo({
          left: newValue < 0 ? 0 : newValue,
          behavior: 'smooth'
        });
      } else {
        container.scrollTo({
          top: newValue < 0 ? 0 : newValue,
          behavior: 'smooth'
        });
      }
    }
  };
  
  const scrollNext = (e: any) => {
    if(e.defaultPrevented) {
      e.preventDefault();
      e.stopPropagation();
    }
    if(scrollMenuRef && scrollMenuRef.current) {
      const container = scrollMenuRef.current.container.current;
      const { scrollLeft, scrollTop, scrollWidth, scrollHeight }: any = container;
      const scrollDirection = layout === "horizontal" ? scrollLeft : scrollTop;
      const newValue = (scrollDirection + state.movePoints);
      if(layout === "horizontal") {
        container.scrollTo({
          left: newValue > scrollWidth ? scrollWidth : newValue,
          behavior: 'smooth'
        });
      } else {
        container.scrollTo({
          top: newValue > scrollHeight ? scrollHeight : newValue,
          behavior: 'smooth'
        });
      }
    }
  };

  const onWheel = (e: any) => {
    if(useWheel) {
      const isTouchpad = Math.abs(e.deltaX) !== 0 || Math.abs(e.deltaY) < 15;
      if(isTouchpad) {
        e.stopPropagation();
        return;
      }
      if(e.deltaY < 0) {
        scrollPrev(e);
      } else if(e.deltaY > 0) {
        scrollNext(e);
      }
    }
  };
  
  const onMouseEnter = (e: any) => {
    if(useWheel) {
      disableScroll();
    }
  };

  const onMouseLeave = (e: any) => {
    if(useWheel) {
      enableScroll();
    }
  };

  const memoChildren = useMemo(() => children, [children]);
  
  return (
    <div className={`${classes.scrollMenuWrapper} ${classNameWrapper ? classNameWrapper : null}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onWheel={onWheel} onClick={onClick} ref={customWrapperRef}>
      {
        (useArrows && state.visibleArrows && !isMobile) ? (
          <IconButton className={`${classes.scrollButton} first ${classNameButton ? classNameButton : null}`} disabled={state.isOnStart} onClick={scrollPrev} dataCy='scrollPrevButton'>
            <SVG src={layout === "horizontal" ? "chevron-left" : "chevron-up"}/>
          </IconButton>
        ) : null
      }
      {
        prependContent ? prependContent : null
      }
      <ScrollContainer onScroll={onScroll} onEndScroll={onScroll} ref={scrollMenuRef} className={`${classes.scrollMenu} ${className ? className : null}`} vertical={layout === "vertical"} horizontal={layout === "horizontal"} nativeMobileScroll={nativeMobileScroll}>
        {memoChildren}
      </ScrollContainer>
      {
        appendContent ? appendContent : null
      }
      {
        (useArrows && state.visibleArrows && !isMobile) ? (
          <IconButton className={`${classes.scrollButton} last ${classNameButton ? classNameButton : null}`} disabled={state.isOnEnd} onClick={scrollNext} dataCy='scrollNextButton'>
            <SVG src={layout === "horizontal" ? "chevron-right" : "chevron-down"}/>
          </IconButton>
        ) : null
      }
    </div>
  );
}

export default ScrollMenu;