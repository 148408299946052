import * as MainService from '../../../../services/main.service';
import { setConfiguration } from 'src/store/actions/configuration.actions';

const loadConfiguration = async (dispatch: any, languageData: any) => {
  try {
    const result: any = await MainService.getConfiguration(languageData.language.toLowerCase());
    if(result && result.data) {
      const configuration = result.data;
      dispatch(setConfiguration(configuration));
      return result.data;
    } else {
      return false;
    }
  } catch (e: any) {
    return false; 
  };
};

export default loadConfiguration;