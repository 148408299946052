import Popover from '@mui/material/Popover';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import SubjectItem from '../SubjectItem';

const useStyles = createUseStyles((theme: any) => ({
  more: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: '4px',
    width: 'calc(100% - 8px)',
    height: 'calc(100% - 8px)',
  },
  hourNumber: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    left: '4px',
    right: '4px',
    fontSize: '14px',
  },
  list: {
    display: 'flex',
    width: '92px',
    height: '100%',
    flexDirection: 'column',
    borderRadius: '12px',
    maxHeight: '20vh',
    overflow: 'auto',
    '& > div': {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    '& > .subjectWrapper': {
      display: 'flex',
      '& > div': {
        width: '100%',
        height: '46px',
        transition: 'all 0.25s',
      },
    },
    '& > .subjectWrapper2': {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        height: '46px',
        transition: 'all 0.25s',
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
    '& > .subjectWrapper3': {
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        height: '46px',
        transition: 'all 0.25s',
        '&:last-of-type': {
          width: '100%',
        },
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
    '& > .subjectWrapper4': {
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        height: '46px',
        transition: 'all 0.25s',
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
  },
  add: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundColor: theme.colors.primaryBlue[500],
  },
  button: {
    display: "flex",
    alignSelf: "center",
    transition: 'opacity 0.25s',
    width: '42px',
    height: '42px',
    color: theme.colors.primaryBlue[500],
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.white,
    },
    '& > svg': {
      width: '32px',
      height: '32px',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      boxShadow: theme.shadows[1],
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[200],
      borderRadius: '0 12px 12px 12px',
      backgroundColor: theme.colors.grey[200],
    },
  },
}));

type MoreSubjectsItemType = {
  hoursData: any;
  day: any;
  hour: any;
};

const MoreSubjectsItem: React.FunctionComponent<MoreSubjectsItemType> = ({hoursData, day, hour}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const timetableData = useAppSelector((state: any) => state.timetable);
  const settings = timetableData.settings === null ? [] : timetableData.settings;

  const hoursCount = hoursData.length - 3;
  const displayHours = hoursData.slice(3, hoursData.length);
  const displayHoursCount = (settings.canEdit && settings.canMultipleHours && timetableData.type !== "break") ? displayHours.length + 1 : displayHours.length;

  const [state, setState] = useStates({
    anchorEl: null,
  });

  const handleClick = (e: any) => {
    setState("anchorEl", e.currentTarget);
  };

  const handleClose = () => {
    setState("anchorEl", null);
  };

  const handleCreateHour = (day: any, hour: any, type: any) => {
    const settings = {
      isOpen: true,
      weekday: day,
      hour: hour,
      type: type,
      mode: timetableData.type,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  return hoursCount > 0 ? (
    <>
      <div className={classes.more} onClick={handleClick}>
        <div className={classes.inner}>
          <span className={classes.hourNumber}>+{hoursCount}</span>
        </div>
      </div>
      <Popover className={classes.popover} id={day.toString() + hour.toString()} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <div className={classes.list}>
          <div className={`subjectWrapper${displayHoursCount === 1 ? 2 : (displayHoursCount > 1 && displayHoursCount < 4 ? displayHoursCount : (displayHoursCount % 2 === 0 ? 4 : 3))}`}>
          {
            displayHours.map((hourData: any, key: any) => (
              <SubjectItem size={displayHoursCount === 1 ? 2 : (displayHoursCount > 1 && displayHoursCount < 4 ? displayHoursCount : 4)} day={day} hour={hour} hourData={hourData} key={`k_${key}`}/>
            ))
          }
          {
            (settings.canEdit && settings.canMultipleHours && timetableData.type !== "break") ? (
              <div className={classes.add} onClick={() => handleCreateHour(day, hour, timetableData.type === "employee" ? 2 : 1)}>
                <IconButton className={classes.button}>
                  <SVG src="plus"/>
                </IconButton>
              </div>
            ) : null
          }
          </div>
        </div>
      </Popover>
    </>
  ) : null;
}

export default MoreSubjectsItem;