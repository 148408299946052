import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  filterParams: any;
  filtersSettings: any;
  isFilterParamsLoaded: boolean;
  isFilterSettingsLoaded: boolean;
  isFilterSetupLoaded: boolean;
};

const initialState: ArrayModel = {
  filterParams: {
    postID: null,
    paymentID: null,
    postTypeID: null,
    galleryTypeID: [],
    activityTypeID: [],
    childID: [],
    employeeID: [],
    dateFrom: null,
    dateTo: null,
    authorID: [],
    pollVoted: null,
    timelineFavorite: null,
    galleryFavorite: null,
    galleryHide: null,
    classID: [],
    schoolID: [],
    paymentMethod: null,
    paymentStatus: null,
    paymentType: null,
    currencyID: null,
    search: '',
  },
  filtersSettings: {
    isAllowedPostID: false,
    isAllowedPaymentID: false,
    isAllowedPostType: false,
    isAllowedGalleryType: false,
    isAllowedActivityType: false,
    isAllowedChildID: false,
    isAllowedEmployeeID: false,
    isAllowedDate: false,
    isAllowedAuthors: false,
    isAllowedPolls: false,
    isAllowedClasses: false,
    isAllowedSchools: false,
    isAllowedTimelineFavorite: false,
    isAllowedGalleryFavorite: false,
    isAllowedGalleryHide: false,
    isAllowedPaymentMethod: false,
    isAllowedPaymentStatus: false,
    isAllowedPaymentType: false,
    isAllowedCurrencyID: false,
  },
  isFilterParamsLoaded: false,
  isFilterSettingsLoaded: false,
  isFilterSetupLoaded: false,
};

const slice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    setFilterParams(state, action: PayloadAction<Model>) {
      state.filterParams = Object.assign(state.filterParams, action.payload);
    },
    setFiltersSettings(state, action: PayloadAction<boolean>){
      state.filtersSettings = action.payload;
    },
    setIsFilterParamsLoaded(state, action: PayloadAction<boolean>) {
      state.isFilterParamsLoaded = action.payload;
    },
    setIsFilterSettingsLoaded(state, action: PayloadAction<boolean>) {
      state.isFilterSettingsLoaded = action.payload;
    },
    setIsFilterSetupLoaded(state, action: PayloadAction<boolean>) {
      state.isFilterSetupLoaded = action.payload;
    },
    resetFilterParams(state) {
      state.filterParams = initialState.filterParams;
    },
    resetAllFiltersSettings(state) {
      state.filtersSettings = initialState.filtersSettings;
    },
  }
});

export default slice;