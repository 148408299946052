import React from 'react';
import { ContextMenu as ContextMenuProp, ContextMenuTrigger as ContextMenuTriggerProp, MenuItem as ContextMenuItemProp, SubMenu as ContextMenuSubMenuProp } from '@firefox-devtools/react-contextmenu';
import { createUseStyles } from 'react-jss';
import { isCypress } from './useCypress';

interface ContextMenuProps {
  children: any;
  id: string;
  className?: any;
  dataCy?: any;
  hideOnLeave?: boolean;
  onMouseLeave?: any;
  onHide?: any;
  onShow?: any;
  preventHideOnContextMenu?: boolean;
  preventHideOnResize?: boolean;
  preventHideOnScroll?: boolean;
};

interface ContextMenuTriggerProps {
  children: any;
  id: string;
  className?: any;
  renderTag?: any;
  attributes?: object;
  disable?: boolean;
  collect?: any;
  holdToDisplay?: number;
  disableIfShiftIsPressed?: boolean;
  triggerOnLeftClick?: boolean;
  dataCy?: any;
  customRef?: any;
};

interface ContextMenuItemProps {
  children: any;
  data?: string;
  className?: any;
  preventClose?: boolean;
  attributes?: object;
  disabled?: boolean;
  disableHover?: boolean;
  onClick?: any;
  dataCy?: any;
};

interface ContextMenuSubMenuProps {
  title: any;
  children: any;
  hoverDelay: number;
  className?: any;
  disabled: boolean;
  preventCloseOnClick?: boolean; 
  onClick?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  contextMenu: {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    width: 'fit-content',
    maxWidth: '400px',
    minWidth: '200px',
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    zIndex: theme.zIndex.contextMenu,
  },
  contextMenuTrigger: {

  },
  contextMenuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 16px',
    cursor: 'pointer',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    transition: 'all 0.25s',
    fontSize: '16px',
    lineHeight: '16px',
    '& > svg': {
      width: '14px',
      height: '14px',
      color: theme.colors.primaryBlue[500],
    },
    '&:not(.disableHover):hover': {
      backgroundColor: theme.colors.grey[225],
      color: theme.colors.black,
    },
    '&:first-of-type:not(:last-of-type)': {
      borderRadius: '12px 12px 0px 0px',
    },
    '&:last-of-type:not(:first-of-type)': {
      borderRadius: '0px 0px 12px 12px',
    },
    '&:first-of-type:last-of-type': {
      borderRadius: '12px',
    },
    '& > small': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
  contextMenuSubMenu: {

  },
}));

const ContextMenu: React.FunctionComponent<ContextMenuProps> = ({ id, children, className, hideOnLeave = false, onMouseLeave, onHide, onShow, preventHideOnContextMenu = false, preventHideOnResize = false, preventHideOnScroll = false}) => {
  
  const classes = useStyles();
  
  return (
    <ContextMenuProp id={id} className={`${classes.contextMenu} ${className}`} hideOnLeave={hideOnLeave} onMouseLeave={onMouseLeave} onHide={onHide} onShow={onShow} preventHideOnContextMenu={preventHideOnContextMenu} preventHideOnResize={preventHideOnResize} preventHideOnScroll={preventHideOnScroll}>
      {children}
    </ContextMenuProp>
  );

};

const ContextMenuTrigger: React.FunctionComponent<ContextMenuTriggerProps> = ({ id, children, renderTag, className, attributes = {}, collect, disable = false, holdToDisplay = 1000, disableIfShiftIsPressed = false, triggerOnLeftClick = false, dataCy, customRef}) => {

  const classes = useStyles();
  const attributesObject = Object.assign(attributes, { className: `${classes.contextMenuTrigger} ${className}`, 'data-cy': isCypress() ? dataCy : null });
  
  return (
    <ContextMenuTriggerProp id={id} attributes={attributesObject as any} renderTag={renderTag} collect={collect} disable={disable} holdToDisplay={holdToDisplay} disableIfShiftIsPressed={disableIfShiftIsPressed} triggerOnLeftClick={triggerOnLeftClick} ref={customRef}>
      {children}
    </ContextMenuTriggerProp>
  );

};


const ContextMenuItem: React.FunctionComponent<ContextMenuItemProps> = ({ children, data, className, attributes = {}, disabled = false, disableHover = false, onClick, dataCy}) => {

  const classes = useStyles();
  const attributesObject = Object.assign(attributes, { className: `${classes.contextMenuItem} ${disableHover ? 'disableHover' : null} ${className}`, 'data-cy': isCypress() ? dataCy : null });
  
  const handleOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <ContextMenuItemProp attributes={attributesObject as any} data={data} disabled={disabled} onClick={handleOnClick}>
      {children}
    </ContextMenuItemProp>
  );
};

const ContextMenuSubMenu: React.FunctionComponent<ContextMenuSubMenuProps> = ({ title, children, className, hoverDelay = 500, disabled = false, preventCloseOnClick = false, onClick }) => {

  const classes = useStyles();

  return (
    <ContextMenuSubMenuProp title={title} disabled={disabled} className={`${classes.contextMenu} ${className}`} hoverDelay={hoverDelay} preventCloseOnClick={preventCloseOnClick} onClick={onClick}>
      {children}
    </ContextMenuSubMenuProp>
  );
};

export { 
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
  ContextMenuSubMenu,
};