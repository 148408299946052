import BlockButton from 'src/components/Buttons/BlockButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { setStockSchool, setStockViewMode } from 'src/store/actions/stock.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  stockSettingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  buttons: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '0 24px',
    maxWidth: 'calc(100% - 48px)',
  },
}));

const StockSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const classes = useStyles();
  const stockData = useAppSelector((state: any) => state.stock);
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;

  const list = dataData.schools.filter((item: any) => item.stock && item.stock.enable);
  const categories = stockData.categories;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const setViewMode = (view: any) => {
    dispatch(setStockViewMode(view));
  };

  const handleSchoolSelect = () => {
    setViewMode('schoolSelect');
    dispatch(setStockSchool(null));
  };

  const handleItemsInfoClick = () => {
    let reason = "";
    if(categories.length === 0) {
      reason = t('function_limited_stock_categories');
    }
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: reason,
    };
    dispatch(setWarningModal(settings));
  };

  const isItemsDisabled = categories === null ? true : categories.length === 0;

  return (
    <div className={classes.stockSettingsWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.buttons}>
        <BlockButton icon={<SVG src="stock-items"/>} onClick={() => setViewMode('categorySelect')} disabled={isItemsDisabled} onInfoIconClick={isItemsDisabled ? handleItemsInfoClick : null}>
          {t('stock_items')}
        </BlockButton>
        <BlockButton icon={<SVG src="stock-categories"/>} onClick={() => setViewMode('categories')}>
          {t('stock_categories')}
        </BlockButton>
        <BlockButton icon={<SVG src="stock-tags"/>} onClick={() => setViewMode('tags')}>
          {t('stock_tags')}
        </BlockButton>
        <BlockButton icon={<SVG src="shop"/>} onClick={() => setViewMode('orders')}>
          {t('stock_orders')}
        </BlockButton>
        {
          list.length > 1 ? (
            <BlockButton icon={<SVG src="school"/>} onClick={handleSchoolSelect}>
              {t('change_school')}
            </BlockButton>
          ) : null
        }
      </div>
    </div>
  );
};

export default StockSettings;