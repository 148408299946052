import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { setInfoModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const Attendance: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalsData = useAppSelector((state: any) => state.modals);

  const handleAttendanceInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('about_attendance'),
      type: 'attendance',
    };
    dispatch(setInfoModal(settings));
  };

  return (
    <>
      <TopBarButton className={modalsData.infoModal.isOpen ? 'active' : null} icon={<SVG src="question-mark-circle"/>} onClick={handleAttendanceInfoClick} dataCy="topBarButtonInfo" tooltip={t('help')}/>
    </>
  );
}

export default Attendance;