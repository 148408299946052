import HttpService from '../http.service';

const listAttendance = (date: any, language?: string) => {
  return HttpService.get(`attendance/${date}`, {customLanguage: language || undefined}, {});
};

const updateAttendance = (payload: any) => {
  return HttpService.post(`attendance`, payload, {}, {});
};

export {
  listAttendance,
  updateAttendance,
};