import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import Currency from 'react-currency-formatter';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import SVG from 'src/components/Images/SvgRenderer';
import TabsMenu from 'src/components/Menus/TabsMenu';
import theme from 'src/ui/theme';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, isKey } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setConfirmModal, setShopItemDetailModal, setShopOrderDetailModal, setShoppingCartModal } from '../../../store/actions/modals.actions';
import { setStockCart, setStockOrders, setStockOrdersCount } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  viewMode: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: (props: Props) => {
      if(props.viewMode === "empty") return "400px";
      else return "800px";
    },
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
      width: '100%',
      '& > thead': {
        '& > tr': {
          '& > th': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: '16px',
    gap: '10px',
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
    paddingBottom: '16px',
  },
  bottom: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  itemName: {
    fontWeight: 'bold',
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    display: 'block',
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  limitInput: {
    width: '100px',
  },
  remove: {
    cursor: 'pointer',
    color: theme.colors.primaryBlue[500],
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(238,238,238,0.5)',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  tableCellWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
  select: {
    width: 'unset',
    flex: 'unset',
  },
}));

const ShoppingCartModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;

  const inputEls: any = useRef([]);
  const noteInputRef: any = useRef(null);

  const schoolSettings = userData.schoolSettings;

  const shoppingCart = stockData.cart;

  const [state, setState] = useStates({
    isLoading: false,
    isDeletedItem: false,
    isSent: false,
    viewMode: null,
    items: [],
    lastItems: [],
    note: stockData.cart.map((item: any) => {
      return {schoolID: item.schoolID, note: ""};
    }),
    children: stockData.cart.map((item: any) => {
      return {schoolID: item.schoolID, children: null};
    }),
  });

  const availableChildren = dataData.children.map((item: any) => { 
    if(item.schoolsID.indexOf(state.viewMode) !== -1) {
      return { ...item, image: item.photo.thumbLink};
    } else {
      return null;
    }
  }).filter((item: any) => item !== null);

  const setViewMode = useCallback((value: any) => {
    setState("viewMode", value);
    if(noteInputRef && noteInputRef.current && value !== "empty") {
      noteInputRef.current.value = state.note.filter((item: any) => item.schoolID === value).length === 0 ? "" : state.note.find((item: any) => item.schoolID === value).note;
    }
    setTimeout(() => {
      if(inputEls.current) {
        const newItems = shoppingCart.filter((item: any) => item.schoolID === value).length === 0 ? [] : shoppingCart.find((item: any) => item.schoolID === value).items;
        newItems.forEach((newItem: any) => {
          const uniqueID = newItem.variantID ? newItem.itemID + "" + newItem.variantID : newItem.itemID;
          const newQuantity = newItem.quantity;
          if(inputEls.current[uniqueID]) {
            inputEls.current[uniqueID].value = newQuantity;
          }
        });
      }
    }, 1);
  }, [setState, noteInputRef, state.note, shoppingCart]);

  const classes = useStyles({
    viewMode: state.viewMode,
  });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getItemData = useCallback((itemID: any) => {
    return state.items.filter((item: any) => item.itemID === itemID).length === 0 ? [] : state.items.find((item: any) => item.itemID === itemID);
  }, [state.items]);

  const tabsItems = shoppingCart.map((school: any) => {
    if(shoppingCart.filter((item: any) => item.schoolID === school.schoolID).length === 0) {
      return null;
    } else {
      if(shoppingCart.find((item: any) => item.schoolID === school.schoolID).items.length === 0) {
        return null;
      } else {
        return {
          name: getSchoolData(parseInt(school.schoolID)).name,
          value: school.schoolID,
          badge: shoppingCart.filter((item: any) => item.schoolID === parseInt(school.schoolID)).length === 0 ? 0 : shoppingCart.find((item: any) => item.schoolID === parseInt(school.schoolID)).items.length,
          badgeType: 'primary',
        };
      }
    }
  }).filter((item: any) => item !== null);

  const getItemsCount = useCallback(() => {
    let items: any = [];
    shoppingCart.forEach((school: any) => {
      if(shoppingCart.find((item: any) => item.schoolID === school.schoolID).items.length !== 0) {
        items = items.concat(school);
      }
    });
    return items;
  }, [shoppingCart]);

  const getAllItems = useCallback(() => {
    let allItems: any = [];
    shoppingCart.forEach((school: any) => {
      if(shoppingCart.find((item: any) => item.schoolID === school.schoolID).items.length !== 0) {
        let items = shoppingCart.find((item: any) => item.schoolID === school.schoolID).items.map((subItem: any) => {
          return subItem.itemID;
        });
        allItems = allItems.concat(items);
      }
    });
    return allItems;
  }, [shoppingCart]);

  const getPrice = useCallback((useTax: boolean, quantity: any, itemID: any, variantID?: any) => {
    const schoolID = parseInt(state.viewMode);
    const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
    const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
    const tax = useTax ? ((getItemData(itemID).taxRate / 100) + 1) : 1;
    const price = (variantID ? (getItemData(itemID).variant ? (getItemData(itemID).variant.filter((variant: any) => variant.variantID === variantID).length === 0 ?  0 : getItemData(itemID).variant.find((variant: any) => variant.variantID === variantID).price * tax) : 0) : (getItemData(itemID).price * tax)) * quantity;
    return [price, getCurrency];
  }, [configurationData.currencies, getItemData, schoolSettings, state.viewMode]);

  const getQuantity = useCallback((itemID: any, variantID?: any) => {
    const quantity = variantID ? (getItemData(itemID).variant ? (getItemData(itemID).variant.filter((variant: any) => variant.variantID === variantID).length === 0 ?  0 : getItemData(itemID).variant.find((variant: any) => variant.variantID === variantID).quantity) : 0) : (getItemData(itemID).quantity);
    return quantity;
  }, [getItemData]);

  const getTotalPrice = useCallback(() => {
    let price = 0;
    const schoolID = parseInt(state.viewMode);
    const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);;
    const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
    if(shoppingCart.filter((item: any) => item.schoolID === state.viewMode).length !== 0) {
      shoppingCart.find((item: any) => item.schoolID === state.viewMode).items.forEach((subItem: any) => {
        price = price + (subItem.variantID ? getPrice(true, subItem.quantity, subItem.itemID, subItem.variantID)[0] : getPrice(true, subItem.quantity, subItem.itemID)[0]);
      });
    }
    return [price, getCurrency];
  }, [shoppingCart, getPrice, state.viewMode, configurationData.currencies, schoolSettings]);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setShoppingCartModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  useEffect(() => {
    if(getItemsCount().length === 0) {
      setViewMode("empty");
    } else {
      const newViewMode = (state.viewMode === "empty" || state.viewMode === null) ? getItemsCount()[0].schoolID : state.viewMode;
      setViewMode(newViewMode);
    }
  }, [getItemsCount, setViewMode, state.viewMode], [getItemsCount]);

  const getOrders = useCallback(() => {
    setState("isLoading", true);
    const settings = {
      schoolID: stockData.filterSchoolID.map((item: any) => { return item.schoolID; }).join(","),
      userID: userData.userObject.userID,
      orderStatusID: stockData.filterOrderStatusID.map((item: any) => { return item.orderStatusID }).join(","),
      limit: 15,
      page: stockData.filterCurrentPage, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockOrders(result.data.orders));
          dispatch(setStockOrdersCount(result.data.countItems));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, stockData, userData, dispatch, setState]);

  useEffect(() => {
    const allItems = getAllItems();
    if(allItems.length > 0) {
      if(state.lastItems.length === 0 || JSON.stringify(state.lastItems) !== JSON.stringify(allItems)) {
        if(!state.isDeletedItem) {
          setState("isLoading", true);
        }
        const settings = {
          itemID: allItems.join(","),
          limit: 10000, 
        };
        stockService && stockService.listItems(settings).then((result: any) => {
          if(result) {
            if(result.data) {
              setState("items", result.data.items);
              setState("lastItems", allItems);
              setState("isLoading", false);
              setState("isDeletedItem", false);
            } else {
              createNotification(t("stock_items_not_loaded"), "error");
            }
          } else {
            createNotification(t("stock_items_not_loaded"), "error");
          }
        }).catch(() => {
          createNotification(t("stock_items_not_loaded"), "error");
        });
      }
    }
  }, [getAllItems, stockService, t, setState, state.lastItems, state.isDeletedItem], [getAllItems]);

  const handleEmptyCart = () => {
    const onAccept = () => {
      const emptyCart = shoppingCart.map((item: any) => {
        if(item.schoolID === state.viewMode) {
          return {schoolID: item.schoolID, items: []};
        } else {
          return item;
        }
      });
      dispatch(setStockCart(emptyCart));
      setViewMode("empty");
    };
    const settings = {
      isOpen: true,
      title: t('stock_empty_cart'),
      content: t('stock_empty_cart_info'),
      onAccept: onAccept,
      onDecline: () => {},
    };
    dispatch(setConfirmModal(settings));
  };

  const handleClearCart = (orderID: any, schoolID: any) => {
    const emptyCart = shoppingCart.map((item: any) => {
      if(item.schoolID === state.viewMode) {
        return {schoolID: item.schoolID, items: []};
      } else {
        return item;
      }
    });
    dispatch(setStockCart(emptyCart));
    setTimeout(() => {
      const findNotEmptySchool = emptyCart.filter((item: any) => item.items.length !== 0);
      if(findNotEmptySchool.length === 0) {
        setViewMode("empty");
        onCloseModal();
      } else {
        setViewMode(findNotEmptySchool[0].schoolID);
      }
      const settings = {
        isOpen: true,
        isNewCreated: true,
        orderID: orderID,
        schoolID: schoolID,
      };
      dispatch(setShopOrderDetailModal(settings)); 
      setState("isSent", false);
    }, 1);
  };

  const handleCreateOrder = () => {
    setState("isSent", true);
    const payload = {
      "items": shoppingCart.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? [] : shoppingCart.find((item: any) => item.schoolID === state.viewMode).items,
      "note": state.note.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? "" : state.note.find((item: any) => item.schoolID === state.viewMode).note,
      "childID": state.children.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? null : state.children.find((item: any) => item.schoolID === state.viewMode).children.childID,
      "schoolID": state.viewMode,
    };
    stockService && stockService.createOrder(payload).then((result: any) => {
      if(result) {
        if(result.data) {
          const orderID = result.data.orders[0].orderID;
          const schoolID = result.data.orders[0].schoolID;
          createNotification(t("stock_order_added"), "success");
          handleClearCart(orderID, schoolID);
          getOrders();
        } else {
          createNotification(t("stock_order_not_added"), "error");
        }
      } else {
        createNotification(t("stock_order_not_added"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_order_not_added"), "error");
    });
  };

  const handleOpenItem = (itemID: any) => {
    const settings = {
      isOpen: true,
      itemID: itemID,
      showCart: true,
      schoolID: parseInt(state.viewMode),
    };
    dispatch(setShopItemDetailModal(settings)); 
  };

  const deleteItem = (itemID: any, variantID: any) => {
    setState("isDeletedItem", true);
    const updateCart = shoppingCart.map((item: any) => {
      if(item.schoolID === state.viewMode) {
        const newItems = item.items.map((subItem: any) => {
          if(variantID !== null) {
            if(subItem.itemID === itemID && subItem.variantID === variantID) {
              return null;
            } else {
              return subItem;
            }
          } else {
            if(subItem.itemID === itemID) {
              return null;
            } else {
              return subItem;
            }
          }
        }).filter((item: any) => item !== null);
        setTimeout(() => {
          newItems.forEach((newItem: any) => {
            const uniqueID = newItem.variantID ? newItem.itemID + "" + newItem.variantID : item.newItemID;
            const newQuantity = newItem.quantity;
            inputEls.current[uniqueID].value = newQuantity;
          });
        }, 1);
        return {schoolID: item.schoolID, items: newItems};
      } else {
        return item;
      }
    });
    dispatch(setStockCart(updateCart));
    const itemsCount = updateCart.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? 0 : updateCart.find((item: any) => item.schoolID === state.viewMode).items.length;
    if(itemsCount === 0) {
      const findNotEmptySchool = updateCart.filter((item: any) => item.items.length !== 0);
      if(findNotEmptySchool.length === 0) {
        setViewMode("empty");
      } else {
        setViewMode(findNotEmptySchool[0].schoolID);
      }
    }
  };

  const handleQuantity = (itemID: any, variantID: any, quantity: any) => {
    const uniqueID = variantID ? itemID + "" + variantID : itemID;
    const maxValue = inputEls.current[uniqueID].max;
    if(quantity !== "" && parseInt(quantity) > 0) {
      if(parseInt(quantity) > parseInt(maxValue)) {
        quantity = parseInt(maxValue);
        inputEls.current[uniqueID].value = parseInt(maxValue);
      }
      const updateCart = shoppingCart.map((item: any) => {
        if(item.schoolID === state.viewMode) {
          const newItems = item.items.map((subItem: any) => {
            if(variantID !== null) {
              if(subItem.itemID === itemID && subItem.variantID === variantID) {
                return {...subItem, quantity: parseInt(quantity)};
              } else {
                return subItem;
              }
            } else {
              if(subItem.itemID === itemID) {
                return {...subItem, quantity: parseInt(quantity)};
              } else {
                return subItem;
              }
            }
          });
          return {schoolID: item.schoolID, items: newItems};
        } else {
          return item;
        }
      });
      dispatch(setStockCart(updateCart));
    }
  };

  const handleEmptyQuantity = (uniqueID: any, itemID: any, variantID: any, quantity: any, ogquantity: any) => {
    if(quantity === "") {
      inputEls.current[uniqueID].value = ogquantity;
    } else if(parseInt(quantity) <= 0) {
      const resetQuantity = () => {
        inputEls.current[uniqueID].value = ogquantity;
      };
      const settings = {
        isOpen: true,
        title: t('stock_item_zero_quantity'),
        content: t('stock_item_zero_quantity_info'),
        onAccept: () => deleteItem(itemID, variantID),
        onDecline: resetQuantity,
      };
      dispatch(setConfirmModal(settings));
    }
  };

  const handleNote = (name: any, value: any) => {
    const newNote = state.note.map((item: any) => {
      if(item.schoolID === state.viewMode) {
        return {schoolID: item.schoolID, note: value};
      } else {
        return item;
      }
    });
    setState(name, newNote);
  };

  const handleSelectChild = (value: any) => {
    const newChildren = state.children.map((item: any) => {
      if(item.schoolID === state.viewMode) {
        return {schoolID: item.schoolID, children: value};
      } else {
        return item;
      }
    });
    setState("children", newChildren);
  };
   
  return state.viewMode !== null ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "shoppingCartModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_shopping_cart')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            state.viewMode === "empty" ? (
              <p>
                {t('stock_shopping_cart_empty')}
              </p>
            ) : (
              <>
                {
                  getItemsCount().length > 1 ? (
                    <div className={classes.tabsWrapper}>
                      <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
                    </div>
                  ) : null
                }
                {
                  state.isLoading ? (
                    <div className={classes.loading}>
                      <CircularProgress className={classes.spinner}/>
                    </div>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {t('stock_item_name')}
                          </th>
                          <th>
                            {t('stock_item_quantity')}
                          </th>
                          <th>
                            {t('stock_item_price_without_tax')}
                          </th>
                          <th>
                            {t('stock_item_price_with_tax')}
                          </th>
                          <th/>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          shoppingCart.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? [] : shoppingCart.find((item: any) => item.schoolID === state.viewMode).items.map((item: any, key: any) => {
                            const uniqueID = item.variantID ? item.itemID + "" + item.variantID : item.itemID;
                            return (
                              <tr key={`k_${key}`} >
                                <td>
                                  <div className={classes.tableCellWrapper}>
                                  <span className={classes.itemName} onClick={() => handleOpenItem(item.itemID)}>
                                    {getItemData(item.itemID).name}
                                    {
                                      item.variantID ? (
                                        <> 
                                        &nbsp;-&nbsp;{(getItemData(item.itemID).variant ? (getItemData(item.itemID).variant.filter((variant: any) => variant.variantID === item.variantID).length === 0 ?  "" : getItemData(item.itemID).variant.find((variant: any) => variant.variantID === item.variantID).name) : "")}
                                        </>
                                      ) : null
                                    }
                                  </span>
                                  {
                                    item.variantID ? (
                                      <> 
                                      {
                                        (getQuantity(item.itemID, item.variantID) !== null && item.quantity > getQuantity(item.itemID, item.variantID)) ? (
                                          <TooltipIcon icon="warning" maxWidth='200' iconColor={theme.colors.systemOrange[500]} position="top" title={t('stock_item_no_stock')}/>
                                        ) : null
                                      }
                                      </>
                                    ) : (
                                      <>
                                      {
                                        (getQuantity(item.itemID) !== null && item.quantity > getQuantity(item.itemID)) ? (
                                          <TooltipIcon icon="warning" maxWidth='200' iconColor={theme.colors.systemOrange[500]} position="top" title={t('stock_item_no_stock')}/>
                                        ) : null
                                      }
                                      </>
                                    )
                                  }
                                  </div>
                                </td>
                                <td>
                                  <Input type="number" className={classes.limitInput} placeholder={t('stock_item_quantity')} value={item.quantity} min={0} max={config.MAX_ITEMS_IN_CART} step={1} onChange={(name: any, value: any) => handleQuantity(item.itemID, item.variantID, value)} onBlur={(name: any, value: any) => handleEmptyQuantity(uniqueID, item.itemID, item.variantID, value, item.quantity)} customRefInput={(el: any) => (inputEls.current[uniqueID] = el)}/>
                                </td>
                                <td>
                                  {
                                    item.variantID ? (
                                      <Currency quantity={getPrice(false, item.quantity, item.itemID, item.variantID)[0]} currency={getPrice(false, item.quantity, item.itemID, item.variantID)[1].iso}/>
                                    ) : (
                                      <Currency quantity={getPrice(false, item.quantity, item.itemID)[0]} currency={getPrice(false, item.quantity, item.itemID)[1].iso}/>
                                    )
                                  }
                                </td>
                                <td>
                                  {
                                    item.variantID ? (
                                      <Currency quantity={getPrice(true, item.quantity, item.itemID, item.variantID)[0]} currency={getPrice(true, item.quantity, item.itemID, item.variantID)[1].iso}/>
                                    ) : (
                                      <Currency quantity={getPrice(true, item.quantity, item.itemID)[0]} currency={getPrice(true, item.quantity, item.itemID)[1].iso}/>
                                    )
                                  }
                                </td>
                                <td>
                                  <IconButton className={classes.remove} tooltip={t('stock_remove_item_from_cart')} tooltipPosition="left" tooltipMaxWidth={150} onClick={() => deleteItem(item.itemID, item.variantID ? item.variantID : null)}>
                                    <SVG src="shopping-cart-remove"/>
                                  </IconButton>
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  )
                }
                {
                  !state.isLoading ? (
                    <div className={classes.bottom}>
                      <Select className={classes.select} label={t('select_child')} inputLabel={t('select_child')} selected={state.children.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? null : state.children.find((item: any) => item.schoolID === state.viewMode).children} setSelected={handleSelectChild} items={availableChildren} defaultTitle={"displayName"} allowClear={false}/>
                      <Input label={t('stock_order_note')} name='note' multiline={true} value={state.note.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? "" : state.note.find((item: any) => item.schoolID === state.viewMode).note} onChange={handleNote} customRefInput={noteInputRef}/>
                      <span>{t('stock_total_price')}: <b><Currency quantity={getTotalPrice()[0]} currency={getTotalPrice()[1].iso}/></b></span>
                    </div>
                  ) : null
                }
              </>
            )
          }
        </div>
        {
          state.viewMode !== "empty" ? (
            <div className={classes.footer}>
              <NormalButton buttonType="secondary" onClick={handleEmptyCart} data-cy={isCypress() ? "closeButton" : null}>
                {t("stock_empty_cart")}
              </NormalButton>
              <NormalButton buttonType="primary" onClick={handleCreateOrder} disabled={state.isSent || state.children.filter((item: any) => item.schoolID === state.viewMode).length === 0 ? true : (state.children.find((item: any) => item.schoolID === state.viewMode).children === null ? true : false)} data-cy={isCypress() ? "orderButton" : null}>
                {t("stock_finish_order")}
              </NormalButton>
            </div>
          ): null
        }
      </div>
    </Modal>
  ) : null;
};

export default ShoppingCartModal;