import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import Select from 'src/components/Forms/Select';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setShopOrderStatusModal } from '../../../store/actions/modals.actions';
import { setStockOrders } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  select: {
    width: '100%',
    flex: '0',
  },
}));

const ShopOrderStatusModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;

  const isValid = modalsData.shopOrderStatusModal.orderID === null ? false : true;
  const orderID = isValid ? modalsData.shopOrderStatusModal.orderID : null;
  const onClose = modalsData.shopOrderStatusModal.onClose;

  const getOrderStatusData = useCallback((orderStatusID: any) => {
    return configurationData.stockOrderStatuses.filter((item: any) => item.orderStatusID === orderStatusID).length === 0 ? "" : configurationData.stockOrderStatuses.find((item: any) => item.orderStatusID === orderStatusID);
  }, [configurationData.stockOrderStatuses]);
  
  const [state, setState] = useStates({
    isLoading: true,
    orderData: {},
    selectedStatus: {
      orderStatusID: 0,
      name: '',
    },
    note: "",
  });

  const getStockOrderStatues = () => {
    const stockOrderStatuses = configurationData.stockOrderStatuses;
    const currentStatus = state.orderData.orderStatusID;
    const statusMap: any = {
      1: stockOrderStatuses.map((item: any) => { return item.orderStatusID; }),
      2: [2, 3, 4, 5, 6],
      3: [2, 3, 7],
      4: [4, 5, 6],
      5: [5, 6],
      6: [6],
      7: [7]
    };
    return statusMap[currentStatus].length === 0 ? [] : stockOrderStatuses.filter((item: any) => statusMap[currentStatus].includes(item.orderStatusID));
  };

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      onClose: null,
      orderID: null,
    };
    dispatch(setShopOrderStatusModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const getOrder = useCallback(() => {
    setState("isLoading", true);
    const settings = {
      orderID: orderID, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          setState("orderData", result.data.orders[0]);
          setState("selectedStatus", getOrderStatusData(result.data.orders[0].orderStatusID));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, setState, orderID, getOrderStatusData]);

  const handleSetOrderStatuses = (value: any) => {
    setState("selectedStatus", value);
  };

  const handleUpdate = () => {
    const settings = {
      orderStatusID: state.selectedStatus.orderStatusID,
      note: state.note,
    };
    stockService && stockService.editOrder(orderID, settings).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          createNotification(t("stock_order_updated"), "success");
          const newOrders = stockData.orders.map((item: any) => {
            if(item.orderID === orderID) {
              return result.data.orders[0];
            } else {
              return item;
            }
          }).filter((item: any) => item !== null);
          dispatch(setStockOrders(newOrders));
        } else {
          createNotification(t("stock_order_not_updated"), "error");
        }
      } else {
        createNotification(t("stock_order_not_updated"), "error");
      }
    });
    onCloseModal();
    if(onClose) {
      onClose();
    }
  };

  useEffect(() => {
    getOrder();
  }, [getOrder], []);

  const handleInput = (name: any, value: any) => {
    setState(name, value);
  };

  return (isValid && !state.isLoading) ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "shopOrderStatusModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_order_change_status')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Select label={t('stock_order_status')} className={classes.select} selected={state.selectedStatus} setSelected={handleSetOrderStatuses} items={getStockOrderStatues()} allowClear={false}/>
          <Input label={t('stock_order_update_note')} name='note' multiline={true} value={state.note} onChange={handleInput}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleUpdate} disabled={state.selectedStatus.orderStatusID === state.orderData.orderStatusID} dataCy="addToCartButton">
            {t('stock_update_order')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default ShopOrderStatusModal;