import CircleIcon from '@mui/icons-material/Circle';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';

interface Props {
  size?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > span': {
      fontSize: '13px',
      color: theme.colors.grey[650],
    },
  },
  root: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  substitutionPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },
  substitutionPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },  
  attendancePartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: theme.colors.primaryBlue[500],
    }, 
  },
  attendancePartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: theme.colors.primaryBlue[500],
    }, 
  }, 
  createPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    opacity: '0',
    transition: 'opacity 0.25s',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },
  createPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    opacity: '0',
    transition: 'opacity 0.25s',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: '#00E5E5',
    }, 
  },  
  createImageMorning: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) scale(1.25)',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
    marginTop: '-3.5px',
    marginLeft: '0.25px',
    '& > svg': {
      width: '100%',
      height: '100%',
      color: theme.colors.white,
    },
  },
  createImageAfternoon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translateY(-50%) scale(1.25)',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
    marginTop: '-3.5px',
    marginRight: '1px',
    '& > svg': {
      width: '100%',
      height: '100%',
      color: theme.colors.white,
    },
  },
}));

type SubstitutionInfoType = {
  morningStatus?: any;
  afternoonStatus?: any;
  size?: any;
  text?: any;
};

const SubstitutionInfoItem: React.FunctionComponent<SubstitutionInfoType> = ({ morningStatus, afternoonStatus, size = 32, text }) => {
  const classes = useStyles({
    size: size,
  });
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
      {
        morningStatus === "substitution" ? (
          <div className={classes.substitutionPartAM}>
            <CircleIcon/>
          </div>
        ) : morningStatus === "attendance" ? (
          <div className={classes.attendancePartAM}>
            <CircleIcon/>
          </div>
        ) : morningStatus === "create" ? (
          <div className={classes.createPartAM}>
            <CircleIcon/>
            <span className={classes.createImageMorning}>
              <SVG src="plus"/>
            </span>
          </div>
        ) : null
      }
      {
        afternoonStatus === "substitution" ? (
          <div className={classes.substitutionPartPM}>
            <CircleIcon/>
          </div>
        ) : afternoonStatus === "attendance" ? (
          <div className={classes.attendancePartPM}>
            <CircleIcon/>
          </div>
        ) : afternoonStatus === "create" ? (
          <div className={classes.createPartPM}>
            <CircleIcon/>
            <span className={classes.createImageAfternoon}>
              <SVG src="plus"/>
            </span>
          </div>
        ) : null
      }
      </div>
      {
        text ? (
          <span>{htmlParse(text)}</span>
        ) : null
      }
    </div>
  );
}

export default SubstitutionInfoItem;