import * as MainService from '../../services/main.service';
import config from 'src/constants/config';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { createNotification } from '../../utils/createNotification';
import { ObjectType } from 'src/types/types';
import { setIsFailed, setIsLanguageChanging, setIsLanguageLoaded } from '../../store/actions/loading.actions';
import { setIsLanguageFirstLoaded } from '../../store/actions/language.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';

interface Props {
  children: any;
};

const LanguageController: React.FunctionComponent<Props> = (props:Props) => {

  const dispatch = useAppDispatch();
  const documentEl: any = document;
  const languageData = useAppSelector((state: any) => state.language);
  const language = languageData.language.toLowerCase();
  const { t } = useTranslation();
  const windowHandler: any = window;
     
  const loadLanguage = useCallback(() => {
    windowHandler.isLanguageReady = false;
    const hasLanguageLoaded: boolean = i18next.hasResourceBundle(language, 'dictionaries');
    const onlyLocalLanguages = config.APP_ONLY_LOCAL_LANGUAGES;
    if(hasLanguageLoaded) {
      if(!languageData.isFirstLoaded) {
        dispatch(setIsLanguageFirstLoaded(true));
      }
      i18next.changeLanguage(language);
      if(languageData.isFirstLoaded) {
        createNotification(t("language_changed"), "success");
      }
      document.documentElement.setAttribute('lang', language);
      setTimeout(() => {
        dispatch(setIsLanguageLoaded(true));
        dispatch(setIsLanguageChanging(false));
        documentEl.querySelector('meta[name="description"]').setAttribute("content", t('twigsee_since'));
        windowHandler.isLanguageReady = true;
      }, 100);
    } else {
      if(onlyLocalLanguages) {
        i18next.changeLanguage(language);
        dispatch(setIsLanguageFirstLoaded(true));
        document.documentElement.setAttribute('lang', language);
        setTimeout(() => {
          dispatch(setIsLanguageLoaded(true));
          dispatch(setIsLanguageChanging(false));
          documentEl.querySelector('meta[name="description"]').setAttribute("content", t('twigsee_since'));
          windowHandler.isLanguageReady = true;
        }, 100);
      } else {
        MainService && MainService.getLanguage(language).then((result) => {
          const phrases: ObjectType = result.data.dictionaries[0].phrases;
          if(!languageData.isFirstLoaded) {
            dispatch(setIsLanguageFirstLoaded(true));
          }
          i18next.addResourceBundle(language, 'dictionaries', phrases);
          i18next.loadNamespaces("dictionaries");
          i18next.setDefaultNamespace("dictionaries");
          i18next.changeLanguage(language);
          if(languageData.isFirstLoaded) {
            setTimeout(() => {
              createNotification(t("language_changed"), "success");
            }, 100);
          }
          document.documentElement.setAttribute('lang', language);
          setTimeout(() => {
            dispatch(setIsLanguageLoaded(true));
            dispatch(setIsLanguageChanging(false));
            documentEl.querySelector('meta[name="description"]').setAttribute("content", t('twigsee_since'));
            windowHandler.isLanguageReady = true;
          }, 100);
        }).catch(() => {
          dispatch(setIsLanguageLoaded(false));
          dispatch(setIsLanguageChanging(false));
          dispatch(setIsLanguageFirstLoaded(false));     
          dispatch(setIsFailed("dictionaries"));
          windowHandler.isLanguageReady = false;
        });
      }
    }  
  }, [dispatch, language, languageData.isFirstLoaded, t, windowHandler, documentEl]);   

  useEffect(() => {
    i18next.removeResourceBundle(language, "dictionaries");
  }, [language], []);

  useEffect(() => {
    loadLanguage();     
  }, [languageData.language, loadLanguage], [languageData.language]);
  
  return props.children;
};

LanguageController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default LanguageController;