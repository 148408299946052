import * as UserService from 'src/services/user.service';
import FirebaseNotification from 'src/components/Layouts/FirebaseNotification';
import NativeNotification from '../../components/Layouts/NativeNotification';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';
import { createFirebaseNotification } from 'src/utils/createFirebaseNotification';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppData, setAppData } from 'src/utils/useApp';
import { isCypress } from 'src/utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { IsLocationPart } from 'src/utils/useLocation';
import { isNotificationSupported, onMessageListener, requestPermission } from '../../utils/useFirebase';
import { setCommunicationNotificationsCount } from 'src/store/actions/communication.actions';
import { setFirebasePermission } from 'src/store/actions/firebasenotification.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  children: any;
};

interface ClassProps {
  firebaseNotificationPosition: any;
};

const useStyles = createUseStyles((theme: any) => ({
  notificationCenter: {
    position: 'fixed',
    top: '0rem',
    right: '0rem',
    zIndex: theme.zIndex.notification,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
    maxHeight: 'calc(100vh - 32px)',
    overflow: 'auto',
  },
  firebaseNotificationCenter: {
    position: 'fixed',
    top: (props: ClassProps) => {
      if(props.firebaseNotificationPosition === 'top-left' || props.firebaseNotificationPosition === 'top-center' || props.firebaseNotificationPosition === 'top-right') return '0px';
      else return '';
    },
    left: (props: ClassProps) => {
      if(props.firebaseNotificationPosition === 'top-left' || props.firebaseNotificationPosition === 'bottom-left') return '0px';
      else if(props.firebaseNotificationPosition === 'top-center' || props.firebaseNotificationPosition === 'bottom-center') return '50%';
      else return '';
    },
    right: (props: ClassProps) => {
      if(props.firebaseNotificationPosition === 'top-right' || props.firebaseNotificationPosition === 'bottom-right') return '0px';
      else return '';
    },
    bottom: (props: ClassProps) => {
      if(props.firebaseNotificationPosition === 'bottom-left' || props.firebaseNotificationPosition === 'bottom-center' || props.firebaseNotificationPosition === 'bottom-right' ) return '1px';
      else return '';
    },
    transform: (props: ClassProps) => {
      if(props.firebaseNotificationPosition === 'top-center' || props.firebaseNotificationPosition === 'bottom-center') return 'translateX(-50%)';
      else return '';
    },
    padding: '16px 0',
    zIndex: theme.zIndex.notification,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 32px)',
    overflow: 'auto',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    gap: '16px',
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
}));

const NotificationController: React.FunctionComponent<Props> = (props:Props) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const firebaseNotificationList = useAppSelector((state: any) => state.firebasenotification.list);
  const firebaseNotificationPositionSelector = useAppSelector((state: any) => state.firebasenotification.position);
  const firebaseNotificationStatus = useAppSelector((state: any) => state.firebasenotification.permission);
  const notificationList = useAppSelector((state: any) => state.notification.list);
  const userData = useAppSelector((state: any) => state.user);
  const userAccess = userData.userAccess;
  const notificationCenterRef: any = useRef(null);
  const firebaseNotificationCenterRef: any = useRef(null);
  const browserNotificationsStatus = isNotificationSupported ? Notification.permission : false;
  const savedData = getAppData();
  const webAppNotifications = savedData.notifications;

  const [firebaseNotificationPosition, setFirebaseNotificationPosition] = useState(firebaseNotificationPositionSelector);

  const classes = useStyles({
    firebaseNotificationPosition: firebaseNotificationPosition,
  });

  if(userData.userStatus === "loggedIn" && isNotificationSupported) {
    onMessageListener().then((payload: any) => {
      if(IsLocationPart("communication")) {
        if(payload.data && payload.data.type && payload.data.type === "chat") {
          return;
        }
      } else {
        if(payload.data && payload.data.type && payload.data.type === "chat") {
          webAppNotificationGet();
        }
      }
      createFirebaseNotification(payload?.notification?.title, payload?.notification?.body, 'default', payload?.notification?.image);
    });
  }

  const webAppNotificationInit = useCallback(async () => {
    if(isNotificationSupported) {
      const data: any = await requestPermission();
      const permission = data[0];
      const fcmToken = data[1];
      if(permission === 'granted') {
        UserService.setNotificationFcmToken(fcmToken).then(() => {
          const notifications = { ...webAppNotifications, permission: permission, fcmToken: fcmToken};
          setAppData({notifications: notifications});
          dispatch(setFirebasePermission(permission));
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("notification_fcm_token_not_set"), "error");
          dispatch(setFirebasePermission(permission));
        });
      } else {
        if(!isCypress()) {
          createNotification(t("notification_fcm_token_not_set"), "error");
        }
        const notifications = { ...webAppNotifications, permission: permission, fcmToken: ''};
        setAppData({notifications: notifications});
        dispatch(setFirebasePermission(permission));
      }
    }
  }, [dispatch, t, webAppNotifications]);  

  const webAppNotificationCheck = useCallback(async () => {
    const permission = browserNotificationsStatus === 'default' ? browserNotificationsStatus : (firebaseNotificationStatus === 'denied' ? firebaseNotificationStatus : browserNotificationsStatus);
    const notifications = { ...webAppNotifications, permission: permission, fcmToken: ''};
    setAppData({notifications: notifications});
    dispatch(setFirebasePermission(permission));
  }, [dispatch, browserNotificationsStatus, firebaseNotificationStatus, webAppNotifications]);  

  const webAppNotificationGet = useCallback(async () => {
    if(userData.userStatus === "loggedIn" && userAccess.communication) {
      UserService.getNotificationData().then((result: any) => {
        if(result && result.data) {
          const response = result.data;
          const unreadThreads = (response.modules && response.modules.communication && response.modules.communication.unreadThreads) ? response.modules.communication.unreadThreads : 0;
          dispatch(setCommunicationNotificationsCount(unreadThreads));
          setTimeout(() => {
            webAppNotificationGet();
          }, 60000);
        }
      }).catch(() => {
        setTimeout(() => {
          webAppNotificationGet();
        }, 30000);
      });
    }
  }, [dispatch, userData.userStatus, userAccess.communication]);  

  useEffect(() => {
    setFirebaseNotificationPosition(firebaseNotificationPositionSelector);
  }, [firebaseNotificationPositionSelector, setFirebaseNotificationPosition], [firebaseNotificationPositionSelector]);

  useEffect(() => {
    if(firebaseNotificationList.length > 0 && firebaseNotificationCenterRef.current) {
      firebaseNotificationCenterRef.current.scrollTo({
        top: 0,
      });
    }
  }, [firebaseNotificationList], [firebaseNotificationList]);

  useEffect(() => {
    if(notificationList.length > 0 && notificationCenterRef.current) {
      notificationCenterRef.current.scrollTo({
        top: 0,
      });
    }
  }, [notificationList], [notificationList]);

  useEffect(() => {
    if(userData.userStatus === "loggedIn" && isNotificationSupported && browserNotificationsStatus === 'granted' && firebaseNotificationStatus === 'granted') {
      webAppNotificationInit();
    }
  }, [userData.userStatus, firebaseNotificationStatus, browserNotificationsStatus, webAppNotificationInit], [userData.userStatus, firebaseNotificationStatus]);

  useEffect(() => {
    if(browserNotificationsStatus && userData.userStatus === "loggedIn") {
      webAppNotificationCheck();
    }
  }, [browserNotificationsStatus, userData.userStatus, webAppNotificationCheck], [userData.userStatus, browserNotificationsStatus]);

  useEffect(() => {
    if(userData.userStatus === "loggedIn") {
      webAppNotificationGet();
    }
  }, [userData.userStatus, webAppNotificationGet], [userData.userStatus]);
      
  return (
    <>
      <div className={classes.notificationCenter} ref={notificationCenterRef}>
        <div className={classes.innerWrapper}>
          {
            notificationList.map((notification: any, key: any) => (
              <NativeNotification key={`k_${key}`} date={notification.date} type={notification.type} timeout={notification.timeout}>{notification.text}</NativeNotification>  
            ))
          }
        </div>
      </div>
      {
        (userData.userStatus === "loggedIn" && isNotificationSupported && firebaseNotificationStatus === 'granted') ? (
          <div className={classes.firebaseNotificationCenter} ref={firebaseNotificationCenterRef}>
            <div className={classes.innerWrapper}>
              {
                firebaseNotificationList.map((notification: any, key: any) => (
                  <FirebaseNotification key={`k_${key}`} date={notification.date} title={notification.title} text={notification.text} image={notification.image}/>  
                ))
              }
            </div>
          </div>
        ) : null
      }
      {props.children}
    </>
  );
};

NotificationController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default NotificationController;