import AuthenticatedImage from '../../../Items/AuthenticatedImage';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useState } from '../../../../utils/useState';
import { useEffect } from 'src/utils/useEffect';

interface Props {
  zoomLevel: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    margin: '0 auto',
    '& > div': {
      position: 'relative',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      transform: (props: Props) => 'scale(' + props.zoomLevel + ')',
    },
    '& > span': {
      maxHeight: '100%',
      height: 'auto',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type RenderPhotoType = {
  mediaData: any;
  zoomLevel: any;
  setIsDownloadable: any;
  handleSecureClick: any;
  setMetadata: any;
};

const RenderPhoto: React.FunctionComponent<RenderPhotoType> = ({ mediaData, zoomLevel, setIsDownloadable, handleSecureClick, setMetadata}) => {

  const classes = useStyles({
    zoomLevel: zoomLevel,
  });
  const mediaID = useAppSelector((state: any) => state.modals).timelineGalleryModal.mediaID;
  const [lastMediaID, setLastMediaID] = useState(null);

  const mediaUrl = mediaData.fullsizeLink;
  
  useEffect(() => {
    setIsDownloadable(true);
    setLastMediaID(mediaID);
    handleSecureClick();
  }, [mediaData, handleSecureClick, mediaID, setIsDownloadable], [mediaData]);

  const onImageLoad = (image: any) => {
    handleSecureClick();
    const img = new Image();
    img.src = image.image;
    img.onload = function() {
      const size = Math.ceil(image.base64.length / 4) * 3;
      const settings = {
        width: img.width,
        height: img.height,
        size: size,
      };
      setMetadata(settings);
    };
  };

  return lastMediaID === mediaID ? (
    <AuthenticatedImage thumbLink={mediaData.thumbLink} fullsizeLink={mediaUrl} preferQuality="fullsize" useThumb={true} className={classes.root} onLoad={onImageLoad} useLoader={true}/>
  ) : (
    <div className={classes.loading}>
      <CircularProgress className={classes.spinner}/>
    </div>
  );
};

export default RenderPhoto;