import React from 'react';

const useMousePosition = () => {
  const mousePosition = React.useRef({ x: null, y: null });
  const updateMousePosition = (e: any) => {
    mousePosition.current = { x: e.clientX, y: e.clientY };
  };
  
  React.useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};

export default useMousePosition;