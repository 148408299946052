import CheckIcon from '@mui/icons-material/Check';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useState } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
  },
  typesContainer: {
    width: "calc(100% - 40px)",
    padding: "20px",
    display: "flex",
    flexDirection: 'column',
    gap: '12px',
  },
  typeItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '24px',
    padding: '20px 24px',
    textTransform: 'unset',
    '& > svg': {
      color: theme.colors.grey[700],
      width: '30px',
      height: '30px',
    },
    '& > span:first-of-type': {
      color: theme.colors.grey[700],
      fontSize: '18px',
      fontWeight: '500',
      marginLeft: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },   
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0',
    marginLeft: 'auto',
    '& > span.check': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primaryBlue[500],
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.primaryBlue[500],
      marginRight: '0px',
      marginLeft: 'auto',
      borderRadius: '100%',
      '& > svg': {
        color: theme.colors.white,
        transform: 'scale(0.7)'
      },
    },
    '& > span.nocheck': {
      display: 'block',
      backgroundColor: theme.colors.white,
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[350],
      marginRight: '0px',
      marginLeft: 'auto',
      borderRadius: '100%',
    },
  },
  count: {
    backgroundColor: theme.colors.white,
    color: '#EB4D63 !important',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px !important',
    lineHeight: '12px',
    fontWeight: '600',
  },
}));

type ModalType = {
  onSave: any;
  onClose: any;
  selected: any;
};

const FiltersGalleryTypesModal: React.FunctionComponent<ModalType> = ({onSave, onClose, selected}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedTypes, setSelectedTypes] = useState(selected);
  
  const onCloseModal = () => {
    onClose();
  };
  
  const handleToggleItem = (galleryType: any) => {
    let tempSelectedTypes = selectedTypes;
    if(selectedTypes.indexOf(galleryType) !== -1) {
      tempSelectedTypes = tempSelectedTypes.filter((tempSelectedType: any) => tempSelectedType !== galleryType);  
    } else {
      tempSelectedTypes = [...tempSelectedTypes, galleryType];
    }
    tempSelectedTypes.sort((a: any, b: any) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    setSelectedTypes(tempSelectedTypes);
  };
  
  const handleClear = (e: any) => {
    e.stopPropagation();
    setSelectedTypes([]);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleSave = (e: any) => {
    e.stopPropagation();
    onSave(selectedTypes);
    onCloseModal();
  };
  
  return (
    <Modal
      open={true} 
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "filtersGalleryTypesModal" : null}>
        <div className={classes.header}>
          <p>{t('gallery_type')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div>
          <div className={classes.typesContainer}> 
            <Button className={classes.typeItem} onClick={() => handleToggleItem("photo")} data-cy={isCypress() ? "galleryTypeButtonPhoto" : null}>
              <SVG src="image"/>
              <span>{t('photos')}</span>
              <div className={classes.status}>
                {
                  selectedTypes.indexOf("photo") !== -1 ? (
                    <span className="check">
                      <CheckIcon/>
                    </span>
                  ) : (
                    <span className="nocheck"/>
                  )
                }
              </div>    
            </Button>
            <Button className={classes.typeItem} onClick={() => handleToggleItem("video")} data-cy={isCypress() ? "galleryTypeButtonVideo" : null}>
              <SVG src="video"/>
              <span>{t('videos')}</span>
              <div className={classes.status}>
                {
                  selectedTypes.indexOf("video") !== -1 ? (
                    <span className="check">
                      <CheckIcon/>
                    </span>
                  ) : (
                    <span className="nocheck"/>
                  )
                }
              </div>    
            </Button>
            <Button className={classes.typeItem} onClick={() => handleToggleItem("attachment")} data-cy={isCypress() ? "galleryTypeButtonAttachment" : null}>
              <SVG src="file"/>
              <span>{t('files')}</span>
              <div className={classes.status}>
                {
                  selectedTypes.indexOf("attachment") !== -1 ? (
                    <span className="check">
                      <CheckIcon/>
                    </span>
                  ) : (
                    <span className="nocheck"/>
                  )
                }
              </div>    
            </Button>
          </div>
        </div>
        <div className={classes.footer}>
          {
            selectedTypes.length !== 0 ? (
              <NormalButton buttonType="clear" startIcon={<span className={classes.count}>{selectedTypes.length}</span>} onClick={handleClear} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          <NormalButton buttonType={JSON.stringify(selected) === JSON.stringify(selectedTypes) ? "disabled" : "primary"} disabled={JSON.stringify(selected) === JSON.stringify(selectedTypes)} onClick={handleSave} dataCy="doneButton">
            {t("done")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default FiltersGalleryTypesModal;