import AuthenticatedImage from '../AuthenticatedImage';
import CalendarGallery from 'src/components/Gallery/CalendarGallery';
import Clamp from 'react-multiline-clamp';
import DateFormat from 'src/utils/dateFormat';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import moment from 'src/utils/moment';
import React, { useCallback } from 'react';
import SVG from '../../Images/SvgRenderer';
import theme from '../../../ui/theme';
import { calendarEventTypes } from 'src/utils/useCalendar';
import { convertToSnakeCase, isKey, removeDuplicatesJSON } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { Popover } from '@mui/material';
import { setCalendarEventHandleModal, setChildCardModal, setConfirmModal, setEmployeeModal, setPostDetailModal } from 'src/store/actions/modals.actions';
import { setCalendarEvents, setCalendarHoverEvent } from 'src/store/actions/calendar.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { weekdays } from 'src/constants/weekdays';

interface Props {
  icon: any;
  backgroundColor: any;
  backgroundColorHover: any;
  textColor: any;
  overlap: any;
};

const useStyles = createUseStyles((theme: any) => ({
  eventWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props: Props) => {
      if(props.overlap === 'full') return '100%';
      else if(props.overlap === 'left' || props.overlap === 'right') return 'calc(100% - 4px)';
      else return 'calc(100% - 8px)';
    },
    borderRadius: (props: Props) => {
      if(props.overlap === 'full') return '0px';
      else if(props.overlap === 'left') return '12px 0 0 12px';
      else if(props.overlap === 'right') return '0 12px 12px 0';
      else return '12px';
    },
    paddingLeft: (props: Props) => {
      if(props.overlap === 'left') return '4px';
      else return '';
    },
    paddingRight: (props: Props) => {
      if(props.overlap === 'right') return '4px';
      else return '';
    },
  },
  event: {
    backgroundColor: (props: Props) => props.backgroundColor,
    borderRadius: 'inherit',
    width: 'calc(100% - 16px)',
    padding: '0 8px',
    height: '30px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    position: 'relative',
    color: (props: Props) => props.textColor,
    transition: 'background-color 0.25s',
    cursor: 'pointer',
    '& > svg': {
      display: 'block',
      width: '16px',
      height: '16px',
      minWidth: '16px',
      minHeight: '16px',
    },
    '&:hover': {
      backgroundColor: (props: Props) => props.backgroundColorHover,
    },
    '&.hover': {
      backgroundColor: (props: Props) => props.backgroundColorHover,
    },
  },
  name: {
    maxWidth: '100%',
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    wordBreak: 'break-all',
    margin: 'auto 0',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      display: 'flex',
      flexDirection: 'column',
      width: '400px',
      boxShadow: theme.shadows[1],
      borderRadius: '12px',
      backgroundColor: theme.colors.white,
    },
  },
  popoverHeader: {
    display: 'block',
    width: '100%',
    height: '100px',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100px',
    backgroundColor: (props: Props) => props.backgroundColor,
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '-10px',
      left: '-10px',
      right: '-10px',
      bottom: '-10px',
      backgroundImage: (props: Props) => `url("/resources/icons/${props.icon}.svg")`,
      backgroundSize: '26px 26px',
      backgroundRepeat: 'space',
      filter: 'invert(100%)',
      zIndex: '0',
      opacity: '0.7',
    }
  },
  popoverButtons: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    display: 'flex',
    gap: '8px',
    zIndex: '1',
  },
  popoverButton: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    '& > svg': {
      width: '95%',
      height: '95%',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
  },
  popoverDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 32px)',
    padding: '16px',
    gap: '8px',
    maxHeight: '500px',
    overflow: 'auto',
  },
  eventName: {
    fontSize: '18px',
    fontWeight: '500',
    wordBreak: 'break-all',
  },
  popoverDetail: {
    display: 'flex',
    width: '100%',
  },
  popoverDetailLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '0 0 32px',
    width: '32px',
    padding: '0 8px',
    '&.iconTop': {
      alignItems: 'flex-start',
      paddingTop: '2px',
    },
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  popoverDetailRight: {
    flex: '0 0 calc(100% - 48px)',
    width: 'calc(100% - 48px)',
  },
  popoverEventIcon: {
    width: '20px',
    height: '20px',
    borderRadius: '6px',
    backgroundColor: (props: Props) => props.backgroundColor,
  },
  eventDates: {
    display: 'flex',
    gap: '8px',
  },
  eventDay: {
    display: 'inline-flex',
  },
  eventTime: {
    display: 'inline-flex',
  },
  eventDayTime: {
    display: 'inline-flex',
  },
  eventDelimiter: {
    display: 'inline-flex',
  },
  classList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
  },
  theClassWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '100%',
  },
  theClass: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '800',
    wordBreak: 'break-all',
    paddingBottom: '8px',
  },
  guestList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: '100%',
  },
  guest: {
    display: 'flex',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '600',
    wordBreak: 'break-all',
    '&.noLink': {
      color: theme.colors.black,
      fontWeight: '400',
      cursor: 'auto',
    },
  },
  guestChildPhoto: {
    position: 'relative',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    maxWidth: '20px',
    maxHeight: '20px',
    borderRadius: '100%',
  },
  guestUserPhoto: {
    position: 'relative',
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
    maxWidth: '20px',
    maxHeight: '20px',
    borderRadius: '6px',
  },
  description: {
    fontSize: '14px',
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  count: {
    display: 'block',
    paddingBottom: '8px',
  },
}));

type CalendarEventItemType = {
  data: any;
  date: any;
  overlaping: boolean;
};

const CalendarEventItem: React.FunctionComponent<CalendarEventItemType> = ({ data, date = moment(), overlaping = true }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const calendarService = useAppSelector((state: any) => state.services).calendarService;

  const defaultChildren = dataData.children;

  const allEvents = calendarData.events;
  const hoverEvent = calendarData.hoverEvent;
  const eventID = data.eventID;
  const eventPopover = getElementID();

  const eventOnlyClasses = Array.isArray(data.classToChild) ? removeDuplicatesJSON(data.classToChild.filter((item: any) => !item.childID).map((item: any) => { return { classID: item.classID }; })) : [];
  const eventChildren = Array.isArray(data.classToChild) ? removeDuplicatesJSON(data.classToChild.filter((item: any) => item.childID).map((item: any) => { return { childID: item.childID }; })) : [];
  const allChildren = Array.isArray(data.classToChild) ? removeDuplicatesJSON([eventOnlyClasses.map((item: any) => { return defaultChildren.filter((dataItem: any) => dataItem.classID.indexOf(item.classID) !== -1).map((dataItem: any) => { return { classID: item.classID, childID: dataItem.childID }; }); }).flat(), eventChildren].flat().map((item: any) => { return { classID: item.classID, childID: item.childID }; })) : [];

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const [state, setState] = useStates({
    anchorEl: null,
    isVisible: false,
  });

  const handleClick = (e: any) => {
    setState("anchorEl", e.currentTarget);
    setState("isVisible", true);
  };

  const handleClose = () => {
    setState("anchorEl", null);
    setTimeout(() => {
      setState("isVisible", false);
    }, theme.transitions.duration.complex);
  };

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getEmployeeData = useCallback((employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  }, [dataData.employees]);

  const getIcon = useMemo(() => {
    if(calendarEventTypes.filter((item: any) => item.value === data.type).length !== 0) {
      return calendarEventTypes.find((item: any) => item.value === data.type).icon;
    } else {
      return "question-mark-circle";
    }
  }, [data.type]);

  const getIsDayOverlaping = () => {
    if(eventID === 0 || !overlaping) {
      return 'none';
    } else {
      if(moment(data.dateTimeFrom).isBefore(moment(date), 'day') && moment(data.dateTimeTo).isAfter(moment(date),'day')) {
        return 'full';
      } else if(!moment(data.dateTimeFrom).isBefore(moment(date), 'day') && moment(data.dateTimeTo).isAfter(moment(date),'day')) {
        return 'left';
      } else if(moment(data.dateTimeFrom).isBefore(moment(date), 'day') && !moment(data.dateTimeTo).isAfter(moment(date),'day')) {
        return 'right';
      } else {
        return 'none';
      }
    }
  };

  const getTypeData = useCallback((type: any) => {
    return calendarEventTypes.filter((item: any) => item.value === type).length === 0 ? { backgroundColor: theme.colors.grey[400], backgroundColorHover: theme.colors.grey[500], color: theme.colors.black } : calendarEventTypes.find((item: any) => item.value === type);
  }, []);

  const classes = useStyles({
    icon: getIcon,
    backgroundColor: getTypeData(data.type).backgroundColor,
    backgroundColorHover: getTypeData(data.type).backgroundColorHover,
    textColor: getTypeData(data.type).textColor,
    overlap: getIsDayOverlaping(), 
  });

  const getDayInfo = useMemo(() => {
    if(moment(data.dateTimeFrom).isSame(moment(data.dateTimeTo), "date")) {
      if(moment(data.dateTimeFrom).isSame(moment(data.dateTimeTo))) {
        return (
          <span className={classes.eventDay}>
            {t(weekdays[moment(data.dateTimeFrom).isoWeekday() - 1].toLowerCase())}
            ,&nbsp;
            {DateFormat(moment(data.dateTimeFrom),"daymonthname", languageData, t)}
          </span>
        );
      } else {
        return (
          <>
            <span className={classes.eventDay}>
              {t(weekdays[moment(data.dateTimeFrom).isoWeekday() - 1].toLowerCase())}
              ,&nbsp;
              {DateFormat(moment(data.dateTimeFrom),"daymonthname", languageData, t)}
            </span>
            <span className={classes.eventDelimiter}>
              &bull;
            </span>
            <span className={classes.eventTime}>
              {moment(data.dateTimeFrom).format("HH:mm")}
              –
              {moment(data.dateTimeTo).format("HH:mm")}
            </span>
          </>
        );
      }
    } else {
      if(moment(data.dateTimeFrom, 'YYYY-MM-DD HH:mm:ss').isValid() && moment(data.dateTimeTo, 'YYYY-MM-DD HH:mm:ss').isValid()) {
        return (
          <>
            <span className={classes.eventDayTime}>
              {DateFormat(moment(data.dateTimeFrom),"daymonthname", languageData, t)}
              ,&nbsp;
              {moment(data.dateTimeFrom).format("HH:mm")}
              &nbsp;
              –
              &nbsp;
              {DateFormat(moment(data.dateTimeTo),"daymonthname", languageData, t)}
              ,&nbsp;
              {moment(data.dateTimeTo).format("HH:mm")}
            </span>
          </>
        );
      } else {
        return (
          <span className={classes.eventDay}>
            {DateFormat(moment(data.dateTimeFrom),"day", languageData, t)}
            –
            {DateFormat(moment(data.dateTimeFrom),"daymonthname", languageData, t)}
          </span>
        );
      }
    }
  }, [data, classes, t, languageData], [data]);

  const getUsers = useMemo(() => {
    if(Array.isArray(data.userID)) {
      return data.userID.filter((item: any) => getUserData(item).length !== 0);
    } else {
      return [];
    }
  }, [data, getUserData], [data]);

  const getEmployees = useMemo(() => {
    if(Array.isArray(data.employeeID)) {
      return data.employeeID.filter((item: any) => getEmployeeData(item).length !== 0);
    } else {
      return [];
    }
  }, [data, getEmployeeData], [data]);

  const handleChildDetail = (childID: any) => {
    const settings = {
      isOpen: true,
      childID: childID,
    };
    dispatch(setChildCardModal(settings));
  };

  const handleEmployeeDetail = (employeeID: any) => {
    const settings = {
      isOpen: true,
      employeeID: employeeID,
    };
    dispatch(setEmployeeModal(settings));
  };

  const handlePost = (e: any) => {
    e.stopPropagation();
    const settings = {
      isOpen: true,
      postID: data.postID,
    };
    dispatch(setPostDetailModal(settings)); 
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      date: null,
      dateTime: null,
      eventID: data.eventID,
    };
    dispatch(setCalendarEventHandleModal(settings));
  };

  const handleDelete = () => {
    const onAccept = () => {
      calendarService && calendarService.deleteEvent(eventID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("calendar_event_deleted"), "success");
            const newItems = allEvents.map((item: any) => {
              if(item.eventID === eventID) {
                return null;
              } else {
                return item;
              }
            }).filter((item: any) => item !== null);
            dispatch(setCalendarEvents(newItems));
            handleClose();
          } else {
            createNotification(t("calendar_event_not_deleted"), "error");
          }
        } else {
          createNotification(t("calendar_event_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("calendar_event_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('calendar_event_delete'),
      content: t('calendar_event_delete_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleHoverSet = () => {
    if(eventID !== 0) {
     dispatch(setCalendarHoverEvent(data.eventID));
    }
  };

  const handleHoverUnset = () => {
    if(eventID !== 0) {
      dispatch(setCalendarHoverEvent(null));
    }
  };

  return (
    <div className={classes.eventWrapper}>
      <div className={`${classes.event} ${hoverEvent === data.eventID ? 'hover' : null}`} onClick={handleClick} onMouseEnter={handleHoverSet} onMouseLeave={handleHoverUnset}>
        <SVG src={getIcon}/>  
        <Clamp withTooltip lines={1}>
          <span className={classes.name}>{data.name}</span>
        </Clamp> 
      </div> 
      {
        state.isVisible ? (
          <Popover className={classes.popover} id={eventPopover} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'center', horizontal: 'center' }}>
            <div className={classes.popoverHeader}>
              <div className={classes.popoverButtons}>
                {
                  data.isEditable ? (
                    <>
                      <IconButton className={classes.popoverButton} iconColor={theme.colors.white} tooltip={t('calendar_event_edit')} size="small" onClick={handleEdit}>
                        <SVG src="edit"/>
                      </IconButton>
                      <IconButton className={classes.popoverButton} iconColor={theme.colors.white} tooltip={t('calendar_event_delete')} size="small" onClick={handleDelete}>
                        <SVG src="trash"/>
                      </IconButton>
                    </>
                  ) : null
                }
                {
                  (data.postID && data.postID !== 0 && data.postID !== "") ? (
                    <IconButton className={classes.popoverButton} iconColor={theme.colors.white} tooltip={t('post')} size="small" onClick={handlePost}>
                      <SVG src="posts"/>
                    </IconButton>
                  ) : null
                }
                <IconButton className={classes.popoverButton} iconColor={theme.colors.white} tooltip={t('close')} size="small" onClick={handleClose}>
                  <SVG src="close"/>
                </IconButton>
              </div>
            </div>
            <div className={classes.popoverDetails}>
              <div className={classes.popoverDetail}>
                <div className={classes.popoverDetailLeft}>
                  <span className={classes.popoverEventIcon}/>
                </div>
                <div className={classes.popoverDetailRight}>
                  <span className={classes.eventName}>
                    {data.name}
                  </span>
                  <div className={classes.eventDates}>{getDayInfo}</div>
                </div>
              </div>
              <div className={classes.popoverDetail}>
                <div className={classes.popoverDetailLeft}>
                  <SVG src="calendar"/>
                </div>
                <div className={classes.popoverDetailRight}>
                  {t(convertToSnakeCase(data.type))}
                </div>
              </div>
              {
                ((eventOnlyClasses.length === 1 && getClassData(eventOnlyClasses[0].classID).length !== 0) || eventOnlyClasses.length > 1) ? (
                  <div className={classes.popoverDetail}>
                    <div className={`${classes.popoverDetailLeft} iconTop`}>
                      <SVG src="people"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      {
                        eventOnlyClasses.length > 1 ? (
                          <>
                            <span className={classes.count}>{eventOnlyClasses.length} {(eventOnlyClasses.length > 1 && eventOnlyClasses.length < 5) ? t('classes').toLowerCase() : t('classes_more').toLowerCase()}</span>
                            <div className={classes.classList}>
                              {
                                eventOnlyClasses.map((item: any, key: any) => {
                                  return Object.keys(getClassData(item.classID)).length > 0 ? (
                                    <div className={classes.theClassWrapper} key={`k_${key}`}>
                                      <span className={classes.theClass}>
                                        {htmlParse(getClassData(item.classID).name)}
                                      </span>
                                      <div className={classes.guestList}>
                                        {
                                          allChildren.filter((subItem: any) => subItem.classID === item.classID).map((subItem: any, key: any) => getChildData(subItem.childID).length !== 0 ? (
                                            <span className={classes.guest} onClick={() => handleChildDetail(subItem.childID)} key={`k_${key}`}>
                                              <AuthenticatedImage className={classes.guestUserPhoto} thumbLink={getChildData(subItem.childID).photo.thumbLink}/>
                                              {getChildData(subItem.childID).displayName}
                                            </span>
                                          ) : null)
                                        }
                                      </div>
                                    </div>
                                  ) : null;
                                })
                              }
                            </div>
                          </>
                        ) : (
                          <>
                            {
                              Object.keys(getClassData(eventOnlyClasses[0].classID)).length > 0 ? (
                                <>
                                  <span className={classes.theClass}>
                                    {htmlParse(getClassData(eventOnlyClasses[0].classID).name)}
                                  </span>
                                  <div className={classes.guestList}>
                                    {
                                      allChildren.filter((item: any) => item.classID === eventOnlyClasses[0].classID).map((item: any, key: any) => getChildData(item.childID).length !== 0 ? (
                                        <span className={classes.guest} onClick={() => handleChildDetail(item.childID)} key={`k_${key}`}>
                                          <AuthenticatedImage className={classes.guestChildPhoto} thumbLink={getChildData(item.childID).photo.thumbLink}/>
                                          {getChildData(item.childID).displayName}
                                        </span>
                                      ) : null)
                                    }
                                  </div>
                                </>
                              ) : null
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
              {
                ((eventChildren.length === 1 && getChildData(eventChildren[0].childID).length !== 0) || eventChildren.length > 1) ? (
                  <div className={classes.popoverDetail}>
                    <div className={`${classes.popoverDetailLeft} iconTop`}>
                      <SVG src="people"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      {
                        eventChildren.length > 1 ? (
                          <>
                            <span className={classes.count}>{eventChildren.length} {(eventChildren.length > 1 && eventChildren.length < 5) ? t('children').toLowerCase() : t('children_more').toLowerCase()}</span>
                            <div className={classes.guestList}>
                              {
                                eventChildren.map((item: any, key: any) => getChildData(item.childID).length !== 0 ? (
                                  <span className={classes.guest} onClick={() => handleChildDetail(item.childID)} key={`k_${key}`}>
                                    <AuthenticatedImage className={classes.guestChildPhoto} thumbLink={getChildData(item.childID).photo.thumbLink}/>
                                    {getChildData(item.childID).displayName}
                                  </span>
                                ) : null)
                              }
                            </div>
                          </>
                        ) : (
                          <span className={classes.guest} onClick={() => handleChildDetail(eventChildren[0].childID)}>
                            <AuthenticatedImage className={classes.guestChildPhoto} thumbLink={getChildData(eventChildren[0].childID).photo.thumbLink}/>
                            {getChildData(eventChildren[0].childID).displayName}
                          </span>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
              {
                ((getUsers.length === 1 && getUserData(getUsers[0]).length !== 0) || getUsers.length > 1) ? (
                  <div className={classes.popoverDetail}>
                    <div className={`${classes.popoverDetailLeft} iconTop`}>
                      <SVG src="employees"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      {
                        getUsers.length > 1 ? (
                          <>
                            <span className={classes.count}>{getUsers.length} {(getUsers.length > 1 && getUsers.length < 5) ? t('teachers').toLowerCase() : t('teachers_more').toLowerCase()}</span>
                            <div className={classes.guestList}>
                              {
                                getUsers.map((item: any, key: any) => getUserData(item).length !== 0 ? (
                                  <span className={`${classes.guest} noLink`} key={`k_${key}`}>
                                    <AuthenticatedImage className={classes.guestUserPhoto} thumbLink={getUserData(item).photo.thumbLink}/>
                                    {getUserData(item).firstname} {getUserData(item).surname}
                                  </span>
                                ) : null)
                              }
                            </div>
                          </>
                        ) : (
                          <span className={`${classes.guest} noLink`}>
                            <AuthenticatedImage className={classes.guestUserPhoto} thumbLink={getUserData(getUsers[0]).photo.thumbLink}/>
                            {getUserData(getUsers[0]).firstname} {getUserData(getUsers[0]).surname}
                          </span>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
              {
                ((getEmployees.length === 1 && getEmployeeData(getEmployees[0]).length !== 0) || getEmployees.length > 1) ? (
                  <div className={classes.popoverDetail}>
                    <div className={`${classes.popoverDetailLeft} iconTop`}>
                      <SVG src="employees"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      {
                        getEmployees.length > 1 ? (
                          <>
                            <span className={classes.count}>{getEmployees.length} {(getEmployees.length > 1 && getEmployees.length < 5) ? t('employees').toLowerCase() : t('employees_more').toLowerCase()}</span>
                            <div className={classes.guestList}>
                              {
                                getEmployees.map((item: any, key: any) => getEmployeeData(item).length !== 0 ? (
                                  <span className={classes.guest} onClick={() => handleEmployeeDetail(item)} key={`k_${key}`}>
                                    <AuthenticatedImage className={classes.guestUserPhoto} thumbLink={getEmployeeData(item).photo.thumbLink}/>
                                    {getEmployeeData(item).firstname} {getEmployeeData(item).surname}
                                  </span>
                                ) : null)
                              }
                            </div>
                          </>
                        ) : (
                          <span className={classes.guest} onClick={() => handleEmployeeDetail(getEmployees[0])}>
                            <AuthenticatedImage className={classes.guestUserPhoto} thumbLink={getEmployeeData(getEmployees[0]).photo.thumbLink}/>
                            {getEmployeeData(getEmployees[0]).firstname} {getEmployeeData(getEmployees[0]).surname}
                          </span>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
              {
                data.description ? (
                  <div className={classes.popoverDetail}>
                    <div className={classes.popoverDetailLeft}>
                      <SVG src="info-circle-outlined"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      <Clamp
                        lines={4}
                        maxLines={100}
                        withToggle
                        showMoreElement={({ toggle }: any) => (
                          <div className={classes.showToggleWrapper}>
                            <span className={classes.showToggle} onClick={toggle}>
                              {t('show_more')}
                            </span>
                          </div>
                        )}
                        showLessElement={({ toggle }: any) => (
                          <div className={classes.showToggleWrapper}>
                            <span className={classes.showToggle} onClick={toggle}>
                              {t('show_less')}
                            </span>
                          </div>
                        )}
                      >
                        <span className={classes.description}>
                          {htmlParse(data.description)}
                        </span>
                      </Clamp> 
                    </div>
                  </div> 
                ) : null
              }
              {
                data.media && data.media.length !== 0 ? (
                  <div className={classes.popoverDetail}>
                    <div className={classes.popoverDetailLeft}>
                      <SVG src="attachment"/>
                    </div>
                    <div className={classes.popoverDetailRight}>
                      <CalendarGallery eventID={data.eventID}/>
                    </div>
                  </div>
                ) : null
              }
            </div>
          </Popover>
        ) : null
      }
    </div>
  );
}

export default CalendarEventItem;