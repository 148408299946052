import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  endOfScroll: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '10px',
    marginTop: '16px',
    '& > span': {
      backgroundColor: theme.colors.white,
      padding: '6px 12px',
      borderRadius: '50px',
    },
  },
}));

type EndOfScrollType = {
  text: any;
  className?: any;
};

const EndOfScroll: React.FunctionComponent<EndOfScrollType> = ({ text, className}) => {

  const classes = useStyles();

  return (
    <div className={`${classes.endOfScroll} ${className ? className : null}`}>
      <span>{text}</span>
    </div>  
  );
};

export default EndOfScroll;