import HttpService from './http.service';
import { setAppData } from '../utils/useApp';

const setData = (user: any) => {
  setAppData({user: user});
};

const signIn = (email: string, password: string) => {
  return HttpService.post('user/login', {
    email,
    password,
  }, {}, {});
};

const resetPasswordRequest = (email: string, redirectUri: string) => {
  return HttpService.post('user/forgotten-password', {
    email,
    redirectUri,
  }, {}, {});
};

const resetPassword = (token: string, password: string) => {
  return HttpService.post('user/new-password', {
    token,
    password,
  }, {}, {});
};

const logout = (bearer?: string) => {
  return bearer ? HttpService.getBearer('user/logout', bearer, {}, {}) : HttpService.get('user/logout', {}, {});
};

const verifyToken = (bearer?: string, apiUrl?: string) => {
  return bearer ? HttpService.getBearer(`user/verify-token`, bearer, {apiUrl: apiUrl}, {}) : HttpService.get('user/verify-token', {}, {});
};

const ssoGetToken = (platform: string) => {
  return HttpService.get('user/sso-get', {
    platform: platform,
  }, {});
};

const ssoSignIn = (ssoToken: string) => {
  return HttpService.get(`user/sso-login/${ssoToken}`, {}, {});
};

const switchSignIn = (apiUrl: any, email: string, password: string) => {
  return HttpService.post('user/login', {
    email,
    password,
  }, {apiUrl: apiUrl}, {});
};

export {
  setData,
  signIn,
  resetPasswordRequest,
  resetPassword,
  logout,
  verifyToken,
  ssoGetToken,
  ssoSignIn,
  switchSignIn,
};