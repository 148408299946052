import * as i18next from 'i18next';
import Backend from 'i18next-http-backend';
import config from './constants/config';
import { getAppLanguage } from './utils/useApp';
import { initReactI18next } from 'react-i18next';

i18next.use(Backend).use(initReactI18next).init({
  lng: getAppLanguage(),
  fallbackLng: getAppLanguage(),
  debug: false,
  ns: ['translations', 'changelog', "suggestions"],
  defaultNS: config.APP_ONLY_LOCAL_LANGUAGES ? 'translations' : 'dictionaries',
  fallbackNS: 'translations',
  partialBundledLanguages: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  resources: {},
  backend: { 
    addPath: '/assets/i18n/{{ns}}/{{lng}}.json?v=' + config.APP_VERSION,
    loadPath: '/assets/i18n/{{ns}}/{{lng}}.json?v=' + config.APP_VERSION,
  },
});

document.documentElement.setAttribute('lang', getAppLanguage());

export default i18next;