import React from 'react';
import UserInfoItem from 'src/components/Items/UserInfoItem';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

type RenderUserType = {
  state: any;
  setState: any;
  schoolData?: any;
  userData: any;
  size: any;
};

const RenderUser: React.FunctionComponent<RenderUserType> = ({
  state,
  setState,
  schoolData,
  userData,
  size,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const schoolID = schoolData.schoolID;
  const userID = userData.userID;
  const searchData = state.isSelectInAllSchool ? { userID: userID } : { userID: userID, schoolID: schoolID };

  const defaultUserData = state.functions.getUserData(userID);
  const isSelected = state.selectedUsers.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  const isDisabled = state.isAllowOnlyOneUserAtOnce ? (isSelected ? true : false) : false;
  
  const handleOnClickUser = (e: any) => {
    e.preventDefault();
    if(state.isDisableClick) return;
    if(state.mode !== "select") return;
    if(state.isAllowOnlyOneUserAtOnce && isSelected) return;
    if(state.validateMultipleSchool && state.selectedUsers.length !== 0) {
      const oldUserSchool = state.selectedUsers[0].schoolID ? state.selectedUsers[0].schoolID : state.functions.getUserData(state.selectedUsers[0].userID).schoolID;
      const newUserSchool = schoolData.schoolID;
      if(oldUserSchool !== newUserSchool) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    if(state.maxUsersSelected && state.selectedUsers.length >= state.maxUsersSelected && !isSelected) {
      const settings = {
        isOpen: true,
        title: t('limit_exceeded'),
        content: state.customLimitMessage ? t(state.customLimitMessage, {limit: state.maxUsersSelected}) : t('limit_exceeded_info', {limit: state.maxUsersSelected}),
      };
      dispatch(setWarningModal(settings));
      return;
    }
    let newSelectedUsers = state.isAllowOnlyOneUserAtOnce ? [] : state.selectedUsers;
    const itemData = {
      school: schoolData,
      user: {...userData, schoolID: schoolData.schoolID},
    };
    if(isSelected) {
      newSelectedUsers = newSelectedUsers.filter((item: any) => JSON.stringify(item) !== JSON.stringify(searchData));
      setState("selectedUsers", newSelectedUsers);
    } else {
      newSelectedUsers = [...newSelectedUsers, searchData];
      setState("selectedUsers", newSelectedUsers);
    }        
    if(state.onClickUser) state.onClickUser(e, itemData, newSelectedUsers); 
  };

  return (
    <UserInfoItem userID={userID} isSelected={isSelected} isDisabled={isDisabled} mode={state.mode} isShowEmoji={state.isShowUsersEmojis} defaultUserData={defaultUserData} size={size} disableClick={state.isDisableClick} onClick={handleOnClickUser}/>
  );
}

export default RenderUser;