import DateItem from './DateItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import months from '../../../constants/months';
import Popover from '@mui/material/Popover';
import React, { useMemo } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../constants/weekdays';

interface Props {
  weekNumber?: boolean,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    '& > span': {
      fontSize: '13px',
      fontWeight: 'bold',
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
      borderRadius: '25px',
      padding: '5px 10px',
    },
  },
  calendarWrapper: {
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      boxShadow: theme.shadows[2],
    },
  },
  calendar: {
    position: "relative",
    width: (props: Props) => props.weekNumber ? "320px" : "280px",
    maxHeight: "500px",
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
    zIndex: "9999",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: '10px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: 'hidden',
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 20px',
    '& p': {
      marginBottom: '0',
      fontWeight: 'bold',
    }
  },
  weekdayHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#ececec',
    cursor: 'auto',
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '5px 0',
    fontSize: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0 !important',
      fontSize: '11px !important',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  navigator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '68px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '20px',
    },
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '90px',
    minWidth: '60px',
    minHeight: '90px',
    maxWidth: '60px',
    maxHeight: '90px',
    backgroundColor: theme.colors.white,
    margin: '10px 5px',
    borderRadius: '25px',
    position: 'relative',
    '&:not(.selected)': {
      cursor: 'pointer',    
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
      transform: 'scale(1.25)',
      width: '24px',
      height: '24px',
    },
  },
}));

type DatePickerType = {
  date?: any;
  setDate?: any;
  dateRange?: any;
  weekNumber?: any;
  dataCy?: any;
};

const DatePicker: React.FunctionComponent<DatePickerType> = ({ date, setDate, dateRange, weekNumber, dataCy }) => {
  
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);

  const [state, setState] = useStates({
    currentYearMonth: moment(date ? date : ""),
    anchorEl: null,
  });

  const classes = useStyles({ weekNumber });

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);
  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const firstDate = useMemo(() => {
    const startOfMonth = new Date(state.currentYearMonth.year(), state.currentYearMonth.month(), 0);
    return moment(startOfMonth).subtract(startOfMonth.getDay(), 'days');
  }, [state.currentYearMonth]);
  
  useEffect(() => {
    setState("currentYearMonth", moment(date));
  }, [date, state.anchorEl, setState], [date, state.anchorEl]);

  const handleClick = (e: any) => {
    setState("anchorEl", e.currentTarget);
  };

  const handleClose = () => {
    setState("anchorEl", null);
  };

  const handleNextMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).add(1, 'months'));
  };

  const handleLastMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).subtract(1, 'months'));
  };
  
  return (
    <>
      <div className={classes.root} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
        <div className={classes.date} data-cy={isCypress() ? 'calendarPickerButton' : null}>
          <SVG src="calendar"/>
        </div>
      </div>
      <Popover className={classes.calendarWrapper} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <div className={classes.calendar}>
          <div className={classes.calendarHeader}>
            <p data-clarity-unmask="true">{`${t(months[state.currentYearMonth.month()].toLowerCase())} ${state.currentYearMonth.year()}`}</p>
            <div className={classes.navigator}>
              <IconButton onClick={handleLastMonth} dataCy='prevMonthButton'>
                <SVG src="chevron-left"/>
              </IconButton>
              <IconButton onClick={handleNextMonth} dataCy='nextMonthButton'>
                <SVG src="chevron-right"/>
              </IconButton>
            </div>
          </div>
          <div className={classes.weekdayHeader}>
            {
              weekNumber ? (
                <div className={classes.weekday}>
                  <p>{t("w")}</p>
                </div>
              ) : null
            }
            {
              weekdays.map((weekday: any, key: any) => (
                <div className={classes.weekday} key={`k_${key}`}>
                  <p>{t(weekday.toLowerCase())}</p>
                </div>
              ))
            }
          </div>
          <div className={classes.dateWrapper} data-cy={isCypress() ? 'calendar' : null}>
            {
              new Array(weekNumber ? 48 : 42).fill('').map((_, key) => {
                const itemDate = weekNumber ? moment(firstDate).add(key % 8 === 0 ? key - Math.floor(key / 8) : key - Math.ceil(key / 8), 'days').add(firstDayInWeek, 'day') : moment(firstDate).add(key, 'days').add(firstDayInWeek, 'day');
                return (
                  <DateItem
                    index={key}
                    key={`k_${key}`}
                    date={itemDate}
                    setDate={setDate}
                    currentSelectedDate={date}
                    dateRange={dateRange}
                    weekNumberMode={weekNumber}
                    handleClose={handleClose}
                  />
                )
              })
            }
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DatePicker;