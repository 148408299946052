import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import { convertToCapitalFirstLetter, hasObjectAnyValue, trimCharacter } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useTranslation } from 'react-i18next';

interface Props {
  width: any;
}

const useStyles = createUseStyles((theme: any) => ({
  pinterestBoardCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  pinterestBoardCard: {
    display: 'flex',
    flexDirection: 'column',
    width: (props: Props) => `${props.width}px`,
    height: (props: Props) => `${(200 / props.width) * props.width}px`,
    backgroundSize: 'contain',
    backgroundColor: theme.colors.white,
    position: 'relative',
    overflow: 'hidden',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    '&:only-child': {
      borderRadius: '14px',
    },
    '&:not(:only-child)': {
      borderRadius: '14px 14px 0 0',
    },
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    cursor: 'pointer',
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 90%)',
    },
  },
  tooltipButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    '& > svg': {
      width: '12px',
      height: '12px',
    },
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    cursor: 'help',
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.alwaysWhite,
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  detail: {
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    right: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    borderRadius: '0 0 14px 14px',
    color: theme.colors.alwaysWhite,
    '& > p': {
      fontSize: '14px',
    },
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    '& > span': {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
      fontSize: '12px',
    },
  },
}));

type ItemsType = {
  item: any;
  columnSize: any;
  onClick: any;
};

const PinterestBoardCard: React.FunctionComponent<ItemsType> = ({ item, columnSize, onClick }) => {

  const classes = useStyles({
    width: columnSize,
  });

  const { t } = useTranslation();

  const id = item.id;
  
  const stats: any = {
    collaborator_count: item.collaborator_count !== 0 ? item.collaborator_count : null,
    follower_count: item.follower_count !== 0 ? item.follower_count : null,
    pin_count: item.pin_count !== 0 ? item.pin_count : null,
  };

  const handleOnClick = () => {
    onClick(item);
  };

  return (
    <div className={classes.pinterestBoardCardWrapper}>
      <div className={classes.pinterestBoardCard} data-cy={isCypress() ? 'pinterestBoardCard' + id : null}>
        <AuthenticatedImage className={classes.image} thumbLink={item.media.image_cover_url} onClick={handleOnClick}/>
        {
          trimCharacter(item.description, " ").length > 0 ? (
            <IconButton className={classes.tooltipButton} tooltip={htmlParse(item.description)} tooltipPosition='bottom-end' tooltipMaxWidth={250} disableRipple={true}>
              <SVG src="info"/>
            </IconButton>
          ) : null
        }
        <div className={classes.detail}>
          <Clamp lines={1}>
            <span className={classes.name} onClick={handleOnClick}>{item.name ? htmlParse(convertToCapitalFirstLetter(item.name)) : null}</span>
          </Clamp>
          {
            hasObjectAnyValue(stats) ? (
              <div className={classes.stats}>
                {
                  stats.collaborator_count ? (
                    <Tooltip title={t('pinterest_collaborator_count')}>
                      <span>
                        <SVG src="people"/> {stats.collaborator_count}
                      </span>
                    </Tooltip>
                  ) : null
                }
                {
                  stats.follower_count ? (
                    <Tooltip title={t('pinterest_follower_count')}>
                      <span>
                        <SVG src="users"/> {stats.follower_count}
                      </span>
                    </Tooltip>
                  ) : null
                }
                {
                  stats.pin_count ? (
                    <Tooltip title={t('pinterest_pin_count')}>
                      <span>
                        <SVG src="pin"/> {stats.pin_count}
                      </span>
                    </Tooltip>
                  ) : null
                }
              </div>
            ) : null
          }

        </div>
      </div>
    </div>
  );
}

export default PinterestBoardCard;