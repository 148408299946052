import Failed from 'src/components/Layouts/Failed';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import TimelineCard from 'src/components/Cards/TimelineCard';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { addTimelinePosts } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  lastPost: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
  },
  content: {
    overflow: 'auto',
    maxHeight: '100%',
  },
  timelineCard: {
    boxShadow: 'none !important',
    borderRadius: '0  !important',
    width: '100% !important',
  },
  moreButton: {
    margin: '0px 8px 8px 8px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  failedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '25px 0',
  },
  failed: {
    width: '100%',
    padding: '100px 0 50px 0',
    '& > svg': {
      width: '64px',
      height: '64px',
    },
    '& > span': {
      fontSize: '18px',
      color: theme.colors.systemRed[500],
      marginTop: '8px',
    },
  },
}));

type LastPostType = {
  settings?: any,
};

const LastPost: React.FunctionComponent<LastPostType> = ({ settings }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const layoutData = useAppSelector((state: any) => state.layout);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  
  const [state, setState, setStates] = useStates({
    isLoading: true,
    isFailed: false,
  });

  const postCount = settings.filter((item: any) => item.key === "countOfPost").length === 1 ? settings.find((item: any) => item.key === "countOfPost").value : 1;

  const postData = state.isLoading ? [] : timelineData.posts.slice(0, postCount);

  const loadPost = useCallback(() => {
    timelineService && timelineService.listPosts({page: 1, limit: postCount}).then((result: any) => {
      if(result && result.data && result.data.posts) {
        const resultPosts = result.data.posts;
        const post = resultPosts.map((post: any) => {
          const childrenID = post.children.map((child: any) => child.childID);
          const uniqueID = post.postID + "_" + childrenID.join();
          return { ...post, uniqueID };
        });
        dispatch(addTimelinePosts(post));
        setStates({
          isLoading: false,
          isFailed: false,
        });
      } else {
        setState("isFailed", true);
      }
    }).catch(() => {
      setState("isFailed", true);
    });
  }, [timelineService, dispatch, setState, setStates, postCount]);

  useEffect(() => {
    loadPost();
  }, [dispatch, loadPost], [postCount]);

  const handleTimeline = () => {
    navigate('/timeline');
  };
  
  return (state.isLoading && !layoutData.isDashboardEditable) ? (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        <CircularProgress/>
      </div>
    </div>
  ) : (
    <div className={classes.lastPost}>
      {
        state.isFailed ? (
          <div className={classes.failedWrapper}>
            <Failed className={classes.failed} title={t('widget_failed_load')}/>
            <NormalButton className={classes.moreButton} onClick={loadPost}>
              {t('try_again')}
            </NormalButton>
          </div>
        ) : (
          <>
            <div className={classes.content}>
              {
                (postData && postData.length > 0) ? (
                  <>
                    {
                      postData.map((item: any, key: any) => (
                        <TimelineCard key={`k_${key}`} className={classes.timelineCard} uniqueID={item.uniqueID} disableContextMenu={true} displaySkeleton={layoutData.isDashboardEditable}/> 
                      ))
                    }
                  </>
                ) : null
              }
            </div>
            <NormalButton className={classes.moreButton} onClick={handleTimeline}>
              {t('view_more_posts')}
            </NormalButton>
          </>
        )
      }
    </div>
  );
};

const LastPostTitle: React.FunctionComponent<LastPostType> = ({ settings }) => {
  const { t } = useTranslation();
  const postCount = settings.filter((item: any) => item.key === "countOfPost").length === 1 ? settings.find((item: any) => item.key === "countOfPost").value : 1;
  return postCount === 1 ? t('last_post') : t('last_posts');
};

export { LastPost, LastPostTitle };