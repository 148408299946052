import Drawer from 'src/utils/drawer';
import FirebaseNotificationStatic from 'src/components/Layouts/FirebaseNotificationStatic';
import IconButton from 'src/components/Buttons/IconButton';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { clearFirebaseNotification } from 'src/store/actions/firebasenotification.actions';
import { createUseStyles } from 'react-jss';
import { IsLocationPart } from 'src/utils/useLocation';
import { setFirebaseNotificationsDrawer } from 'src/store/actions/drawers.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getSideContentSize } from 'src/utils/useFunctions';
import useBreakpoint from 'src/utils/useBreakpoint';

interface Props {
  browserHeight: any;
  sideContentSize: any;
  isSideContentVisible: any;
};

const useStyles = createUseStyles((theme: any) => ({
  paper: {
    right: (props: Props) => {
      if(props.isSideContentVisible) return props.sideContentSize + '%';
      else return '';
    },
  },
  root: {
    backgroundColor: theme.colors.white,
    width: '20vw',
    padding: "20px 0px 0px 20px",
    overflow: 'hidden',
    height: (props: Props) => (props.browserHeight - 84) + 'px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('lg')]: {
      width: '30vw',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 20px)',
      height: (props: Props) => (props.browserHeight - 140) + 'px',
    },
  },
  header: {
    display: 'flex',
    gap: '16px',
    paddingRight: '20px',
    alignItems: 'center',
    width: 'calc(100% - 20px)',
    '& > span': {
      fontWeight: '600',
    },
    '& > div': {
      marginLeft: 'auto',
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  },
  clearButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
  settingsButton: {
    backgroundColor: theme.colors.grey[200],
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  list: {
    padding: '16px 20px 16px 0px',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    gap: '16px',
    maxHeight: 'calc(100% - 32px)',
    overflow: 'auto',
    width: 'calc(100% - 20px)',
  },
}));


const FirebaseNotificationsDrawer: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const browserData = useAppSelector((state: any) => state.browser);
  const drawersData = useAppSelector((state: any) => state.drawers);
  const layoutData = useAppSelector((state: any) => state.layout);
  const firebaseNotificationList = useAppSelector((state: any) => state.firebasenotification.list);

  const breakpoint: any = useBreakpoint();
  const isSideContentVisible = getSideContentSize(breakpoint) !== 0 && layoutData.sideContent !== null;

  const classes = useStyles({
    browserHeight: browserData.height,
    sideContentSize: getSideContentSize(breakpoint),
    isSideContentVisible: isSideContentVisible,
  });
  
  const onCloseDrawer = () => {
    const settings = {
      isOpen: false,
      position: 'right',
    };
    dispatch(setFirebaseNotificationsDrawer(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseDrawer();
  };

  const handleClearNotifications = () => {
    dispatch(clearFirebaseNotification());
  };

  const handleClickSettings = () => {
    navigate('/settings/notifications');  
  };
  
  return (
    <Drawer
      position={drawersData.firebaseNotificationsDrawer.position}
      open={drawersData.firebaseNotificationsDrawer.isOpen}
      onClose={handleClose}
      classNamePaper={classes.paper}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          <span>{t('notifications')} { firebaseNotificationList.length > 0 ? (<>({firebaseNotificationList.length})</>) : null }</span>
          <div>
            {
              firebaseNotificationList.length > 0 ? (
                <NormalButton className={classes.clearButton} buttonType='clear' onClick={handleClearNotifications}>
                  {t('clear')}
                </NormalButton>
              ) : null
            }
            {
              !IsLocationPart('settings') ? (
                <IconButton onClick={handleClickSettings} className={classes.settingsButton} tooltip={t('notifications_settings')} tooltipMaxWidth={400}>
                  <SVG src="cog"/>
                </IconButton>
              ) : null
            }
          </div>
        </div>
        <div className={classes.list}>
          {
            firebaseNotificationList.length === 0 ? (
              <NotFound text={t('notifications_empty')}/>
            ) : firebaseNotificationList.map((notification: any, key: any) => (
              <FirebaseNotificationStatic key={`k_${key}`} title={notification.title} text={notification.text} image={notification.image} date={notification.date}/>  
            ))
          }
        </div>
      </div>
    </Drawer>
  )
};

export default FirebaseNotificationsDrawer;
