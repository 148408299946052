import MuiSpeedDial from '@mui/material/SpeedDial';
import MuiSpeedDialAction from '@mui/material/SpeedDialAction';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createCypressDataText, isCypress } from '../../../utils/useCypress';
import { createUseStyles } from 'react-jss';

interface Props {
  isOpen: any;
};

const useStyles = createUseStyles((theme: any) => ({
  speedDialButtonWrapper: {
    visibility: (props: Props) => {
      if(props.isOpen) return 'visible';
      else return 'visible';
    },   
    '& .MuiSpeedDialAction-staticTooltipLabel': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      padding: '4px 8px',
    },
  },
  speedDialButton: {
    backgroundColor: theme.colors.primaryBlue[500],
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    height: '58px',
    width: '58px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      height: 'unset !important',
    },
    '& svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.alwaysWhite,
      transform: (props: Props) => {
        if(props.isOpen) return 'rotate(45deg)';
        else return 'rotate(0deg)';
      },
      transition: 'all 0.25s',
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
  },
  speedDialButtonAction: {
    backgroundColor: theme.colors.primaryBlue[500],
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      height: 'unset',
    },
    '& svg': {
      color: theme.colors.alwaysWhite,
      width: '20px',
      height: '20px',
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
  },
}));

type SpeedDialButtonType = {
  className?: any;
  classNameButton?: any;
  classNameActionButton?: any;
  icon?: any;
  iconOpen?: any;
  onClick?: any;
  direction?: 'down' | 'left' | 'right' | 'up',
  isOpen?: boolean;
  isTooltipOpen?: boolean;
  isHidden?: boolean;
  dataCy?: string;
  items: any;
  disabled?: any;
  transitionDuration?: any;
};

const SpeedDialButton: React.FunctionComponent<SpeedDialButtonType> = ({className, classNameButton, classNameActionButton, icon = <SVG src="plus"/>, iconOpen = icon, onClick, direction = "up", isOpen, isTooltipOpen, isHidden, dataCy, items, disabled, transitionDuration = 500}) => {
  
  const classes = useStyles({
    isOpen: isOpen,
  });

  const fabProps = {
    className: `${classes.speedDialButton} ${classNameButton}`,
    disabled: disabled,
  };

  return (
    <MuiSpeedDial ariaLabel="" FabProps={fabProps} className={`${classes.speedDialButtonWrapper} ${className}`} icon={icon} openIcon={iconOpen} direction={direction} onClick={onClick} open={isOpen} hidden={isHidden} data-cy={isCypress() ? dataCy : null} transitionDuration={transitionDuration}>
      {
        items.filter((item: any) => item !== null).map((item: any, key: any) => (
          <MuiSpeedDialAction key={`k_${key}`} className={`${classes.speedDialButtonAction} ${classNameActionButton}`} icon={item.icon} tooltipTitle={item.name} tooltipOpen={isTooltipOpen} onClick={item.onClick} data-cy={isCypress() ? dataCy + (item.dataCy ? item.dataCy : createCypressDataText(item.name)) : null}/>
        ))
      }
    </MuiSpeedDial>
  );
};

export default SpeedDialButton;