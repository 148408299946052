import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  failed: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '80%',
    },
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
}));

type FailedType = {
  className?: any;
  title?: any;
  text?: any;
};

const Failed: React.FunctionComponent<FailedType> = ({ className, title, text }) => {
  
  const classes = useStyles();
    
  return (
    <div className={`${classes.failed} ${className ? className : ''}`}>
      <SVG src="failed"/>
      { 
        title ? (
          <span>{title}</span>
        ) : null
      }
      { 
        text ? (
          <p>{text}</p>
        ) : null
      }
    </div>  
  );
}

export default Failed;