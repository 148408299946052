import moment from './moment';

export const compareDates = (date1: any, date2: any) => (
  moment(date1).year() === moment(date2).year() && moment(date1).month() === moment(date2).month() && moment(date1).date() === moment(date2).date()
);

export const inRange = (date: any, range: any) => {
  if (compareDates(moment(range.start), moment(range.end)) && compareDates(moment(range.start), moment(date)))
    return "SINGLE";
  else if (moment(range.start).isAfter(moment(range.end)))
    return "ERR";
  else if (compareDates(moment(date), moment(range.start)))
    return "START";
  else if (compareDates(moment(date), moment(range.end)))
    return "END";
  else if (moment(range.end).isAfter(date) && moment(date).isAfter(moment(range.start))) return "IN";
  else return "NOT";
};
