import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '8px',
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const Loading: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <span>{t('communication_loading_threads')}</span>
        <div className={classes.spinner}>
          <CircularProgress/>
        </div>
      </div>
    </div>
  );
};

export default Loading;