import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import DateFormat from '../../../utils/dateFormat';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import React, { useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { parseTranslation } from 'src/utils/useFunctions';
import { setWageDetailModal, setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isLocked?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  employeeCard: {
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: '100vw',
    position: 'relative',
    overflow: 'hidden',
  },
  employeeHeader: {
    display: 'flex',
    width: '100%',
    height: '188px',
    position: 'relative',
    backgroundColor: theme.colors.primaryBlue[200],
    color: theme.colors.black,
    borderRadius: '24px 24px 0 0',
  },
  employeeImage: {
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 90%)',
    },
  },
  nameBlock: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 28px',
    maxWidth: '50%',
    color: theme.colors.alwaysWhite,
  },
  fullName: {
    fontSize: '24px',
    fontWeight: 'bold',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  otherBlock: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 28px',
    maxWidth: '50%',
    color: theme.colors.alwaysWhite,
  },
  employeePosition: {
    fontSize: '14px',
    fontWeigth: '300',
  },
  infoBlock: {
    padding: '12px 18px 22px 18px',
    backgroundColor: theme.colors.white,
  },
  bookmark: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '16px',
    padding: '10px 10px',
    fontWeight: 'bold',
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: '12px',
    },
    '&:not(:first-child)::after': {
      content: `''`,
      position: 'absolute',
      top: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1px',
      width: '90%',
      backgroundColor: '#F2F4FA',
    },
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },    
    '& > div': {
      display: 'flex',
      gap: '8px',
      marginLeft: 'auto',
      '& > span': {
        fontSize: '12px',
        fontWeight: '500',
        color: theme.colors.grey[500],
        '&.active': {
          color: theme.colors.primaryBlue[500],
        },
        '&:not(.active)': {
          cursor: 'pointer',
        },
      },
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '16px',
    padding: '10px 10px',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '12px',
    position: 'relative',
    width: '100%',
    textTransform: 'none',
    height: (props: Props) => '',
    '&:not(:first-child)': {
      marginTop: '12px',
    },
    '&:not(:first-child)::after': {
      content: `''`,
      position: 'absolute',
      top: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1px',
      width: '90%',
      backgroundColor: '#F2F4FA',
    },
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
      '&:last-of-type': {
        marginRight: '0px',
        marginLeft: 'auto',
        width: '24px',
        height: '24px',
        color: (props: Props) => {
          if(props.isLocked) return theme.colors.yellow[500];
          else return '';
        },
      },
    },
    '& > span': {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      '& > em': {
        color: theme.colors.white,
        fontStyle: 'normal',
        marginLeft: 'auto',
        borderRadius: '50%',
        backgroundColor: theme.colors.systemRed[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        fontSize: '10px',
        lineHeight: '10px',
      },
    },    
  },
  notes: {
    marginTop: '16px',
    display: 'block',
    color: theme.colors.grey[650],
    fontSize: '14px',
    padding: '0px 10px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& a': {
      color: theme.colors.primaryBlue[500],
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
    fontSize: '12px',
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  schoolWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    paddingTop: '8px',
    backgroundColor: theme.colors.white,
  },
  schoolHeader: {
    display: 'flex',
    width: '100%',
    height: '188px',
    position: 'relative',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.black,
  }, 
  schoolImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    '& > div': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.colors.alwaysWhite,
    },
  },
  schoolBadge: {
    position: 'absolute',
    top: '18px',
    right: '0',
    width: '115px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,133,255,0.7)',
    color: theme.colors.white,
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '11px 24px 9px 15px',
    borderRadius: '6px 0px 0px 6px',
    '& > svg': {
      marginRight: '9px',
      width: '24px',
      height: '24px',
    },
  },
  schoolBlock: {
    padding: '0px 28px 22px 28px',
  },
  schoolName: {
    display: 'flex',
    color: '#2D3142',
    fontSize: '22px',
    padding: '22px 0px 10px 0px',
    fontWeight: 'bold',
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },  
  },
  schoolDescription: {
    fontSize: '14px',
    color: theme.colors.grey[650],
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& a': {
      color: theme.colors.primaryBlue[500],
      textDecoration: 'underline',
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
  contactWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    width: '100%',
    flexWrap: 'wrap',
  },
  callButton: {
    width: '28px',
    height: '28px',
    cursor: 'pointer',
    backgroundColor: theme.colors.primaryBlue[500],
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& svg': {
      color: theme.colors.white,
      width: '16px',
      height: '16px',
      transform: 'scale(2)',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  cell: {
    display: 'block',
    flex: '1 1 auto',
    fontSize: '14px',
    '&:first-of-type': {
      fontWeight: '500',
      whiteSpace: 'nowrap',
    },
    '&:last-of-type': {
      display: 'block',
      whiteSpace: 'nowrap',
      paddingLeft: '8px',
      maxWidth: 'calc(100% - 32px)',
      textAlign: 'right',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& a': {
      maxWidth: '100%',
      color: theme.colors.primaryBlue[500],
      cursor: 'pointer',
      transition: 'color 0.25s',
      fontWeight: 'bold',
      textDecoration: 'none',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
}));

type ItemsType = {
  employeeID: any;
  disableSchool?: any;
  disableButtons?: any;
};

const EmployeeCard: React.FunctionComponent<ItemsType> = ({employeeID, disableSchool = false, disableButtons = false}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const employeeData = useMemo(() => dataData.employees.find((employee: any) => employee.employeeID === employeeID), [dataData.employees, employeeID]);
  const userData = useAppSelector((state: any) => state.user);
  const userAccessSchools = userData.userAccessSchools;
  const [address, setAddress] = useState("permanent");
  const isLocked = !userAccessSchools.subscription.includes(employeeData.schoolID);

  const classes = useStyles({
    isLocked: isLocked,
  });

  const parseData = (data: any, type: any) => {
    if(type === "text") {
      return data;
    } else if(type === "date") {
      return DateFormat(data, "default", languageData, t);
    } else if(type === "gender") {
      return data === "m" ? t("male") : t("female");
    } else if(type === "mail") {
      return htmlParse('<a href="mailto:' + data + '">' + data + '</a>');
    } else if(type === "phone") {
      return htmlParse('<a href="tel:+' + data + '">+' + data + '</a>');
    } else if(type === "boolean") {
      return data ? t('yes') : t('no');
    } else {
      return data;
    }
  };

  const employeeInformations: any = {
    "degreeBefore": "text",
    "firstname": "text",
    "middlename": "text",
    "surname": "text",
    "degreeAfter": "text",
    "maidenname": "text",
    "birthday": "date",
    "birthNumber": "text",
    "citizenship": "text",
    "employedSince": "date",
    "idOrPassport": "text",
    "foreignerId": "text",
    "healthInsurance": "text",
    "nationality": "text",
    "placeOfBirth": "text",
    "sex": "gender",
    "important": "boolean",
  };

  const employeeContacts: any = {
    "bankAccountNumber": "text",
    "email": "mail",
    "workEmail": "mail",
  };

  const employeeJobInformations: any = {
    "contractSigned": "boolean",
    "contractFrom": "date",
    "contractTo": "date",
    "criminalRecord": "boolean",
    "criminalRecordDate": "date",
    "startDate": "date",
    "dateOutput": "date",
    "trialPeriodUntil": "date",
    "education": "boolean",
    "employeeCenter": "text",
    "employeePosition": "text",
    "gdpr": "boolean",
    "healthCard": "boolean",
    "healthCardUntil": "date",
    "identificationNumber": "text",
    "materialLiability": "boolean",
  };

  const handleCallButton = (phone: any) => {
    window.location.href = 'tel:+' + phone; 
  };

  const getIsPermanentAddressFilled = () => {
    let isFilled = false;
    Object.keys(employeeData.permanentAddress).forEach((item: any) => {
      if(employeeData.permanentAddress[item] !== "") {
        isFilled = true;
      }
    });
    return isFilled;
  };

  const getIsDeliveryAddressFilled = () => {
    let isFilled = false;
    Object.keys(employeeData.deliveryAddress).forEach((item: any) => {
      if(employeeData.deliveryAddress[item] !== "") {
        isFilled = true;
      }
    });
    return isFilled;
  };

  const handlePermanentAddress = () => {
    setAddress("permanent");
  };

  const handleDeliveryAddress = () => {
    setAddress("delivery");
  };

  const handleClickWages = () => {
    const settings = {
      isOpen: true,
      employeeID: employeeData.employeeID,
      schoolID: employeeData.schoolID,
    };
    dispatch(setWageDetailModal(settings));
  };

  const subscriptionInfo = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_employees_wages', {license_start: `<b>${t('license_start')}</b>`, license_premium: `<b>${t('license_premium')}</b>`}),
    };
    dispatch(setWarningModal(settings));
  };
  
  return (
    <div className={classes.employeeCard} data-cy={isCypress() ? 'employeeCard' + employeeData.employeeID : null}>
      <div className={classes.employeeHeader}>
        {
          employeeData.photo ? (
            <AuthenticatedImage className={classes.employeeImage} thumbLink={employeeData.photo.thumbLink} fullsizeLink={employeeData.photo.fullsizeLink} preferQuality="fullsize"/>
          ) : null
        }
        <div className={classes.nameBlock}>
          <span className={classes.fullName} data-cy={isCypress() ? 'employeeFullName' : null}>{employeeData.firstname + ' ' + employeeData.surname}</span> 
        </div>
        {
          employeeData.employeePosition ? (
            <div className={classes.otherBlock}>
              <span className={classes.employeePosition} data-cy={isCypress() ? 'employeePosition' : null}>
                {employeeData.employeePosition}
              </span> 
            </div>
          ) : null
        }
      </div>
      <div className={classes.infoBlock}>
        {
          !disableButtons ? (
            <Button className={classes.link} onClick={isLocked ? subscriptionInfo : handleClickWages} data-cy={isCypress() ? 'employeeWagesButton' : null}>
              <SVG src="wage"/>
              <span>{t('wages')}</span>
              <SVG src={isLocked ? "lock-outlined" : "chevron-right"}/>
            </Button>
          ) : null
        }
        {
          Object.keys(employeeInformations).filter((employeeInformation: any) => (employeeData[employeeInformation] || employeeData[employeeInformation] === false) && employeeData[employeeInformation] !== "").length > 0 ? (
            <>
            <span className={classes.bookmark} data-cy={isCypress() ? 'employeeBasicDetails' : null}>
              <SVG src="info-circle-outlined"/>
              {t('employee_information')}
            </span>
            <div className={classes.table}>
              {
                Object.keys(employeeInformations).map((employeeInformation: any, key: any) => (employeeData[employeeInformation] || employeeData[employeeInformation] === false) && employeeData[employeeInformation] !== "" ? (
                  <div className={classes.row} key={`k_${key}`}>
                    <div className={classes.cell}>
                      {t(`employee_information_${parseTranslation(employeeInformation)}`)}:
                    </div>
                    <div className={classes.cell}>
                      {parseData(employeeData[employeeInformation], employeeInformations[employeeInformation])}
                    </div>
                  </div>
                ) : null) 
              }
            </div>
            </>
          ) : null
        }
        {
          (Object.keys(employeeContacts).filter((employeeContact: any) => (employeeData[employeeContact] || employeeData[employeeContact] === false) && employeeData[employeeContact] !== "").length > 0 || employeeData.phonePrefix + employeeData.phoneNumber !== "" || employeeData.workPhonePrefix + employeeData.workPhoneNumber !== "") ? (
            <>
              <span className={classes.bookmark} data-cy={isCypress() ? 'employeeContactDetails' : null}>
                <SVG src="phone-circle-outlined"/>
                {t('employee_contacts')}
              </span>
              <div className={classes.table}>
                {
                  Object.keys(employeeContacts).map((employeeContact: any, key: any) => (employeeData[employeeContact] || employeeData[employeeContact] === false) && employeeData[employeeContact] !== "" ? (
                    <div className={classes.row} key={`k_${key}`}>
                      <div className={classes.cell}>
                        {t(`employee_information_${parseTranslation(employeeContact)}`)}:
                      </div>
                      <div className={classes.cell}>
                        <div className={classes.contactWrapper}>
                          {parseData(employeeData[employeeContact], employeeContacts[employeeContact])}
                        </div>
                      </div>
                    </div>
                  ) : null) 
                }
                {
                  employeeData.phonePrefix + employeeData.phoneNumber !== "" ? (
                    <div className={classes.row}>
                      <div className={classes.cell}>
                        {t(`employee_information_phone`)}:
                      </div>
                      <div className={classes.cell}>
                        <div className={classes.contactWrapper}>
                          {parseData(employeeData.phonePrefix + employeeData.phoneNumber, "phone")}
                          <IconButton className={classes.callButton} onClick={() => handleCallButton(employeeData.phonePrefix + employeeData.phoneNumber)}>
                            <SVG src="phone"/>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  employeeData.workPhonePrefix + employeeData.workPhoneNumber !== "" ? (
                    <div className={classes.row}>
                      <div className={classes.cell}>
                        {t(`employee_information_workPhone`)}:
                      </div>
                      <div className={classes.cell}>
                        <div className={classes.contactWrapper}>
                          {parseData(employeeData.workPhonePrefix + employeeData.workPhoneNumber, "phone")}
                          <IconButton className={classes.callButton} onClick={() => handleCallButton(employeeData.workPhonePrefix + employeeData.workPhoneNumber)}>
                            <SVG src="phone"/>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
              </div>
            </>
          ) : null
        }
        {
          Object.keys(employeeJobInformations).filter((employeeJobInformation: any) => (employeeData[employeeJobInformation] || employeeData[employeeJobInformation] === false) && employeeData[employeeJobInformation] !== "").length > 0 ? (
            <>
            <span className={classes.bookmark} data-cy={isCypress() ? 'employeeJobDetails' : null}>
              <SVG src="briefcase-circle-outlined"/>
              {t('employee_job_information')}
            </span>
            <div className={classes.table}>
              {
                Object.keys(employeeJobInformations).map((employeeJobInformation: any, key: any) => (employeeData[employeeJobInformation] || employeeData[employeeJobInformation] === false) && employeeData[employeeJobInformation] !== "" ? (
                  <div className={classes.row} key={`k_${key}`}>
                    <div className={classes.cell}>
                      {t(`employee_information_${parseTranslation(employeeJobInformation)}`)}:
                    </div>
                    <div className={classes.cell}>
                      <span>{parseData(employeeData[employeeJobInformation], employeeJobInformations[employeeJobInformation])}</span>
                    </div>
                  </div>
                ) : null) 
              }
            </div>
            </>
          ) : null
        }
        {
          (getIsPermanentAddressFilled() || getIsDeliveryAddressFilled()) ? (
            <>
              <span className={classes.bookmark} data-cy={isCypress() ? 'employeeAddressesDetails' : null}>
                <SVG src="home-circle-outlined"/>
                {t('employee_address_information')}
                <div>
                  {
                    getIsPermanentAddressFilled() ? (
                      <span onClick={handlePermanentAddress} className={address === "permanent" ? "active" : ""}>{t('employee_information_permanent_address')}</span>
                    ) : null
                  }
                  {
                    getIsDeliveryAddressFilled() ? (
                      <span onClick={handleDeliveryAddress} className={address === "delivery" ? "active" : ""}>{t('employee_information_delivery_address')}</span>
                    ) : null
                  }
                </div>
              </span>
              <div className={classes.table}>
                {
                  Object.keys(address === "permanent" ? employeeData.permanentAddress : employeeData.deliveryAddress).map((employeeAddressInformation: any, key: any) => (address === "permanent" ? employeeData.permanentAddress[employeeAddressInformation] : employeeData.deliveryAddress[employeeAddressInformation]) !== "" ? (
                    <div className={classes.row} key={`k_${key}`}>
                      <div className={classes.cell}>
                        {t(`employee_information_${parseTranslation(employeeAddressInformation)}`)}:
                      </div>
                      <div className={classes.cell}>
                        <span>{parseData(address === "permanent" ?  employeeData.permanentAddress[employeeAddressInformation] : employeeData.deliveryAddress[employeeAddressInformation], "text")}</span>
                      </div>
                    </div>
                  ) : null) 
                }
              </div>
            </>
          ) : null
        }
        {
          employeeData.note ? (
            <span className={classes.bookmark} data-cy={isCypress() ? 'employeeOtherDetails' : null}>
              <SVG src="question-mark-circle"/>
              {t('other_info')}
            </span>
          ) : null
        }
        {
          employeeData.note ? (
            <Clamp
              lines={2}
              maxLines={100}
              withToggle
              showMoreElement={({ toggle }: {toggle: any}) => (
                <div className={classes.showToggleWrapper}>
                  <span className={classes.showToggle} onClick={toggle}>
                    {t('show_more')}
                  </span>
                </div>
              )}
              showLessElement={({ toggle }: {toggle: any}) => (
                <div className={classes.showToggleWrapper}>
                  <span className={classes.showToggle} onClick={toggle}>
                    {t('show_less')}
                  </span>
                </div>
              )}
            >
              <div className={classes.notes} data-cy={isCypress() ? 'childOtherNotes' : null}>
                {htmlParse(employeeData.note)}
              </div>
            </Clamp>
          ) : null
        }  
      </div>
      {
        !disableSchool && employeeData.schoolID && dataData.schools.filter((schoolData: any) => schoolData.schoolID === employeeData.schoolID).length > 0 ? (
          <div className={classes.schoolWrapper} data-cy={isCypress() ? 'childSchool' + employeeData.schoolID : null}>
            <div className={classes.schoolHeader}>
              <AuthenticatedImage className={classes.schoolImage} thumbLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === employeeData.schoolID).logo.thumbLink} fullsizeLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === employeeData.schoolID).logo.fullsizeLink} preferQuality="fullsize"/>
              <span className={classes.schoolBadge}>
                <SVG src="school-cap-outlined"/>
                {t('school')}
              </span>
            </div>
            <div className={classes.schoolBlock}>
              <span className={classes.schoolName} data-cy={isCypress() ? 'childSchoolName' : null}>
                {htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === employeeData.schoolID).name)}
              </span>
              {
                dataData.schools.find((schoolData: any) => schoolData.schoolID === employeeData.schoolID)?.description ? (
                  <div className={classes.schoolDescription} data-cy={isCypress() ? 'childSchoolDescription' : null}>
                    {htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === employeeData.schoolID).description)}
                  </div>
                ) : null
              }
            </div>            
          </div>
        ) : null
      }     
    </div>
  );
}

export default EmployeeCard;