import CheckboxInput from 'src/components/Forms/CheckboxInput';
import Currency from 'react-currency-formatter';
import DateFormat from 'src/utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import moment from 'src/utils/moment';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { a2ab, createFileName, handleSum, isKey } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { saveAs } from 'file-saver';
import { setAttachmentModal, setConfirmModal, setShopOrderDetailModal, setShopOrderStatusModal } from 'src/store/actions/modals.actions';
import { setStockOrders, setStockSelectedOrders } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '&:only-child': {
        '& > span': {
          justifyContent: 'center',
        },
      },
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  checkbox: {
    width: '20px',
    paddingLeft: '8px',
  },
  name: {
    fontWeight: '500',
    maxWidth: '300px',
    width: '300px',
    '& > span': {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: '12px 10px',
    },
  },  
  date: {
    paddingLeft: '8px',
  },
  status: {
    paddingLeft: '8px',
  },
  user: {
    paddingLeft: '8px',
    '& > p': {
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  child: {
    paddingLeft: '8px',
    '& > p': {
      display: 'block',
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  price: {
    paddingLeft: '8px',
  },
  quantity: {
    paddingLeft: '8px',
  },
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: 'calc(72px - 16px)',
  },
  button: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: '#5AB8FF',
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

const ShopOrdersTable: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const exportService = useAppSelector((state: any) => state.services).exportService;
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const classes = useStyles();

  const orders = stockData.orders;
  const selectedOrders = stockData.selectedOrders;

  const isChecked = useMemo(() => (orderID: any) => {
    return selectedOrders.indexOf(orderID) !== -1 ? true : false;
  }, [selectedOrders]);

  const isCheckedAll = useMemo(() => () => {
    return (selectedOrders.length === orders.length && selectedOrders.length !== 0) ? true : false;
  }, [selectedOrders, orders]);
  
  const getOrderData = useCallback((orderID: any) => {
    return orders.filter((item: any) => item.orderID === orderID).length === 0 ? [] : orders.find((item: any) => item.orderID === orderID);
  }, [orders]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getOrderStatusName = useCallback((orderStatusID: any) => {
    return configurationData.stockOrderStatuses.filter((item: any) => item.orderStatusID === orderStatusID).length === 0 ? "" : configurationData.stockOrderStatuses.find((item: any) => item.orderStatusID === orderStatusID).name;
  }, [configurationData.stockOrderStatuses]);

  const getTotalPrice = useCallback((orderID: any) => {
    const currencyID = getOrderData(orderID).currencyID;
    const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
    const totalPrice = getOrderData(orderID).items.map((item: any) => {
      return (item.orderPrice * (1 + (item.orderTax / 100))) * item.orderQuantity;
    }).reduce(handleSum, 0);
    return [totalPrice, getCurrency];
  }, [getOrderData, configurationData.currencies]);

  const getTotalQuantity = useCallback((orderID: any) => {
    const totalQuantity = getOrderData(orderID).items.map((item: any) => {
      return item.orderQuantity;
    }).reduce(handleSum, 0);
    return totalQuantity;
  }, [getOrderData]);

  const handleClickView = (orderID: any, schoolID: any) => {
    const settings = {
      isOpen: true,
      isNewCreated: false,
      orderID: orderID,
      schoolID: schoolID,
    };
    dispatch(setShopOrderDetailModal(settings)); 
  };

  const handleClickChangeStatus = (orderID: any) => {
    const settings = {
      isOpen: true,
      onClose: null,
      orderID: orderID,
    };
    dispatch(setShopOrderStatusModal(settings)); 
  };

  const handleClickDelete = (orderID: any) => {
    const onAccept = () => {
      stockService && stockService.deleteOrder(orderID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("stock_order_canceled"), "success");
            const newOrders = stockData.orders.map((item: any) => {
              if(item.orderID === orderID) {
                return {...item, orderStatusID: 7, isEditable: false};
              } else {
                return item;
              }
            }).filter((item: any) => item !== null);
            dispatch(setStockOrders(newOrders));
          } else {
            createNotification(t("stock_order_not_canceled"), "error");
          }
        } else {
          createNotification(t("stock_order_not_canceled"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_order_not_canceled"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('stock_order_cancel'),
      content: t('stock_order_cancel_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleExportDeliveryList = useCallback((title: any, name: any, orderID: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportPDF = () => {
      exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
          type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const settings = {
      isOpen: true,
      title: `${title} <span>${name}</span>`,
      content: null,
      download: null,
    };
    dispatch(setAttachmentModal(settings));
    exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
      const buf = a2ab(result.data);
      const buftype = 'application/pdf';
      const blob = new Blob([buf], {
        type: buftype,
      });
      const content = URL.createObjectURL(blob);
      const settings = {
        content: content,
        download: handleExportPDF,
        type: format,
      };
      dispatch(setAttachmentModal(settings));
    }).catch(() => {
      createNotification(t('something_went_wrong'), 'error');
      const settings = {
        isOpen: false,
        title: null,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
    });
  }, [dispatch, exportService, t]);

  const handleCheck = useCallback((orderID: any) => {
    let newSelectedOrders = selectedOrders;
    if(isChecked(orderID)) {
      newSelectedOrders = newSelectedOrders.filter((item: any) => item !== orderID);
    } else {
      newSelectedOrders = [...newSelectedOrders, orderID];
    }
    dispatch(setStockSelectedOrders(newSelectedOrders));
  }, [dispatch, isChecked, selectedOrders]);

  const handleCheckAll = useCallback(() => {
    let newSelectedOrders = selectedOrders;
    if(isCheckedAll()) {
      newSelectedOrders = [];
    } else {
      newSelectedOrders = orders.map((item: any) => { return item.orderID; });
    }
    dispatch(setStockSelectedOrders(newSelectedOrders));
  }, [dispatch, isCheckedAll, selectedOrders, orders]);

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            {
              (getUserRole(userData.userObject.roleType) === "director" && orders.length > 1) ? (
                <th>
                  <CheckboxInput checked={isCheckedAll()} onChange={handleCheckAll}/>
                </th>
              ) : null
            }
            <th>
              {t('stock_order_number')}
            </th>
            <th>
              {t('stock_order_date')}
            </th>
            <th>
              {t('stock_order_status')}
            </th>
            {
              getUserRole(userData.userObject.roleType) === "director" ? (
                <th>
                  {t('stock_order_user')}
                </th>
              ) : null
            }
            <th>
              {t('stock_order_child')}
            </th>
            <th>
              {t('stock_total_price')}
            </th>
            <th>
              {t('stock_total_items')}
            </th>
            <th>
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            orders.length > 0 ? orders.map((item: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                {
                  (getUserRole(userData.userObject.roleType) === "director" && orders.length > 1) ? (
                    <td className={classes.checkbox}>
                      <CheckboxInput checked={isChecked(item.orderID)} onChange={() => handleCheck(item.orderID)}/>
                    </td>
                  ) : null
                }
                <td className={classes.name}>
                  <span>{item.orderID}</span>
                </td>
                <td className={classes.date}>
                  {DateFormat(moment(item.dateTime), "defaulttime", languageData, t)}
                </td>
                <td className={classes.status}>
                  {getOrderStatusName(item.orderStatusID)}
                </td>
                {
                  getUserRole(userData.userObject.roleType) === "director" ? (
                    <td className={classes.user}>
                      <p>{item.userName}</p>
                    </td>
                  ) : null
                }
                <td className={classes.child}>
                  <p>{item.childID ? getChildData(item.childID).displayName : ''}</p>
                </td>
                <td className={classes.price}>
                  <span>
                    <Currency quantity={parseFloat(getTotalPrice(item.orderID)[0])} currency={getTotalPrice(item.orderID)[1].iso}/>
                  </span>
                </td>
                <td className={classes.quantity}>
                  <span>
                    {getTotalQuantity(item.orderID)}
                  </span>
                </td>
                <td className={classes.action}>
                  <IconButton tooltip={t('stock_order_detail')} tooltipMaxWidth={200}  className={classes.button} onClick={() => handleClickView(item.orderID, item.schoolID)}>
                    <SVG src="eye"/>
                  </IconButton>
                  {
                    (getUserRole(userData.userObject.roleType) === "director" && item.orderStatusID !== 7 && item.orderStatusID !== 6) ? (
                      <IconButton tooltip={t('stock_order_change_status')} tooltipPosition='bottom' tooltipMaxWidth={200} className={classes.button} onClick={() => handleClickChangeStatus(item.orderID)}>
                        <SVG src="edit"/>
                      </IconButton>
                    ) : null
                  }
                  {
                    item.isEditable ? (
                      <IconButton tooltip={t('stock_order_cancel')} tooltipPosition='bottom' tooltipMaxWidth={200} className={classes.button} onClick={() => handleClickDelete(item.orderID)}>
                        <SVG src="trash-outlined"/>
                      </IconButton>
                    ) : null
                  }
                  {
                    getUserRole(userData.userObject.roleType) === "director" ? (
                      <IconButton tooltip={t('stock_order_export_delivery_list')} tooltipPosition='bottom' tooltipMaxWidth={200} className={classes.button} onClick={() => handleExportDeliveryList(t('stock_order_delivery_list'), `${t('stock_order_delivery_list_short')} ${item.orderID} - ${DateFormat(moment(item.dateTime), "defaulttime", languageData, t)}`, item.orderID, "pdf")}>
                        <SVG src="export-pdf"/>
                      </IconButton>
                    ) : null
                  }
                </td>
              </tr>
            )) : (
              <tr className={classes.tableRow}>
                <td colSpan={getUserRole(userData.userObject.roleType) === "director" ? 8 : 7}>
                  <span>{t('no_stock_orders_found')}</span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default ShopOrdersTable;