import Button from '@mui/material/Button';
import React from 'react';
import RenderMenuItemSub from './';
import SVG from '../../../Images/SvgRenderer';
import { createCypressDataText, isCypress } from '../../../../utils/useCypress';
import { createUseStyles } from 'react-jss';
import { getSchoolsSettings } from 'src/utils/useFunctions';
import { getUserHomepage } from 'src/utils/useUser';
import { setSchoolUrlsModal, setWarningModal } from '../../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from '../../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: boolean;
};

const useStyles = createUseStyles((theme: any) => ({
  menuItem: {
    width: 'calc(100% - 32px)',
    '& .MuiSvgIcon-root': {
      transition: '0.3s all',
    },
    '&.Mui-expanded': {
      '& .MuiSvgIcon-root': {
        transform: 'rotate(90deg)',
      }
    },
  },
  menuItemList: {
    display: 'none',
    flexDirection: 'column',
    marginLeft: '1rem',
    '&.active': {
      display: 'flex',
    },
  },
  menuItemLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '24px',
    fontSize: '12px',
    color: (props: Props) => {
      if(!props.isVisible) return `${theme.colors.grey[600]} !important`;
      else return theme.colors.primaryBlue[700];
    },
    '&:disabled': {
      filter: 'grayscale(100%)',
      '& > *': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiButton-iconSizeMedium': {
      '& > *:first-child': {
        fontSize: 'unset',
      }   
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > span.text': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        paddingLeft: '0.25rem',      
      },
    },
    '&.MuiButton-text': {
      padding: '8px 20px',
    },
    '& .MuiButton-startIcon': {
      color: 'inherit',
    },
    '& .MuiButton-endIcon': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      position: 'relative',
      '& > svg': {
        color: theme.colors.yellow[500],      
      },
      '& > p': {
        width: '12px',
        height: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.primaryBlue[500],
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.colors.white,
        borderRadius: '100%',
      },
    },
    '& svg': {
      color: 'inherit',
      height: "20px",
      width: "20px",
    },
    '&.active': {
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      boxShadow: theme.shadows[2],
    },
  },
  expander: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 'inherit !important',
    '& svg': {
      height: "24px",
      color: 'inherit',
      transform: 'rotate(0deg)',
      transition: 'transform 0.25s',
    },
    '&.active': {
      '& svg': {
        transform: 'rotate(90deg)',
      },
    },
  },
  menuItemIcon: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
  },
}));

type RenderMenuItemType = {
  node: any;
  parent: any;
  itemsOpenHandle: any;
};

const RenderMenuItem: React.FunctionComponent<RenderMenuItemType> = ({node, parent, itemsOpenHandle}) => {

  const classes = useStyles({
    isVisible: node.isVisible,
  });
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  
  const schoolSettings = userData.schoolSettings;
  const userAccess = userData.userAccess;
  const activeClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active).length;
  const isUnreadThreads = communicationData.notificationsCount > 0;
  
  const [isOpened, setIsOpened] = useState(false);
  const itemStatus = itemsOpenHandle[node.to];

  const handleOnClick = () => {
    if(node.validations) {
      if(node.validations.includes('attendance')) {
        if(activeClasses === 0) {
          const settings = {
            isOpen: true,
            title: t('function_unavailable'),
            content: t('function_unavailable_attendance'),
          };
          dispatch(setWarningModal(settings));
          return;
        }
      } else if(node.validations.includes('bakalariUrl')) {
        const bakalariSchoolUrls = getSchoolsSettings('bakalariLoginUrl', schoolSettings).map((item: any) => { return { schoolID: item.schoolID, url: item.item }; });
        if(bakalariSchoolUrls.length === 1) {
          window.open(bakalariSchoolUrls[0].url);
        } else {
          const settings = {
            isOpen: true,
            urls: bakalariSchoolUrls,
            title: t('bakalari_login'),
          };
          dispatch(setSchoolUrlsModal(settings))
        }
        return;
      } else if(node.validations.includes('mealmenuUrl')) {
        const mealmenuSchoolUrls = getSchoolsSettings('mealmenuUrl', schoolSettings).map((item: any) => { return { schoolID: item.schoolID, url: item.item }; });
        if(mealmenuSchoolUrls.length === 1) {
          window.open(mealmenuSchoolUrls[0].url);
        } else {
          const settings = {
            isOpen: true,
            urls: mealmenuSchoolUrls,
            title: t('mealmenu_login'),
          };
          dispatch(setSchoolUrlsModal(settings))
        }
        return;
      } else if(node.validations.includes('back')) {
        navigate(getUserHomepage(dispatch, dataData, layoutData, userData));
        return;
      }
    }
    if(node.onClick) {
      node.onClick();
    }
    if(!Array.isArray(node.submenus)) {
      navigate(node.to);
    } else {
      setIsOpened(!isOpened);
    }       
  };              

  useEffect(() => {
    itemsOpenHandle[node.to] = false;
    if(parent != null) {
      if(location.pathname === node.to) {
        itemsOpenHandle[parent] = true;
      }  
    }
    if(itemsOpenHandle[node.to]) {
      setIsOpened(true);
    }    
  }, [itemStatus, itemsOpenHandle, location.pathname, node.to, parent], [itemStatus]);
  
  return (
    <div className={classes.menuItem}>
      <Button className={`${classes.menuItemLabel} ${isOpened ? 'active' : null} ${((location.pathname === node.to || location.pathname.includes(node.to)) && node.isVisible) ? 'active' : null}`} onClick={handleOnClick} startIcon={node.icon !== "" ? (<SVG src={node.icon}/>) : null} endIcon={(<>{(node.validations && node.validations.includes("communication") && isUnreadThreads) ? (<p/>) : null}{(((node.requirements && node.requirements.includes("premium") && !userData.membership.active) ? (<SVG src="lock-outlined"/>) : null) || ((node.requirements && node.requirements.includes("subscription") && !userAccess.subscription) ? (<SVG src="lock-outlined"/>) : null))}{Array.isArray(node.submenus) ? (<div className={`${classes.expander} ${isOpened && 'active'}`}><SVG src="chevron-right"/></div>) : null}</>)} data-cy={isCypress() ? "leftMenu" + createCypressDataText(node.title) : null}>
        <span className="text">{t(`${node.title}`)}</span>
      </Button>
      {
        Array.isArray(node.submenus) ? (
          <div className={`${classes.menuItemList} ${isOpened ? 'active' : null}`}>
          {
            Array.isArray(node.submenus) ? node.submenus.map((subnode: any, key: any) => (
              <RenderMenuItemSub node={subnode} parent={node.to} itemsOpenHandle={itemsOpenHandle} key={`k_${key}`}/>
            )) : null
          }
          </div>
        ) : null
      }
    </div>  
  );
}

export default RenderMenuItem;