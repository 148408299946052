import EmployeeCard from 'src/components/Cards/EmployeeCard';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import Masonry from 'react-masonry-component';
import React, { useCallback, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getFiltersKeys, getFiltersKeysArray, onlyUnique } from 'src/utils/useFunctions';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  employeesPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
  },
  employeeCardWrapper: {
    width: '400px',
    maxWidth: '100%',
    marginBottom: '20px',
    borderRadius: '24px',
  },
  masonryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  masonry: {
    width: '100%',
    maxWidth: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    width: '100%',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filtersData = useAppSelector((state: any) => state.filters);
  const userData = useAppSelector((state: any) => state.user); 

  const classes = useStyles();

  const employeesScroll: any = useRef(null);
  const containerWidth = employeesScroll.current ? employeesScroll.current.clientWidth : 0;

  const getLimit = useCallback(() => {
    const count = (Math.floor(containerWidth / 400)) * 2;
    return count === 0 ? 1 : count;
  }, [containerWidth]);

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoadingMore: false,
    page: 1,
  });

  const filtersParams = getFiltersKeys(filtersData.filterParams, {});

  const employeesIds = filtersParams.employeeID ? filtersParams.employeeID.map((item: any) => { return parseInt(item); }) : [];

  const employeesTotal = employeesIds;
  const employeesList = employeesTotal.slice(0, state.page * getLimit());

  const handleChangeEmployees = (employees: any) => {
    employees = employees.filter((onlyUnique)).map((item: any) => {
      return item.employeeID;
    });
    dispatch(setFilterParams({employeeID: employees}));
  };
  
  const handleClose = () => {
    navigate('/timeline');
  };

  const onScroll = () => {
    if(employeesScroll.current) {
      const { scrollTop, scrollHeight, clientHeight }: any = employeesScroll.current;
      if(scrollTop + clientHeight > scrollHeight - 100) {
        if(!state.isLoadingMore && employeesList.length < employeesTotal.length) {
          setState("isLoadingMore", true);
          setTimeout(() => {
            setState("isLoadingMore", false);
            setState("page", state.page + 1);
          }, 500);
        };
      }
    }
  };

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["employees", "employees_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {});
      await saveUserSettings(dispatch, userData, "filters", ["employees"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: false,
        isAllowedPostType: false,
        isAllowedGalleryType: false,
        isAllowedActivityType: false,
        isAllowedChildID: false,
        isAllowedEmployeeID: true,
        isAllowedDate: false,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: false,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    }
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["children", "children_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["children"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      } else {
        dispatch(setFilterParams({}));
      }
      dispatch(setIsFilterParamsLoaded(true));
      setState("isLoading", false);
    }
  }, [dispatch, setState, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded,], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);
  
  return (
    <div className={classes.employeesPage} onScroll={onScroll} ref={employeesScroll}>
      <div className={classes.wrapper}>
        {
          employeesTotal.length === 0 ? (
            <EmployeesSelect
              isInModal={true}
              isModalOpen={true}
              modalTitle="employees"
              modalOnClose={handleClose}
              modalOnSave={handleChangeEmployees}
              isSelectAll={true}
              isSelectInAllSchool={true}
              isMultipleSelect={true}
              isAllowSearch={true}
              mode="select"
              modalCloseOnSave={false}
              modalEmployeesCount="unique"
              modalAllowEmployeesCount={true}
              modalAllowCancel={true}
              modalAllowClose={true}
            />
          ) : (
            <div className={classes.masonryWrapper}>
              <Masonry className={classes.masonry} options={{columnWidth: 400, gutter: 25, fitWidth: true, horizontalOrder: true}}>
              {
                employeesList.map((employeeID: any, key: any) => {
                  return (
                    <div className={classes.employeeCardWrapper} key={`k_${key}`}>
                      <EmployeeCard employeeID={employeeID}/>
                    </div>
                  );               
                })
              }
              </Masonry>
              {
                state.isLoadingMore ? (
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                ) : null
              }
              {
                employeesList.length >= employeesTotal.length ? ( 
                  <EndOfScroll text={t("no_more_employees")}/>
                ) : null
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default PageTemplate;