import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';

interface Props {
  position: any;
};

const useStyles = createUseStyles((theme: any) => ({
  dropdownWrapper: {
    position: 'relative',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  visibleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '37px',
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: '10px',
    boxSizing: 'border-box',
    padding: '20px 10px',
    cursor: 'pointer',
    '&.disabled': {
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      cursor: 'auto',
    },
  },
  hiddenWrapper: {
    position: "absolute",
    left: "0",
    bottom: (props: Props) => {
      if(props.position === "top") return "calc(100% + 10px)";
      else return "";
    },
    top: (props: Props) => {
      if(props.position === "bottom") return "calc(100% + 10px)";
      else return "";
    },
    width: "calc(100% - 40px)",
    height: "auto",
    maxHeight: 'calc(40vh - 40px)',
    overflow: 'auto',
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    padding: "20px",
    borderRadius: "10px",
    zIndex: "9999",
    "& > span": {
      fontSize: "14px",
      marginBottom: "1rem",
      fontWeight: "bold",
    },
    cursor: "auto",     
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '& > svg': {
      transition: 'transform 0.25s',
      color: theme.colors.primaryBlue[500],
      transform: 'rotate(0deg)',
      width: '24px',
      height: '24px',
    },
    '&.open': {
      '& > svg': {
        transform: 'rotate(-90deg)',
      },
    },
  }
}));

type DropdownType = {
  visibleChildren: any;
  hiddenChildren: any;
  isDropdownOpen: boolean;
  setIsDropdownOpen: any;
  label?: any;
  className?: any;
  position?: 'top' | 'bottom',
  disabled?: boolean;
};

const Dropdown: React.FunctionComponent<DropdownType> = ({visibleChildren, hiddenChildren, isDropdownOpen, setIsDropdownOpen, label, position = "top", disabled, className}) => {

  const classes = useStyles({
    position: position,
  });

  const handleClickAway = () => {
    setIsDropdownOpen(false);
  };
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${classes.dropdownWrapper} ${className ? className : null}`}>
        {
          label ? (
            <label>
              {label}
            </label>
          ) : null
        }
        <div className={`${classes.visibleWrapper} ${disabled ? 'disabled' : null}`} onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}>
          {
            visibleChildren
          }
          <span className={`${classes.chevron} ${isDropdownOpen ? 'open' : null}`}>
            <SVG src="chevron-right"/>
          </span>
        </div>
        {
          isDropdownOpen ? (
            <div className={classes.hiddenWrapper}>
              {
                hiddenChildren
              }
            </div>
          ) : null
        }
      </div>
    </ClickAwayListener>
  );
}

export default Dropdown;