import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { isCypress } from 'src/utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  spinnerWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px',  
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '5px',
      maxWidth: 'calc(100% - 10px)',
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      marginBottom: '0',
      color: theme.colors.primaryBlue[500],
      margin: '0px 10px',
      fontWeight: '700',
    },
  },
  button: {
    color: theme.colors.primaryBlue[500],
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    '&:disabled': {
      color: theme.colors.grey[560],
    },
  },
}));

type SpinnerType = {
  children: any;
  prevVisible?: boolean;
  nextVisible?: boolean;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  onClickPrev: any;
  onClickNext: any;
  className?: any;
  disabled?: any;
};

const Spinner: React.FunctionComponent<SpinnerType> = ({children, prevVisible = true, nextVisible = true, prevDisabled = false, nextDisabled = false, onClickPrev, onClickNext, className, disabled}) => {

  const classes = useStyles();
  
  return (
    <div className={`${classes.spinnerWrapper} ${className ? className : null}`}>
      {
        prevVisible ? (
          <IconButton className={classes.button} onClick={onClickPrev} disabled={disabled || prevDisabled} dataCy='spinnerPrevButton'>
            <SVG src="chevron-left"/>
          </IconButton>
        ) : null
      }
      <p data-cy={isCypress() ? 'spinnerContent' : null}>{children}</p>
      {
        nextVisible ? (
          <IconButton className={classes.button} onClick={onClickNext} disabled={disabled || nextDisabled} dataCy='spinnerNextButton'>
            <SVG src="chevron-right"/>
          </IconButton>
        ) : null
      }
    </div>
  );
}

export default Spinner;