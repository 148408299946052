import DateFormat from 'src/utils/dateFormat';
import MediaGrid from '../MediaGrid';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setCommunicationGalleryModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  align: any;
};

const useStyles = createUseStyles(() => ({
  communicationGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    width: 'calc(100% - 30px)',
    maxWidth: 'calc(100% - 30px)',
    padding: '15px',
    maxHeight: '400px',
    height: '400px',
    borderWidth: '0px',
    borderStyle: 'solid',
    borderColor: 'inherit',
    borderRadius: '24px',
    '& > div': {
      '& > div': {
        justifyContent: (props: Props) => props.align,
      },
    }
  },
}));

type CommunicationGalleryType = {
  messages: any;
  messageID: any;
  currentMediaID?: any;
  display?: any;
  align?: "flex-start" | "flex-end" | "center",
};

const CommunicationGallery: React.FunctionComponent<CommunicationGalleryType> = ({ messages, messageID, currentMediaID, display = "responsive", align = "center" }) => {

  const classes = useStyles({
    align: align,
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const languageData = useAppSelector((state: any) => state.language);
  const messageData = messages.find((item: any) => item.messageID === messageID);
  const photos = messageData.media.filter((item: any) => item.type === "image");
  const files = messageData.media.filter((item: any) => item.type === "attachment");
  const videos = messageData.media.filter((item: any) => item.type === "video");
  const [gridData, setGridData]: any = useState([]);
  
  useEffect(() => {
    const tmpPhotos = photos.map((photo: any) => {
      return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name };
    });
    const tmpFiles = files.map((file: any) => {
      return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size };
    });
    const tmpVideos = videos.map((video: any) => {
      return { mediaID: video.mediaID, type: 'video', thumbLink: video.thumbLink, link: video.link, name: video.name };
    });
    const tmpGridData = [].concat(tmpPhotos, tmpFiles, tmpVideos).filter((item: any) => item);
    if(tmpGridData.length > 0) {
      setGridData(tmpGridData);
    }
  }, [messageID, photos, files, videos, dispatch], [messageData]);

  const handleOpenModal = (mediaID: any) => {
    const settings = {
      isOpen: true,
      mediaID: mediaID,
      mediaList: gridData,
    };
    dispatch(setCommunicationGalleryModal(settings));  
  };
  
  return (
    <Tooltip title={DateFormat(messageData.createdDate, "timeline", languageData, t)} placement='left' arrow>
      <div className={classes.communicationGallery} data-cy={isCypress() ? "communicationGallery" : null}>
        <MediaGrid
          data={gridData}
          currentMediaID={currentMediaID}
          display={display}
          width='500px'
          maxWidth='100%'
          onModalOpen={handleOpenModal}
        />
      </div>
    </Tooltip>
  );
}

export default CommunicationGallery;