import AuthenticatedImage from '../../Items/AuthenticatedImage';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../utils/useUser';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  classCard: {
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: '100vw',
    position: 'relative',
    overflow: 'hidden',
  },
  classHeader: {
    display: 'flex',
    width: '100%',
    minHeight: '90px',
    height: 'fit-content',
    position: 'relative',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.black,
    borderRadius: '24px 24px 0 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }, 
  },
  nameBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '14px 28px',
    maxWidth: '50%',
    flex: '1 1 50%',
    color: theme.colors.white,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flex: 'unset',
      alignItems: 'center',
    }, 
  },
  name: {
    fontSize: '24px',
    fontWeight: 'bold',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  details: {
    fontSize: '14px',
    fontWeigth: '300',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  otherBlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '14px 28px',
    maxWidth: '50%',
    flex: '1 1 50%',
    color: theme.colors.white,
    '&:empty': {
      display: 'none',
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      height: '44px',
      width: '1px',
      backgroundColor: theme.colors.white,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flex: 'unset',
      alignItems: 'center',
      '&::after': {
        content: `''`,
        position: 'absolute',
        top: '0',
        left: '10%',
        right: '10%',
        height: '1px',
        width: '80%',
        backgroundColor: theme.colors.white,
        transform: 'unset',
      },
    }, 
  },
  childrenCount: {
    fontSize: '14px',
    fontWeigth: '300',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  schoolWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    paddingTop: '8px',
    backgroundColor: theme.colors.white,
  },
  schoolHeader: {
    display: 'flex',
    width: '100%',
    height: '188px',
    position: 'relative',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.black,
  }, 
  schoolImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    '& > div': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.colors.alwaysWhite,
    },
  },
  schoolBadge: {
    position: 'absolute',
    top: '18px',
    right: '0',
    width: '115px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,133,255,0.7)',
    color: theme.colors.white,
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '11px 24px 9px 15px',
    borderRadius: '6px 0px 0px 6px',
    '& > svg': {
      marginRight: '9px',
      width: '24px',
      height: '24px',
    },
  },
  schoolBlock: {
    padding: '0px 28px 22px 28px',
  },
  schoolName: {
    display: 'flex',
    color: '#2D3142',
    fontSize: '22px',
    padding: '22px 0px 10px 0px',
    fontWeight: 'bold',
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },  
  },
  schoolDescription: {
    fontSize: '14px',
    color: theme.colors.grey[650],
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& a': {
      color: theme.colors.primaryBlue[500],
      textDecoration: 'underline',
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
}));

type ItemsType = {
  classID: any;
};

const ClassCard: React.FunctionComponent<ItemsType> = ({classID}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const classData = useMemo(() => dataData.classes.find((theclass: any) => theclass.classID === classID), [classID, dataData.classes]);
  
  return (
    <div className={classes.classCard}>
      <div className={classes.classHeader}>
        <div className={classes.nameBlock}>
          <span className={classes.name}>
            {htmlParse(classData.name)}
            {
              getUserRole(userData.userObject.roleType) !== "parent" ? (
                <span className={classes.childrenCount}>
                  ({classData.childrenID.length})
                </span>
              ) : null
            }
          </span>
          <span className={classes.details}>{classData.isArchived ? t('class_archived') : t('class_unarchived')}</span>
        </div>
          <div className={classes.otherBlock}>
            {
              classData.isSchoolCare ? (
                <span className={classes.details}>{t('class_schoolcare')}</span>
              ) : null
            }
            {
              getUserRole(userData.userObject.roleType) !== "parent" ? (
                <span className={classes.details}>{classData.active ? t('class_active') : t('class_inactive')}</span>
              ) : null
            }
          </div>
      </div>
      {
        classData.schoolID ? (
          <div className={classes.schoolWrapper}>
            <div className={classes.schoolHeader}>
              <AuthenticatedImage className={classes.schoolImage} thumbLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === classData.schoolID).logo.thumbLink} fullsizeLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === classData.schoolID).logo.fullsizeLink} preferQuality="fullsize"/>
              <span className={classes.schoolBadge}>
                <SVG src="school-cap-outlined"/>
                {t('school')}
              </span>
            </div>
            <div className={classes.schoolBlock}>
              <span className={classes.schoolName}>{htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === classData.schoolID).name)}</span>
              {
                dataData.schools.find((schoolData: any) => schoolData.schoolID === classData.schoolID)?.description ? (
                  <div className={classes.schoolDescription}>
                    {htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === classData.schoolID).description)}
                  </div>
                ) : null
              }
            </div>            
          </div>
        ) : null
      }       
    </div>
  );
}

export default ClassCard;