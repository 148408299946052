import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React, { useRef } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
import Spinner from '../../Forms/Spinner';
import Switch from '../../Forms/Switch';
import { base64ToArrayBuffer } from 'src/utils/useFunctions';
import { canvasPreview } from '../../../utils/useCanvas';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setPhotoChangeModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useDebounce } from '../../../utils/useDebounce';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginTop: '16px',
    gap: '10px',
  },
  image: {
    width: 'auto',
    height: '100%',
  },
  cropWrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: 'inherit',
    width: 'auto',
    '& > div': {
      maxHeight: 'inherit',
      backgroundColor: theme.colors.black,
    },
  },
  settings: {
    display: 'flex',
    gap: '10px',
    marginTop: '16px',
    marginRight: 'auto',
    flexWrap: 'wrap',
    '& > div': {
      width: 'auto',
      '& > p': {
        color: theme.colors.black,
        fontWeight: 'normal',
        fontSize: "13px",
      }
    }
  },
}));

const PhotoChangeModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const [state, setState] = useStates({
    crop: null,
    completedCrop: null,
    scale: 1,
    rotate: 0,
    aspect: modalsData.photoChangeModal.aspect,
  });
  const imgRef: any = useRef<HTMLImageElement>(null);
  const canvasRef: any = useRef<HTMLCanvasElement>(null);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      file: null,
      onSave: null,
      title: null,
      aspect: null
    };
    dispatch(setPhotoChangeModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleAspectRatio = () => {
    if(state.aspect === undefined) {
      setState("aspect", 1);
      const { width, height } = imgRef.current;
      const newcrop: Crop = centerCrop(makeAspectCrop({ unit: '%', width: state.crop.width}, 1 / 1, width, height), width, height);
      setState("crop", newcrop);
    } else {
      setState("aspect", undefined);
    }
  };

  const handleRotateAdd = () => {
    if(state.rotate < 360) {
      setState("rotate", parseFloat(state.rotate) + 10);
    } else {
      setState("rotate", 10);
    }
  };

  const handleRotateSubtract = () => {
    if(state.rotate > 0) {
      setState("rotate", parseFloat(state.rotate) - 10);
    } else {
      setState("rotate", 350);
    }
  };

  const handleScaleAdd = () => {
    if(state.scale < 10) {
      setState("scale", (parseFloat(state.scale) + 0.1).toFixed(1));
    } else {
      setState("scale", 0.1);
    }
  };

  const handleScaleSubtract = () => {
    if(state.scale > 0.1) {
      setState("scale", (parseFloat(state.scale) - 0.1).toFixed(1));
    } else {
      setState("scale", 10);
    }
  };

  useDebounce(
    async () => {
      if(state.completedCrop?.width && state.completedCrop?.height && imgRef.current && canvasRef.current) {
        canvasPreview(imgRef.current, canvasRef.current, state.completedCrop, state.scale, state.rotate);
      }
    },
    100,
    [state.completedCrop, state.scale, state.rotate],
  );

  const handleSave = (e: any) => {
    const base64 = canvasRef.current.toDataURL("image/jpeg").split(",")[1];
    const photoData = {...modalsData.photoChangeModal.photo, image: state.completedCrop ? base64ToArrayBuffer(base64) : modalsData.photoChangeModal.photo.raw, size: Math.ceil(base64.length / 4) * 3};
    modalsData.photoChangeModal.onSave(photoData);
    handleClose(e);
  };

  const onImageLoad = (e: any) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const newcrop: Crop = modalsData.photoChangeModal.aspect === 1 ? centerCrop(makeAspectCrop({ unit: '%', width: 90}, 1 / 1, width, height), width, height) : { unit: '%', x: 5, y: 5, width: 90, height: 90};
    setState("crop", newcrop);
  };

  const setCrop = (value: any) => {
    setState("crop", value);
  };

  const setCompletedCrop = (value: any) => {
    setState("completedCrop", value);
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "photoChangeModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{modalsData.photoChangeModal.title}</p>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.cropWrapper}>
            <ReactCrop
              crop={state.crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={state.aspect}
            >
              <img 
                ref={imgRef}
                className={classes.image}
                alt="crop"
                src={"data:" + modalsData.photoChangeModal.photo.type + ";base64," + modalsData.photoChangeModal.photo.base64}
                style={{ transform: `scale(${state.scale}) rotate(${state.rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            {
              !!state.completedCrop ? (
                <canvas
                  ref={canvasRef}
                  style={{
                    display: 'none',
                    objectFit: 'contain',
                    width: state.completedCrop.width,
                    height: state.completedCrop.height,
                  }}
                />
              ) : null
            }
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.settings}>
            <Switch checked={state.aspect === undefined ? false : true} onChange={handleAspectRatio} label={t('aspect_ratio_same')}/>
            {
              !!state.completedCrop ? (
                <>
                  <Spinner onClickPrev={handleRotateSubtract} onClickNext={handleRotateAdd}>{t('rotate')}: {state.rotate}°</Spinner>
                  <Spinner onClickPrev={handleScaleSubtract} onClickNext={handleScaleAdd}>{t('scale')}: {Math.round(state.scale * 100)}%</Spinner>
                </>
              ) : null
            }
          </div>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton buttonType="primary" onClick={handleSave} dataCy="saveButton">
            {t("save")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  )
};  

export default PhotoChangeModal;
