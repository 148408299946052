import { useMediaQuery, useTheme } from "@mui/material";

const useBreakpoint = () => {
    const theme: any = useTheme();

    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const co= useMediaQuery(theme.breakpoints.only('co'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const bg = useMediaQuery(theme.breakpoints.only('bg'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));
    const xxl = useMediaQuery(theme.breakpoints.only('xxl'));
    const xxxl = useMediaQuery(theme.breakpoints.only('xxxl'));
    const xxxxl = useMediaQuery(theme.breakpoints.only('xxxxl'));

    const getBreakPointName = () => {
        if(xs) { return "xs"; }
        if(sm) { return "sm"; }
        if(co) { return "co"; }
        if(md) { return "md"; }
        if(bg) { return "bg"; }
        if(lg) { return "lg"; }
        if(xl) { return "xl"; }
        if(xxl) { return "xxl"; }
        if(xxxl) { return "xxxl"; }
        if(xxxxl) { return "xxxxl"; }
    }

    return getBreakPointName();
}

export default useBreakpoint;