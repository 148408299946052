import React from 'react';
import SchoolsSelect from 'src/components/Selects/SchoolsSelect';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  schoolSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    height: '100%',
  },
  schoolsSelect: {
    maxHeight: '100%',
    overflow: 'auto',
    borderRadius: '24px',
    height: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& > div:last-of-type': {
        flex: '1 1 auto',
      },
    },
  },
  customContainer: {
    backgroundColor: theme.colors.grey[100],
    maxHeight: '100%',
    overflow: 'auto',
  },
  customTopWrapper: {
    position: 'sticky',
    top: '-0px',
    zIndex: '2',
  },
}));

type SchoolSelectType = {
  onSelect: any;
};

const SchoolSelect: React.FunctionComponent<SchoolSelectType> = ({ onSelect }) => {

  const classes = useStyles();

  const handleChange = (_: any, value: any) => {
    onSelect(value.school.schoolID);
  };

  return (
    <div className={classes.schoolSelectWrapper}>
      <SchoolsSelect onClickSchool={handleChange} isAllowOnlyOneSchoolAtOnce={true} customClasses={{schoolsSelect: classes.schoolsSelect, container: classes.customContainer, topWrapper: classes.customTopWrapper}}/>
    </div>
  );
};

export default SchoolSelect;