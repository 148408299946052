import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import NormalButton from '../../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getDayName, isKey } from 'src/utils/useFunctions';
import { setTimetableData } from 'src/store/actions/timetable.actions';
import { setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
  employees: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  employeePhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '6px',
      minWidth: '35px',
      minHeight: '35px',
      width: '35px',
      height: '35px',
      maxWidth: '35px',
      maxHeight: '35px',
    },
    '& > p': {
      fontSize: '12px',
      marginLeft: '8px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  fullname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
}));

type ContainerType = {
  onClose: any;
};

const Break: React.FunctionComponent<ContainerType> = ({ onClose }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);
  const employees = timetableData.school === null ? [] : dataData.employees.filter((employee: any) => employee.schoolID === timetableData.school.schoolID && employee.enableForTimetable).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID } });

  const isEdit = modalsData.timetableHourDetailModal.timetableID === null ? false : true;
  const timetableID = isEdit ? modalsData.timetableHourDetailModal.timetableID : null;
  const breakData = isEdit ? (timetableData.data.timetables.filter((hour: any) => hour.timetableID === timetableID).length === 1 ? timetableData.data.timetables.find((hour: any) => hour.timetableID === timetableID) : []) : [];
  const roomData = timetableData.rooms.filter((item: any) => item.roomID === breakData.roomID).length === 1 ? timetableData.rooms.find((item: any) => item.roomID === breakData.roomID) : [];
  const breakEmployees = isEdit ? employees.filter((employee: any) => breakData.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; }) : [];
  const weekday = modalsData.timetableHourDetailModal.weekday;
  const hour = modalsData.timetableHourDetailModal.hour;
  const type = modalsData.timetableHourDetailModal.type;
  const mode = modalsData.timetableHourDetailModal.mode;
  const isEditable = modalsData.timetableHourDetailModal.isEditable;
  const isFirstBreakZero = modalsData.timetableHourDetailModal.isFirstBreakZero;

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      weekday: weekday,
      hour: hour,
      type: type,
      mode: mode,
      timetableID: timetableID,
      isFirstBreakZero: isFirstBreakZero,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  const handleDelete = () => {
    timetableService && timetableService.deleteHour(timetableID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("timetable_break_deleted"), "success");
          onClose();
          const newData = {...timetableData.data, timetables: timetableData.data.timetables.filter((item: any) => item.timetableID !== timetableID)};
          dispatch(setTimetableData(newData));
        } else {
          createNotification(t("timetable_break_not_deleted"), "error");
        }
      } else {
        createNotification(t("timetable_break_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_break_not_deleted"), "error");
    });
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{t('timetable_break_detail')} - {t(getDayName(weekday))} {isFirstBreakZero ? (hour + 1) : hour}. {t('timetable_break').toLowerCase()}</p>
        </div>
        <CloseButton onClick={onClose} dataCy="timesButton"/>
      </div>
      <div className={classes.body}>
        <div className={classes.item}>
          <p className={classes.title}>
            <SVG src="info-circle-outlined"/>
            {t('timetable_room')}
          </p>
          <p className={classes.detail}>
            {roomData.name}
          </p>
        </div>
        <div className={classes.item}>
          <span className={classes.title}>
            <SVG src="user-circle-outlined"/>
            {t('timetable_break_teachers')}
          </span>
          <div className={classes.employees}>
          {
            breakEmployees.map((employee: any, key: any) => (
              <div className={classes.employeePhoto} key={`k_${key}`}>
                <AuthenticatedImage thumbLink={getEmployeeData(employee.employeeID).photo.thumbLink}/>
                <p className={classes.fullname}>{getEmployeeData(employee.employeeID).firstname + ' ' + getEmployeeData(employee.employeeID).surname}</p>
              </div>
            ))
          }
          </div>
        </div>
      </div>
      {
        isEditable ? (
          <div className={classes.footer}>
            <NormalButton buttonType="clear" onClick={handleDelete} dataCy="cancelButton">
              {t("timetable_break_delete")}
            </NormalButton>
            <NormalButton onClick={handleEdit} dataCy="editButton">
              {t('timetable_break_edit')}
            </NormalButton>
          </div>
        ) : null
      }
    </>
  );
};

export default Break;