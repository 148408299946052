import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { setShoppingCartModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const Shop: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockData = useAppSelector((state: any) => state.stock);
  const shoppingCartCount = stockData.cart.map((item: any) => { return item.items }).flat().length;

  const handleShoppingCartClick = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setShoppingCartModal(settings));
  };

  return (
    <>
      <TopBarButton className={modalsData.shoppingCartModal.isOpen ? 'active' : null} icon={<SVG src="shopping-cart"/>} onClick={handleShoppingCartClick} dataCy="topBarButtonInfo" tooltip={t('stock_shopping_cart')}>
        {
          shoppingCartCount === 0 ? null : (
            <span>{shoppingCartCount < 100 ? shoppingCartCount : "99+"}</span>
          )
        }
      </TopBarButton>
    </>
  );
}

export default Shop;