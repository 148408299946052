import CalendarDay from './Day';
import CalendarMonth from './Month';
import CalendarWeek from './Week';
import CalendarYear from './Year';
import moment from 'src/utils/moment';
import React, { useCallback, useRef } from 'react';
import { arrayToObject, getFiltersKeysArray, isKey } from 'src/utils/useFunctions';
import { calendarViewModes } from 'src/utils/useCalendar';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setCalendarDate, setCalendarEvents, setCalendarFilterChildID, setCalendarFilterClassID, setCalendarFilterSchoolID, setCalendarFilterType, setCalendarFilterUserID, setCalendarHoverEvent, setCalendarIsLoading, setCalendarViewMode } from 'src/store/actions/calendar.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles(() => ({
  calendar: {
    width: '100%',
    height: '100%',
  },
}));

const Calendar: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const userData = useAppSelector((state: any) => state.user);
  const calendarService = useAppSelector((state: any) => state.services).calendarService;
  const windowHandler: any = window;

  const calendarRef: any = useRef(null);
  const isFirstTime: any = useRef(true);

  const curDate = calendarData.date;
  const viewMode = calendarData.viewMode;
  const isLoading = calendarData.isLoading;

  const filterSchoolID = calendarData.filterSchoolID;
  const filterClassID = calendarData.filterClassID;
  const filterChildID = calendarData.filterChildID;
  const filterUserID = calendarData.filterUserID;
  const filterType = calendarData.filterType;

  useEffect(() => {
    windowHandler.isCalendarReady = !isLoading;
  }, [windowHandler, isLoading], [isLoading]);

  const getDateFrom =  useCallback(() => {
    let date;
    if(viewMode.value === "day") {
      date = moment(curDate);
    } else if(viewMode.value === "week") {
      date = moment(curDate).isoWeekday(1);
    } else if(viewMode.value === "month") {
      date = moment(moment(curDate).date(1)).subtract(moment(moment(curDate).date(1)).day(), 'days').add(1, 'day');
    } else if(viewMode.value === "year") {
      date = moment(curDate).startOf("year");
    }
    return moment(date).format("YYYY-MM-DD");
  }, [curDate, viewMode]);

  const getDateTo = useCallback(() => {
    let date;
    if(viewMode.value === "day") {
      date = moment(curDate).add(1, 'day');
    } else if(viewMode.value === "week") {
      date = moment(curDate).isoWeekday(7);
    } else if(viewMode.value === "month") {
      date = moment(moment(curDate).date(1)).subtract(moment(moment(curDate).date(1)).day(), 'days').add(42, 'day');
    } else if(viewMode.value === "year") {
      date = moment(curDate).endOf("year");
    }
    return moment(date).format("YYYY-MM-DD");
  }, [curDate, viewMode]);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["calendar", "calendar_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {});
      await saveUserSettings(dispatch, userData, "filters", ["calendar"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(filtersData.filterParams.childID.length === 1) {
      dispatch(setCalendarDate(moment()));
      dispatch(setCalendarFilterSchoolID([]));
      dispatch(setCalendarFilterClassID([]));
      dispatch(setCalendarFilterChildID([{childID: filtersData.filterParams.childID[0]}]));
      dispatch(setCalendarFilterUserID([]));
      dispatch(setCalendarFilterType([]));
      dispatch(setCalendarViewMode(calendarViewModes[2]));
    } else {
      if(viewMode) {
        dispatch(setCalendarEvents([]));
        dispatch(setCalendarIsLoading(true));
        const payload = {
          dateFrom: getDateFrom(),
          dateTo: getDateTo(),
          schoolID: filterSchoolID.length !== 0 ? filterSchoolID.map((item: any) => { return item.schoolID }).join(",") : "",
          classID: filterClassID.length !== 0 ? filterClassID.map((item: any) => { return item.classID }).join(",") : "",
          childID: filterChildID.length !== 0 ? filterChildID.map((item: any) => { return item.childID }).join(",") : "",
          userID: filterUserID.length !== 0 ? filterUserID.map((item: any) => { return item.userID }).join(",") : "",
          type: filterType.length !== 0 ? filterType.map((item: any) => { return item.value }).join(",") : "",
        };
        calendarService && calendarService.listCalendar(payload).then((result: any) => {
          if(result.data) {
            if(result.data.events) {
              dispatch(setCalendarEvents(result.data.events));
              dispatch(setCalendarIsLoading(false));
            } else {
              createNotification(t("calendar_events_not_loaded"), "error");
            }
          } else {
            createNotification(t("calendar_events_not_loaded"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("calendar_events_not_loaded"), "error");
        });
      }
    }
  }, [curDate, calendarService, getDateFrom, getDateTo, viewMode, dispatch, t, filtersData, filterSchoolID, filterClassID, filterChildID, filterUserID, filterType], [curDate, viewMode, filterSchoolID, filterClassID, filterChildID, filterUserID, filterType]);

  useEffect(() => {
    if(isFirstTime.current === false) {
      const filterParams = {
        curDate: curDate,
        schoolID: filterSchoolID.length !== 0 ? filterSchoolID.map((item: any) => { return item.schoolID; }) : [],
        classID: filterClassID.length !== 0 ? filterClassID.map((item: any) => { return item.classID; }) : [],
        childID: filterChildID.length !== 0 ? filterChildID.map((item: any) => { return item.childID; }) : [],
        userID: filterUserID.length !== 0 ? filterUserID.map((item: any) => { return { userID: item.userID, schoolID: item.schoolID }; }) : [],
        type: filterType.length !== 0 ? filterType.map((item: any) => { return { name: item.name, value: item.value }; }) : [],
        viewMode: {name: viewMode.name, value: viewMode.value},
      };
      saveFilters(filterParams);
    } else {
      isFirstTime.current = false;
    }
  }, [filterChildID, filterClassID, filterSchoolID, filterType, filterUserID, curDate, viewMode, saveFilters], [filterChildID, filterClassID, filterSchoolID, filterType, filterUserID, curDate, viewMode]);

  useEffect(() => {
    if(getUserSetting(userData.userSettings, "addons", ["calendar", "calendar_filters_save"])) {
      const customFilters = getUserSetting(userData.userSettings, "filters", ["calendar"]);
      if(Array.isArray(customFilters)) {
        const customFiltersObject = arrayToObject(customFilters);
        if(customFiltersObject.curDate) {
          dispatch(setCalendarDate(moment(customFiltersObject.curDate)));
        }
        if(customFiltersObject.schoolID) {
          const schoolID = customFiltersObject.schoolID.map((item: any) => { return getSchoolData(item)});
          dispatch(setCalendarFilterSchoolID(schoolID));
        }
        if(customFiltersObject.classID) {
          const classID = customFiltersObject.classID.map((item: any) => { return getClassData(item)});
          dispatch(setCalendarFilterClassID(classID));
        }
        if(customFiltersObject.childID) {
          const childID = customFiltersObject.childID.map((item: any) => { return { childID: item }; })
          dispatch(setCalendarFilterChildID(childID));
        }
        if(customFiltersObject.userID) {
          dispatch(setCalendarFilterUserID(customFiltersObject.userID));
        }
        if(customFiltersObject.type) {
          dispatch(setCalendarFilterType(customFiltersObject.type));
        }
        if(customFiltersObject.viewMode) {
          dispatch(setCalendarViewMode(customFiltersObject.viewMode));
        }
      }
    }
  }, [userData.userSettings, dispatch, getChildData, getClassData, getSchoolData], []);

  useEffect(() => {
    return () => {
      dispatch(setCalendarDate(moment()));
      dispatch(setCalendarViewMode(calendarViewModes[2]));
      dispatch(setCalendarIsLoading(true));
      dispatch(setCalendarEvents([]));
      dispatch(setCalendarHoverEvent(null));
      dispatch(setCalendarFilterSchoolID([]));
      dispatch(setCalendarFilterClassID([]));
      dispatch(setCalendarFilterChildID([]));
      dispatch(setCalendarFilterUserID([]));
      dispatch(setCalendarFilterType([]));
    }
  }, [dispatch, filtersData], []);

  return viewMode ? (
    <div className={classes.calendar} data-cy={isCypress() ? (isLoading ? "CalendarIsLoading" : "CalendarIsLoaded") : null} ref={calendarRef}>
      {
        viewMode.value === "day" ? (
          <CalendarDay/>
        ) : null
      }
      {
        viewMode.value === "week" ? (
          <CalendarWeek/>
        ) : null
      }
      {
        viewMode.value === "month" ? (
          <CalendarMonth/>
        ) : null
      }
      {
        viewMode.value === "year" ? (
          <CalendarYear/>
        ) : null
      }
    </div>
  ) : null;
};

export default Calendar;