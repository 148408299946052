import CloseButton from 'src/components/Buttons/CloseButton';
import NormalButton from '../../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getDayName, isKey } from 'src/utils/useFunctions';
import { setTimetableData } from 'src/store/actions/timetable.actions';
import { setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
}));

type ContainerType = {
  onClose: any;
};

const Teacher: React.FunctionComponent<ContainerType> = ({ onClose }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);

  const timetableID = modalsData.timetableHourDetailModal.timetableID;
  const hourData = timetableData.data.timetables.filter((hour: any) => hour.timetableID === timetableID).length === 1 ? timetableData.data.timetables.find((hour: any) => hour.timetableID === timetableID) : [];
  const subjectData = timetableData.subjects.filter((item: any) => item.subjectID === hourData.subjectID).length === 1 ? timetableData.subjects.find((item: any) => item.subjectID === hourData.subjectID) : [];
  const roomData = timetableData.rooms.filter((item: any) => item.roomID === hourData.roomID).length === 1 ? timetableData.rooms.find((item: any) => item.roomID === hourData.roomID) : [];
  const weekday = modalsData.timetableHourDetailModal.weekday;
  const hour = modalsData.timetableHourDetailModal.hour;
  const type = modalsData.timetableHourDetailModal.type;
  const mode = modalsData.timetableHourDetailModal.mode;
  const isEditable = modalsData.timetableHourDetailModal.isEditable;

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      weekday: weekday,
      hour: hour,
      type: type,
      mode: mode,
      timetableID: timetableID,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  const handleDelete = () => {
    timetableService && timetableService.deleteHour(timetableID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("timetable_teacher_hour_deleted"), "success");
          onClose();
          const newData = {...timetableData.data, timetables: timetableData.data.timetables.filter((item: any) => item.timetableID !== timetableID)};
          dispatch(setTimetableData(newData));
        } else {
          createNotification(t("timetable_teacher_hour_not_deleted"), "error");
        }
      } else {
        createNotification(t("timetable_teacher_hour_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_teacher_hour_not_deleted"), "error");
    });
  };

  return hourData.length !== 0 ? (
    <>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{t('timetable_hour_detail')} {subjectData.name} - {t(getDayName(weekday))} {hour}. {t('hour').toLowerCase()}</p>
        </div>
        <CloseButton onClick={onClose} dataCy="timesButton"/>
      </div>
      <div className={classes.body}>
        <div className={classes.item}>
          <p className={classes.title}>
            <SVG src="info-circle-outlined"/>
            {t('timetable_subject')}
          </p>
          <p className={classes.detail}>
            {subjectData.name}
          </p>
        </div>
        {
          roomData.name ? (
            <div className={classes.item}>
              <p className={classes.title}>
                <SVG src="info-circle-outlined"/>
                {t('timetable_room')}
              </p>
              <p className={classes.detail}>
                {roomData.name}
              </p>
            </div>
          ) : null
        }
      </div>
      {
        isEditable ? (
          <div className={classes.footer}>
            <NormalButton buttonType="clear" onClick={handleDelete} dataCy="cancelButton">
              {t("timetable_hour_delete")}
            </NormalButton>
            <NormalButton onClick={handleEdit} dataCy="editButton">
              {t('timetable_hour_edit')}
            </NormalButton>
          </div>
        ) : null
      }
    </>
  ) : null;
};

export default Teacher;