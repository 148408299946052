import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import Currency from 'react-currency-formatter';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum, isKey } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setStockItemDetailModal, setStockItemHandleModal } from '../../../store/actions/modals.actions';
import { setStockItems } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
      width: '100%',
      '& > thead': {
        '& > tr': {
          '& > th': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  bodyWrapper: {
    display: 'flex',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > span': {
      display: 'block',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  mediaBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '50%',
    '& > div': {
      height: '200px',
      width: 'fit-content',
      maxWidth: '100%',
      '& > img': {
        height: '100%',
        borderRadius: '14px',
      }
    },
  },
  tabs: {
    width: '100% !important',
    marginBottom: '16px',
  },
  variantName: {
    display: 'block',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left',
  }
}));

const StockItemDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);

  const schoolSettings = userData.schoolSettings;

  const isValid = modalsData.stockItemDetailModal.itemID === null ? false : true;
  const itemID = isValid ? modalsData.stockItemDetailModal.itemID : null;
  const schoolID = isValid ? modalsData.stockItemDetailModal.schoolID : null;
  const itemData = isValid ? (stockData.items.filter((item: any) => item.itemID === itemID).length === 1 ? stockData.items.find((item: any) => item.itemID === itemID) : []) : [];
  
  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
  
  const tax = 1;
  const price = itemData.variant.length === 0 ? (itemData.price * tax) : (Math.min(...itemData.variant.map((item: any) => { return (item.price * tax); })) === Math.max(...itemData.variant.map((item: any) => { return (item.price * tax); })) ? itemData.variant[0].price : [Math.min(...itemData.variant.map((item: any) => { return (item.price * tax); })), Math.max(...itemData.variant.map((item: any) => { return (item.price * tax); }))]);

  const quantity = itemData.quantity === null ? null : (itemData.variant.length === 0 ? itemData.quantity : itemData.variant.map((item: any) => { return item.quantity; }).reduce(handleSum, 0));

  const isVariants = itemData.variant.length === 0 ? false : true;

  const [viewMode, setViewMode] = useState("basic");

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      itemID: null,
      schoolID: null,
    };
    dispatch(setStockItemDetailModal(settings));
  };

  const tabsItems: any = [
    {
      name: 'stock_item_basic',
      value: 'basic',
      isEnabled: true,
    },
    isVariants ? {
      name: 'stock_item_variants',
      value: 'variants',
      badge: itemData.variant.length,
      badgeType: 'primary',
      isEnabled: true,
    } : null,
  ];

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      itemID: itemID,
      schoolID: schoolID,
    };
    dispatch(setStockItemHandleModal(settings));
  };

  const handleDelete = () => {
    stockService && stockService.deleteItem(itemID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("stock_item_deleted"), "success");
          onCloseModal();
          const newItems = stockData.items.map((item: any) => {
            if(item.itemID === itemID) {
              return null;
            } else {
              return item;
            }
          }).filter((item: any) => item !== null);
          dispatch(setStockItems(newItems));
        } else {
          createNotification(t("stock_item_not_deleted"), "error");
        }
      } else {
        createNotification(t("stock_item_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_item_not_deleted"), "error");
    });
  };

  return isValid ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockItemDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_item_detail')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <TabsMenu className={classes.tabs} items={tabsItems.filter((item: any) => item !== null)} selected={viewMode} onSelect={setViewMode}/>
          {
            viewMode === "basic" ? (
              <div className={classes.bodyWrapper}>
                <div className={classes.block}>
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="info-circle-outlined"/>
                      {t('stock_item_name')}
                    </p>
                    <p className={classes.detail}>
                      <span>{itemData.name}</span>
                    </p>
                  </div>
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="info-circle-outlined"/>
                      {t('stock_item_available_quantity')}
                    </p>
                    <p className={classes.detail}>
                      {quantity === null ? t('stock_quantity_unlimited') : quantity}
                    </p>
                  </div>
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="info-circle-outlined"/>
                      {t('stock_item_price_without_tax')}
                    </p>
                    <p className={classes.detail}>
                      { 
                        Array.isArray(price) ? (
                          <>
                            <Currency quantity={parseFloat(price[0])} currency={getCurrency.iso}/>
                            &nbsp;-&nbsp;
                            <Currency quantity={parseFloat(price[1])} currency={getCurrency.iso}/>
                          </>
                        ) : (
                          <Currency quantity={parseFloat(price)} currency={getCurrency.iso}/>
                        )
                      }
                    </p>
                  </div>
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="info-circle-outlined"/>
                      {t('stock_item_tax_rate')}
                    </p>
                    <p className={classes.detail}>
                    {itemData.taxRate}%
                    </p>
                  </div>
                  {
                    itemData.sku !== "" ? (
                      <div className={classes.item}>
                        <p className={classes.title}>
                          <SVG src="info-circle-outlined"/>
                          {t('stock_item_sku')}
                        </p>
                        <p className={classes.detail}>
                          {itemData.sku}
                        </p>
                      </div>
                    ) : null
                  }
                  {
                    itemData.upc !== "" ? (
                      <div className={classes.item}>
                        <p className={classes.title}>
                          <SVG src="info-circle-outlined"/>
                          {t('stock_item_upc')}
                        </p>
                        <p className={classes.detail}>
                          {itemData.upc}
                        </p>
                      </div>
                    ) : null
                  }
                </div>
                <div className={classes.mediaBlock}>
                  {
                    (itemData.media.length !==0 && itemData.media.photos.length > 0) ? (
                      <AuthenticatedImage fullsizeLink={itemData.media.photos[0].fullsizeLink} isResponsive={false}/>
                    ) : null
                  }
                </div>
              </div>
            ) : viewMode === "variants" ? (
              <table>
                <thead>
                  <tr>
                    <th>{t('stock_item_variant_name')}</th>
                    <th>{t('stock_item_variant_price_without_tax')}</th>
                    <th>{t('stock_item_variant_quantity')}</th>
                    <th>{t('stock_item_variant_sku')}</th>
                    <th>{t('stock_item_variant_upc')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    itemData.variant.map((item: any, key: any) => (
                      <tr key={`k_${key}`}>
                        <td className={classes.variantName}>
                          <span>{item.name}</span>
                        </td>
                        <td><Currency quantity={parseFloat(item.price)} currency={getCurrency.iso}/></td>
                        <td>{item.quantity === null ? t('stock_quantity_unlimited') : item.quantity}</td>
                        <td>{item.sku}</td>
                        <td>{item.upc}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="clear" onClick={handleDelete} dataCy="deleteButton">
            {t("stock_item_delete")}
          </NormalButton>
          <NormalButton onClick={handleEdit} dataCy="editButton">
            {t('stock_item_edit')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default StockItemDetailModal;