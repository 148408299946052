import config from 'src/constants/config';
import parse from 'html-react-parser';
import React, { useCallback } from 'react';
import { setSvgs } from '../../../store/actions/medias.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';

type SVGType = {
  src?: any,
  children?: any,
};

const SVG: React.FunctionComponent<SVGType> = ({ src = "", children = null, ...rest }) => {

  const mediasData: any = useAppSelector((state: any) => state.medias); 
  const svgsData: any = useMemo(() => mediasData.svgs ? mediasData : {svgs: []}, [mediasData]); 
  const dispatch = useAppDispatch();

  const svgUrl = useMemo(() => (src && typeof src === "string") ? src.includes('/') ? src + '.svg?v=' + config.APP_VERSION : '/resources/icons/' + src + '.svg?v=' + config.APP_VERSION : "", [src]);
  const svgIcon =  useMemo(() => svgsData.svgs.filter((svg: any) => svg.url === svgUrl).length === 0 ? <svg/> : svgsData.svgs.find((svg: any) => svg.url === svgUrl).text, [svgUrl, svgsData.svgs]); 
  
  const isSprite = (src && typeof src === "string") ? !src.includes("/") : false;

  const fetchSvg = useCallback(() => {
    let url = src + '.svg';
    if(!url.includes("/")) {
      url = "/resources/icons/" + src + '.svg';
    }
    url = url + '?v=' + config.APP_VERSION;
    const isCached = svgsData.svgs.filter((svg: any) => svg.url === url).length;
    if(isCached === 0) {
      fetch(url).then((r: any) => r.text()).then(text => {
        const svgText = text.toString();
        const svgObj = { text: svgText, url: url };
        dispatch(setSvgs(svgObj));
      }); 
    }
  }, [dispatch, src, svgsData.svgs]); 

  useEffect(() => {
    if(!isSprite) {
      fetchSvg();
    }
  }, [src, fetchSvg, isSprite], [src]);

  const isValid = useMemo(() => src !== "" ? (typeof svgIcon === "string" ? (svgIcon.toString().includes("html") ? false : true) : false) : false, [src, svgIcon]);

  return (
    <>
      {
        isSprite ? (
          <svg {...rest}>
            <use xlinkHref={`#twigsee-${src}`}/>
          </svg>
        ) : (
          <>
            { 
              isValid ? (
                <>
                  {parse(svgIcon.toString())}
                </>
              ) : children ? children : null
            }
          </>
        )
      }
    </>
  );
}

export default SVG;