import appEnvironment from '../constants/environment';
import config from '../constants/config';
import StorageService from '../services/storage.service';
import { langs } from '../constants/languages';
import { ObjectType } from '../types/types';

const apiServers: ObjectType = config.API_SERVERS;

const getAppApiServer = () => {
  return getAppData() ? (getAppData().apiServer ? getAppData().apiServer : Object.values(apiServers)[0].url) : Object.values(apiServers)[0].url;  
};

const getCurrentApiServer = (apiServersList: any, apiUrl: any) => {
  if(apiUrl) {
    if(apiServersList.filter((item: any) => item.url === apiUrl).length === 0) {
      return apiServersList[0];
    } else {
      return apiServersList.find((item: any) => item.url === apiUrl);
    }
  } else {
    return apiServersList[0];
  }
};

const getAppAccessToken = () => {
  const tokenKey: string = config.TOKEN_KEY;
  return StorageService.getItem(tokenKey);
};

const setAppAccessToken = (newValue: any) => {
  const tokenKey: string = config.TOKEN_KEY;
  StorageService.setItem(tokenKey, newValue);
};

const removeAppAccessToken = () => {
  const tokenKey: string = config.TOKEN_KEY;
  StorageService.removeItem(tokenKey);
};

const getAppEnvironmentData = () => {
  const environmentData = appEnvironment[config.APP_ENVIRONMENT]; 
  return environmentData;
};

const getAppEnvironmentApiData = (apiData?: string) => {
  const apiServer = apiData? apiData : getAppApiServer();
  const apiServers = appEnvironment[config.APP_ENVIRONMENT].apiServers;
  const environmentData = (url: any) => {
    for (const serverKey in apiServers) {
      if (apiServers.hasOwnProperty(serverKey)) {
        if (apiServers[serverKey].url === url) {
          return apiServers[serverKey];
        }
      }
    }
    return null;
  } 
  return environmentData(apiServer) === null ? {} : environmentData(apiServer);
};

const getAppData = () => {
  const dataKey: string = config.DATA_KEY;
  const savedData: ObjectType = StorageService.getItem(dataKey);
  return savedData === null ? {} : savedData;
};

const setAppData = (newValue: any) => {
  const dataKey: string = config.DATA_KEY;
  const savedData = getAppData();
  const newData = Object.assign(typeof savedData === 'object' ? savedData : {}, newValue);
  StorageService.setItem(dataKey, newData);
};

const removeAppData = (removeKey: any) => {
  const dataKey: string = config.DATA_KEY;
  const savedData = getAppData();
  delete savedData[removeKey];
  StorageService.setItem(dataKey, savedData);
};

const getAppLanguage = () => {
  const navigatorLanguage = (((navigator?.language)?.split("-"))[0])?.toUpperCase();
  const appLanguage = getAppData() ? (getAppData().language ? getAppData().language.toLowerCase() : (langs.indexOf(navigatorLanguage) !== -1 ? navigatorLanguage : "en")) : null;
  return appLanguage;  
};

export {
  getAppApiServer,
  getCurrentApiServer,
  getAppAccessToken,
  setAppAccessToken,
  removeAppAccessToken,
  getAppEnvironmentData,
  getAppEnvironmentApiData,
  getAppData,
  setAppData,
  removeAppData,
  getAppLanguage,
};