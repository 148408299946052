import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'src/utils/moment';
import React, { useCallback } from 'react';
import RenderChild from '../RenderChild';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { onlyUnique } from 'src/utils/useFunctions';
import { setExcuseNotesCurrentChildren, setExcuseNotesCurrentChildrenData, setExcuseNotesCurrentClass, setExcuseNotesDate, setExcuseNotesViewMode } from 'src/store/actions/excusenotes.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  childrenListWrapper: {
    display: 'flex',
    padding: '13px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '13px 9px 13px',
    },
  },
  childrenAllList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    padding: '13px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    maxWidth: '100%',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '13px 9px 13px',
    },
  },
  childrenList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    padding: '0px 18px 13px',
    alignItems: 'flex-start',
    width: 'calc(100% - 36px)',
    maxWidth: '100%',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      padding: '0px 9px 13px',
    },
  },
  childWrapper: {
    width: (props: Props) => (props.size + 30) + 'px',
    flex: (props: Props) => '0 0 ' + (props.size + 30) + 'px',  
  },
  attendanceDetail: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minWidth: '100%',
    width: 'calc(100% - 48px)',
    padding: '0px 24px 13px',
    gap: '8px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& svg': {
        width: '10px',
        height: '10px',
        marginRight: '2px',
      },    
    },
  },
  attendanceStatus: {
    fontWeight: 'bold',  
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    height: '48px',
    minHeight: '48px',
    maxHeight: '48px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    margin: '8px auto',
    color: theme.colors.primaryBlue[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type RenderChildrenType = {
  state: any;
  setState: any;
  schoolData?: any;
  classData?: any;
};

const RenderChildren: React.FunctionComponent<RenderChildrenType> = ({
  state,
  setState,
  schoolData,
  classData,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sizes = {
    xs: 32,
    sm: 38,
    md: 40,
    lg: 48,
    xl: 54,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? sizes.xl : isLg ? sizes.lg : isMd ? sizes.md : isSm ? sizes.sm : isXs ? sizes.xs : 30;

  const classes = useStyles({
    size: size,
  });

  const isSelectedChild = useMemo(() => (searchData: any) => {
    return state.selectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedChildren]);

  const attendanceData = useAppSelector((state: any) => state.attendance);
  const userData = useAppSelector((state: any) => state.user);
  const classID = useMemo(() => (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? null : classData.classID, [classData, userData.userObject.roleType, state.displayMode]);
  const classInfo = useMemo(() => state.functions.getClassData(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const childrenList = useMemo(() => (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? state.functions.getChildren() : state.functions.getChildrenByClassID(classID), [classID, state.functions, userData.userObject.roleType, state.displayMode], [classID, state.functions, state.isShowArchived, state.search, userData.userObject.roleType, state.displayMode]);
  const totalChildren = useMemo(() => state.functions.getChildrenByClassID(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalSelectedChildren = useMemo(() => state.functions.getChildrenByClassID(classID).filter((item: any) => isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: classID })), [classID, isSelectedChild, state.functions, state.isSelectInAllClass], [classID, isSelectedChild, state.functions, state.isAllowArchived, state.search, state.isSelectInAllClass]);
  const totalPresentChildren = useMemo(() => totalSelectedChildren.length, [totalSelectedChildren.length]);
  const totalAttendanceChildren = useMemo(() => totalChildren.filter((item: any) => state.functions.getChildData(item.childID).classData && state.functions.getChildData(item.childID).classData.filter((clsItem: any) => clsItem.classID === classID).length !== 0 ? (state.functions.getChildData(item.childID).classData.find((clsItem: any) => clsItem.classID === classID).morningAttendanceStatusId || state.functions.getChildData(item.childID).classData.find((clsItem: any) => clsItem.classID === classID).afternoonAttendanceStatusId) : (state.functions.getChildData(item.childID).morningAttendanceStatusId || state.functions.getChildData(item.childID).afternoonAttendanceStatusId)).filter((item: any) => !isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: classID })), [classID, isSelectedChild, state.functions, state.isSelectInAllClass, totalChildren], [classID, isSelectedChild, state.functions, state.isAllowArchived, state.search, state.isSelectInAllClass, totalChildren]);
  const totalMissingCount = useMemo(() => totalChildren.length - totalSelectedChildren.length - totalAttendanceChildren.length, [totalAttendanceChildren.length, totalChildren.length, totalSelectedChildren.length]);

  const handleExcuseNotesButton = useCallback(() => {
    const childrenIDs = childrenList.map((item: any) => { return item.childID; }).filter(onlyUnique);
    dispatch(setExcuseNotesCurrentChildren(childrenIDs));
    dispatch(setExcuseNotesCurrentChildrenData(childrenList));
    dispatch(setExcuseNotesCurrentClass(classInfo));
    dispatch(setExcuseNotesDate(moment(attendanceData.date)));
    dispatch(setExcuseNotesViewMode('class'));
    navigate('/excuse-notes');
  }, [attendanceData.date, childrenList, classInfo, dispatch, navigate]);

  const statusDisplay = useMemo(() => (status: any) => {
    if(status.id === 1) return totalPresentChildren === 0 ? false : true;
    else if(status.id === 5) return totalMissingCount === 0 ? false : true;
    else return status.count === 0 ? false : true;
  }, [totalPresentChildren, totalMissingCount]);

  const statusCounter = useMemo(() => (status: any) => {
    if(status.id === 1) return totalPresentChildren;
    else if(status.id === 5) return totalMissingCount;
    else return status.count;
  }, [totalPresentChildren, totalMissingCount]);

  const childrenRender = useMemo(() => (
    <>
      {
        (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? (
          <div className={classes.childrenListWrapper}>
            <div className={`${classes.childrenAllList} ${state.customClasses ? (state.customClasses.childrenList ? state.customClasses.childrenList : "") : ""}`} data-cy={isCypress() ? 'childrenContainer' : null}>
              {
                childrenList.map((theChild: any, key: any) => (
                  <div className={classes.childWrapper} key={`k_${key}`}>
                    <RenderChild state={state} setState={setState} childData={theChild} size={size}/>   
                  </div>  
                ))
              }
            </div>
          </div>
        ) : (
          <div className={classes.childrenWrapper} data-cy={isCypress() ? 'childrenWrapper' : null}>
            {
              state.isShowAttendanceCount ? (
                <div className={classes.attendanceDetail}>
                {
                  classInfo.classAttendanceStatus ? classInfo.classAttendanceStatus.filter((status: any) => statusDisplay(status)).map((status: any, key: any) => (
                    <span className={classes.attendanceStatus} key={`k_${key}`} style={{color: status.color}} data-cy={isCypress() ? 'attendanceStatus' + status.id : null}>
                      <CircleIcon/>
                      {statusCounter(status)} {status.name}
                    </span>
                  )) : null
                }
                </div>
              ) : null
            }
            <div className={`${classes.childrenList} ${state.customClasses ? (state.customClasses.childrenList ? state.customClasses.childrenList : "") : ""}`} data-cy={isCypress() ? 'childrenContainer' : null}>
              {
                childrenList.map((theChild: any, key: any) => (
                  <div className={classes.childWrapper} key={`k_${key}`}>
                    <RenderChild state={state} setState={setState} schoolData={schoolData} classData={classData} childData={theChild} size={size}/>   
                  </div>  
                ))
              }
            </div>
            {
              state.mode === "attendance" ? (
                <Button className={classes.button} onClick={handleExcuseNotesButton} data-cy={isCypress() ? 'excuseNotesButton' : null}>
                  <SVG src="calendar-attendance"/>
                  <span>{t('excuse_notes')}</span>
                  <span className={classes.chevron}>
                    <SVG src="chevron-right"/>
                  </span>
                </Button>
              ) : null
            }
          </div>
        )
      }
    </>
  ), [childrenList, classData, classInfo.classAttendanceStatus, classes.attendanceDetail, classes.attendanceStatus, classes.button, classes.chevron, classes.childWrapper, classes.childrenList, classes.childrenListWrapper, classes.childrenAllList, classes.childrenWrapper, handleExcuseNotesButton, schoolData, setState, size, state, statusCounter, statusDisplay, t, userData.userObject.roleType]);

  return childrenRender;

}

export default RenderChildren;