import Banner from 'src/components/Layouts/Banner';
import menuItems from 'src/constants/menu';
import NormalButton from 'src/components/Buttons/NormalButton';
import RadioInput from 'src/components/Forms/RadioInput';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveUserSettings } from '../../../../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { mergeMenuItems, validateItemRequirements } from 'src/utils/useFunctions';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0px auto 0px',
    maxHeight: '100%',
    overflow: 'visible',
    height: '100%',
  },
  menuItem: {
    width: '100%',
    height: '56px',
    '& .MuiSvgIcon-root': {
      transition: '0.3s all',
    },
  },
  menuItemLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '24px',
    backgroundColor: theme.colors.grey[100],
    color: theme.colors.primaryBlue[700],
    fontSize: '12px',
    height: '100%',
    '&:disabled': {
      filter: 'grayscale(100%)',
      '& > *': {
        backgroundColor: 'transparent !important',
      },
    },
    '& .MuiButton-iconSizeMedium': {
      '& > *:first-child': {
        fontSize: 'unset',
      }   
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      '& > span.text': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        paddingLeft: '0.25rem',      
      },
    },
    '&.MuiButton-text': {
      padding: '8px 20px',
    },
    '& .MuiButton-startIcon': {
      color: 'inherit',
    },
    '& .MuiButton-endIcon': {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      '& > svg': {
        color: theme.colors.yellow[500],      
      },
    },
    '& svg': {
      color: 'inherit',
      height: "20px",
      width: "20px",
    },
  },
  menuItemIcon: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
  },
  banner: {
    width: 'calc(40% - 40px)',
    maxWidth: '100%',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      borderRadius: '0px',
    },
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
  settings: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}));

const Homepage: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const isSaving = useRef(false);

  const homepageSettings = getUserSetting(userData.userSettings, "customizations", ["homepage"]);

  const mainMenuItems = menuItems.main.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const mainMenuItemSettings = getUserSetting(userData.userSettings, "customizations", ["mainmenu"]);

  const mainMenuItemsUpdated = mainMenuItemSettings ? mergeMenuItems(mainMenuItems, mainMenuItemSettings) : mainMenuItems;

  const mainMenuItemsAvailable = mainMenuItemsUpdated.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null).filter((item: any) => item.isVisible && item.canBeHome);

  const mainMenuItemsSortable = mainMenuItemsAvailable.map((item: any) => {
    return {
      title: t(item.title),
      to: item.to,
      icon: item.icon,
      order: item.order,
    };
  });

  mainMenuItemsSortable.sort((a: any, b: any) => { return a.order - b.order; });

  const getHomepageItem = (item: any) => {
    const itemExist = mainMenuItemsSortable.filter((node: any) => node.to === item).length === 1;
    if(itemExist) {
      return mainMenuItemsSortable.find((node: any) => node.to === item)
    } else {
      const newHomepage = mainMenuItemsSortable[0];
      saveUserSettings(dispatch, userData, "customizations", ["homepage"], newHomepage.to);
      return newHomepage;
    }
  };

  const [state, setState] = useStates({
    homepage: (homepageSettings && homepageSettings.length) > 0 ? getHomepageItem(homepageSettings) : mainMenuItemsSortable[0],
  });

  const handleChange = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      const oldValue = state.homepage;
      const newValue = value.to;
      setState("homepage", value);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["homepage"], newValue);
      if(result) {
        createNotification(t('homepage_saved'), "success");
        isSaving.current = false;
      } else {
        setState("homepage", oldValue);
        createNotification(t('failed_homepage'), "error");
        isSaving.current = false;
      }
    }
  };

  const handleReset = async () => {
    const result = await saveUserSettings(dispatch, userData, "customizations", ["homepage"], '');
    if(result) {
      setState("lastMainMenuItems", []);
      createNotification(t('reset_default_reseted'), "success");
    } else {
      createNotification(t('reset_default_failed'), "error");
      setState("mainMenuItems", state.lastMainMenuItems);
    }
  };

  return (
    <>
    <div className={classes.box}>
      <div className={classes.menuList}>
        {mainMenuItemsSortable.map((node: any, key: any) => {
          return (
          <div className={classes.menuItem} key={key}>
            <Button className={classes.menuItemLabel} startIcon={node.icon !== "" ? (<SVG src={node.icon}/>) : null} endIcon={<RadioInput checked={node.to === state.homepage.to} disabled={node.to === state.homepage.to} icon={<SVG src="circle-outlined"/>} checkedIcon={<SVG src="home-circle"/>} onClick={() => handleChange(node)}/>} onClick={() => handleChange(node)}>
              <span className="text">{t(`${node.title}`)}</span>
            </Button>
          </div>  
          )
          })
        }
      </div>
      <NormalButton buttonType='secondary' onClick={handleReset} disabled={homepageSettings === ''}>
        {t('reset_default')}
      </NormalButton>
    </div>
    <Banner className={classes.banner} icon={true} type='info'>
      {t(`homepage_detail`)}
    </Banner>
    </>
  );
};

export default Homepage;