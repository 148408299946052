import * as UserService from '../../../../../services/user.service';
import { createNotification } from '../../../../../utils/createNotification';
import { setMembership, setOrder } from '../../../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const PremiumStatus: React.FunctionComponent = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const orderService = useAppSelector((state: any) => state.services).orderService;
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get('path');

  useEffect(() => {
    if(path) {
      orderService && orderService.getOrder(path).then((result: any) => {
        if(result.status === 200) {
          if(result.data) {
            if(result.data.order) {
              const order = result.data.order;
              dispatch(setOrder(order));
              UserService && UserService && UserService.getUserData().then((result: any) => {
                const userMembershipContent = result.data.user.membership;
                dispatch(setMembership(userMembershipContent));
                navigate('/settings/premium');
              }).catch(() => {
                createNotification(t('premium_failed'), 'error');
              });
            }
          }
        }
      }).catch(() => {
        createNotification(t('premium_failed'), 'error');
      });
    } else {
      navigate('/settings/premium');
    }
  }, [dispatch, navigate, orderService, path, t], []);

  return null;
};

export default PremiumStatus;