import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  text?: string;
  type?: string;
  timeout?: number;
  date?: number;
};

interface ArrayModel {
  list: Model[];
};

const initialState: ArrayModel = {
  list: [],
};

const slice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotifications(state, action: PayloadAction<Model>) {
      state.list = [...state.list, action.payload];
    },
  }
});

export default slice;