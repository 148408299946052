import Calendar from '../../../components/Calendars/ExcuseNotes';
import ChildrenListSelect from '../../../components/Selects/ChildrenListSelect';
import CircularProgress from '@mui/material/CircularProgress';
import DateFormat from '../../../utils/dateFormat';
import ExcuseNoteCard from '../../../components/Cards/ExcuseNoteCard';
import ExcuseNoteGroupCard from '../../../components/Cards/ExcuseNoteGroupCard';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import NormalButton from '../../../components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import SVG from '../../../components/Images/SvgRenderer';
import tabs from '../../../constants/tabs';
import TabsMenu from '../../../components/Menus/TabsMenu';
import { addToExcuseNotesList, addToExcuseNotesMonths, clearExcuseNotesList, clearExcuseNotesMonths, setExcuseNotesCurrentChild, setExcuseNotesCurrentChildren, setExcuseNotesCurrentClass, setExcuseNotesDate, setExcuseNotesList, setExcuseNotesViewMode } from '../../../store/actions/excusenotes.actions';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { isKey, monthsInDateRange } from 'src/utils/useFunctions';
import { setExcuseNotesDetailModal, setExcuseNotesHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  excuseNotesPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '0px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    paddingBottom: '16px',
    '& > div': {
      [theme.breakpoints.down('xl')]: {
        width: '70%',
      },
    },
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      width: '70%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  excuseNotesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    position: 'relative',
    height: '42px',
    '& > button': {
      position: 'absolute',
      right: '0',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '8px',
      height: 'unset',
      '& > button': {
        position: 'unset',
        right: 'unset',
      },
    },
  },
  addButton: {
    position: 'absolute',
    right: '-10%',
    bottom: '29px',
    [theme.breakpoints.down('lg')]: {
      right: '29px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '75px',
    },
    '& > button': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      height: '58px',
      width: '58px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        color: theme.colors.white,
        width: '20px',
        height: '19px',
      },
      '&:hover': {
        backgroundColor: theme.colors.primaryBlue[600],
      },
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  excuseNoteCardWrapper: {
    width: '100%',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    width: 'calc(100% - 24px)',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.chip,
    marginBottom: '24px',
    '& > span': {
      fontWeight: '600',
      fontSize: '18px',
    },
    '& > p': {
      fontSize: '16px',
    },
  },
  spinner: {
    display: "flex",
    width: '100%',
    height: '100%',
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  todayButton: {
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '19px',
      },
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const absenceService = useAppSelector((state: any) => state.services).absenceService;
  const filtersData = useAppSelector((state: any) => state.filters);
  const excuseNotesWrapper: any = useRef(null);

  const excuseNotesWrapperClientWidth = (excuseNotesWrapper.current && excuseNotesWrapper.current.clientWidth) ? excuseNotesWrapper.current.clientWidth : 0;
  
  const [state, setState] = useStates({
    isLoading: false,
    childrenListSelectWidth: excuseNotesWrapperClientWidth,
    lastViewMode: "calendar",
  });

  const childrenData = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass);
  const curChild = excusenotesData.currentChild;
  const curChildren = excusenotesData.currentChildren;
  const curDate = excusenotesData.date;
  const absenceList = excusenotesData.list;
  
  const tabsItems = tabs.excuseNotes.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);
  
  const loadExcuseNotes = useCallback((tempCurChild: any, tempCurDate: any) => {
    const currentDate = moment(tempCurDate);
    let startDate = moment(currentDate).subtract(1,'month');
    let endDate = moment(currentDate).add(1,'month');
    let months = monthsInDateRange(moment, startDate, endDate);
    if(excusenotesData.months.filter((item: any) => item.childID === tempCurChild.childID).length > 0) {
      if(excusenotesData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
        startDate = currentDate;
        if(excusenotesData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(startDate).format("YYYY-MM")) !== -1) {
          startDate = endDate;
        }
      }
      if(excusenotesData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
        endDate = currentDate;
        if(excusenotesData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(endDate).format("YYYY-MM")) !== -1) {
          endDate = startDate;
        }
      }
      months = monthsInDateRange(moment, startDate, endDate);
    }
    if(months.length === 1 && excusenotesData.months.filter((item: any) => item.childID === tempCurChild.childID).length > 0 && excusenotesData.months.find((item: any) => item.childID === tempCurChild.childID).months.indexOf(moment(months[0]).format("YYYY-MM")) !== -1) {
      setTimeout(() => {
        setState("isLoading", false);
      }, 1000);
    } else {
      months.forEach((month: any) => {
        dispatch(addToExcuseNotesMonths({childID: tempCurChild.childID, month: month}));
      });
      absenceService && absenceService.listAbsenceRange(tempCurChild.childID, moment(startDate).startOf("month").format("YYYY-MM-DD"), moment(endDate).endOf("month").format("YYYY-MM-DD")).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.absence) {
              months.forEach((month: any) => {
                const absenceData = {childID: tempCurChild.childID, dates: result.data.absence.filter((item: any) => moment(item.date).month() === moment(month).month())};
                dispatch(addToExcuseNotesList(absenceData)); 
              });
              setState("isLoading", false);
            } else {
              createNotification(t("excuse_note_failed_load"), "error");
              setState("isLoading", false);
            }
          } else {
            createNotification(t("excuse_note_failed_load"), "error");
            setState("isLoading", false);
            return;
          }
        } else {
          createNotification(t("excuse_note_failed_load"), "error");
          setState("isLoading", false);
          return;
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("excuse_note_failed_load"), "error");
        setState("isLoading", false);
      });
    }
  }, [absenceService, dispatch, excusenotesData.months, setState, t]);

  const loadExcuseNotesClass = useCallback((tempCurChild: any, tempCurDate: any) => {
    const newDate = moment(tempCurDate).format("YYYY-MM");
    absenceService && absenceService.listAbsence(tempCurChild, newDate).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.absence) {
            const absenceData = result.data.absence;
            let newList: any = [];
            let childrenData: any = [];
            tempCurChild.split(",").forEach((child: any) => {
              const newChild = {date: moment(tempCurDate).format("YYYY-MM"), childID: parseInt(child), dates: []};
              if(childrenData.filter((children: any) => children.childID === child).length === 0) {
                childrenData = [...childrenData, newChild];
              }
            });
            childrenData.forEach((childData: any) => {
              newList = [...newList, {...childData, dates: absenceData.filter((data: any) => data.childID === childData.childID && moment(data.date).format("YYYY-MM-DD") === moment(curDate).format("YYYY-MM-DD"))}];
            });
            dispatch(setExcuseNotesList(newList)); 
            setState("isLoading", false);
          } else {
            createNotification(t("excuse_note_failed_load"), "error");
            setState("isLoading", false);
          }
        } else {
          createNotification(t("excuse_note_failed_load"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("excuse_note_failed_load"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("excuse_note_failed_load"), "error");
      setState("isLoading", false);
    });
  }, [absenceService, curDate, dispatch, setState, t]);

  const prepareExcuseNotes = useCallback((tempCurChild: any, tempCurDate: any) => {
    setState("isLoading", true);
    loadExcuseNotes(tempCurChild, tempCurDate);
  }, [loadExcuseNotes, setState]);

  const prepareExcuseNotesClass = useCallback((tempCurChild: any, tempCurDate: any) => {
    setState("isLoading", true);
    loadExcuseNotesClass(tempCurChild, tempCurDate);
  }, [loadExcuseNotesClass, setState]);
  
  useEffect(() => {
    return () => {
      dispatch(clearExcuseNotesList());
      dispatch(clearExcuseNotesMonths());
      dispatch(setExcuseNotesCurrentChild(null));
      dispatch(setExcuseNotesCurrentChildren([]));
      dispatch(setExcuseNotesCurrentClass(null));
      dispatch(setExcuseNotesDate(moment()));
      dispatch(setExcuseNotesViewMode('calendar'));
    };
  }, [dispatch, setState], []);

  useEffect(() => {
    setState("childrenListSelectWidth", excuseNotesWrapperClientWidth);  
  }, [browserData.width, excuseNotesWrapperClientWidth, setState], [browserData.width, excuseNotesWrapperClientWidth]);
  
  useEffect(() => {
    if(filtersData.filterParams.childID.length === 1) {
      const childID = filtersData.filterParams.childID[0];
      const childData = getChildData(childID);
      dispatch(setExcuseNotesCurrentChild(childData));
    } else {
      dispatch(setExcuseNotesCurrentChild(childrenData[0])); 
    }
  }, [childrenData, dispatch, filtersData.filterParams.childID, getChildData], [filtersData.filterParams.childID]);  

  useEffect(() => {
    if(curChild !== null && curChild !== undefined && curDate !== null && curDate !== undefined && excusenotesData.viewMode !== "class") {
      prepareExcuseNotes(curChild, curDate);
    }
  }, [curChild, curDate, excusenotesData.viewMode, prepareExcuseNotes], [curChild, curDate]);

  useEffect(() => {
    if(excusenotesData.viewMode === "class") {
      prepareExcuseNotesClass(curChildren.join(","), curDate);
    } else if(curChild !== null && curChild !== undefined && curDate !== null && curDate !== undefined) {
      if(state.lastViewMode === "class") {
        dispatch(clearExcuseNotesList());
      }
      prepareExcuseNotes(curChild, curDate); 
    }
    setState("lastViewMode", excusenotesData.viewMode);
  }, [excusenotesData.viewMode, curChild, curChildren, curDate, dispatch, prepareExcuseNotes, prepareExcuseNotesClass, setState, state.lastViewMode], [excusenotesData.viewMode]);

  const handleSetToday = () => {
    const newDate = moment();
    dispatch(setExcuseNotesDate(newDate));
  };

  const handleSetDate = (newDate: any) => {
    dispatch(setExcuseNotesDate(newDate));
  };

  const handleOpenExcuseDetail = (absenceID: any, childID: any, type: any) => {
    const settings = {
      isOpen: true,
      absenceID: absenceID,
      childID: childID,
      type: type,
      readOnly: false,
    };
    dispatch(setExcuseNotesDetailModal(settings));
  };

  const handleOpenAddAbsence = (date: any) => {
    const settings = {
      isOpen: true,
      date: date,
      child: curChild,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };

  const handleOpenAddEmptyAbsence = () => {
    const settings = {
      isOpen: true,
      date: moment(),
      child: null,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };
  
  const handleOpenAddClassAbsence = () => {
    const settings = {
      isOpen: true,
      date: moment(curDate),
      child: null,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };

  const setViewMode = (viewMode: any) => {
    dispatch(setExcuseNotesViewMode(viewMode));
  };

  return (
    <div className={classes.excuseNotesPage}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={excusenotesData.viewMode} onSelect={setViewMode}/>
        <Sidebar/>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          <div className={classes.excuseNotesWrapper} ref={excuseNotesWrapper}>
            {
              excusenotesData.viewMode !== "class" ? (
                <>
                  <ChildrenListSelect currentChild={excusenotesData.currentChild} childrenData={childrenData} setCurrentChild={setExcuseNotesCurrentChild} useDispatch={true} width={state.childrenListSelectWidth} isDisabled={state.isLoading}/>
                  <div className={classes.toolbar}>
                    <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={state.isLoading}/>
                    <NormalButton className={classes.todayButton} startIcon={<SVG src="calendar-today"/>} disabled={moment(curDate).isSame(moment(), 'month') || state.isLoading} onClick={handleSetToday}>
                      {t('actual_month')} 
                    </NormalButton>
                  </div>
                </>
              ) : null
            }
            {
              excusenotesData.viewMode === "calendar" ? (
                <Calendar isLoading={state.isLoading} handleOpenExcuseDetail={handleOpenExcuseDetail} handleOpenAddAbsence={handleOpenAddAbsence}/>
              ) : excusenotesData.viewMode === "list" ? (
                <div className={classes.list}>
                  {
                    (curChild !== null && absenceList.filter((item: any) => item.childID === curChild.childID).length > 0 && absenceList.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).length > 0) ? (
                      <>
                      {
                        absenceList.find((item: any) => item.childID === curChild.childID).dates.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).map((x: any) => { return {...x}}).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.absenceID - a.absenceID; return moment(b.date).unix() - moment(a.date).unix(); }).map((absence: any, key: any) =>
                        absenceList.find((item: any) => item.childID === curChild.childID).dates.filter((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID).length > 1 ? (
                          absenceList.find((item: any) => item.childID === curChild.childID).dates.filter((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID && moment(thisAbsence.date).format("YYYY-MM") === moment(curDate).format("YYYY-MM")).findIndex((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID && thisAbsence.absenceID === absence.absenceID) === 0 ? (
                            <ExcuseNoteGroupCard absence={{absenceGroupID: absence.absenceGroupID, date: absence.date, childID: absence.childID}} handleOpenExcuseDetail={handleOpenExcuseDetail} onClick={() => handleOpenExcuseDetail(absence.absenceID, absence.childID, "group")} key={`k_${key}`}/>
                          ) : null) : (
                            <ExcuseNoteCard absence={{absenceID: absence.absenceID, date: absence.date, childID: absence.childID}} onClick={() => handleOpenExcuseDetail(absence.absenceID, absence.childID, "single")} key={`k_${key}`}/>
                          )
                        )
                      }
                      </>
                    ) : (
                      <div className={classes.notFound}>
                        <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                        <span>{t('no_results')}</span>
                        <p>{t('no_results_found')}</p>
                      </div>
                    )
                  }
                </div>
              ) : (
                <>
                  <div className={classes.info}>
                    <span>{htmlParse(excusenotesData.currentClass.name)}</span>
                    <p>{DateFormat(curDate, "daymonthnameyear", languageData, t)}</p>
                  </div>
                  <div className={classes.list}>
                    {
                      state.isLoading ? (
                        <div className={classes.spinner}>
                          <CircularProgress/>
                        </div>
                      ) : (absenceList.length > 0 && absenceList.filter((data: any) => data.dates.length > 0).length > 0) ? (
                        <>
                        {
                          absenceList.map((theChild: any, key: any) => (
                            <div className={classes.excuseNoteCardWrapper} key={`k_${key}`}>
                            {
                              theChild.dates.map((x: any) => { return {...x}}).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.absenceID - a.absenceID; return moment(b.date).unix() - moment(a.date).unix(); }).map((absence: any, key: any) => (
                                <ExcuseNoteCard absence={{absenceID: absence.absenceID, date: absence.date, childID: absence.childID}} onClick={() => handleOpenExcuseDetail(absence.absenceID, absence.childID, "single")} key={`k_${key}`}/>
                              ))
                            }
                            </div>
                          ))
                        }
                        </>
                      ) : (
                        <div className={classes.notFound}>
                          <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                          <span>{t('no_results')}</span>
                          <p>{t('no_results_found')}</p>
                        </div>
                      )
                    }
                </div>
                </>
              )
            }
          </div>
          {
            ((excusenotesData.viewMode !== "class" || (excusenotesData.viewMode === "class" && moment(curDate).isSameOrAfter(moment(), 'day'))) && !modalsData.postCreateModal.isOpen) ? (
              <div className={classes.addButton}>
                <IconButton onClick={excusenotesData.viewMode === "class" ? handleOpenAddClassAbsence : handleOpenAddEmptyAbsence} data-cy={isCypress() ? "excuseNotesAddButton" : null}>
                  <SVG src="plus"/>
                </IconButton>
              </div>
            ) : null
          }
        </div>
        <Sidebar/>
      </div>
    </div>
  );
};

export default PageTemplate;