import Input from 'src/components/Forms/Input';
import NormalButton from 'src/components/Buttons/NormalButton';
import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback, useRef } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import Select from 'src/components/Forms/Select';
import ShopOrdersTable from 'src/components/Tables/ShopOrdersTable';
import SVG from 'src/components/Images/SvgRenderer';
import { a2ab, createFileName } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveAs } from 'file-saver';
import { setAttachmentModal } from 'src/store/actions/modals.actions';
import { setStockFilterChildID, setStockFilterCurrentPage, setStockFilterOrderID, setStockFilterOrderStatusID, setStockFilterSchoolID, setStockOrders, setStockOrdersCount, setStockSelectedOrders, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  ordersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderRadius: '24px',
    padding: '0 16px',
    width: 'fit-content',
    minWidth: 'calc(90% - 32px)',
    maxWidth: 'calc(90% - 32px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '16px 0px',
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '100%',
    '& > h3': {
      display: 'flex',
      gap: '4px',
      '& > span': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  ordersToolbar: {
    display: "flex",
    gap: '10px',
    borderRadius: "24px",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    transition: 'height 0.5s, padding 0.5s',
    height: '42px',
    padding: '9px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    },
    flex: '0 0 42px',
    margin: '0 auto',
  },
  ordersToolbarWrapper: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  ordersToolbarInner: {
    display: "flex",
    gap: '10px',
    width: '100%',
    height: '100%',
  },
  backButton: {
    marginLeft: 'auto',
    flex: '0 0 auto',
  },
  shopOrdersTableWrapper: {
    position: 'relative',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
    borderRadius: '20px',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  buttons: {
    position: 'fixed',
    bottom: '60px',
    display: 'flex',
  },
  saveButton: {
    borderRadius: '10px 0 0 10px',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
  },
  cancelButton: {
    borderRadius: '0 10px 10px 0',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > span': {
      height: '16px',
    },
  },
}));

const Orders: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const exportService = useAppSelector((state: any) => state.services).exportService;
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;

  const orderIDInputRef: any = useRef(null);

  let delayDebounceFn: any;

  const stockOrderStatuses = configurationData.stockOrderStatuses;
  const stockChildren = dataData.children.filter((item: any) => item.schoolsID.includes(stockData.school.schoolID)).map((item: any) => { return { ...item, image: item.photo.thumbLink }; }).filter((item: any) => item !== null);

  const selectedOrders = stockData.selectedOrders;

  const [state, setState] = useStates({
    isLoading: true,
  });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  useEffect(() => {
    return () => {
      dispatch(setStockOrders([]));
      dispatch(setStockSelectedOrders([]));
      dispatch(setStockFilterOrderID(null));
      dispatch(setStockFilterSchoolID([]));
      dispatch(setStockFilterChildID(null));
      dispatch(setStockFilterOrderStatusID([]));
      dispatch(setStockFilterCurrentPage(1));
    };
  }, [dispatch], []);

  const getOrders = useCallback((page: any, orderID?: any, orderStatusID?: any, childID?: any) => {
    setState("isLoading", true);
    const settings = {
      schoolID: stockData.school.schoolID,
      orderID: orderID ? (orderID === "clear" ? "" : orderID) : (stockData.filterOrderID ? stockData.filterOrderID : ''),
      orderStatusID: (orderStatusID ? orderStatusID : stockData.filterOrderStatusID).map((item: any) => { return item.orderStatusID; }).join(","),
      childID: childID ? (childID === "clear" ? "" : childID.childID) : (stockData.filterChildID ? stockData.filterChildID.childID : ''),
      limit: 15,
      page: page, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockOrders(result.data.orders));
          dispatch(setStockOrdersCount(result.data.countItems));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, stockData, dispatch, setState]);

  const onPaginationChange = (page: any) => {
    dispatch(setStockFilterCurrentPage(page));
    dispatch(setStockSelectedOrders([]));
    getOrders(page);
  };

  const handleSaveOrderStatuses = (value: any) => {
    dispatch(setStockFilterOrderStatusID(value));
    dispatch(setStockFilterCurrentPage(1));
    getOrders(1, null, value);
  };

  const handleSaveChildren = (value: any) => {
    dispatch(setStockFilterChildID(value === null ? null : value));
    dispatch(setStockFilterCurrentPage(1));
    getOrders(1, null, null, value === null ? "clear" : value);
  };

  const handleSaveOrder = (value: any, e: any) => {
    e.stopPropagation();
    clearTimeout(delayDebounceFn);
    if((Number.isInteger(parseInt(value)) && value > 0) || value === "") {
      delayDebounceFn = setTimeout(() => {
        dispatch(setStockFilterOrderID(value === "" ? null : value));
        dispatch(setStockFilterCurrentPage(1));
        getOrders(1, value === "" ? "clear" : value, null, null);
      }, value.length === 0 ? 1 : 500);
    } else {
      orderIDInputRef.current.value = "";
    }
  };

  useEffect(() => {
    getOrders(1);
  }, [getOrders], []);

  const setViewMode = (view: any) => {
    dispatch(setStockViewMode(view));
  };

  const handleExportDeliveryList = useCallback((title: any, name: any, orderID: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportPDF = () => {
      exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
          type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const settings = {
      isOpen: true,
      title: `${title} <span>${name}</span>`,
      content: null,
      download: null,
    };
    dispatch(setAttachmentModal(settings));
    exportService && exportService.exportStockDeliveryList(orderID).then((result: any) => {
      const buf = a2ab(result.data);
      const buftype = 'application/pdf';
      const blob = new Blob([buf], {
        type: buftype,
      });
      const content = URL.createObjectURL(blob);
      const settings = {
        content: content,
        download: handleExportPDF,
        type: format,
      };
      dispatch(setAttachmentModal(settings));
    }).catch(() => {
      createNotification(t('something_went_wrong'), 'error');
      const settings = {
        isOpen: false,
        title: null,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
    });
  }, [dispatch, exportService, t]);

  const handleExportDeliveryListSelectedOrders = () => {
    dispatch(setStockSelectedOrders([]));
    handleExportDeliveryList(t('stock_order_delivery_lists'), `${t('stock_order_delivery_lists')} ${selectedOrders.join(", ")}`, selectedOrders.join(","), "pdf")
  };

  const handleClearSelectedOrders = () => {
    dispatch(setStockSelectedOrders([]));
  };

  const handleBack = () => {
    setViewMode("stockSettings");
  };

  return (
    <div className={classes.ordersWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.box}>
        <div className={classes.form}>
          <div className={classes.ordersToolbar}>
            <ScrollMenu classNameWrapper={classes.ordersToolbarWrapper} className={classes.ordersToolbarInner} layout="horizontal" nativeMobileScroll={false}>
              <Input min={1} placeholder={t('stock_order_number')} name="orderID" value={stockData.filterOrderID} useName={false} onChange={handleSaveOrder} width={200} baseWidth="inherit" customRefInput={orderIDInputRef}/>
              <Select inputLabel={t("stock_order_statuses")} items={stockOrderStatuses} selected={stockData.filterOrderStatusID} setSelected={handleSaveOrderStatuses} width={200} isMultiple={true} allowClear={false} defaultTitle="name"/>
              <Select inputLabel={t("stock_children")} items={stockChildren} selected={stockData.filterChildID} setSelected={handleSaveChildren} width={200} allowClear={true} defaultTitle="displayName"/>
            </ScrollMenu>
            <NormalButton className={classes.backButton} buttonType="primary" onClick={handleBack}>
              {t('back')}
            </NormalButton>
          </div>
          <div className={classes.shopOrdersTableWrapper}>
            {
              state.isLoading ? (
                <div className={classes.loadingWrapper}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : null
            }
            <ShopOrdersTable/>
          </div>
          <Pagination currentPage={stockData.filterCurrentPage} total={stockData.ordersCount} limit={15} disabled={state.isLoading} onChange={onPaginationChange}/>
        </div>
      </div>
      {
        selectedOrders.length > 1 ? (
          <div className={classes.buttons}>
            <NormalButton buttonType="primary" className={classes.saveButton} onClick={handleExportDeliveryListSelectedOrders} dataCy="stockExportButton">
              {t('stock_order_mass_export_delivery_lists')} ({selectedOrders.length})
            </NormalButton>
            <NormalButton buttonType="clear" className={classes.cancelButton} onClick={handleClearSelectedOrders} dataCy="stockClearButton">
              <SVG src="close"/>
            </NormalButton>
          </div>
        ) : null
      } 
    </div> 
  );
};

export default Orders;