import CircularProgress from '@mui/material/CircularProgress';
import DateItem from './DateItem';
import moment from '../../../utils/moment';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../constants/weekdays';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: 'hidden',
    marginBottom: '30px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  weekdayHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ececec',
    borderBottom: '1px solid #d2d2d2',
    borderRadius: '24px 24px 0 0',
    position: 'relative',
    zIndex: theme.zIndex.calendarHeader,
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '10px',
    fontSize: '14px',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type CalendarType = {
  className?: any;
  isLoading?: any;
  handleOpenSubstitutionDetail: any;
  handleOpenAddSubstitution: any;
};

const Calendar: React.FunctionComponent<CalendarType> = ({ className, isLoading = false, handleOpenSubstitutionDetail, handleOpenAddSubstitution }) => {
  
  const { t } = useTranslation();
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const userData = useAppSelector((state: any) => state.user);
  const curDate = substitutionsData.date;
  const classes = useStyles();
  const windowHandler: any = window;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const firstDate = useMemo(() => {
    const startOfMonth = moment(curDate).date(0);
    return moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days');
  }, [curDate]);

  useEffect(() => {
    windowHandler.isCalendarReady = !isLoading;
  }, [windowHandler, isLoading], [isLoading]);

  return (
    <div className={`${classes.root} ${className ? className : null}`} data-cy={isCypress() ? (isLoading ? "CalendarIsLoading" : "CalendarIsLoaded") : null}>
      {
        isLoading ? (
          <div className={classes.loadingWrapper}>
            <div className={classes.spinner}>
              <CircularProgress/>
            </div>
          </div>
        ) : null
      }
      <div className={classes.weekdayHeader}>
        {
          weekdays.map((weekday: any, key: any) => (
            <div className={classes.weekday} key={`k_${key}`}>
              <p>{t(weekday[0].toLowerCase() + weekday.slice(1))}</p>
            </div>
          ))
        }
      </div>
      <div className={classes.dateWrapper}>
        {
          firstDate && new Array(42).fill('').map((_, key) => {
            return (
              <DateItem
                index={key}
                key={`k_${key}`}
                date={moment(firstDate).add(key + firstDayInWeek, 'days')}
                onClickSubstitution={(substitutionID: any, childID: any) => handleOpenSubstitutionDetail(substitutionID, childID)}
                onClickDate={(date: any) => handleOpenAddSubstitution(date)}
                isLoading={isLoading}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Calendar;