import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setItemPickerModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import ItemPicker from 'src/components/Forms/ItemPicker';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    minHeight: '300px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemPicker: {
    maxWidth: '100%',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
  },
}));

const ItemPickerModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);

  const items = modalsData.itemPickerModal.items ? modalsData.itemPickerModal.items : [];
  const value = modalsData.itemPickerModal.value ? modalsData.itemPickerModal.value : null;
  const valueCompare = modalsData.itemPickerModal.valueCompare ? modalsData.itemPickerModal.valueCompare : "value";
  const onChange = modalsData.itemPickerModal.onChange ? modalsData.itemPickerModal.onChange : null;
  const closeOnChange = modalsData.itemPickerModal.onChange ? modalsData.itemPickerModal.closeOnChange : false;
  const title = modalsData.itemPickerModal.title ? modalsData.itemPickerModal.title : "";

  const [state, setState] = useStates({
    canClick: true,
    value: value,
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      items: [],
      value: null,
      valueCompare: 'value',
      onChange: null,
      closeOnChange: true,
      title: "",
    };
    dispatch(setItemPickerModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleChange = (item: any) => {
    setState("canClick", false);
    setState("value", item);
    if(onChange) {
      onChange(item);
      setState("canClick", true);
    }
    if(closeOnChange) {
      onCloseModal();
    }
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "itemPickerDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{title}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <ItemPicker className={classes.itemPicker} items={items} value={state.value} valueCompare={valueCompare} onChange={handleChange} disabled={!state.canClick}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
            {t("close")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default ItemPickerModal;