import CloseButton from 'src/components/Buttons/CloseButton';
import EmployeesInput from 'src/components/Forms/EmployeesInput';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import RadioGroup from 'src/components/Forms/RadioGroup';
import React, { useCallback, useMemo } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import { CirclePicker } from 'react-color';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { generateRandomColor, isKey } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setTimetableSubjectHandleModal } from '../../../store/actions/modals.actions';
import { setTimetableSubjects } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  colorPicker: {
    width: 'calc(100% - 18px) !important',
    boxShadow: 'none !important',
    borderColor: 'rgb(232, 232, 232) !important',
    borderStyle: 'solid !important',
    borderWidth: '1px !important',
    borderRadius: '10px !important',
    padding: '8px',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  reloadButton: {
    width: '32px',
    height: '32px',
    '& svg': {
      width: '24px',
      height: '24px',
      color: '#0085FF',
    }
  },
}));

const TimetableSubjectHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);
  const employees = dataData.employees.filter((employee: any) => employee.schoolID === modalsData.timetableSubjectHandleModal.schoolID && employee.enableForTimetable).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; });

  const isEdit = modalsData.timetableSubjectHandleModal.subjectID === null ? false : true;
  const subjectID = isEdit ? modalsData.timetableSubjectHandleModal.subjectID : null;
  const subjectData = isEdit ? (timetableData.subjects.filter((subject: any) => subject.subjectID === subjectID).length === 1 ? timetableData.subjects.find((subject: any) => subject.subjectID === subjectID) : null) : null
  const subjectEmployees = isEdit ? employees.filter((employee: any) => subjectData.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; }) : [];
  
  const getColor = (data: any) => {
    const regex = /^#[0-9A-F]{6}$/i;
    if(regex.test(data)) {
      return data;
    } else {
      return "#000000";
    }
  };

  const defaultColor = generateRandomColor();
  const disabledColors = useMemo(() => [theme.colors.black, theme.colors.white, theme.colors.primaryBlue[500], theme.colors.grey[200]], []);

  const [state, setState] = useStates({
    name: isEdit ? subjectData.name : "",
    abbr: isEdit ? subjectData.shortName : "",
    type: isEdit ? subjectData.type.toString() : null,
    color: isEdit ? getColor(subjectData.color) : defaultColor,
    isEmployeeSelectOpen: false,
    employees: isEdit? subjectEmployees : [],
    colorsList: isEdit ? [getColor(subjectData.color), defaultColor] : [defaultColor],
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      subjectID: null,
      schoolID: null,
    };
    dispatch(setTimetableSubjectHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  const handleColorChange = (color: any) => {
    setState("color", color.hex);
  };

  const handleEmployeesChange = (employees: any) => {
    setState("employees", employees);
  };

  const handleSave = () => {
    const payload = {
      "name": state.name,
      "shortName": state.abbr,
      "type": state.type,
      "color": state.color,
      "schoolID": modalsData.timetableSubjectHandleModal.schoolID,
      "teacherID": state.employees.map((employee: any) => { return employee.employeeID }),
    };
    if(isEdit) {
      timetableService && timetableService.editSubject(subjectID, payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_subject_updated"), "success");
            onCloseModal();
            const newSubjects = timetableData.subjects.map((subject: any) => {
              if(subject.subjectID === subjectID) {
                return result.data;
              } else {
                return subject;
              }
            });
            dispatch(setTimetableSubjects(newSubjects));
          } else {
            createNotification(t("timetable_subject_not_updated"), "error");
          }
        } else {
          createNotification(t("timetable_subject_not_updated"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_subject_not_updated"), "error");
      });
    } else {
      timetableService && timetableService.createSubject(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_subject_added"), "success");
            onCloseModal();
            const newSubjects = [...timetableData.subjects, result.data];
            dispatch(setTimetableSubjects(newSubjects));
          } else {
            createNotification(t("timetable_subject_not_added"), "error");
          }
        } else {
          createNotification(t("timetable_subject_not_added"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_subject_not_added"), "error");
      });
    }
  };

  const handleSaveEmployeesModal = (value: any) => {
    const newValue = value.map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; });
    setState("employees", newValue);  
  };

  const subjectTypes: any = [
    {
      name: t('timetable_subject_education'),
      value: '1',
      dataCy: 'educationSubjectRadio',
    },
    {
      name: t('timetable_subject_teacher'),
      value: '2',
      dataCy: 'teacherSubjectRadio',
    },
  ];

  const handleSubjectType = (e: any) => {
    setState("type", e.target.value);
  };

  const getColorsList = useCallback(() => {
    let tempColorsList = isEdit ? (getColor(subjectData.color) !== state.color ? [getColor(subjectData.color), state.color] : [getColor(subjectData.color)]) : [state.color];
    while(tempColorsList.length !== 30) {
      const newColor = generateRandomColor();
      if(tempColorsList.indexOf(newColor) === -1 && disabledColors.indexOf(newColor) === -1) {
        tempColorsList = [...tempColorsList, newColor];
      }
    };
    setState("colorsList", tempColorsList);
    setState("color", state.color);
  }, [isEdit, setState, subjectData, state, disabledColors]);

  useEffect(() => {
    getColorsList();
  }, [getColorsList], []);

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "timetableSubjectHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('timetable_subject_edit') : t('timetable_subject_add')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Input label={t('timetable_subject_name')} name="name" value={isEdit ? subjectData.name : null} onChange={handleInputChange}/>
          <Input label={t('timetable_subject_abbr')} name="abbr" value={isEdit ? subjectData.shortName : null} onChange={handleInputChange}/>
          <Label>{t('timetable_subject_type')}</Label>
          <RadioGroup items={subjectTypes} value={state.type} onChange={handleSubjectType}/>
          <Label>
            {t('timetable_subject_color')}
            <IconButton className={classes.reloadButton} onClick={getColorsList}>
              <SVG src="reload"/>
            </IconButton>
          </Label>
          <div className={classes.colorPicker}>
            <CirclePicker color={state.color} colors={state.colorsList} width="100%" triangle="hide" onChange={handleColorChange}/>
          </div>
          <EmployeesInput label={t('timetable_subject_teachers')} selectedEmployees={state.employees} setSelectedEmployees={handleEmployeesChange} onClick={() => setState("isEmployeeSelectOpen", true)}/>
          {
            state.isEmployeeSelectOpen ? (
              <EmployeesSelect
                selectedEmployees={state.employees}
                isInModal={true}
                isModalOpen={true}
                defaultEmployees={employees}
                modalTitle="employees"
                mode="select"
                modalAllowCancel={false}
                modalAllowClose={true}
                modalAllowClear={true}
                isSelectAll={true}
                isMultipleSelect={true}
                modalOnClose={() => setState("isEmployeeSelectOpen", false)}
                modalOnSave={handleSaveEmployeesModal}
                modalAllowEmployeesCount={true}
              />
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.name === "" || state.abbr === "" || state.type === null || state.color === "" || state.employees.length === 0} dataCy="saveButton">
            {t('save')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default TimetableSubjectHandleModal;