import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setStockTagHandleModal } from '../../../store/actions/modals.actions';
import { setStockTags } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 20px)',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
}));

const StockTagHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const stockData = useAppSelector((state: any) => state.stock);

  const isEdit = modalsData.stockTagHandleModal.tagID === null ? false : true;
  const tagID = isEdit ? modalsData.stockTagHandleModal.tagID : null;
  const tagData = isEdit ? (stockData.tags.filter((tag: any) => tag.tagID === tagID).length === 1 ? stockData.tags.find((tag: any) => tag.tagID === tagID) : null) : null

  const [state, setState] = useStates({
    name: isEdit ? tagData.name : "",
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      tagID: null,
      schoolID: null,
    };
    dispatch(setStockTagHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  const handleSave = () => {
    const payload = {
      "name": state.name,
      "schoolID": modalsData.stockTagHandleModal.schoolID,
    };
    if(isEdit) {
      stockService && stockService.editTag(tagID, payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("stock_tag_updated"), "success");
            onCloseModal();
            const newTags = stockData.tags.map((tag: any) => {
              if(tag.tagID === tagID) {
                return result.data;
              } else {
                return tag;
              }
            });
            dispatch(setStockTags(newTags));
          } else {
            createNotification(t("stock_tag_not_updated"), "error");
          }
        } else {
          createNotification(t("stock_tag_not_updated"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_tag_not_updated"), "error");
      });
    } else {
      stockService && stockService.createTag(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("stock_tag_added"), "success");
            onCloseModal();
            const newTags = [...stockData.tags, result.data];
            dispatch(setStockTags(newTags));
          } else {
            createNotification(t("stock_tag_not_added"), "error");
          }
        } else {
          createNotification(t("stock_tag_not_added"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_tag_not_added"), "error");
      });
    }
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockTagHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('stock_tag_edit') : t('stock_tag_add')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Input label={t('stock_tag_name')} name="name" value={isEdit ? tagData.name : null} onChange={handleInputChange} autoFocus={true}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.name === ""} dataCy="saveButton">
            {t('save')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default StockTagHandleModal;