import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Clamp from 'react-multiline-clamp';
import config from '../../../constants/config';
import DateFormat from '../../../utils/dateFormat';
import React, { useMemo, useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { getUserRole } from '../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { ObjectType } from 'src/types/types';
import { setTimelinePollDetailModal } from '../../../store/actions/modals.actions';
import { setTimelinePosts } from '../../../store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  timelinePoll: {
    position: "relative",
    display: "flex",
    flexDirection: 'column',
    width: 'calc(100% - 30px)',
    padding: '15px',
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
    color: '#C9CCE0',
    fontSize: '14px',
    width: '100%',
    '& svg': {
      width: '24px',
      height: '24px',
      color: 'inherit',
      marginRight: '4px',
    },
    '& > span': {
      marginLeft: 'auto',
      marginRight: '0',
      fontSize: '12px',
    },
  },
  answers: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    marginTop: '12px',
  },
  card: {
    backgroundColor: theme.colors.grey[125],
    color: '#5A5C7B',
    borderRadius: '12px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    maxWidth: '100%',
    '& > span': {
      color: theme.colors.grey[700],
      fontSize: '18px',
      fontWeight: '600',
      marginLeft: '12px',
      maxWidth: 'calc(100% - 52px)',
      flex: '1 1 calc(100% - 52px)',
      wordBreak: 'break-all',  
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0',
    flex: '0 0 40px',
    justifyContent: 'center',
    '& > p': {
      fontSize: '14px',
      color: '#C8CBDF',
    },
    '& > span.check': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primaryBlue[500],
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.primaryBlue[500],
      marginRight: '0px',
      borderRadius: '100%',
      '& > svg': {
        color: theme.colors.white,
        transform: 'scale(0.7)'
      },
    },
    '& > span.nocheck': {
      display: 'block',
      backgroundColor: theme.colors.white,
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[350],
      marginRight: '0px',
      borderRadius: '100%',
    },
  },
  selected: {
    borderColor: '#A3CEEB',
    backgroundColor: '#F4F7FE',
    cursor: 'pointer',
  },
  voted: {
    borderColor: '#A3CEEB',
    backgroundColor: '#F4F7FE',
  },
  voteable: {
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[250],
      cursor: 'pointer',    
    },
  },
  button: {
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    padding: '10px',
    width: '100%',
    cursor: 'default',
    color: theme.colors.white,
    height: '50px',
    transition: "background-color 0.5s",
    textTransform: 'none',
    '&:disabled': {
      color: theme.colors.white,
      border: '1px solid #E1E1E1',
      borderColor: '#C8CBDF',
      backgroundColor: '#C8CBDF',
      '&:hover': {
        backgroundColor: '#C8CBDF', 
      },
    },
    '&:not(:disabled)': {
      borderColor: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.primaryBlue[500],
      cursor: 'pointer',
    },
  },
}));

type TimelinePollType = {
  uniqueID: any;
};

const TimelinePoll: React.FunctionComponent<TimelinePollType> = ({uniqueID}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const userData = useAppSelector((state: any) => state.user); 
  const schoolSettings = userData.schoolSettings;
  const postData = useMemo(() => timelineData.posts.find((post: any) => post.uniqueID === uniqueID), [timelineData.posts, uniqueID]);
  const schoolTimezone = useMemo(() => getSchoolSettings(postData.schoolID, 'timeZone', schoolSettings) === null ? config.APP_TIMEZONE : getSchoolSettings(postData.schoolID, 'timeZone', schoolSettings), [postData.schoolID, schoolSettings]);
  const { t } = useTranslation();
  const selectedAnswers: any = useRef([]);
  const [selected, setSelected]: any = useState([]);
  
  const voteAnswer = () => {
    if(selected.length !== 0) {
      let payload = `{
        "answerID": [` + selected.join() + `],
        "childID": ` + postData.children[0].childID + `,
        "postID": ` + postData.postID + `
      }`;
      timelineService && timelineService.voteAnswer(payload).then((result: any) => {
        if(result.status === 201) {
          const uniqueIDs = result.data.posts.map((post: any) => {
            const childrenID = post.children.map((child: any) => child.childID);
            return {
              ...post,
              uniqueID: post.postID + "_" + childrenID.join(),
            };
          });
          const newTimelineData = timelineData.posts.map((post: any) => {
            const foundPost = uniqueIDs.find((newPost: any) => newPost.uniqueID === post.uniqueID);
            return foundPost ? { ...post, children: foundPost.children, poll: foundPost.poll } : post;
          });
          dispatch(setTimelinePosts(newTimelineData));
          createNotification(t("vote_saved"), "success");
        } else {
          createNotification(t("vote_not_saved"), "error");
        }
      }).catch((e: any) => {
        createNotification(e.response.data.message, "error");
      });
    }
  };
  
  const RenderAnswer: React.FunctionComponent<ObjectType> = ({answerData}) => {
  
    const [isSelected, setIsSelected] = useState(false);
    
    const toggleSelected = () => {
      if(answerData.available && postData.poll.answers.filter((answer: any) => answer.available).length !== 0 && postData.poll.answers.filter((answer: any) => answer.voted).length === 0) {
        setIsSelected(!isSelected);
        if(postData.poll.isMultipleChoice) {
          if(selectedAnswers.current.indexOf(answerData.id) !== -1) {
            selectedAnswers.current = selectedAnswers.current.filter((selected: any) => selected !== answerData.id);  
          } else {
            selectedAnswers.current = [...selectedAnswers.current, answerData.id];  
          }
        } else {
          if(selectedAnswers.current.indexOf(answerData.id) !== -1) {
            selectedAnswers.current = [];  
          } else {
            selectedAnswers.current = [answerData.id];  
          }
        }
        setSelected(selectedAnswers.current);
      }
    };
    
    return getUserRole(userData.userObject.roleType) === "parent" ? (
      <div className={`${classes.card} ${(selected.indexOf(answerData.id) !== -1 && !answerData.voted && answerData.available) ? classes.selected : ''}  ${answerData.voted ? classes.voted : ''} ${(answerData.available && postData.poll.answers.filter((answer: any) => answer.available).length !== 0 && postData.poll.answers.filter((answer: any) => answer.voted).length === 0) ? classes.voteable : ''}`} onClick={toggleSelected}>
        <Clamp lines={1}>
          <span data-clarity-unmask="true">
            {answerData.answer}
          </span>
        </Clamp>
        <div className={classes.status}>
        {
          answerData.available ? (
            (postData.poll.answers.filter((answer: any) => answer.available).length !== 0 && postData.poll.answers.filter((answer: any) => answer.voted).length !== 0) ? null : (selected.indexOf(answerData.id) !== -1 || answerData.voted) ? (
              <span className="check">
                <CheckIcon/>
              </span>
            ) : (
              <span className="nocheck"/>
            )
          ) : (
            answerData.voted ? (
              <span className="check">
                <CheckIcon/>
              </span>
            ) : null
          )
        }
        </div>
      </div>
    ) : (
      <div className={classes.card}>
        <Clamp lines={1}>
          <span data-clarity-unmask="true">
            {answerData.answer}
          </span>
        </Clamp>
        <div className={classes.status}>
          <p data-clarity-unmask="true">({postData.children.filter((child: any) => child.pollAnswers.indexOf(answerData.id) !== -1).length}{answerData.limit !== 0 ? '/' + answerData.limit : null})</p>
        </div>
      </div>
    );
  };
  
  const openModal = () => {
    const settings = {
      isOpen: true,
      uniqueID: uniqueID,
    };
    dispatch(setTimelinePollDetailModal(settings)); 
  };
  
  return (
    <div className={classes.timelinePoll} data-cy={isCypress() ? "timelineCardPoll" : null}>
      <span className={classes.hint}>
      {
        postData.poll.isMultipleChoice ?  (<SVG src="polls"/>) : (<SVG src="checkmark-circle"/>)
      }
      {
        postData.poll.isMultipleChoice ? t('multiple_choice') : t('single_choice') 
      }
      {
        postData.poll.votableTo != null ? (
          <span data-clarity-unmask="true">
            {t(getUserRole(userData.userObject.roleType) === "parent" ? 'poll_can_vote_until' : 'poll_vote_until', {date: DateFormat(postData.poll.votableTo,"timeline", languageData, t, schoolTimezone)})}.
          </span>
        ) : null
      }
      </span>
      <div className={classes.answers}>
        {
          postData.poll.answers.map((answer: any, key: any) => (
            <RenderAnswer answerData={answer} key={`k_${key}`}/>  
          ))
        }      
      </div>
      {
        getUserRole(userData.userObject.roleType) === "parent" ?
          ((postData.poll.answers.filter((answer: any) => answer.available).length === 0 && postData.poll.answers.filter((answer: any) => answer.voted).length === 0) ? 
            null : (
              <Button className={classes.button} disabled={selected.length === 0 || postData.poll.answers.filter((answer: any) => answer.voted).length !== 0} onClick={voteAnswer}>
              {
                postData.poll.answers.filter((answer: any) => answer.voted).length !== 0 ? (
                  <span>
                  {t("already_answered")}
                  </span>
                ) : !postData.poll.isMultipleChoice ? (
                  <span>
                  {
                    selected.length === 0 ? t("choose_answer") : t("submit_answer")
                  }
                  </span>
                ) : (
                  <span>
                  {
                    selected.length > 1
                    ? t("submit_answers")
                    : selected.length === 0
                      ? t("choose_one_more_answers")
                      : t("submit_answer")
                  }
                  </span>
                )
              }
              </Button>
            )
          ) : (
            <Button className={classes.button} onClick={openModal}>
              {t('view_poll_details')}
            </Button>
          )
      }
    </div>
  );
}

export default TimelinePoll;