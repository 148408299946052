import CheckIcon from '@mui/icons-material/Check';
import htmlParse from 'html-react-parser';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useMemo } from 'src/utils/useMemo';

interface Props {
  isClickable: any;
  isSelected: any;
}

const useStyles = createUseStyles((theme: any) => ({
  schoolItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[0],  
  },
  schoolDetail: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    position: 'relative',
    backgroundColor: (props: Props) => {
      if(props.isClickable && props.isSelected) return theme.colors.primaryBlue[800];
      else return theme.colors.primaryBlue[500];
    },
    borderRadius: '12px',
    padding: '26px 24px',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'url("/resources/icons/school-large.svg")',
      backgroundPosition: '50% 50%',
      backgroundSize: 'auto 90%',
      backgroundRepeat: 'no-repeat',
      filter: 'invert(100%)',
      opacity: '0.2',
      zIndex: '0',
    },
    '& > .MuiAccordionSummary-content': {
      position: 'relative',
      zIndex: '1',
      margin: '0',
      alignItems: 'center',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
      width: 'calc(100% - 24px)',
    },
    cursor: (props: Props) => {
      if(props.isClickable && !props.isSelected) return 'pointer';
      else return '';
    },
    transition: (props: Props) => {
      if(props.isClickable && !props.isSelected) return 'background-color 0.25s';
      else return '';
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.isClickable && !props.isSelected) return theme.colors.primaryBlue[700];
        else return '';
      },
    },
  },
  schoolInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
    position: 'relative',
    zIndex: '1',
  },
  schoolName: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.alwaysWhite,
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  schoolCount: {
    color: '#EEEEEE',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: 'auto 10px',
    '&.search': {
      color: '#BFBFBF',
    },   
  },
  schoolContent: {
    padding: '0',
  },
  schoolCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    marginLeft: "auto",
    fontSize: "16px",
    cursor: "pointer",
    '& p': {
      position: 'absolute',
      top: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '10px',
      textTransform: 'uppercase',
      color: theme.colors.white,
      opacity: '0',
      transition: 'opacity 0.25s',
    },
    '& .checked + p': {
      opacity: '1',
    }
  },
  schoolCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: '#359EFF',
    borderRadius: "100%",
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.white,
      '& svg': {
        opacity: '1',
        color: theme.colors.primaryBlue[500],
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  schoolExpander: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    backgroundColor: theme.colors.white,
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.grey[700],
      transition: 'transform 0.25s',
      width: '24px',
      height: '24px',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxWidth: '24px',
      maxHeight: '24px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },      
  },
}));

type RenderSchoolType = {
  state: any;
  setState: any;
  schoolData: any;
};

const RenderSchool: React.FunctionComponent<RenderSchoolType> = ({
  state,
  setState,
  schoolData,
}) => {

  const schoolID = useMemo(() => schoolData.schoolID, [schoolData.schoolID]);
  const schoolInfo = useMemo(() => state.functions.getSchoolData(schoolID), [schoolID, state.functions], [schoolID, state.functions, state.isShowArchived, state.search]);

  const isSelectedSchool = useMemo(() => (searchData: any) => {
    return state.selectedSchools.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedSchools]);
  
  const isSelected = useMemo(() => isSelectedSchool({ schoolID: schoolID }), [isSelectedSchool, schoolID]);

  const classes = useStyles({
    isClickable: state.isAllowOnlyOneSchoolAtOnce,
    isSelected: isSelected,
  });
  
  const handleSelect = useCallback((e: any) => {
    if(state.isDisableClick) return;
    if(state.isAllowOnlyOneSchoolAtOnce && isSelected) return;
    e.stopPropagation();
    e.preventDefault();
    let newSelectedSchools = [...state.selectedSchools];
    if(isSelected) {
      newSelectedSchools = newSelectedSchools.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ schoolID: schoolID }));
    } else {
      const newSchool = { schoolID: schoolID };
      if(newSelectedSchools.filter((theSchool: any) => JSON.stringify(theSchool) === JSON.stringify(newSchool)).length === 0) {
        newSelectedSchools = [...newSelectedSchools, newSchool];
      }
    }
    setState("selectedSchools", newSelectedSchools);
  }, [schoolID, setState, state, isSelected]);

  const handleClick = useCallback((e: any) => {
    if(state.isDisableClick) return;
    if((state.isAllowOnlyOneSchoolAtOnce && isSelected) || !state.isAllowOnlyOneSchoolAtOnce) return;
    e.stopPropagation();
    e.preventDefault();
    let newSelectedSchools: any = [];
    if(isSelected) {
      newSelectedSchools = newSelectedSchools.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ schoolID: schoolID }));
    } else {
      const newSchool = { schoolID: schoolID };
      if(newSelectedSchools.filter((theSchool: any) => JSON.stringify(theSchool) === JSON.stringify(newSchool)).length === 0) {
        newSelectedSchools = [...newSelectedSchools, newSchool];
      }
    }
    setState("selectedSchools", newSelectedSchools);
    if(state.onClickSchool) {
      const itemData = {
        school: schoolData,
      };
      state.onClickSchool(e, itemData, newSelectedSchools);
    } 
  }, [schoolID, setState, state, isSelected, schoolData]);

  return (
    <div className={classes.schoolItem}>
      <div className={classes.schoolDetail} onClick={handleClick} data-cy={isCypress() ? `school${schoolID}` : null}>
        <span className={classes.schoolInfo}>
          <span className={classes.schoolName} data-cy={isCypress() ? 'schoolName' : null} data-clarity-unmask="true">
            {htmlParse(schoolInfo.name)}
          </span>
          {
            !state.isAllowOnlyOneSchoolAtOnce ? (
              <span className={classes.schoolCheck}>
                <span className={`${classes.schoolCheckButton} ${isSelectedSchool({ schoolID: schoolID }) ? 'checked' : '' }`} onClick={handleSelect} data-cy={isCypress() ? `school${schoolID}Select` : null}>
                  <CheckIcon/>
                </span>
              </span>
            ) : null
          }
        </span>
      </div>
    </div>
  ); 
}

export default RenderSchool;