import PaginationButton from 'src/components/Buttons/PaginationButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useState } from 'src/utils/useState';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  pagination: {
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    gap: '8px',
    height: 'fit-content',
  },
}));

type PaginationType = {
  total: any;
  limit: any;
  currentPage?: any;
  onChange: any;
  disabled?: any;
  className?: any,
};

const Pagination: React.FunctionComponent<PaginationType> = ({ total, limit, currentPage, className, disabled, onChange }) => {

  const classes = useStyles();
  const [curPage, setCurPage] = useState(currentPage ? currentPage : 1);

  const fixedLimit = limit < 1 ? 1 : limit;

  const handleOnClick = (page: any) => {
    onChange(page / fixedLimit);
    setCurPage(page / fixedLimit);
  };

  useEffect(() => {
    const newPage = Math.ceil(currentPage);
    if(newPage !== curPage) {
      setCurPage(newPage);
    }
  }, [currentPage, curPage], [currentPage]);

  const countMath = Math.ceil(total / fixedLimit);
  const count = countMath < 1 ? 1 : countMath;
  
  return total > fixedLimit ? (
    <div className={`${classes.pagination} ${className}`}>
      {
        Array.from('x'.repeat(count)).map((i: any, key: any) => (
          <PaginationButton disabled={disabled || curPage === key + 1} selected={curPage === key + 1} onClick={() => handleOnClick(fixedLimit * (key + 1))} key={`${"k_"}${key}`} dataCy={`page${(key + 1)}`}>
            { key + 1 }
          </PaginationButton>
        ))   
      }  
    </div>
  ) : null;
}

export default Pagination;