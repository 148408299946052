import moment from 'src/utils/moment';
import React from 'react';
import { calculateNextUpdateIn, formatRelativeDate } from 'src/utils/useFunctions';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type RelativeDateType = {
  date: any;
};

const RelativeDate: React.FunctionComponent<RelativeDateType> = ({ date }) => {

  const { t } = useTranslation();

  const [state, setState] = useStates({
    formattedDate: formatRelativeDate(date, moment, t),
    nextUpdateIn: calculateNextUpdateIn(date, moment),
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setState("formattedDate", formatRelativeDate(date, moment, t));
      setState("nextUpdateIn", calculateNextUpdateIn(date, moment));
    }, state.nextUpdateIn * 1000);
    return () => clearTimeout(timeout);
  }, [date, t, setState, state.nextUpdateIn], [state.formattedDate]);

  useEffect(() => {
    setState("formattedDate", formatRelativeDate(date, moment, t));
    setState("nextUpdateIn", calculateNextUpdateIn(date, moment));
  }, [date, setState, t], [date]);
  
  return (
    <>
      {state.formattedDate}
    </>
  );
}

export default RelativeDate;