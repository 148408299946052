import AuthenticatedImage from '../../../../../components/Items/AuthenticatedImage';
import DateFormat from '../../../../../utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../../utils/moment';
import React, { useCallback, useMemo } from 'react';
import ReactionsList from 'src/components/Reactions/List';
import SVG from '../../../../../components/Images/SvgRenderer';
import { Tooltip } from '@mui/material';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { handleSum } from 'src/utils/useFunctions';
import { InView } from 'react-intersection-observer';
import { isCypress } from 'src/utils/useCypress';
import { setChildCardModal, setChildrenModal, setGalleryModal } from '../../../../../store/actions/modals.actions';
import { setGalleryMedias, setGallerySelected } from '../../../../../store/actions/gallery.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  membershipActive: any;
  isSelected: any;
  isFavorite: any;
  isOnlyFavorited: any;
  isHide: any;
  isOnlyHidden: any;
};

const useStyles = createUseStyles((theme: any) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    width: '351px',
    height: '271px',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 3px 20px',
    padding: '8px',
    marginTop: '16px',
    pointerEvents: (props: Props) => {
      if(!props.membershipActive) return 'none';
      else return '';
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(232px - 16px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(142px - 16px)',
    },
  },
  headerWrapper: {
    width: '100%',
    height: '234px',
    cursor: 'pointer',
    borderRadius: '6px',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      '& > div': {
        opacity: '1',
      },
    },
    [theme.breakpoints.down('lg')]: {
      height: '144px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '84px',
    },
    '& > div:first-of-type': {
      filter: (props: Props) => {
        if(props.isHide && !props.isOnlyHidden) return 'grayscale(100%)';
        else if(!props.isHide && props.isOnlyHidden) return 'grayscale(100%)';
        else if(!props.isFavorite && props.isOnlyFavorited) return 'grayscale(100%)';
        else if(props.isFavorite && props.isOnlyHidden) return 'grayscale(100%)';
        else return 'grayscale(0%)';
      },
      transition: 'all 0.25s',
    },
  },
  info: {
    display: 'flex',
    padding: '12px 8px 4px 8px',
    width: 'calc(100% - 16px)',
    fontSize: '14px',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '14px',
      gap: '4px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        fontSize: '9px',
      },
      '& > svg': {
        width: '16px',
        height: '16px',
        [theme.breakpoints.down('sm')]: {
          width: '11px',
          height: '11px',
        },
      },
    },
  },
  childPhoto: {
    width: '16px',
    height: '16px',
    minWidth: '16px',
    minHeight: '16px',
    maxWidth: '16px',
    maxHeight: '16px', 
    backgroundColor: theme.colors.white,
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      '& > div': {
        position: 'relative',
      },
    },    
  },
  actions: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 8px 4px 8px',
    width: 'fit-content',
    position: 'absolute',
    top: '8px',
    right: '8px',
    backgroundColor: theme.colors.primaryBlue[500],
    cursor: 'auto',
    opacity: (props: Props) => {
      if(props.isSelected) return '1';
      else return '0';
    },
    transition: 'opacity 0.25s',
    borderRadius: '24px',
    [theme.breakpoints.down('md')]: {
      opacity: '1 !important',
    },
  },
  select: {
    padding: '0',
    height: 'fit-content',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.white,
    },
  },
  favorite: {
    padding: '0',
    height: 'fit-content',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: (props: Props) => {
        if(props.isFavorite) return theme.colors.yellow[500];
        else return theme.colors.white;
      },
    },
  },
  hide: {
    padding: '0',
    height: 'fit-content',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.white,
    },
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
    '& > div': {
      width: '100%',
      height: '100%',
      '& > div': {
        position: 'relative',
      },
    },
  },
  video: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(2.5)',
      filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))',
      color: theme.colors.white,
      width: '24px',
      height: '24px',
    },
  },
  attachment: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '96px',
      height: '96px',
      fill: theme.colors.grey[75],
    }
  },
  customWidth: {
    minWidth: "10px !important"
  },
  reactions: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    padding: '4px 8px 4px 8px',
    width: 'fit-content',
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    backgroundColor: theme.colors.white,
    opacity: (props: Props) => {
      if(props.isSelected) return '1';
      else return '0';
    },
    transition: 'opacity 0.25s',
    borderRadius: '24px',
    [theme.breakpoints.down('md')]: {
      opacity: '1 !important',
    },
    '&:hover': {
      '& > span': {
        textDecoration: 'underline',
      },
    },
  },
  reactionEmoji: {
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.25s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}));

type RenderItemType = {
  date: any;
  mediaID: any;
};

const RenderItem: React.FunctionComponent<RenderItemType> = ({date, mediaID}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const galleryData = useAppSelector((state: any) => state.gallery);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  const item = useMemo(() => galleryData.medias.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).length === 0 ? {} : (galleryData.medias.find((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).medias.filter((item: any) => item.mediaID === mediaID).length === 0 ? {} : galleryData.medias.find((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).medias.find((item: any) => item.mediaID === mediaID)), [date, galleryData.medias, mediaID]);
  const itemDate = useMemo(() => item.post.created, [item.post.created])
  const childData = useMemo(() => item.post.children.length !== 1 ? null : dataData.children.find((child: any) => child.childID === item.post.children[0]), [dataData.children, item.post.children]);
  const medias = useMemo(() => galleryData.medias, [galleryData.medias]);
  const selected = useMemo(() => galleryData.selected, [galleryData.selected]);

  const isHide = useMemo(() => item.attributes.find((attribute: any) => attribute.name === "hide").value, [item.attributes]);
  const isFavorite = useMemo(() => item.attributes.find((attribute: any) => attribute.name === "favorite").value, [item.attributes]);
  const isSelected = useMemo(() => galleryData.selected.indexOf(item.mediaID) === -1 ? false : true, [galleryData.selected, item.mediaID]);
  const [isInView, setIsInView] = useState(false);

  const classes = useStyles({
    membershipActive: userData.membership.active,
    isSelected: isSelected,
    isFavorite: isFavorite,
    isOnlyFavorited: filtersData.filterParams.galleryFavorite,
    isHide: isHide,
    isOnlyHidden: filtersData.filterParams.galleryHide,
  });

  const updateAttribute = useCallback((attr: any, value: any) => {
    const newItems = medias.map((date: any) => {
      if(moment(date.date).format("YYYY-MM") === moment(itemDate).format("YYYY-MM")) {
        return {...date, medias: date.medias.map((media: any) => {
          if(media.mediaID === item.mediaID) {
            return {...media, attributes: media.attributes.map((attribute: any) => {
              if(attribute.name === attr) {
                return {...attribute, value: value};
              } else {
                return attribute;
              }
            })};
          } else {
            return media;
          }
        })};
      } else {
        return date;
      }
    });
    dispatch(setGalleryMedias(newItems));
  }, [dispatch, item.mediaID, itemDate, medias]);

  const handleSelect = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    let newSelected = selected;
    if(isSelected) {
      newSelected = newSelected.filter((media: any) => media !== item.mediaID);
    } else {
      newSelected = [...newSelected, item.mediaID];
    }
    dispatch(setGallerySelected(newSelected));
  }, [dispatch, selected, item.mediaID, isSelected]);

  const handleFavorite = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(!userData.membership.active) return;
    const newValue = !isFavorite;
    updateAttribute("favorite", newValue);
    const payload = {
      attributes: [
        {
          name: 'favorite',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(item.mediaID, payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute("favorite", !newValue);
    });
  }, [galleryService, isFavorite, item.mediaID, t, updateAttribute, userData.membership.active]);

  const handleHide = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(!userData.membership.active) return;
    const newValue = !isHide;
    updateAttribute("hide", newValue);
    const payload = {
      attributes: [
        {
          name: 'hide',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(item.mediaID, payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute("hide", !newValue);
    });
  }, [galleryService, isHide, item.mediaID, t, updateAttribute, userData.membership.active]);

  const handleOpen = useCallback(() => {
    if(!userData.membership.active) return;
    const settings = {
      isOpen: true,
      date: date,
      mediaID: mediaID,
    };
    dispatch(setGalleryModal(settings));
  }, [date, dispatch, mediaID, userData.membership.active]);

  const getChildren = useMemo(() => {
    const newChildren = dataData.children.map((theChild: any) => {
      if(item.post.children.indexOf(theChild.childID) !== -1) {
        return theChild;
      } else {
        return null;
      }
    }).filter((item: any) => item !== null);
    return newChildren;
  }, [dataData.children, item.post.children]);

  const handleOpenChildrenModal = useCallback(() => {
    const handleCloseChildrenModal = () => {
      const settings = {
        isOpen: false,
        modalTitle: null,
        modalOnClose: null,
        defaultChildren: null,
        mode: null,
        isAllowArchived: null,
        isShowChildrenViews: null,
      };
      dispatch(setChildrenModal(settings));
    };
    const settings = {
      isOpen: true,
      modalTitle: 'list_of_children',
      modalOnClose: handleCloseChildrenModal,
      defaultChildren: getChildren,
      mode: "detail",
      isAllowArchived: getChildren.filter((theChild: any) => theChild.isArchived).length === 0 ? false : true,
    };
    dispatch(setChildrenModal(settings));  
  }, [dispatch, getChildren]);

  const handleOpenChildModal = useCallback(() => {
    const settings = {
      isOpen: true,
      childID: item.post.children[0],
    };
    dispatch(setChildCardModal(settings));
  }, [dispatch, item.post.children]);

  return (
    <InView onChange={(inView) => setIsInView(inView)} className={classes.card} data-cy={isCypress() ? 'galleryItem' + mediaID : null}>
      {
        isInView ? (
          <>
            <div className={classes.headerWrapper}>
              {
                item.type === "photo" ? (
                  <div className={classes.imageWrapper} onClick={handleOpen} data-cy={isCypress() ? 'galleryImage' : null}>
                    <AuthenticatedImage thumbLink={item.thumbLink}/>
                  </div>
                ) : item.type === "video" ? (
                  <div className={classes.video} onClick={handleOpen} data-cy={isCypress() ? 'galleryImage' : null}>
                    <div className={classes.imageWrapper}>
                      <AuthenticatedImage thumbLink={item.thumbLink}/>
                    </div>
                    <SVG src="play"/>
                  </div>
                ) : item.type === "attachment" ? (
                  <div className={classes.attachment} onClick={handleOpen} data-cy={isCypress() ? 'galleryImage' : null}>
                    <div className={classes.imageWrapper}>
                      <AuthenticatedImage thumbLink={item.thumbLink}/>
                    </div>
                    <SVG src={`${"document-"}${(item.name).split('.').pop()}${""}`} children={<SVG src="file"/>}/>
                  </div>
                ) : null
              }
              <div className={classes.actions}>
                <IconButton className={classes.select} onClick={handleSelect} dataCy='galleryImageSelectButton'>
                  <SVG src={isSelected ? "checkmark-circle" : "circle-outlined"}/>
                </IconButton>
                <IconButton className={classes.favorite} onClick={handleFavorite} dataCy='galleryImageFavoriteButton'>
                  <SVG src={isFavorite ? "star-filled" : "star"}/>
                </IconButton>
                <IconButton className={classes.hide} onClick={handleHide} dataCy='galleryImageHideButton'>
                  <SVG src={isHide ? "eye" : "eye-slash"}/>
                </IconButton>
              </div>
              {
                ((item.reactions && item.reactions.enable && item.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (item.reactions && item.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0)) ? (
                  <ReactionsList className={classes.reactions} data={item} ID={item.mediaID} type='media'/>
                ) : null
              } 
            </div>
            <span className={classes.info} data-cy={isCypress() ? 'galleryImageInfo' : null} data-clarity-unmask="true">
              {DateFormat(item.post.created, "timeline", languageData, t)}
              <span onClick={item.post.children.length === 1 ? handleOpenChildModal : handleOpenChildrenModal} data-cy={isCypress() ? 'galleryImageChildrenList' : null} data-clarity-unmask="true">
                {
                  item.post.children.length === 1 ? (
                    <Tooltip title={childData.displayName} classes={{ tooltip: classes.customWidth }} arrow>
                      <div className={classes.childPhoto}>
                        <AuthenticatedImage thumbLink={childData.photo.thumbLink}/>
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      <SVG src="people"/> {item.post.children.length}
                    </>
                  )
                }
              </span>
            </span>
          </>
        ) : (
          <div className={classes.headerWrapper}/>
        )
      }
    </InView>
  );
};

export default RenderItem;
