import React from 'react';
import RenderUserGroup from '../RenderUserGroup';
import { createUseStyles } from 'react-jss';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  userGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  userGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

type RenderUserGroupsType = {
  state: any;
  setState: any;
  schoolData: any;
};

const RenderUserGroups: React.FunctionComponent<RenderUserGroupsType> = ({
  state,
  setState,
  schoolData,
}) => {

  const classes = useStyles();
  const schoolID = schoolData.schoolID;
  const roles = Array.isArray(state.groupRoles) ? state.groupRoles : (state.groupRoles[schoolID] ? state.groupRoles[schoolID] : [4,2,3]);

  const getTotalUsersByRole = useMemo(() => (role: any) => { return state.functions.getUsersBySchoolIDAndRole(schoolID, role); }, [state.functions, schoolID], [state.functions, state.isShowArchived, state.search]);

  return (
    <div className={`${classes.userGroupList} ${state.customClasses ? (state.customClasses.userGroupList ? state.customClasses.userGroupList : "") : ""}`}>
      {
        roles.map((role: any, key: any) => {
          const totalUsers = getTotalUsersByRole(role);
          return totalUsers.length === 0 ? null : (
            <div className={classes.userGroupWrapper} key={`k_${key}`}>
              <RenderUserGroup state={state} setState={setState} schoolData={schoolData} role={role}/>   
            </div>  
          );
        })
      }
    </div>
  );
}

export default RenderUserGroups;