import config from 'src/constants/config';
import React from 'react';
import { createUseStyles } from 'react-jss';
import SVG from 'src/components/Images/SvgRenderer';

const useStyles = createUseStyles((theme: any) => ({
  previewApp: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 2px)',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  topBarDesktop: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '5.9259259259259265%',
    position: 'relative',
  },
  logoDesktop: {
    position: 'absolute',
    top: '50%',
    left: '1.0416666666666665%',
    transform: 'translateY(-50%)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: 'auto',
      height: '53.125%',
    },
  },
  toolbarDesktop: {
    display: 'flex',
    gap: '0.7407407407407408%',
    width: 'calc(100% - 1.0416666666666665%)',
    height: '100%',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '1.0416666666666665%',
    '& > span': {
      display: 'block',
      backgroundColor: theme.colors.chip,
      height: '59.375%',
      minWidth: '1.9791666666666665%',
      borderRadius: '100%',
      '&:last-of-type': {
        borderRadius: '18.75%',
        backgroundColor: theme.colors.grey[500],
      },
    },
  },
  containerDesktop: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  leftMenuDesktop: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(12.5% - 1.66666666667%)',
    height: 'calc(100% - 2.7777777777777777% - 2.685185185185185%)',
    margin: '2.7777777777777777% 0.8333333333333334% 0 0.8333333333333334%',
    gap: '0.7407407407407408%',
  },
  leftMenuItemDesktop: {
    display: 'flex',
    width: 'calc(100% - 2.08333333333%)',
    height: 'calc(2.685185185185185% + 1.48148148148%)',
    padding: '0.7407407407407408% 1.0416666666666665%',
    color: theme.colors.primaryBlue[700],
    borderRadius: '24px',
    alignItems: 'center',
    '&::before': {
      content: `''`,
      display: 'block',
      width: '5%',
      height: '15%',
      backgroundColor: 'currentColor',
      marginLeft: '5%',
    },
    '&::after': {
      content: `'•••••••'`,
      display: 'block',
      width: '80%',
      fontSize: '70%',
      lineHeight: '100%',
      margin: '2.5% 0 2.5% 5%',
    },
    '&:first-of-type': {
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      boxShadow: theme.shadows[2],
    },
  },
  leftMenuVersionDesktop: {
    marginTop: 'auto',
    '&::after': {
      content: `'•••• | ••••'`,
      display: 'block',
      width: '80%',
      fontSize: '50%',
      lineHeight: '100%',
      margin: '2.5% 0 2.5% 5%',
      color: theme.colors.primaryBlue[500],
    },
  },
  contentDesktop: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 25%)',
    height: 'calc(100% - 2.96296296296%)',
    maxHeight: 'calc(100% - 2.96296296296%)',
    overflow: 'hidden',
    alignItems: 'center',
    gap: '1.4814814814814816%',
    paddingTop: '1.4814814814814816%',
  },
  timelineCardDesktop: {
    display: 'flex',
    width: '50.72916666666667%',
    minHeight: '45.629629629629626%',
    height: '45.629629629629626%',
    backgroundColor: theme.colors.white,
    borderRadius: '2.2222222222222223%',
    boxShadow: theme.shadows[2],
  },
  sidebarDesktop: {
    display: 'flex',
    width: '12.5%',
    height: '100%',
  },
  topBarMobile: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '5.9259259259259265%',
    position: 'relative',
  },
  logoMobile: {
    position: 'absolute',
    top: '50%',
    left: '7.0416666666666665%',
    transform: 'translateY(-50%)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: 'auto',
      height: '53.125%',
    },
  },
  toolbarMobile: {
    display: 'flex',
    gap: '1.7407407407407408%',
    width: 'calc(100% - 1.0416666666666665%)',
    height: '100%',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '7.0416666666666665%',
    '& > span': {
      display: 'block',
      backgroundColor: theme.colors.chip,
      height: '50.375%',
      minWidth: '5.9791666666666665%',
      borderRadius: '100%',
      '&:last-of-type': {
        borderRadius: '18.75%',
        backgroundColor: theme.colors.grey[500],
      },
    },
  },
  containerMobile: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 11.8518518519%)',
  },
  contentMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 2.96296296296%)',
    maxHeight: 'calc(100% - 2.96296296296%)',
    overflow: 'hidden',
    alignItems: 'center',
    gap: '3.4814814814814816%',
    paddingTop: '8.4814814814814816%',
  },
  timelineCardMobile: {
    display: 'flex',
    width: '100%',
    minHeight: '25.629629629629626%',
    height: '25.629629629629626%',
    backgroundColor: theme.colors.white,
    borderRadius: '2.2222222222222223%',
    boxShadow: theme.shadows[2],
  },
  bottomMenuMobile: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 14.0833333333%)',
    height: '5.9259259259259265%',
    position: 'relative',
    padding: '0 7.0416666666666665%',
    gap: '10.7407407407407408%',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  bottomMenuItemMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(2.685185185185185% + 1.48148148148%)',
    height: 'calc(100% - 3.48148148148%)',
    padding: '1.7407407407407408% 1.0416666666666665%',
    color: theme.colors.primaryBlue[700],
    borderRadius: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': {
      content: `''`,
      display: 'block',
      width: '50%',
      height: '15%',
      backgroundColor: 'currentColor',
      marginLeft: '125%',
    },
    '&::after': {
      content: `'••••'`,
      display: 'block',
      width: '80%',
      fontSize: '70%',
      lineHeight: '100%',
      margin: '2.5% 0 2.5% 5%',
    },
    '&:first-of-type': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type PreviewAppType = {
  layout: 'desktop' | 'mobile',
};

const PreviewApp: React.FunctionComponent<PreviewAppType> = ({ layout = "desktop" }) => {
  
  const classes = useStyles();

  return (
    <div className={classes.previewApp}>
      {
        layout === "desktop" ? (
          <>
            <div className={classes.topBarDesktop}>
              <div className={classes.logoDesktop}>
                <SVG src={config.APP_LOGO}/>
              </div>
              <div className={classes.toolbarDesktop}>
                <span/>
                <span/>
                <span/>
              </div>
            </div>
            <div className={classes.containerDesktop}>
              <div className={classes.leftMenuDesktop}>
                <div className={classes.leftMenuItemDesktop}/>
                <div className={classes.leftMenuItemDesktop}/>
                <div className={classes.leftMenuItemDesktop}/>
                <div className={classes.leftMenuItemDesktop}/>
                <div className={classes.leftMenuItemDesktop}/>
                <div className={classes.leftMenuItemDesktop}/>
                <span className={classes.leftMenuVersionDesktop}/>
              </div>
              <div className={classes.contentDesktop}>
                <div className={classes.timelineCardDesktop}/>
                <div className={classes.timelineCardDesktop}/>
                <div className={classes.timelineCardDesktop}/>
                <div className={classes.timelineCardDesktop}/>
              </div>
              <div className={classes.sidebarDesktop}/>
            </div>
          </>
        ) : layout === "mobile" ? (
          <>
            <div className={classes.topBarMobile}>
              <div className={classes.logoMobile}>
                <SVG src={config.APP_LOGO}/>
              </div>
              <div className={classes.toolbarMobile}>
                <span/>
                <span/>
                <span/>
              </div>
            </div>
            <div className={classes.containerMobile}>
              <div className={classes.contentMobile}>
                <div className={classes.timelineCardMobile}/>
                <div className={classes.timelineCardMobile}/>
                <div className={classes.timelineCardMobile}/>
              </div>
            </div>
            <div className={classes.bottomMenuMobile}>
              <div className={classes.bottomMenuItemMobile}/>
              <div className={classes.bottomMenuItemMobile}/>
              <div className={classes.bottomMenuItemMobile}/>
              <div className={classes.bottomMenuItemMobile}/>
              <div className={classes.bottomMenuItemMobile}/>
              <div className={classes.bottomMenuItemMobile}/>
            </div>
          </>
        ): null
      }
    </div>
  );
}

export default PreviewApp;
