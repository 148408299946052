import Button from '@mui/material/Button';
import Clamp from 'react-multiline-clamp';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

interface Props {
  buttonType?: any;
  originalButtonType? : any;
  disabled: any;
  size: any;
}

const useStyles = createUseStyles((theme: any) => ({
  quickLinkButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    minWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',
    borderRadius: (props: Props) => (props.size / 18) + 'px',
    gap: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: (props: Props) => {
      if(props.buttonType === "grey") return theme.colors.grey[150];
      else if(props.buttonType === "blue") return theme.colors.primaryBlue[100];
      else if(props.buttonType === "green") return theme.colors.systemGreen[100];
      else if(props.buttonType === "red") return theme.colors.systemRed[100];
      else if(props.buttonType === "orange") return theme.colors.systemOrange[100];
      else if(props.buttonType === "purple") return theme.colors.purple[100];
      else if(props.buttonType === "pink") return theme.colors.pink[100];
      else if(props.buttonType === "yellow") return theme.colors.yellow[100];
      else if(props.buttonType === "brightRed") return theme.colors.brightRed[100];
      else return 'none';
    },
    color: (props: Props) => {
      if(props.buttonType) return theme.colors.black;
      else return 'none';
    },
    '& svg': {
      marginTop: 'auto',
      width: (props: Props) => (props.size / 2.5) + 'px',
      height: (props: Props) => (props.size / 2.5) + 'px',
      color: (props: Props) => {
        if(props.disabled) return theme.colors.grey[500];
        else if(props.buttonType === "grey") return theme.colors.grey[500];
        else if(props.buttonType === "blue") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "green") return theme.colors.systemGreen[500];
        else if(props.buttonType === "red") return theme.colors.systemRed[500];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[500];
        else if(props.buttonType === "purple") return theme.colors.purple[500];
        else if(props.buttonType === "pink") return theme.colors.pink[500];
        else if(props.buttonType === "yellow") return theme.colors.yellow[500];
        else if(props.buttonType === "brightRed") return theme.colors.brightRed[500];
        else return 'none';
      },
    },
    '& > span': {
      display: 'block',
      maxWidth: '100%',
      color: (props: Props) => {
        if(props.disabled) return theme.colors.grey[500];
        else if(props.buttonType === "grey") return theme.colors.grey[500];
        else if(props.buttonType === "blue") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "green") return theme.colors.systemGreen[500];
        else if(props.buttonType === "red") return theme.colors.systemRed[500];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[500];
        else if(props.buttonType === "purple") return theme.colors.purple[500];
        else if(props.buttonType === "pink") return theme.colors.pink[500];
        else if(props.buttonType === "yellow") return theme.colors.yellow[500];
        else if(props.buttonType === "brightRed") return theme.colors.brightRed[500];
        else return 'none';
      },
      fontSize: '100%',
      fontWeight: '500',
      textTransform: 'none',
      lineHeight: '1.3',
      margin: 'auto 0',
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.buttonType === "grey") return theme.colors.grey[200];
        else if(props.buttonType === "blue") return theme.colors.primaryBlue[200];
        else if(props.buttonType === "green") return theme.colors.systemGreen[200];
        else if(props.buttonType === "red") return theme.colors.systemRed[200];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[200];
        else if(props.buttonType === "purple") return theme.colors.purple[200];
        else if(props.buttonType === "pink") return theme.colors.pink[200];
        else if(props.buttonType === "yellow") return theme.colors.yellow[200];
        else if(props.buttonType === "brightRed") return theme.colors.brightRed[200];
        else return 'none';
      },
    },
  },
}));

type QuickLinkButtonType = {
  className?: any;
  icon?: any;
  buttonType?: 'grey' | 'blue' | 'green' | 'red' | 'orange' | 'purple' | 'pink' | 'brightRed' | 'disabled';
  onClick?: any;
  children?: any;
  dataCy?: string;
  disabled?: boolean;
  size?: any;
};

const QuickLinkButton: React.FunctionComponent<QuickLinkButtonType> = ({className, buttonType = 'blue', icon, onClick, children, dataCy, disabled, size = 128}) => {
  
  const originalButtonType = buttonType;
  if(buttonType === "disabled") disabled = true;
  if(disabled) buttonType = "disabled";

  const classes = useStyles({
    buttonType,
    originalButtonType,
    disabled,
    size
  });

  return (
    <Button type="button" className={`${classes.quickLinkButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={-1} data-cy={isCypress() ? dataCy : null}>
      {icon}
      <Clamp withTooltip lines={2}>
        <span>{children}</span>
      </Clamp>
    </Button>
  );
};

export default QuickLinkButton;