import BlockButton from 'src/components/Buttons/BlockButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { setTimetableSchool, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  timetableSettingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  buttons: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '0 24px',
    maxWidth: 'calc(100% - 48px)',
  },
}));

const TimetableSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const classes = useStyles();
  const timetableData = useAppSelector((state: any) => state.timetable);
  const userData = useAppSelector((state: any) => state.user);
  const schoolID = timetableData.school === null ? null : timetableData.school.schoolID;

  const schoolSettings = userData.schoolSettings;
  const userAccessSchools = userData.userAccessSchools;

  const list = dataData.schools.filter((item: any) => userAccessSchools.timetable.includes(item.schoolID));
  const slots = (getSchoolSettings(schoolID, "modules", schoolSettings).timetable && getSchoolSettings(schoolID, "modules", schoolSettings).timetable.slots) ? getSchoolSettings(schoolID, "modules", schoolSettings).timetable.slots : false;
  const workingDays = getSchoolSettings(schoolID, 'workingDays', schoolSettings) === null ? [] : getSchoolSettings(schoolID, 'workingDays', schoolSettings);
  const subjects = timetableData.subjects;
  const employees = dataData.employees.filter((item: any) => item.schoolID === schoolID && item.enableForTimetable);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const setViewMode = (view: any) => {
    dispatch(setTimetableViewMode(view));
  };

  const handleSchoolSelect = () => {
    setViewMode('schoolSelect');
    dispatch(setTimetableSchool(null));
  };

  const handleTimetableInfoClick = () => {
    let reason = "";
    if(!slots) {
      reason = t('function_limited_timetables_slots');
    } else  if(workingDays.length === 0) {
      reason = t('function_limited_timetables_days');
    } else  if(subjects.length === 0) {
      reason = t('function_limited_timetables_subjects');
    } else if(employees.length === 0) {
      reason = t('function_limited_timetables_teachers');
    }
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: reason,
    };
    dispatch(setWarningModal(settings));
  };

  const handleSubjectsInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_subject_teachers'),
    };
    dispatch(setWarningModal(settings));
  };


  const isTimetableDisabled = !slots || workingDays.length === 0 || subjects.length === 0 || employees.length === 0;
  const isSubjectsDisabled = employees.length === 0;

  return (
    <div className={classes.timetableSettingsWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.buttons}>
        <BlockButton icon={<SVG src="calendar-timetable"/>} onClick={() => setViewMode('timetableSelect')} disabled={isTimetableDisabled} onInfoIconClick={isTimetableDisabled ? handleTimetableInfoClick : null}>
          {t('timetable')}
        </BlockButton>
        <BlockButton icon={<SVG src="rooms"/>} onClick={() => setViewMode('rooms')}>
          {t('timetable_rooms')}
        </BlockButton>
        <BlockButton icon={<SVG src="subjects"/>} onClick={() => setViewMode('subjects')} disabled={isSubjectsDisabled} onInfoIconClick={isSubjectsDisabled ? handleSubjectsInfoClick : null}>
          {t('timetable_subjects')}
        </BlockButton>
        <BlockButton icon={<SVG src="employees"/>} onClick={() => setViewMode('employees')}>
          {t('timetable_employees')}
        </BlockButton>
        {
          list.length > 1 ? (
            <BlockButton icon={<SVG src="school"/>} onClick={handleSchoolSelect}>
              {t('change_school')}
            </BlockButton>
          ) : null
        }
      </div>
    </div>
  );
};

export default TimetableSettings;