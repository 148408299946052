import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { isCypress } from 'src/utils/useCypress';
import { setTimetableChild, setTimetableData, setTimetableType, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  childSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '16px',
    boxShadow: theme.shadows[2],
    maxHeight: '85vh',
    width: 'fit-content',
    minWidth: 'calc(60vw - 32px)',
    maxWidth: 'calc(60vw - 32px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
    },
    '& > span': {
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  backButton: {
    backgroundColor: theme.colors.grey[200],
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    width: '36px',
    height: '36px',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  childrenSelect: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  customContainer: {
    backgroundColor: theme.colors.grey[100],
    borderRadius: '24px',
    maxHeight: '100%',
    overflow: 'auto',
  },
  topWrapper: {
    backgroundColor: theme.colors.white,
    position: 'sticky',
    top: '0',
    paddingTop: '24px',
    boxShadow: 'none',
  },
}));

const ChildSelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const schoolID = timetableData.school === null ? null : timetableData.school.schoolID;
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const classes = useStyles();
  const list = schoolID === null ? [] : dataData.children.filter((item: any) => item.schoolsID.indexOf(schoolID) !== -1).map((item: any) => { return { childID: item.childID, classID: item.classID }; });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const [state, setState] = useStates({
    isSelectOpen: false,
    children: [],
    canClick: true,
  });

  const handleChange = (e: any, data: any, value: any) => {
    dispatch(setTimetableChild(value[0]));
    dispatch(setTimetableType("child"));
    getHours(value[0]);
  };

  const getHours = useCallback((value: any) => {
    const params = {
      childID: value.childID,
      schoolID: schoolID,
      type: [1],
    };
    timetableService && timetableService.listHours(params).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableData(result.data));
          dispatch(setTimetableViewMode("timetableEdit"));
        } else {
          createNotification(t("timetable_failed_load"), "error");
          setState("canClick", true);
        }
      } else {
        createNotification(t("timetable_failed_load"), "error");
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("timetable_failed_load"), "error");
      setState("canClick", true);
    });
  }, [dispatch, t, timetableService, schoolID, setState]);


  const handleBack = () => {
    dispatch(setTimetableViewMode("timetableSelect"));
  };

  return (
    <div className={classes.childSelectWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.box}>
        <IconButton className={classes.backButton} onClick={handleBack} data-cy={isCypress() ? "backButton" : null}>
          <SVG src="arrow-left"/>
        </IconButton> 
        <span>{t('timetable_select_child')}</span>
        <ChildrenSelect
          selectedChildren={state.children}
          defaultChildren={list}
          mode="select"
          isDisableClick={!state.canClick}
          isSelectAll={false}
          isMultipleSelect={false}
          isSelectInAllClass={true}
          isAllowOnlyOneChildAtOnce={true}
          onClickChild={handleChange}
          customClasses={{
            childrenSelect: classes.childrenSelect,
            container: classes.customContainer,
            topWrapper: classes.topWrapper,
          }}
        />
      </div>
    </div>
  );
};

export default ChildSelect;