import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import ColorInfoItem from 'src/components/Items/ColorInfoItem';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setTimetableSubjectDetailModal, setTimetableSubjectHandleModal } from '../../../store/actions/modals.actions';
import { setTimetableSubjects } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
  employees: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  employeePhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '6px',
      minWidth: '35px',
      minHeight: '35px',
      width: '35px',
      height: '35px',
      maxWidth: '35px',
      maxHeight: '35px',
    },
    '& > p': {
      fontSize: '12px',
      marginLeft: '8px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  fullname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
}));

const TimetableSubjectDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);
  const employees = dataData.employees.filter((employee: any) => employee.schoolID === modalsData.timetableSubjectDetailModal.schoolID && employee.enableForTimetable);

  const isValid = modalsData.timetableSubjectDetailModal.subjectID === null ? false : true;
  const subjectID = isValid ? modalsData.timetableSubjectDetailModal.subjectID : null;
  const schoolID = isValid ? modalsData.timetableSubjectDetailModal.schoolID : null;
  const subjectData = isValid ? (timetableData.subjects.filter((subject: any) => subject.subjectID === subjectID).length === 1 ? timetableData.subjects.find((subject: any) => subject.subjectID === subjectID) : null) : null
  const subjectEmployees = isValid ? employees.filter((employee: any) => subjectData.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return {employeeID: employee.employeeID} }) : [];
  
  const getType = (type: any) => {
    if(type === 1) {
      return t('timetable_subject_education');
    } else if(type === 2) {
      return t('timetable_subject_teacher');
    } else {
      return '';
    }
  };

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      subjectID: null,
      schoolID: null,
    };
    dispatch(setTimetableSubjectDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      subjectID: subjectID,
      schoolID: schoolID,
    };
    dispatch(setTimetableSubjectHandleModal(settings));
  };

  const handleDelete = () => {
    timetableService && timetableService.deleteSubject(subjectID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("timetable_subject_deleted"), "success");
          onCloseModal();
          const newSubjects = timetableData.subjects.map((subject: any) => {
            if(subject.subjectID === subjectID) {
              return null;
            } else {
              return subject;
            }
          }).filter((item: any) => item !== null);
          dispatch(setTimetableSubjects(newSubjects));
        } else {
          createNotification(t("timetable_subject_not_deleted"), "error");
        }
      } else {
        createNotification(t("timetable_subject_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_subject_not_deleted"), "error");
    });
  };

  return isValid ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "timetableSubjectDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('timetable_subject')} {subjectData.name}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.item}>
            <p className={classes.title}>
              <SVG src="info-circle-outlined"/>
              {t('timetable_subject_name')}
            </p>
            <p className={classes.detail}>
              {subjectData.name}
            </p>
          </div>
          <div className={classes.item}>
            <p className={classes.title}>
              <SVG src="briefcase-outlined"/>
              {t('timetable_subject_abbr')}
            </p>
            <p className={classes.detail}>
              {subjectData.shortName}
            </p>
          </div>
          <div className={classes.item}>
            <p className={classes.title}>
              <SVG src="class"/>
              {t('timetable_subject_type')}
            </p>
            <p className={classes.detail}>
              {getType(subjectData.type)}
            </p>
          </div>
          <div className={classes.item}>
            <p className={classes.title}>
              <SVG src="bucket-paint-outlined"/>
              {t('timetable_subject_color')}
            </p>
            <div className={classes.detail}>
              <ColorInfoItem color={subjectData.color}/>
            </div>
          </div>
          <div className={classes.item}>
            <span className={classes.title}>
              <SVG src="user-circle-outlined"/>
              {t('timetable_subject_teachers')}
            </span>
            <div className={classes.employees}>
            {
              subjectEmployees.map((employee: any, key: any) => (
                <div className={classes.employeePhoto} key={`k_${key}`}>
                  <AuthenticatedImage thumbLink={getEmployeeData(employee.employeeID).photo.thumbLink}/>
                  <p className={classes.fullname}>{getEmployeeData(employee.employeeID).firstname + ' ' + getEmployeeData(employee.employeeID).surname}</p>
                </div>
              ))
            }
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="clear" onClick={handleDelete} dataCy="deleteButton">
            {t("timetable_subject_delete")}
          </NormalButton>
          <NormalButton onClick={handleEdit} dataCy="editButton">
            {t('timetable_subject_edit')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null;
};  

export default TimetableSubjectDetailModal;