import MediaGrid from '../MediaGrid';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setCalendarGalleryModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  calendarGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: 'calc(100% - 30px)',
    padding: '15px',
    minHeight: '220px',
  },
}));

type CalendarGalleryType = {
  eventID: any;
};

const CalendarGallery: React.FunctionComponent<CalendarGalleryType> = ({ eventID }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const eventData = calendarData.events.find((item: any) => item.eventID === eventID);
  const photos = eventData.media.photos;
  const files = eventData.media.files;
  const [gridData, setGridData]: any = useState([]);
  
  useEffect(() => {
    const tmpPhotos = photos.map((photo: any) => {
      return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name, favorite: photo.favorite };
    });
    const tmpFiles = files.map((file: any) => {
      return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size, favorite: file.favorite };
    });
    const tmpGridData = [].concat(tmpPhotos, tmpFiles).filter((item: any) => item);
    if(tmpGridData.length > 0) {
      setGridData(tmpGridData);
    }
  }, [eventID, photos, files, dispatch], [eventID, photos, files]);

  const handleOpenModal = (mediaID: any) => {
    const settings = {
      isOpen: true,
      mediaID: mediaID,
      mediaList: gridData,
    };
    dispatch(setCalendarGalleryModal(settings));  
  };
  
  return (
    <div className={classes.calendarGallery} data-cy={isCypress() ? "calendarGallery" : null}>
      <MediaGrid
        data={gridData}
        width='100%'
        maxWidth='100%'
        size={0.5}
        onModalOpen={handleOpenModal}
      />
    </div>
  );
}

export default CalendarGallery;