import HttpService from '../http.service';

const listRooms = () => {
  return HttpService.get(`timetable/room`, {}, {});
};

const listSubjects = () => {
  return HttpService.get(`timetable/subject`, {}, {});
};

const listHours = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`timetable${query ? `${query}`: ""}`, {}, {});
};

export {
  listRooms,
  listSubjects,
  listHours,
};