import Blog from './components/Blog';
import Pinterest from './components/Pinterest';
import React, { useRef } from 'react';
import tabs from '../../../constants/tabs';
import TabsMenu from '../../../components/Menus/TabsMenu';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getUserRole } from 'src/utils/useUser';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';

const useStyles = createUseStyles((theme: any) => ({
  inspirationPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: '1 1 100%',
  },
  wrapperColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  inspirationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  nothingFoundWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  nothingFound: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tab } = useParams();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const userAccess = userData.userAccess;

  const [state, setState] = useStates({
    isEndOfScroll: false,
    isLoadingMore: true,
  });

  const setIsLoadingMore = (value: any) => {
    setState("isLoadingMore", value);
  };

  const setIsEndOfScroll = (value: any) => {
    setState("isEndOfScroll", value);
  };

  const inspirationScroll: any = useRef(null);

  const tabsItems = tabs.inspiration.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));  

  const onScroll = () => {
    if(inspirationScroll.current) {
      const { scrollTop, scrollHeight, clientHeight }: any = inspirationScroll.current;
      if(scrollTop + clientHeight > scrollHeight - 100) {
        if(!state.isLoadingMore && !state.isEndOfScroll) {
          setState("isLoadingMore", true);
        };
      }
    }
  };
  
  useEffect(() => {
    const url = location.pathname.replace("/inspiration/", "");
    if(url === "/inspiration") {
      setState("isLoadingMore", true);
      setState("isEndOfScroll", false);
      navigate("/inspiration/pinterest");
    }
  }, [tab, location.pathname, navigate, setState], [tab]);
  
  const setTab = (tab: any) => {
    setState("isLoadingMore", true);
    setState("isEndOfScroll", false);
    navigate(`/inspiration/${tab}`);
  };

  return (
    <div className={classes.inspirationPage} onScroll={onScroll} ref={inspirationScroll}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={tab} onSelect={setTab} disabled={!userAccess.subscription}/>
      </div>
      <div className={classes.wrapper}>
        {
          !userAccess.subscription ? (
          <SubscriptionBanner service="inspiration" isInCenter={true}/>
          ) : null
        }
        <div className={classes.wrapperColumn}>
          <div className={classes.inspirationWrapper}>
            {
              (tab === "" || tab === undefined) ? (
                <div className={classes.loading}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : tab === "pinterest" ? (
                <Pinterest isLoadingMore={state.isLoadingMore} isEndOfScroll={state.isEndOfScroll} setIsLoadingMore={setIsLoadingMore} setIsEndOfScroll={setIsEndOfScroll}/>
              ) : tab === "blog" ? (
                <Blog isLoadingMore={state.isLoadingMore} isEndOfScroll={state.isEndOfScroll} setIsLoadingMore={setIsLoadingMore} setIsEndOfScroll={setIsEndOfScroll}/>
              ) : (
                <div className={classes.nothingFoundWrapper}>
                  <div className={classes.nothingFound}>
                    {t('nothing_was_found')}
                  </div>  
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;