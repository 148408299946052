import { createUseStyles } from 'react-jss';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

interface Props {
  type: any,
};

const useStyles = createUseStyles((theme: any) => ({
  banner: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px',
    borderRadius: '6px',
    fontSize: '0.9rem',
    gap: '8px',
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    backgroundColor: (props: Props) => {
      if(props.type === "default") return theme.colors.primaryBlue[500];
      else if(props.type === "info") return theme.colors.primaryBlue[100];
      else if(props.type === "success") return theme.colors.systemGreen[500];
      else if(props.type === "warning") return theme.colors.systemOrange[500];
      else if(props.type === "error") return theme.colors.systemRed[500];
      else return theme.colors.primaryBlue[500];     
    },
    color: (props: Props) => {
      if(props.type === "default") return theme.colors.white;
      else if(props.type === "info") return theme.colors.black;
      else if(props.type === "success") return theme.colors.white;
      else if(props.type === "warning") return theme.colors.white;
      else if(props.type === "error") return theme.colors.white;
      else return theme.colors.white;     
    },
    '& > button': {
      marginLeft: 'auto',
      color: 'inherit',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
    '& > p': {
      '& > span': {
        marginLeft: '4px',
        cursor: 'pointer',
        transition: 'all 0.25s',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& > span': {
      marginLeft: '-10px',
      height: '20px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

type BannerType = {
  children: any;
  button?: any;
  icon?: boolean;
  type?: any;
  timeout?: any;
  closeable?: boolean,
  className?: any,
};

const Banner: React.FunctionComponent<BannerType> = ({ children, button, icon = false, type = "default", timeout, closeable = false, className }) => {
  
  const [isVisible, setIsVisible] = useState(true);

  const classes = useStyles({
    type: type,
  });

  const handleOnClick = () => {
    handleRemove();  
  };  
  
  const handleRemove = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if(timeout) {
      setTimeout(function() {
        handleRemove();
      }, timeout);
    }
  }, [timeout], []);

  const getIcon = () => {
    if(type === "default") return "flash-outlined";
    else if(type === "info") return "info-circle-outlined";
    else if(type === "success") return "checkmark-circle";
    else if(type === "warning") return "warning";
    else if(type === "error") return "exclamation-mark-circle-outlined";
    else return "flash-outlined";
  };
  
  return isVisible ? (
    <div className={`${classes.banner} ${className ? className : null}`}>
      {
        icon ? (
          <span>
            <SVG src={getIcon()}/>
          </span>
        ) : null
      }
      <p data-clarity-unmask="true">
        {htmlParse(children)}
        {
          button ? button : null
        }
      </p>
      {
        closeable ? (
          <IconButton onClick={handleOnClick}>
            <SVG src="close"/>
          </IconButton>
        ) : null
      }
    </div>
  ) : null;
}

export default Banner;
