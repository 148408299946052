import slice from '../slices/excusenotes.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setExcuseNotesCurrentChild = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesCurrentChild(data));
  }
};

export const setExcuseNotesCurrentChildren = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesCurrentChildren(data));
  }
};

export const setExcuseNotesCurrentChildrenData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesCurrentChildrenData(data));
  }
};

export const setExcuseNotesCurrentClass = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesCurrentClass(data));
  }
};

export const setExcuseNotesDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesDate(data));
  }
};

export const setExcuseNotesList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesList(data));
  }
};

export const addToExcuseNotesMonths = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToExcuseNotesMonths(data));
  }
};

export const addToExcuseNotesList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToExcuseNotesList(data));
  }
};

export const addExcuseNotesList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addExcuseNotesList(data));
  }
};

export const updateExcuseNotesList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateExcuseNotesList(data));
  }
};

export const updateExcuseNotesGroupList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateExcuseNotesGroupList(data));
  }
};

export const deleteExcuseNotesAbsence = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteExcuseNotesAbsence(data));
  }
};

export const deleteExcuseNotesAbsenceGroup = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteExcuseNotesAbsenceGroup(data));
  }
};

export const clearExcuseNotesList = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearExcuseNotesList());
  }
};

export const clearExcuseNotesMonths = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.clearExcuseNotesMonths());
  }
};

export const setExcuseNotesViewMode = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExcuseNotesViewMode(data));
  }
};