import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

interface Props {
  children: any;
  title: any;
};

const DocumentTitle: React.FunctionComponent<Props> = (props: Props) => {
  
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);  

  return props.children;
};

DocumentTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default DocumentTitle;