import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useRef } from 'react';
import RenderAll from './RenderAll';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  usersSelect: {
    width: '100%',
    '&.inModal': {
      display: 'none',
    },  
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type UsersSelectType = {
  onClickUser?: any;
  setSelectedUsers?: any;
  selectedUsers?: any;
  defaultSchools?: any;
  defaultUsers?: any;
  defaultSchoolsData?: any;
  defaultUsersData?: any; 
  isInModal?: boolean;
  isModalOpen?: boolean;
  isMultipleSelect?: boolean;
  isSelectAll?: boolean;
  isSelectInAllSchool?: boolean;
  mode?: any;
  isShowUsersEmojis?: boolean;
  isDisableTotalCount?: boolean;
  isDisableClick?: boolean;
  validateMultipleSchool?: boolean;
  maxUsersSelected?: number | null;
  isAllowArchived?: boolean;
  isAllowArchivedToggle?: boolean;
  isAllowSearch?: boolean;
  isAllowOnlyOneUserAtOnce?: boolean;
  isGroupByRoles?: boolean;
  groupRoles?: any;
  modalTitle?: string;
  modalOnClose?: any;
  modalOnSave?: any;
  modalCloseOnSave?: boolean;
  modalUsersCount?: string;
  modalAllowUsersCount?: boolean;
  modalAllowCancel?: boolean;
  modalAllowClose?: boolean;
  modalAllowClear?: boolean;
  modalAllowNoUser?: boolean;
  customSort?: any;
  customClasses?: any;
  customNoResults?: any;
  customCombineMessage?: any;
  customLimitMessage?: any;
  isReload?: any;
  isCloseAllOnReload?: any;
};

const UsersSelect: React.FunctionComponent<UsersSelectType> = ({
  onClickUser,
  setSelectedUsers,
  selectedUsers,
  defaultSchools,
  defaultUsers,
  defaultSchoolsData,
  defaultUsersData, 
  isInModal = false,
  isModalOpen = false,
  isMultipleSelect = false,
  isSelectAll = false,
  isSelectInAllSchool = false,
  mode = "detail",
  isShowUsersEmojis = false,
  isDisableTotalCount = false,
  isDisableClick = false,
  validateMultipleSchool = false,
  maxUsersSelected = null,
  isAllowArchived = false,
  isAllowArchivedToggle = true,
  isAllowSearch = true,
  isAllowOnlyOneUserAtOnce = false,
  isGroupByRoles = false,
  groupRoles = [2, 3, 4],
  modalTitle = "",
  modalOnClose,
  modalOnSave,
  modalCloseOnSave = true,
  modalUsersCount = "default",
  modalAllowUsersCount = true,
  modalAllowCancel = false,
  modalAllowClose = false,
  modalAllowClear = false,
  modalAllowNoUser = false,
  customSort,
  customClasses,
  customNoResults,
  customCombineMessage,
  customLimitMessage,
  isReload = false,
  isCloseAllOnReload = false,
}) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const schoolsData = useMemo(() => dataData.schools.map((item: any) => {
    let returnItem = item;
    if(defaultSchoolsData) {
      const isMoreData = defaultSchoolsData.filter((more: any) => more.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isMoreData) {
        const moreData = defaultSchoolsData.find((more: any) => more.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.schools, defaultSchoolsData, customSort]);

  const usersData = useMemo(() => dataData.users.map((item: any) => {
    let returnItem = item;
    if(defaultUsersData) {
      const moreDataCount = defaultUsersData.filter((more: any) => more.userID === returnItem.userID).length === 1;
      if(moreDataCount) {
        const moreData = defaultUsersData.find((more: any) => more.userID === returnItem.userID);
        returnItem = Object.assign({}, item, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.userID === returnItem.userID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.userID === returnItem.userID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.users, defaultUsersData, customSort]);

  const defaultSchoolList = (defaultSchools ? defaultSchools : dataData.schools).map((item: any) => { return { schoolID: item.schoolID }; });
  const defaultUserList = (defaultUsers ? defaultUsers : dataData.users).map((item: any) => { return { userID: item.userID, schoolsID: Array.isArray(item.schoolID) ? item.schoolID : [] }; });

  const getUserData = useCallback((userID: any) => {
    return usersData.filter((item: any) => item.userID === userID).length === 0 ? usersData.find((item: any) => item.userID === -1) : usersData.find((item: any) => item.userID === userID);
  }, [usersData]);

  const getSchoolData = useCallback((schoolID: any) => {
    return schoolsData.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : schoolsData.find((item: any) => item.schoolID === schoolID);
  }, [schoolsData]);

  const getUsersBySchoolID = useCallback((schoolID: any) => {
    let list = defaultUserList.filter((item: any) => getUserData(item.userID).schoolsID.indexOf(schoolID) !== -1 || item.userID === -1);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getUserData(item.userID).firstname.toLowerCase().includes(stateRef.current.search) || getUserData(item.userID).surname.toLowerCase().includes(stateRef.current.search));
    }
    list.sort((a: any, b: any) => {
      if(getUserData(a.userID).customSortOrder !== undefined && getUserData(b.userID).customSortOrder !== undefined) {
        return getUserData(a.userID).customSortOrder - getUserData(b.userID).customSortOrder;
      } else if (getUserData(a.userID).customSortOrder !== undefined) {
        return -1;
      } else if (getUserData(b.userID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultUserList, getUserData]);

  const getUsersBySchoolIDAndRole = useCallback((schoolID: any, role: any) => {
    let list = defaultUserList.filter((item: any) => (getUserData(item.userID).schoolsID.indexOf(schoolID) !== -1 || item.userID === -1) && getUserData(item.userID).roleType === role);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getUserData(item.userID).firstname.toLowerCase().includes(stateRef.current.search) || getUserData(item.userID).surname.toLowerCase().includes(stateRef.current.search));
    }
    list.sort((a: any, b: any) => {
      if(getUserData(a.userID).customSortOrder !== undefined && getUserData(b.userID).customSortOrder !== undefined) {
        return getUserData(a.userID).customSortOrder - getUserData(b.userID).customSortOrder;
      } else if (getUserData(a.userID).customSortOrder !== undefined) {
        return -1;
      } else if (getUserData(b.userID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultUserList, getUserData]);

  const getUsers = useCallback(() => {
    let list = defaultUserList;
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getUserData(item.userID).firstname.toLowerCase().includes(stateRef.current.search) || getUserData(item.userID).surname.toLowerCase().includes(stateRef.current.search));
    }
    list.sort((a: any, b: any) => {
      if (getUserData(a.userID).customSortOrder !== undefined && getUserData(b.userID).customSortOrder !== undefined) {
        return getUserData(a.userID).customSortOrder - getUserData(b.userID).customSortOrder;
      } else if (getUserData(a.userID).customSortOrder !== undefined) {
        return -1;
      } else if (getUserData(b.userID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultUserList, getUserData]);

  const getSchools = useCallback(() => {
    let list = defaultSchoolList;
    list = list.filter((item: any) => getUsersBySchoolID(item.schoolID).length !== 0);
    list.sort((a: any, b: any) => {
      if (getSchoolData(a.schoolID).customSortOrder !== undefined && getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return getSchoolData(a.schoolID).customSortOrder - getSchoolData(b.schoolID).customSortOrder;
      } else if (getSchoolData(a.schoolID).customSortOrder !== undefined) {
        return -1;
      } else if (getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultSchoolList, getUsersBySchoolID, getSchoolData]);

  const activeUsers = defaultUserList.filter((item: any) => getUserData(item.employeeID).isArchived === false);
  const archivedUsers = defaultUserList.filter((item: any) => getUserData(item.employeeID).isArchived === true);
  
  const defaultState: any = useMemo(() => {
    return {
      data: {
        schools: defaultSchoolList,
        users: defaultUserList,
      },
      isReload: isReload,
      isCloseAllOnReload: isCloseAllOnReload,
      isLoadedAll: true,
      search: '',
      onClickUser: onClickUser,
      selectedUsers: selectedUsers ? selectedUsers : [],
      defaultSelectedUsers: selectedUsers ? selectedUsers : [],
      setSelectedUsers: setSelectedUsers,
      defaultSchools: defaultSchools,
      defaultUsers: defaultUsers,
      defaultSchoolsData: defaultSchoolsData,
      defaultUsersData: defaultUsersData,
      isInModal: isInModal,
      isModalOpen: isModalOpen,
      isMultipleSelect: isMultipleSelect,
      isSelectAll: isSelectAll,
      isSelectInAllSchool: isSelectInAllSchool,
      mode: mode,
      isShowUsersEmojis: isShowUsersEmojis,
      isDisableTotalCount: isDisableTotalCount,
      isDisableClick: isDisableClick,
      validateMultipleSchool: validateMultipleSchool,
      maxUsersSelected: maxUsersSelected,
      isAllowArchived: isAllowArchived,
      isAllowArchivedToggle: isAllowArchivedToggle,
      isAllowSearch: isAllowSearch,
      isAllowOnlyOneUserAtOnce: isAllowOnlyOneUserAtOnce,
      isGroupByRoles: isGroupByRoles,
      groupRoles: groupRoles,
      modalTitle: modalTitle,
      modalOnClose: modalOnClose,
      modalOnSave: modalOnSave,
      modalCloseOnSave: modalCloseOnSave,
      modalUsersCount: modalUsersCount,
      modalAllowUsersCount: modalAllowUsersCount,
      modalAllowCancel: modalAllowCancel,
      modalAllowClose: modalAllowClose,
      modalAllowClear: modalAllowClear,
      modalAllowNoUser: modalAllowNoUser,
      customClasses: customClasses,
      customNoResults: customNoResults,
      customCombineMessage: customCombineMessage,
      customLimitMessage: customLimitMessage,
      functions: {
        getUserData: (userID: any) => getUserData(userID),
        getSchoolData: getSchoolData,
        getUsersBySchoolID: getUsersBySchoolID,
        getUsersBySchoolIDAndRole: getUsersBySchoolIDAndRole,
        getUsers: getUsers,
        getSchools: getSchools,
      },
    }
  }, [isReload, isCloseAllOnReload, customClasses, customNoResults, customCombineMessage, customLimitMessage, defaultUserList, defaultUsers, defaultUsersData, defaultSchoolList, defaultSchools, defaultSchoolsData, getUsers, getUsersBySchoolID, getUsersBySchoolIDAndRole, getUserData, getSchoolData, getSchools, isAllowArchived, isAllowArchivedToggle, isAllowSearch, isDisableClick, isDisableTotalCount, validateMultipleSchool, maxUsersSelected, isAllowOnlyOneUserAtOnce, isGroupByRoles, groupRoles, isInModal, isModalOpen, isMultipleSelect, isSelectAll, isSelectInAllSchool, mode, isShowUsersEmojis, modalAllowCancel, modalAllowUsersCount, modalAllowClear, modalAllowClose, modalAllowNoUser, modalUsersCount, modalCloseOnSave, modalOnClose, modalOnSave, modalTitle, onClickUser, selectedUsers, setSelectedUsers]);
  
  const [state, setState, setStates] = useStates(defaultState);

  const handleState = useCallback((name: any, value: any) => {
    if(Array.isArray(name) && Array.isArray(value)) {
      let stateHolder = state;
      let newObjects = {};
      name.forEach((item: any, index: any) => {
        stateHolder = {...stateHolder, [item]: value[index]};
        const newObject = {
          [item]: value[index],
        };
        newObjects = Object.assign(newObject, newObjects, newObject);
      });
      stateRef.current = stateHolder;
      setStates(newObjects);
    } else {
      stateRef.current = {...state, [name]: value};
      setState(name, value);
    }
  }, [state, setState, setStates]); 

  const stateRef = useRef(state);

  useEffect(() => {
    let stateNames: any = [];
    let stateValues: any = [];
    if(archivedUsers.length === 0) {
      if(state.isShowArchived) {
        stateNames = [...stateNames, "isShowArchived"];
        stateValues = [...stateValues, false]; 
      }
      if(state.isShowArchivedToggle) {
        stateNames = [...stateNames, "isShowArchivedToggle"];
        stateValues = [...stateValues, false];  
      }
    }
    if(activeUsers.length === 0 && archivedUsers.length !== 0) {
      if(!state.isShowArchived) {
        stateNames = [...stateNames, "isShowArchived"];
        stateValues = [...stateValues, true]; 
      }
      if(state.isShowArchivedToggle) {
        stateNames = [...stateNames, "isShowArchivedToggle"];
        stateValues = [...stateValues, false];
      }
    }
    if(selectedUsers) {
      if(selectedUsers.length > 0) {
        if(selectedUsers.filter((item: any) => getUserData(item.employeeID).isArchived).length > 0) {
          if(!state.isShowArchived) {
            stateNames = [...stateNames, "isShowArchived"];
            stateValues = [...stateValues, true];
          }
        }
      }
    }
    stateNames = [...stateNames, "isLoadedAll"];
    stateValues = [...stateValues, true]; 
    handleState(stateNames, stateValues);
  }, [activeUsers,archivedUsers, handleState, selectedUsers, state.isShowArchived, state.isShowArchivedToggle, getUserData], []);
  
  useEffect(() => {
    return () => {
      if(setSelectedUsers) setSelectedUsers(stateRef.current.selectedUsers);
    }
  }, [setSelectedUsers], []);

  useEffect(() => {
    if(setSelectedUsers) setSelectedUsers(stateRef.current.selectedUsers);
  }, [setSelectedUsers], [stateRef.current.selectedUsers]);

  useEffect(() => {
    if(state.isDisableClick !== isDisableClick) {
      handleState("isDisableClick", isDisableClick);
    }
    if(JSON.stringify(state.defaultUsers) !== JSON.stringify(defaultUsers)) {
      handleState("defaultUsers", defaultUsers);
    }
    if(JSON.stringify(state.defaultUsersData) !== JSON.stringify(defaultUsersData)) {
      handleState("defaultUsersData", defaultUsersData);
    }
    if(isReload) {
      const newDefaultState = {...defaultState, isLoadedAll: true};
      setStates(newDefaultState);
      stateRef.current = defaultState;
    }
  }, [state, handleState, defaultState, setStates, isDisableClick, defaultUsers, defaultUsersData, isReload], [isDisableClick, defaultUsers, defaultUsersData, isReload])

  return (
    <div className={`${classes.usersSelect} ${isInModal ? 'inModal' : null} ${customClasses ? (customClasses.usersSelect ? customClasses.usersSelect : "") : ""}`}>
      {
        !state.isLoadedAll ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <RenderAll state={state} setState={handleState}/>
        )
      }
    </div>
  );
}

export default UsersSelect;