import * as UserService from 'src/services/user.service';
import CountUp from 'react-countup';
import httpResult from 'src/constants/httpresult';
import IconButton from 'src/components/Buttons/IconButton';
import loadUserSettings from 'src/controllers/UserController/modules/UserSettings';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TreeView from 'src/components/Layouts/TreeView';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { formatFileSize, isKey, joinValues } from 'src/utils/useFunctions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { setIsOpenSetupScreen } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  dataManagementWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '8px',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    width: 'calc(100% - 60px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px 0px 20px 0px',
      borderRadius: '0px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > button': {
      width: '36px',
      height: '36px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      width: 'fit-content',
      fontSize: '24px',
      fontWeight: '600',
      height: '36px',
      gap: '4px',
      '& > b': {
        color: theme.colors.primaryBlue[500],
        fontWeight: '700',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        '&:only-child': {
          margin: '0 auto',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
  },
  categories: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.chip,
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    height: 'fit-content',
    gap: '4px',
  },
  categoryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '256px',
    maxWidth: '100%',
    height: '256px',
    flex: '1 1 350px',
    gap: '4px',
    textTransform: 'unset',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderRadius: '0',
    transition: 'color 0.25s',
    position: 'relative',
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[100],
    },
    '& svg': {
      width: '64px',
      height: '64px',
    },
    '& > p': {
      fontSize: '16px',
      fontWeight: '400',
      color: theme.colors.grey[500],
      width: '50%',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: theme.colors.white,
    '& > span': {
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
  },
  name: {
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
  },
  counter: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '18px',
    '& > p': {
      padding: '4px 8px',
      fontWeight: '500',
      borderRadius: '12px',
      backgroundColor: theme.colors.chip,
    },
  },
  treeView: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.black,
    '& > li': {
      '& > div': {
        borderLeftWidth: '1px',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.colors.black,
        borderRightWidth: '1px',
        borderRightStyle: 'solid',
        borderRightColor: theme.colors.black,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.black,
        position: 'relative',
        '&::before': {
          content: `''`,
          position: 'absolute',
          top: '-1px',
          left: '0',
          width: '100%',
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: theme.colors.black,
        },
      },
      '&:last-child': {
        '& > div': {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.colors.black,
        },
      },
      '&:only-child': {
        '& > div': {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.colors.black,
        },
      },
      '& ul': {
        '& li': {
          '& > div': {
            borderLeftWidth: '1px',
            borderLeftStyle: 'solid',
            borderLeftColor: theme.colors.black,
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: theme.colors.black,
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: theme.colors.black,
            position: 'relative',
            '&::before': {
              content: `''`,
              position: 'absolute',
              top: '-1px',
              left: '0',
              width: '100%',
              borderTopWidth: '1px',
              borderTopStyle: 'solid',
              borderTopColor: theme.colors.black,
            },
          },
        },
      },
    },
  },
}));

const DataManagementSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);

  const categoryList: any = userData.userSettings.map((item: any) => { return { key: item.key, name: t(item.key), value: item.value }; });

  const { viewmode: customViewMode } = useParams();

  const getCustomViewMode = customViewMode ? (categoryList.filter((item: any) => item.key === customViewMode).length === 0 ? null : categoryList.find((item: any) => item.key === customViewMode).key) : null;

  const [viewMode, setViewMode] = useState(getCustomViewMode ? getCustomViewMode : null);

  const transformArray = (inputArray: any) => {
    return inputArray.map((item: any, key: any) => {
      if(item) {
        if(!item.hasOwnProperty('value')) {
          if(typeof item === "object") {
            return {
              key: key.toString(),
              name: key.toString(),
              items: Object.keys(item).map((okey: string) => ({
                key: okey.toString(),
                name: okey.toString(),
                items: transformArray([item[okey]])
              }))
            };
          } else {
            return {
              key: item.toString(),
              name: item.toString(),
            };
          }
        }
        let items: any = [];
        if(item.value === null) {
          items = "null";
        } else if(Array.isArray(item.value)) {
          if (item.value.length > 0) {
            items = transformArray(item.value);
          }
        } else if(typeof item.value === "object" && Object.keys(item.value).length > 0) {
          items = Object.keys(item.value).map((okey: string) => ({
            key: okey.toString(),
            name: okey.toString(),
            items: transformArray([(item.value[okey]).toString()])
          }));
        } else {
          items = ((item.value && item.value.length > 0) || typeof item === "boolean") ? [{ key: key, name: item.value.toString() }] : [];
        }
        const newItem = {
          key: item.key.toString(),
          name: item.key.toString(),
          items: items,
        };
        return newItem;
      } else {
        return null;
      }
    }).filter((item: any) => item !== null);
  };
  
  categoryList.sort((a: any, b: any) => { if (t(a.name) < t(b.name)) { return -1; } if (t(a.name) > t(b.name)) { return 1; } return 0;});
  const currentCategory = viewMode ? (categoryList.filter((item: any) => item.key === viewMode).length > 0 ? categoryList.find((item: any) => item.key === viewMode) : []) : [];
  const currentCategorySize = viewMode ? (categoryList.filter((item: any) => item.key === viewMode).length > 0 ? new Blob([joinValues(categoryList.find((item: any) => item.key === viewMode).value)]).size : 0) : 0;
  const currentCategoryFormatedSize: any = viewMode ? formatFileSize(currentCategorySize, true) : [0, "B"];
  const currentCategoryItems = viewMode ? (categoryList.filter((item: any) => item.key === viewMode).length > 0 ? categoryList.find((item: any) => item.key === viewMode).value : []) : [];
  const currentCategoryTreeItems = transformArray(currentCategoryItems); 

  const handleDelete = async (category: any) => {
    const deleteData = async () => {
      try {
        const result: any = await UserService.deleteUserSetting(category);
        if(result && result.status && result.status === httpResult.NO_CONTENT) {
          try {
            const userSettingsStatus = await loadUserSettings(dispatch, userData);
            if(userSettingsStatus) {
              createNotification(t("data_deleted"),"success");
            } else {
              createNotification(t("data_not_deleted"),"error");
            }
          } catch {
            createNotification(t("data_not_deleted"),"error");
          }
        } else {
          createNotification(t("data_not_deleted"),"error");
        }
      } catch(e: any) {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("data_not_deleted"),"error");
      }
    };
    const settings = {
      isOpen: true,
      title: t('data_delete'),
      content: t('data_delete_confirm'),
      onAccept: deleteData,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleSetupReset = async () => {
    const resetSetup = async () => {
      try {
        const result: any = await UserService.deleteUserSetting("setup");
        if(result && result.status && result.status === httpResult.NO_CONTENT) {
          try {
            const userSettingsStatus = await loadUserSettings(dispatch, userData);
            if(userSettingsStatus) {
              createNotification(t("setup_reseted"),"success");
              dispatch(setIsOpenSetupScreen(true));
            } else {
              createNotification(t("setup_not_reseted"),"error");
            }
          } catch {
            createNotification(t("setup_not_reseted"),"error");
          }
        } else {
          createNotification(t("setup_not_reseted"),"error");
        }
      } catch(e: any) {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("setup_not_reseted"),"error");
      }
    };
    const settings = {
      isOpen: true,
      title: t('setup_reset'),
      content: t('setup_reset_confirm'),
      onAccept: resetSetup,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  useEffect(() => {
    const url = location.pathname.replace("/settings/data-management/", "");
    if(url !== "/settings/data-management") {
      window.history.replaceState({},'',"/settings/data-management");
    }
  }, [location.pathname], []);
  
  return (
    <div className={classes.dataManagementWrapper}>
      <div className={classes.title}>
        {
          viewMode !== null ? (
            <>
              <IconButton onClick={() => setViewMode(null)}>
                <SVG src="arrow-left"/>
              </IconButton>
              <span>{t('data')} <b>{t(currentCategory.name)}</b></span>
            </>
          ) : (
            <span>{t('data_management')}</span>
          )
        }
      </div>
      {
        viewMode === null ? (
          <div className={classes.categories}>
            {
              categoryList.map((item: any, key: any) => {
                if(item.value.length > 0) {
                  return (
                    <Button className={classes.categoryBox} key={`k_${key}`} onClick={() => setViewMode(item.key)}>
                      <SVG src={item.key}/>
                      <span className={classes.name}>{t(item.name)}</span>
                    </Button>
                  );
                } else {
                  return null;
                }
              })
            }
          </div>
        ) : (
          <div className={classes.settingsWrapper}>
            <div className={classes.row}>
              <div className={classes.counter}>
                <span>{t('used_storage')}:</span>
                <p>
                  <CountUp start={0} end={currentCategoryFormatedSize[0]} duration={0.5} suffix={currentCategoryFormatedSize[1]}/>
                </p>
              </div>
              {
                viewMode === "setup" ? (
                  <NormalButton buttonType='clear' onClick={() => handleSetupReset()}>
                    {t('setup_reset')}
                  </NormalButton>
                ) : (
                  <NormalButton buttonType='clear' onClick={() => handleDelete(currentCategory.key)} disabled={parseInt(currentCategoryFormatedSize[0]) === 0}>
                    {t('clear_data')}
                  </NormalButton>
                )
              }
            </div>
            <span>{t('data_viewer')}:</span>
            <TreeView className={classes.treeView} items={currentCategoryTreeItems} disableSelection={true} disabledItemsFocusable={true}/>
          </div>
        )
      }
    </div>
  );
};

export default DataManagementSettings;