import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { setIsDashboardEditable } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { setDashboardHandleWidgetModal } from 'src/store/actions/modals.actions';

const Dashboard: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const layoutData = useAppSelector((state: any) => state.layout);
  const modalsData = useAppSelector((state: any) => state.modals);

  const handleDashboardAddWidget = () => {
    const settings = {
      isOpen: true,
      widgetKey: null,
    };
    dispatch(setDashboardHandleWidgetModal(settings));
  };

  const handleDashboardEditable = () => {
    dispatch(setIsDashboardEditable(!layoutData.isDashboardEditable));
  };

  return (
    <>
      {
        layoutData.isDashboardEditable ? (
          <TopBarButton className={modalsData.dashboardHandleWidgetModal.isOpen ? 'active' : null} icon={<SVG src="plus"/>} onClick={handleDashboardAddWidget} dataCy="topBarButtonAdd" tooltip={t('add_widget')}/>
        ) : null
      }
      <TopBarButton className={layoutData.isDashboardEditable ? 'active' : null} icon={<SVG src="pencil"/>} onClick={handleDashboardEditable} dataCy="topBarButtonEdit" tooltip={t('edit')}/>
      {
        layoutData.isDashboardEditable ? (
          <TopBarButton className={modalsData.dashboardSettingsModal.isOpen ? 'active' : null} icon={<SVG src="cog"/>} onClick={handleDashboardEditable} dataCy="topBarButtonSettings" tooltip={t('settings')}/>
        ) : null
      }
    </>
  );
}

export default Dashboard;