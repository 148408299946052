import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  width: any;
  height: any;
  isStandalone: any;
};

const initialState: ArrayModel = {
  width: window.innerWidth,
  height: window.innerHeight,
  isStandalone: false,
};

const slice = createSlice({
  name: 'browser',
  initialState: initialState,
  reducers: {
    setBrowserWidth(state, action: PayloadAction<Model>){
      state.width = action.payload;
    },
    setBrowserHeight(state, action: PayloadAction<Model>){
      state.height = action.payload;
    },
    setBrowserIsStandalone(state, action: PayloadAction<Model>){
      state.isStandalone = action.payload;
    },
  }
});

export default slice;