//import html2canvas from 'html2canvas';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import Switch from 'src/components/Forms/Switch';
import TimetableTable from 'src/components/Tables/TimetableTable';
import { createUseStyles } from 'react-jss';
import { setTimetableSettings, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import TimetableDetailTable from 'src/components/Tables/TimetableDetailTable';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    gap: '5px',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: theme.shadows[2],
    padding: '13px 12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    height: 'fit-content',
    position: 'relative',
    width: '100%',
    '&:empty': {
      display: 'none',
    },
    '& > p': {
      '& > span': {
        fontWeight: '500',
        whiteSpace: 'nowrap',
        '&:last-of-type': {
          color: '#0085FF',
          fontWeight: '600',
          whiteSpace: 'nowrap',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }, 
  },
  controls: {
    display: 'flex',
    gap: '16px',
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    '& > div': {
      '& > span': {
        whiteSpace: 'nowrap',
      },
    },
  },
}));

const TimetableEdit: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const timetableRef: any = useRef({});

  const [state, setState] = useStates({
    isEdit: false,
  });

  useEffect(() => {
    const settings = {
      breaks: (timetableData.type === "break" || timetableData.type === "employee") ? true : false,
      canAdd: timetableData.type === "employee" ? timetableData.subjects.filter((item: any) => item.type === 2 && item.teacherID.indexOf(timetableData.employee.employeeID) !== -1).length > 0 : true,
      canEdit: state.isEdit,
      canMultipleHours: timetableData.type === "class" ? true : false,
      subjectPopup: !state.isEdit,
    };
    dispatch(setTimetableSettings(settings));
  }, [state.isEdit, timetableData, dispatch], [state.isEdit]);

  const setViewMode = (view: any) => {
    dispatch(setTimetableViewMode(view));
  };

  const handleIsEdit = () => {
    setState("isEdit", !state.isEdit);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  /*
  const takeScreen = () => {
    if(timetableRef.current) {
      html2canvas(timetableRef.current).then((canvas: any) => {
        canvas.toBlob((blob: any) => {
          const url = URL.createObjectURL(blob);
          window.open(url);
        });
      }));
    }
  };
  */

  return timetableData.data !== null ? (
    <div className={classes.wrapper}>
      <div className={classes.infoBlock}>
        {
          timetableData.type === "class" ? (
            <>
              <TimetableDetailTable mode="class"/>
              <div className={classes.controls}>
                {
                  !getClassData(timetableData.class.classID).isArchived ? (
                    <Switch labelLeft={t('timetable_view_mode')} label={t('timetable_edit_mode')} checked={state.isEdit} onChange={handleIsEdit}/>
                  ) : null
                }
                <NormalButton onClick={() => setViewMode('timetableSelect')}>
                  {t('back')}
                </NormalButton>
              </div>
            </>
          ) : null
        }
        {
          timetableData.type === "employee" ? (
            <>
              <TimetableDetailTable mode="employee"/>
              <div className={classes.controls}>
                {
                  !getEmployeeData(timetableData.employee.employeeID).isArchived ? (
                    <Switch labelLeft={t('timetable_view_mode')} label={t('timetable_edit_mode')} checked={state.isEdit} onChange={handleIsEdit}/>
                  ) : null
                }
                <NormalButton onClick={() => setViewMode('timetableSelect')}>
                  {t('back')}
                </NormalButton>
              </div>
            </>
          ) : null
        }
        {
          timetableData.type === "child" ? (
            <>
              <TimetableDetailTable mode="child"/>
              <div className={classes.controls}>
                {
                  !getChildData(timetableData.child.childID).isArchived ? (
                    <Switch labelLeft={t('timetable_view_mode')} label={t('timetable_edit_mode')} checked={state.isEdit} onChange={handleIsEdit}/>
                  ) : null
                }
                <NormalButton onClick={() => setViewMode('timetableSelect')}>
                  {t('back')}
                </NormalButton>
              </div>
            </>
          ) : null
        }
        {
          timetableData.type === "break" ? (
            <>
              <TimetableDetailTable mode="break"/>
              <div className={classes.controls}>
                <Switch labelLeft={t('timetable_view_mode')} label={t('timetable_edit_mode')} checked={state.isEdit} onChange={handleIsEdit}/>
                <NormalButton onClick={() => setViewMode('timetableSelect')}>
                  {t('back')}
                </NormalButton>
              </div>
            </>
          ) : null
        }
        {
          timetableData.type === "room" ? (
            <>
              <TimetableDetailTable mode="room"/>
              <div className={classes.controls}>
                <NormalButton onClick={() => setViewMode('timetableSelect')}>
                  {t('back')}
                </NormalButton>
              </div>
            </>
          ) : null
        }
      </div>
      <TimetableTable customRef={timetableRef}/>
    </div> 
  ) : null;
};

export default TimetableEdit;