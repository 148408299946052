import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import htmlParse from 'html-react-parser';
import React, { useCallback } from 'react';
import RenderChildren from '../RenderChildren';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { isCypress } from 'src/utils/useCypress';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  classItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }, 
    '&:last-of-type': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },   
  },
  classDetail: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '26px 24px',
    alignItems: 'center',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
  },
  classTopBar: {
    display: 'flex',
    alignItems: 'center',
  },
  classBottomBar: {
    display: 'flex',
    alignItems: 'center',
  },
  classInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
  },
  classIcon: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.grey[700],
      marginRight: '8px',
    },
  },
  className: {
    color: theme.colors.grey[700],
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  classCount: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryBlue[500],
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: 'auto',
    marginRight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginRight: '0px',
    },
    '&.search': {
      color: '#BFBFBF',
    },
  },
  classContent: {
    padding: '0',
    backgroundColor: theme.colors.white,
  },
  classCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    '& p': {
      position: 'absolute',
      top: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '10px',
      textTransform: 'uppercase',
      color: theme.colors.primaryBlue[500],
      opacity: '0',
      transition: 'opacity 0.25s',
      paddingRight: '16px',
    },
    '& .checked + p': {
      opacity: '1',
    }
  },
  classCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: "100%",
    marginRight: '16px',
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      '& svg': {
        opacity: '1',
        color: theme.colors.white,
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  classExpander: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    backgroundColor: theme.colors.grey[125],
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.grey[700],
      transition: 'transform 0.25s',
      width: '24px',
      height: '24px',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxWidth: '24px',
      maxHeight: '24px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },    
  },
  classViewCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginLeft: 'auto',
    paddingLeft: '1.5vw',
  },
  childrenView: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    '& svg': {
      width: '18px',
      height: '18px',
      color: theme.colors.primaryBlue[500] + ' !important',
    },
    '& p': {
      margin: "0",
      marginLeft: "5px",
      marginRight: "2px !important",
      color: theme.colors.primaryBlue[500],
    }
  },
  childrenNoView: { 
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& svg': {
      width: '18px',
      height: '18px',
      color: '#A4A7C1 !important',
    },
    '& p': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.grey[500],
    }
  },
  attendanceClass: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    minWidth: '100%',
  },
  attendanceBadge: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.chip,
    fontSize: '16px',
    borderRadius: '6px',
    padding: '4px 18px',
    marginTop: '8px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    '& span + span': {
      marginLeft: '20px',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: 'inherit',
      marginRight: '2px',
    },  
  },
  attendancePresent: {
    color: '#55CEAD',  
  },
  attendanceAbsent: {
    color: '#8690B3',  
  },
  attendanceExcusedAbsent: {
    color: '#9B8AFF',  
  },
  attendanceAbsentPart: {
    color: '#BFC1DC',  
  },
  attendanceAbsentPartAM: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    marginLeft: '-9px',
  },
  attendanceAbsentPartAMPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      color: theme.colors.white,
      marginLeft: '-9px',
    },
  },
  attendanceAbsentPartPM: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      marginLeft: '-9px',
    },
  },
  attendanceAbsentPartPMPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: '9px',
    '& > svg': {
      color: theme.colors.white,
    },
  },
  attendanceRule: {
    color: '#DCDEF8',
    marginLeft: '9.5px',
    marginRight: '9.5px',
    marginBottom: '0px',
  },
  customWidth: {
    minWidth: "10px !important"
  },
}));

type RenderClassType = {
  state: any;
  setState: any;
  schoolData: any;
  classData: any;
};

const RenderClass: React.FunctionComponent<RenderClassType> = ({
  state,
  setState,
  schoolData,
  classData,
}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [classState, setClassState] = useStates({
    isActive: false,
    isContentVisible: false,
  });

  const userData = useAppSelector((state: any) => state.user);
  const schoolID = useMemo(() => schoolData.schoolID, [schoolData.schoolID]);
  const classID = useMemo(() => classData.classID, [classData.classID]);
  const classInfo = useMemo(() => state.functions.getClassData(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const count = useMemo(() => state.functions.getClassesBySchoolID(schoolID).length, [schoolID, state.functions], [schoolID, state.functions, state.isShowArchived, state.search]);

  const schoolSettings = userData.schoolSettings;

  const isSelectedChild = useMemo(() => (searchData: any) => {
    return state.selectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedChildren]);

  const totalChildren = useMemo(() => state.functions.getChildrenByClassID(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);
  const totalSelectedChildren = useMemo(() => state.functions.getChildrenByClassID(classID).filter((item: any) => isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: classID })), [isSelectedChild, classID, state.functions, state.isSelectInAllClass], [isSelectedChild, classID, state.functions, state.isSelectInAllClass, state.isShowArchived, state.search]);
  const totalCount = useMemo(() => (!state.isDisableTotalCount && !state.isSelectAll && !state.isMultipleSelect) ? totalChildren.length : totalSelectedChildren.length + "/" + totalChildren.length, [state.isDisableTotalCount, state.isMultipleSelect, state.isSelectAll, totalChildren.length, totalSelectedChildren.length]);

  const totalPostSeenChildren = useMemo(() => state.functions.getChildrenByClassID(classID).filter((item: any) => state.functions.getChildData(item.childID).postSeen), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);

  const toggleActive = useCallback(() => {
    if(!classState.isActive && classState.isContentVisible) return;
    if(count === 1 || state.search.length > 0) return;
    if(classState.isActive) {
      setClassState("isActive", false);
      setTimeout(() => {
        setClassState("isContentVisible", false);
      }, theme.transitions.duration.complex);
    } else {
      setClassState("isActive", true);
      setClassState("isContentVisible", true);
    }
  }, [classState.isActive, classState.isContentVisible, count, setClassState, state.search.length]);
  
  const handleSelectAll = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(state.validateAbsence &&  state.selectedChildren.length !== 0) {
      const oldChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence
      const newChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(totalChildren[0].childID).schoolID, "modules", schoolSettings).absence;
      if(oldChildAbsenceSettings.allowNotes !== newChildAbsenceSettings.allowNotes || oldChildAbsenceSettings.allowDayParts !== newChildAbsenceSettings.allowDayParts) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    if(state.validateMultipleSchool && state.selectedChildren.length !== 0) {
      const oldChildSchool = state.functions.getChildData(state.selectedChildren[0].childID).schoolID;
      const newChildSchool = state.functions.getChildData(totalChildren[0].childID).schoolID;
      if(oldChildSchool !== newChildSchool) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    let newSelectedChildren = [...state.selectedChildren];
    totalChildren.forEach((child: any) => {
      if(totalChildren.length === totalSelectedChildren.length) {
        newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify(state.isSelectInAllClass ? { childID: child.childID } : { childID: child.childID, classID: classID }));
      } else {
        const newChild = state.isSelectInAllClass ? { childID: child.childID } : { childID: child.childID, classID: classID };
        if(newSelectedChildren.filter((child: any) => JSON.stringify(child) === JSON.stringify(newChild)).length === 0) {
          newSelectedChildren = [...newSelectedChildren, newChild];
        }
      }
    });
    setState("selectedChildren", newSelectedChildren);
  }, [classID, setState, state.functions, state.isSelectInAllClass, state.selectedChildren, state.validateAbsence, state.validateMultipleSchool, state.customCombineMessage, totalChildren, totalSelectedChildren.length, dispatch, t, schoolSettings]);
  
  useEffect(() => {
    if(state.isReload && state.isCloseAllOnReload) {
      setClassState("isActive", false);
      setClassState("isContentVisible", false);
    }
  }, [state.isReload, state.isCloseAllOnReload, setClassState],  [state.isReload, state.isCloseAllOnReload]);

  const classRender = useMemo(() => (
    <Accordion expanded={(count === 1 || state.search.length > 0) ? true : classState.isActive} className={classes.classItem} data-cy={isCypress() ? `class${classID}` : null}>
      <AccordionSummary className={`${classes.classDetail} ${(count === 1 || state.search.length > 0) ? 'nonclickable' : 'clickable'}`} onClick={toggleActive} data-cy={isCypress() ? `class${classID}Toggle` : null}>
        <div className={classes.classTopBar}>
          {
            (state.isSelectAll && state.search === '' && count !== 1) ? (
              <span className={classes.classCheck}>
                <span className={`${classes.classCheckButton} ${totalChildren.length === totalSelectedChildren.length ? 'checked' : '' }`} onClick={handleSelectAll} data-cy={isCypress() ? `class${classID}SelectAll` : null}>
                  <CheckIcon/>
                </span>
                <p>{t("all")}</p>
              </span>
            ) : null
          }
          <span className={classes.classInfo}>
            {
              (classInfo.isArchived || !classInfo.active) ? (
                <Tooltip title={classInfo.isArchived ? t('class_archived') : classInfo.active ? null : t('class_inactive')} classes={{ tooltip: classes.customWidth }} arrow>
                  <span className={classes.classIcon}>
                    {
                      classInfo.isArchived ? (
                        <SVG src="archived"/>
                      ) : classInfo.active ? null : (
                        <SVG src="inactive"/>
                      )
                    }
                  </span>
                </Tooltip>
              ) : null
            }
            <span className={classes.className} data-cy={isCypress() ? 'className' : null} data-clarity-unmask="true">
              {htmlParse(classInfo.name)}
            </span>
            {
              !state.isDisableTotalCount ? (
                <span className={`${classes.classCount} ${state.search.length > 0 ? 'search' : ''}`} data-cy={isCypress() ? 'classCount' : null} data-clarity-unmask="true">
                  ({totalCount})
                </span>
              ) : null
            }
            {
              state.isShowChildrenViews ? (
                <div className={classes.classViewCount}>
                  <span className={classes.childrenView}>
                    <SVG src="eye"/>
                    <p data-cy={isCypress() ? 'childrenView' : null} data-clarity-unmask="true">
                      {totalPostSeenChildren.length}
                    </p>
                  </span>
                  <span className={classes.childrenNoView}>
                    <SVG src="eye-slash"/>
                    <p data-cy={isCypress() ? 'childrenNoView' : null} data-clarity-unmask="true">
                      {totalChildren.length - totalPostSeenChildren.length}
                    </p>
                  </span>  
                </div>
              ) : null
            }
          </span>
          {
            (count === 1 || state.search.length > 0) ? null : (
              <span className={`${classes.classExpander} ${classState.isActive ? 'active' : ''}`} data-cy={isCypress() ? 'classExpander' : null}>
                <SVG src="chevron-down"/>
              </span>
            )
          }
        </div>
        <div className={classes.classBottomBar}>
          {
            (state.isShowAttendanceCount && classInfo.presentCount !== undefined && classInfo.missingCount !== undefined && classInfo.expectedMorningCount !== undefined && classInfo.expectedAfternoonCount !== undefined) ? (
              <div className={classes.attendanceClass}>
                <div className={classes.attendanceBadge}>
                  <span className={classes.attendancePresent} data-cy={isCypress() ? 'attendancePresent' : null} data-clarity-unmask="true">
                    <SVG src="attendance-person-present"/>
                    {totalSelectedChildren.length}
                  </span>
                  <span className={classes.attendanceAbsent} data-cy={isCypress() ? 'attendanceAbsent' : null} data-clarity-unmask="true">
                    <SVG src="attendance-person-missing"/>
                    {totalChildren.length - totalSelectedChildren.length}
                  </span>
                </div>
                <div className={classes.attendanceBadge}>
                  <span className={classes.attendanceAbsentPart} data-cy={isCypress() ? 'attendanceMorning' : null} data-clarity-unmask="true">
                    <div className={classes.attendanceAbsentPartAM}>
                      <CircleIcon/>
                    </div>
                    <div className={classes.attendanceAbsentPartAMPlaceholder}>
                      <CircleIcon/>
                    </div>
                    <SVG src="attendance-person-present"/>
                    {classInfo.expectedMorningCount}
                  </span>
                  <p className={classes.attendanceRule}>|</p>
                  <span className={classes.attendanceAbsentPart} data-cy={isCypress() ? 'attendanceAfternoon' : null} data-clarity-unmask="true">
                    <div className={classes.attendanceAbsentPartPMPlaceholder}>
                      <CircleIcon/>
                    </div>
                    <div className={classes.attendanceAbsentPartPM}>
                      <CircleIcon/>
                    </div>
                    <SVG src="attendance-person-present"/>
                    {classInfo.expectedAfternoonCount}
                  </span>
                </div>
              </div>
            ) : null
          }
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.classContent} data-cy={isCypress() ? `class${classID}Children` : null}>
        {
          (count === 1 || state.search.length > 0) || classState.isContentVisible ? (
            <RenderChildren state={state} setState={setState} schoolData={schoolData} classData={classData}/>
          ) : null
        }
      </AccordionDetails>
    </Accordion>
  ), [classData, classID, classInfo.active, classInfo.expectedAfternoonCount, classInfo.expectedMorningCount, classInfo.isArchived, classInfo.missingCount, classInfo.name, classInfo.presentCount, classState.isActive, classState.isContentVisible, classes.attendanceAbsent, classes.attendanceAbsentPart, classes.attendanceAbsentPartAM, classes.attendanceAbsentPartAMPlaceholder, classes.attendanceAbsentPartPM, classes.attendanceAbsentPartPMPlaceholder, classes.attendanceBadge, classes.attendanceClass, classes.attendancePresent, classes.attendanceRule, classes.childrenNoView, classes.childrenView, classes.classBottomBar, classes.classCheck, classes.classCheckButton, classes.classContent, classes.classCount, classes.classDetail, classes.classExpander, classes.classIcon, classes.classInfo, classes.classItem, classes.className, classes.classTopBar, classes.classViewCount, classes.customWidth, count, handleSelectAll, schoolData, setState, state, t, toggleActive, totalChildren.length, totalCount, totalPostSeenChildren.length, totalSelectedChildren.length]); 

  return classRender;
}

export default RenderClass;