import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import DropdownWithMenu from '../../Buttons/DropdownWithMenu';
import hexToRgba from 'hex-to-rgba';
import Hotkeys from 'react-hot-keys';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NavigationButton from '../../Buttons/NavigationButton';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import ReactionsList from 'src/components/Reactions/List';
import ReactionsPopper from 'src/components/Reactions/Popper';
import RenderAttachment from './RenderAttachment';
import RenderPhoto from './RenderPhoto';
import RenderVideo from './RenderVideo';
import SVG from '../../Images/SvgRenderer';
import { arrayBufferToBase64, base64toBlob, formatFileSize, getSchoolSettings, handleSum } from 'src/utils/useFunctions';
import { Button } from '@mui/material';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { isCypress } from '../../../utils/useCypress';
import { saveAs } from 'file-saver';
import { setChildCardModal, setGalleryModal, setPostDetailModal } from '../../../store/actions/modals.actions';
import { setFilterParams } from '../../../store/actions/filters.actions';
import { setGalleryMedias } from '../../../store/actions/gallery.actions';
import { setIsFiltersVisible } from '../../../store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  activityTypeBackgroundColorParam?: any;
  activityTypeColorParam?: any;
  isPresentation?: any;
  browserHeight?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "0px",
    backgroundColor: theme.colors.alwaysBlack,
    overflow: "auto",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: (props: Props) => props.browserHeight + 'px',
    maxHeight: (props: Props) => props.browserHeight + 'px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '12px',
    flex: '0 0 auto',
  },
  previewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    "& > button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0px",
      outline: "none",
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: theme.colors.grey[400],
      minWidth: "48px",
      minHeight: "48px",
      width: "48px",
      height: "48px",
      maxWidth: "48px",
      maxHeight: "48px",
      padding: '0',
      fontSize: "20px",
      cursor: "pointer",
      opacity: "1",
      transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
      zIndex: '3',
      transform: 'translateY(-50%)',
      '& svg': {
        width: '24px',
        height: '24px',
      },
      "&:first-of-type": {
        position: "absolute",
        top: "50%",
        left: "0px",
        borderRadius: "0px 100% 100% 0px",
      },
      "&:last-of-type": {
        position: "absolute",
        top: "50%",
        right: "0px",
        borderRadius: "100% 0px 0px 100%",
      },
      "&:hover": {
        backgroundColor: 'rgba(110, 113, 145, 0.9)',
        color: theme.colors.grey[400],
      },
      "&:disabled": {
        opacity: "0",
        pointerEvents: "none",
      },
    },
  },
  preview: {
    width: 'calc(100% - 128px)',
    maxWidth: 'calc(100% - 128px)',
    height: (props: Props) => {
      if(props.isPresentation) return '100vh';
      else return 'calc(100vh - 160px)';
    },
    margin: '16px 0',
    overflow: 'hidden',
    position: 'relative',
    flex: '1 0 calc(70% - 128px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  button: {
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
    '&.Mui-disabled': {
      '& svg': {
        filter: 'grayscale(100%)',
      }
    },
  },
  sidebar: {
    backgroundColor: theme.colors.white,
    flex: '0 0 30%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 32px)',
    maxHeight: 'calc(100% - 32px)',
    width: 'calc(100% - 32px)',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      maxHeight: 'unset',
    },
  },
  authorInformation: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    '& .square img': {
      width: '100%',
      height: '100%',
    },
    '& .width img': {
      height: '100%',
    },
    '& .height img': {
      width: '100%',
    },
  },
  authorInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60px',
  },
  authorImageContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      minHeight: '40px',
      width: '40px',
      height: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },
  authorImage: {
    width: '48px',
    height: '48px',
    borderRadius: '12px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
      height: '38px',
    },
  },
  authorName: {
    color: theme.colors.grey[800],
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.25s',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    '&:hover': {
      color: theme.colors.primaryBlue[500],
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  infobox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "0.5rem",
  },
  childrenbox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "0.5rem",
    overflow: 'auto',
    flex: '1 1 auto',
  },
  moreWrapper: {
    position: 'relative',
    marginLeft: 'auto',
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '15px',
    paddingBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    '& > svg': {
      width: '17px',
      height: '17px',
      marginRight: '4px',
    }
  },
  subTitle: {
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  mediaTypeBadge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '6px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    cursor: 'pointer',
    backgroundColor: '#EEEEEE',
    '& img': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    '& svg': {
      width: '12px',
      height: '12px',
      marginRight: '5px',
    },
  },
  originalBadge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '2px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: '#EEEEEE',
  },
  activityType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px',
    width: 'fit-content',
    backgroundColor: (props: Props) => props.activityTypeBackgroundColorParam,
    color: (props: Props) => props.activityTypeColorParam,
    fontSize: '10px',
    cursor: 'pointer',
    '& > img': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  childrenListWrapper: {
    position: 'relative',
    width: "auto",
    marginLeft: '0',
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5rem',
    overflow: 'auto',
  },
  child: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    fontSize: '14px',
    cursor: "pointer",
    maxWidth: 'calc(20% - 0.5rem)',
    width: '15%',
    padding: '8px',
    borderRadius: '24px',
    transition: 'all 0.25s',
    height: '92px',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(20% - 0.5rem)',
      width: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(25% - 0.5rem)',
      width: '25%',
    },
  },
  childPhoto: {
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    backgroundColor: theme.colors.white,
    position: 'relative',
  },
  childDisplayName: {
    maxWidth: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '6px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
  hideButton: {
    width: '42px',
    height: '42px',
    minWidth: 'unset',
    borderRadius: '100%',
    backgroundColor: '#EEEEEE',
    color: theme.colors.black,
  },
  starButton: {
    width: '42px',
    height: '42px',
    minWidth: 'unset',
    borderRadius: '100%',
    backgroundColor: '#EEEEEE',
    color: theme.colors.black,
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&.active': {
      '& svg': {
        color: theme.colors.yellow[500], 
      },
    },
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
    '& > *': {
      flex: '1 1 calc(0.25rem)',
      '& > span': {
        [theme.breakpoints.down('sm')]: {
          zoom: '85%',
        },
      },
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customWidth: {
    minWidth: "10px !important"
  },
  dropDownMenuWrapper: {
    width: '100%',
  },
  dropDownMenu: {
    position: "absolute",
    right: "0",
    top: '0',
    width: 'fit-content',
    marginTop: '0.25rem',
    minWidth: '200px',
    maxWidth: '90vw',
    overflowY: "hidden",
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    zIndex: "9999",
    flexWrap: "wrap",
    transition: '0.5s',
    fontSize: "0.8em",
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '12px 8px',
      cursor: 'pointer',
      transition: 'all 0.25s',
      whiteSpace: 'nowrap',
      '& > svg': {
        marginRight: 'auto',
        width: '20px',
        height: '20px',
      },
      '& > p': {
        paddingLeft: '0.5rem',
        marginBottom: '0',
      },
      '&:hover': {
        backgroundColor: '#F1F1F1',
      },
    },
  },
  presentationBarWrapper: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: '0',
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '5',
    transition: 'all 0.25s',
    '&.active': {
      opacity: '1',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px'
    },
  },
  presentationBar: {
    width: '400px',
    backgroundColor: theme.colors.white,
    padding: '0.5rem 1rem',
    borderRadius: '24px',
    display: 'flex',
    boxShadow: theme.shadows[2],
    '& > button': {
      width: '40px',
      height: '40px',
    },
  },
  presentationBarInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 100%',
  },
  closeButton: {
    position: 'fixed',
    top: '0.5rem',
    left: '0.5rem',
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
  },
  mediaInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginLeft: '8px',
    padding: '0',
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '13px',
      '& > span': {
        color: theme.colors.grey[700],
        fontWeight: '600',
        whiteSpace: 'nowrap',
      },
      '& > p': {
        fontSize: '11px',
      },
    },
  },
  toolbar: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '8px',
    display: 'flex',
    gap: '8px',
    '& > button': {
      color: theme.colors.white,
      transition: 'background-color 0.25s',
      backgroundColor: 'rgba(255,255,255,0)',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
      '&:disabled': {
        color: '#4C4C4C',
      },
    },
  },
  reactionsWrapper: {
    position: 'relative',
    minWidth: '120px',
    '& > button': {
      width: '100%',
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
}));

const GalleryModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const mediasData = useAppSelector((state: any) => state.gallery).medias;
  const modalsData = useAppSelector((state: any) => state.modals);
  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  const configurationData = useAppSelector((state: any) => state.configuration);
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const mediaID = modalsData.galleryModal.mediaID;
  const date = modalsData.galleryModal.date;

  const [state, setState] = useStates({
    isDownloadable: false,
    isLoaded: false,
    isFullscreen: false,
    isPresentation: false,
    isPresentationActive: false,
    isPresentationBarActive: false,
    isOriginalSize: false,
    isFirst: false,
    isLast: false,
    lastLocation: "",
    metadata: {},
    canClick: true,
    presentationTimeout: null,
    zoomLevel: (1).toFixed(2),
  });

  const allMediasData = state.isPresentation ? mediasData.map((dates: any) => { return dates.medias; }).flat().filter((medias: any) => medias.type === "photo") : mediasData.map((dates: any) => { return dates.medias; }).flat();
  const mediaData: any = allMediasData.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : allMediasData.find((media: any) => media.mediaID === mediaID);
  const mediaIndex: any = allMediasData.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : allMediasData.findIndex((media: any) => media.mediaID === mediaID);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authorData = useMemo(() => mediaData ? (dataData.users.filter((user: any) => user.userID === mediaData.post.authorID).length === 0 ? dataData.users.find((user: any) => user.userID === 0) : dataData.users.find((user: any) => user.userID === mediaData.post.authorID)) : dataData.users.find((user: any) => user.userID === 0), [dataData.users, mediaData]);
  const activityTypes = useMemo(() => configurationData.configuration.activityTypes, [configurationData.configuration.activityTypes]);
  const activityType = useMemo(() => mediaData ? (activityTypes.filter((activity: any) => activity.activityTypeID === mediaData.post.activityTypeID).length === 0 ? null : activityTypes.find((activity: any) => activity.activityTypeID === mediaData.post.activityTypeID)) : null, [activityTypes, mediaData]);
  const activityTypeBackgroundColor = useMemo(() => activityType ? hexToRgba(activityType.color, '0.2') : '', [activityType]);
  const activityTypeColor = useMemo(() => activityType ? activityType.color : '', [activityType]);
  const children = useMemo(() => mediaData ? dataData.children.filter((child: any) => Object.values(mediaData.post.children).indexOf(child.childID) > -1) : [], [dataData.children, mediaData]);
  const schoolID = mediaData ? mediaData.schoolID : 0; 
  const schoolSettings = userData.schoolSettings;
  const schoolReactions = useMemo(() => schoolID === 0 ? false : (getSchoolSettings(schoolID, 'modules', schoolSettings) === null ? false : getSchoolSettings(schoolID, 'modules', schoolSettings).reaction ? (getSchoolSettings(schoolID, 'modules', schoolSettings).reaction.enable && getSchoolSettings(schoolID, 'modules', schoolSettings).reaction.enableFor.medias) : false), [schoolID, schoolSettings]);

  const classes = useStyles({
    activityTypeBackgroundColorParam: activityTypeBackgroundColor,
    activityTypeColorParam: activityTypeColor,
    isPresentation: state.isPresentation,
    browserHeight: browserData.height,
  });

  const isFavorite = mediaData === null ? null : mediaData.attributes.find((attribute: any) => attribute.name === "favorite").value;
  const isHide = mediaData === null ? null : mediaData.attributes.find((attribute: any) => attribute.name === "hide").value;
  
  const onClose = useCallback(()  => {
    const settings = {
      isOpen: false,
      date: null,
      mediaID: null,
    };
    dispatch(setGalleryModal(settings));   
  }, [dispatch]);
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };  
    
  const handleDownload = () => {
    if(state.isDownloadable) {
      const fileType = (mediaData.name).split('.').pop();
      galleryService && galleryService.downloadFile(mediaID).then((result: any) => {
        if(result) {
          if(result.data) {
            if(mediaData.type === "photo") {
              const base64 = arrayBufferToBase64(result.data);
              const blob = base64toBlob(base64, "image/" + fileType);
              saveAs(blob,  mediaData.name);
            } else if(mediaData.type === "video") {
              const base64 = arrayBufferToBase64(result.data)
              const blob = base64toBlob(base64, "video/" + fileType);
              saveAs(blob, mediaData.name);
            } else {
              const base64 = arrayBufferToBase64(result.data)
              const contentType = "application/octet-stream";
              const blob = base64toBlob(base64, contentType);
              saveAs(blob, mediaData.name);
            }
          } else {
            createNotification(t('something_went_wrong'), 'error');
          }
        } else {
          createNotification(t('something_went_wrong'), 'error');
        }
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    }
  };
  
  const updateAttribute = (attr: any, value: any) => {
    const newItems = mediasData.map((dates: any) => {
      if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
        return {...dates, medias: dates.medias.map((media: any) => {
          if(media.mediaID === mediaID) {
            return {...media, attributes: media.attributes.map((attribute: any) => {
              if(attribute.name === attr) {
                return {...attribute, value: value};
              } else {
                return attribute;
              }
            })};
          } else {
            return media;
          }
        })};
      } else {
        return dates;
      }
    });
    dispatch(setGalleryMedias(newItems));
  };

  const handleFavoriteButton = (e: any) => {
    const newValue = !isFavorite;
    updateAttribute("favorite", newValue);
    const payload = {
      attributes: [
        {
          name: 'favorite',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(mediaID, payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute("favorite", !newValue);
    });
  };

  const handleHideButton = () => {
    const newValue = !isHide;
    updateAttribute("hide", newValue);
    const payload = {
      attributes: [
        {
          name: 'hide',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(mediaID, payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute("hide", !newValue);
    });
  };

  const handleViewPost = (e: any) => {
    e.stopPropagation();
    const settings = {
      isOpen: true,
      postID: mediaData.post.postID,
    };
    dispatch(setPostDetailModal(settings)); 
  };

  const handleFullscreen = useFullScreenHandle();

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose], []);

  useEffect(() => {
    if(state.lastLocation !== "") {
      onClose();
    } else {
      setState("lastLocation", location.pathname);
    }
  }, [location, onClose, setState, state.lastLocation], [location]);
  
  useEffect(() => {
    setState("isLoaded", true);
    if(mediaIndex !== null) {
      if(mediaIndex === 0) {
        setState("isFirst", true); 
      } else {
        setState("isFirst", false);
      }
      if(mediaIndex === allMediasData.length - 1) {
        setState("isLast", true); 
      } else {
        setState("isLast", false);
      }
    }
  }, [mediaID, mediaIndex, setState, allMediasData], [mediaID]);
  
  const handleSecureClick = () => {
    setState("canClick", true);
  };

  const handleChildrenClick = (child: any) => {  
    const settings = {
      isOpen: true,
      childID: child.childID,
    };
    dispatch(setChildCardModal(settings));
  }; 
  
  const keyPress = (keyName: any, e: any, handle: any) => {
    if(keyName === "left") {
      if(!state.isPresentation) {
        prevMedia();
      }
    } else if(keyName === "right") {
      if(!state.isPresentation) {
        nextMedia();
      }
    } else if(keyName === "space") {
      if(state.isPresentation) {
        handlePresentationActive(e);
      }
    }
  };

  const prevMedia = () => {
    if(state.canClick && mediaIndex !== 0) {
      setState("canClick", false);
      const newMediaID = allMediasData[mediaIndex - 1].mediaID;
      dispatch(setGalleryModal({mediaID: newMediaID}));
    }
  };
  
  const nextMedia = () => {
    if(state.canClick && mediaIndex !== allMediasData.length - 1) {
      setState("canClick", false);
      const newMediaID = allMediasData[mediaIndex + 1].mediaID;
      dispatch(setGalleryModal({mediaID: newMediaID}));
    }
  };

  const setMedia = (mediaID: any) => {
    dispatch(setGalleryModal({mediaID: mediaID}));
  }; 

  const handleAuthorNameClick = () => {
    navigate('/timeline');
    dispatch(setFilterParams({authorID: [authorData.userID]}));
    setTimeout(() => {
      dispatch(setFilterParams({authorID: [authorData.userID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleMediaTypeClick = () => {
    onClose();
    dispatch(setFilterParams({galleryTypes: [mediaData.type]}));
    setTimeout(() => {
      dispatch(setFilterParams({galleryTypes: [mediaData.type]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleActivityTypeClick = () => {
    onClose();
    dispatch(setFilterParams({activityTypeID: [activityType.activityTypeID]}));
    setTimeout(() => {
      dispatch(setFilterParams({activityTypeID: [activityType.activityTypeID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleIsDownloadAble = (value: any) => {
    setState("isDownloadable", value);
  };

  const handleMetadata = (value: any) => {
    setState("metadata", value);
  };

  const handleOriginalSize = () => {
    setState("isOriginalSize", !state.isOriginalSize);
  };

  const handlePresentation = (mIndex: any) => {
    const nextMediaID = allMediasData[mIndex !== allMediasData.length - 1 ? mIndex + 1 : 0].mediaID;
    setMedia(nextMediaID);
  };

  const handleOnLoadPresentation = () => {
    if(state.isPresentationActive) {
      const tempPresentationTimeout = setTimeout(function() {
        handlePresentation(mediaIndex);
      }, 2500);
      setState("presentationTimeout", tempPresentationTimeout);
    }
  };

  const handlePresentationActive = (e: any) => {
    e.stopPropagation();
    if(!state.isPresentationActive) {
      const tempPresentationTimeout = setTimeout(function() {
        handlePresentation(mediaIndex);
      }, 2500);
      setState("presentationTimeout", tempPresentationTimeout);
    } else {
      clearTimeout(state.presentationTimeout);
      setState("presentationTimeout", null);
    }
    setState("isPresentationActive", !state.isPresentationActive); 
  };


  const handlePresentationMode = useCallback(() => {
    if(!state.isPresentation) {
      setState("isPresentationBarActive", true); 
      setState("isOriginalSize", false);   
      handleFullscreen.enter();
    } else {
      clearTimeout(state.presentationTimeout);
      setState("presentationTimeout", null);
      setState("isPresentationActive", false);    
      handleFullscreen.exit();
    }
    setState("isPresentation", !state.isPresentation);
  }, [handleFullscreen, setState, state.isPresentation, state.presentationTimeout]);

  const handlePresentationBarActiveToggle = () => {
    setState("isPresentationBarActive", !state.isPresentationBarActive);
  };

  const handlePresentationBarActive = () => {
    setState("isPresentationBarActive", true);
  };

  const handlePresentationBarInactive = () => {
    setState("isPresentationBarActive", false);
  };

  const handleZoomPlus = () => {
    if(state.zoomLevel < 2) {
      setState("zoomLevel", (parseFloat(state.zoomLevel) + 0.2).toFixed(2));
    }
  };

  const handleZoomMinus = () => {
    if(state.zoomLevel > 1) {
      setState("zoomLevel", (parseFloat(state.zoomLevel) - 0.2).toFixed(2));
    }
  };

  const handleSideFullscreen = () => {
    setState("isFullscreen", !state.isFullscreen);
  };

  const dropdownMenuItems = [
    {
      title: isHide ? t('show_in_gallery') : t('hide_in_gallery'),
      icon: (<SVG src={isHide ? "eye" : "eye-slash"}/>),
      onClick: handleHideButton,
      dataCy: 'galleryModalHideButton',
    },
    {
      title: t('download'),
      icon: (<SVG src="download"/>),
      onClick: handleDownload,
      dataCy: 'galleryModalDownloadButton',
    },
    (mediaData && mediaData.type === "photo") ? {
      title: t('toggle_original'),
      icon: (<SVG src="original-size"/>),
      onClick: handleOriginalSize,
      dataCy: 'galleryModalOriginalButton',
    } : null,
    (!state.isPresentation && mediaData && mediaData.type === "photo") ? {
      title: t('presentation_mode'),
      icon: (<SVG src="play"/>),
      onClick: handlePresentationMode,
      dataCy: 'galleryModalPresentationButton',
    } : null,
  ].filter((item: any) => item !== null);

  useEffect(() => {
    if(state.isPresentation && !handleFullscreen.active) {
      handlePresentationMode();
    };
  }, [handleFullscreen.active, handlePresentationMode, state.isPresentation], [handleFullscreen.active]);

  return mediaData === null ? null : (
    <Modal
      open={true} 
      onClose={onCloseModal}
      disableEscapeKeyDown={state.isPresentation}
    >
      <div className={classes.root} data-cy={isCypress() ? "galleryModal" : null}>
        <FullScreen className={classes.root} handle={handleFullscreen}>
          {
            !state.isPresentation ? (
              <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
            ) : null
          }
          <Hotkeys 
            keyName="left,right,space" 
            onKeyDown={keyPress}
          >
            {
              state.isLoaded ? (
                <div className={classes.previewWrapper}>
                  {
                    !state.isPresentation ? (
                      <div className={classes.toolbar}>
                        {
                           mediaData?.type === "photo" ? (
                            <>
                            <IconButton disabled={state.zoomLevel >= (2).toFixed(2)} onClick={handleZoomPlus} data-cy={isCypress() ? 'galleryModalZoomPlusButton' : null}>
                              <SVG src="zoom-plus"/>
                            </IconButton>
                            <IconButton disabled={state.zoomLevel === (1).toFixed(2)} onClick={handleZoomMinus} data-cy={isCypress() ? 'galleryModalZoomMinusButton' : null}>
                              <SVG src="zoom-minus"/>
                            </IconButton>
                            </>
                          ) : null
                        }
                        <IconButton onClick={handleSideFullscreen} data-cy={isCypress() ? 'galleryModalFullscreenButton' : null}>
                          {
                            !state.isFullscreen ? (
                              <SVG src="fullscreen-enter"/>
                            ) : (
                              <SVG src="fullscreen-exit"/>
                            )
                          }
                        </IconButton>
                      </div>
                    ) : null
                  }
                  <div className={classes.preview}>
                    {
                      mediaData?.type === "photo" ? (
                        <RenderPhoto mediaData={mediaData} isOriginalSize={state.isOriginalSize} zoomLevel={state.zoomLevel} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata} onLoad={handleOnLoadPresentation}/>
                      ) : mediaData?.type === "video" ? (
                        <RenderVideo mediaData={mediaData} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata}/>
                      ) : mediaData?.type === "attachment" ? (
                        <RenderAttachment mediaData={mediaData} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata}/>
                      ) : (
                        <div>error</div>
                      )
                    }
                  </div>
                  {
                    state.isPresentation && (
                      <div className={`${classes.presentationBarWrapper} ${state.isPresentationBarActive && 'active'}`} onClick={handlePresentationBarActiveToggle} onMouseEnter={handlePresentationBarActive} onMouseLeave={handlePresentationBarInactive}>
                        <div className={classes.presentationBar}>
                          <IconButton onClick={handlePresentationActive} data-cy={isCypress() ? "galleryModalPresentationToggleButton" : null}>
                            <SVG src={state.isPresentationActive ? "pause" : "play"}/>
                          </IconButton>
                          <div className={classes.presentationBarInfo} data-cy={isCypress() ? "galleryModalPresentationSlides" : null}>
                            {mediaIndex + 1} / {allMediasData.length}
                          </div>
                          <IconButton onClick={handlePresentationMode} data-cy={isCypress() ? "galleryModalPresentationExitButton" : null}>
                            <SVG src="close"/>
                          </IconButton>
                        </div>
                      </div>
                    )
                  }
                  {
                    (!state.isPresentation && allMediasData.length > 1) ? (
                      <NavigationButton icon={<SVG src="chevron-left"/>} onClick={prevMedia} disabled={state.isFirst} dataCy="galleryModalPrevButton"/>
                    ) : null
                  }
                  {
                    (!state.isPresentation && allMediasData.length > 1) ? (
                      <NavigationButton icon={<SVG src="chevron-right"/>} onClick={nextMedia} disabled={state.isLast} dataCy="galleryModalNextButton"/>
                    ) : null
                  }
                </div>
              ) : (
                <div className={classes.preview}>...</div>
              )
            }
            {
              (!state.isFullscreen && !state.isPresentation) ? (
                <div className={classes.sidebar}>
                  <div className={classes.authorInformation}> 
                    <div className={classes.authorImageContainer}>
                      <AuthenticatedImage className={classes.authorImage} thumbLink={authorData.photo.thumbLink} fullsizeLink={authorData.photo.fullsizeLink} isClickable/>
                    </div>
                    <div className={classes.authorInfo}>
                      <span className={classes.authorName} onClick={handleAuthorNameClick} data-cy={isCypress() ? "galleryModalAuthorButton" : null}>
                        {authorData.displayName}
                      </span>
                      <span className={classes.date} data-cy={isCypress() ? 'galleryModalDateCreated' : null}>
                        <SVG src="clock"/>
                        {DateFormat(mediaData.post.created,"timeline",languageData, t)}
                      </span>
                    </div>
                    <div className={classes.moreWrapper}>
                      {
                        !filtersData.filterParams.galleryHide ? (
                          <Button className={`${classes.starButton} ${isFavorite ? 'active' : null}`} type="button" onClick={handleFavoriteButton} data-cy={isCypress() ? "galleryModalFavoriteButton" : null}>
                            <SVG src={isFavorite ? "star-filled" : "star"}/>
                          </Button>
                        ) : null
                      }
                      <DropdownWithMenu items={dropdownMenuItems} dataCy='galleryModalMoreButton'/>
                    </div>
                  </div>
                  <div className={classes.infobox}>
                    <span className={classes.title}>
                      <SVG src="info-circle-outlined"/>
                      {t('media_info')}:
                    </span>          
                    <ul className={classes.mediaInfo}>
                      <li>
                        <span>{t('media_name')}:</span>
                        <p>{mediaData.name}</p>
                      </li>
                      {
                        mediaData.type === "photo" ? (
                          <>
                            <li>
                              <span>{t('media_type')}:</span>
                              <p className={classes.mediaTypeBadge} onClick={handleMediaTypeClick} data-cy={isCypress() ? "galleryModalMediaTypeButton" : null}>
                                <SVG src="image"/> {t("photo")}
                              </p>
                            </li>
                            {
                              Object.keys(state.metadata).length > 0 ? (
                                <>
                                  <li>
                                    <span>{t('media_resolution')}:</span>
                                    <p>{state.metadata.width}x{state.metadata.height}px {state.isOriginalSize ? (<span className={classes.originalBadge}>{t("original")}</span>) : null}</p>
                                  </li>
                                  <li>
                                    <span>{t('media_size')}:</span>
                                    <p>{formatFileSize(state.metadata.size)}</p>
                                  </li>
                                </>
                              ) : null
                            }
                            
                          </>
                        ) : null
                      }
                      {
                        mediaData.type === "video" ? (
                          <>
                            <li>
                              <span>{t('media_type')}:</span>
                              <p className={classes.mediaTypeBadge} onClick={handleMediaTypeClick} data-cy={isCypress() ? "galleryModalMediaTypeButton" : null}>
                                <SVG src="video"/> {t("video")}
                              </p>
                            </li>
                            {
                              Object.keys(state.metadata).length > 0 ? (
                                <>
                                  <li>
                                    <span>{t('media_duration')}:</span>
                                    <p>{DateFormat(state.metadata.duration * 1000, "minutesseconds", languageData, t)}</p>
                                  </li>
                                  <li>
                                    <span>{t('media_size')}:</span>
                                    <p>{formatFileSize(state.metadata.size)}</p>
                                  </li>
                                </>
                              ) : null
                            }
                            
                          </>
                        ) : null
                      }
                      {
                        mediaData.type === "attachment" ? (
                          <>
                            <li>
                              <span>{t('media_type')}:</span>
                              <p className={classes.mediaTypeBadge} onClick={handleMediaTypeClick} data-cy={isCypress() ? "galleryModalMediaTypeButton" : null}>
                                <SVG src="file"/> {t("file")}
                              </p>
                            </li>
                            {
                              Object.keys(state.metadata).length > 0 ? (
                                <>
                                  <li>
                                    <span>{t('media_size')}:</span>
                                    <p>{formatFileSize(state.metadata.size)}</p>
                                  </li>
                                </>
                              ) : null
                            }
                          </>
                        ) : null
                      }
                    </ul>
                  </div>
                  <div className={classes.infobox}>
                    <span className={classes.title}>
                      <SVG src="activity-type"/>
                      {t('activity_type')}:
                    </span>          
                    {
                      activityType ? (
                        <div className={classes.activityType} onClick={handleActivityTypeClick} data-cy={isCypress() ? "galleryModalActivityTypeButton" : null}>
                          <img src={activityType.image} alt={activityType.name}/>
                          {activityType.name}
                        </div>
                      ) : null
                    }
                  </div>
                  <div className={classes.childrenbox}>
                    <span className={classes.title}>
                      <SVG src="people"/>
                      {t('children')}:
                    </span>    
                    {
                      children.length > 0 && (
                        <div className={classes.childrenListWrapper}>
                          {
                            children.map((child: any, key: any) => (
                              <div className={classes.child} key={`k_${key}`} onClick={() => handleChildrenClick(child)} data-cy={isCypress() ? "galleryModalChildButton" + key : null}>
                                <AuthenticatedImage className={classes.childPhoto} thumbLink={child.photo && child.photo.thumbLink}/>
                                <Clamp withTooltip lines={2}>
                                  <span className={classes.childDisplayName}>{child.displayName}</span>
                                </Clamp> 
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                  <div className={classes.bottom}>   
                    {
                      ((mediaData.reactions && mediaData.reactions.enable && mediaData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (mediaData.reactions && mediaData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0)) ? (
                        <ReactionsList data={mediaData} ID={mediaID} type='media'/>
                      ) : null
                    } 
                    <div className={classes.buttons}>
                      {
                        (mediaData.reactions && mediaData.reactions.enable && schoolReactions) ? (
                          <ReactionsPopper className={classes.reactionsWrapper} data={mediaData} ID={mediaID} date={date} type='media'/>
                        ) : null
                      }
                      <NormalButton buttonType='chip' startIcon={<SVG src="download"/>} onClick={handleDownload} dataCy="galleryModalDownloadMediaButton">
                        {t("download")}
                      </NormalButton>
                      <NormalButton buttonType='chip' startIcon={<SVG src="post"/>} onClick={handleViewPost} dataCy="galleryModalViewPostButton">
                        {t("view_post")}
                      </NormalButton>
                    </div>   
                  </div>  
                </div>
              ) : null
            }
          </Hotkeys>
        </FullScreen>
      </div>
    </Modal>
  );
};

export default GalleryModal;