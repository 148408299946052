import React from 'react';
import { Navigate as NavigateRouter, useLocation } from 'react-router';
import { useAppSelector } from 'src/hooks/redux-hooks';

type NavigateType = {
  to: any,
  isLoaded?: boolean;
};

const Navigate: React.FunctionComponent<NavigateType> = ({ to, isLoaded }) => {
  
  const location = useLocation();
  const loadingData = useAppSelector((state: any) => state.loading);

  const isAllLoaded = loadingData.isLanguageLoaded && loadingData.isUserLoaded;

  const isNavigate = isLoaded ? isAllLoaded : true;

  return isNavigate ? (
    <NavigateRouter to={to} state={{ from: location }}/>
  ) : null;
}

export default Navigate;