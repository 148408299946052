const base64toBlob = (b64Data: any, contentType: any = '', sliceSize: any = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

const a2ab = (s: any) => {
  let buf = new Uint8Array(s).buffer;
  return buf;
};

const base64ToArrayBuffer = (base64: any) => {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for(let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

const arrayBufferToBase64 = (buffer: any) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for(let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return btoa(binary);
};

const blobToDataUrl = async (blob: any, func?: any) => {
  if(func) {
    const reader = new FileReader();
    reader.onload = async () => {
      const dataUrl: any = reader.result;
      func(dataUrl);
    };
    reader.readAsDataURL(blob);
  } else {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl: any = reader.result;
        resolve(dataUrl);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }
};

const base64ToDataUrl = (base64: any, fileType: any) => {
  return `data:${fileType};base64,${base64}`;
};

const dataUrlToBase64 = (dataUrl: any) => {
  return dataUrl.split(",")[1];
};

const formatFileSize = (bytes: any, asArray?: any) => {
  if(bytes === 0) return asArray ? [0, 'B'] : '0 B';
  const k = 1024;
  const dm = 2;
  const sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return asArray ? [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]] : parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];    
};

const getFileType = (type: any) => {
  let newType = "";
  if(type.includes("image")) newType = "image";
  else if(type.includes("video")) newType = "video";
  else newType = "attachment";
  return newType; 
};

const isLightOrDark = (color: any) => {
  let r;
  let g;
  let b;
  if(color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    r = color >> 16;
    g = color >> 8 & 255; //eslint-disable-line
    b = color & 255;
  }
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  if(hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const getColor = (data: any) => {
  const regex = /^#[0-9A-F]{6}$/i;
  if(regex.test(data)) {
    return data;
  } else {
    return "#000000";
  }
};

const toHoursMinutesSeconds = (totalSeconds: any) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours: hours, minutes: minutes, seconds: seconds };
};

const onlyUnique = (value: any, index: any, array: any) => {
  return array.indexOf(value) === index;
};

const onlyUniqueJSON = (value: any, index: any, array: any) => {
  return array.indexOf(JSON.stringify(value)) === JSON.stringify(index);
};

const generateRandomColor = () => {
  let hex = '#';
  const hexValues = [0,1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F']; 
  for(let i = 0; i < 6; i++){
    const index = Math.floor(Math.random() * hexValues.length)
    hex += hexValues[index];
  }
  return hex;
};

const getDayName = (day: any) => {
  const daysNames = [
    "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday",
  ];
  return daysNames[day - 1];
};

const generateColorFromString = (str: any) => {
  let hash = 0;
  let i;
  for (i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  let j;
  for (j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

const isKey = (text: any) => {
  if(text !== null && text !== undefined) {
    if(text.includes("_") || !text.includes(" ")) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const handleSum = (accumulator: any, a: any) => {
  return accumulator + a;
};

const handlePositiveSum = (accumulator: any, a: any) => {
  if(a > 0) {
    return accumulator + a;
  } else {
    return accumulator;
  }
};

const findDuplicate = (array: any) => {
  return array.filter((item: any, index: any) => array.indexOf(item) !== index);
};

const removeDuplicates = (arr: any) => {
  let unique: any = [];
  arr.forEach((element: any) => {
    if (!unique.includes(element)) {
        unique.push(element);
    }
  });
  return unique;
};

const removeDuplicatesJSON = (arr: any) => {
  let unique: any = [];
  arr.forEach((element: any) => {
    if (!unique.includes(JSON.stringify(element))) {
        unique.push(JSON.stringify(element));
    }
  });
  return unique.map((item: any) => { return JSON.parse(item); });
};

const createFileName = (text: string) => {
  let newText = text.toLowerCase();
  newText = newText.normalize("NFD").replace(/[\u0300-\u036f]/g, '');
  newText = newText.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
  newText = newText.replace(/-+/g, '-');
  return newText;
};

const createUrlLink = (text: string) => {
  let newText = text.toLocaleLowerCase();
  newText = newText.replace(/[^\p{L}\p{N}\s-]/gu, '');
  newText = newText.replace(/\s+/g, '-');
  newText = newText.replace(/-+/g, '-');
  return newText;
};

const parseTranslation = (key: any) => {
  return key.replace(/([A-Z])/g, '_' + ('$1').toLowerCase()).toLowerCase();
};

const parseEnviromentName = (text: any) => {
  const words = text.split(/[-\s]+/);
  const capitalizedWords = words.map((word: any) => {
    if(word.toLowerCase() === "twgs") return word.toUpperCase();
    else return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const formattedString = capitalizedWords.join(' ');
  return formattedString;
};

const convertToSnakeCase = (text: string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

const convertToCapitalFirstLetter = (text: any) => {
  return (text && text.length > 0) ? text.charAt(0).toUpperCase() + text.slice(1) : text;
};

const getCalendarBackgroundColor = (date: any, index: any, theme: any) => {
  if(date.day() === 6 || date.day() === 0) {
    return theme.colors.grey[225];
  } else if(Math.ceil(index + 1 / 7) % 2 === 0 ) {
    return date.day() % 2 === 0 ? theme.colors.grey[10] : theme.colors.white;
  } else {
    return date.day() % 2 === 0 ? theme.colors.grey[50] : theme.colors.grey[100];
  }
};

const getCalendarDateColor = (date: any, index: any, theme: any) => {
  if(date.day() === 6 || date.day() === 0) {
    return theme.colors.grey[225];
  } else if(Math.ceil(index + 1 / 7) % 2 === 0 ) {
    return date.day() % 2 === 0 ? theme.colors.white : theme.colors.grey[225];
  } else {
    return date.day() % 2 === 0 ? theme.colors.white : theme.colors.grey[225];
  }
};

const classToChild = (data: any, defaultChildren: any) => {
  const onlyClasses = data.map((item: any) => { return { classID: item.classID }; }).filter(onlyUnique);
  const newData = onlyClasses.map((item: any) => {
    if(data.filter((dataItem: any) => dataItem.classID === item.classID).length === defaultChildren.filter((dataItem: any) => dataItem.classID.indexOf(item.classID) !== -1).length) {
      return [{ classID: item.classID }];
    } else {
      return data.filter((dataItem: any) => dataItem.classID === item.classID).map((dataItem: any) => { return { classID: dataItem.classID, childID: dataItem.childID }; })
    }
  });
  return removeDuplicatesJSON(newData.flat());
};

const validateItemRequirements = (node: any, data: any) => {
    
  const children = data.dataData.children.length > 0;
  const childrenWithAttendance = data.dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass && item.attendanceProgram && item.attendanceProgram !== null && data.userAccessSchools.substitutions.includes(item.schoolID)).length > 0;
  const childrenWithPayments = data.dataData.children.filter((item: any) => item.payment && item.payment.totalCount > 0).length > 0;
  const employees = data.dataData.employees.length > 0;
  const employeesInTimetables = data.dataData.employees.filter((item: any) => item.enableForTimetable).length > 0;
  const isCookies = data.layoutData.cookies.length !== 0 && !data.isCypress('cookies');
  const isEmployee = (data.userObject && data.userObject.employeeID) ? data.userObject.employeeID.length > 0 : false;
  const isPremiumParent = data.userMembership.active;

  const requirements = node.requirements && node.requirements.length > 0 ? node.requirements.filter((item: any) => item !== "subscription" && item !== "premium") : [];

  const isValidrequirements = requirements.map((requirement: any) => {
    if(typeof requirement === "string") {
      if(requirement === "bakalariUrl" && data.userAccess.bakalariUrl) {
        return "bakalariUrl";
      } else if(requirement === "club" && data.userAccess.club) {
        return "club";
      } else if(requirement === "calendar" && data.userAccess.calendar) {
        return "calendar";
      } else if(requirement === "cookies" && isCookies) {
        return "cookies";
      } else if(requirement === "contacts" && employees) {
        return "contacts";
      } else if(requirement === "communication" && data.userAccess.communication) {
        return "communication";
      } else if(requirement === "employee" && isEmployee) {
        return "employee";
      } else if(requirement === "employees" && employees) {
        return "employees";
      } else if(requirement === "finance" && childrenWithPayments) {
        return "finance";
      } else if(requirement === "children" && children) {
        return "children";
      } else if(requirement === "mealMenu" && data.userAccess.mealMenu) {
        return "mealMenu";
      } else if(requirement === "mealmenuUrl" && data.userAccess.mealmenuUrl) {
        return "mealmenuUrl";
      } else if(requirement === "noPremiumParent" && !isPremiumParent) {
        return "noPremiumParent";
      } else if(requirement === "premiumParent" && isPremiumParent) {
        return "premiumParent";
      } else if(requirement === "shop" && data.userAccess.shop) {
        return "shop";
      } else if(requirement === "stock" && data.userAccess.stock) {
        return "stock";
      } else if(requirement === "substitutions" && data.userAccess.substitutions && childrenWithAttendance) {
        return "substitutions";
      } else if(requirement === "timetable" && data.userAccess.timetable) {
        return "timetable";
      } else if(requirement === "timetableEmployees" && employeesInTimetables) {
        return "timetableEmployees";
      } else {
        return null;
      }
    } else if(Array.isArray(requirement)) {
      const keys = requirement;
      if(keys[0] === "customSetting") {
        const status = data.getUserSetting(data.userSettings, keys[1], keys[2]);
        if(status) {
          return status;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }).filter((item: any) => item !== null);

  return node.isEnabled && requirements.length === isValidrequirements.length ? node : null;
};

const getSchoolSettings = (schoolID: any, setting: any, schoolSettingsData: any) => {
  const isExist = schoolSettingsData.filter((item: any) => item.schoolID === schoolID).length === 1;
  const isExistItem = isExist ? schoolSettingsData.find((item: any) => item.schoolID === schoolID)[setting] : false;
  if(isExist && isExistItem) {
    return schoolSettingsData.find((item: any) => item.schoolID === schoolID)[setting];
  } else {
    return null;
  }
};

const getSchoolsSettings = (setting: any, schoolSettingsData: any) => {
  const isExistItem = schoolSettingsData.filter((item: any) => item[setting]).length > 0;
  if(isExistItem) {
    return schoolSettingsData.filter((item: any) => item[setting]).map((item: any) => {
      return { schoolID: item.schoolID, item: item[setting] };
    });
  } else {
    return null;
  }
};

const voidFunction = () => {
  return false;
};

const demoDataPrepare = (data: any) => {
  return new Promise((resolve, reject) => {
    try {
      const result = {data: data};
      resolve(result);
    } catch (error) {
      reject(error);
    }
  })
};

const randomNumberInRange = (min: any, max: any) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const randomBoolean = (probability?: any) => {
  const getProbability = probability ? probability : 0.5;
  return Math.random() < getProbability;
};

const randomFromArray = (array: any) => {
  return array[Math.floor((Math.random() * array.length))];
};

const monthsInDateRange = (moment: any, date1: any,  date2: any) => {
  const compareDate1 = moment(date1).startOf('month');
  const compareDate2 = moment(date2).endOf('month');
  const difference = compareDate2.diff(date1, 'month');
  const months = [compareDate1.format('YYYY-MM')];
  for(let i = 1; i <= difference; i++) {
    const newDate = compareDate1.add(i, 'month');
    months.push(newDate.format('YYYY-MM'));
  }
  return months;
};

const shuffle = (array: any) => {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
};

const formatRelativeDate = (inputDate: any, moment: any, t: any) => {
  const currentDate = moment();
  const diffSeconds = currentDate.diff(inputDate, 'seconds');
  const diffMinutes = currentDate.diff(inputDate, 'minutes');
  const diffHours = currentDate.diff(inputDate, 'hours');
  const diffDays = currentDate.diff(inputDate, 'days');
  const diffWeeks = currentDate.diff(inputDate, 'weeks');
  const diffMonths = currentDate.diff(inputDate, 'months');
  const diffYears = currentDate.diff(inputDate, 'years');
  let result;
  if(diffSeconds < 60) {
    result = t('just_now');
  } else if(diffMinutes < 60) {
    result = t('ago_formula', {number: diffMinutes, text: inflection(diffMinutes, t('minute_ago'), t('minutes_ago'), t('minutes_more_ago')).toLowerCase()});
  } else if(diffHours < 24) {
    result = t('ago_formula', {number: diffHours, text: inflection(diffHours, t('hour_ago'), t('hours_ago'), t('hours_more_ago')).toLowerCase()});
  } else if(diffDays < 7) {
    result = t('ago_formula', {number: diffDays, text: inflection(diffDays, t('day_ago'), t('days_ago'), t('days_more_ago')).toLowerCase()});
  } else if(diffWeeks < 4) {
    result = t('ago_formula', {number: diffWeeks, text: inflection(diffWeeks, t('week_ago'), t('weeks_ago'), t('weeks_more_ago')).toLowerCase()});
  } else if(diffMonths < 12) {
    result = t('ago_formula', {number: diffMonths, text: inflection(diffMonths, t('month_ago'), t('months_ago'), t('months_more_ago')).toLowerCase()});
  } else {
    result = t('ago_formula', {number: diffYears, text: inflection(diffYears, t('year_ago'), t('years_ago'), t('years_more_ago')).toLowerCase()});
  }
  return result;
};

const formatRelativeSeconds = (seconds: any, t: any) => {
  const result = t('left_formula', {number: seconds, text: inflection(seconds, t('second_left'), t('seconds_left'), t('seconds_more_left')).toLowerCase()});
  return result;
};

const inflection = (number: any, single: any, more: any, moreMore: any) => {
  if (number === 1) {
    return single;
  } else if (number > 1 && number < 5) {
    return more;
  } else {
    return moreMore;
  }
};

const calculateNextUpdateIn = (date: any, moment: any) => {
  const currentDate = moment();
  const diffSeconds = currentDate.diff(date, 'seconds');
  const diffMinutes = currentDate.diff(date, 'minutes');
    if (diffSeconds < 60) {
      return 60 - (diffSeconds % 60);
    } else if (diffMinutes < 60) {
      return 60 - (diffSeconds % 60);
    } else if (diffMinutes < 1440) {
      return 3600 - (diffSeconds % 3600);
    } else if (diffMinutes < 10080) {
      return 86400 - (diffSeconds % 86400);
    } else if (diffMinutes < 43200) {
      return 604800 - (diffSeconds % 604800);
    } else if (diffMinutes < 302400) {
      return 2592000 - (diffSeconds % 2592000);
    } else {
      return 31536000 - (diffSeconds % 31536000);
    }
};

const removeAccents = (strAccents: any) => {
  if(strAccents) {
    strAccents = strAccents.split('');
    let strAccentsOut: any = [];
    const strAccentsLen = strAccents.length;
    const accents =    "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëÇçðÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    const accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeCcdDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (var y = 0; y < strAccentsLen; y++) {
      if (accents.indexOf(strAccents[y]) !== -1) {
        strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
      } else {
        strAccentsOut[y] = strAccents[y];
      }
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
  } else {
    return strAccents;
  }
};

const filterEmptyValues = (obj: any) => {
  const result: any = {};
  for(const key in obj) {
    if(obj[key] !== "") {
      result[key] = obj[key];
    }
  }
  return result;
};

const filterNotExistingValues = (obj: any) => {
  const result: any = {};
  for(const key in obj) {
    if(obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }
  return result;
};

const getSupportedMediaRecorderType = (extension?: boolean) => {
  let supportedType = "none";
  let supportedExtension = "none";
  const types = [
    {
      type: "video/webm",
      extension: "webm",
    },
    {
      type: "video/webm;codecs=vp8",
      extension: "webm",
    },
    {
      type: "video/webm;codecs=daala",
      extension: "webm",
    },
    {
      type: "video/webm;codecs=h264",
      extension: "webm",
    },
    {
      type: "video/mpeg",
      extension: "mpeg",
    },
    {
      type: "video/mp4",
      extension: "mp4",
    },
  ];
  types.forEach((type: any) => {
    if(MediaRecorder.isTypeSupported(type.type) && supportedType === "none") {
      supportedType = type.type;
      if(extension) {
        supportedExtension = type.extension;
      }
    }
  });
  return extension ? supportedExtension : supportedType;
};

const getImageFromClipboard = (pasteEvent?: any, callback?: any, imageFormat?: any) => {
  if(pasteEvent.clipboardData === false){
      if(typeof(callback) == "function"){
          callback(undefined);
      }
  };
  let items = pasteEvent.clipboardData.items;
  if(items === undefined){
      if(typeof(callback) == "function"){
          callback(undefined);
      }
  };
  for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === -1) continue;
      let blob = items[i].getAsFile();
      let mycanvas = document.createElement("canvas");
      let ctx: any = mycanvas.getContext('2d');
      let img = new Image();
      img.onload = function(){
        mycanvas.width = img.width;
        mycanvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        if(typeof(callback) == "function"){
          callback(mycanvas.toDataURL(
            (imageFormat || "image/png")
          ));
        }
      };
      let URLObj = window.URL || window.webkitURL;
      img.src = URLObj.createObjectURL(blob);
  }
};

const handleDetectCamera = (callback: any) => {
  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then((devices: any) => {
    callback(devices.some((device: any) => 'videoinput' === device.kind));
  });
};

const handleDetectScreen = () => {
  const mediaDevices = navigator.mediaDevices;
  if (!mediaDevices || !mediaDevices.getDisplayMedia) {
    return false;
  } else {
    return true;
  }
};

const isExistInArrayWithObject = (array: any, keys: any | any[], values: any | any[]) => {
  if(!Array.isArray(keys)) {
    keys = [keys];
  }
  if(!Array.isArray(values)) {
    values = [values];
  }
  return array.some((item: any) => {
    return keys.every((key: any, index: any) => item[key] === values[index]);
  });
};

const removeParamsFromUrl = (url: any) => {
  return url.split('?')[0].split('&')[0];
};

const isFiltersEnabled = (loc: any, items: any) => {
  let enabled = false;
  if(items) {
    if(items.filter((item: any) => item.to === loc).length > 0) {
      if(items.find((item: any) => item.to === loc).hasFilters !== undefined) {
        enabled = items.find((item: any) => item.to === loc).hasFilters;
      }
    }
  }
  return enabled;
};

const mergeArrays = (array1: any, array2: any) => {
  const mergedMap = new Map();
  array1.forEach((item: any) => {
    mergedMap.set(item.key, item.value);
  });
  array2.forEach((item: any) => {
    mergedMap.set(item.key, item.value);
  });
  const mergedArray = Array.from(mergedMap, ([key, value]) => ({ key, value }));
  return mergedArray;
};

const mergeArraysFirst = (array1: any, array2: any) => {
  let map = new Map();
  array1.forEach((obj: any) => {
      map.set(obj.key, obj);
  });
  array2.forEach((obj: any) => {
      if (!map.has(obj.key)) {
          map.set(obj.key, obj);
      }
  });
  let mergedArray = Array.from(map.values());
  return mergedArray;
};

const mergeDeepArrays = (array1: any, array2: any) => {
  const mergedArray = [...array1];
  array2.forEach((item2: any) => {
    if(Array.isArray(item2.value)) {
      const index = mergedArray.findIndex(item1 => item1.key === item2.key);
      if(index !== -1) {
        if(item2.value === null && mergedArray[index].value !== null) {
          mergedArray[index].value = array1[index].value;
        } else if (item2.value !== null) {
          mergedArray[index].value = item2.value;
        } else {
          mergedArray[index].value = [...item2.value,...mergedArray[index].value.filter((val1: any) => !item2.value.some((val2: any) => val1.key === val2.key))];
        }
      } else {
        mergedArray.push(item2);
      }
    } else if(typeof item2.value === "object" && item2.value !== null) {
      const index = mergedArray.findIndex(item1 => item1.key === item2.key);
      if(index !== -1) {
        if(item2.value === null && mergedArray[index].value !== null) {
          mergedArray[index].value = array1[index].value;
        } else if (item2.value !== null) {
          mergedArray[index].value = item2.value;
        } else {
          mergedArray[index].value = Object.assign(mergedArray[index].value, item2.value);
        }
      } else {
        mergedArray[index].value = item2.value;
      }
    } else if(typeof item2.value === "string") {
      const index = mergedArray.findIndex(item1 => item1.key === item2.key);
      if(index !== -1) {
        mergedArray[index].value = item2.value;
      } else {
        mergedArray.push(item2);
      }
    } else {
      const index = mergedArray.findIndex(item1 => item1.key === item2.key);
      if(index !== -1) {
        if(item2.value === null && mergedArray[index].value !== null) {
          mergedArray[index].value = array1[index].value;
        } else if (item2.value !== null) {
          mergedArray[index].value = item2.value;
        } else {
          mergedArray[index].value = item2.value;
        }
      } else {
        mergedArray.push(item2);
      }
    }
  });
  return mergedArray;
};

const getActiveFilters = (data: any) => {
  return Object.entries(data).filter((item: any) => item[0] !== "search" && item[1] !== undefined && item[1] !== null && item[1].length !== 0).length;
};

const getFiltersKeys = (data: any, replaceKeys: any) => {
  const params = Object.entries(data).filter((item: any) => item[1] !== undefined && item[1] !== null && item[1].length !== 0).map((entry: any) => {
    const key = replaceKeys[entry[0]] || entry[0];
    return { [key]: entry[1] };
  }).reduce((a: any, v: any) => ({ ...a, ...v }), {});
  return params;
};

const getFiltersKeysArray = (data: any, replaceKeys: any) => {
  const params = Object.entries(Object.entries(data).filter((item: any) => item[1] !== undefined && item[1] !== null && item[1].length !== 0).map((entry: any) => {
    const key = replaceKeys[entry[0]] || entry[0];
    return { [key]: entry[1] };
  }).reduce((a: any, v: any) => ({ ...a, ...v }), {})).map((item: any) => {
    return { key: item[0], value: item[1]};
  });
  return params;
};

const hasObjectAnyValue = (object: any) => {
  for(let key in object) {
    if(object.hasOwnProperty(key) && object[key] !== null) {
      return true;
    }
  }
  return false;
};

const arrayToObject = (array: any[]) => {
  const resultObject: any = {};
  array.forEach((item: any) => {
    resultObject[item.key] = item.value;
  });
  return resultObject;
};

const addonsArrayToObject = (array: any[]) => {
  return array.reduce((obj: any, item: any) => {
      obj[item.name] = item.value;
      return obj;
  }, {});
};

const mergeMenuItems = (array1: any, array2: any) => {
  return array1.map((item1: any) => {
    const matchingItemExist = array2.filter((item2: any) => item1.to === item2.to).length !== 0;
    if(matchingItemExist) {
      const matchingItem = array2.find((item2: any) => item1.to === item2.to);
      return { ...item1, ...matchingItem };
    }
    return item1;
  });
};

const yesOrNo = (value: any) => {
  return value ? "yes" : "no";
};

const joinValues = (array: any) => {
  let result = '';
  if(Array.isArray(array)) {
    array.forEach((element: any) => {
      if(element && element.value) {
        if(Array.isArray(element.value)) {
          result += joinValues(element.value);
        } else if(typeof element.value === "object") {
          result += JSON.stringify(element.value);
        } else {
          result += element.value.toString();
        }
      } else {
        if(typeof element === "object") {
          result += JSON.stringify(element);
        } else {
          result += element ? element.toString() : "";
        }
      }
    });
  } else {
    if(typeof array === "object") {
      result += JSON.stringify(array);
    } else {
      result += array.toString();
    }
  }
  return result;
};

const recalculateKeys = (array: any) => {
  return array.map((item: any, key: any) => {
    return {...item, key: key};
  });
};

const getUniquePosts = (array: any) => {
  let uniqueIDs: any = {};
  let newArray: any = [];
  array.forEach((object: any) => {
    if(!uniqueIDs[object.uniqueID]) {
      uniqueIDs[object.uniqueID] = true;
      newArray.push(object);
    }
  });
  return newArray;
};

const checkObjectKeyInArray = (array: any, key: any, value: any) => {
  for(let i = 0; i < array.length; i++) {
    if(array[i].hasOwnProperty(key) && array[i][key] === value) {
      return true;
    }
  }
  return false;
};

const removeEmoji = (str: any) => {
  const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g; // eslint-disable-line
  return str.replace(regex, '');
};

const isOnlyEmoji = (str: any) => {
  return removeEmoji(str).length === 0;
};

const containsEmoji = (str: any) => {
  const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F900}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}]/u;
  return emojiRegex.test(str);
};

const getEmptyFileLimits = () => {
  return {
    attachmentCountLimit: 0,
    attachmentSizeLimit: 0,
    photoCountLimit: 0,
    photoSizeLimit: 0,
    videoCountLimit: 0,
    videoSizeLimit: 0, 
    videoTimeLimit: 0, 
  };
};

const getMaxLimits = (array: any, limitsKey: any) => {
  const result: any = {};
  array.forEach((obj: any) => {
    if(obj.modules) {
      Object.entries(obj.modules).forEach(([key, value]: any) => {
        if(value[limitsKey]) {
          if(!result[key]) {
            result[key] = { ...value[limitsKey] };
          } else {
            Object.entries(value[limitsKey]).forEach(([limitKey, limitValue]: any) => {
              if(result[key][limitKey] !== undefined) {
                result[key][limitKey] = Math.max(result[key][limitKey], limitValue);
              } else {
                result[key][limitKey] = limitValue;
              }
            });
          }
        }
      });
    }
  });
  return result;
};

const getSideContentSize = (breakpoint: any) => {

  const sizes: any = {
    xxxxl: 45,
    xxxl: 40,
    xxl: 35,
    xl: 25,
    lg: 25,
    bg: 35,
    md: 0,
    co: 0,
    sm: 0,
    xs: 0,
  };

  return sizes[breakpoint];

};

const isValidMessage = (message: any, t: any) => {
  const regexHTML = /<\/?[^>]+(>|$)/g;
  const trimmedMessage = message.trim();
  if(trimmedMessage.length === 0) {
    return t("communication_message_empty");
  }
  if(message.length > 2048) {
    return t("communication_message_long");
  }
  if(regexHTML.test(message)) {
    return t("communication_message_unallowed_chars");
  }
  return true;
};

const trimCharacter = (string: any, character: any) => {
  while (string.startsWith(character)) {
      string = string.slice(1);
  }
  while (string.endsWith(character)) {
      string = string.slice(0, -1);
  }
  return string;
};

const moveLastToFirstInArray = (array: any) => {
  if(array.length > 0) {
    const lastItem = array[array.length - 1];
    return [lastItem, ...array.slice(0, -1)];
  }
  return array;
};

const hasUpperCase = (str: any) => {
  return str !== str.toLowerCase();
};

const callFunction = async (func: any, funcData: any, context: any) => {
  if(typeof func === 'function') {
    const args = funcData.map((data: any) => context[data]);
    return await func.apply(null, args);
  }
};

const parseXmlResponse = (text: any) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'application/xml');
  const getTextContent = (element: any, tagName: any) => {
    const tags = element.getElementsByTagName(tagName);
    if (tags.length === 1) {
      return tags[0].textContent;
    } else if (tags.length > 1) {
      return Array.from(tags).map((tag: any) => tag.textContent);
    } else {
      return null;
    }
  };
  const items = xmlDoc.getElementsByTagName('item');
  const feed = Array.from(items).map(item => ({
    author: getTextContent(item, 'dc:creator'),
    category: getTextContent(item, 'category'),
    contentEncoded: getTextContent(item, 'content:encoded'),
    description: getTextContent(item, 'description'),
    guid: getTextContent(item, 'guid'),
    image: getTextContent(item, 'image'),
    link: getTextContent(item, 'link'),
    pubDate: getTextContent(item, 'pubDate'),
    tag: getTextContent(item, 'tag'),
    title: getTextContent(item, 'title'),
  }));
  const response = {
    info: {
      description: getTextContent(xmlDoc, 'description'),
      language: getTextContent(xmlDoc, 'language'),
      lastBuildDate: getTextContent(xmlDoc, 'lastBuildDate'),
      link: getTextContent(xmlDoc, 'link'),
      title: getTextContent(xmlDoc, 'title'),
    },
    items: feed,
  };
  return response;
};

const getPinterestMetricValue = (item: any, key: string): number | null => {
  if (item && item.pin_metrics && item.pin_metrics.lifetime_metrics) {
    const value = item.pin_metrics.lifetime_metrics[key];
    return value === 0 || value == null ? null : value;
  }
  return null;
};

export {
  base64toBlob,
  a2ab,
  base64ToArrayBuffer,
  arrayBufferToBase64,
  blobToDataUrl,
  base64ToDataUrl,
  dataUrlToBase64,
  formatFileSize,
  getFileType,
  isLightOrDark,
  getColor,
  toHoursMinutesSeconds,
  onlyUnique,
  onlyUniqueJSON,
  generateRandomColor,
  getDayName,
  generateColorFromString,
  isKey,
  handleSum,
  handlePositiveSum,
  findDuplicate,
  removeDuplicates,
  removeDuplicatesJSON,
  createFileName,
  createUrlLink,
  parseTranslation,
  parseEnviromentName,
  convertToSnakeCase,
  convertToCapitalFirstLetter,
  getCalendarBackgroundColor,
  getCalendarDateColor,
  classToChild,
  validateItemRequirements,
  getSchoolSettings,
  getSchoolsSettings,
  voidFunction,
  demoDataPrepare,
  randomNumberInRange,
  randomBoolean,
  randomFromArray,
  monthsInDateRange,
  shuffle,
  formatRelativeDate,
  formatRelativeSeconds,
  inflection,
  calculateNextUpdateIn,
  removeAccents,
  filterEmptyValues,
  filterNotExistingValues,
  getSupportedMediaRecorderType,
  getImageFromClipboard,
  handleDetectCamera,
  handleDetectScreen,
  isExistInArrayWithObject,
  removeParamsFromUrl,
  isFiltersEnabled,
  mergeArrays,
  mergeArraysFirst,
  mergeDeepArrays,
  getActiveFilters,
  getFiltersKeys,
  getFiltersKeysArray,
  hasObjectAnyValue,
  arrayToObject,
  addonsArrayToObject,
  mergeMenuItems,
  yesOrNo,
  joinValues,
  recalculateKeys,
  getUniquePosts,
  checkObjectKeyInArray,
  removeEmoji,
  isOnlyEmoji,
  containsEmoji,
  getEmptyFileLimits,
  getMaxLimits,
  getSideContentSize,
  isValidMessage,
  trimCharacter,
  moveLastToFirstInArray,
  hasUpperCase,
  callFunction,
  parseXmlResponse,
  getPinterestMetricValue,
};
