import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import DateFormat from 'src/utils/dateFormat';
import DropdownWithMenu from 'src/components/Buttons/DropdownWithMenu';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import ReactionsList from 'src/components/Reactions/List';
import ReactionsPopper from 'src/components/Reactions/Popper';
import RelativeDate from 'src/components/Layouts/RelativeDate';
import SVG from 'src/components/Images/SvgRenderer';
import TimelineCommentsThreads from 'src/components/Comments/TimelineCommentsThreads';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { Button } from '@mui/base';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { setCommentsDeleted, setCommentsEdited, setCommentsReplyTo, setCommentsVisibility } from 'src/store/actions/comments.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';

interface Props {
  isEditable?: any;
  isEdited?: any;
  isDeleted?: any;
  isEditing?: any;
  isReplyTo?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  commentCardWrapper: {
    display: 'flex',
    gap: '8px',
    width: 'calc(100% - 16px)',
    position: 'relative',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 48px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: '1 1 auto',
  },
  userWrapper: {
    display: 'flex',
    height: '48px',
  },
  user: {
    display: 'flex',
    position: 'relative',
    width: '48px',
    minWidth: '48px',
    height: '48px',
    minHeight: '48px',
    zIndex: '2',
    '& > div': {
      width: '100%',
      height: '100%',
      borderRadius: '12px',
      overflow: 'hidden',
      backgroundColor: theme.colors.white,
      boxShadow: theme.shadows[2],
    },
  },
  infoIcon: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.colors.grey[200],
    position: 'absolute',
    right: '-3.2px',
    bottom: '-3.2px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      width: '16px',
      height: '16px',
      color: theme.colors.black + ' !important',
      transform: 'scale(0.8)' 
    },
  },
  commentCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  commentCardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 8px',
    backgroundColor: (props: Props) => {
      if(props.isEditing || props.isReplyTo) return theme.colors.yellow[100];
      else if(props.isDeleted) return theme.colors.grey[200];
      else return theme.colors.white;
    },
    flex: '1 1 auto',
    borderRadius: '14px',
    position: 'relative',
    boxShadow: theme.shadows[2],
    width: 'calc(100% - 16px)',
  },
  commentCardInner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '8px',
  },
  commentCardLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  commentCardRight: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '7px',
    paddingRight: '3px',
    '&:empty': {
      display: 'none',
    },
  },
  commentCardHeader: {
    display: 'flex',
    gap: '8px',
  },
  displayName: {
    fontWeight: '500',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  text: {
    fontSize: '14px',
    fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
    maxWidth: '100%',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  }, 
  actions: {
    display: 'flex',
    gap: '8px',
    paddingLeft: '8px',
    width: 'calc(100% - 8px)',
  },
  dateTime: {
    fontWeight: '400',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
  tooltip: {
    width: 'fit-content',
    marginTop: '5px !important',
  },
  dropdownMenu: {
    position: 'sticky',
    top: '7px',
  },
  dropdownMenuPopover: {
    borderRadius: '14px',
  },
  dropdownMenuButton: {
    backgroundColor: theme.colors.grey[100],
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    cursor: 'pointer',
    transition: 'color 0.25s',
    color: theme.colors.black,
    padding: '0',
    '-webkit-appearance': 'none',
    backgroundColor: 'transparent',
    borderWidth: '0',
    borderStyle: 'none',
    borderColor: 'transparent',
    minWidth: 'unset',
    fontFamily: 'inherit',
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      '& > span': {
        color: theme.colors.primaryBlue[500],
      },
    },
    '&.active': {
      '& > span': {
        color: theme.colors.brightRed[300],
        fontWeight: '600',
      },
      '&:hover': {
        '& > span': {
          color: theme.colors.brightRed[400],
        },
      },
    },
    '& > span': {
      transition: 'color 0.25s',
      lineHeight: 'normal',
      fontFamily: 'inherit',
      '& > span': {
        display: 'none',
      },
    },
  },
  moreButton: {
    backgroundColor: theme.colors.grey[200],
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '& > svg': {
      width: '14px',
      height: '14px',
    },
  },
  reactions: {
    position: 'absolute',
    right: '-5px',
    bottom: '-10px',
    padding: '2px 4px',
    borderRadius: '14px',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.colors.white,
    '& > div': {
      '& > div': {
        width: '16px',
        height: '16px',
        fontSize: '60%',
      }
    }
  },
  lineHorizontal: {
    position: 'absolute',
    top: '-24px',
    left: '-34px',
    right: '0',
    width: '48px',
    height: 'calc(100% + 8px)',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.grey[500],
  },
  lineConnectional: {
    position: 'absolute',
    top: '0px',
    left: '-34px',
    right: '0',
    width: '48px',
    height: '100%',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.grey[500],
  },
  lineVertical: {
    position: 'absolute',
    top: '-24px',
    left: '-34px',
    right: '0',
    width: '48px',
    height: '48px',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.grey[500],
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[500],
    borderBottomLeftRadius: '24px 24px',
  },
}));

type CommentCardType = {
  uniqueID: any;
  commentID: any;
};

const CommentCard: React.FunctionComponent<CommentCardType> = ({uniqueID, commentID}) => {
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const commentsData = useAppSelector((state: any) => state.comments);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const comments = commentsData.comments;
  const edited = commentsData.edited;
  const visibility = commentsData.visibility;
  const deleted = commentsData.deleted;
  const replyTo = commentsData.replyTo;
  const commentData = comments.filter((item: any) => item.commentID === commentID).length === 0 ? [] : comments.find((item: any) => item.commentID === commentID);
  const commentsInThisThread = comments.filter((item: any) => item.threadID === commentData.threadID);
  const postData = timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
  const schoolID = postData.schoolID;
  const schoolSettings = userData.schoolSettings;
  const schoolReactions = useMemo(() => getSchoolSettings(schoolID, 'modules', schoolSettings) === null ? false : getSchoolSettings(schoolID, 'modules', schoolSettings).reaction ? (getSchoolSettings(schoolID, 'modules', schoolSettings).reaction.enable && getSchoolSettings(schoolID, 'modules', schoolSettings).reaction.enableFor.comments) : false, [schoolID, schoolSettings]);
  const isPostCommentsEnabled = postData === null ? false : postData.comments.enable;
  const isCommentsEnabled = getSchoolSettings(schoolID, 'modules', schoolSettings).comment ? getSchoolSettings(schoolID, 'modules', schoolSettings).comment.enable : false;
  const isThreadsEnabled = getSchoolSettings(schoolID, 'modules', schoolSettings).comment ? getSchoolSettings(schoolID, 'modules', schoolSettings).comment.threads : false;

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const commentUserData = getUserData(commentData.userID);

  const classes = useStyles({
    isEditable: commentData.editable,
    isEdited: commentData.edited,
    isDeleted: commentData.deleted,
    isEditing: edited === commentID,
    isReplyTo: replyTo === commentID,
  });

  const handleOnClickEdit = () => {
    dispatch(setCommentsReplyTo(0));
    dispatch(setCommentsVisibility(0));
    dispatch(setCommentsDeleted(0));
    dispatch(setCommentsEdited(commentData.commentID));
  };

  const handleOnClickVisibility = () => {
    dispatch(setCommentsEdited(0));
    dispatch(setCommentsDeleted(0));
    dispatch(setCommentsReplyTo(0));
    dispatch(setCommentsVisibility(commentData.commentID));
  };

  const handleOnClickDelete = () => {
    dispatch(setCommentsReplyTo(0));
    dispatch(setCommentsVisibility(0));
    dispatch(setCommentsEdited(0));
    dispatch(setCommentsDeleted(commentData.commentID));
  };

  const handleOnClickReply = () => {
    dispatch(setCommentsEdited(0));
    dispatch(setCommentsVisibility(0));
    dispatch(setCommentsDeleted(0));
    dispatch(setCommentsReplyTo(commentData.commentID));
  };

  const dropdownMenuItems = [
    (isCommentsEnabled && isPostCommentsEnabled) ? {
      title: t('edit'),
      icon: (<SVG src="edit"/>),
      onClick: handleOnClickEdit,
      dataCy: 'commentCardEditButton',
    } : null,
    getUserRole(userData.userObject.roleType) === "director" ? {
      title: commentData.visibility ? t('hide') : t('show'),
      icon: (<SVG src={commentData.visibility ? "eye-slash": "eye"}/>),
      onClick: handleOnClickVisibility,
      dataCy: 'commentCardVisibilityButton',
    } : null,
    (isCommentsEnabled && isPostCommentsEnabled) ? {
      title: t('delete'),
      icon: (<SVG src="trash"/>),
      onClick: handleOnClickDelete,
      dataCy: 'commentCardDeleteButton',
    } : null,
  ].filter((item: any) => item !== null);

  return (
    <div className={classes.commentCardWrapper}>
      <div className={classes.leftSide}>
        <div className={classes.userWrapper}>
          {
            commentData.threadID !== 0 ? (
              <>
                {
                  commentsInThisThread[commentsInThisThread.length - 1].commentID !== commentID ? (
                    <span className={classes.lineHorizontal}/>
                  ) : null
                }
                <span className={classes.lineVertical}/>
              </>
            ) : null
          }
          <div className={classes.user}>
            <AuthenticatedImage thumbLink={commentUserData.photo.thumbLink} useLoader={true}/>
            {
              (commentData.edited && !commentData.deleted) ? (
                <TooltipIcon className={classes.infoIcon} icon="pencil" title={t('comment_was_edited')} maxWidth={400}/>
              ) : null
            }
            {
              (!commentData.visibility && !commentData.deleted) ? (
                <TooltipIcon className={classes.infoIcon} icon="eye-slash" title={t('comment_was_hidden')} maxWidth={400}/>
              ) : null
            }
            {
              commentData.deleted ? (
                <TooltipIcon className={classes.infoIcon} icon="trash" title={t('comment_was_deleted')} maxWidth={400}/>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.commentCard}>
          {
            commentData.countComments !== 0 ? (
              <span className={classes.lineConnectional}/>
            ) : null
          }
          <div className={classes.commentCardContent}>
            <div className={classes.commentCardInner}>
              <div className={classes.commentCardLeft}>
                <div className={classes.commentCardHeader}>
                  <span className={classes.displayName}>
                    {commentUserData.displayName}
                  </span>
                </div>
                <div className={classes.text}>
                  {commentData.text}
                </div>
              </div>
              <div className={classes.commentCardRight}>
                {
                  (isCommentsEnabled && isPostCommentsEnabled && commentData.editable && !commentData.deleted) ? (
                    <DropdownWithMenu className={classes.dropdownMenu} items={dropdownMenuItems} classNamePopover={classes.dropdownMenuPopover} classNameMenuButton={classes.dropdownMenuButton} classNameMoreButton={classes.moreButton} moreButtonSize='small' disabled={edited === commentID || visibility === commentID || deleted === commentID} dataCy='commentCardMoreButton'/>
                  ) : null
                }
                {
                  (!commentData.editable && !commentData.deleted && getUserRole(userData.userObject.roleType) === "director") ? (
                    <IconButton onClick={handleOnClickVisibility} className={classes.moreButton} tooltip={commentData.visibility ? t('comment_hide') : t('comment_show')} tooltipMaxWidth={400} disabled={edited === commentID || visibility === commentID || deleted === commentID}>
                      <SVG src={commentData.visibility ? "eye-slash": "eye"}/>
                    </IconButton>
                  ) : null
                }
              </div>
            </div>
            {
              ((postData.reactions && postData.reactions.enable && commentData.reactions && commentData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (postData.reactions && commentData.reactions && commentData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0)) ? (
                <ReactionsList className={classes.reactions} data={commentData} ID={[uniqueID, commentData.commentID]} type='comment'/>
              ) : null
            }
          </div>
          <div className={classes.actions}>
            <Tooltip title={DateFormat(commentData.dateTime, "comment", languageData, t)} classes={{ tooltip: classes.tooltip }} placement='bottom' arrow={true}>
              <span className={classes.dateTime}>
                <RelativeDate date={commentData.dateTime}/>
              </span>
            </Tooltip>
            {
              (postData.reactions && postData.reactions.enable && schoolReactions) ? (
                <ReactionsPopper data={commentData} ID={commentData.commentID} type='comment' reactionButtonType='text' reactionButtonTypeActive='text' classNameReactionButton={classes.actionButton}/>
              ) : null
            }
            {
              (isCommentsEnabled && isPostCommentsEnabled && isThreadsEnabled) ? (
                <Button className={classes.actionButton} onClick={handleOnClickReply}>
                  {t('comment_reply')}
                </Button>
              ) : null
            }
          </div>
        </div>
        {
          commentData.countComments !== 0 ? (
            <TimelineCommentsThreads uniqueID={uniqueID} threadID={commentData.commentID}/>
          ) : null
        }
      </div>
    </div>
  );
}

export default CommentCard;