import HttpService from '../http.service';

const listPosts = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`post${query ? `${query}`: ""}`, {}, {});
};

const listMedias = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for(const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  } 
  return HttpService.get(`media${query ? `${query}`: ""}`, {}, {});
};

const downloadFile = (fileID: any) => {
  return HttpService.get(`file/${fileID}`, {}, {responseType: 'arraybuffer'});
};

const voteAnswer = (payload: any) => {
  return HttpService.post('postAnswer', payload, {}, {});
};

const setAttribute = (postID: any, payload: any) => {
  return HttpService.put(`post/attribute/${postID}`, payload, {}, {});
};

export {
  listPosts,
  listMedias,
  downloadFile,
  voteAnswer,
  setAttribute
};