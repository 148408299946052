import config from 'src/constants/config';
import Laptop from 'src/components/Devices/Laptop';
import NormalButton from 'src/components/Buttons/NormalButton';
import PreviewApp from 'src/components/Layouts/PreviewApp';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { setTheme } from 'src/store/actions/layout.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  select: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '96px',
    gap: '16px',
    position: 'relative',
    '& > span': {
      fontSize: '24px',
      width: '80%',
      fontWeight: '600',
      marginBottom: '16px',
      color: theme.colors.black,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    '& > div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '80%',
      '& > button': {
        '&.disabled': {
          cursor: 'default',
          pointerEvents: 'none',
        },
        '& > span': {
          width: '100%',
        },
      },
    },
  },
  experimental: {
    backgroundColor: theme.colors.systemRed[500],
    color: theme.colors.white,
    padding: '2px 4px',
    borderRadius: '8px',
    marginLeft: 'auto',
    fontSize: '12px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '80%',
    marginTop: '16px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  laptop: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) scale(1.2)',
    left: '20%',
  },
  loading: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type SectionType = {
  updateSection: any;
  handleNext: any;
};

const Theme: React.FunctionComponent<SectionType> = ({ updateSection, handleNext }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const section = "theme";

  const [state, setState] = useStates({
    canContinue: true,
  });

  const schemeRef = useRef(layoutData.theme);

  const breakpoint: any = useBreakpoint();

  const devicesToShow: any = {
    xxxxl: "laptop",
    xxxl: "laptop",
    xxl: "laptop",
    xl: "laptop",
    lg: "laptop",
    bg: "laptop",
    md: "mobile",
    co: "mobile",
    sm: "none",
    xs: "none",
  };

  const deviceToShow = devicesToShow[breakpoint];

  const handleChangeTheme = async (scheme: any) => {
    if(state.canContinue && schemeRef.current !== scheme) {
      setState("canContinue", false);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["theme"], scheme);
      if(result) {
        schemeRef.current = scheme;
        dispatch(setTheme(scheme));
        setState("canContinue", true);
        return true;
      } else {
        setState("canContinue", false);
        return false;
      }
    }
  };

  const handleSave = async () => {
    const value = schemeRef.current;
    const oldValue = getUserSetting(userData.userSettings, "setup", [section]);
    const newValue = updateUserSetting(userData.userSettings, "setup", [section], value);
    updateSection(section, false);
    dispatch(setUserSettings(newValue));
    const result = await saveUserSettings(dispatch, userData, "setup", [section], value);
    if(result) {
      return true;
    } else {
      const updateSettings = updateUserSetting(userData.userSettings, "setup", [section], oldValue);
      updateSection(section, true);
      dispatch(setUserSettings(updateSettings));
      return false;
    }
  };


  const handleContinue = async () => {
    if(state.canContinue) {
      setState("canContinue", false);
      const isSaved = await handleSave();
      if(isSaved) {
        handleNext();
      } else {
        createNotification(t('something_went_wrong'), 'error');
        setState("canContinue", true);
      }
    }
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.select}>
          <span>{t('setup_select_theme')}</span>
          <div>
            <NormalButton className={(!state.canContinue || schemeRef.current === "light") ? "disabled" : null} buttonType="blackOutlined" onClick={() => handleChangeTheme("light")}>
              {t('app_theme_light')}
            </NormalButton>
            <NormalButton className={(!state.canContinue || schemeRef.current === "dark") ? "disabled" : null} buttonType="black" onClick={() => handleChangeTheme("dark")}>
              {t('app_theme_dark')}
              <span className={classes.experimental}>
                {t('experimental')}
              </span>
            </NormalButton>
          </div>
          <div className={classes.buttonWrapper}>
            <NormalButton buttonType="primary" onClick={handleContinue} disabled={!state.canContinue}>
              {t('setup_continue')}
            </NormalButton>
          </div>
          {
            !state.canContinue ? (
              <div className={classes.loading}>
                <div className={classes.spinner}>
                  <CircularProgress size={128}/>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
      <div className={classes.rightSide}>
        {
          deviceToShow === "laptop" ? (
            <Laptop className={classes.laptop}>
              <PreviewApp layout="desktop"/>
            </Laptop>
          ) : null
        }
      </div>
    </div>
  );
}

export default Theme;