import config from 'src/constants/config';
import moment from 'src/utils/moment';
import { browserName, deviceType, fullBrowserVersion, osName, osVersion } from 'react-device-detect';
import { getAppApiServer } from 'src/utils/useApp';
import { getUserRole } from 'src/utils/useUser';

const getUserInfo = (userData: any, location: any) => {

    const firstLogin = moment().format("YYYY-MM-DD HH:mm:ss");
    const apiServer = getAppApiServer();
    const environment = config.APP_ENVIRONMENT;
    const version = config.APP_VERSION;
    const branch = config.BRANCH;

    if(userData) {
    
      const userInfo: any = {
        username: userData.userID,
        email: userData.email,
        firstname: userData.firstname,
        lastname: userData.surname,
        role: getUserRole(userData.roleType),
        signUpAt: firstLogin,
        props: { 
          apiServer: apiServer,
          branch: environment === "review" ? branch : null,
          environment: environment,
          version: version,
          device: deviceType,
          browser: `${browserName} ${fullBrowserVersion}`,
          os: `${osName} ${osVersion}`,
          premiumParent: getUserRole(userData.roleType) === "parent" ? (userData.membership ? userData.membership.active : null) : null,
          premiumSchool: userData.schoolSetting ? (userData.schoolSetting.filter((item: any) => item.subscription === "PREMIUM" || item.subscription === "PRO").length > 0 ? true : null) : null,
          lastPage: location.pathname,
          lastActiveSeconds: moment().diff(userData.time, 'second'),
          lastActivity: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      };

      return userInfo;
    } else {
      return {};
    }
  };

export default getUserInfo;