import Default from '../components/Default';
import New from '../components/New';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import Settings from '../components/Settings';
import Thread from '../components/Thread';
import Threads from '../components/Threads';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  mobileLayout: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 2px)',
    overflow: 'auto',
    flex: '0 0 auto',
    gap: '1px',
    backgroundColor: theme.colors.white,
  },
  threadsScreen: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 24px)',
    padding: '12px',
    '& > span': {
      fontSize: '24px',
      fontWeight: '600',
    },
  },
  inputWrapper: {
    padding: '0 12px',
    width: 'calc(100% - 24px)',
  },
  rightColumn: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flex: '0 0 calc(80% - 0.5px)',
    maxWidth: 'calc(80% - 0.5px)',
  },
  buttons: {
    display: 'flex',
    gap: '4px',
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.grey[200],
    },
  },
  backToApp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 50px',
    height: '50px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.grey[325],
    '& > button': {
      display: 'flex',
      gap: '6px',
      width: '95%',
      height: '85%',
      textTransform: 'unset',
      color: theme.colors.black,
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  notFound: {
    textAlign: 'center',
  },
}));

type MobileLayoutType = {
  isLoading: boolean;
  isLoadingMessages: boolean;
  isFirstTimeLoaded: boolean;
  setIsLoading: any;
  setIsLoadingMessages: any;
  setIsFirstTimeLoaded: any;
};

const MobileLayout: React.FunctionComponent<MobileLayoutType> = ({ isLoading, isLoadingMessages, isFirstTimeLoaded, setIsLoading, setIsLoadingMessages, setIsFirstTimeLoaded }) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const { tab: tabViewMode, param: getThreadID }: any = useParams();
  const communicationData = useAppSelector((state: any) => state.communication);
  const userData = useAppSelector((state: any) => state.user);

  const userAccess = userData.userAccess;

  const customViewMode = communicationData.viewMode;
  const customThreadID = communicationData.threadID;

  const viewMode = customViewMode ? customViewMode : tabViewMode;
  const currentThreadID = customThreadID ? parseInt(customThreadID) : parseInt(getThreadID);

  return !userAccess.communication ? (
    <NotFound className={classes.notFound} title={t('page_forbidden')} text={t('page_forbidden_detail')}/>
  ) : (
    <div className={classes.mobileLayout}>
      {
        viewMode === "threads" ? (
          <Threads currentThreadID={currentThreadID} isLoading={isLoading} isLoadingMessages={isLoadingMessages} isFirstTimeLoaded={isFirstTimeLoaded} setIsLoading={setIsLoading} setIsFirstTimeLoaded={setIsFirstTimeLoaded}/>
        ) : viewMode === "new" ? (
          <New/>
        ) : viewMode === "thread" ? (
          <Thread threadID={currentThreadID} isLoading={isLoading} isLoadingMessages={isLoadingMessages} isFirstTimeLoaded={isFirstTimeLoaded} setIsLoading={setIsLoading} setIsLoadingMessages={setIsLoadingMessages} setIsFirstTimeLoaded={setIsFirstTimeLoaded}/>
        ) : viewMode === "settings" ? (
          <Settings/>
        ) : (
          <Default/>  
        )
      }
    </div>
  );
};

export default MobileLayout;