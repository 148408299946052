import EmployeesInput from 'src/components/Forms/EmployeesInput';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import moment from 'src/utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import React from 'react';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import WageTable from 'src/components/Tables/WageTable';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  wagesWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  selects: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  select: {
    width: 'fit-content !important',
    minWidth: '250px !important',
    justifyContent: 'center',
  },
}));

const WagesSettings: React.FunctionComponent = () => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const employeesData = userData.userObject.employeeID.filter((employee: any) => userAccessSchools.subscription.includes(getEmployeeData(employee).schoolID)).map((employeeData: any) => { return { employeeID: employeeData.employeeID, schoolID: employeeData.schoolID }; });

  const [state, setState] = useStates({
    curDate: moment(),
    employee: [employeesData[0]],
    curEmployee: employeesData[0],
    isEmployeeSelectOpen: false,
  });

  const handleSetDate = (newDate: any) => {
    setState("curDate", newDate);
  };

  const handleChangeEmployee = (e: any, data: any, value: any) => {
    setState("employee", value);
    setState("curEmployee", value[0]);
    setState("isEmployeeSelectOpen", false);
  };

  return (
    <div className={classes.wagesWrapper}>
      {
        !userAccess.subscription ? (
          <SubscriptionBanner service="wages"/>
        ) : (
          <>
            <div className={classes.selects}>
              <MonthPicker presetDate={moment(state.curDate)} setDate={handleSetDate} mode="spinner"/>
              {
                employeesData.length > 1 ? (
                  <>
                    <EmployeesInput className={classes.select} selectedEmployees={state.employee} setSelectedEmployees={handleChangeEmployee} isDeletable={false} onClick={() => setState("isEmployeeSelectOpen", true)}/>
                    {
                      state.isEmployeeSelectOpen ? (
                        <EmployeesSelect
                          selectedEmployees={state.employee}
                          isInModal={true}
                          isModalOpen={true}
                          defaultEmployees={employeesData}
                          modalTitle="employees"
                          mode="select"
                          modalAllowCancel={false}
                          modalAllowClose={true}
                          isSelectAll={false}
                          isMultipleSelect={false}
                          isAllowOnlyOneEmployeeAtOnce={true}
                          modalOnClose={() => setState("isEmployeeSelectOpen", false)}
                          onClickEmployee={handleChangeEmployee}
                          modalAllowEmployeesCount={false}
                        />
                      ) : null
                    }
                  </>
                ) : null
              }
            </div>
            <WageTable employeeID={state.curEmployee.employeeID} schoolID={state.curEmployee.schoolID} date={moment(state.curDate).format("YYYY-MM")}/>
          </> 
        )
      }
    </div>
  );
};

export default WagesSettings;