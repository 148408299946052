import Label from 'src/components/Forms/Label';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { setAppData } from 'src/utils/useApp';
import { setCookies } from 'src/store/actions/layout.actions';
import { setCookiesModal } from 'src/store/actions/modals.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  cookiesWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cookies: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  optionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const CookiesSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickCookiesSettings = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCookiesModal(settings));
  };
  
  const handleDeleteCookiesSettings = () => {
    setAppData({cookies: []});
    dispatch(setCookies([]));
    navigate("/settings/profile");
  };

  return (
    <div className={classes.cookiesWrapper}>
      <div className={classes.cookies}>
        <div className={classes.optionWrapper}>
          <Label>{t('cookies_settings')}</Label>
          <div className={classes.buttons}>
            <NormalButton onClick={handleClickCookiesSettings}>{t('cookies_click_for_settings')}</NormalButton>
            <NormalButton onClick={handleDeleteCookiesSettings} buttonType='clear'>{t('cookies_reset_settings')}</NormalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesSettings;