import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Button from '@mui/material/Button';
import React from 'react';
import RenderMenuItemSub from './';
import SVG from '../../../Images/SvgRenderer';
import { createCypressDataText, isCypress } from '../../../../utils/useCypress';
import { createUseStyles } from 'react-jss';
import { getSchoolsSettings } from 'src/utils/useFunctions';
import { getUserHomepage } from 'src/utils/useUser';
import { setIsOpenMoreMenu, setIsOpenSubMenu } from 'src/store/actions/layout.actions';
import { setSchoolUrlsModal, setWarningModal } from '../../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  isStandalone: boolean;
};

const useStyles = createUseStyles((theme: any) => ({
  submenu: {
    position: 'fixed',
    top: '60px',
    left: '0',
    right: '0',
    bottom: (props: Props) => {
      if(props.isStandalone) return '66px';
      else return '56px';
    },
    backgroundColor: theme.colors.white,
    display: 'none',
    zIndex: theme.zIndex.bottomBarMenu,
    width: '100%',
    flexDirection: 'column',
    '& > span': {
      fontSize: '24px',
      fontWeight: '900',
      color: theme.colors.grey[800],
      padding: '12px 26px',
    },
    '&.active': {
      display: 'flex',
    },
  },
  submenuItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    overflowY: 'auto',
    width: 'calc(100% - 52px)',
    height: 'calc(100% - 24px)',
    maxHeight: 'calc(100% - 24px)',
    padding: '12px 26px 24px 26px',
  },
  submenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  moremenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type RenderMenuItemType = {
  node: any;
  isMore: any;
  parent: any;
};

const RenderMenuItem: React.FunctionComponent<RenderMenuItemType> = ({ node, isMore, parent}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  
  const activeClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === false).length;
  const userAccess = userData.userAccess;

  const isOpenSubMenu = layoutData.isOpenSubMenu;
  const isOpenMoreMenu = layoutData.isOpenMoreMenu;
  const schoolSettings = userData.schoolSettings;

  const classes = useStyles({
    isStandalone: browserData.isStandalone,
  });
  
  const handleOnClick = () => {
    if(node.validations) {
      if(node.validations.includes('attendance')) {
        if(activeClasses === 0) {
          const settings = {
            isOpen: true,
            title: t('function_unavailable'),
            content: t('function_unavailable_attendance'),
          };
          dispatch(setWarningModal(settings));
          return;
        }
      } else if(node.validations.includes('bakalariUrl')) {
        const bakalariSchoolUrls = getSchoolsSettings('bakalariLoginUrl', schoolSettings).map((item: any) => { return { schoolID: item.schoolID, url: item.item }; });
        if(bakalariSchoolUrls.length === 1) {
          window.open(bakalariSchoolUrls[0].url);
        } else {
          const settings = {
            isOpen: true,
            urls: bakalariSchoolUrls,
            title: t('bakalari_login'),
          };
          dispatch(setSchoolUrlsModal(settings))
        }
        return;
      } else if(node.validations.includes('mealmenuUrl')) {
        const mealmenuSchoolUrls = getSchoolsSettings('mealmenuUrl', schoolSettings).map((item: any) => { return { schoolID: item.schoolID, url: item.item }; });
        if(mealmenuSchoolUrls.length === 1) {
          window.open(mealmenuSchoolUrls[0].url);
        } else {
          const settings = {
            isOpen: true,
            urls: mealmenuSchoolUrls,
            title: t('mealmenu_login'),
          };
          dispatch(setSchoolUrlsModal(settings))
        }
        return;
      } else if(node.validations.includes('back')) {
        navigate(getUserHomepage(dispatch, dataData, layoutData, userData));
        return;
      }
    }
    if(node.onClick) {
      node.onClick();
    }
    if(!Array.isArray(node.submenus)) {
      navigate(node.to);
    } else {
      dispatch(setIsOpenSubMenu(node.to));
      dispatch(setIsOpenMoreMenu(false));
    }       
  };    
  
  return (
    <>
      {
        isMore ? (
          <Button className={classes.moremenuItem} onClick={handleOnClick}>
            {((node.requirements && node.requirements.includes("premium") && !userData.membership.active) || ((node.requirements && node.requirements.includes("subscription") && !userAccess.subscription) ? (<SVG src="lock-outlined"/>) : null)) ? (<SVG src="lock-outlined"/>) : (<SVG src={node.icon}/>)}
            <span>{t(node.title)}</span>
            <span className={classes.chevron}>
              <SVG src="chevron-right"/>
            </span> 
          </Button>
        ) : parent === null ? (
          <>
            <BottomNavigationAction label={t(`${node.title}`)} icon={((node.requirements && node.requirements.includes("premium") && !userData.membership.active) || ((node.requirements && node.requirements.includes("subscription") && !userAccess.subscription) ? (<SVG src="lock-outlined"/>) : null)) ? (<SVG src="lock-outlined"/>) : (<SVG src={node.icon}/>)} className={`${((!isOpenMoreMenu && !isOpenSubMenu && (location.pathname === node.to || location.pathname.includes(node.to))) || isOpenSubMenu === node.to) ? 'active' : 'inactive'} ${((node.requirements && node.requirements.includes("premium") && !userData.membership.active) || (node.requirements && node.requirements.includes("subscription") && !userAccess.subscription)) ? 'locked' : null} ${!node.isVisible ? 'not-visible' : ''}`} onClick={handleOnClick} data-cy={isCypress() ? "bottomBarMenu" + createCypressDataText(node.title) : null}/>
            {
              Array.isArray(node.submenus) ? (
                <div className={`${classes.submenu} ${isOpenSubMenu === node.to && 'active'}`}>
                  <span>{t(node.title)}</span>
                  <div className={classes.submenuItems}>
                  {
                    Array.isArray(node.submenus) && node.submenus.map((subnode: any, key: any) => (
                      <RenderMenuItemSub node={subnode} isMore={false} parent={node.to} key={`k_${key}`}/>
                    ))
                  }
                  </div>
                </div>
              ) : null
            }
          </>  
        ) : (
          <Button className={classes.submenuItem} onClick={handleOnClick}>
            {((node.requirements && node.requirements.includes("premium") && !userData.membership.active) || ((node.requirements && node.requirements.includes("subscription") && !userAccess.subscription) ? (<SVG src="lock-outlined"/>) : null)) ? (<SVG src="lock-outlined"/>) : (<SVG src={node.icon}/>)}
            <span>{t(node.title)}</span>
            <span className={classes.chevron}>
              <SVG src="chevron-right"/>
            </span> 
          </Button>
        )
      }
    </>
  );
}

export default RenderMenuItem;