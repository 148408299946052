import HttpService from '../http.service';

const listComments = (ID: number, type: 'post' | 'thread', page?: any, limit?: any) => {
  return HttpService.get(`comment?${type}ID=${ID}${page ? `&page=${page}`: ""}${limit ? `&limit=${limit}`: ""}`, {}, {});
};

const createComment = (payload: any) => {
  return HttpService.post(`comment`, payload, {}, {});
};

const updateComment = (commentID: number, payload: any) => {
  return HttpService.put(`comment/${commentID}`, payload, {}, {});
};

const deleteComment = (commentID: number) => {
  return HttpService.remove(`comment/${commentID}`, {});
};

export {
  listComments,
  createComment,
  updateComment,
  deleteComment,
};