import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import CloseButton from 'src/components/Buttons/CloseButton';
import Currency from 'react-currency-formatter';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useMemo } from 'react';
import Select from 'src/components/Forms/Select';
import SVG from 'src/components/Images/SvgRenderer';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getDayName, isKey } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setChildCardModal, setClubDetailModal, setConfirmModal } from '../../../store/actions/modals.actions';
import { setClubItems } from 'src/store/actions/club.actions';
import { setFilterParams } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& table': {
      width: '100%',
      '& > thead': {
        '& > tr': {
          '& > th': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'flex-end',
    marginTop: '32px',
    gap: '10px',
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.tabsWrapper,
    '& > div': {
      width: '100%',
      backgroundColor: theme.colors.white,
    },
    paddingBottom: '16px',
  },
  itemName: {
    fontWeight: 'bold',
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
  },
  limitInput: {
    width: '100px',
  },
  remove: {
    cursor: 'pointer',
    color: theme.colors.primaryBlue[500],
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(238,238,238,0.5)',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td': {
          fontSize: '14px',
          '&:first-of-type': {
            fontWeight: '500',
            whiteSpace: 'nowrap',
          },
          '&:last-of-type': {
            paddingLeft: '8px',
            maxWidth: 'calc(100% - 8px)',
            textAlign: 'right',
          },
          '& a': {
            maxWidth: '100%',
            color: theme.colors.primaryBlue[500],
            cursor: 'pointer',
            transition: 'color 0.25s',
            fontWeight: 'bold',
            textDecoration: 'none',
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
            '&:hover': {
              color: theme.colors.primaryBlue[600],
            },
          },
          '& > small': {
            fontSize: '12px',
          },
        },
      },
    },
  },
  applicationsTables: {
    '& > thead': {
      '& > tr': {
        '& > th': {
          whiteSpace: 'nowrap',
        },
      },
    },
    '& > tbody': {
      '& > tr': {
        '& > td': {
          width: '25%',
          fontSize: '14px',
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '& > span': {
              display: 'block',
              maxWidth: '10vw',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          '& > small': {
            fontSize: '12px',
          },
        },
      },
    },
  },
  cancelButton: {
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    '& > svg': {
      color: '#5AB8FF',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  childImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    minWidth: '24px',
    '& > div': {
      width: '24px',
      height: '24px',
      borderRadius: '100%',
      position: 'relative',
    },
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  childWrapper: {
    cursor: 'pointer',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: '600',
    },
  },
  paymentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > button': {
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    }
  },
  paymentInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ClubDetailModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const clubsData = useAppSelector((state: any) => state.club);
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const clubsService = useAppSelector((state: any) => state.services).clubsService;

  const isValid = modalsData.clubDetailModal.clubID === null ? false : true;
  const clubID = isValid ? modalsData.clubDetailModal.clubID : null;
  const isClubExist = clubsData.items.filter((item: any) => item.clubID === clubID).length === 1;
  const clubTempData = useMemo(() => { return (isValid ? (isClubExist ? clubsData.items.find((item: any) => item.clubID === clubID) : {}) : {}); }, [clubID, clubsData.items, isClubExist, isValid]);
  const clubData = useMemo(() => { return {...clubTempData, pricePerLesson: clubTempData.price / clubTempData.countLessons}; }, [clubTempData]);

  const currencyID = clubData.currencyID;
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
  
  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const clubChildren = useMemo(() => { return (Object.keys(clubData).length > 0 ? (clubData.children !== undefined ? (clubData.children.length > 0 ? getUserRole(userData.userObject.roleType) === "parent" ? clubData.children.filter((item: any) => item.state !== 3 && getChildData(item.childID).length !== 0) : clubData.children.filter((item: any) => getChildData(item.childID).length !== 0) : []) : []) : []); }, [clubData, getChildData, userData.userObject.roleType]);

  const applicationChildren = clubChildren.map((item: any) => { return item.childID; });

  const availableChildren = dataData.children.map((item: any) => { 
    if(applicationChildren.indexOf(item.childID) === -1 && item.schoolsID.indexOf(clubData.schoolID) !== -1) {
      return { ...item, image: item.photo.thumbLink};
    } else {
      return null;
    }
  }).filter((item: any) => item !== null);

  const [state, setState] = useStates({
    isLoading: false,
    selectedChildren: null,
    viewMode: 'basic',
  });

  const tabsItems: any = [
    {
      name: 'club_basic',
      value: 'basic',
      isEnabled: true,
    },
    {
      name: 'club_applications',
      value: 'applications',
      badge: clubChildren.length,
      badgeType: 'primary',
      isEnabled: true,
    },
  ];

  const classes = useStyles();

  const setViewMode = useCallback((value: any) => {
    setState("viewMode", value);
  }, [setState]);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      clubID: false,
    };
    dispatch(setClubDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const setSelectedChildren = (value: any) => {
    setState("selectedChildren", value);
  };

  const handleCreateApplication = () => {
    const settings = {
      clubID: clubData.clubID,
      childID: state.selectedChildren.childID
    };
    clubsService && clubsService.createClubApplication(settings).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          createNotification(t("club_application_created"), "success");
          const newClubs = clubsData.items.map((item: any) => {
            if(item.clubID === clubID) {
              return result.data;
            } else {
              return item;
            }
          }).filter((item: any) => item !== null);
          dispatch(setClubItems(newClubs));
          setState("selectedChildren", null);
        } else {
          createNotification(t("club_application_not_created"), "error");
        }
      } else {
        createNotification(t("club_application_not_created"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("club_application_not_created"), "error");
    });
  };

  const handleClickDelete = (clubApplicationID: any, childID: any) => {
    const onAccept = () => {
      const settings = {
        clubID: clubID,
        childID: childID
      };
      clubsService && clubsService.deleteClubApplication(clubApplicationID, settings).then((result: any) => {
        if(result) {
          if(result.status === 200) {
            createNotification(t("club_application_canceled"), "success");
            const newClubs = clubsData.items.map((item: any) => {
              if(item.clubID === clubID) {
                return result.data;
              } else {
                return item;
              }
            }).filter((item: any) => item !== null);
            dispatch(setClubItems(newClubs));
          } else {
            createNotification(t("club_application_not_canceled"), "error");
          }
        } else {
          createNotification(t("club_application_not_canceled"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("club_application_not_canceled"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('club_application_cancel'),
      content: t('club_application_cancel_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleOpenChildDetail = (childID: any) => {
    const settings = {
      isOpen: true,
      childID: childID,
    };
    dispatch(setChildCardModal(settings));
  };

  const handlePaymentID = (e: any, paymentID: any) => {
    e.stopPropagation();
    navigate('/finance');
    dispatch(setFilterParams({paymentID: paymentID}));
    setTimeout(() => {
      dispatch(setFilterParams({paymentID: paymentID}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  useEffect(() => {
    if(clubChildren.length === 0 && state.viewMode !== 'basic') {
      setState("viewMode", "basic");
    }
  }, [clubChildren, setState, state.viewMode], [clubChildren])
   
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "shopOrderDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('club_detail')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            state.isLoading ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            ) : (
              <>
                {
                  Object.keys(clubData).length !== 0 ? (
                    <>
                      {
                        clubChildren.length > 0 ? (
                          <div className={classes.tabsWrapper}>
                            <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
                          </div>
                        ) : null
                      }
                      {
                        state.viewMode === 'basic' ? (
                          <table className={classes.table}>
                            <tbody>
                              <tr>
                                <td>
                                  {t('club_name')}:
                                </td>
                                <td>
                                  {clubData.name}
                                </td>
                              </tr>
                              {
                                clubData.description ? (
                                  <tr>
                                    <td>
                                      {t('club_description')}:
                                    </td>
                                    <td>
                                    <Clamp
                                      lines={4}
                                      maxLines={100}
                                      withToggle
                                      showMoreElement={({ toggle }: any) => (
                                        <div className={classes.showToggleWrapper}>
                                          <span className={classes.showToggle} onClick={toggle}>
                                            {t('show_more')}
                                          </span>
                                        </div>
                                      )}
                                      showLessElement={({ toggle }: any) => (
                                        <div className={classes.showToggleWrapper}>
                                          <span className={classes.showToggle} onClick={toggle}>
                                            {t('show_less')}
                                          </span>
                                        </div>
                                      )}
                                    >
                                      {clubData.description}
                                    </Clamp>
                                    </td>
                                  </tr>
                                ) : null
                              }
                              <tr>
                                <td>
                                  {t('club_school')}:
                                </td>
                                <td>
                                  {getSchoolData(clubData.schoolID).name}
                                </td>
                              </tr>
                              {
                                clubData.teacherName ? (
                                  <tr>
                                    <td>
                                      {t('club_teacher')}:
                                    </td>
                                    <td>
                                      {clubData.teacherName}
                                    </td>
                                  </tr>
                                ) : null
                              }
                              <tr>
                                <td>
                                  {t('club_place')}:
                                </td>
                                <td>
                                  {clubData.place}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('club_price_total')}:
                                </td>
                                <td>
                                  <Currency quantity={parseFloat(clubData.price)} currency={getCurrency.iso}/>
                                  &nbsp;
                                  <small>(<Currency quantity={parseFloat(clubData.pricePerLesson)} currency={getCurrency.iso}/>&nbsp;{t('club_price_per_lesson').toLowerCase()})</small>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('club_lessons_count')}:
                                </td>
                                <td>
                                  {clubData.countLessons}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('club_day')}:
                                </td>
                                <td>
                                  {t(getDayName(clubData.dayInWeek))}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('club_time_from_to')}:
                                </td>
                                <td>
                                  {clubData.timeFrom}
                                  &nbsp;-&nbsp;
                                  {clubData.timeTo}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {t('club_capacity')}:
                                </td>
                                <td>
                                  {t('club_capacity_info_min', {min: clubData.childrenMin, minChildText: clubData.childrenMin === 1 ? (t('child')).toLowerCase() : (clubData.childrenMin === 2 || clubData.childrenMin === 3 || clubData.childrenMin === 4) ? (t('children')).toLowerCase() : (t('children_more')).toLowerCase()})}
                                  &nbsp;
                                  {clubData.childrenMax > 0 ? (t('club_capacity_info_max', {max: clubData.childrenMax, maxChildText: clubData.childrenMax === 1 ? (t('child')).toLowerCase() : (clubData.childrenMax === 2 || clubData.childrenMax === 3 || clubData.childrenMax === 4) ? (t('children')).toLowerCase() : (t('children_more')).toLowerCase()})) : ''}
                                </td>
                              </tr>
                              {
                                getUserRole(userData.userObject.roleType) !== "parent" ? (
                                  <tr>
                                    <td>
                                      {t('club_visible_for_parent')}:
                                    </td>
                                    <td>
                                      {clubData.visibleForParent ? t('yes') : t('no')}
                                    </td>
                                  </tr>
                                ) : null
                              }
                              {
                                getUserRole(userData.userObject.roleType) !== "parent" ? (
                                  <tr>
                                    <td>
                                      {t('club_enable_login')}:
                                    </td>
                                    <td>
                                      {clubData.enableLogin ? t('yes') : t('no')}
                                    </td>
                                  </tr>
                                ) : null
                              }
                            </tbody>
                          </table>
                        ) : state.viewMode === 'applications' ? (
                          <table className={classes.applicationsTables}>
                            <thead>
                              <tr>
                                <th>
                                  {t('club_child')}
                                </th>
                                <th>
                                  {t('club_application_status')}
                                </th>
                                <th>
                                  {t('club_application_price')}
                                </th>
                                <th>
                                  {t('club_application_lessons_count')}
                                </th>
                                {
                                  getUserRole(userData.userObject.roleType) === "parent" ? (
                                    <th/>
                                  ) : null
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                clubChildren.map((item: any, key: any) => {
                                  const itemData = {...item, pricePerLesson: item.price / item.countLessons};
                                  return getChildData(itemData.childID).length !== 0 ? (
                                    <tr key={`k_${key}`}>
                                      <td>
                                        <div className={classes.childWrapper} onClick={() => handleOpenChildDetail(itemData.childID)}>
                                          <AuthenticatedImage className={classes.childImage} thumbLink={getChildData(itemData.childID).photo.thumbLink}/>
                                          <span>{getChildData(itemData.childID).displayName}</span>
                                        </div>
                                      </td>
                                      <td>
                                        {itemData.stateText}
                                      </td>
                                      <td>
                                        <div className={classes.paymentWrapper}>
                                          <div className={classes.paymentInfoWrapper}>
                                            <Currency quantity={parseFloat(itemData.price)} currency={getCurrency.iso}/>
                                            <small>(<Currency quantity={parseFloat(itemData.pricePerLesson)} currency={getCurrency.iso}/>&nbsp;{t('club_price_per_lesson').toLowerCase()})</small>
                                          </div>
                                          {
                                             getUserRole(userData.userObject.roleType) === "parent" && itemData.paymentID ? (
                                              <IconButton tooltip={t('payment_detail')} onClick={(e: any) => handlePaymentID(e, itemData.paymentID)}>
                                                <SVG src="invoice"/>
                                              </IconButton>
                                             ) : null
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        {itemData.countLessons}
                                      </td>
                                      {
                                        getUserRole(userData.userObject.roleType) === "parent" ? (
                                          <>
                                            {
                                              itemData.state === 1 ? (
                                                <td>
                                                  <IconButton className={classes.cancelButton} onClick={() => handleClickDelete(itemData.clubApplicationID, itemData.childID)}>
                                                    <SVG src="close"/>
                                                  </IconButton>
                                                </td>
                                              ) : (
                                                <td/>
                                              )
                                            }
                                          </>
                                        ) : null
                                      }
                                    </tr>
                                  ) : null;
                                })
                              }
                            </tbody>
                          </table>
                        ) : null
                      }
                    </>
                  ) : null
                }
              </>
            )
          }
          {
            (!state.isLoading && getUserRole(userData.userObject.roleType) === "parent" && clubData.enableLogin && state.viewMode === 'basic' && availableChildren.length > 0) ? (
              <div className={classes.footer}>
                <Select label={t('club_application_create')} inputLabel={t('select_child')} selected={state.selectedChildren} setSelected={setSelectedChildren} items={availableChildren} defaultTitle={"displayName"}/>
                <NormalButton onClick={handleCreateApplication} disabled={state.selectedChildren === null} dataCy="createApplicationButton">
                  {t('club_create_application')}
                </NormalButton>
              </div>
            ) : null
          }
        </div>
      </div>
    </Modal>
  );
};

export default ClubDetailModal;