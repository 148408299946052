import React from 'react';
import RenderEmployee from '../RenderEmployee';
import theme from '../../../../ui/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';

interface Props {
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  employeesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: 'calc(100% - 48px)',
    padding: '24px 24px 14px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 12px 2px 12px',
      width: 'calc(100% - 24px)',
    },
  },
  employeesList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: '5px',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    padding: '13px 18px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      maxWidth: 'calc(100% - 18px)',
      padding: '0px 9px 13px',
    },
  },
  employeeWrapper: {
    width: (props: Props) => (props.size + 30) + 'px',
    flex: (props: Props) => '0 0 ' + (props.size + 30) + 'px',  
  },
}));

type RenderEmployeesType = {
  state: any;
  setState: any;
  schoolData?: any;
};

const RenderEmployees: React.FunctionComponent<RenderEmployeesType> = ({
  state,
  setState,
  schoolData,
}) => {
  
  const sizes = {
    xs: 32,
    sm: 38,
    md: 40,
    lg: 48,
    xl: 54,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? sizes.xl : isLg ? sizes.lg : isMd ? sizes.md : isSm ? sizes.sm : isXs ? sizes.xs : 30;

  const classes = useStyles({
    size: size,
  });

  const schoolID = schoolData.schoolID;
  const employeesList = state.functions.getEmployeesBySchoolID(schoolID);

  return (
    <div className={classes.employeesWrapper} data-cy={isCypress() ? 'employeesWrapper' : null}>
      <div className={`${classes.employeesList} ${state.customClasses ? (state.customClasses.employeesList ? state.customClasses.employeesList : "") : ""}`} data-cy={isCypress() ? 'employeesContainer' : null}>
        {
          employeesList.map((theEmployee: any, key: any) => (
            <div className={classes.employeeWrapper} key={`k_${key}`}>
              <RenderEmployee state={state} setState={setState} schoolData={schoolData} employeeData={theEmployee} size={size}/>   
            </div>  
          ))
        }
      </div>
    </div>
  );
}

export default RenderEmployees;