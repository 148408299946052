import * as MainService from '../../../../../services/main.service';
import * as UserService from '../../../../../services/user.service';
import config from 'src/constants/config';
import Flag from 'react-world-flags';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { languages } from 'src/constants/languages';
import { setAppData } from 'src/utils/useApp';
import { setConfiguration } from 'src/store/actions/configuration.actions';
import { setIsFailed, setIsLanguageLoaded } from 'src/store/actions/loading.actions';
import { setLanguage } from 'src/store/actions/language.actions';
import { setMembership, setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  select: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '96px',
    gap: '16px',
    position: 'relative',
    '& > span': {
      fontSize: '24px',
      width: '80%',
      fontWeight: '600',
      marginBottom: '16px',
      color: theme.colors.black,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '80%',
    marginTop: '16px',
  },
  languageListWrapper: {
    maxHeight: 'calc(100% - 67px)',
    height: 'unset',
  },
  languageList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '80%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  languageButton: {
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
    padding: '8px',
    width: '24px',
    height: '24px',
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  button: {
    minWidth: '50%',
    width: 'fit-content',
    maxWidth: '100%',
    '& > span': {
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '6px',
      width: '100%',
      '& > img': {
        marginRight: 'auto',
      },
      '& > small': {
        fontSize: '80%',
        lineHeight: '80%',
      },
    },
    '&.active': {
      backgroundColor: theme.colors.primaryBlue[500],
      '& > span': {
        color: theme.colors.alwaysWhite,
      },
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  iconPlaceholder: {
    position: 'relative',
    width: '50%',
    paddingTop: '50%',
    '& > svg': {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      color: theme.colors.primaryBlue[500],
    },
  },
  loading: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type SectionType = {
  updateSection: any;
  handleNext: any;
};

const Language: React.FunctionComponent<SectionType> = ({ updateSection, handleNext }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const section = "language";

  const [state, setState] = useStates({
    canChange: true,
    canContinue: true,
  });

  const handleChangeLan = async (e: any, language: any) => {
    e.stopPropagation();
    e.preventDefault();
    setState("canChange", false);
    setState("canContinue", false);
    dispatch(setLanguage(language));  
    const resultLan = await saveUserSettings(dispatch, userData, "customizations", ["language"], language);
    if(resultLan) {
      MainService && MainService.getConfiguration(language.toLowerCase()).then((result: any) => {
        if(result) {
          const configurationData = result.data;
          UserService && UserService.getUserData(language.toLowerCase()).then((result: any) => {
            const userMembershipContent = result.data.user.membership;
            setAppData({language: language});
            dispatch(setConfiguration(configurationData));
            dispatch(setMembership(userMembershipContent));
            setState("canChange", true);
            setState("canContinue", true);
          }).catch(() => {
            dispatch(setIsLanguageLoaded(false));
            dispatch(setIsFailed("dictionaries")); 
            setState("canChange", true); 
            setState("canContinue", false);
          });
        }
      }).catch(() => {
        dispatch(setIsLanguageLoaded(false));
        dispatch(setIsFailed("dictionaries"));  
        setState("canChange", true);
        setState("canContinue", false);
      });  
    } else {
      dispatch(setIsLanguageLoaded(false));
      dispatch(setIsFailed("dictionaries")); 
      setState("canChange", true); 
      setState("canContinue", false);
    }
  };

  const handleSave = async () => {
    const value = languageData.language;
    const oldValue = getUserSetting(userData.userSettings, "setup", [section]);
    const newValue = updateUserSetting(userData.userSettings, "setup", [section], value);
    updateSection(section, false);
    dispatch(setUserSettings(newValue));
    const result = await saveUserSettings(dispatch, userData, "setup", [section], value);
    if(result) {
      return true;
    } else {
      const updateSettings = updateUserSetting(userData.userSettings, "setup", [section], oldValue);
      updateSection(section, true);
      dispatch(setUserSettings(updateSettings));
      return false;
    }
  };
  
  const handleContinue = async () => {
    if(state.canContinue) {
      setState("canContinue", false);
      const isSaved = await handleSave();
      if(isSaved) {
        handleNext();
      } else {
        createNotification(t('something_went_wrong'), 'error');
        setState("canContinue", true);
      }
    }
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.select}>
          <span>{t('setup_select_language')}</span>
          <ScrollMenu classNameWrapper={classes.languageListWrapper} className={classes.languageList} classNameButton={classes.languageButton} layout="vertical" nativeMobileScroll={false} isArrowFloat={true}>
            {
              languages.map((language: any, key: any) => (
                <NormalButton
                  className={`${classes.button} ${language.acronym === (languageData.language || 'EN') ? "active" : null}`}
                  buttonType={language.acronym === (languageData.language || 'EN') ? "primary" : "secondary"}
                  onClick={(e: any) => handleChangeLan(e, language.acronym)}
                  key={`k_${key}`}
                  disabled={language.acronym === (languageData.language || 'EN') || !state.canChange}
                  dataCy={`setupLanguage${language.acronym}Button`}
                >
                  <Flag code={(language.flag || 'us')} height="16" />
                  {language.acronym === (languageData.language || 'EN') ? language.nativeLan : language.lan} <small>({language.acronym === (languageData.language || 'EN') ? language.lan : language.nativeLan})</small>
                </NormalButton>
              ))
            }
            {
              !state.canContinue ? (
                <div className={classes.loading}>
                  <div className={classes.spinner}>
                    <CircularProgress size={128}/>
                  </div>
                </div>
              ) : null
            }
          </ScrollMenu>
          <div className={classes.buttonWrapper}>
            <NormalButton buttonType="primary" onClick={handleContinue} disabled={!state.canContinue}>
              {t('setup_continue')}
            </NormalButton>
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.icon}>
          <div className={classes.iconPlaceholder}>
            <SVG src={section}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;