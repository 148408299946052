import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import useBreakpoint from 'src/utils/useBreakpoint';
import { getSideContentSize } from 'src/utils/useFunctions';
import { resetCommunicationList } from 'src/store/actions/communication.actions';
import { setCommunicationDrawer } from 'src/store/actions/drawers.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const CommunicationButton: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const communicationData = useAppSelector((state: any) => state.communication);
  const drawersData = useAppSelector((state: any) => state.drawers);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const isOpen = drawersData.communicationDrawer.isOpen;
  const sideContent = layoutData.sideContent;
  const isMobile = layouts[breakpoint] === "mobile";

  const userAccess = userData.userAccess;
  
  const isSideContentVisible = getSideContentSize(breakpoint) !== 0;

  const handleCommunicationClick = () => {
    if(isMobile) {
      navigate("/communication");
    } else {
      if(isOpen) {
        setTimeout(() => {
          dispatch(resetCommunicationList());
        }, theme.transitions.duration.leavingScreen);
      } else {
        dispatch(resetCommunicationList());
      }
      const settings = {
        isOpen: !isOpen,
        position: 'right',
      };
      dispatch(setCommunicationDrawer(settings));
    }
  };

  const isUnreadThreads = communicationData.notificationsCount > 0;

  return (
    <>
      {
        ((sideContent !== "communication" || (sideContent === "communication" && !isSideContentVisible)) && userAccess.communication) ? (
          <TopBarButton icon={<SVG src="communication"/>} onClick={handleCommunicationClick} dataCy="topBarButtonCommunication" tooltip={t('communication')}>
            {isUnreadThreads ? (<p/>) : null}
          </TopBarButton>
        ) : null
      }
    </>
  );
}

export default CommunicationButton;