import * as DirectorDataService from '../../../../services/director/data.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import { getUserRole } from 'src/utils/useUser';
import { onlyUnique } from 'src/utils/useFunctions';
import { setDataChildren, setDataChildrenID } from 'src/store/actions/data.actions';

const updateChildren = (children: any, classes: any) => {
  const tempChildren = children.map((theChild: any) => {
    if(classes.length !== 0) {
      const isInArchivedClass = theChild.classesID.some((classID: any) => {
        const isClass = classes.filter((findClass: any) => findClass.classID === classID).length === 1;
        if(isClass) {
          const foundClass = classes.find((findClass: any) => findClass.classID === classID);
          return foundClass.isArchived;
        } else {
          return false;
        }
      });
      const isInAnyActiveClass = theChild.classesID.some((classID: any) => {
        const isClass = classes.filter((findClass: any) => findClass.classID === classID).length === 1;
        if(isClass) {
          const foundClass = classes.find((findClass: any) => findClass.classID === classID);
          return foundClass.active && !foundClass.isArchived;
        } else {
          return false;
        }
      });
      return { ...theChild, isInArchivedClass: isInArchivedClass, isInAnyActiveClass: isInAnyActiveClass };
    } else {
      return { ...theChild, isInArchivedClass: false, isInAnyActiveClass: true };
    }
  });
  return tempChildren;
};

const loadChildren = async (dispatch: any, userData: any, classes: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") {
    try {
      const result: any = await ParentDataService.listChildren();
      if(result && result.data && result.data.children && result.data.children.length !== 0) {
        const tempChildren = result.data.children.map((item: any) => { if(classes.length === 0) { return { ...item, schoolID: item.schoolsID[0], classID: [], classesID: [] }; } else { const childClasses = classes.filter((theClass: any) => theClass.childrenID.includes(item.childID)).map((item: any) => { return item.classID; }).flat(); return { ...item, schoolID: item.schoolsID[0], classID: childClasses, classesID: [].concat(item.classID, childClasses).filter(onlyUnique) }; }}).filter((item: any) => classes.length !== 0 ? (item.classID.length !== 0 && item.classesID.length !== 0) : item !== null);
        tempChildren.sort((a: any, b: any) => {
          const nameA = a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          const nameB = b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if(nameA === nameB) return a.childID - b.childID;
          return nameA > nameB ? 1 : -1;
        });
        const tempChildrenID = tempChildren.map((item: any) => { return item.childID; }).filter(onlyUnique);
        const newChildren = updateChildren(tempChildren, classes);
        dispatch(setDataChildren(newChildren));
        dispatch(setDataChildrenID(tempChildrenID));
        return {children: newChildren, childrenID: tempChildrenID};
      } else {
        return true;
      }
    } catch {
      return false;  
    };    
  } else if(getUserRole(userData.userObject.roleType) === "teacher") {
    try {
      const result: any = await TeacherDataService.listChildren();
      if(result && result.data && result.data.children && result.data.children.length !== 0) {
        const tempChildren = result.data.children.map((item: any) => { if(classes.length === 0) { return { ...item, schoolID: item.schoolsID[0], classID: [], classesID: [] }; } else { const childClasses = classes.filter((theClass: any) => theClass.childrenID.includes(item.childID)).map((item: any) => { return item.classID; }).flat(); return { ...item, schoolID: item.schoolsID[0], classID: childClasses, classesID: [].concat(item.classID, childClasses).filter(onlyUnique) }; }}).filter((item: any) => classes.length !== 0 ? (item.classID.length !== 0 && item.classesID.length !== 0) : item !== null);
        tempChildren.sort((a: any, b: any) => {
          const nameA = a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          const nameB = b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if(nameA === nameB) return a.childID - b.childID;
          return nameA > nameB ? 1 : -1;
        });
        const tempChildrenID = tempChildren.map((item: any) => { return item.childID; }).filter(onlyUnique);
        const newChildren = updateChildren(tempChildren, classes);
        dispatch(setDataChildren(newChildren));
        dispatch(setDataChildrenID(tempChildrenID));
        return {children: newChildren, childrenID: tempChildrenID};
      } else {
        return true;
      }
    } catch {
      return false;  
    };    
  } else if(getUserRole(userData.userObject.roleType) === "director") {
    try {
      const result: any = await DirectorDataService.listChildren();
      if(result && result.data && result.data.children && result.data.children.length !== 0) {
        const tempChildren = result.data.children.map((item: any) => { if(classes.length === 0) { return { ...item, schoolID: item.schoolsID[0], classID: [], classesID: [] }; } else { const childClasses = classes.filter((theClass: any) => theClass.childrenID.includes(item.childID)).map((item: any) => { return item.classID; }).flat(); return { ...item, schoolID: item.schoolsID[0], classID: childClasses, classesID: [].concat(item.classID, childClasses).filter(onlyUnique) }; }}).filter((item: any) => classes.length !== 0 ? (item.classID.length !== 0 && item.classesID.length !== 0) : item !== null);
        tempChildren.sort((a: any, b: any) => {
          const nameA = a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          const nameB = b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
          if(nameA === nameB) return a.childID - b.childID;
          return nameA > nameB ? 1 : -1;
        });
        const tempChildrenID = tempChildren.map((item: any) => { return item.childID; }).filter(onlyUnique);
        const newChildren = updateChildren(tempChildren, classes);
        dispatch(setDataChildren(newChildren));
        dispatch(setDataChildrenID(tempChildrenID));
        return {children: newChildren, childrenID: tempChildrenID};
      } else {
        return true;
      }
    } catch {
      return false;  
    };    
  } 
};

export default loadChildren;