import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import config from '../../../constants/config';
import DateFormat from '../../../utils/dateFormat';
import DatePicker from '../../DatePickers/Substitutions';
import htmlParse from 'html-react-parser';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import RadioGroup from '../../Forms/RadioGroup';
import React, { useCallback, useRef } from 'react';
import SVG from '../../Images/SvgRenderer';
import { addSubstitutionsList, updateSubstitutionsList } from 'src/store/actions/substitutions.actions';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum, isKey } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setSubstitutionsHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import ClassesSelect from 'src/components/Selects/ClassesSelect';
import ClassesInput from 'src/components/Forms/ClassesInput';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  text: {
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: "20px 0 40px 0",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    height: '100%',
  },
  footer: {
    display: "flex",
    gap: '15px',
    padding: "20px",
    justifyContent: "flex-end",
    flexWrap: 'wrap',
    width: 'calc(100% - 40px)',
    '& > button': {
      width: 'fit-content',
    },
  },
  items: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '10px',
    },
  },
  item: {
    display: 'flex',
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    flexDirection: 'column',
    '& > p': {
      fontSize: '14px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  childrenCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    marginLeft: "10px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '16px',
    }
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '50px',
      minHeight: '50px',
      width: '50px',
      height: '50px',
      maxWidth: '50px',
      maxHeight: '50px',
    },
    '& > p': {
      fontSize: '14px',
      marginLeft: '16px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  nickname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  timezones: {
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    '&:empty': {
      display: 'none',
    }
  },
  timezone: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      color: '#C2C4D9',
      fontSize: '13px',
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
      },
      '& > svg': {
        width: '18px',
        height: '18px',
        marginRight: '4px',
        marginLeft: '-2px',
        marginTop: '-2px',
      },
    },
  },
  customClassesSelect: {
    backgroundImage: theme.colors.gradient,
    overflow: 'auto',
    maxHeight: 'calc(100vh - 400px)',
  },
  customSearch: {
    padding: '0 24px 24px 24px',
  },
  customSchoolList: {
    maxHeight: 'unset !important',
  },
}));


const SubstitutionsHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const substitutionsService = useAppSelector((state: any) => state.services).substitutionsService;

  const curChild = modalsData.substitutionsHandleModal.child;
  const curDate = modalsData.substitutionsHandleModal.date;
  const isEdit = modalsData.substitutionsHandleModal.substitutionID !== null;
  const editValue = isEdit ? modalsData.substitutionsHandleModal.substitutionID : null;
  const list = substitutionsData.list;
  const schoolSettings = userData.schoolSettings;

  const substitutionDataAll = (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? list.find((data: any) => data.childID === curChild.childID).dates : []) : [];
  const substitutionDataID = (substitutionDataAll.length && editValue !== null) > 0 ? (substitutionDataAll.filter((data: any) => data.substitutionID === editValue).length > 0 ? substitutionDataAll.find((data: any) => data.substitutionID === editValue): {}) : {};
  const substitutionData: any = isEdit ? substitutionDataID : null;

  const isSaveClasses = useRef(false);

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? {} : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getChildDefaultClass = (childID: any) => {
    const childData = getChildData(childID);
    const childAllClasses = dataData.classesID.filter((item: any) => childData.classID.includes(item));
    if(childAllClasses.length === 1) {
      return [{classID: childAllClasses[0]}];
    } else {
      const childClassesWithData = childAllClasses.map((item: any) => {
        return getClassData(item);
      });
      const childMainClasses = childClassesWithData.filter((item: any) => item.isMain);
      if(childAllClasses.length !== 1) {
        return [];
      } else {
        return [{classID: childMainClasses[0].classID}];
      }
    }
  };

  const [state, setState] = useStates({
    selectedChildren: (curChild === null || curChild === undefined) ? [] : [curChild],
    selectedClasses: (curChild === null || curChild === undefined) ? [] : getChildDefaultClass(curChild.childID),
    selectedClassesTemp: [],
    isClassesSelectOpen: false,
    date: isEdit ? substitutionData.startDate : curDate,
    partOfDay: isEdit ? substitutionData.substitutionPartID.toString() : "1",
    canClick: true,
  });

  const currentChild = state.selectedChildren[0];
  const currentChildData = getChildData(currentChild.childID);
  const availableClasses = dataData.classesID.filter((item: any) => currentChildData.classID.includes(item));

  const openClassesSelect = () => {
    isSaveClasses.current = true;
    setState("selectedClassesTemp", state.selectedClasses);
    setState("isClassesSelectOpen", true);
  };
  
  const closeClassesSelect = () => {
    isSaveClasses.current = false;
    setState("selectedClassesTemp", []);
    setState("isClassesSelectOpen", false);
  };

  const selectClassesSelect = () => {
    isSaveClasses.current = false;
    setState("selectedClasses", state.selectedClassesTemp);
    setState("selectedClassesTemp", []);
    setState("isClassesSelectOpen", false);
  };

  const setSelectedClassesFromSelect = (_:any, __: any, value: any) => {
    setState("selectedClassesTemp", value);
  };

  const setSelectedClasses = (value: any) => {
    setState("selectedClasses", value);
  };

  const getDateCredit = useCallback(() => {
    const allDays = substitutionsData.childData.allDays;
    const credits = allDays.filter((item: any) => item.substitutionRemaining && moment(item.date).isBetween(moment(state.date).subtract(substitutionsData.childData.schoolDays, 'days'), moment(state.date).add(substitutionsData.childData.schoolDays, 'days')) && moment(item.date).isBefore(moment(state.date),'day')).map((item: any) => {
      return item.substitutionRemaining;
    }).reduce(handleSum, 0);
    return credits;
  }, [state.date, substitutionsData.childData.allDays, substitutionsData.childData.schoolDays]);

  const getUsageCredit = () => {
    return parseInt(state.partOfDay) === 1 ? 1 : 0.5;
  };

  useEffect(() => {
    if(getDateCredit() < 1) {
      if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(curDate).day())).attendanceType === "am")) {
        setState("partOfDay", "3");
      } else {
        setState("partOfDay", "2");
      }
    } else {
      if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(curDate).day())).attendanceType === "am") {
        setState("partOfDay", "3");
      } else if(substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(curDate).day())).attendanceType === "pm") {
        setState("partOfDay", "2");
      } else {
        setState("partOfDay", "1");
      }
    }
  }, [state.date, curDate, getDateCredit, setState, substitutionsData.childData.attendanceProgram.days], [state.date])
  
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      date: null,
      child: null,
      substitutionID: null,
    };
    dispatch(setSubstitutionsHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  useEffect(() => {
    if(curChild === null && curChild === undefined) {
      setState("selectedChildren", curChild);  
    }
  }, [curChild, setState], [curChild]);

  useEffect(() => {
    if(curDate === null && curDate === undefined) {
      setState("startDate", isEdit ? substitutionData.startDate : curDate);  
      setState("endDate", isEdit ? substitutionData.endDate : curDate);  
    }
  }, [curDate, setState, isEdit, substitutionData], [curDate]);

  const uniqueChildren = (children: any) => {
    let uniqueChildrenList: any = [];
    children.forEach((child: any) => {
      if(uniqueChildrenList.indexOf(child.childID) === -1) {
        uniqueChildrenList.push(child.childID);
      }
    });
    return uniqueChildrenList; 
  };
  
  const uniqueClasses = (classes: any) => {
    let uniqueClassesList: any = [];
    classes.forEach((theClass: any) => {
      if(uniqueClassesList.indexOf(theClass.classID) === -1) {
        uniqueClassesList.push(theClass.classID);
      }
    });
    return uniqueClassesList; 
  };

  const parseDay = (day: any) => {
    if(day === 0) return 7;
    else return day;
  };

  const partsOfDay: any = [
    (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(curDate).day())).attendanceType === "pm")) ? {
      name: t('morning'),
      value: '2',
      dataCy: 'morningRadio',
      disabled: getDateCredit() === 0,
    } : null,
    (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length === 0 && getDateCredit() >= 1) ? {
      name: t('whole_day'),
      value: '1',
      dataCy: 'wholeDayRadio',
      disabled: getDateCredit() === 0,
    } : null,
    (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(curDate).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(curDate).day())).attendanceType === "am")) ? {
      name: t('afternoon'),
      value: '3',
      dataCy: 'afternoonRadio',
      disabled: getDateCredit() === 0,
    } : null,
  ];

  const handlePartOfDay = (e: any) => {
    setState("partOfDay", e.target.value);
  };

  const handleCreate = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    if(moment(state.startDate).isSameOrAfter(moment(),'day') && moment(state.startDate).isSameOrBefore(moment().add(180,'days'),'day') && moment(state.endDate).isSameOrAfter(moment(),'day') && moment(state.endDate).isSameOrBefore(moment().add(180,'days'),'day')) {
      const payload = {
        childrenID: uniqueChildren(state.selectedChildren),
        classID: uniqueClasses(state.selectedClasses)[0],
        dateStart: moment(state.date).format("YYYY-MM-DD"),
        dateEnd: moment(state.date).format("YYYY-MM-DD"),
        substitutionPartID: parseInt(state.partOfDay),
      };
      substitutionsService && substitutionsService.createSubstitution(payload).then((result: any) => {
        if(result.status === 200) {
          createNotification(t("substitution_added"), "success");
          dispatch(addSubstitutionsList(result.data.substitution));
          setState("canClick", true);
          onCloseModal();
        } else {
          createNotification(t("substitution_not_added"), "error");  
          setState("canClick", true);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("substitution_not_added"), "error");
        setState("canClick", true);
      });
    } else {
      createNotification(t("substitution_not_added_past"), "error");
      setState("canClick", true);
    }
  };

  const handleEdit = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    const payload = {
      substitutionPartID: parseInt(state.partOfDay),
    };
    substitutionsService && substitutionsService.editSubstitution(substitutionData.substitutionID, payload).then((result: any) => {
      const absences = result.data.absence;
      if(result.status === 201) {
        createNotification(t("substitution_updated"), "success");
        const settings = {
          substitutionID: substitutionData.substitutionID,
          childID: substitutionData.childID,
          classID: substitutionData.classID,
          substitutionPartID: parseInt(state.partOfDay),
          absences: absences,
        };
        dispatch(updateSubstitutionsList(settings));
        setState("canClick", true);
        onCloseModal();
      } else {
        createNotification(t("substitution_not_updated"), "error");   
        setState("canClick", true);
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("substitution_not_updated"), "error");
      setState("canClick", true);
    });
  };

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const schoolTimezones = () => {
    let schools: any = {};
    state.selectedChildren.forEach((theChild: any) => {
      theChild.schoolsID.forEach((theSchool: any) => {
        const schoolName = getSchoolData(theSchool) ? getSchoolData(theSchool).name : '';
        const timezone = getSchoolSettings(theSchool, 'timeZone', schoolSettings) === null ? config.APP_TIMEZONE : getSchoolSettings(theSchool, 'timeZone', schoolSettings);
        if(moment().tz(timezone).format("YYYYMMDDHHmmss") !== moment().tz(config.APP_TIMEZONE).format("YYYYMMDDHHmmss")) {
          schools = {...schools, [theSchool]: {name: schoolName, timezone: timezone}};
        }
      });
    });
    return schools;
  };

  const setDate = (value: any) => {
    setState("date", value);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        (!isEdit || substitutionData !== null) ? (
          <div className={classes.root} data-cy={isCypress() ? "substitutionsHandleModal" : null}>
            <div className={classes.header}>
              <div className={classes.wrapper}>
                <p>{isEdit ? (t('edit_substitution') + ' ' + DateFormat(moment(state.startDate), "default", languageData, t)) : t('add_substitution')}</p>
              </div>
              <CloseButton onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.body}>
            {
                state.isClassesSelectOpen ? (
                  <ClassesSelect
                    onClickClass={setSelectedClassesFromSelect}
                    selectedClasses={state.selectedClasses.map((item: any) => { return { classID: item.classID }; })}
                    isSelectAll={false}
                    isMultipleSelect={false}
                    isAllowOnlyOneClassAtOnce={true}
                    isAllowArchived={false}
                    isAllowInactiveClasses={false}
                    isAllowArchivedToggle={false}
                    isAllowSearch={true}
                    isShowChildrenCounts={false}
                    defaultClasses={availableClasses.map((item: any) => { return { classID: item }; })}
                    mode="select"
                    customClasses={{classesSelect: classes.customClassesSelect, search: classes.customSearch, schoolList: classes.customSchoolList}}
                  />
                ) : (
                  <>
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="user-circle-outlined"/>
                        {t('child') + '*'}
                      </span>
                      <div className={classes.childPhoto}>
                        <AuthenticatedImage thumbLink={curChild.photo?.thumbLink}/>
                        <p className={classes.nickname}>{curChild.displayName}</p>
                      </div>
                    </div>
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="class"/>
                        {t('class') + '*'}
                      </span>
                      {
                        isEdit ? (
                          <></>
                        ) : (
                          <ClassesInput selectedClasses={state.selectedClasses} setSelectedClasses={setSelectedClasses} isDeletable={false} onClick={openClassesSelect}/>
                        )
                      }
                    </div>
                    {
                      !isEdit ? (
                        <>
                        <div className={classes.timezones}>
                          {
                            Object.keys(schoolTimezones()).length > 0 ? (
                              <>
                              {
                                  Object.keys(schoolTimezones()).map((schoolTimezone: any, key: any) => moment().tz(schoolTimezones()[schoolTimezone].timezone).format("YYYYMMDDHHmmss") !== moment().tz(config.APP_TIMEZONE).format("YYYYMMDDHHmmss") ? (
                                    <div className={classes.timezone} key={`k_${key}`}>
                                    {
                                      Object.keys(schoolTimezones()).length > 1 ? (
                                        <span>
                                          <SVG src="warning"/>
                                          {t('timezone_specific_school', {school: schoolTimezones()[schoolTimezone].name})}
                                        </span>
                                      ) : (
                                        <span>
                                          <SVG src="warning"/>
                                          {t('timezone_school')}
                                        </span>
                                      )
                                    }
                                    </div>
                                  ) : null)
                              }
                              </>
                            ) : null
                          }
                        </div>
                        <div className={classes.item}>
                          <span className={classes.title}>
                            <SVG src="clock"/>
                            {t('date_substitution')}*
                          </span>
                          <DatePicker presetDate={state.date} setDate={setDate} dataCy="datePicker"/>
                        </div>
                        </>
                      ) : null
                    }
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="clock-watch"/>
                        {t('part_of_day') + '*'}
                      </span>
                      <RadioGroup items={partsOfDay} value={getDateCredit() === 0 ? 0 : state.partOfDay} onChange={handlePartOfDay}/>
                    </div>
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="ticket"/>
                        {t('substitution_usage')}
                      </span>
                      <p>{getDateCredit() === 0 ? t('substitution_usage_not_remaining') : htmlParse(t('substitution_usage_info', {used: "<b>" + getUsageCredit() + "</b>", available: "<b>" + getDateCredit() + "</b>"}))}</p>
                    </div>
                  </>
                )
            }
            </div>
            <div className={classes.footer}>
            {
              state.isClassesSelectOpen ? (
                <>
                  <NormalButton buttonType="secondary" onClick={closeClassesSelect} dataCy="cancelButton">
                    {t('cancel')}
                  </NormalButton>
                  <NormalButton buttonType="primary" onClick={selectClassesSelect} dataCy="selectButton" disabled={state.selectedClassesTemp.length === 0}>
                    {state.selectedClassesTemp.length === 0 ? t('select_class') : JSON.stringify(state.selectedClasses) === JSON.stringify(state.selectedClassesTemp) ? t('select_without_changes') : t('select')}
                  </NormalButton>
                </>
              ) : (
                <>
                  {
                    isEdit ? (
                      <>
                        <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
                          {t('cancel')}
                        </NormalButton>
                        <NormalButton disabled={!state.canClick} buttonType="primary" onClick={handleEdit || getDateCredit() === 0} dataCy="saveButton">
                          {t('save')}
                        </NormalButton>
                      </>
                    ) : (
                      <>
                        <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
                          {t('cancel')}
                        </NormalButton>
                        <NormalButton buttonType="primary" onClick={handleCreate} disabled={state.selectedClasses.length === 0 || !state.canClick || getDateCredit() === 0} dataCy="createButton">
                          {t('create')}
                        </NormalButton>
                      </>
                    )
                  }
                </>
              )
            }
            </div>
          </div>
        ) : null
      }
    </Modal>
  );
};

export default SubstitutionsHandleModal;