import HttpService from '../http.service';

const createOrder = (payload: any) => {
  return HttpService.post(`order`, payload, {}, {});
};

const getOrder = (path?: any) => {
  return HttpService.get(`order${path ? '?path=' + path : ''}`, {}, {});
};

export {
  createOrder,
  getOrder,
};