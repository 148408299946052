import Input from '../Input';
import React, { useRef } from 'react';
import theme from 'src/ui/theme';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  inputGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap',
    width: '100%', 
    gap: '20px',
    '& label': {
      fontSize: '11px !important',
      fontWeight: 'bold !important',
    },
    '& button': {
      marginTop: 'auto',
      minHeight: '38px',
      width: '38px',
      minWidth: '38px',
      backgroundColor: theme.colors.systemRed[400],
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.systemRed[500],
      },
    }
  },
  inputWrapper: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    gap: '5px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
      justifyContent: 'center',
      '&:not(:first-child)': {
        borderTopWidth: '1px',
        borderTopStyle: 'dashed',
        borderTopColor: theme.colors.grey[500],
      },
    },
  },
  limitInput: {
    width: '40%',
    minWidth: '150px',
    '& > label': {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tooltipIcon: {
    paddingLeft: '8px',
    width: '14px',
    height: '14px',
  },
}));

type StockVariantInputGroupType = {
  items: any;
  initialPrice?: any;
  unlimitedQuantity: any;
  label?: any;
  currency?: any;
  onChange: any;
  className?: any;
  disabled?: boolean;
};

const StockVariantInputGroup: React.FunctionComponent<StockVariantInputGroupType> = ({items, label, initialPrice, unlimitedQuantity, currency, onChange, className, disabled}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const inputNameEls: any = useRef([]);
  const inputPriceEls: any = useRef([]);
  const inputQuantityEls: any = useRef([]);
  const inputSKUEls: any = useRef([]);
  const inputUPCEls: any = useRef([]);

  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const itemsNames = items.map((item: any, key: any) => { return {name: item.name, key: key}; });

  const handleOnChange = (value: any, type: any, key: any, uniqueID: any) => {
    let newItems: any;
    newItems = items.filter((item: any) => !item.deleted).map((item: any, itemKey: any) => {
      if(itemKey === key) {
        const newValue = (type === "price" || type === "quantity") ? (value < 0 ? 0 : value) : value;
        return {...item, [type]: newValue};
      } else {
        return item;
      }
    });
    if(key === (items.length - 1) && type === "name") {
      if(value.length > 0) {
        newItems = [...newItems, { name: '', price: initialPrice ? initialPrice : 0, quantity: 0, sku: '', upc: '', deleted: false, new: true, uniqueID: getElementID() }];
      }
    }
    onChange(newItems);
  };

  const handleOnBlur = (value: any, type: any, key: any, uniqueID: any) => {
    let newItems = items;
    if(type === "name" && (key + 1) !== items.filter((item: any) => !item.deleted).length) {
      const lastName = items[key].name;
      if(value.length === 0) {
        const onAccept = () => {
          newItems = newItems.filter((item: any, itemKey: any) => itemKey !== key);
          setTimeout(() => {
            newItems.forEach((item: any) => {
              inputNameEls.current[item.uniqueID].value = item.name;
              inputPriceEls.current[item.uniqueID].value = item.price;
              inputSKUEls.current[item.uniqueID].value = item.sku;
              inputUPCEls.current[item.uniqueID].value = item.upc;
              if(!unlimitedQuantity) {
                inputQuantityEls.current[item.uniqueID].value = item.quantity;
              }
            });
          }, 100);
          onChange(newItems);
        };
        const onDecline = () => {
          inputNameEls.current[uniqueID].value = lastName;
          newItems = items.filter((item: any) => !item.deleted).map((item: any, itemKey: any) => {
            if(itemKey === key) {
              return {...item, name: lastName};
            } else {
              return item;
            }
          });
          onChange(newItems);
        };
        const settings = {
          isOpen: true,
          title: t('stock_item_variant_no_name'),
          content: t('stock_item_variant_no_name_info'),
          onAccept: () => onAccept(),
          onDecline: () => onDecline(),
        };
        dispatch(setConfirmModal(settings));
      }
    }
    onChange(newItems);
  };

  return (
    <div className={`${classes.inputGroupWrapper} ${className ? className : null}`}>
      {
        label ? (
          <label>
            {label}
          </label>
        ) : null
      }
      <div className={classes.wrapper}>
      {
        items.filter((item: any) => !item.deleted).map((item: any, key: any) => (
          <div className={classes.inputWrapper} key={`k_${key}`}>
            <Input label={(key === 0 || smallDevice) ? t('stock_item_variant_name') : null} placeholder={t('stock_item_variant_name') + " " + (key + 1) + (item.original ? " *" : "")} value={item.name} onChange={(name: any, value: any) => handleOnChange(value, "name", key, item.uniqueID)} onBlur={(name: any, value: any) => handleOnBlur(value, "name", key, item.uniqueID)} disabled={disabled} errorMessage={(itemsNames.filter((itm: any) => itm.name === item.name && itm.key !== key).length === 0 || item.name.length === 0) ? false : t('stock_item_variant_name_duplicate')} customRefInput={(el: any) => (inputNameEls.current[item.uniqueID] = el)}/>
            <Input label={(key === 0 || smallDevice) ? t('stock_item_variant_price_without_tax') : null} type="number" className={classes.limitInput} placeholder={t('stock_item_variant_price_without_tax')} value={item.price} min={0} append={currency} onChange={(name: any, value: any) => handleOnChange(value, "price", key, item.uniqueID)} disabled={disabled} customRefInput={(el: any) => (inputPriceEls.current[item.uniqueID] = el)}/>
            {
              !unlimitedQuantity ? (
                <Input label={(key === 0 || smallDevice) ? t('stock_item_variant_quantity') : null} type="number" className={classes.limitInput} placeholder={t('stock_item_variant_quantity')} value={item.quantity} min={0} onChange={(name: any, value: any) => handleOnChange(value, "quantity", key, item.uniqueID)} disabled={disabled} customRefInput={(el: any) => (inputQuantityEls.current[item.uniqueID] = el)}/>
              ) : null
            }
            <Input label={(key === 0 || smallDevice) ? (<>{t('stock_item_variant_sku')}<TooltipIcon className={classes.tooltipIcon} title={t('stock_item_sku_info')} maxWidth={400}/></>) : null} placeholder={t('stock_item_variant_sku')} value={item.sku} onChange={(name: any, value: any) => handleOnChange(value, "sku", key, item.uniqueID)} disabled={disabled} customRefInput={(el: any) => (inputSKUEls.current[item.uniqueID] = el)}/>
            <Input label={(key === 0 || smallDevice) ? (<>{t('stock_item_variant_upc')}<TooltipIcon className={classes.tooltipIcon} title={t('stock_item_upc_info')} maxWidth={400}/></>) : null} placeholder={t('stock_item_variant_upc')} value={item.upc} onChange={(name: any, value: any) => handleOnChange(value, "upc", key, item.uniqueID)} disabled={disabled} customRefInput={(el: any) => (inputUPCEls.current[item.uniqueID] = el)}/>
          </div>
        ))
      }
      </div>
    </div>
  );
}

export default StockVariantInputGroup;