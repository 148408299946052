import addons from 'src/constants/addons';
import config from 'src/constants/config';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { arrayToObject } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  switches: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '96px',
    gap: '16px',
    position: 'relative',
    '& > span': {
      fontSize: '24px',
      width: '80%',
      fontWeight: '600',
      marginBottom: '16px',
      color: theme.colors.black,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '80%',
    marginTop: '16px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  iconPlaceholder: {
    position: 'relative',
    width: '50%',
    paddingTop: '50%',
    '& > svg': {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      color: theme.colors.primaryBlue[500],
    },
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: theme.colors.chip,
    maxWidth: 'calc(80% - 32px)',
    width: 'calc(80% - 32px)',
    margin: '0 16px',
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '8px 0',
    gap: '8px',
    '& > svg': {
      width: '36px',
      height: '36px',
      color: theme.colors.grey[800],
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    gap: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    '& > span': {
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      alignItems: 'center',
      gap: '6px',
      '& > em': {
        display: 'inline-flex',
        height: 'fit-content',
        fontStyle: 'unset',
        fontSize: '9px',
        padding: '3px 5px',
        borderRadius: '6px',
        backgroundColor: theme.colors.primaryBlue[500],
        color: theme.colors.white,
      },
      '& > span': {
        lineHeight: '1',
        '& > svg': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
  switch: {
    width: 'fit-content',
    transform: 'scale(1.25)',
  },
  loading: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type SectionType = {
  updateSection: any;
  handleNext: any;
};

const App: React.FunctionComponent<SectionType> = ({ updateSection, handleNext }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const isSaving = useRef(false);

  const icon = "webapp-logo";
  const section = "app";
  const curAddon = "app";

  const addonsList: any = addons.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const currentAddon = addonsList.filter((item: any) => item.key === curAddon).length > 0 ? addonsList.find((item: any) => item.key === curAddon) : [];
  const addonItems = currentAddon.items.filter((item: any) => item.isEnabled && item.isSetup && item.roles.includes(getUserRole(userData.userObject.roleType)));

  const [state, setState] = useStates({
    canContinue: true,
    isSaving: false,
  });

  const handleSwitch = async (name: any, value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const oldValue = getUserSetting(userData.userSettings, "addons", [currentAddon.key, name]);
      const newValue = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "addons", [currentAddon.key, name], value);
      if(result) {
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleSave = async () => {
    const value = arrayToObject(getUserSetting(userData.userSettings, "addons", [currentAddon.key]));
    const oldValue = getUserSetting(userData.userSettings, "setup", [section]);
    const newValue = updateUserSetting(userData.userSettings, "setup", [section], value);
    updateSection(section, false);
    dispatch(setUserSettings(newValue));
    const result = await saveUserSettings(dispatch, userData, "setup", [section], value);
    if(result) {
      return true;
    } else {
      const updateSettings = updateUserSetting(userData.userSettings, "setup", [section], oldValue);
      updateSection(section, true);
      dispatch(setUserSettings(updateSettings));
      return false;
    }
  };

  const handleContinue = async () => {
    if(state.canContinue) {
      setState("canContinue", false);
      const isSaved = await handleSave();
      if(isSaved) {
        handleNext();
      } else {
        createNotification(t('something_went_wrong'), 'error');
        setState("canContinue", true);
      }
    }
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.switches}>
          <span>{t('setup_app')}</span>
          <div className={classes.settingsWrapper}>
            {
              addonItems.map((item: any, key: any) => (
                <div className={classes.setting} key={`k_${key}`}>
                  {
                    item.icon ? (
                      <SVG src={item.icon}/>
                    ) : null
                  }
                  <div className={classes.content}>
                    <div className={classes.row}>
                      <span>
                        <span>{t(item.name)}</span>
                        <TooltipIcon title={t(item.desc)} maxWidth={400}/>
                        {
                          item.isRecommended ? (
                          <em>{t('recommended_by_twigsee')}</em>
                          ) : null
                        }
                      </span>
                    </div>
                    <Switch className={classes.switch} name={item.key} checked={getUserSetting(userData.userSettings, "addons", [currentAddon.key, item.key])} onChange={(name: any, val: any) => handleSwitch(name, val)} disabled={state.isSaving}/>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={classes.buttonWrapper}>
            <NormalButton buttonType="primary" onClick={handleContinue} disabled={!state.canContinue}>
              {t('setup_continue')}
            </NormalButton>
          </div>
          {
            !state.canContinue ? (
              <div className={classes.loading}>
                <div className={classes.spinner}>
                  <CircularProgress size={128}/>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.icon}>
          <div className={classes.iconPlaceholder}>
            <SVG src={icon}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;