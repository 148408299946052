import StorageService from '../services/storage.service';
import store from '../store';
import { setCypressCurrentTag } from './../store/actions/layout.actions';

const isCypress = (value?: any) => {
  const useCypress = StorageService.existItem("twigsee.cypress");
  if(value) {
    if(useCypress) {
      const useCypressValue = StorageService.getItem("twigsee.cypress") || "";
      return useCypressValue.toString().includes(value.toString()) ? false : true;
    } else {
      return useCypress ? true : false;  
    }
  } else {
    return useCypress ? true : false;  
  }
};

const getAllCypressTags = () => {
  const list = Array.prototype.slice.call(document.querySelectorAll("[data-cy]"));
  let newList: any = [];
  list.forEach((item: any) => {
    newList[item.getAttribute("data-cy")] = item;
  });
  return newList;
};

const getAllCypressTagsNames = () => {
  const list = Array.prototype.slice.call(document.querySelectorAll("[data-cy]"));
  return list.map((item: any) => {
    return item.getAttribute("data-cy");
  });
};

const hightlightAllCypressTagsNames = () => {
  document.querySelectorAll("[data-cy]").forEach((item: any) => {
    item.style.border = '1px solid red';
    item.addEventListener("mouseenter",() => {
      store.dispatch(setCypressCurrentTag(item.getAttribute("data-cy")));
    }, false);
    item.addEventListener("mouseleave",() => {
      store.dispatch(setCypressCurrentTag(undefined));
    }, false);
  });
};

const hightlightAllCypressTagsNamesKey = (e: any) =>  {
  if (e.ctrlKey && e.which === 72) {
    e.preventDefault();
    e.stopPropagation();
    hightlightAllCypressTagsNames();
  }
};

const createCypressDataText = (text: any) => {
  const words: any = typeof text === "string" ? text.split(/[-_ ]/) : text.toString().split(/[-_ ]/);
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join('');
};

export {
  isCypress,
  getAllCypressTags,
  getAllCypressTagsNames,
  hightlightAllCypressTagsNames,
  hightlightAllCypressTagsNamesKey,
  createCypressDataText,
};