import React from 'react';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { TreeItem as MuiTreeItem } from '@mui/x-tree-view/TreeItem';

const useStyles = createUseStyles((theme: any) => ({
  treeItem: {
    display: 'flex',
    flexDirection: 'column',
    '& > .MuiTreeItem-content': {
      '& > .MuiTreeItem-iconContainer': {
        '& > svg': {
          width: '18px',
          height: '18px',
        },
      },
    },
  },
}));

type TreeItemType = {
  label: any;
  items?: any;
  disabled?: any;
  className?: any,
};

const TreeItem: React.FunctionComponent<TreeItemType> = ({ label, items, disabled, className }) => {
  
  const classes = useStyles();
  const uid = getElementID().toString();
  
  return (
    <MuiTreeItem nodeId={uid} label={label} className={`${classes.treeItem} ${className ? className : ''}`} disabled={disabled}>
      {
        (items && Array.isArray(items) && items.length) ? (
          <>
          {
            items.map((item: any, key: any) => (
              <TreeItem key={`k_${key}`} label={item.name} items={item.items} className={className} disabled={item.disabled}/>
            ))
          }
          </>
        ) : null
      }  
    </MuiTreeItem>
  );
}

export default TreeItem;
