import CountUp from 'react-countup';
import React from 'react';
import useFitText from 'use-fit-text';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  countCard: {
    minWidth: '250px',
    width: 'fit-content',
    height: '150px',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '24px',
    padding: '0 18px',
    boxShadow: theme.shadows[2],
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      height: '150px',
    }
  },
  title: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    maxWidth: '90%',
    '& > em': {
      fontStyle: 'normal',
      marginLeft: '8px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      fontSize: '10px',
      lineHeight: '10px',
      '&.primary': {
        color: theme.colors.white,
        backgroundColor: theme.colors.primaryBlue[500],
      },
      '&.error': {
        color: theme.colors.white,
        backgroundColor: theme.colors.systemRed[500],
      },
    },
  },
  number: {
    marginTop: '4px',
    fontSize: '32px',
    fontWeight: '500',
  },
  chip: {
    fontStyle: 'normal',
    marginLeft: '8px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '0 8px',
    height: '20px',
    fontSize: '10px',
    lineHeight: '10px',
    color: theme.colors.black,
    backgroundColor: theme.colors.chip,
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
}));

type ItemsType = {
  title: any;
  badge?: any;
  badgeType?: any;
  chip?: any;
  countLeft?: any;
  countNumber: any;
  countDecimal?: any;
  countRight?: any;
};

const CountCard: React.FunctionComponent<ItemsType> = ({title, badge, badgeType = "primary", chip, countLeft, countNumber = 0, countDecimal = 0, countRight}) => {
  
  const classes = useStyles();
  const { fontSize, ref } = useFitText({maxFontSize: 60});
  
  return (
    <div className={classes.countCard}>
      <span className={classes.title}>
        {title}
        {
          badge ? (
            <em className={badgeType} ref={ref} style={{ fontSize, height: 20, width: 20 }}>{badge > 99 ? '99+' : badge}</em>
          ) : null
        }
      </span>
      {
        chip ? (
          <span className={classes.chip}>{chip}</span>
        ) : null
      }
      <span className={classes.number}>
        {countLeft}
        <CountUp end={countNumber} decimal="," decimals={countDecimal} duration={0.5}/>
        {countRight}
      </span>
  </div>
  );
}

export default CountCard;