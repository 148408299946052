import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  drawer: {
    top: '64px',
    zIndex: theme.zIndex.drawer,
    [theme.breakpoints.down('md')]: {
      bottom: '56px',
    },
  },
  paper: {
    top: '64px',
    height: 'calc(100% - 64px)',
    zIndex: theme.zIndex.drawer,
    [theme.breakpoints.down('md')]: {
      bottom: '56px',
      height: 'calc(100% - 120px)',
    },
  },
}));

type DrawerType = {
  children: any;
  open: boolean;
  position?: 'left' | 'right' | 'top' | 'bottom';
  hideBackdrop?: boolean;
  transitionDuration?: any;
  onClose?: any;
  className?: any;
  classNamePaper?: any;
};

const Drawer: React.FunctionComponent<DrawerType> = ({children, open, position = "right", className, classNamePaper, hideBackdrop, transitionDuration = { enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen }, onClose}) => {

  const classes = useStyles();
  
  const slotProps = {
    root: {
      className: `${classes.drawer} ${className ? className : null}`,
    },
  };

  const paperProps = {
    className: `${classes.paper} ${classNamePaper ? classNamePaper : null}`,
  };
  
  return (
    <MuiDrawer
      anchor={position}
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      transitionDuration={transitionDuration}
      slotProps={slotProps}
      PaperProps={paperProps}
    >
      {children}            
    </MuiDrawer>
  );
}


Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default Drawer;