import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as SvgSprite } from '/public/resources/iconpack/sprite.min.svg';

interface Props {
  children: any;
}

const SvgController: React.FunctionComponent<Props> = (props:Props) => {
    
  return (
    <>
      <SvgSprite/>
      {props.children}
    </>
  );
};

SvgController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default SvgController;