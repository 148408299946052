import Attendance from 'src/components/Attendance';
import Failed from 'src/components/Layouts/Failed';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress, Skeleton } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setAttendanceSelectedChildren } from 'src/store/actions/attendance.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  todayAttendance: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
  },
  content: {
    overflow: 'auto',
    maxHeight: '100%',
    '& > div': {
      width: '100% !important',
    },
  },
  moreButton: {
    margin: '0px 8px 8px 8px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  failedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '25px 0',
  },
  failed: {
    width: '100%',
    padding: '100px 0 50px 0',
    '& > svg': {
      width: '64px',
      height: '64px',
    },
    '& > span': {
      fontSize: '18px',
      color: theme.colors.systemRed[500],
      marginTop: '8px',
    },
  },
  buttons: {
    position: 'fixed',
    bottom: '60px',
    display: 'flex',
    justifyContent: 'center',
  },
  saveButton: {
    borderRadius: '10px 0 0 10px',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
  },
  cancelButton: {
    borderRadius: '0 10px 10px 0',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > span': {
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '16px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: '16px 26px',
    maxWidth: 'calc(100% - 84px)',
    backgroundColor: theme.colors.grey[100],
    borderRadius: '12px',
    padding: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
  },
  detail: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  info: {
    marginTop: '13px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
    },
  },
  children: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginTop: '16px',
  },
  child: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
}));

const TodayAttendance: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const attendanceData = useAppSelector((state: any) => state.attendance);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const languageData = useAppSelector((state: any) => state.language);
  const selectedChildren = attendanceData.selectedChildren;
  const attendanceService = useAppSelector((state: any) => state.services).attendanceService;
  
  const [state, setState] = useStates({
    isLoaded: false,
    isFailed: false,
    isReloaded: false,
    classesList: [],
    childrenList: [],
    presentChildren: [],
    canSave: true,
  });

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? {} : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? {} : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const loadAttendance= useCallback((isReload?: boolean) => {
    attendanceService && attendanceService.listAttendance(moment().format('YYYY-MM-DD'), languageData.language.toLowerCase()).then((result: any) => {
      const newClassesList = result.data.classes.map((item: any) => {
        return {
          classID: item.classID,
          classAttendanceStatus: item.classAttendanceStatus,
          presentCount: item.presentCount,
          missingCount: item.missingCount,
          expectedAfternoonCount: item.expectedAfternoonCount,
          expectedMorningCount: item.expectedMorningCount,
          schoolID: getClassData(item.classID).schoolID,
          name: getClassData(item.classID).name,
          children: item.children,
        };
      });
      const newChildrenList = result.data.classes.map((item: any) => {
        return item.children.map((child: any) => {
          if(Object.keys(getChildData(child.childID)).length !== 0) {
            return {...child, classID: [item.classID], schoolID: getChildData(child.childID).schoolID};
          } else {
            return null;
          }
        }).filter((item: any) => item !== null);
      }).flat();
      const presentChildren = newChildrenList.filter((item: any) => item.isPresent).map((item: any) => { return { childID: item.childID, classID: item.classID[0] }; })
      setState("classesList", newClassesList);
      setState("childrenList", newChildrenList);
      setState("presentChildren", presentChildren);
      setState("isReloaded", true);
      if(isReload) {
        setTimeout(() => {
          setState("isReloaded", false);
          setState("isLoaded", true);
        }, 100);
      } else {
        setState("isReloaded", false);
        setState("isLoaded", true);
      }
    });
  }, [attendanceService, languageData.language, setState, getClassData, getChildData]);

  useEffect(() => {
    loadAttendance();
    return () => {
      dispatch(setAttendanceSelectedChildren([]));
    }
  }, [dispatch, loadAttendance], []);

  const handleAttendance = () => {
    navigate('/attendance');
  };

  const handleSave = () => {
    if(state.canSave) {
      setState("canSave", false);
      const updateChildren = selectedChildren.map((item: any) => {
        return { childID: item.childID, classID: item.classID, isPresent: item.isPresent };
      });
      let payload = {children: updateChildren};
      attendanceService && attendanceService.updateAttendance(payload).then((result: any) => {
        if(result.status === 200) {
          createNotification(t("attendance_saved"), "success");
          dispatch(setAttendanceSelectedChildren([]));
          loadAttendance(true);
        } else {                       
          createNotification(t("attendance_not_saved"), "error");
        }
        setState("canSave", true);
      }).catch(() => {
        createNotification(t("attendance_not_saved"), "error");
        setState("canSave", true);
      });
    }
  };
  
  const handleClear = () => {
    setState("isReloaded", true);
    dispatch(setAttendanceSelectedChildren([]));
    loadAttendance(false);
  };
  
  return (!state.isLoaded && !layoutData.isDashboardEditable) ? (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        <CircularProgress/>
      </div>
    </div>
  ) : (
    <div className={classes.todayAttendance}>
      {
        state.isFailed ? (
          <div className={classes.failedWrapper}>
            <Failed className={classes.failed} title={t('widget_failed_load')}/>
            <NormalButton className={classes.moreButton} onClick={loadAttendance}>
              {t('try_again')}
            </NormalButton>
          </div>
        ) : (
          <>
            <div className={classes.content}>
              {
                layoutData.isDashboardEditable ? (
                  <>
                    <div className={classes.search}>
                      <Skeleton variant="rectangular" animation="wave" width={724} height={38}/>
                    </div>
                    {
                      [0,1,2,3,4].map((_: any, key: any) => (
                        <div className={classes.card} key={`k_${key}`}>
                          <div className={classes.header}>
                            <Skeleton variant="text" animation="wave" width={65} height={27}/>
                            <div>
                              <Skeleton variant="text" animation="wave" width={31} height={25}/>
                              <Skeleton variant="circular" animation="wave" width={34} height={34}/>
                            </div>
                          </div>
                          <div className={classes.detail}>
                            <Skeleton variant="rectangular" animation="wave" width={123} height={33}/>
                            <Skeleton variant="rectangular" animation="wave" width={123} height={33}/>
                          </div>
                          <div className={classes.info}>
                            <div>
                              <Skeleton variant="circular" animation="wave" width={9} height={9}/>
                              <Skeleton variant="text" animation="wave" width={124} height={17}/>
                            </div>
                            <div>
                              <Skeleton variant="circular" animation="wave" width={9} height={9}/>
                              <Skeleton variant="text" animation="wave" width={124} height={17}/>
                            </div>
                          </div>
                          <div className={classes.children}>
                            {
                              [0,1,2,3,4,5,6,7,8,9,10].map((_: any, key: any) => (
                                <div className={classes.child} key={`k_${key}`}>
                                  <Skeleton key={`k_${key}`} variant="circular" animation="wave" width={54} height={54}/>
                                  <Skeleton variant="text" animation="wave" width={55} height={16}/>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </>
                ) : (
                  <>
                    <Attendance isLoaded={state.isLoaded} isReloaded={state.isReloaded} presentChildren={state.presentChildren} classesList={state.classesList} childrenList={state.childrenList}/>
                      {
                        selectedChildren.length !== 0 ? (
                          <div className={classes.buttons}>
                            <NormalButton buttonType="primary" className={classes.saveButton} onClick={handleSave} dataCy="attendanceSaveButton">
                              {t('save_attendance')}
                            </NormalButton>
                            <NormalButton buttonType="clear" className={classes.cancelButton} onClick={handleClear} dataCy="attendanceClearButton">
                              <SVG src="close"/>
                            </NormalButton>
                          </div>
                        ) : null
                      } 
                  </>
                )
              }
            </div>
            <NormalButton className={classes.moreButton} onClick={handleAttendance}>
              {t('view_more_attendance')}
            </NormalButton>
          </>
        )
      }
    </div>
  );
};

const TodayAttendanceTitle: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return t('today_attendance');
};

export { TodayAttendance, TodayAttendanceTitle };