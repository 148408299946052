import ChildrenInput from 'src/components/Forms/ChildrenInput';
import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import DateFormat from 'src/utils/dateFormat';
import Input from 'src/components/Forms/Input';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { a2ab, createFileName } from 'src/utils/useFunctions';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveAs } from 'file-saver';
import { setAttachmentModal, setPressReportTemplateHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  finishHandlerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '32px',
    height: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '32px',
    height: '100%',
    overflow: 'auto',
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0 24px',
    flex: '1 1 calc(40%- 48px)',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      order: '2',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0 24px',
    flex: '1 1 calc(60% - 48px)',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      order: '1',
    },
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& > span': {
      fontWeight: '600',
    },
  },
  options: {
    display: 'flex',
    gap: '8px',
  },
  selectButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    minWidth: 'unset',
    textTransform: 'unset',
    '& > svg': {
      width: '64px',
      height: '64px',
    },
    '&.active': {
      color: theme.colors.primaryBlue[500],
    },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    gap: '8px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileButtons: {
    display: 'none',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '24px',
    gap: '8px',
    padding: '0 24px',
    width: 'calc(100% - 48px)',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  saveTemplateButton: {
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: 'unset',
    },
  },
}));

type FinishHandlerType = {
  pressReportData: any;
  state: any;
  setState: any;
  onBack: any;
};

const FinishHandlerSelect: React.FunctionComponent<FinishHandlerType> = ({ pressReportData, state, setState, onBack }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const exportService = useAppSelector((state: any) => state.services).exportService;

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const [customState, setCustomState] = useStates({
    isChildrenSelectOpen: false,
  });

  const allColumns = state.allColumns.map((item: any) => item.columns.flat()).flat();

  const handleTitle = (value: any) => {
    setState("title", value);
  };

  const handlePageLayout = (value: any) => {
    setState("pageLayout", value);
  };

  const handlePageOrientation = (value: any) => {
    setState("pageOrientation", value);
  };

  const handlePageSize = (value: any) => {
    setState("pageSize", value);
  };

  const handleBack = () => {
    onBack(state.templateData && state.templateData.isEditable ? 5 : 4);
  };

  const handleExport = (title: any, name: any, format: any) => {
    const pressTitle = state.title;
    const updatedColumns = state.columnsOrdered.map((item: any) => { 
      if(!item.key || (item.key && item.key.includes("webAppCustomColumn"))) {
        return { name: item.name };
      } else {
        return { key: item.key };
      }
    });
    const fileName = createFileName(name);
    const handleExportPress = () => {
      const payload = {
        type: 'children',
        format: format,
        title: pressTitle,
        pageLayout: state.pageLayout.value,
        pageOrientation: state.pageOrientation.value,
        pageSize: state.pageSize.value,
        columns: updatedColumns,
        entityID: state.children.map((item: any) => { return item.childID; }),
      };
      exportService && exportService.exportReport(payload).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = format === "pdf" ? 'application/pdf' : 'application/vnd.ms-excel;charset=utf-8';
        const blob = new Blob([buf], {
            type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    if(format === "pdf") {
      const settings = {
        isOpen: true,
        title: `${title} <span>${fileName}</span>`,
        content: null,
        download: null,
        isPrintable: null,
      };
      dispatch(setAttachmentModal(settings));
      const payload = {
        type: 'children',
        format: format,
        title: pressTitle,
        pageLayout: state.pageLayout.value,
        pageOrientation: state.pageOrientation.value,
        pageSize: state.pageSize.value,
        columns: updatedColumns,
        entityID: state.children.map((item: any) => { return item.childID; }),
      };
      exportService && exportService.exportReport(payload).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
            type: buftype,
        });
        const content = URL.createObjectURL(blob);
        const settings = {
          content: content,
          download: handleExportPress,
          type: format,
          isPrintable: true,
        };
        dispatch(setAttachmentModal(settings));
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
        const settings = {
          isOpen: false,
          title: null,
          content: null,
          download: null,
          isPrintable: null,
        };
        dispatch(setAttachmentModal(settings));
      });
    } else {
      handleExportPress();
    }
  };

  const handleCreateTemplate = () => {
    const schoolID = state.schoolID;
    const columns = state.columnsOrdered.map((item: any) => { return item.key ? { key: item.key } : { name: item.name }; });
    const title = state.title;
    const settings = {
      isOpen: true,
      allColumns: state.allColumns,
      columns: columns,
      schoolID: schoolID,
      title: title,
      public: false,
      name: null,
    };
    dispatch(setPressReportTemplateHandleModal(settings));
  };

  const handleSaveTemplate = () => {
    const schoolID = state.schoolID;
    const columns = state.columnsOrdered.map((item: any) => { return item.key ? { key: item.key } : { name: item.name }; });
    const title = state.title;
    const settings = {
      isOpen: true,
      templateID: state.templateData.templateID,
      allColumns: state.allColumns,
      columns: columns,
      schoolID: schoolID,
      title: title,
      public: state.templateData.public,
      name: state.templateData.name,
    };
    dispatch(setPressReportTemplateHandleModal(settings));
  };

  const toggleChildrenSelect = () => {
    setCustomState("isChildrenSelectOpen", !customState.isChildrenSelectOpen);
  };

  return (
    <div className={classes.finishHandlerWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.settings}>
          <div className={classes.option}>
            <span>{t('page_layout')}</span>
            <div className={classes.options}>
              {
                pressReportData.pageLayouts.map((item: any, key: any) => (
                  <Button className={`${classes.selectButton} ${state.pageLayout.value === item.value ? "active" : ''}`} key={`k_${key}`} onClick={() => handlePageLayout(item)}>
                    <SVG src={item.value}/>
                    <span>{item.name}</span>
                  </Button>
                ))
              }
            </div>
          </div>
          <div className={classes.option}>
            <span>{t('page_orientation')}</span>
            <div className={classes.options}>
              {
                pressReportData.pageOrientations.map((item: any, key: any) => (
                  <Button className={`${classes.selectButton} ${state.pageOrientation.value === item.value ? "active" : ''}`} key={`k_${key}`} onClick={() => handlePageOrientation(item)}>
                    <SVG src={`${item.value}-outlined`}/>
                    <span>{item.name}</span>
                  </Button>
                ))
              }
            </div>
          </div>
          <div className={classes.option}>
            <span>{t('page_size')}</span>
            <div className={classes.options}>
              {
                pressReportData.pageSizes.map((item: any, key: any) => (
                  <Button className={`${classes.selectButton} ${state.pageSize.value === item.value ? "active" : ''}`} key={`k_${key}`} onClick={() => handlePageSize(item)}>
                    <SVG src={`page-${item.value}`}/>
                    <span>{item.name}</span>
                  </Button>
                ))
              }
            </div>
          </div>
          <div className={classes.buttons}>
            {
              state.templateData && state.templateData.isEditable ? (
                <NormalButton className={classes.saveTemplateButton} buttonType='secondary' onClick={handleSaveTemplate}>
                  {t('press_report_save_template')}
                </NormalButton>
              ) : (
                <NormalButton className={classes.saveTemplateButton} buttonType='secondary' onClick={handleCreateTemplate}>
                  {t('press_report_save_as_template')}
                </NormalButton>
              )
            }
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.option}>
            <span>{t('press_report_title')}</span>
            <Input placeholder={t('press_report_title')} name="title" value={state.title} useName={false} onChange={handleTitle} autoFocus/>
          </div>
          <div className={classes.option}>
            <span>{t('selected_children')} ({state.children.length})</span>
            <ChildrenInput selectedChildren={state.children} setSelectedChildren={null} isDeletable={false} onClick={toggleChildrenSelect} maxRows={3}/>
            {
              customState.isChildrenSelectOpen ? (
                <ChildrenSelect
                  isSelectAll={false}
                  isSelectInAllClass={false}
                  isMultipleSelect={false}
                  isAllowSearch={true}
                  defaultSchools={[{schoolID: state.schoolID}]}
                  defaultClasses={dataData.classes.filter((item: any) => item.schoolID === state.schoolID)}
                  defaultChildren={state.children.map((item: any) => { return {childID: item.childID, classID: getChildData(item.childID).classID, schoolID: state.schoolID}; })}
                  displayMode='simple'
                  isInModal={true}
                  isModalOpen={true}
                  modalAllowClose={true}
                  modalAllowChildrenCount={false}
                  modalTitle={t('children')}
                  modalOnClose={toggleChildrenSelect}
                  mode="detail"
                  isChildModal={true}
                />
              ) : null
            }
          </div>
          <div className={classes.option}>
            <span>{t('selected_columns')} ({state.columnsOrdered.length})</span>
            <p>
              {
                state.columnsOrdered.map((item: any) => { 
                  const getName = allColumns.filter((subItem: any) => subItem.key === item.key).length === 1 ? allColumns.find((subItem: any) => subItem.key === item.key).name : (item.name ? item.name : "");
                  return getName;
                }).join(", ")
              }
            </p>
          </div>
          <div className={classes.buttons}>
            <NormalButton className={classes.saveTemplateButton} buttonType='secondary' onClick={handleBack}>
              {state.templateData && state.templateData.isEditable ? t('edit_template') : t('back')}
            </NormalButton>
            <NormalButton buttonType='secondary' onClick={() => handleExport(t('press_report'), `${t('press_report')}-${DateFormat(moment(),"defaulttime", languageData, t)}`, 'xlsx')}>
              {t('press_report_generate_download_xlsx')}
            </NormalButton>
            <NormalButton buttonType='primary' onClick={() => handleExport(t('press_report'), `${t('press_report')}-${DateFormat(moment(),"defaulttime", languageData, t)}`, 'pdf')}>
              {t('press_report_generate_pdf')}
            </NormalButton>
          </div>
        </div>
      </div>
      <div className={classes.mobileButtons}>
        {
          state.templateData && state.templateData.isEditable ? (
            <NormalButton className={classes.saveTemplateButton} buttonType='secondary' onClick={handleSaveTemplate}>
              {t('press_report_save_template')}
            </NormalButton>
          ) : (
            <NormalButton className={classes.saveTemplateButton} buttonType='secondary' onClick={handleCreateTemplate}>
              {t('press_report_save_as_template')}
            </NormalButton>
          )
        }
        <NormalButton buttonType='secondary' onClick={handleBack}>
          {state.templateData && state.templateData.isEditable ? t('edit_template') : t('back')}
        </NormalButton>
        <NormalButton buttonType='secondary' onClick={() => handleExport(t('press_report'), `${t('press_report')}-${DateFormat(moment(),"defaulttime", languageData, t)}`, 'xlsx')}>
          {t('press_report_generate_download_xlsx')}
        </NormalButton>
        <NormalButton buttonType='primary' onClick={() => handleExport(t('press_report'), `${t('press_report')}-${DateFormat(moment(),"defaulttime", languageData, t)}`, 'pdf')}>
          {t('press_report_generate_pdf')}
        </NormalButton>
      </div>
    </div>
  );
};

export default FinishHandlerSelect;