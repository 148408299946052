const globalStyles: any = () => {
    const styles = {
        '*': {
            margin: '0',
        },
        body: {
            margin: 0,
            fontFamily: '-apple-system, BlinkMacSystemFont, "Poppins", "Helvetica", "Arial", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
        },
        code: {
            fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        },
        button: {
            margin: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
        },
        input: {
            margin: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
        },
        optgroup: {
            margin: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
        },
        select: {
            margin: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
        },
        textarea: {
            margin: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
        },
        p: {
            margin: '0',
        },
        '.cke_dialog_background_cover': {
            backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
            opacity: 'unset !important',
        },
        '.cke_notifications_area': {
            display: 'none !important',
        },
    };
    return styles;
};

export default globalStyles;