import BreakBlock from './BreakBlock';
import BreakHourBlock from './BreakHourBlock';
import DayBlock from './DayBlock';
import HourBlock from './HourBlock';
import React from 'react';
import SubjectBlock from './SubjectBlock';
import TimeBlock from './TimeBlock';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { getUserSetting } from 'src/utils/useUser';

const useStyles = createUseStyles((theme: any) => ({
  timetableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '32px',
    maxWidth: '100%',
  },
  header: {
    display: 'flex',
    paddingBottom: '16px',
    paddingLeft: '140px',
  },
  body: {
    display: 'flex',
    maxWidth: '100%',
    justifyContent: 'center',
  },
  side: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: '74px',
    paddingRight: '56px',
    justifyContent: 'flex-end',
    minWidth: 'calc(140px - 56px)',
    maxWidth: 'calc(140px - 28px)',
    '& td': {
      height: '96px',
    },
  },
  hourWrapper: {
    width: '96px',
    minWidth: '96px',
  },
  breakWrapper: {
    '&:empty': {
      width: '50px',
      minWidth: '50px',
    },
    '&:not(:empty)': {
      width: '70px',
      minWidth: '70px',
    },
  },
  hourAllWrapper: {
    width: '96px',
    minWidth: '96px',
    height: '116px',
  },
  breakAllWrapper: {
    '&:empty': {
      width: '50px',
      minWidth: '50px',
    },
    '&:not(:empty)': {
      width: '70px',
      minWidth: '70px',
    },
    height: '70px',
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  tableBodyWrapper: {
    maxWidth: 'calc(100% - 140px)',
    flex: '1 1 auto',
    overflow: 'auto',
  },
  tableContainer: {
    maxWidth: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '16px',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        whiteSpace: 'nowrap',
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '& > td': {
      whiteSpace: 'nowrap',
    },
  },
  firstCell: {
    width: '135.9px',
    minWidth: '140px',
    height: '10px',
    padding: '0px',
  },
}));

type TimetableType = {
  customRef?: any;
};

const TimetableTable: React.FunctionComponent<TimetableType> = ({customRef}) => {

  const classes = useStyles();
  const timetableData = useAppSelector((state: any) => state.timetable);
  const userData = useAppSelector((state: any) => state.user);
  const schoolID = timetableData.school === null ? null : timetableData.school.schoolID;
  const schoolSettings = userData.schoolSettings;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const slots = timetableData.data === null ? [] : timetableData.data.slots;
  const schoolWorkingDays = getSchoolSettings(schoolID, 'workingDays', schoolSettings) === null ? [] : getSchoolSettings(schoolID, 'workingDays', schoolSettings);
  const workingDays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(schoolWorkingDays) : schoolWorkingDays;
  const settings = timetableData.settings === null ? [] : timetableData.settings;
  const isFirstBreakZero = slots.length === 0 ? false : slots.filter((item: any) => item.type === 2)[0].hour === 0 ? true : false;

  return (
    <div className={classes.timetableWrapper} ref={customRef}>
      <div className={classes.body}>
        <div className={classes.side}>
          <table className={classes.tableContainer}>
            <tbody className={classes.tableBody}>
            {
              workingDays.map((day: any, key: any) => (
                <tr className={classes.tableRow} key={`k_${key}`}>
                  <td>
                    <DayBlock day={day}/>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
        <div className={classes.tableBodyWrapper}>
          <table className={classes.tableContainer}>
            <thead className={classes.tableHead}>
              <tr>
                {
                  slots.map((hour: any, key: any) => hour.type === 1 ? (
                    <th key={`k_${key}`} className={classes.hourWrapper}>
                      <div className={classes.head}>
                        <HourBlock hour={hour.hour}/>
                        <TimeBlock start={hour.start} end={hour.end}/>
                      </div>
                    </th>
                  ) : (
                    <th key={`k_${key}`} className={classes.breakWrapper}>
                      { 
                        settings.breaks ? (
                          <div className={classes.head}>
                            <BreakHourBlock hour={hour.hour} isFirstBreakZero={isFirstBreakZero}/>
                          </div>
                        ) : null
                      }
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              {
                workingDays.map((day: any, key: any) => (
                  <tr className={classes.tableRow} key={`k_${key}`}>
                    {
                      slots.map((hour: any, key: any) => {
                        return hour.type === 1 ? (
                          <td key={`k_${key}`} className={classes.hourAllWrapper}>
                            <SubjectBlock day={day} hour={hour.hour}/>
                          </td>
                        ) : (
                          <td key={`k_${key}`} className={classes.breakAllWrapper}>
                            { 
                              settings.breaks ? (
                                <BreakBlock day={day} hour={hour.hour} isFirstBreakZero={isFirstBreakZero}/>
                              ) : null
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TimetableTable;