import AuthenticatedImage from '../AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import React from 'react';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setEmployeeModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  isSelected?: boolean;
  isDisabled?: boolean;
  disableClick?: any;
  size?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
    gap: '8px',
    cursor: (props: Props) => props.disableClick ? 'auto' : 'pointer',
    '& > div': {
      '& > div': {
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: (props: Props) => {
          if(props.isSelected) return theme.colors.primaryBlue[500];
          else return 'transparent';
        },
        borderRadius: '12px',
        width: 'calc(100% - 6px)',
        height: 'calc(100% - 6px)',
      },
    },
    '& > span': {
      color: (props: Props) => {
        if(props.isSelected) return theme.colors.primaryBlue[500];
        else return '#808080';  
      },
      fontWeight: (props: Props) => {
        if(props.isSelected) return 'bold';
        else return 'normal';
      },
    }
  },
  avatarWrapper: {
    position: 'relative',
    '& > div': {
      '& > div': {
        backgroundColor: theme.colors.white,
        filter: (props: Props) => {
          if(!props.isSelected) return 'grayscale(100%)';
          else return 'grayscale(0%)';
        },
      },
    },
  },
  avatar: {
    display: 'block',
    minWidth: (props: Props) => props.size + 'px',
    width: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',  
    borderRadius: '12px',
    '& > div': {
      backgroundColor: theme.colors.white,
      borderRadius: '0px',
      borderColor: 'unset',
      borderWidth: 'unset',
      borderStyle: 'unset',
    },

  },
  archivedBadge: {
    width: (props: Props) => (props.size / 2) + 'px',
    height: (props: Props) => (props.size / 2) + 'px',
    backgroundColor: theme.colors.grey[200],
    position: 'absolute',
    right: (props: Props) => '-' + (props.size / 15) + 'px',
    bottom: (props: Props) => '-' + (props.size / 15) + 'px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      width: (props: Props) => (props.size / 3) + 'px',
      height: (props: Props) => (props.size / 3) + 'px',
      color: theme.colors.black + ' !important',
      transform: 'scale(0.8)' 
    }
  },
  fullname: {
    maxWidth: '100%',
    fontSize: '13px',
    lineHeight: '13px',
    height: '26px',
    textAlign: 'center',
    marginTop: '6px',
  },
}));

type EmployeeInfoType = {
  employeeID: any;
  isSelected?: boolean;
  isDisabled?: boolean;
  disableClick?: boolean;
  defaultEmployeeData?: any;
  isVisibleArchived?: any;
  useModal?: any;
  onClick?: any;
  size?: any;
};

const EmployeeInfoItem: React.FunctionComponent<EmployeeInfoType> = ({ employeeID, isSelected, isDisabled, disableClick, defaultEmployeeData, isVisibleArchived, useModal, onClick, size = 60 }) => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const employeeData = dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? (defaultEmployeeData ? defaultEmployeeData : []) : (defaultEmployeeData ? Object.assign({}, defaultEmployeeData, dataData.employees.find((item: any) => item.employeeID === employeeID)) : dataData.employees.find((item: any) => item.employeeID === employeeID));

  const classes = useStyles({
    isSelected: isSelected,
    isDisabled: isDisabled,
    size: size,
  });

  const dispatch = useAppDispatch();
  const onClickAction = disableClick ? null : onClick;

  const onModalAction = () => {
    const settings = {
      isOpen: true,
      employeeID: employeeID,
    };
    dispatch(setEmployeeModal(settings));
  };
    
  return (
    <div className={classes.root} onClick={(!disableClick && !isDisabled) ? (useModal ? onModalAction : onClickAction) : undefined}>
      <div className={classes.avatarWrapper}>
        <AuthenticatedImage thumbLink={employeeData.photo.thumbLink} className={classes.avatar} dataCy="employeePhoto"/>
        {
          (isVisibleArchived && employeeData.isArchived) ? (
            <TooltipIcon icon="archived" title={t('archived')} position='bottom' className={classes.archivedBadge} dataCy={isCypress() ? `childArchived` : null}/>
          ) : null
        }
      </div>
      <Clamp withTooltip lines={2}>
        <span className={classes.fullname}>{employeeData.firstname} {employeeData.surname}</span>
      </Clamp>
    </div>
  );
};

export default EmployeeInfoItem;