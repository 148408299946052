import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SchoolButton from 'src/components/Buttons/SchoolButton';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setSchoolUrlsModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    minHeight: '300px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    alignItems: 'center',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
  },
}));

const SchoolUrlsModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const title = modalsData.schoolUrlsModal.title ? modalsData.schoolUrlsModal.title : ""
  const schoolUrls = modalsData.schoolUrlsModal.urls ? modalsData.schoolUrlsModal.urls : [];
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      urls: [],
      title: "",
    };
    dispatch(setSchoolUrlsModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleOpenUrl = (url: any) => {
    window.open(url);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "schoolUrlsModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{title}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            schoolUrls.map((item: any, key: any) => (
              <SchoolButton key={`k_${key}`} schoolID={item.schoolID} onClick={() => handleOpenUrl(item.url)}/>
            ))
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleClose} dataCy="closeButton">
            {t("close")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default SchoolUrlsModal;