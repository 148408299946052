import HttpService from './http.service';

const getConfiguration = (language?: string) => {
  return HttpService.get('configuration', {customLanguage: language ? language : undefined}, {});
};

const getLanguage = (lang: string) => {
  return HttpService.get(`dictionary/?lang=${lang}&platform=webapp&format=json&module=webapp`, {}, {});
};

export {
  getConfiguration,
  getLanguage,
};