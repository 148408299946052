import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Button from '@mui/material/Button';
import config from 'src/constants/config';
import Currency from 'react-currency-formatter';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setShopItemVariantModal } from 'src/store/actions/modals.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  buttonType?: any;
  disabled: any;
  size: any;
  image: any;
};

const useStyles = createUseStyles((theme: any) => ({
  shopItemButtonWrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    minWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',
    borderRadius: (props: Props) => (props.size / 18) + 'px',
  },
  shopItemButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
    gap: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: theme.colors.white,
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
    color: (props: Props) => {
      if(props.buttonType) return theme.colors.black;
      else return 'none';
    },
    textTransform: 'unset',
  },
  image: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
  },
  imagePlaceholder: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/resources/images/image_placeholder.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: (props: Props) => {
      if(props.image) return 'rgba(255,255,255,0.3)';
      else return '';
    },
    pointerEvents: 'none',
  },
  overlay: {
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
    },
  },
  name: {
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[500];
      else return theme.colors.black;
    },
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: theme.colors.white,
    borderRadius: '14px',
    padding: '2px 8px',
    maxWidth: 'calc(75% - 32px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  price: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[500];
      else return theme.colors.black;
    },
    fontSize: '12px',
    fontWeight: '400',
    backgroundColor: theme.colors.white,
    borderRadius: '14px',
    padding: '2px 8px',
    maxWidth: 'calc(100% - 32px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cartButton: {
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[500];
      else return theme.colors.primaryBlue[500];
    },
    backgroundColor: theme.colors.white,
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[200],
    },
  },
}));

type ShopItemButtonType = {
  className?: any;
  data?: any;
  image?: any;
  onClick?: any;
  dataCy?: string;
  disabled?: boolean;
  size?: any;
};

const ShopItemButton: React.FunctionComponent<ShopItemButtonType> = ({className, data, image, onClick, dataCy, disabled, size = 256}) => {
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const schoolID = stockData.school.schoolID;
  const schoolCart = stockData.cart;

  const schoolSettings = userData.schoolSettings;

  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];

  let buttonType = "default";
  if(buttonType === "disabled") disabled = true;
  if(disabled) buttonType = "disabled";

  const classes = useStyles({
    buttonType,
    disabled,
    size,
    image,
  });

  const tax = data.isWithoutTax ? 1 : (data.taxRate / 100) + 1;
  const price: any = data.variant.length === 0 ? (data.price * tax) : (Math.min(...data.variant.map((item: any) => { return (item.price * tax); })) === Math.max(...data.variant.map((item: any) => { return (item.price * tax); })) ? (data.variant[0].price * tax) : [Math.min(...data.variant.map((item: any) => { return (item.price * tax); })), Math.max(...data.variant.map((item: any) => { return (item.price * tax); }))]);

  const handleCart = () => {
    let showNotify = true;
    if(data.variant.length === 0) {
      const cartItems = schoolCart.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : stockData.cart.find((item: any) => item.schoolID === schoolID).items;
      const isExist = cartItems.filter((item: any) => item.itemID === data.itemID).length === 0 ? false : true;
      let newSchoolCart: any = schoolCart;
      if(isExist) {
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = item.items.map((subItem: any) => {
              if(subItem.itemID === data.itemID) {
                const newQuantity = subItem.quantity + 1;
                if(newQuantity > config.MAX_ITEMS_IN_CART) {
                  createNotification(t('stock_shop_item_exceeded', {max: config.MAX_ITEMS_IN_CART}), "info");
                  showNotify = false;
                }
                return {...subItem, quantity: newQuantity > config.MAX_ITEMS_IN_CART ? config.MAX_ITEMS_IN_CART : newQuantity};
              } else {
                return subItem;
              }
            });
            return { schoolID: schoolID, items: newItems };
          } else {
            return item;
          }
        });
      } else {
        const newItemToCart = {
          itemID: data.itemID,
          quantity: 1,
        };
        newSchoolCart = newSchoolCart.map((item: any) => {
          if(item.schoolID === schoolID) {
            const newItems = [...item.items, newItemToCart];
            return { schoolID: schoolID, items: newItems };
          } else {
            return item;
          }
        });
      }
      dispatch(setStockCart(newSchoolCart));
      if(showNotify) createNotification(t('stock_item_added_to_cart'), 'success');
    } else {
      const settings = {
        isOpen: true,
        itemID: data.itemID,
        schoolID: schoolID,
      };
      dispatch(setShopItemVariantModal(settings));
    }
  };

  return (
    <div className={classes.shopItemButtonWrapper}>
      <Button type="button" className={`${classes.shopItemButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={-1} data-cy={isCypress() ? dataCy : null}>
        {
          image ? (
            <AuthenticatedImage className={classes.image} thumbLink={image}/>
          ) : (
          <div className={classes.imagePlaceholder}/>
          )
        }
      </Button>
      <div className={classes.content}>
        <span className={classes.price}>
          {
            Array.isArray(price) ? (
              <>
              <Currency quantity={parseFloat(price[0])} currency={getCurrency.iso}/>
              &nbsp;-&nbsp;
              <Currency quantity={parseFloat(price[1])} currency={getCurrency.iso}/>
              </>
            ) : (
              <Currency quantity={parseFloat(price)} currency={getCurrency.iso}/>
            )
          }
        </span> 
        <span className={classes.name}>{data.name}</span> 
      </div>
      <div className={classes.overlay}>
        <IconButton className={classes.cartButton} tooltip={t('stock_add_to_cart')} tooltipMaxWidth={75} onClick={handleCart}>
          <SVG src="shopping-cart-add"/>
        </IconButton>
      </div>
    </div>
  );
};

export default ShopItemButton;