import config from 'src/constants/config';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { setIsOpenSetupScreen } from 'src/store/actions/layout.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '16px',
    marginBottom: '96px',
    '& > h1': {
      fontWeight: '400',
      color: theme.colors.black,
    },
    '& > p': {
      color: theme.colors.grey[500],
      width: '80%',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  iconPlaceholder: {
    position: 'relative',
    width: '50%',
    paddingTop: '50%',
    '& > svg': {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const Finish: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(setIsOpenSetupScreen(false));
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.content}>
          <h1>{t('setup_finished')}</h1>
          <p>{t('setup_finished_info')}</p>
          <NormalButton buttonType='primary' onClick={handleClose}>
            {t('setup_close')}
          </NormalButton>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.icon}>
          <div className={classes.iconPlaceholder}>
            <SVG src="checkmark-circle"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finish;