import ExcuseNoteCard from 'src/components/Cards/ExcuseNoteCard';
import ExcuseNoteGroupCard from 'src/components/Cards/ExcuseNoteGroupCard';
import Failed from 'src/components/Layouts/Failed';
import moment from 'src/utils/moment';
import NoResults from 'src/components/Layouts/NoResults';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import { CircularProgress, Skeleton } from '@mui/material';
import { clearExcuseNotesList, clearExcuseNotesMonths, setExcuseNotesList } from 'src/store/actions/excusenotes.actions';
import { createUseStyles } from 'react-jss';
import { setExcuseNotesDetailModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  todayExcuseNotes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
  },
  absenceCard: {
    boxShadow: 'none !important',
    borderRadius: '0  !important',
    width: '100% !important',
    marginBottom: '0 !important',
    overflow: 'unset !important',
  },
  absenceCardGroup: {
    boxShadow: 'none !important',
    borderRadius: '0  !important',
    width: '100% !important',
    marginBottom: '0 !important',
    overflow: 'unset !important',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: theme.colors.chip,
    maxHeight: '40vh',
    overflow: 'auto',
  },
  moreButton: {
    margin: '0px 8px 8px 8px',
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  failedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '25px 0',
  },
  failed: {
    width: '100%',
    padding: '100px 0 50px 0',
    '& > svg': {
      width: '64px',
      height: '64px',
    },
    '& > span': {
      fontSize: '18px',
      color: theme.colors.systemRed[500],
      marginTop: '8px',
    },
  },
  noResults: {
    width: '100%',
    padding: '100px 0 50px 0',
    '& > svg': {
      width: '64px',
      height: '64px',
    },
    '& > span': {
      fontSize: '18px',
      color: theme.colors.black,
      marginTop: '8px',
    },
  },
  excuseNoteCard: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    borderRadius: '0px',
    marginBottom: '0px',
    boxShadow: 'none',
    overflow: 'unset',
    padding: '16px',
    width: '100%',
    maxWidth: 'calc(100% - 32px)',
    gap: '24px',
    cursor: 'pointer',
  },
  leftSide: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  reason: {
    display: 'flex',
    width: '72px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  smallChild: {
    position: 'absolute',
    right: '-6px',
    bottom: '-6px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '5px',
    '& > div': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
    },
  },
  chevronSide: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    marginLeft: 'auto',
  },
}));

const TodayExcuseNotes: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const absenceService = useAppSelector((state: any) => state.services).absenceService;
  
  const [state, setState, setStates] = useStates({
    isLoading: true,
    isFailed: false,
    absenceList: [],
  });

  const absenceList = state.isLoading ? [] : state.absenceList;

  const loadExcuseNotes = useCallback(() => {
    setState("isLoading", true);
    const allChildrenID = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass).map((item: any) => { return item.childID; }).join(",");
    const today = moment().format("YYYY-MM-DD");
    absenceService && absenceService.listAbsenceRange(allChildrenID, today, today).then((result: any) => {
      if(result && result.data && result.data.absence) {
        const absenceData = result.data.absence;
        let newList: any = [];
        let childrenData: any = [];
        allChildrenID.split(",").forEach((child: any) => {
          const newChild = {date: moment(today).format("YYYY-MM"), childID: parseInt(child), dates: []};
          if(childrenData.filter((children: any) => children.childID === child).length === 0) {
            childrenData = [...childrenData, newChild];
          }
        });
        childrenData.forEach((childData: any) => {
          newList = [...newList, {...childData, dates: absenceData.filter((data: any) => data.childID === childData.childID && moment(data.date).format("YYYY-MM-DD") === moment(today).format("YYYY-MM-DD"))}];
        });
        dispatch(setExcuseNotesList(newList)); 
        setStates({
          absenceList: absenceData,
          isLoading: false,
          isFailed: false,
        });
      } else {
        setState("isFailed", true);
      }
    }).catch(() => {
      setState("isFailed", true);
    });
  }, [absenceService, dispatch, setState, setStates, dataData.children]);

  useEffect(() => {
    loadExcuseNotes();
    return () => {
      dispatch(clearExcuseNotesList());
      dispatch(clearExcuseNotesMonths());
    }
  }, [dispatch, loadExcuseNotes], []);

  const handleExcuseNotes = () => {
    navigate('/excuse-notes');
  };

  const handleOpenExcuseDetail = (absenceID: any, childID: any, type: any) => {
    const settings = {
      isOpen: true,
      absenceID: absenceID,
      childID: childID,
      type: type,
      readOnly: false,
    };
    dispatch(setExcuseNotesDetailModal(settings));
  };
  
  return (state.isLoading && !layoutData.isDashboardEditable) ? (
    <div className={classes.loading}>
      <div className={classes.spinner}>
        <CircularProgress/>
      </div>
    </div>
  ) : (
    <div className={classes.todayExcuseNotes}>
      {
        state.isFailed ? (
          <div className={classes.failedWrapper}>
            <Failed className={classes.failed} title={t('widget_failed_load')}/>
            <NormalButton className={classes.moreButton} onClick={loadExcuseNotes}>
              {t('try_again')}
            </NormalButton>
          </div>
        ) : (
          <>
            <div className={classes.list}>
              {
                layoutData.isDashboardEditable ? (
                  <>
                    {
                      [0,1,2,3,4].map((_: any, key: any) => (
                        <div className={classes.excuseNoteCard} key={`k_${key}`}>
                          <div className={classes.leftSide}>
                            <div className={classes.wrapper}>
                              <div className={classes.reason}>
                                <Skeleton variant="rectangular" animation="wave" width={56} height={56}/>
                                <Skeleton className={classes.smallChild} variant="circular" animation="wave" width={24} height={24}/>
                              </div>
                            </div>
                          </div>
                          <div className={classes.rightSide}>
                            <Skeleton variant="text" animation="wave" width={150} height={25}/>
                            <div>
                              <Skeleton variant="circular" animation="wave" width={16} height={16}/>
                              <Skeleton variant="text" animation="wave" width={85} height={25}/>
                            </div>
                          </div>
                          <div className={classes.chevronSide}>
                            <Skeleton variant="rectangular" animation="wave" width={24} height={24}/>
                          </div>
                        </div>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      absenceList.length > 0 ? (
                        <>
                          {
                            absenceList.map((x: any) => { return {...x}}).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.absenceID - a.absenceID; return moment(b.date).unix() - moment(a.date).unix(); }).map((absence: any, key: any) =>
                            absenceList.filter((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID).length > 1 ? (
                              absenceList.filter((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID).findIndex((thisAbsence: any) => thisAbsence.absenceGroupID === absence.absenceGroupID && thisAbsence.absenceID === absence.absenceID) === 0 ? (
                                <ExcuseNoteGroupCard className={classes.absenceCardGroup} absence={{absenceGroupID: absence.absenceGroupID, date: absence.date, childID: absence.childID}} handleOpenExcuseDetail={handleOpenExcuseDetail} onClick={() => handleOpenExcuseDetail(absence.absenceID, absence.childID, "group")} key={`k_${key}`}/>
                              ) : null) : (
                                <ExcuseNoteCard className={classes.absenceCard} absence={{absenceID: absence.absenceID, date: absence.date, childID: absence.childID}} onClick={() => handleOpenExcuseDetail(absence.absenceID, absence.childID, "single")} key={`k_${key}`}/>
                              )
                            )
                          }
                        </>
                      ) : (
                        <NoResults className={classes.noResults} title={t('no_excuse_notes_found')}/>
                      )
                    }
                  </>
                )
              }
            </div>
            <NormalButton className={classes.moreButton} onClick={handleExcuseNotes}>
              {absenceList.length > 0 ? t('view_more_excuse_notes') : t('go_to_excuse_notes')}
            </NormalButton>
        </>
        )
      }
    </div>
  );
};

const TodayExcuseNotesTitle: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return t('today_excuse_notes');
};

export { TodayExcuseNotes, TodayExcuseNotesTitle };