import AuthenticatedImage from '../../Items/AuthenticatedImage';
import BlockPicker from '../../Forms/BlockPicker';
import ChildrenInput from '../../Forms/ChildrenInput';
import ChildrenSelect from '../../Selects/ChildrenSelect';
import CloseButton from 'src/components/Buttons/CloseButton';
import config from '../../../constants/config';
import DateFormat from '../../../utils/dateFormat';
import DatePicker from '../../DatePickers/ExcuseNotes';
import Input from '../../Forms/Input';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import RadioGroup from '../../Forms/RadioGroup';
import React, { useCallback, useMemo, useRef } from 'react';
import SVG from '../../Images/SvgRenderer';
import { addExcuseNotesList, updateExcuseNotesGroupList, updateExcuseNotesList } from '../../../store/actions/excusenotes.actions';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setExcuseNotesHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    margin: "20px 0 40px 0",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    height: '100%',
  },
  footer: {
    display: "flex",
    gap: '15px',
    padding: "20px",
    justifyContent: "flex-end",
    flexWrap: 'wrap',
    width: 'calc(100% - 40px)',
    '& > button': {
      width: 'fit-content',
    },
  },
  items: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '10px',
    },
  },
  item: {
    display: 'flex',
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  childrenCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    marginLeft: "10px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '19px',
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '16px',
    }
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '50px',
      minHeight: '50px',
      width: '50px',
      height: '50px',
      maxWidth: '50px',
      maxHeight: '50px',
    },
    '& > p': {
      fontSize: '14px',
      marginLeft: '16px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  nickname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  timezones: {
    padding: "0 25px",
    width: 'calc(100% - 50px)',
    '&:empty': {
      display: 'none',
    }
  },
  timezone: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      color: '#C2C4D9',
      fontSize: '13px',
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '11px',
      },
      '& > svg': {
        width: '18px',
        height: '18px',
        marginRight: '4px',
        marginLeft: '-2px',
        marginTop: '-2px',
      },
    },
  },
  customChildrenSelect: {
    backgroundImage: theme.colors.gradient,
    overflow: 'auto',
    maxHeight: 'calc(100vh - 400px)',
  },
  customSearch: {
    padding: '0 24px 24px 24px',
  },
  customSchoolList: {
    maxHeight: 'unset !important',
  },
}));


const ExcuseNotesHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const absenceService = useAppSelector((state: any) => state.services).absenceService;

  const curChild = modalsData.excuseNotesHandleModal.child;
  const curDate = modalsData.excuseNotesHandleModal.date;
  const isEdit = modalsData.excuseNotesHandleModal.absenceID !== null || modalsData.excuseNotesHandleModal.absenceGroupID !== null;
  const editType = isEdit ? (modalsData.excuseNotesHandleModal.absenceGroupID !== null ? "absenceGroupID" : "absenceID"): null;
  const editValue = useMemo(() => { return isEdit ? (editType === "absenceGroupID" ? [modalsData.excuseNotesHandleModal.absenceID, modalsData.excuseNotesHandleModal.absenceGroupID] : modalsData.excuseNotesHandleModal.absenceID) : null}, [editType, isEdit, modalsData.excuseNotesHandleModal.absenceGroupID, modalsData.excuseNotesHandleModal.absenceID]);
  const list = excusenotesData.list;
  const absenceTypes = configurationData.configuration.absenceTypes;
  const availableChildren = excusenotesData.currentChildrenData;
  const schoolSettings = userData.schoolSettings;

  const absenceDataAll = useMemo(() => { return (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? list.find((data: any) => data.childID === curChild.childID).dates : []) : []}, [curChild, list]);
  const absenceDataID = (absenceDataAll.length && editValue !== null) > 0 ? (absenceDataAll.filter((data: any) => data.absenceID === editValue).length > 0 ? absenceDataAll.find((data: any) => data.absenceID === editValue): {}) : {};
  const absenceDataGroupID = (absenceDataAll.length && editValue !== null && editValue.length === 2) > 0 ? (absenceDataAll.filter((data: any) => data.absenceID === editValue[0] && data.absenceGroupID === editValue[1]).length > 0 ? absenceDataAll.find((data: any) => data.absenceID === editValue[0] && data.absenceGroupID === editValue[1]): {}) : {};
  const absenceData: any = isEdit ? (editType === "absenceID" ? absenceDataID : absenceDataGroupID) : null;
  const absenceGroupItems = useMemo(() => { return (absenceDataAll.length > 0 && editValue !== null && editValue.length === 2) ? absenceDataAll.filter((data: any) => data.absenceGroupID === editValue[1]) : []}, [absenceDataAll, editValue]);
  
  const isSaveChildren = useRef(false);

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const [state, setState] = useStates({
    selectedChildren: (curChild === null || curChild === undefined) ? [] : [{childID: curChild.childID, classID: curChild.classID, schoolID: curChild.schoolID}],
    isChildrenSelectOpen: false,
    startDate: isEdit ? (editType === "absenceID" ? absenceData.startDate : absenceGroupItems[0].startDate) : curDate,
    endDate: isEdit ? (editType === "absenceID" ? absenceData.endDate : absenceGroupItems[absenceGroupItems.length - 1].endDate) : curDate,
    partOfDay: isEdit ? absenceData.absencePartID.toString() : "1",
    absenceType: isEdit ? absenceData.absenceTypeID : 1,
    absenceNote: isEdit ? absenceData.note : "",
    canClick: true,
  });

  const enableNotes = state.selectedChildren.length > 0 ? ((getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence && getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence.allowNotes) ? getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence.allowNotes : false) : false;
  const enablePartOfDay = state.selectedChildren.length > 0 ? ((getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence && getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence.allowDayParts) ? getSchoolSettings(getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence.allowDayParts : false) : false;
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      date: null,
      child: null,
      absenceID: null,
      absenceGroupID: null,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  useEffect(() => {
    if(curChild === null && curChild === undefined) {
      setState("selectedChildren", curChild);  
    }
  }, [curChild, setState], [curChild]);

  useEffect(() => {
    if(curDate === null && curDate === undefined) {
      setState("startDate", isEdit ? (editType === "absenceID" ? absenceData.startDate : absenceGroupItems[0].startDate) : curDate);  
      setState("endDate", isEdit ? (editType === "absenceID" ? absenceData.endDate : absenceGroupItems[absenceGroupItems.length - 1].endDate) : curDate);  
    }
  }, [curDate, absenceData, absenceGroupItems, editType, isEdit, setState], [curDate]);

  const openChildrenSelect = () => {
    isSaveChildren.current = false;
    setState("isChildrenSelectOpen", true);
  };
  
  const closeChildrenSelect = () => {
    isSaveChildren.current = false;
    setState("isChildrenSelectOpen", false);
  };

  const selectChildrenSelect = () => {
    isSaveChildren.current = true;
    setState("isChildrenSelectOpen", false);
  };

  const setSelectedChildrenFromSelect = (value: any) => {
    if(isSaveChildren.current) {
      setState("selectedChildren", value);
    }
  };

  const setSelectedChildren = (value: any) => {
    setState("selectedChildren", value);
  };

  const uniqueChildren = (children: any) => {
    let uniqueChildrenList: any = [];
    children.forEach((child: any) => {
      if(uniqueChildrenList.indexOf(child.childID) === -1) {
        uniqueChildrenList.push(child.childID);
      }
    });
    return uniqueChildrenList; 
  };

  const partsOfDay: any = [
    {
      name: t('morning'),
      value: '2',
      dataCy: 'morningRadio',
    },
    {
      name: t('whole_day'),
      value: '1',
      dataCy: 'wholeDayRadio',
    },
    {
      name: t('afternoon'),
      value: '3',
      dataCy: 'afternoonRadio',
    },
  ];

  const handlePartOfDay = (e: any) => {
    setState("partOfDay", e.target.value);
  };

  const handleAbsenceType = (value: any) => {
    setState("absenceType", value);
  };

  const handleAbsenceNote = (name: any, value: any) => {
    setState("absenceNote", value);
  };

  const handleCreate = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    if(moment(state.startDate).isSameOrAfter(moment(),'day') && moment(state.startDate).isSameOrBefore(moment().add(180,'days'),'day') && moment(state.endDate).isSameOrAfter(moment(),'day') && moment(state.endDate).isSameOrBefore(moment().add(180,'days'),'day')) {
      const payload = {
        childrenID: uniqueChildren(state.selectedChildren),
        dateStart: moment(state.startDate).format("YYYY-MM-DD"),
        dateEnd: moment(state.endDate).format("YYYY-MM-DD"),
        absencePartID: parseInt(state.partOfDay),
        absenceTypeID: parseInt(state.absenceType),
        note: enableNotes ? state.absenceNote : "",
      };
      absenceService && absenceService.createAbsence(payload).then((result: any) => {
        if(result.status === 200) {
          createNotification(t("excuse_note_added"), "success");
          dispatch(addExcuseNotesList(result.data.absence));
          setState("canClick", true);
          onCloseModal();
        } else {
          createNotification(t("excuse_note_not_added"), "error");  
          setState("canClick", true);
        }
      }).catch(() => {
        createNotification(t("excuse_note_not_added"), "error");
        setState("canClick", true);
      });
    } else {
      createNotification(t("excuse_note_not_added_past"), "error");
      setState("canClick", true);
    }
  };

  const handleEdit = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    const payload = {
      absencePartID: parseInt(state.partOfDay),
      absenceTypeID: parseInt(state.absenceType),
      note: state.absenceNote,
    };
    absenceService && absenceService.editAbsence(absenceData.absenceID, payload).then((result: any) => {
      const absences = result.data.absence;
      if(result.status === 201) {
        createNotification(t("excuse_note_updated"), "success");
        const settings = {
          absenceID: absenceData.absenceID,
          childID: absenceData.childID,
          absencePartID: enablePartOfDay ? parseInt(state.partOfDay) : 1,
          absenceTypeID: parseInt(state.absenceType),
          note: state.absenceNote,
          absences: absences,
        };
        dispatch(updateExcuseNotesList(settings));
        setState("canClick", true);
        onCloseModal();
      } else {
        createNotification(t("excuse_note_not_updated"), "error");   
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("excuse_note_not_updated"), "error");
      setState("canClick", true);
    });
  };

  const handleEditGroup = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    const payload = {
      absencePartID: parseInt(state.partOfDay),
      absenceTypeID: parseInt(state.absenceType),
      note: state.absenceNote,
    };
    absenceService && absenceService.editAbsenceGroup(absenceData.absenceGroupID, payload).then((result: any) => {
      if(result.status === 201) {
        createNotification(t("excuse_note_group_updated"), "success");
        const settings = {
          absenceGroupID: absenceData.absenceGroupID,
          childID: absenceData.childID,
          //data: result.data.absence,
          absencePartID: parseInt(state.partOfDay),
          absenceTypeID: parseInt(state.absenceType),
          note: state.absenceNote,
        };
        dispatch(updateExcuseNotesGroupList(settings));
        setState("canClick", true);
        onCloseModal();
      } else {
        createNotification(t("excuse_note_group_not_updated"), "error");  
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("excuse_note_group_not_updated"), "error");
      setState("canClick", true);
    });
  };

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const schoolTimezones = () => {
    let schools: any = {};
    state.selectedChildren.forEach((child: any) => {
      getChildData(child.childID).schoolsID.forEach((theSchool: any) => {
        const schoolName = getSchoolData(theSchool) ? getSchoolData(theSchool).name : '';
        const timezone = getSchoolSettings(theSchool, 'timeZone', schoolSettings) === null ? config.APP_TIMEZONE : getSchoolSettings(theSchool, 'timeZone', schoolSettings);
        if(moment().tz(timezone).format("YYYYMMDDHHmmss") !== moment().tz(config.APP_TIMEZONE).format("YYYYMMDDHHmmss")) {
          schools = {...schools, [theSchool]: {name: schoolName, timezone: timezone}};
        }
      });
    });
    return schools;
  };
  
  const setStartDate = (value: any) => {
    setState("startDate", value);
  };

  const setEndDate = (value: any) => {
    setState("endDate", value);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        (!isEdit || absenceData !== null) ? (
          <div className={classes.root} data-cy={isCypress() ? "excuseNotesHandleModal" : null}>
            <div className={classes.header}>
              <div className={classes.wrapper}>
                <p data-clarity-unmask="true">{isEdit ? (editType === "absenceID" ? (t('edit_absence') + ' ' + DateFormat(moment(state.startDate), "default", languageData, t)) : (t('edit_absence_group') + ' ' + DateFormat(moment(state.startDate), "default", languageData, t)  + ' - ' + DateFormat(moment(state.endDate), "default", languageData, t))) : (moment(state.startDate).isSame(moment(state.endDate), 'day') ? t('add_absence') : t('add_absence_group'))}</p>
              </div>
              <CloseButton onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.body}>
              {
                state.isChildrenSelectOpen ? (
                  <ChildrenSelect
                    setSelectedChildren={setSelectedChildrenFromSelect}
                    selectedChildren={state.selectedChildren.map((item: any) => { return { childID: item.childID }; })}
                    isSelectAll={true}
                    isSelectInAllClass={true}
                    isMultipleSelect={true}
                    isAllowArchived={false}
                    isAllowInactiveClasses={false}
                    isAllowArchivedToggle={false}
                    isAllowSearch={true}
                    validateAbsence={true}
                    defaultChildren={excusenotesData.viewMode === "class" ? availableChildren : null}
                    mode="select"
                    customClasses={{childrenSelect: classes.customChildrenSelect, search: classes.customSearch, schoolList: classes.customSchoolList}}
                    customCombineMessage={t('cannot_combine_excuse_notes')}
                  />
                ) : (
                  <>
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="user-circle-outlined"/>
                        {isEdit ? t('child') : t('children') + '*'}
                        {
                          !isEdit ? (
                            <span className={classes.childrenCount}>
                              <SVG src="people"/>
                              <span data-clarity-unmask="true">{uniqueChildren(state.selectedChildren).length}</span>
                            </span>
                          ) : null
                        }
                      </span>
                      {
                        isEdit ? (
                          <div className={classes.childPhoto}>
                            <AuthenticatedImage thumbLink={curChild.photo?.thumbLink}/>
                            <p className={classes.nickname}>{curChild.displayName}</p>
                          </div>
                        ) : (
                          <ChildrenInput selectedChildren={state.selectedChildren} setSelectedChildren={setSelectedChildren} onClick={openChildrenSelect}/>
                        )
                      }
                    </div>
                    {
                      !isEdit ? (
                        <>
                        <div className={classes.timezones}>
                          {
                            Object.keys(schoolTimezones()).length > 0 ? (
                              <>
                              {
                                  Object.keys(schoolTimezones()).map((schoolTimezone: any, key: any) => moment().tz(schoolTimezones()[schoolTimezone].timezone).format("YYYYMMDDHHmmss") !== moment().tz(config.APP_TIMEZONE).format("YYYYMMDDHHmmss") ? (
                                    <div className={classes.timezone} key={`k_${key}`}>
                                    {
                                      Object.keys(schoolTimezones()).length > 1 ? (
                                        <span>
                                          <SVG src="warning"/>
                                          {t('timezone_specific_school', {school: schoolTimezones()[schoolTimezone].name})}
                                        </span>
                                      ) : (
                                        <span>
                                          <SVG src="warning"/>
                                          {t('timezone_school')}
                                        </span>
                                      )
                                    }
                                    </div>
                                  ) : null)
                              }
                              </>
                            ) : null
                          }
                        </div>
                        <div className={classes.items}>
                          <div className={classes.item}>
                            <span className={classes.title}>
                              <SVG src="clock"/>
                              {t('start_absence')}*
                            </span>
                            <DatePicker presetDate={state.startDate} setDate={setStartDate} start presetDateRange={{start: state.startDate, end: state.endDate}} disabled={excusenotesData.viewMode === "class"} dataCy="datePickerStart"/>
                          </div>
                          <div className={classes.item}>
                            <span className={classes.title}>
                              <SVG src="clock"/>
                              {t('end_absence')}*
                            </span>
                            <DatePicker presetDate={state.endDate} setDate={setEndDate} end presetDateRange={{start: state.startDate, end: state.endDate}} disabled={excusenotesData.viewMode === "class"} dataCy="datePickerEnd"/>
                          </div>
                        </div>
                        </>
                      ) : null
                    }
                    {
                      enablePartOfDay ? (
                        <div className={classes.item}>
                          <span className={classes.title}>
                            <SVG src="clock-watch"/>
                            {t('part_of_day') + '*'}
                          </span>
                          <RadioGroup items={partsOfDay} value={state.partOfDay} onChange={handlePartOfDay}/>
                        </div>
                      ) : null
                    }
                    <div className={classes.item}>
                      <span className={classes.title}>
                        <SVG src="info-circle-outlined"/>
                        {t('reason') + '*'}
                      </span>
                      <BlockPicker items={absenceTypes} value={state.absenceType} valueCompare={"absenceTypeID"} onChange={handleAbsenceType}/>
                    </div>
                    {
                      (enableNotes) ? (        
                        <div className={classes.item}>
                          <p className={classes.title}>
                            <SVG src="question-mark-circle"/>
                            {t('notes')}
                          </p>
                          <Input name='notes' multiline={true} value={state.absenceNote} onChange={handleAbsenceNote} dataCy="notesInput"/>
                        </div>
                      ) : null
                    }
                </>
                )
              }
            </div>
            <div className={classes.footer}>
            {
              state.isChildrenSelectOpen ? (
                <>
                  <NormalButton buttonType="secondary" onClick={closeChildrenSelect} dataCy="cancelButton">
                    {t('cancel')}
                  </NormalButton>
                  <NormalButton buttonType="primary" onClick={selectChildrenSelect} dataCy="selectButton">
                    {t('select')}
                  </NormalButton>
                </>
              ) : (isEdit ? (
                <>
                  <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
                    {t('cancel')}
                  </NormalButton>
                  <NormalButton disabled={!state.canClick} buttonType="primary" onClick={editType === "absenceID" ? handleEdit : handleEditGroup} dataCy="saveButton">
                    {t('save')}
                  </NormalButton>
                </>
              ) : (
                <>
                  <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
                    {t('cancel')}
                  </NormalButton>
                  <NormalButton buttonType="primary" onClick={handleCreate} disabled={!state.canClick || state.selectedChildren.length === 0} dataCy="createButton">
                    {t('create')}
                  </NormalButton>
                </>
              ))
            }
            </div>
          </div>
        ) : null
      }
    </Modal>
  );
};

export default ExcuseNotesHandleModal;