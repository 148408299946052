import config from 'src/constants/config';
import htmlParse from 'html-react-parser';
import Laptop from 'src/components/Devices/Laptop';
import NormalButton from 'src/components/Buttons/NormalButton';
import PreviewApp from 'src/components/Layouts/PreviewApp';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import { createUseStyles } from 'react-jss';
import { getUserSettingCategory } from 'src/utils/useUser';
import { setIsOpenSetupScreen } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import Mobile from 'src/components/Devices/Mobile';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: 'unset',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '96px',
    gap: '16px',
    '& > span': {
      fontSize: '36px',
      width: '80%',
      '& > span': {
        color: theme.colors.primaryBlue[500],
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
    },
    '& > p': {
      color: theme.colors.grey[500],
      width: '80%',
    },
    '& > div': {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      width: '80%',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  laptop: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) scale(1.2)',
    left: '20%',
  },
  mobile: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) scale(0.9)',
    maxHeight: '100%',
    left: '10%',
  },
}));

type SectionType = {
  handleNext: any;
};

const Default: React.FunctionComponent<SectionType> = ({ handleNext }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const setupStatus = getUserSettingCategory(userData.userSettings, "setup") ? getUserSettingCategory(userData.userSettings, "setup").value : [];
  const isInProcess = (setupStatus && setupStatus.length) > 0 ? setupStatus.filter((item: any) => item.value !== null).length !== 0 : false;

  const breakpoint: any = useBreakpoint();

  const devicesToShow: any = {
    xxxxl: "laptop",
    xxxl: "laptop",
    xxl: "laptop",
    xl: "laptop",
    lg: "laptop",
    bg: "laptop",
    md: "mobile",
    co: "mobile",
    sm: "none",
    xs: "none",
  };

  const deviceToShow = devicesToShow[breakpoint];

  const displayName = `${userData.userObject.firstname} ${userData.userObject.surname}`;

  const handleClose = () => {
    dispatch(setIsOpenSetupScreen(false));
  };

  const handleContinue = () => {
    handleNext();
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.info}>
          <span>{htmlParse(t('hello_title', {name: displayName}))}</span>
          <p>
            {isInProcess ? t('setup_continue_text') : t('setup_welcome_text')}
          </p>
          <div>
            <NormalButton buttonType="primary" onClick={handleContinue}>
              {isInProcess ?  t('setup_continue') : t('setup_start')}
            </NormalButton>
            <NormalButton buttonType='tertiary' onClick={handleClose}>
              {t('setup_later')}
            </NormalButton>
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        {
          deviceToShow === "laptop" ? (
            <Laptop className={classes.laptop}>
              <PreviewApp layout="desktop"/>
            </Laptop>
          ) : deviceToShow === "mobile" ? (
            <Mobile className={classes.mobile}>
              <PreviewApp layout="mobile"/>
            </Mobile>
          ) : null
        }
      </div>
    </div>
  );
}

export default Default;