import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useRef } from 'react';
import RenderAll from './RenderAll';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  employeesSelect: {
    width: '100%',
    '&.inModal': {
      display: 'none',
    },  
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type EmployeesSelectType = {
  onClickEmployee?: any;
  setSelectedEmployees?: any;
  selectedEmployees?: any;
  defaultSchools?: any;
  defaultEmployees?: any;
  defaultSchoolsData?: any;
  defaultEmployeesData?: any; 
  isInModal?: boolean;
  isModalOpen?: boolean;
  isMultipleSelect?: boolean;
  isSelectAll?: boolean;
  isSelectInAllSchool?: boolean;
  mode?: string;
  isEmployeeModal?: boolean;
  isShowEmployeesArchived?: boolean;
  isDisableTotalCount?: boolean;
  isDisableClick?: boolean;
  isAllowArchived?: boolean;
  isAllowArchivedToggle?: boolean;
  isAllowSearch?: boolean;
  isAllowOnlyOneEmployeeAtOnce?: boolean;
  modalTitle?: string;
  modalOnClose?: any;
  modalOnSave?: any;
  modalCloseOnSave?: boolean;
  modalEmployeesCount?: string;
  modalAllowEmployeesCount?: boolean;
  modalAllowCancel?: boolean;
  modalAllowClose?: boolean;
  modalAllowClear?: boolean;
  modalAllowNoEmployee?: boolean;
  customSort?: any;
  customClasses?: any;
  customNoResults?: any;
  isReload?: any;
  isCloseAllOnReload?: any;
};

const EmployeesSelect: React.FunctionComponent<EmployeesSelectType> = ({
  onClickEmployee,
  setSelectedEmployees,
  selectedEmployees,
  defaultSchools,
  defaultEmployees,
  defaultSchoolsData,
  defaultEmployeesData, 
  isInModal = false,
  isModalOpen = false,
  isMultipleSelect = false,
  isSelectAll = false,
  isSelectInAllSchool = false,
  mode = "detail",
  isEmployeeModal = false,
  isShowEmployeesArchived = false,
  isDisableTotalCount = false,
  isDisableClick = false,
  isAllowArchived = false,
  isAllowArchivedToggle = true,
  isAllowSearch = true,
  isAllowOnlyOneEmployeeAtOnce = false,
  modalTitle = "",
  modalOnClose,
  modalOnSave,
  modalCloseOnSave = true,
  modalEmployeesCount = "default",
  modalAllowEmployeesCount = true,
  modalAllowCancel = false,
  modalAllowClose = false,
  modalAllowClear = false,
  modalAllowNoEmployee = false,
  customSort,
  customClasses,
  customNoResults,
  isReload = false,
  isCloseAllOnReload = false,
}) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const schoolsData = useMemo(() => dataData.schools.map((item: any) => {
    let returnItem = item;
    if(defaultSchoolsData) {
      const isMoreData = defaultSchoolsData.filter((more: any) => more.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isMoreData) {
        const moreData = defaultSchoolsData.find((more: any) => more.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.schools, defaultSchoolsData, customSort]);
  
  const employeesData = useMemo(() => dataData.employees.map((item: any) => {
    let returnItem = item;
    if(defaultEmployeesData) {
      const moreDataCount = defaultEmployeesData.filter((more: any) => more.employeeID === returnItem.employeeID).length === 1;
      if(moreDataCount) {
        const moreData = defaultEmployeesData.find((more: any) => more.employeeID === returnItem.employeeID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.employeeID === returnItem.employeeID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.employeeID === returnItem.employeeID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.employees, defaultEmployeesData, customSort]);

  const defaultSchoolList = (defaultSchools ? defaultSchools : dataData.schools).map((item: any) => { return { schoolID: item.schoolID }; });
  const defaultEmployeeList = (defaultEmployees ? defaultEmployees : dataData.employees).map((item: any) => { return { employeeID: item.employeeID, schoolID: item.schoolID }; });

  const getEmployeeData = useCallback((employeeID: any) => {
    return employeesData.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : employeesData.find((item: any) => item.employeeID === employeeID);
  }, [employeesData]);

  const getSchoolData = useCallback((schoolID: any) => {
    return schoolsData.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : schoolsData.find((item: any) => item.schoolID === schoolID);
  }, [schoolsData]);

  const getEmployeesBySchoolID = useCallback((schoolID: any) => {
    let list = defaultEmployeeList.filter((item: any) => getEmployeeData(item.employeeID).schoolID === schoolID);
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getEmployeeData(item.employeeID).firstname.toLowerCase().includes(stateRef.current.search) || getEmployeeData(item.employeeID).surname.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getEmployeeData(item.employeeID).isArchived);
    }
    list.sort((a: any, b: any) => {
      if (getEmployeeData(a.employeeID).customSortOrder !== undefined && getEmployeeData(b.employeeID).customSortOrder !== undefined) {
        return getEmployeeData(a.employeeID).customSortOrder - getEmployeeData(b.employeeID).customSortOrder;
      } else if (getEmployeeData(a.employeeID).customSortOrder !== undefined) {
        return -1;
      } else if (getEmployeeData(b.employeeID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultEmployeeList, getEmployeeData]);

  const getEmployees = useCallback(() => {
    let list = defaultEmployeeList;
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getEmployeeData(item.employeeID).firstname.toLowerCase().includes(stateRef.current.search) || getEmployeeData(item.employeeID).surname.toLowerCase().includes(stateRef.current.search));
    }
    if(!stateRef.current.isShowArchived) {
      list = list.filter((item: any) => !getEmployeeData(item.employeeID).isArchived);
    }
    list.sort((a: any, b: any) => {
      if (getEmployeeData(a.employeeID).customSortOrder !== undefined && getEmployeeData(b.employeeID).customSortOrder !== undefined) {
        return getEmployeeData(a.employeeID).customSortOrder - getEmployeeData(b.employeeID).customSortOrder;
      } else if (getEmployeeData(a.employeeID).customSortOrder !== undefined) {
        return -1;
      } else if (getEmployeeData(b.employeeID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultEmployeeList, getEmployeeData]);

  const getSchools = useCallback(() => {
    let list = defaultSchoolList;
    list = list.filter((item: any) => getEmployeesBySchoolID(item.schoolID).length !== 0);
    list.sort((a: any, b: any) => {
      if (getSchoolData(a.schoolID).customSortOrder !== undefined && getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return getSchoolData(a.schoolID).customSortOrder - getSchoolData(b.schoolID).customSortOrder;
      } else if (getSchoolData(a.schoolID).customSortOrder !== undefined) {
        return -1;
      } else if (getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultSchoolList, getEmployeesBySchoolID, getSchoolData]);

  const activeEmployees = defaultEmployeeList.filter((item: any) => getEmployeeData(item.employeeID).isArchived === false);
  const archivedEmployees = defaultEmployeeList.filter((item: any) => getEmployeeData(item.employeeID).isArchived === true);
  
  const defaultState: any = useMemo(() => {
    return {
      data: {
        schools: defaultSchoolList,
        employees: defaultEmployeeList,
      },
      isShowArchived: isAllowArchived ? isAllowArchived : false,
      isShowArchivedToggle: isAllowArchivedToggle ? isAllowArchivedToggle : false,
      isReload: isReload,
      isCloseAllOnReload: isCloseAllOnReload,
      isLoadedAll: false,
      search: '',
      onClickEmployee: onClickEmployee,
      selectedEmployees: selectedEmployees ? selectedEmployees : [],
      defaultSelectedEmployees: selectedEmployees ? selectedEmployees : [],
      setSelectedEmployees: setSelectedEmployees,
      defaultSchools: defaultSchools,
      defaultEmployees: defaultEmployees,
      defaultSchoolsData: defaultSchoolsData,
      defaultEmployeesData: defaultEmployeesData,
      isInModal: isInModal,
      isModalOpen: isModalOpen,
      isMultipleSelect: isMultipleSelect,
      isSelectAll: isSelectAll,
      isSelectInAllSchool: isSelectInAllSchool,
      mode: mode,
      isEmployeeModal: isEmployeeModal,
      isShowEmployeesArchived: isShowEmployeesArchived,
      isDisableTotalCount: isDisableTotalCount,
      isDisableClick: isDisableClick,
      isAllowArchived: isAllowArchived,
      isAllowArchivedToggle: isAllowArchivedToggle,
      isAllowSearch: isAllowSearch,
      isAllowOnlyOneEmployeeAtOnce: isAllowOnlyOneEmployeeAtOnce,
      modalTitle: modalTitle,
      modalOnClose: modalOnClose,
      modalOnSave: modalOnSave,
      modalCloseOnSave: modalCloseOnSave,
      modalEmployeesCount: modalEmployeesCount,
      modalAllowEmployeesCount: modalAllowEmployeesCount,
      modalAllowCancel: modalAllowCancel,
      modalAllowClose: modalAllowClose,
      modalAllowClear: modalAllowClear,
      modalAllowNoEmployee: modalAllowNoEmployee,
      customClasses: customClasses,
      customNoResults: customNoResults,
      functions: {
        getEmployeeData: getEmployeeData,
        getSchoolData: getSchoolData,
        getEmployeesBySchoolID: getEmployeesBySchoolID,
        getEmployees: getEmployees,
        getSchools: getSchools,
      },
    }
  }, [isReload, isCloseAllOnReload, customClasses, customNoResults, defaultEmployeeList, defaultEmployees, defaultEmployeesData, defaultSchoolList, defaultSchools, defaultSchoolsData, getEmployeeData, getEmployees, getEmployeesBySchoolID, getSchoolData, getSchools, isAllowArchived, isAllowArchivedToggle, isAllowSearch, isEmployeeModal, isShowEmployeesArchived, isDisableClick, isDisableTotalCount, isAllowOnlyOneEmployeeAtOnce, isInModal, isModalOpen, isMultipleSelect, isSelectAll, isSelectInAllSchool, modalAllowCancel, modalAllowEmployeesCount, modalAllowClear, modalAllowClose, modalAllowNoEmployee, modalEmployeesCount, modalCloseOnSave, modalOnClose, modalOnSave, modalTitle, mode, onClickEmployee, selectedEmployees, setSelectedEmployees]);
  
  const [state, setState, setStates] = useStates(defaultState);

  const handleState = useCallback((name: any, value: any) => {
    if(Array.isArray(name) && Array.isArray(value)) {
      let stateHolder = state;
      let newObjects = {};
      name.forEach((item: any, index: any) => {
        stateHolder = {...stateHolder, [item]: value[index]};
        const newObject = {
          [item]: value[index],
        };
        newObjects = Object.assign(newObject, newObjects, newObject);
      });
      stateRef.current = stateHolder;
      setStates(newObjects);
    } else {
      stateRef.current = {...state, [name]: value};
      setState(name, value);
    }
  }, [state, setState, setStates]); 

  const stateRef = useRef(state);

  useEffect(() => {
    let stateNames: any = [];
    let stateValues: any = [];
    if(archivedEmployees.length === 0) {
      if(state.isShowArchived) {
        stateNames = [...stateNames, "isShowArchived"];
        stateValues = [...stateValues, false]; 
      }
      if(state.isShowArchivedToggle) {
        stateNames = [...stateNames, "isShowArchivedToggle"];
        stateValues = [...stateValues, false];  
      }
    }
    if(activeEmployees.length === 0 && archivedEmployees.length !== 0) {
      if(!state.isShowArchived) {
        stateNames = [...stateNames, "isShowArchived"];
        stateValues = [...stateValues, true]; 
      }
      if(state.isShowArchivedToggle) {
        stateNames = [...stateNames, "isShowArchivedToggle"];
        stateValues = [...stateValues, false];
      }
    }
    if(selectedEmployees) {
      if(selectedEmployees.length > 0) {
        if(selectedEmployees.filter((item: any) => getEmployeeData(item.employeeID).isArchived).length > 0) {
          if(!state.isShowArchived) {
            stateNames = [...stateNames, "isShowArchived"];
            stateValues = [...stateValues, true];
          }
        }
      }
    }
    stateNames = [...stateNames, "isLoadedAll"];
    stateValues = [...stateValues, true]; 
    handleState(stateNames, stateValues);
  }, [activeEmployees,archivedEmployees, handleState, selectedEmployees, state.isShowArchived, state.isShowArchivedToggle, getEmployeeData], []);
  
  useEffect(() => {
    return () => {
      if(setSelectedEmployees) setSelectedEmployees(stateRef.current.selectedEmployees);
    }
  }, [setSelectedEmployees], []);

  useEffect(() => {
    if(setSelectedEmployees) setSelectedEmployees(stateRef.current.selectedEmployees);
  }, [setSelectedEmployees], [stateRef.current.selectedEmployees]);

  useEffect(() => {
    if(state.isDisableClick !== isDisableClick) {
      handleState("isDisableClick", isDisableClick);
    }
    if(isReload) {
      const newDefaultState = {...defaultState, isLoadedAll: true};
      setStates(newDefaultState);
      stateRef.current = newDefaultState;
    }
  }, [state, handleState, defaultState, setStates, isDisableClick, isReload], [isDisableClick, isReload])

  return (
    <div className={`${classes.employeesSelect} ${isInModal ? 'inModal' : null} ${customClasses ? (customClasses.employeesSelect ? customClasses.employeesSelect : "") : ""}`}>
      {
        (!state.isLoadedAll && !state.isInModal) ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <RenderAll state={state} setState={handleState}/>
        )
      }
    </div>
  );
}

export default EmployeesSelect;