import AttendanceProgramInfoItem from 'src/components/Items/AttendanceProgramInfoItem';
import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from 'src/utils/dateFormat';
import FinanceChildItem from 'src/components/Items/FinanceChildItem';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import TabsMenu from '../../Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setChildDetailModal, setClassModal } from '../../../store/actions/modals.actions';
import { setFilterParams } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useNavigate } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-end",
    gap: '10px',
    '& > p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
    '& > span': {
      fontSize: '14px',
    },
    '& > div': {
      display: "flex",
      alignItems: "center",
      gap: '10px',
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  buttons: {
    display: 'flex', 
    gap: '0.5rem', 
  },
  button: {
    width: '36px',
    height: '36px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: "0px 20px 40px 20px",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    height: '100%',
    maxWidth: '100%',
  },
  footer: {
    display: "flex",
    gap: '15px',
    padding: "20px",
    justifyContent: "flex-end",
    width: 'calc(100% - 40px)',
    '& > button': {
      width: 'fit-content',
    },
  },
  mediumWidth: {
    width: "120px"
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.tabsWrapper,
    '& > div': {
      width: '100%',
      backgroundColor: theme.colors.white,
    },
    paddingBottom: '16px',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 32px)',
    padding: '0 16px',
  },
  appData: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  leftColumn: {
    width: '128px',
    flex: '0 0 128px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  rightColumn: {
    width: 'calc(100% - 144px)',
    flex: '0 0 calc(100% - 144px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '100%',
    },
  },
  childImage: {
    width: '128px',
    height: '128px',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
  },
  list: {
    borderSpacing: '8px',
    fontSize: '14px',
    width: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td': {
          '&:first-of-type': {
            width: '200px',
            verticalAlign: 'top',
            '& > span': {
              fontWeight: '600',
              whiteSpace: 'nowrap',
              '&::after': {
                content: `':'`,
              }, 
            },
          },
          '&:last-of-type': {
            width: 'calc(100% - 200px)',
            paddingLeft: '16px',
            '& > div': {
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              '& > span': {
                cursor: 'pointer',
              }
            },
          },
        },
      },
    },
  },
  note: {
    display: 'block',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
  representatives: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  representative: {
    width: '100%',
    borderRadius: '20px',
    borderCollapse: 'collapse',
    fontSize: '14px',
    '& > thead': {
      backgroundColor: "#ECECEC",
      borderRadius: '20px 20px 0 0',
      '& > tr': {
        '& > th': {
          padding: '12px 10px',
          whiteSpace: 'nowrap',
          textAlign: 'left',
          borderRadius: '20px 20px 0 0',
        },
      },
    },
    '& > tbody': {
      '& > tr': {
        backgroundColor: theme.colors.grey[100],
        '&:last-of-type': {
          borderRadius: '0 0 20px 20px',
          '& > td': {
            '&:first-of-type': {
              borderRadius: '0 0 0 20px',
            },
            '&:last-of-type': {
              borderRadius: '0 0 20px 0',
            },
          },
        },
        '& > td': {
          padding: '12px 10px',
          whiteSpace: 'nowrap',
          '&:first-of-type': {
            width: '300px',
            '& > span': {
              fontWeight: '600',
              '&::after': {
                content: `':'`,
              }, 
            },
          },
        },
      },
    },
  },
  phone: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  attendanceProgram: {
    width: '100%',
    marginBottom: '12px',
    position: 'relative',
    '& > div': {
      display: 'flex',
      width: '100%',
      marginTop: '16px',
    },
  },
  attendanceCell: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    color: theme.colors.grey[650], 
    '& > span': {
      marginBottom: '8px',
    },
  },
}));

const ChildDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);

  const curChild = modalsData.childDetailModal.childID;

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getSchoolData = (schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  };

  const childData = getChildData(curChild);

  const [state, setState] = useStates({
    viewMode: "app_data",
  });

  const schoolSettings = userData.schoolSettings;
  const isSchoolSubstitutionsEnabled = getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions.enable : false;

  const representatives = childData.representative.length + childData.representativePickup.length;
  const attendanceDays = useMemo(() => (childData.attendanceProgram && childData.attendanceProgram !== null && childData.attendanceProgram.days) ? childData.attendanceProgram.days.filter((day: any) => day.day === 6 || day.day === 7).length > 0 ? [t('mo'),t('tu'),t('we'),t('th'),t('fr'),t('sa'),t('su')] : [t('mo'),t('tu'),t('we'),t('th'),t('fr')] : [], [childData.attendanceProgram, t]);
  const payments = childData.payment ? childData.payment.totalCount : 0;
  const note = childData.note;

  const tabsItems: any = [
    {
      name: 'app_data',
      value: 'app_data',
      isEnabled: true,
    },
    {
      name: 'child_details',
      value: 'details',
      isEnabled: true,
    },
    {
      name: 'representatives',
      value: 'representatives',
      badge: representatives,
      badgeType: 'primary',
      isEnabled: true,
      disabled: representatives === 0,
    },
    {
      name: 'attendance_programme',
      value: 'attendance_programme',
      isEnabled: true,
      disabled: attendanceDays.length === 0,
    },
    {
      name: 'payments',
      value: 'payments',
      badge: payments,
      badgeType: 'primary',
      isEnabled: getUserRole(userData.userObject.roleType) === "parent",
      disabled: payments === 0,
    },
    {
      name: 'note',
      value: 'note',
      isEnabled: true,
      disabled: note === "" || note.length === 0,
    },
  ];

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      classID: null,
      date: null,
    };
    dispatch(setChildDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleEdit = () => {
    window.open(childData.editLink); 
  };

  const handleClickExcuseNotes = () => {
    navigate('/excuse-notes');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickSubstitutions = () => {
    navigate('/substitutions');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickFinance = () => {
    navigate('/finance');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleClickPosts = () => {
    navigate('/timeline');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };
  
  const handleClickCalendar = () => {
    navigate('/calendar');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickClassDetail = (classID: any) => {
    const settings = {
      isOpen: true,
      classID: classID,
    };
    dispatch(setClassModal(settings));
  };

  const handleCallButton = (phone: any) => {
    window.location.href = 'tel:' + phone; 
  };
  
  const setViewMode = (value: any) => {
    setState("viewMode", value);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "classbookHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>
              {t('child_details')}
            </p>
          </div>
          <div className={classes.buttons}>
            {
              (childData.editLink && childData.editLink !== "") ? (
                <IconButton className={classes.button} onClick={handleEdit} tooltip={t('edit_child')} tooltipPosition='bottom' dataCy="editButton">
                  <SVG src="pencil"/>
                </IconButton>
              ) : null
            }
            {
              (!childData.isArchived && childData.isInAnyActiveClass) ? (
                <IconButton className={classes.button} onClick={handleClickExcuseNotes} tooltip={t('excuse_notes')} tooltipPosition='bottom' dataCy="excuseNotesButton">
                  <SVG src="calendar-excuse-notes"/>
                </IconButton>
              ) : null
            }
            {
              (childData.attendanceProgram && childData.attendanceProgram !== null && !childData.isArchived && childData.isInAnyActiveClass && isSchoolSubstitutionsEnabled) ? (
                <IconButton className={classes.button} onClick={handleClickSubstitutions} tooltip={t('substitutions')} tooltipPosition='bottom' dataCy="substitutionsButton">
                  <SVG src="calendar-substitutions"/>
                </IconButton>
              ) : null
            }
            {
              (getUserRole(userData.userObject.roleType) === "parent" && childData.payment && childData.payment.totalCount > 0) ? (
                <IconButton className={classes.button} onClick={handleClickFinance} tooltip={t('finance')} tooltipPosition='bottom' dataCy="financeButton">
                  <SVG src="finance"/>
                </IconButton>
              ) : null 
            }
            <IconButton className={classes.button} onClick={handleClickPosts} tooltip={t('posts')} tooltipPosition='bottom' dataCy="postsButton">
              <SVG src="timeline"/>
            </IconButton>
            {
              (!childData.isArchived && childData.isInAnyActiveClass) ? (
                <IconButton className={classes.button} onClick={handleClickCalendar} tooltip={t('calendar')} tooltipPosition='bottom' dataCy="calendarButton">
                  <SVG src="calendar"/>
                </IconButton>
              ) : null
            }
            <CloseButton className={classes.button} onClick={handleClose} dataCy="timesButton"/>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.tabsWrapper}>
            <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
          </div>
          <div className={classes.detail}>
          {
            state.viewMode === "app_data" ? (
              <div className={classes.appData}>
                <div className={classes.leftColumn}>
                  <AuthenticatedImage className={classes.childImage} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink} preferQuality="fullsize"/>
                </div>
                <div className={classes.rightColumn}>
                  <table className={classes.list}>
                    <tbody>
                      {
                        childData.firstname ? (
                          <tr>
                            <td>
                              <span>{t('children_firstname')}</span>
                            </td>
                            <td>{childData.firstname}</td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.surname ? (
                          <tr>
                            <td>
                              <span>{t('children_surname')}</span>
                            </td>
                            <td>{childData.surname}</td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.nickname ? (
                          <tr>
                            <td>
                              <span>{t('children_nickname')}</span>
                            </td>
                            <td>{childData.nickname}</td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.hasGDPR !== undefined ? (
                          <tr>
                            <td>
                              <span>{t('children_gdpr')}</span>
                            </td>
                            <td>{childData.hasGDPR ? t('yes') : t('no')}</td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.classesID.length > 0 ? (
                          <tr>
                            <td>
                              <span>{t('children_class')}</span>
                            </td>
                            <td>
                              <div>
                              {
                                childData.classesID.map((classID: any, key: any) => dataData.classes.filter((classData: any) => classData.classID === classID).length !== 0 ? (
                                  <span key={`k_${key}`} onClick={() => handleClickClassDetail(classID)} data-cy={isCypress() ? 'childClass' + classID : null}>
                                    {htmlParse(getClassData(classID).name)}
                                  </span> 
                                ) : null)
                              }
                              </div>
                            </td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.schoolsID.length > 0 ? (
                          <tr>
                            <td>
                              <span>{t('children_school')}</span>
                              </td>
                            <td>{getSchoolData(childData.schoolID).name}</td>
                          </tr>
                        ) : null
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            ) : state.viewMode === "details" ? (
              <table className={classes.list}>
                <tbody>
                  <tr>
                    <td>
                      <span>{t('children_twid')}</span>
                    </td>
                    <td>{childData.childID}</td>
                  </tr>
                  {
                    childData.firstname ? (
                      <tr>
                        <td>
                          <span>{t('children_firstname')}</span>
                        </td>
                        <td>{childData.firstname}</td>
                      </tr>
                    ) : null
                  }
                  {
                    childData.surname ? (
                      <tr>
                        <td>
                          <span>{t('children_surname')}</span>
                        </td>
                        <td>{childData.surname}</td>
                      </tr>
                    ) : null
                  }
                  {
                    childData.birthday ? (
                      <tr>
                        <td>
                          <span>{t('children_birthday')}</span>
                        </td>
                        <td>{DateFormat(childData.birthday, "default", languageData, t)}</td>
                      </tr>
                    ) : null
                  }
                  {
                    childData.age ? (
                      <tr>
                        <td>
                          <span>{t('children_age')}</span>
                        </td>
                        <td>{childData.age} {childData.age === 1 ? (t('year')).toLowerCase() : (childData.age === 2 || childData.age === 3 || childData.age === 4) ? (t('years')).toLowerCase() : (t('years_more')).toLowerCase()}</td>
                      </tr>
                    ) : null
                  }
                  {
                    childData.attendanceProgram !== undefined ? (
                      <tr>
                        <td>
                          <span>{t('children_attendance_programme')}</span>
                        </td>
                        <td>{attendanceDays.length > 0 ? t('yes') : t('no')}</td>
                      </tr>
                    ) : null
                  }
                </tbody>
              </table>
            )  : state.viewMode === "representatives" ? (
              <div className={classes.representatives}>
                {
                  childData.representative.map((item: any, key: any) => (
                    <table className={classes.representative} key={`k_${key}`}>
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            {t('representative')} {key + 1}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.degreeBefore ? (
                            <tr>
                              <td>
                                <span>{t('degreeBefore')}</span>
                              </td>
                              <td>{item.degreeBefore}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.firstname ? (
                            <tr>
                              <td>
                                <span>{t('firstname')}</span>
                              </td>
                              <td>{item.firstname}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.surname ? (
                            <tr>
                              <td>
                                <span>{t('surname')}</span>
                              </td>
                              <td>{item.surname}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.degreeAfter ? (
                            <tr>
                              <td>
                                <span>{t('degreeAfter')}</span>
                              </td>
                              <td>{item.degreeAfter}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.relationship ? (
                            <tr>
                              <td>
                                <span>{t('children_relationship')}</span>
                              </td>
                              <td>{item.relationship}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.numberIdCard ? (
                            <tr>
                              <td>
                                <span>{t('number_id_card')}</span>
                              </td>
                              <td>{item.numberIdCard}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.phone ? (
                            <tr>
                              <td>
                                <span>{t('phone_number')}</span>
                              </td>
                              <td>
                                <span className={classes.phone} onClick={() => handleCallButton(item.phone)}>
                                  {item.phone}
                                </span>
                              </td>
                            </tr>
                          ) : null
                        }
                      </tbody>
                    </table> 
                  ))
                }
                {
                  childData.representativePickup.map((item: any, key: any) => (
                    <table className={classes.representative} key={`k_${key}`}>
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            {t('pickup_representative')} {key + 1}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.degreeBefore ? (
                            <tr>
                              <td>
                                <span>{t('degreeBefore')}</span>
                              </td>
                              <td>{item.degreeBefore}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.firstname ? (
                            <tr>
                              <td>
                                <span>{t('firstname')}</span>
                              </td>
                              <td>{item.firstname}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.surname ? (
                            <tr>
                              <td>
                                <span>{t('surname')}</span>
                              </td>
                              <td>{item.surname}</td>
                            </tr>
                          ) : null
                        }
                        {
                          item.degreeAfter ? (
                            <tr>
                              <td>
                                <span>{t('degreeAfter')}</span>
                              </td>
                              <td>{item.degreeAfter}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.relationship ? (
                            <tr>
                              <td>
                                <span>{t('children_relationship')}</span>
                              </td>
                              <td>{item.relationship}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.numberIdCard ? (
                            <tr>
                              <td>
                                <span>{t('number_id_card')}</span>
                              </td>
                              <td>{item.numberIdCard}</td>
                            </tr>
                          ) : null
                        }
                        {
                          childData.phone ? (
                            <tr>
                              <td>
                                <span>{t('phone_number')}</span>
                              </td>
                              <td>
                                <span className={classes.phone} onClick={() => handleCallButton(item.phone)}>
                                  {item.phone}
                                </span>
                              </td>
                            </tr>
                          ) : null
                        }
                      </tbody>
                    </table> 
                  ))
                }
              </div>
            ) : state.viewMode === "attendance_programme" ? (
                <div className={classes.attendanceProgram} data-cy={isCypress() ? 'childAttendanceProgram' : null}>
                  <table className={classes.list}>
                    <tbody>
                      {
                        childData.attendanceProgram.name ? (
                          <tr>
                            <td>
                              <span>{t('attendance_programme_name')}</span>
                            </td>
                            <td>{childData.attendanceProgram.name}</td>
                          </tr>
                        ) : null
                      }
                      {
                        childData.attendanceProgram.price ? (
                          <tr>
                            <td>
                              <span>{t('attendance_programme_price')}</span>
                            </td>
                            <td>{childData.attendanceProgram.price}</td>
                          </tr>
                        ) : null
                      }
                    </tbody>
                  </table>
                  <div>
                    {
                      attendanceDays.map((day: any, key: any) => (
                        <div className={classes.attendanceCell} key={`k_${key}`}>
                          <span>{day}.</span>
                          <AttendanceProgramInfoItem days={childData.attendanceProgram.days.filter((day: any) => day.day === (key + 1)).length} day={childData.attendanceProgram.days.find((day: any) => day.day === (key + 1))}/>
                        </div>
                      ))
                    }
                  </div>
              </div>
            ) : state.viewMode === "payments" ? (
              <FinanceChildItem childID={curChild}/>
            ) : state.viewMode === "note" ? (
              <div className={classes.note}>
                {htmlParse(childData.note)}
              </div>
            ) : null
          }
          </div>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleClose} dataCy="closeButton">
            {t('close')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default ChildDetailModal;