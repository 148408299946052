import React from 'react';
import { createUseStyles } from 'react-jss';
import { Tooltip } from '@mui/material';

interface Props {
  maxWidth: any;
  size: any;
}

const useStyles = createUseStyles((theme: any) => ({
  emoji: {
    fontFamily: `'Noto Color Emoji', sans-serif`,
    fontSize: (props: Props) => props.size + 'px !important',
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
  },
  tooltipWidth: {
    maxWidth: (props: Props) => props.maxWidth + 'px',
  },
}));

type EmojiIconType = {
  emoji?: any;
  size?: any;
  className?: any;
  tooltipTitle?: any;
  tooltipPosition?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  tooltipArrow?: boolean;
  tooltipMaxWidth?: any;
  onClick?: any;
};

const EmojiIcon: React.FunctionComponent<EmojiIconType> = ({ emoji = "😀", size, className, tooltipTitle, tooltipPosition = "right", tooltipArrow = true, tooltipMaxWidth = '100', onClick }) => {

  const classes = useStyles({
    maxWidth: tooltipMaxWidth,
    size: size,
  });

  return (
    <Tooltip title={tooltipTitle} classes={{ tooltip: classes.tooltipWidth }} placement={tooltipPosition} arrow={tooltipArrow}>
      <span className={`${classes.emoji} ${className}`} onClick={onClick}>
        {emoji}
      </span>
    </Tooltip>
  );
};

export default EmojiIcon;