import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CheckIcon from '@mui/icons-material/Check';
import React, { useCallback } from 'react';
import RenderUser from '../RenderUser';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  userGroupItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }, 
    '&:last-of-type': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },   
  },
  userGroupDetail: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '26px 24px',
    alignItems: 'center',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
  },
  userGroupBar: {
    display: 'flex',
    alignItems: 'center',
  },
  userGroupInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
  },
  userGroupName: {
    color: theme.colors.grey[700],
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  userGroupCount: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryBlue[500],
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: 'auto',
    marginRight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginRight: '0px',
    },
    '&.search': {
      color: '#BFBFBF',
    },
  },
  userGroupContent: {
    padding: '0',
    backgroundColor: theme.colors.white,
    '& > p': {
      padding: '0 32px 26px 32px',
      width: 'calc(100% - 64px)',
      fontSize: '14px',
    },
  },
  userGroupCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    '& p': {
      position: 'absolute',
      top: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '10px',
      textTransform: 'uppercase',
      color: theme.colors.primaryBlue[500],
      opacity: '0',
      transition: 'opacity 0.25s',
      paddingRight: '16px',
    },
    '& .checked + p': {
      opacity: '1',
    }
  },
  userGroupCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: "100%",
    marginRight: '16px',
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      '& svg': {
        opacity: '1',
        color: theme.colors.white,
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  userGroupExpander: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    backgroundColor: theme.colors.grey[125],
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.grey[700],
      transition: 'transform 0.25s',
      width: '24px',
      height: '24px',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxWidth: '24px',
      maxHeight: '24px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },    
  },
  usersList: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: '5px',
    width: 'calc(100% - 36px)',
    maxWidth: 'calc(100% - 36px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    padding: '13px 18px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 18px)',
      maxWidth: 'calc(100% - 18px)',
      padding: '4px 9px 13px',
    },
  },
  userWrapper: {
    width: (props: Props) => (props.size + 30) + 'px',
    flex: (props: Props) => '0 0 ' + (props.size + 30) + 'px',  
  },
}));

type RenderClassType = {
  state: any;
  setState: any;
  schoolData: any;
  role: any;
};

const RenderUserGroup: React.FunctionComponent<RenderClassType> = ({
  state,
  setState,
  schoolData,
  role,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);
  
  const [userGroupState, setUserGroupState] = useStates({
    isActive: false,
    isContentVisible: false,
  });

  const sizes = {
    xs: 32,
    sm: 38,
    md: 40,
    lg: 48,
    xl: 54,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? sizes.xl : isLg ? sizes.lg : isMd ? sizes.md : isSm ? sizes.sm : isXs ? sizes.xs : 30;

  const classes = useStyles({
    size: size,
  });

  const schoolID = useMemo(() => schoolData.schoolID, [schoolData.schoolID]);
  const count = useMemo(() => state.groupRoles.length, [state.groupRoles], [state.groupRoles, state.functions, state.isShowArchived, state.search]);

  const isParent = getUserRole(userData.userObject.roleType) === "parent";
  const isPublic = userData.userObject.public;
  const isParentWithNoPublicAccount = isParent && !isPublic;

  const isSelectedUser = useMemo(() => (searchData: any) => {
    return state.selectedUsers.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedUsers]);

  const totalUsers = useMemo(() => state.functions.getUsersBySchoolIDAndRole(schoolID, role), [schoolID, state.functions, role], [schoolID, state.functions, state.isShowArchived, state.search, role]);
  const totalSelectedUsers = useMemo(() => state.functions.getUsersBySchoolIDAndRole(schoolID, role).filter((item: any) => isSelectedUser(state.isSelectInAllSchool ? { userID: item.userID } : { userID: item.userID, schoolID: schoolID })), [isSelectedUser, schoolID, state.functions, state.isSelectInAllSchool, role], [isSelectedUser, schoolID, state.functions, state.isSelectInAllClass, state.isShowArchived, state.search, role]);
  const totalCount = useMemo(() => (!state.isDisableTotalCount && !state.isSelectAll && !state.isMultipleSelect) ? totalUsers.length : totalSelectedUsers.length + "/" + totalUsers.length, [state.isDisableTotalCount, state.isMultipleSelect, state.isSelectAll, totalUsers.length, totalSelectedUsers.length]);
  
  const toggleActive = useCallback(() => {
    if(!userGroupState.isActive && userGroupState.isContentVisible) return;
    if(count === 1 || state.search.length > 0) return;
    if(userGroupState.isActive) {
      setUserGroupState("isActive", false);
      setTimeout(() => {
        setUserGroupState("isContentVisible", false);
      }, theme.transitions.duration.complex);
    } else {
      setUserGroupState("isActive", true);
      setUserGroupState("isContentVisible", true);
    }
  }, [userGroupState.isActive, userGroupState.isContentVisible, count, setUserGroupState, state.search.length]);
  
  const handleSelectAll = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(state.validateMultipleSchool && state.selectedUsers.length !== 0) {
      const oldUserSchool = state.selectedUsers[0].schoolID ? state.selectedUsers[0].schoolID : state.functions.getUserData(state.selectedUsers[0].userID).schoolID;
      const newUserSchool = schoolID;
      if(oldUserSchool !== newUserSchool) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    if(state.maxUsersSelected && totalUsers.length >= state.maxUsersSelected) {
      const settings = {
        isOpen: true,
        title: t('limit_exceeded'),
        content: state.customLimitMessage ? t(state.customLimitMessage, {limit: state.maxUsersSelected}) : t('limit_exceeded_info', {limit: state.maxUsersSelected}),
      };
      dispatch(setWarningModal(settings));
      return;
    }
    let newSelectedUsers = [...state.selectedUsers];
    if(totalUsers.length === totalSelectedUsers.length) {
      totalSelectedUsers.forEach((user: any) => {
        newSelectedUsers = newSelectedUsers.filter((item: any) => JSON.stringify(item) !== JSON.stringify(state.isSelectInAllSchool ? { userID: user.userID } : { userID: user.userID, schoolID: schoolID }));
      });
    } else {
      totalUsers.forEach((item: any) => {
        const newUser = state.isSelectInAllSchool ? { userID: item.userID } : { userID: item.userID, schoolID: schoolID };
        if(newSelectedUsers.filter((user: any) => JSON.stringify(user) === JSON.stringify(newUser)).length === 0) {
          newSelectedUsers = [...newSelectedUsers, newUser];
        }
      });
    }
    setState("selectedUsers", newSelectedUsers);
  }, [schoolID, setState, state.functions, state.isSelectInAllSchool, state.selectedUsers, state.maxUsersSelected, state.validateMultipleSchool, state.customCombineMessage, state.customLimitMessage, totalUsers, totalSelectedUsers, dispatch, t]);
  
  useEffect(() => {
    if(state.isReload && state.isCloseAllOnReload) {
      setUserGroupState("isActive", false);
      setUserGroupState("isContentVisible", false);
    }
  }, [state.isReload, state.isCloseAllOnReload, setUserGroupState],  [state.isReload, state.isCloseAllOnReload]);

  const userGroupRender = useMemo(() => (
    <>
    {
      (isParentWithNoPublicAccount && role === 3) ? (
        <div className={classes.userGroupItem}>
          <div  className={`${classes.userGroupDetail} 'nonclickable'`}>
            <span className={classes.userGroupName} data-cy={isCypress() ? 'userGroupName' : null} data-clarity-unmask="true">
              {t(`role_${getUserRole(role)}`)}
            </span>
          </div>
          <div className={classes.userGroupContent} data-cy={isCypress() ? `schoolID${schoolID}role${role}Info` : null}>
            <p>{t('communication_parent_disabled_no_public')}</p>
          </div>
        </div>
      ) : (
        <Accordion expanded={(count === 1 || state.search.length > 0) ? true : userGroupState.isActive} className={classes.userGroupItem} data-cy={isCypress() ? `schoolID${schoolID}role${role}` : null}>
          <AccordionSummary className={`${classes.userGroupDetail} ${(count === 1 || state.search.length > 0) ? 'nonclickable' : 'clickable'}`} onClick={toggleActive} data-cy={isCypress() ? `schoolID${schoolID}role${role}Toggle` : null}>
            <div className={classes.userGroupBar}>
              {
                (state.isSelectAll && state.search === '' && count !== 1) ? (
                  <span className={classes.userGroupCheck}>
                    <span className={`${classes.userGroupCheckButton} ${totalUsers.length === totalSelectedUsers.length ? 'checked' : '' }`} onClick={handleSelectAll} data-cy={isCypress() ? `schoolID${schoolID}role${role}SelectAll` : null}>
                      <CheckIcon/>
                    </span>
                    <p>{t("all")}</p>
                  </span>
                ) : null
              }
              <span className={classes.userGroupInfo}>
                <span className={classes.userGroupName} data-cy={isCypress() ? 'userGroupName' : null} data-clarity-unmask="true">
                  {t(`role_${getUserRole(role)}`)}
                </span>
                {
                  !state.isDisableTotalCount ? (
                    <span className={`${classes.userGroupCount} ${state.search.length > 0 ? 'search' : ''}`} data-cy={isCypress() ? 'userGroupCount' : null} data-clarity-unmask="true">
                      ({totalCount})
                    </span>
                  ) : null
                }
              </span>
              {
                (count === 1 || state.search.length > 0) ? null : (
                  <span className={`${classes.userGroupExpander} ${userGroupState.isActive ? 'active' : ''}`} data-cy={isCypress() ? 'userGroupExpander' : null}>
                    <SVG src="chevron-down"/>
                  </span>
                )
              }
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.userGroupContent} data-cy={isCypress() ? `schoolID${schoolID}role${role}Children` : null}>
            {
              (count === 1 || state.search.length > 0) || userGroupState.isContentVisible ? (
                <div className={`${classes.usersList} ${state.customClasses ? (state.customClasses.usersList ? state.customClasses.usersList : "") : ""}`} data-cy={isCypress() ? 'usersContainer' : null}>
                  {
                    totalUsers.map((theUser: any, key: any) => (
                      <div className={classes.userWrapper} key={`k_${key}`}>
                        <RenderUser state={state} setState={setState} schoolData={schoolData} userData={theUser} size={size}/>   
                      </div>  
                    ))
                  }
                </div>
              ) : null
            }
          </AccordionDetails>
        </Accordion>
      )
    }
    </>
  ), [schoolID, isParentWithNoPublicAccount, classes.usersList, classes.userGroupCheck, classes.userGroupCheckButton, classes.userGroupContent, classes.userGroupCount, classes.userWrapper, userGroupState.isActive, userGroupState.isContentVisible, classes.userGroupDetail, classes.userGroupExpander, classes.userGroupInfo, classes.userGroupItem, classes.userGroupName, classes.userGroupBar, count, handleSelectAll, schoolData, setState, state, t, toggleActive, totalUsers, totalCount, totalSelectedUsers.length, role, size]); 

  return userGroupRender;
}

export default RenderUserGroup;