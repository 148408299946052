import * as UserService from '../../../../../services/user.service';
import AuthenticatedImage from '../../../../../components/Items/AuthenticatedImage';
import config from '../../../../../constants/config';
import Input from '../../../../../components/Forms/Input';
import moment from 'src/utils/moment';
import NormalButton from '../../../../../components/Buttons/NormalButton';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { arrayBufferToBase64, base64ToArrayBuffer, formatFileSize, handleDetectCamera } from 'src/utils/useFunctions';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { FileDrop } from 'react-file-drop';
import { getUserRole } from '../../../../../utils/useUser';
import { isDesktop } from 'react-device-detect';
import { setCameraModal, setPhotoChangeModal } from '../../../../../store/actions/modals.actions';
import { setUserObject } from '../../../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { processImage } from 'src/utils/useMedia';

const useStyles = createUseStyles((theme: any) => ({
  profileWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  },
  photo: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(40% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    gap: '16px',
    height: 'fit-content',
    position: 'relative',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > small': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontSize: '14px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  photoWrapper: {
    width: '128px',
    height: '128px',
    '& > div': {
      position: 'relative',
      width: '100%',
      height: '100%',
      '& > div': {
        borderRadius: '24px',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
      },
    },
  },
  hidden: {
    display: 'none',
    pointerEvents: 'none',
  },
  limit: {
    marginLeft: '4px',
    fontSize: '10px !important',
  },
  button: {
    minWidth: 'unset',
    padding: '4.5px 12px',
    height: '32px',
    '& svg': {
      width: '16px',
      height: '16px',
    }
  },
  draganddrop: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    borderWidth: '5px',
    borderStyle: 'dashed',
    borderColor: theme.colors.black,
    borderRadius: '24px',
    backgroundColor: 'rgba(153,153,153,0.9)',
    color: theme.colors.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
    zIndex: theme.zIndex.dragAndDrop,
  },
}));

const ProfileSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const photoSelector: any = useRef(null);

  const userInfo: any = {
    degreeBefore: userData.userObject.degreeBefore,
    firstname: userData.userObject.firstname,
    surname: userData.userObject.surname,
    middlename: userData.userObject.middlename,
    degreeAfter: userData.userObject.degreeAfter,
  };

  const [values, setValues, setAllValues] = useStates(userInfo);
  const [state, setState] = useStates({
    isEdit: false,
    isCameraAvailable: false,
    isDragAndDrop: false,
  });

  const isChanged = (values.degreeBefore !== userInfo.degreeBefore || values.firstname !== userInfo.firstname || values.surname !== userInfo.surname || values.middlename !== userInfo.middlename || values.degreeAfter !== userInfo.degreeAfter) && (values.firstname !== "" && values.surname !== "");

  const handleInput = (name: any, value: any) => {
    setValues(name, value);
  };

  const handleEdit = () => {
    if(state.isEdit) {
      setAllValues(userInfo);
    }
    setState("isEdit", !state.isEdit);
  };

  const saveChanges = () => {
    let payload: any = {};
    Object.keys(values).forEach((key: any) => {
      if(values[key as keyof typeof values] !== userInfo[key as keyof typeof values]) {
        payload[key] = values[key as keyof typeof values];
      }
    });
    if(Object.keys(payload).length > 0) {
      UserService && UserService.changeUserData(payload).then((result: any) => {
        if(result.status === 200) { 
          let newUser = userData.userObject;
          Object.keys(payload).forEach((key: any) => {
            newUser = {...newUser, [key]: payload[key as keyof typeof values]};
          });
          dispatch(setUserObject(newUser));
          setState("isEdit", false);
          createNotification(t('profile_updated'), 'success');
        } else {
          createNotification(t('something_went_wrong'), 'error');
        }
      }).catch((e: any) => {
        createNotification(t('something_went_wrong'), 'error');
      });
    }
  };

  const uploadPhoto = () => {
    photoSelector.current.click();
  };

  const takePhoto = () => {
    const settings = {
      isOpen: true,
      mode: 'photo',
      mediaLimit: "timeline",
      onSave: handleTakePhoto,
    };
    dispatch(setCameraModal(settings));
  };

  const handleTakePhoto = (dataUri: any) => {
    const dataSplit = dataUri.split(",");
    const base64 = dataSplit[dataSplit.length - 1];
    const data = base64ToArrayBuffer(base64);
    const file = new File([data], 'cameraPhoto_' + userData.userObject.userID +'_' + moment().format("YYYY_MM_DD_HH_mm_ss") + '.png', {type: 'image/png'});
    const newFile = file;
    handleAddFile(newFile);
  };

  const handleUpload = (e: any) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles) {
      let newFile = Array.from(uploadedFiles);
      if(newFile.length === 0) {
        createNotification(t("select_atleast_file"), "error");
      } else {
        handleAddFile(newFile[0]); 
      }
      photoSelector.current.value = '';
    }
  };

  const handleAddFile = (newFile: any) => {
    let file: any = {name: newFile.name, size: newFile.size, type: newFile.type};
    if(file.size > config.UPLOAD_PROFILE_PHOTO_MAX_SIZE) {      
      createNotification(t("file_not_added_max_size", {file: file.name}), "error");
    } else {
      if(config.UPLOAD_FORMATS_PHOTOS.indexOf(file.type) === -1) {
        createNotification(t("file_not_added_not_allowed", {file: file.name}), "error");
      } else {
        const reader = new FileReader();
        reader.readAsArrayBuffer(newFile);
        reader.onload = async () => {
          const handleOnSave = (photoData: any) => {
            const payload = {photo: {name: photoData.name, size: photoData.size}}
            UserService && UserService.changeUserData(payload).then((result: any) => {
              if(result.status === 200) { 
                UserService && UserService.uploadFile(result.data.photo.uploadUrl, photoData.image).then((result: any) => {
                  if(result) {
                    if(result.status === 201) {
                      const newMediaID = result.data.mediaID;
                      let newUser = userData.userObject;
                      let newPhoto = newUser.photo;
                      const oldMediaID = newUser.photo.mediaID;
                      newPhoto = {...newPhoto, mediaID: newMediaID, photoID: newMediaID, thumbLink: newPhoto.thumbLink.replace(oldMediaID,newMediaID), fullsizeLink: newPhoto.fullsizeLink.replace(oldMediaID,newMediaID), parametricLink: newPhoto.parametricLink.replace(oldMediaID,newMediaID), link: newPhoto.link.replace(oldMediaID,newMediaID)}
                      newUser = {...newUser, photo: newPhoto};
                      dispatch(setUserObject(newUser));
                      createNotification(t('profile_image_updated'), 'success');
                    } else {
                      createNotification(t('something_went_wrong'), 'error');
                    }
                  }
                }).catch(() => {
                  createNotification(t('something_went_wrong'), 'error');
                });
              }
            }).catch(() => {
              createNotification(t('something_went_wrong'), 'error');
            });
          }
          const base64 = arrayBufferToBase64(reader.result);
          const processedImage = await processImage(base64, file.name, file.type);
          const processedImageBase64 = processedImage[0];
          const processedImageArrayBuffer = base64ToArrayBuffer(processedImageBase64);
          file = {...file, raw: processedImageArrayBuffer, base64: processedImageBase64};
          const settings = {
            isOpen: true,
            photo: file,
            onSave: handleOnSave,
            title: t('profile_photo_crop'),
            aspect: 1,
          };
          dispatch(setPhotoChangeModal(settings));
        };
        reader.onerror = () => {
          createNotification(t("file_not_added", {file: file.name}), "error");
        }; 
      }
    }    
  };

  useEffect(() => {
    handleDetectCamera((hasWebcam: any) => {
      if(hasWebcam) {
        setState("isCameraAvailable", true); 
      }
    });  
  }, [setState], []);

  useEffect(() => {
    window.addEventListener('dragenter', () => {
      setState("isDragAndDrop", true);
    }, false);
    window.addEventListener('dragend', () => {
      setState("isDragAndDrop", false);
    }, false);
    return () => {
      window.removeEventListener('dragenter', () => {
        setState("isDragAndDrop", true);
      });
      window.removeEventListener('dragend', () => {
        setState("isDragAndDrop", false);
      });
    };
  }, [setState], []);

  const handleDragLeave = () => {
    setState("isDragAndDrop", false);
  };

  const handleDrop = (files: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isDragAndDrop", false);
    handleAddFile(files[0]);
    return false;
  }; 

  const handleFrameDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isDragAndDrop", false);
    return false;
  }; 
  
  return (
    <div className={classes.profileWrapper}>
      <div className={classes.profile}>
        {
          (getUserRole(userData.userObject.roleType) === "parent" && state.isEdit) ? (
            <>
              {
                Object.keys(values).map((value: any, key: any) => (
                  <Input label={t(value)} name={value} value={values[value as keyof typeof values]} onChange={handleInput} key={`k_${key}`}/>
                ))                 
              }
              <div className={classes.buttons}>
                <NormalButton buttonType="secondary" onClick={handleEdit}>{t('cancel')}</NormalButton>
                <NormalButton buttonType="primary" onClick={saveChanges} disabled={!isChanged}>{t('save_changes')}</NormalButton>
              </div>
            </>
          ) : (
            <>
              {
                Object.keys(values).map((value: any, key: any) => values[value as keyof typeof values] !== "" ? (
                  <div className={classes.inputWrapper} key={`k_${key}`}>
                    <label>{t(value)}</label>
                    <span>{values[value as keyof typeof values]}</span>
                  </div>
                ) : null)                 
              }
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <NormalButton buttonType="primary" onClick={handleEdit}>{t('edit_data')}</NormalButton>
                ) : null
              }
            </>
          )
        }
        
      </div>
      <div className={classes.photo}>
        <label>{t('profile_photo')}</label>
        <div className={classes.photoWrapper}>
          <AuthenticatedImage fullsizeLink={userData.userObject.photo.fullsizeLink} useLoader={true}/>
          <input className={classes.hidden} id="file" type="file" ref={photoSelector} accept={config.UPLOAD_FORMATS_PHOTOS.join(", ")} onChange={(e: any) => handleUpload(e)} multiple/>
        </div>
        <div className={classes.buttons}>
          <NormalButton className={classes.button} startIcon={<SVG src="image"/>} buttonType="primary" onClick={uploadPhoto}>
            {t('upload_image_short')}
            <span className={classes.limit}>({formatFileSize(config.UPLOAD_PROFILE_PHOTO_MAX_SIZE)})</span>
          </NormalButton>
          {
            state.isCameraAvailable ? (
              <NormalButton className={classes.button} startIcon={<SVG src="camera"/>} buttonType="primary" onClick={takePhoto}>
                {t('take_image_short')}
              </NormalButton>
            ) : null
          }
          {
            state.isDragAndDrop ? (
              <FileDrop onDrop={handleDrop} onFrameDrop={handleFrameDrop}>
                <div className={classes.draganddrop} onDragLeave={handleDragLeave}>
                  {t('drag and drop files here')}
                </div>
              </FileDrop>
            ) : null
          }
        </div>
        {
          isDesktop ? (
            <small>
              <SVG src="info-circle-outlined"/> {t('drag and drop_photo_tip')}
            </small>
          ) : null
        }
      </div>
    </div>
  );
};

export default ProfileSettings;