import ChildInfoItem from '../../../Items/ChildInfoItem';
import React, { useCallback } from 'react';
import { getUserRole } from '../../../../utils/useUser';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';
import { getSchoolSettings } from 'src/utils/useFunctions';

type RenderChildType = {
  state: any;
  setState: any;
  schoolData?: any;
  classData?: any;
  childData: any;
  size: any;
};

const RenderChild: React.FunctionComponent<RenderChildType> = ({
  state,
  setState,
  schoolData,
  classData,
  childData,
  size,
}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);
  const classID = useMemo(() => (getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? null : classData.classID, [classData, userData.userObject.roleType, state.displayMode]);
  const childID = useMemo(() => childData.childID, [childData.childID]);
  const searchData = useMemo(() => (state.isSelectInAllClass || getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple") ? { childID: childID } : { childID: childID, classID: classID }, [childID, classID, state.isSelectInAllClass, userData.userObject.roleType, state.displayMode]);

  const schoolSettings = userData.schoolSettings;

  const defaultClassData = useMemo(() => state.functions.getClassData(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search, state.selectedChildren]);
  const defaultChildData = useMemo(() => state.functions.getChildDataByClass(childID, classID), [childID, classID, state.functions], [childID, classID, state.functions, state.isShowArchived, state.search, state.selectedChildren]);
  const isSelected = useMemo(() => state.selectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0, [searchData, state.selectedChildren]);
  const isPostSeen = useMemo(() => state.functions.getChildData(childID).postSeen, [childID, state.functions], [childID, state.functions, state.isShowArchived, state.search, state.selectedChildren]);
  const isDisabled = useMemo(() => state.isAllowOnlyOneChildAtOnce ? (isSelected ? true : false) : false, [isSelected, state.isAllowOnlyOneChildAtOnce], [isSelected, state.isAllowOnlyOneChildAtOnce, state.selectedChildren]);
  
  const handleOnClickChild = useCallback((e: any) => {
    e.preventDefault();
    if(state.isDisableClick) return;
    if(state.mode === "detail") return;
    if(state.isAllowOnlyOneChildAtOnce && isSelected) return;
    if(state.validateAbsence &&  state.selectedChildren.length !== 0) {
      const oldChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence
      const newChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(childID).schoolID, "modules", schoolSettings).absence;
      if(oldChildAbsenceSettings.allowNotes !== newChildAbsenceSettings.allowNotes || oldChildAbsenceSettings.allowDayParts !== newChildAbsenceSettings.allowDayParts) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    if(state.validateMultipleSchool && state.selectedChildren.length !== 0) {
      const oldChildSchool = state.functions.getChildData(state.selectedChildren[0].childID).schoolID;
      const newChildSchool = state.functions.getChildData(childID).schoolID;
      if(oldChildSchool !== newChildSchool) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    let newSelectedChildren = state.isAllowOnlyOneChildAtOnce ? [] : state.selectedChildren;
    const itemData = {
      school: schoolData,
      class: classData,
      child: childData,
    };
    if((getUserRole(userData.userObject.roleType) === "parent" || state.displayMode === "simple")) {
      if(isSelected) {
        newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify(searchData));
        setState("selectedChildren", newSelectedChildren);
      } else {
        newSelectedChildren = [...newSelectedChildren, searchData];
        setState("selectedChildren", newSelectedChildren);
      }
    } else {
      if(isSelected) {
        newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify(searchData));
        setState("selectedChildren", newSelectedChildren);
      } else {
        newSelectedChildren = [...newSelectedChildren, searchData];
        setState("selectedChildren", newSelectedChildren);
      }
    }           
    if(state.onClickChild) state.onClickChild(e, itemData, newSelectedChildren); 
  }, [childData, classData, isSelected, schoolData, searchData, setState, state, userData.userObject.roleType, dispatch, t, childID, schoolSettings]);

  return (
    <ChildInfoItem childID={childID} isSelected={isSelected} isPostSeen={isPostSeen} isDisabled={isDisabled} defaultClassData={defaultClassData} defaultChildData={defaultChildData} size={size} mode={state.mode} isVisibleGDPR={state.isChildGDPR} isVisibleArchived={state.isShowChildrenArchived} useModal={state.isChildModal} disableClick={state.isDisableClick} onClick={handleOnClickChild} />
  );
}

export default RenderChild;