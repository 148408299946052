import App from './Sections/App';
import Classbook from './Sections/Classbook';
import Default from './Sections/Default';
import Finish from './Sections/Finish';
import Language from './Sections/Language';
import Privacy from './Sections/Privacy';
import React, { useRef } from 'react';
import setup from 'src/constants/setup';
import Theme from './Sections/Theme';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSettingCategory } from 'src/utils/useUser';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  setupScreen: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: theme.zIndex.screen,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'saturate(180%) blur(20px)',
    '& > svg': {
      maxWidth: '60%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '24px',
    backgroundColor: theme.colors.white,
    width: 'calc(80% - 96px)',
    height: 'calc(100% - 96px)',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      borderRadius: '0px',
    },
  },
  body: {
    display: 'flex',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    height: '100%',
  },
}));

const SetupScreen: React.FunctionComponent = () => {

  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);

  const setupStatus = getUserSettingCategory(userData.userSettings, "setup") ? getUserSettingCategory(userData.userSettings, "setup").value : [];

  const sections = setup.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    const isEnabled = setupStatus.filter((subItem: any) => subItem.key === item.key).length === 0 ? true : setupStatus.find((subItem: any) => subItem.key === item.key).value === null ? true : false;
    return { ...item, enabled: isEnabled};
  });

  const [state, setState] = useStates({
    section: 'default',
    sections: sections,
  });

  const sectionRef = useRef(sections);

  const updateSection = (key: any, value: any) => {
    const newSections = sectionRef.current.map((item: any) => {
      if(item.key === key) {
        return {...item, enabled: value};
      } else {
        return item;
      }
    });
    sectionRef.current = newSections;
    setState("sections", newSections);
  };

  const getNextSection = () => {
    const isAvailableSection = sectionRef.current.filter((item: any) => item.enabled !== false).length !== 0;
    const getFirstEnabledSection = isAvailableSection ? sectionRef.current.filter((item: any) => item.enabled !== false)[0].key : "finish";
    return getFirstEnabledSection;
  };

  const handleNext = () => {
    const section = getNextSection();
    setState("section", section);
  };

  return (
    <div className={classes.setupScreen}>
      <div className={classes.content}>

        <div className={classes.body}>
          {
            state.section === "default" ? (
              <Default handleNext={handleNext}/>
            ) : state.section === "language" ? (
              <Language updateSection={updateSection} handleNext={handleNext}/>
            ) : state.section === "theme" ? (
              <Theme updateSection={updateSection} handleNext={handleNext}/>
            ) : state.section === "app" ? (
              <App updateSection={updateSection} handleNext={handleNext}/>
            ) : state.section === "privacy" ? (
              <Privacy updateSection={updateSection} handleNext={handleNext}/>
            ) : state.section === "classbook" ? (
              <Classbook updateSection={updateSection} handleNext={handleNext}/>
            ) : state.section === "finish" ? (
              <Finish/>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

export default SetupScreen;