import * as AuthService from '../../../../services/auth.service';
import Button from '@mui/material/Button';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserStatus } from 'src/store/actions/user.actions';
import { useAppDispatch } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  moremenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg': {
      marginLeft: '24px',
      width: '24px',
      height: '24px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
    height: '24px',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

const LogoutMenuItem: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleLogout = () => {
    dispatch(setDeinitializeUserController(true));
  };
  
  const handleClickLogout = () => {
    dispatch(setUserStatus("loggingOff"));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  };
  
  return (
    <Button className={classes.moremenuItem} onClick={handleClickLogout}>
      <SVG src="logout"/>
      <span>{t('logout')}</span>
      <span className={classes.chevron}>
        <SVG src="chevron-right"/>
      </span> 
    </Button>
  );
}

export default LogoutMenuItem;