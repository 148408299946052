import React from 'react';
import { createUseStyles } from 'react-jss';
import { getDayName } from 'src/utils/useFunctions';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  dayBlock: {
    backgroundColor: theme.colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[100],
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '6px 8px',
    textAlign: 'center',
    height: 'fit-content',
    maxWidth: '92px',
    minWidth: '64px',
    margin: '0 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

type TimeBlockType = {
  day: any;
};

const DayBlock: React.FunctionComponent<TimeBlockType> = ({day}) => {

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.dayBlock}>
      {t(getDayName(day))}
    </div>
  );
}

export default DayBlock;