import HttpService from '../http.service';

const listThreads = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`communication/thread${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const createThread = (payload: any) => {
  return HttpService.post(`communication/thread`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const updateThread = (threadID: number, payload: any) => {
  return HttpService.put(`communication/thread/${threadID}`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const leaveThread = (threadID: number) => {
  return HttpService.put(`communication/thread/leave/${threadID}`, {}, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const settingsThread = (threadID: number, payload: any) => {
  return HttpService.put(`communication/thread/settings/${threadID}`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const deleteThread = (threadID: number) => {
  return HttpService.remove(`communication/thread/${threadID}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' });
};

const listMessages = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`communication/message${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const createMessage = (payload: any) => {
  return HttpService.post(`communication/message`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const updateMessage = (messageID: number, payload: any) => {
  return HttpService.put(`communication/message/${messageID}`, payload, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const deleteMessage = (messageID: number) => {
  return HttpService.remove(`communication/message/${messageID}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' });
};

const uploadFile = (uploadURL: any, payload: any) => {
  return HttpService.post(uploadURL, payload, {'Content-Type': 'application/json'}, {});
};

const listMedias = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for(const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  } 
  return HttpService.get(`media${query ? `${query}`: ""}`, {}, {});
};

export {
  listThreads,
  createThread,
  updateThread,
  leaveThread,
  settingsThread,
  deleteThread,
  listMessages,
  createMessage,
  updateMessage,
  deleteMessage,
  uploadFile,
  listMedias,
};