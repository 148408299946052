import React from 'react';
import RenderContent from '../RenderContent';

type RenderInlineType = {
  state: any;
  setState: any;
};

const RenderInline: React.FunctionComponent<RenderInlineType> = ({
  state,
  setState,
}) => {

  return (
    <RenderContent state={state} setState={setState}/>
  ); 
}

export default RenderInline;