type appEnvironmentType = {
  [key: string]: any;
};

const appEnvironment: appEnvironmentType = {
  localhost: {
    url: `${process.env.REACT_APP_USE_HTTPS ? 'https' : 'http'}://localhost:3000/`,
    logo: '/resources/images/logo_localhost',
    logoCircle: '/resources/images/logo_localhost_circle',
    logoSmall: '/resources/images/logo_localhost_small',
    productFruitsCode: null,
    sentryUrl: null,
    clarityID: "ktf3irfxhp",
    gaID: null,
    apiServers: {
      /*
      localhost: {
        name: 'Localhost',
        shortName: 'LOC',
        url: 'http://localhost:8100/v2',
        adminUrl: 'http://localhost:8100/',
      },
      */
      sandbox: {
        name: 'Sandbox',
        shortName: 'SNBX',
        url: 'https://sandbox-api.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      stage: {
        name: 'Stage',
        shortName: 'STGE',
        url: 'https://apiv2.stage.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      production: {
        name: 'Production',
        shortName: 'PROD',
        url: 'https://api.twigsee.com/v2',
        adminUrl: 'https://admin.twigsee.com/',
      }
    },
  },
  review: {
    url: `https://webapp.${process.env.REACT_APP_BRANCH}.review.twigsee.com/`,
    logo: '/resources/images/logo_review',
    logoCircle: '/resources/images/logo_review_circle',
    logoSmall: '/resources/images/logo_review_small',
    productFruitsCode: null,
    sentryUrl: 'https://11b569a435b548efb762a5202e4d512a@o4505512134508544.ingest.sentry.io/4505512192376832',
    clarityID: "ktf3irfxhp",
    gaID: null,
    apiServers: {
      sandbox: {
        name: 'Sandbox',
        shortName: 'SNBX',
        url: 'https://sandbox-api.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      stage: {
        name: 'Stage',
        shortName: 'STGE',
        url: 'https://apiv2.stage.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      production: {
        name: 'Production',
        shortName: 'PROD',
        url: 'https://api.twigsee.com/v2',
        adminUrl: 'https://admin.twigsee.com/',
      }
    },
  },
  stage: {
    url: `https://webapp.stage.twigsee.com/`,
    logo: '/resources/images/logo_stage',
    logoCircle: '/resources/images/logo_stage_circle',
    logoSmall: '/resources/images/logo_stage_small',
    productFruitsCode: null,
    sentryUrl: 'https://11b569a435b548efb762a5202e4d512a@o4505512134508544.ingest.sentry.io/4505512192376832',
    clarityID: "ktf3irfxhp",
    gaID: null,
    apiServers: {
      sandbox: {
        name: 'Sandbox',
        shortName: 'SNBX',
        url: 'https://sandbox-api.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      stage: {
        name: 'Stage',
        shortName: 'STGE',
        url: 'https://apiv2.stage.twigsee.com/v2',
        adminUrl: 'https://sandbox-admin.twigsee.com/',
      },
      production: {
        name: 'Production',
        shortName: 'PROD',
        url: 'https://api.twigsee.com/v2',
        adminUrl: 'https://admin.twigsee.com/',
      }
    },
  },
  production: {
    url: `https://app.twigsee.com/`,
    logo: '/resources/images/logo_production',
    logoCircle: '/resources/images/logo_production_circle',
    logoSmall: '/resources/images/logo_production_small',
    productFruitsCode: null, //'HifBpbKyzeUvwpEd',
    sentryUrl: 'https://11b569a435b548efb762a5202e4d512a@o4505512134508544.ingest.sentry.io/4505512192376832',
    clarityID: '90xcq4pray',
    gaID: ['G-ETSG3PPWCS', 'GT-TQTSBHS'],
    apiServers: {
      production: {
        name: 'Production',
        shortName: 'PROD',
        url: 'https://api.twigsee.com/v2',
        adminUrl: 'https://admin.twigsee.com/',
      }
    },
  },
};

export default appEnvironment;