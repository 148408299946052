import CommunicationDrawer from 'src/components/Drawers/CommunicationDrawer';
import FirebaseNotificationsDrawer from 'src/components/Drawers/FirebaseNotificationsDrawer';
import PropTypes from 'prop-types';
import React from 'react';
import { resetAllDrawers } from 'src/store/actions/drawers.actions';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { useEffect } from '../../utils/useEffect';
import { useLocation } from 'react-router-dom';

interface Props {
  children: any;
};

const DrawersController: React.FunctionComponent<Props> = (props:Props) => {

  const dispatch = useAppDispatch(); 
  const location = useLocation();
  
  useEffect(() => {
    dispatch(resetAllDrawers());
  }, [location, dispatch], [location]);

  return (
    <>
      {props.children} 
      <CommunicationDrawer/>
      <FirebaseNotificationsDrawer/>
    </>
  );
};

DrawersController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default DrawersController;