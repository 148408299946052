import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import DateFormat from 'src/utils/dateFormat';
import FitText from 'src/components/Layouts/FitText';
import htmlParse from 'html-react-parser';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import QuickLinkButton from 'src/components/Buttons/QuickLinkButton';
import React, { useRef } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import Select from 'src/components/Forms/Select';
import Slider from 'infinite-react-carousel';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import useBreakpoint from 'src/utils/useBreakpoint';
import useQuicklinks from 'src/constants/quicklinks';
import useTips from 'src/constants/tips';
import useVersions from 'src/constants/versions';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { languages } from 'src/constants/languages';
import { setAppData } from 'src/utils/useApp';
import { setDetailModal } from 'src/store/actions/modals.actions';
import { setIsAutoOpenWelcomeScreen, setIsOpenWelcomeScreen } from 'src/store/actions/layout.actions';
import { shuffle } from 'src/utils/useFunctions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Forms/Switch';
import { setUserSettings } from 'src/store/actions/user.actions';
import { createNotification } from 'src/utils/createNotification';

const useStyles = createUseStyles((theme: any) => ({
  welcomeScreen: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: theme.zIndex.screen,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'saturate(180%) blur(20px)',
    '& > svg': {
      maxWidth: '60%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '24px',
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 96px)',
    height: 'calc(100% - 96px)',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      borderRadius: '0px',
    },
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
    '& > button': {
      padding: '10px',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0px",
      outline: "none",
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      minWidth: "48px",
      minHeight: "48px",
      width: "48px",
      height: "48px",
      maxWidth: "48px",
      maxHeight: "48px",
      fontSize: "20px",
      cursor: "pointer",
      opacity: "1",
      transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
      zIndex: '4',
      '& svg': {
        color: theme.colors.white,
      },
      "&:hover": {
        backgroundColor: 'rgba(110, 113, 145, 0.9)',
        color: '#F1F1F1',
      },
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: 'calc(100% - 112px)',
    padding: '0 56px 0px 56px',
    maxHeight: '100%',
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 48px)',
      padding: '0px 24px 24px 24px',
    },
  },
  wrapper: {
    display: 'flex',
    gap: '32px',
    width: 'calc(100% - 32px)',
    maxHeight: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  userColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '60%',
    flex: '0 0 60%',
    maxHeight: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: '100%',
    },
  },
  changelogColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '40%',
    flex: '0 0 40%',
    maxHeight: 'calc(100% - 32px)',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: '100%',
      '&::after': {
        content: `''`,
        width: '100%',
        height: '24px',
        minHeight: '24px',
        display: 'block',
      },
    },
  },
  userRow: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  contentRows: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    gap: '16px',
    maxHeight: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      overflow: 'unset',
    },
  },
  changelogRow: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    paddingTop: '9px',
    justifyContent: 'space-between',
  },
  tipsRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  quickLinksRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    paddingTop: '32px',
  },
  title: {
    fontSize: '36px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
  subTitle: {
    fontSize: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  middleTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '20px',
    '& > svg': {
      width: '28px',
      height: '28px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  userPhoto: {
    width: '64px',
    height: '64px',
    minWidth: '64px',
    minHeight: '64px',
    maxWidth: '64px',
    maxHeight: '64px',
    position: 'relative',
    borderRadius: '24px',
  },
  changelogSelect: {
    width: '100px',
    flex: 'unset',
  },
  changelogListWrapper: {
    maxHeight: 'calc(100% - 67px)',
  },
  changelogList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingRight: '24px',
    width: 'calc(100% - 24px)',
    maxHeight: '100%',
    overflow: 'auto',
  },
  changelogButton: {
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
    padding: '8px',
    width: '24px',
    height: '24px',
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  },
  changelogContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    backgroundColor: theme.colors.chip,
    width: 'calc(100% - 24px)',
    height: 'fit-content',
    padding: '12px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      fontSize: '16px',
      fontWeight: '600',
      '& > small': {
        fontSize: '14px',
        marginLeft: 'auto',
        fontWeight: '500',
      },
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      overflow: 'unset',
      height: 'fit-content',
    },
  },
  list: {
    paddingInlineStart: '20px',
    listStyle: 'none',
    '& > li': {
      '&::before': {
        content: `'\\2022'`, 
        color: theme.colors.primaryBlue[500],
        fontWeight: '800',
        fontSize: '18px',
        display: 'inline-block',
        width: '1rem',
        marginLeft: '-1rem',
      },
      '& > span': {
        fontWeight: '500',
      }
    },
  },
  tipsSlider: {
    width: '100%',
    height: '280px',
    '& > div': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& .carousel-arrow': {
      color: theme.colors.black,
      backgroundColor: theme.colors.white,
      borderRadius: '100%',
      padding: '8px',
      width: '24px',
      height: '24px',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        backgroundColor: theme.colors.grey[100],
      },
      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  tipWrapper: {
    display: 'inline-flex !important',
    justifyContent: 'center',
  },
  tipBlock: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    backgroundColor: theme.colors.grey[150],
    width: '232px',
    height: '232px',
    padding: '12px',
    '& > div': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      maxWidth: '100%',
      '& > span:first-of-type': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '64px',
        width: '64px',
        height: '64px',
        backgroundColor: theme.colors.white,
        borderRadius: '100%',
        '& > svg': {
          width: '48px',
          height: '48px',
        },
      },
      '& > span:last-of-type': {
        fontWeight: '500',
      },
    },
    '& > p': {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 auto',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& > button': {
      marginTop: 'auto',
    },
  },
  quicklinks: {
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    marginTop: '16px',
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '8px 24px 24px 24px',
    gap: '8px',
  },
  settingContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    gap: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > span': {
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      alignItems: 'center',
      gap: '6px',
    },
  },
  switch: {
    width: 'fit-content',
    transform: 'scale(1.25)',
  },
}));

const WelcomeScreen: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const layoutData = useAppSelector((state: any) => state.layout);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation(["translations", "changelog", "suggestions"]);

  const changelogScroll: any = useRef(null);

  const versionsData: any = useVersions();
  const tipsData: any = useTips();
  const quickLinksData: any = useQuicklinks();
  const versions = versionsData.map((item: any, key: any) => { return {name: item.name, value: item.name, key: key}});

  const breakpoint: any = useBreakpoint();
  const isSaving = useRef(false);

  const slidesToShow: any = {
    xxxxl: 6,
    xxxl: 5,
    xxl: 4,
    xl: 3,
    lg: 3,
    bg: 2,
    md: 1,
    co: 2,
    sm: 1,
    xs: 1,
  };

  const [state, setState] = useStates({
    currentVersion: versions[0],
    tips: shuffle(tipsData[getUserRole(userData.userObject.roleType)]),
    isSaving: false,
  });

  const displayName = `${userData.userObject.firstname} ${userData.userObject.surname}`;

  const handleClose = () => {
    dispatch(setIsAutoOpenWelcomeScreen(false));
    dispatch(setIsOpenWelcomeScreen(false));
    setAppData({changelogVersion: config.APP_CHANGELOG_VERSION});
  };

  const handleSelectVersion = (value: any) => {
    setState("currentVersion", value);
    const el: any = document.getElementById(`changelog${value.key}`);
    if(el) {
      el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  const handleAction = (actionData: any) => {
    const type = actionData.type;
    const target = actionData.target;
    if(type === "navigate") {
      handleClose();
      navigate(target);
    } else if(type === "windowopen") {
      window.open(target);
    } else if(type === "knowledgebase") {
      const langs: any = languages;
      const url: any = langs.find((lang: any) => lang.acronym === languageData.language).knowledgeBaseLink;
      window.open(url);
    } else if(type === "premiumparent") {
      const langs: any = languages;
      const url: any = langs.find((lang: any) => lang.acronym === languageData.language).premiumParentLink;
      window.open(url);
    }
  };

  const handleDetail = (title: any, text: any) => {
    const settings = {
      isOpen: true,
      title: title,
      content: text,
      zIndex: theme.zIndex.screen,
    };
    dispatch(setDetailModal(settings));
  };

  const handleSwitch = async (name: any, value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const oldValue = getUserSetting(userData.userSettings, "addons", ["app", name]);
      const newValue = updateUserSetting(userData.userSettings, "addons", ["app", name], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "addons", ["app", name], value);
      if(result) {
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "addons", ["app", name], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  return (
    <div className={classes.welcomeScreen}>
      <div className={classes.content}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
          <CloseButton onClick={handleClose}/>
        </div>
        <div className={classes.body}>
          <div className={classes.wrapper}>
            <div className={classes.userColumn}>
              <div className={classes.userRow}>
                <AuthenticatedImage className={classes.userPhoto} thumbLink={userData.userObject.photo.thumbLink}/>
                <h1 className={classes.title}>
                  {htmlParse(t('welcome_title', {name: displayName}))}
                </h1>
              </div>
              <div className={classes.contentRows}>
                <div className={classes.tipsRow}>
                  <h3 className={classes.middleTitle}>
                    <SVG src="bulb-outlined"/>
                    {t('tips_title')}
                  </h3>
                  <Slider dots className={classes.tipsSlider} slidesToShow={slidesToShow[breakpoint]} prevArrow={<SVG src="chevron-left"/>} nextArrow={<SVG src="chevron-right"/>}>
                    {
                      state.tips.map((item: any, key: any) => (
                        <div className={classes.tipWrapper} key={`k_${key}`}>
                          <div className={classes.tipBlock}>
                            <div>
                              <span>
                                <SVG src={item.icon}/>
                              </span>
                              <FitText renderAs='span' options={{maxFontSize: 150}} style={{display: 'block', width: '100%', height: 'auto', maxWidth: '100%',}}>
                                {t(item.title, {ns: 'suggestions'})}
                              </FitText>
                            </div>
                            <p onClick={() => handleDetail(t(item.title, {ns: 'suggestions'}), t(item.text, {ns: 'suggestions'}))}>
                              <FitText renderAs='span' style={{display: 'block', width: '100%', height: 'auto', padding: '0 8px', maxHeight: item.action ? '80%' : '95%'}}>
                                {t(item.text, {ns: 'suggestions'})}
                              </FitText>
                            </p>
                            {
                              item.action ? (
                                <NormalButton buttonType='primary' onClick={() => handleAction(item.action)}>
                                  {t(item.action.title, {ns: 'suggestions'})}
                                </NormalButton>
                              ) : null
                            }
                          </div>
                      </div>
                      ))
                    }
                  </Slider>
                </div>
                <div className={classes.quickLinksRow}>
                  <h3 className={classes.middleTitle}>
                    <SVG src="bolt-outlined"/>
                    {t('quick_links_title')}
                  </h3>
                  <ScrollMenu className={classes.quicklinks} layout="horizontal" nativeMobileScroll={false}>
                    {
                      quickLinksData[getUserRole(userData.userObject.roleType)].map((item: any, key: any) => (
                        <QuickLinkButton buttonType={item.color} icon={<SVG src={item.icon}/>} key={`k_${key}`} onClick={() => handleAction(item.action)}>
                          {t(item.title)}
                        </QuickLinkButton>
                      ))
                    }
                  </ScrollMenu>
                </div>
              </div>
            </div>
            <div className={classes.changelogColumn}>
              <div className={classes.changelogRow}>
                <h2 className={classes.subTitle}>
                  {layoutData.isAutoOpenWelcomeScreen ? t('changelog_what_is_new') : t('changelog_title')}
                </h2>
                <Select className={classes.changelogSelect} items={versions} selected={state.currentVersion} setSelected={handleSelectVersion} allowClear={false}/>
              </div>
              <ScrollMenu classNameWrapper={classes.changelogListWrapper} className={classes.changelogList} classNameButton={classes.changelogButton} layout="vertical" nativeMobileScroll={false} isArrowFloat={true} customRef={changelogScroll}>
                {
                  versionsData.filter((item: any) => item.name === state.currentVersion.name).map((item: any, key: any) => {
                    return (
                      <div className={classes.changelogContent} id={`changelog${key}`} key={`k_${key}`}>
                        {
                          item.text ? (
                            <p>
                              {item.text}
                            </p>
                          ) : null
                        }
                        {
                          (item.list && item.list[getUserRole(userData.userObject.roleType)]) ? (
                            <>
                              <span data-clarity-unmask="true">
                                {t('version')} {item.name}
                                <small>{DateFormat(moment(item.date, "DD-MM-YYYY"), "default", languageData, t)}</small>
                              </span>
                              <ul className={classes.list}>
                                {item.list[getUserRole(userData.userObject.roleType)].map((listItem: any, lkey: any) => (
                                  <li key={`k_${lkey}`}>
                                    {listItem.includes("nothing_new") ? (<span>{t(listItem, {ns: 'changelog'})}</span>) : t(listItem, {ns: 'changelog'})}
                                  </li>
                                ))}
                              </ul> 
                            </>
                          ) : null
                        }
                      </div>
                    );
                  })
                }
              </ScrollMenu>
            </div>
          </div>
        </div>
        <div className={classes.setting}>
            <div className={classes.settingContent}>
              <div className={classes.row}>
                <span>
                  {t('welcome_screen_open_on_new_version')}
                </span>
              </div>
              <Switch className={classes.switch} name="app_update_welcome_screen" checked={getUserSetting(userData.userSettings, "addons", ["app", "app_update_welcome_screen"])} onChange={(name: any, val: any) => handleSwitch(name, val)} disabled={state.isSaving}/>
            </div>
          </div>
      </div>
    </div>
  );
}

export default WelcomeScreen;