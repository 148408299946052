import HttpService from '../http.service';

const listReactions = (ID: number, type: 'post' | 'media' | 'comment') => {
  return HttpService.get(`reaction?${type}ID=${ID}`, {}, {});
};

const handleReaction = (payload: any) => {
  return HttpService.post(`reaction`, payload, {}, {});
};

const deleteReaction = (ID: number, type: 'post' | 'media' | 'comment') => {
  return HttpService.remove(`reaction?${type}ID=${ID}`, {});
};

export {
  listReactions,
  handleReaction,
  deleteReaction,
};