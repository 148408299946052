import CheckIcon from '@mui/icons-material/Check';
import NormalButton from '../NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  pickButton: {
    padding: '9px 12px',
    justifyContent: 'center',
    gap: '8px',
    '& > span': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      '&.MuiButton-startIcon': {
        marginLeft: '0px',
      }
    },
  },
  isCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.primaryBlue[500],
    minWidth: '18px',
    minHeight: '18px',
    width: '18px',
    height: '18px',
    maxWidth: '18px',
    maxHeight: '18px',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.colors.primaryBlue[500],
    marginRight: '0px',
    marginLeft: 'auto',
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.white,
      transform: 'scale(0.6)'
    },
  },
  isNoCheck: {
    display: 'block',
    backgroundColor: theme.colors.white,
    minWidth: '18px',
    minHeight: '18px',
    width: '18px',
    height: '18px',
    maxWidth: '18px',
    maxHeight: '18px',
    borderWidth: '1.5px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    marginRight: '0px',
    marginLeft: 'auto',
    borderRadius: '100%',
  },
}));

type PickButtonType = {
  className?: any;
  children: any;
  onClick?: any;
  isPicked?: boolean;
  dataCy?: string;
  disabled?: boolean;
};

const PickButton: React.FunctionComponent<PickButtonType> = ({className, children, onClick, isPicked = false, dataCy, disabled}) => {
  
  const classes = useStyles();

  return (
    <NormalButton
      className={`${classes.pickButton} ${className ? className : null}`}
      buttonType="chip"
      onClick={onClick}
      startIcon={
        isPicked ? (
        <span className={classes.isCheck}>
          <CheckIcon/>
        </span>
      ) : (
        <span className={classes.isNoCheck}></span>
        )
      }
      disabled={disabled}
      dataCy={isCypress() ? dataCy : ''}
    >
      {children}
    </NormalButton>
  );
};

export default PickButton;