import LoadingScreen from 'src/components/Screen/LoadingScreen';
import React from 'react';
import { getUserHomepage } from 'src/utils/useUser';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';

const Homepage: React.FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);

  useEffect(() => {
    if(userData.userStatus === "loggedIn" && loadingData.isLanguageLoaded && loadingData.isUserLoaded && loadingData.isUserDataLoaded) {
      navigate(getUserHomepage(dispatch, dataData, layoutData, userData));
    }
  }, [dispatch, navigate, dataData, layoutData, loadingData, userData], [loadingData]);

  return (
    <>
      <LoadingScreen/>
      {
        userData.userStatus === "loggedIn" ? (
          <>...</>
        ) : (
          <Navigate to="/auth/login" state={{ from: location }}/>
        )
      }
    </>);
}

export default Homepage;