import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setImageModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: '10px',
    backgroundColor: 'transparent',
    overflow: 'visible',
    margin: '20px auto',
    width: 'fit-content',
    maxWidth: 'calc(100% - 30px)',
    maxHeight: 'calc(100% - 30px)',
    outline: 'none',
  },
  imageWrapper: {
    width: 'auto',
    height: 'auto',
    position: 'relative',
    '& .loader': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '24px',
      backgroundColor: theme.colors.white,
      borderRadius: '10px',
      width: 'auto',
    },
    '& > div': {
      maxWidth: 'unset',
      borderRadius: '10px',
      overflow: 'hidden',
      '& > img': {
        outline: 'none',
        maxWidth: '90vw',
        maxHeight: '90vh',
        borderRadius: '10px',
        backgroundColor: theme.colors.white,
      },
      '& > div': {
        '& > canvas': {
          outline: 'none',
          maxWidth: '90vw',
          maxHeight: '90vh',
          borderRadius: '10px',
          backgroundColor: theme.colors.white,
        },
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    backgroundColor: theme.colors.white,
    zIndex: '2',
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const ImageModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      thumbLink: null,
      fullsizeLink: null,
    };
    dispatch(setImageModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} daty-cy={isCypress() ? "imageModal" : null}>
        <div className={classes.imageWrapper}>
          <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/> 
          <AuthenticatedImage thumbLink={modalsData.imageModal.thumbLink} fullsizeLink={modalsData.imageModal.fullsizeLink} isResponsive={false} preferQuality="fullsize" useLoader={true}/>
        </div>
      </div>
    </Modal>
  );
};  

export default ImageModal;