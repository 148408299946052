import EmployeeCard from 'src/components/Cards/EmployeeCard';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  wagesWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '&::after': {
      content: `''`,
      width: '100%',
      height: '25px',
      display: 'block',
    },
  },
  cardWrapper: {
    width: 'calc(50% - 16px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
    },
  },
}));

const EmployeeSettings: React.FunctionComponent = () => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const employeesData: any = [];

  userData.userObject.employeeID.forEach((employeeID: any) => {
    if(dataData.employees.filter((employee: any) => employee.employeeID === employeeID).length === 1) {
      const employeeData = dataData.employees.find((employee: any) => employee.employeeID === employeeID);
      employeesData.push(employeeData);
    } 
  });

  return (
    <div className={classes.wagesWrapper}>
      {
        employeesData.map((employee: any, key: any) => (
          <div className={classes.cardWrapper} key={`k_${key}`}>
            <EmployeeCard employeeID={employee.employeeID} disableButtons={true}/>
          </div>
        ))
      }
    </div>
  );
};

export default EmployeeSettings;