import LoadingScreen from '../../components/Screen/LoadingScreen';
import PropTypes from 'prop-types';
import React from 'react';
import { useAppSelector } from '../../hooks/redux-hooks';
import config from 'src/constants/config';

interface Props {
  children: any;
};

const LoadingController: React.FunctionComponent<Props> = (props:Props) => {

  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);

  const windowHandler: any = window;
  const location = windowHandler.location;

  const isInstantAccessPage = config.APP_INSTANT_ACCESS_PAGES.some((page: any) => location.pathname.includes(page));

  return (
    <>
      {
        (!isInstantAccessPage && (loadingData.isLanguageChanging || userData.userStatus === "loggingOff" || ((userData.userStatus === "loggedIn" || !loadingData.isLanguageLoaded) && (!loadingData.isLanguageLoaded || !loadingData.isUserLoaded || userData.userStatus !== "loggedIn")))) ? (
          <LoadingScreen />
        ) : null
      }
      {props.children}
    </>
  );
};

LoadingController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default LoadingController;