import Currency from 'react-currency-formatter';
import moment from 'src/utils/moment';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { arrayBufferToBase64, base64toBlob } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveAs } from 'file-saver';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
    alignItems: 'center',
    '& > span': {
      fontWeight: '600',
    },
  },
  items: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  attachments: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    maxWidth: '100%',
    alignItems: 'center',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 38px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 15px 20px 15px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 30px)',
      borderRadius: '0px',
    },
    '& > table': {
      width: '100%',
      fontSize: '14px',
      '& > thead': {
        '& + tbody': {
          '& > tr': {
            '& > td': {
              textAlign: 'center',
            },
          },
        },
      },
      '& > tbody': {
        '& > tr': {
          '& > td': {
            '&:last-of-type': {
              paddingLeft: '8px',
            },
          },
        },
      },
    },
    '& > span': {
      fontWeight: 'bold',
      marginBottom: '16px',
    },
  },
  summary: {
    marginTop: 'auto',
    paddingTop: '64px',
    textAlign: 'center',
    '& > span': {
      '& > b': {
        whiteSpace: 'nowrap',
      }
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '32px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  file: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
    height: '200px',
    backgroundColor: theme.colors.grey[350],
    borderRadius: '24px',
    color: '#888FAE',
    cursor: 'pointer',
    '& > span': {
      fontSize: '14px',
      marginBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    '& > svg': {
      width: '50%',
      height: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '48px',
        height: '48px',
      },
    },
    '& > p': {
      marginTop: '4px',
      marginBottom: '0',
      fontSize: '16px',
      maxWidth: '80%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        maxWidth: '60%',
      },
    },
  },
  approve: {
    backgroundColor: theme.colors.systemGreen[100],
    color: theme.colors.systemGreen[500],
    padding: '6px 8px',
    fontSize: '12px',
    borderRadius: '12px',
    marginTop: '12px',
  },
  unapprove: {
    backgroundColor: theme.colors.systemRed[100],
    color: theme.colors.systemRed[500],
    padding: '6px 8px',
    fontSize: '14px',
    borderRadius: '12px',
    marginTop: '12px',
  },
  notset: {
    backgroundColor: theme.colors.grey[100],
    color: theme.colors.grey[800],
    padding: '6px 8px',
    fontSize: '14px',
    borderRadius: '12px',
    marginTop: '12px',
  },
  plus: {
    color: theme.colors.systemGreen[500],
  },
  minus: {
    color: theme.colors.systemRed[500],
  },
}));

type WageTableType = {
  employeeID: any;
  schoolID: any;
  date: any;
};

const WageTable: React.FunctionComponent<WageTableType> = ({employeeID = null, schoolID = null, date = moment()}) => {

  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const wagesService = useAppSelector((state: any) => state.services).wagesService;
  const classes = useStyles();

  const [state, setState] = useStates({
    isLoaded: false,
    data: [],
  });

  const wageCurrency = state.data.currencyID ? configurationData.currencies.filter((currency: any) => currency.currencyID === state.data.currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === state.data.currencyID) : [];
  
  useEffect(() => {
    setState("isLoaded", false);
    wagesService && wagesService.listWage(employeeID, schoolID, date).then((result: any) => {
      if(result) {
        if(result.data) {
          setState("data", result.data.length > 0 ? result.data[0] : []);
          setState("isLoaded", true);
        } else {
          createNotification(t("wages_failed_load"), "error");
        }
      } else {
        createNotification(t("wages_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("wages_failed_load"), "error");
    });
  }, [date, employeeID, schoolID, setState, t, wagesService], [date, employeeID, schoolID]);

  const downloadAttachment = (mediaName: any, mediaExtension: any, mediaID: any) => {
    wagesService && wagesService.downloadFile(mediaID).then((result: any) => {
      if(result) {
        if(result.data) {
          const base64 = arrayBufferToBase64(result.data)
          const contentType = "application/octet-stream";
          const blob = base64toBlob(base64, contentType);
          saveAs(blob, `${mediaName}.${mediaExtension}`);
        } else {
          
        }
      } else {
       
      }
    });
  };
  
  return state.isLoaded ? (
    <>
      {
        state.data.length === 0 ? (
          <span className={classes.notset}>
            {moment(date).isAfter(moment(), 'month') ? t('wage_not_set_yet') : t('wage_not_set')}
          </span>
        ) : (
          <>
          {
            state.data.approved ? (
              <div className={classes.wageWrapper}>
                <div className={classes.items}>
                  <div className={classes.wrapper}>
                    <div className={classes.block}>
                      <span>{t('wage_payslip')}</span>
                      <table>
                        <tbody>
                          <tr>
                            <td>{t('wage_gross')}</td>
                            <td>
                              <Currency quantity={state.data.wageGross} currency={wageCurrency.iso}/>
                            </td>
                          </tr>
                          {
                            state.data.item.map((item: any, key: any) => (
                              <tr key={`k_${key}`}>
                                <td>{item.name}</td>
                                <td>
                                  <Currency quantity={parseInt(item.amount)} currency={wageCurrency.iso}/>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      <div className={classes.summary}>
                        <span>{t('wage_summary')}: <b><Currency quantity={state.data.wageTotal} currency={wageCurrency.iso}/></b></span>
                      </div>
                      <span className={classes.approve}>{t('wage_approved')}</span>
                    </div>
                  </div>
                  <div className={classes.wrapper}>
                    <div className={classes.block}>
                      <span>{t('wage_items_plus')}</span>
                      <table>
                        <tbody>
                          {
                            state.data.plus.length === 0 ? (
                              <tr>
                                <td colSpan={2}>
                                  {t('wage_no_items_plus')}
                                </td>
                              </tr>
                            ) : state.data.plus.map((item: any, key: any) => (
                              <tr key={`k_${key}`}>
                                <td>{item.name}</td>
                                <td className={classes.plus}>
                                  <Currency quantity={parseInt(item.amount)} currency={wageCurrency.iso}/>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className={classes.block}>
                      <span>{t('wage_items_minus')}</span>
                      <table>
                        <tbody>
                          {
                            state.data.minus.length === 0 ? (
                              <tr>
                                <td colSpan={2}>
                                  {t('wage_no_items_minus')}
                                </td>
                              </tr>
                            ) : state.data.minus.map((item: any, key: any) => (
                              <tr key={`k_${key}`}>
                                <td>{item.name}</td>
                                <td className={classes.minus}>
                                  <Currency quantity={parseInt(item.amount)} currency={wageCurrency.iso}/>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={classes.wrapper}>
                    <div className={classes.block}>
                      <span>{t('wage_bonuses')}</span>
                      <table>
                        <tbody>
                          {
                            state.data.bonus.length === 0 ? (
                              <tr>
                                <td colSpan={2}>
                                  {t('wage_no_bonuses')}
                                </td>
                              </tr>
                            ) : state.data.bonus.map((item: any, key: any) => (
                              <tr key={`k_${key}`}>
                                <td>{item.name}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className={classes.block}>
                      <span>{t('wage_remaining')}</span>
                      <table>
                        <thead>
                          <tr>
                            <th>{t('wage_sick_days')}</th>
                            <th>{t('wage_holiday')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {state.data.sickdaysLeft}
                              &nbsp;
                              {
                                state.data.sickdaysLeft === 1 ? (t('day')).toLowerCase() : (state.data.sickdaysLeft === 2 || state.data.sickdaysLeft === 3 || state.data.sickdaysLeft === 4) ? (t('days')).toLowerCase() : (t('days_more')).toLowerCase() 
                              }  
                            </td>
                            <td>
                              {state.data.holidayLeft}
                              &nbsp;
                              {
                                state.data.holidayLeft === 1 ? (t('hour')).toLowerCase() : (state.data.holidayLeft === 2 || state.data.holidayLeft === 3 || state.data.holidayLeft === 4) ? (t('hours')).toLowerCase() : (t('hours_more')).toLowerCase() 
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {
                  state.data.attachments.length > 0 ? (
                    <>
                      <span>{t('attachments')}</span>
                      <div className={classes.attachments}>
                        {
                          state.data.attachments.map((attachment: any, key: any) => (
                            <div className={classes.file} onClick={() => downloadAttachment(attachment.name, attachment.extension, attachment.mediaID)} key={`k_${key}`}>
                              <SVG src={'document-' + attachment.extension} children={<SVG src="file"/>}/>
                              <p>{attachment.name}</p>
                            </div>
                          ))
                        }
                      </div>
                    </>
                  ) : null
                }
              </div>
            ) : (
            <span className={classes.unapprove}>
              {t('wage_not_approved')}
            </span>
            )
          }
          </>
        )
      }
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress/>
    </div>
  );
}

export default WageTable;