import HttpService from '../http.service';

const listEmployees = (userID?: any, employeeID?: any) => {
  return HttpService.get(`employee${userID ? `?userID=${userID}`: ""}${employeeID ? `?employeeID=${employeeID}`: ""}`, {}, {});
};

const listChildren = (childID?: any) => {
  return HttpService.get(`child?includeArchived=1${childID ? `&childID=${childID}` : ''}`, {}, {});
};

const listClasses = (classID?: any) => {
  return HttpService.get(`class?includeArchived=1${classID ? `&classID=${classID}` : ''}`, {}, {});
};

const listSchools = (schoolID: any) => {
  return HttpService.get(`school?schoolID=${schoolID}`, {}, {});
};

const listUsers = (userID?: any) => {
  return HttpService.get(`person?roleType=2,3,4${userID ? `&userID=${userID}`: ""}`, {}, {});
};

export {
  listUsers,
  listEmployees,
  listChildren,
  listClasses,
  listSchools,
};