import { useEffect, useState } from 'react';

type TWindowSize = [number, number];

type THook = TWindowSize;

export const useWindowResize = (): THook => {
  const initSize: TWindowSize = [
    window.innerWidth,
    window.innerHeight,
  ];
  const [windowSize, setWindowSize] = useState<TWindowSize>(initSize);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize([
        window.innerWidth,
        window.innerHeight,
      ]);
    };
    const handleOrientation = (): void => {
      setTimeout(function() {
        setWindowSize([
          window.innerWidth,
          window.innerHeight,
        ]);
      }, 1);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientation);
    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('orientationchange', handleOrientation);
    };
  }, []);

  return windowSize;
};