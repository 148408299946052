import React, { useCallback } from 'react';
import theme from 'src/ui/theme';
import Tooltip from '@mui/material/Tooltip';
import { createUseStyles } from 'react-jss';
import { generateColorFromString, getDayName, isLightOrDark } from 'src/utils/useFunctions';
import { setTimetableHourDetailModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  color: any;
  isLightOrDark: any;
};

const useStyles = createUseStyles((theme: any) => ({
  break: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: (props: Props) => props.color,
    color: (props: Props) => {
      if(props.isLightOrDark(props.color) === "light") return '#000000';
      else return '#FFFFFF';
    },
  },
  roomName: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    left: '0px',
    right: '0px',
    fontSize: '16px',
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.colors.primaryBlue[500],
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipArrow: {
    color: theme.colors.primaryBlue[500],
  },
}));

type BreakItemType = {
  breakData: any;
  day: any;
  hour: any;
  isFirstBreakZero: any;
};

const BreakItem: React.FunctionComponent<BreakItemType> = ({breakData, day, hour, isFirstBreakZero}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const settings = timetableData.settings === null ? [] : timetableData.settings;
  const rooms = timetableData.rooms;
  const timetableSettings = timetableData.settings === null ? [] : timetableData.settings;
  const roomID = breakData.roomID;
  const type = breakData.type;
  const timetableID = breakData.timetableID;
  const teacherID = breakData.teacherID;
  
  const roomData = rooms.filter((item: any) => item.roomID === roomID).length === 1 ? rooms.find((item: any) => item.roomID === roomID) : [];

  const getEmployeeData = useCallback((employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  }, [dataData.employees]);

  const getEmployeeName = (employeeID: any) => {
    return (getEmployeeData(employeeID).firstname ? getEmployeeData(employeeID).firstname : "") + " " + (getEmployeeData(employeeID).surname ? getEmployeeData(employeeID).surname : "");
  };

  const classes = useStyles({
    color: roomData.name ? generateColorFromString(roomData.name) : theme.colors.black,
    isLightOrDark: isLightOrDark,
  });

  const handleDetailHour = (day: any, hour: any, type: any) => {
    const settings = {
      isOpen: true,
      weekday: day,
      hour: hour,
      type: type,
      timetableID: timetableID,
      mode: timetableData.type,
      isEditable: timetableSettings.canEdit,
      isFirstBreakZero: isFirstBreakZero,
    };
    dispatch(setTimetableHourDetailModal(settings));
  };

  const tooltipContent = (
    <>
      <b>{t(getDayName(day))} {isFirstBreakZero ? (hour + 1) : hour}. {t('timetable_break').toLowerCase()}</b>
      <span>
        {t('timetable_break_teachers')}:&nbsp;
        <b>
          {
            teacherID.map((teacher: any) => {
              return getEmployeeName(teacher);
            }).join(", ")
          }
        </b>
      </span>
      <span>
        {t('timetable_room')}:&nbsp;
        <b>
          {
            roomData.name
          }
        </b>
      </span>
    </>
  );

  return (
    <div className={classes.break} onClick={() => handleDetailHour(day, hour, type)}>
      <Tooltip title={tooltipContent} classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} placement='right-start' disableHoverListener={!settings.subjectPopup} disableInteractive={true} arrow>
        <span className={classes.roomName}>{roomData.shortName}</span>
      </Tooltip>
    </div>
  );
}

export default BreakItem;