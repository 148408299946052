import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  size?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  attendanceProgramInfoItem: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: 'inherit',
    }, 
  },
  attendanceItemPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    color: theme.colors.primaryBlue[500],
  },
  attendanceItemPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    color: theme.colors.primaryBlue[500],
    '& > svg': {
      marginLeft: '-16px',
    },
  },
  attendanceItemEmpty: {
    position: 'relative',
    top: '0px',
    left: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => props.size + 'px',
    color: '#BBBBBB',
  },
}));

type AttendanceProgramInfoType = {
  days: any;
  day: any;
  size?: any;
};

const AttendanceProgramInfoItem: React.FunctionComponent<AttendanceProgramInfoType> = ({ days, day, size = 32 }) => {

  const { t } = useTranslation();
  const classes = useStyles({
    size: size,
  });

  const tooltipText = () => {
    if(days === 1) {
      if(day.attendanceType === "all") return t('whole_day');
      else if(day.attendanceType === "am") return t('morning');
      else if(day.atteandanceType === "pm") return t('afternoon');
      else return '';
    } else {
      return t('absent');
    }
  };
  
  return (
    <Tooltip title={tooltipText()} placement='bottom' arrow>
      <div className={classes.attendanceProgramInfoItem}>
        {
          days === 1 ? (
            <>
              {
                (day.attendanceType === "am" || day.attendanceType === "all") ? (
                  <div className={classes.attendanceItemPartAM}>
                    <CircleIcon/>
                  </div>
                ) : null
              }
              {
                (day.attendanceType === "pm" || day.attendanceType === "all") ? (
                  <div className={classes.attendanceItemPartPM}>
                    <CircleIcon/>
                  </div>
                ) : null
              }    
            </>
          ) : (
            <div className={classes.attendanceItemEmpty}>
              <CloseIcon/>
            </div>   
          )
        }
      </div>
    </Tooltip>
  );
}

export default AttendanceProgramInfoItem;