import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import config from 'src/constants/config';
import DateFormat from 'src/utils/dateFormat';
import Message from '../Message';
import moment from 'src/utils/moment';
import React, { useCallback, useRef } from 'react';
import Tooltip from 'src/components/Layouts/Tooltip';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setCommunicationMessageViewedModal } from 'src/store/actions/modals.actions';
import { updateThreadData } from 'src/store/actions/communication.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'src/utils/useBreakpoint';

interface Props {
  isMobile: any;
};

const useStyles = createUseStyles((theme: any) => ({
  messages: {
    width: 'calc(100% - 16px)',
    flex: '1 1 calc(100% - 16px)',
    maxHeight: 'calc(100% - 16px)',
    padding: '8px',
    overflow: 'hidden auto',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    position: 'relative',
    '&::before': {
      content: `''`,
      marginTop: 'auto',
    },
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '8px',
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
    },
  },
  loadingSmall: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    transform: 'translate(-50%, -8px)',
    backgroundColor: theme.colors.white,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& > div': {
      transform: 'scale(0.5)',
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  messageGroupTo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '& > div': {
      '&:only-child': {
        '& > div': {
          '& > div': {
            '& > span': {
              borderRadius: '24px',
            },
          },
        },
      },
      '&:not(:only-child)': {
        '&:first-of-type': {
          '& > div': {
            '& > div': {
              '& > span': {
                borderRadius: '24px 24px 6px 24px',
              },
            },
          },
        },
        '&:last-of-type': {
          '& > div': {
            '& > div': {
              '& > span': {
                borderRadius: '24px 6px 24px 24px',
              },
            },
          },
        },
        '&:not(:first-of-type):not(:last-of-type)': {
          '& > div': {
            '& > div': {
              '& > span': {
                borderRadius: '24px 6px 6px 24px',
              },
            },
          },
        },
      },
    },
  },
  messageGroupFrom: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '& > div': {
      '&:only-child': {
        '& > div': {
          '& > div:first-of-type + div': {
            '& > span': {
              borderRadius: '24px',
            },
          },
        },
      },
      '&:not(:only-child)': {
        '&:first-of-type': {
          '& > div': {
            '& > div:not(:last-of-type):first-of-type': {
              visibility: 'hidden',
              height: '1px',
            },
            '& > div:first-of-type + div': {
              '& > span': {
                borderRadius: '24px 24px 24px 6px',
              },
            },
          },
        },
        '&:last-of-type': {
          '& > span': {
            '&:first-of-type': {
              display: 'none',
            },
          },
          '& > div': {
            '& > div:first-of-type + div': {
              '& > span': {
                borderRadius: '6px 24px 24px 24px',
              },
            },
          },
        },
        '&:not(:first-of-type):not(:last-of-type)': {
          '& > span': {
            '&:first-of-type': {
              display: 'none',
            },
          },
          '& > div': {
            '& > div:not(:last-of-type):first-of-type': {
              visibility: 'hidden',
              height: '1px',
            },
            '& > div:first-of-type + div': {
              '& > span': {
                borderRadius: '6px 24px 24px 6px',
              },
            },
          },
        },
      },
    },
  },
  messageSystem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '500',
    height: '44px',
    minHeight: '44px',
    textAlign: 'center',
  },
  messageDateTime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '500',
    height: '44px',
    minHeight: '44px',
  },
  messageUsersViewed: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '24px',
    gap: '2px',
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '10px',
      height: '10px',
      padding: '2px',
      fontSize: '50%',
      borderRadius: '3px',
      backgroundColor: theme.colors.grey[200],
    },
  },
  viewedAvatar: {
    width: (props: Props) => {
      if(props.isMobile) return '20px';
      else return '14px';
    },
    height: (props: Props) => {
      if(props.isMobile) return '20px';
      else return '14px';
    },
    position: 'relative',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: '4px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      minWidth: 'unset',
      maxWidth: 'unset',
      minHeight: 'unset',
      maxHeight: 'unset',
    }
  },
}));

type MessagesType = {
  threadID: any;
  messages: any;
  setMessages: any;
  setLastDateTime: any;
  isLoadingMessages: any;
  setIsLoadingMessages: any;
  editMessageID: any;
  setEditMessageID: any;
  messagesRef: any;
  messagesContainerRef: any;
  reset: any;
  search: any;
  limit: any;
};

const Messages: React.FunctionComponent<MessagesType> = ({ threadID, messages, setMessages, setLastDateTime, isLoadingMessages, setIsLoadingMessages, editMessageID, setEditMessageID, messagesRef, messagesContainerRef, reset, search, limit }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const threadsList = communicationData.list;

  const [state, setState] = useStates({
    isEndOfScroll: false,
    activeMessageID: null,
  });

  const threadIDRef = useRef(threadID);
  const isEndOfScroll = useRef(state.isEndOfScroll);


  const maxViewUsers = 4;
  const pageRef = useRef(1);
  const limitRef = useRef(limit);
  const searchRef = useRef(search);

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const classes = useStyles({
    isMobile: layout === "mobile",
  });

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadData = useCallback((threadID: any) => {
    return communicationData.list.filter((item: any) => item.threadID === threadID).length === 0 ? null : communicationData.list.find((item: any) => item.threadID === threadID);
  }, [communicationData.list]);

  const threadData = getThreadData(threadID);
  const isThreadReady = threadData && threadData.length !== 0;
  const usersData = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)) : [];

  const groupMessagesByTimeAndAuthor = (messages: any) => {
    const groups: any = [];
    const defaultGroups: any = {};
    let lastDefaultMessageTime: any = null;
    messages.sort((a: any, b: any) => { return a.messageID - b.messageID; });
    const defaultMessages = messages.filter((item: any) => item.type === "default");
    const firstMessageID = defaultMessages[0] ? defaultMessages[0].messageID : 0;
    messages.forEach((message: any, key: any) => {
      if(message && message.createdDate && message.authorID && message.type) {
        const messageDate = /*message.modifiedDate ? message.modifiedDate : */message.createdDate;
        const timeKey = messageDate.slice(0, 16);
        const realTime = moment(messageDate).format("YYYY-MM-DD HH:mm:ss");
        const messageID = message.messageID;
        const authorID = message.authorID;
        const messageType = message.type;
        const messageTime = moment(messageDate);
        if((messageType === "default" && lastDefaultMessageTime && (Math.abs(lastDefaultMessageTime.diff(messageTime, 'minute')) > 30)) || messageID === firstMessageID) {
          groups.push({
            time: timeKey,
            realTime: moment(realTime).set("seconds", 0).format("YYYY-MM-DD HH:mm:ss"),
            datetime: messageDate,
            type: 'datetime',
          });
        }
        if(messageType === "system") {
          groups.push({
            time: timeKey,
            realTime: realTime,
            message: message,
            type: 'system',
            key: key,
          });
        } else if(messageType === "default") {
          const groupKey = `${timeKey}-${authorID}`;
          if(!defaultGroups[groupKey]) {
            defaultGroups[groupKey] = {
              time: timeKey,
              realTime: realTime,
              authorID: authorID,
              messages: [],
              type: 'default',
            };
            groups.push(defaultGroups[groupKey]);
          }
          defaultGroups[groupKey].messages.push(message);
          defaultGroups[groupKey].realTime = realTime;
          lastDefaultMessageTime = messageTime;
        }
      }
    });
    usersData.filter((item: any) => item.lastSeenDate).forEach((user: any) => {
      const timeKey = user.lastSeenDate.slice(0, 16);
      const realTime = moment(user.lastSeenDate).format("YYYY-MM-DD HH:mm:ss");
      groups.push({
        time: timeKey,
        realTime: realTime,
        user: {userID: user.userID, lastSeenDate: user.lastSeenDate},
        type: 'user',
      });
    });
    groups.sort((a: any, b: any) => { return moment(a.time).unix() - moment(b.time).unix(); });
    groups.sort((a: any, b: any) => { if(a.type === "system" || b.type === "system") return a.key - b.key; else return 1; });
    const totalMessages = groups.filter((item: any) => item.type !== "user").length;
    const transformedGroups = [];
    let currentUserArray = [];
    for (let i = 0; i < groups.length; i++) {
      const obj = groups[i];
      if (obj.type === 'user') {
        currentUserArray.push(obj.user);
        if (i === groups.length - 1 || groups[i + 1].type !== 'user') {
          transformedGroups.push({
            time: obj.time,
            realTime: obj.realTime,
            users: [...currentUserArray],
            type: 'users'
          });
          currentUserArray = [];
        }
      } else {
        transformedGroups.push({...obj});
      }
    }
    const allMessages = totalMessages === 0 ? [] : transformedGroups.filter((item, index, arr) => {
      if(item.type === 'datetime') {
        const prevDatetime = arr[index - 1];
        if(prevDatetime && prevDatetime.type === 'datetime') {
          const diffMinutes = moment(item.datetime).diff(moment(prevDatetime.datetime), 'minutes');
          return diffMinutes >= 30;
        }
        return true;
      }
      return true;
    });
    allMessages.sort((a: any, b: any) => { return moment(a.realTime).unix() - moment(b.realTime).unix(); })
    return allMessages;
  };

  const groupedMessages = groupMessagesByTimeAndAuthor(messages);
  
  const handleLoadMessages = useCallback((page: any) => {
    if(search) return;
    if(isEndOfScroll.current) return;
    if(isLoadingMessages) return;
    setIsLoadingMessages(true);
    const settings = {
      limit: limitRef.current,
      page: page,
      threadID: threadIDRef.current
    };
    communicationService && communicationService.listMessages(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const scrollTo = page !== 1 ? messagesContainerRef.current.scrollHeight : 0;
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          let newMessages: any;
          if(page === 1) {
            newMessages = response;
          } else {
            if(result.data.length !== 0) {
              newMessages = [...messagesRef.current, ...response];
            } else {
              newMessages = messagesRef.current;
            }
          }
          setMessages(newMessages);
          messagesRef.current = newMessages;
          setLastDateTime(moment());
          pageRef.current = page + 1;
          setIsLoadingMessages(false);
          if(page === 1) {
            if(messagesContainerRef && messagesContainerRef.current) {
              setTimeout(() => {
                if(messagesContainerRef && messagesContainerRef.current) {
                  messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
                }
              } , 1);
            }
            const newThreadData = newMessages[0].thread;
            const newData = {
              threadID: threadID,
              data: newThreadData,
            };
            dispatch(updateThreadData(newData));
          } else {
            if(messagesContainerRef && messagesContainerRef.current) {
              setTimeout(() => {
                if(messagesContainerRef && messagesContainerRef.current) {
                  messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - scrollTo;
                }
              } , 1);
            }
          }
          if(count <= (limitRef.current * page)) {
            setState("isEndOfScroll", true);
            isEndOfScroll.current = true;
          }
        } else {
          createNotification(t("community_messages_failed_load"), "error");
          setIsLoadingMessages(false);
        }
      } else {
        createNotification(t("community_messages_failed_load"), "error");
        setIsLoadingMessages(false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_load"), "error");
      setIsLoadingMessages(false);
    });
  }, [communicationService, t, setState, isLoadingMessages, setLastDateTime, setMessages, setIsLoadingMessages, dispatch, threadID, messagesRef, messagesContainerRef, search]);

  const handleSearchMessages = useCallback((value: any, page: any) => {
    if(isEndOfScroll.current) return;
    if(isLoadingMessages) return;
    setIsLoadingMessages(true);
    const settings = {
      threadID: threadID,
      limit: limitRef.current,
      page: page,
      search: value,
    };
    communicationService && communicationService.listMessages(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const scrollTo = page !== 1 ? messagesContainerRef.current.scrollHeight : 0;
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          let newMessages: any;
          if(page === 1) {
            newMessages = response;
          } else {
            if(result.data.length !== 0) {
              newMessages = [...messagesRef.current, ...response];
            } else {
              newMessages = messagesRef.current;
            }
          }
          setMessages(newMessages);
          messagesRef.current = newMessages;
          setLastDateTime(moment());
          pageRef.current = page + 1;
          setIsLoadingMessages(false);
          if(page === 1) {
            if(messagesContainerRef && messagesContainerRef.current) {
              setTimeout(() => {
                if(messagesContainerRef && messagesContainerRef.current) {
                  messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
                }
              } , 1);
            }
            const newThreadData = newMessages[0].thread;
            const newData = {
              threadID: threadID,
              data: newThreadData,
            };
            dispatch(updateThreadData(newData));
          } else {
            if(messagesContainerRef && messagesContainerRef.current) {
              setTimeout(() => {
                if(messagesContainerRef && messagesContainerRef.current) {
                  messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight - scrollTo;
                }
              } , 1);
            }
          }
          if(count <= (limitRef.current * page)) {
            setState("isEndOfScroll", true);
            isEndOfScroll.current = true;
          }
        } else {
          createNotification(t("community_messages_failed_load"), "error");
          setIsLoadingMessages(false);
        }
      } else {
        createNotification(t("community_messages_failed_load"), "error");
        setIsLoadingMessages(false);
      }
    }).catch(() => {
      createNotification(t("community_messages_failed_load"), "error");
      setIsLoadingMessages(false);
    });
  }, [communicationService, setIsLoadingMessages, messagesContainerRef, setMessages, t, isLoadingMessages, threadID, dispatch, messagesRef, setLastDateTime, setState]);

  const handleViewMessageViewed = (users: any) => {
    const settings = {
      isOpen: true,
      users: users,
    };
    dispatch(setCommunicationMessageViewedModal(settings));
  };

  const handleSetActiveMessageID = (messageID: any) => {
    setState("activeMessageID", messageID);
  };

  useEffect(() => {
    if(limit > 0) {
      limitRef.current = limit;
      setState("isEndOfScroll", false);
      isEndOfScroll.current = false;
      threadIDRef.current = threadID;
      handleLoadMessages(1);
    }
  }, [handleLoadMessages, setState, threadID, limit], [threadID, reset, limit]);

  useEffect(() => {
    searchRef.current = search;
    pageRef.current = 2;
  }, [search], [search]);
  
  useEffect(() => {
    const messagesContainerRefCurrent = messagesContainerRef.current;
    if(messagesContainerRefCurrent) {
      const handleScroll = () => {
        const scrollTop = messagesContainerRefCurrent.scrollTop;
        const scrollHeight = messagesContainerRefCurrent.scrollHeight;
        const clientHeight = messagesContainerRefCurrent.clientHeight;
        if(scrollTop === 0 && scrollHeight > clientHeight) {
          if(searchRef) {
            handleSearchMessages(searchRef.current, pageRef.current);
          } else {
            handleLoadMessages(pageRef.current);
          }
        }
      };
      messagesContainerRefCurrent.addEventListener('scroll', handleScroll);
      return () => {
        if(messagesContainerRefCurrent) {
          messagesContainerRefCurrent.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [handleLoadMessages, handleSearchMessages, messagesContainerRef], []);

  return (
    <div className={classes.messages} ref={messagesContainerRef}>
      {
        isLoadingMessages ? (
          <>
            {
              messages.length === 0 ? (
                <div className={classes.loading}>
                  {
                    threadsList.length === 0 ? (
                      <span>{t('communication_loading_messages')}</span>
                    ) : null
                  }
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : (
                <div className={classes.loadingSmall}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              )
            }
          </>
        ) : null
      }
      {
        groupedMessages.map((group: any, key: any) => {
          const isGroupAuthor = group.authorID ? userData.userObject.userID === group.authorID : false;
          const groupType = group.type;
          
          return groupType === "default" ? (
            <div className={isGroupAuthor ? classes.messageGroupTo : classes.messageGroupFrom} key={`k_${key}`}>
              {
                group.messages.map((item: any, mkey: any) => (
                  <Message key={`k_${mkey}`} threadID={threadID} activeMessageID={state.activeMessageID} setActiveMessageID={handleSetActiveMessageID} messages={messages} setMessages={setMessages} messageID={item.messageID} editMessageID={editMessageID} setEditMessageID={setEditMessageID} search={search} messagesContainerRef={messagesContainerRef}/>
                ))
              }
            </div>
          ) : group.type === "system" ? (
            <div key={`k_${key}`} className={classes.messageSystem}>
              <Tooltip title={DateFormat(group.time, "timeline", languageData, t)} position='top' maxWidth={400} arrow>
                <span>
                  {group.message.text}
                </span>
              </Tooltip>
            </div>
          ) : group.type === "datetime" ? (
            <div key={`k_${key}`} className={classes.messageDateTime}>
              {DateFormat(group.datetime, "timeline", languageData, t)} 
            </div>
          ) : group.type === "users" ? (
            <div key={`k_${key}`} className={classes.messageUsersViewed} onClick={() => handleViewMessageViewed(group.users)}>
              {
                group.users.length > maxViewUsers ? (
                  <Tooltip title={(group.users ? group.users.slice(maxViewUsers, group.users.length).map((item: any) => { return getUserData(item.userID) ? getUserData(item.userID).displayName : ""; }) : []).join("\n")} position='top' maxWidth={400} multiline={true} arrow>
                    <span>
                      {group.users.length - maxViewUsers}+
                    </span>
                  </Tooltip>
                ) : null
              }
              {
                group.users.slice(0, maxViewUsers).map((item: any, ukey: any) => {
                  const userDisplayName = getUserData(item.userID) ? getUserData(item.userID).displayName : "";
                  const userAvatar = getUserData(item.userID) ? getUserData(item.userID).photo.thumbLink : "";
                  const userLastSeenDate = item.lastSeenDate ? item.lastSeenDate : group.time;
                  return (
                    <Tooltip key={`k_${ukey}`} title={t('communication_message_viewed', {user: userDisplayName, datetime: DateFormat(userLastSeenDate, "timeline", languageData, t)})} position='top' maxWidth={400} arrow>
                      <div><AuthenticatedImage className={classes.viewedAvatar} thumbLink={userAvatar}/></div>
                    </Tooltip>
                  );
                })
              }
            </div>
          ) : null;
        })
      }
    </div>
  );
};

export default Messages;