import DatePicker from '../../DatePickers/Attendance';
import moment from '../../../utils/moment';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setAttendanceDate } from '../../../store/actions/attendance.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  dateCarousel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  dateList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: 'fit-content',
    maxWidth: '100%',
    gap: '4px',
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    '& > span': {
      fontSize: '13px',
      fontWeight: 'bold',
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
      borderRadius: '25px',
      padding: '5px 10px',
    },
    '&.newYear': {
      marginRight: '50px',
    },
  },
  date: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '90px',
    minWidth: '60px',
    minHeight: '90px',
    maxWidth: '60px',
    maxHeight: '90px',
    backgroundColor: theme.colors.white,
    margin: '10px 5px',
    borderRadius: '25px',
    position: 'relative',
    '&.disabled': {
      backgroundColor: theme.colors.grey[125],
      color: '#5A5C7B',
      cursor: 'auto',
    },
    '&.selected': {
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      '& > span': {
        color: theme.colors.white
      },
    },
    '&:not(.selected):not(.disabled)': {
      cursor: 'pointer',    
    },
    '&.today': {
      '&::after': {
        content: `''`,
        width: '6px',
        height: '6px',
        backgroundColor: '#89D3E5',
        borderRadius: '100%',
        position: 'absolute',
        bottom: '8px',
      },
    },
    '& > svg': {
      color: 'inherit',
      width: '24px',
      height: '24px',
      marginTop: '10px',
    },
  },
  day: {
    color: theme.colors.grey[400],
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  dayCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    width: '33px',
    height: '33px',
    borderRadius: '100%',
    fontSize: '14px',
  },
  newYear: {
    position: 'absolute',
    top: '55%',
    left: 'calc(100%)',
    whiteSpace: 'nowrap',
    fontSize: '13px',
    fontWeight: 'bold',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderRadius: '25px',
    padding: '5px 10px',
  },
}));

type DateCarouselSelectType = {
  width?: any,
};

const DateCarouselSelect: React.FunctionComponent<DateCarouselSelectType> = ({width = null}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const attendanceData = useAppSelector((state: any) => state.attendance);
  const date = attendanceData.date;

  const [state, setState] = useStates({
    daysBefore: [],
    daysAfter: [],
  });
  
  const handleClickToday = () => {
    if(!moment(date).isSame(moment(), 'day')) {
      dispatch(setAttendanceDate(moment()));
    }
  };
  
  useEffect(() => {
    const dayCount = (Math.round(width / 70) - 2) / 2;
    let beforeDays = [];
    for(let i = 1; i <= dayCount - 1; i++) {
      const newDay = moment(date).subtract(i, 'day');
      beforeDays.push(newDay);  
    }
    beforeDays.reverse();
    let afterDays = [];
    for(let i = 1; i <= dayCount - 1; i++) {
      const newDay = moment(date).add(i, 'day');
      afterDays.push(newDay);  
    }
    setState("daysBefore", beforeDays);
    setState("daysAfter", afterDays);
  }, [date, width, setState], [date, width]);
  
  const handleClickDate = (day: any) => {
    dispatch(setAttendanceDate(moment(day)));
  };
  
  return (
    <div className={classes.dateCarousel} data-cy={isCypress() ? 'dateCarouselSelect' : null}>
      <div className={classes.dateList}>
        <div className={classes.dateWrapper}>
          <div className={`${classes.date} ${moment(date).isSame(moment(), 'day') ? 'disabled' : null}`} onClick={handleClickToday} data-cy={isCypress() ? 'todayButton' : null}>
            <span className={classes.day}>{t('today')}</span>
            <SVG src="calendar-today"/>
          </div>
        </div>
        {
          state.daysBefore.map((day: any, key: any) => (
            <div className={`${classes.dateWrapper} ${moment(day).isSame(moment(day).format("YYYY-12-31"),"day") ? 'newYear' : null}`} key={`k_${key}`} data-cy={isCypress() ? 'dateBlock' + moment(day).format("YYYY-MM-DD") : null}>
              {
                moment(day).isSame(moment(day).format("YYYY-MM-") + moment(day).daysInMonth(),"day") ? (
                  <span data-cy={isCypress() ? 'dateMonthBlock' + moment(day).locale("en").format('MMMM') : null} data-clarity-unmask="true">
                    {t(moment(day).locale("en").format('MMMM').toLowerCase())}
                  </span>
                ) : null
              }
              <div className={`${classes.date} ${moment(day).isSame(moment(),'day') ? 'today' : null}`} onClick={() => handleClickDate(day)} data-cy={isCypress() ? 'dateClickableBlock' + moment(day).format("YYYY-MM-DD") : null}>
                <span className={classes.day} data-clarity-unmask="true">{t(moment(day).locale("en").format('dd').toLowerCase())}</span>
                <div className={classes.dayCircle} data-clarity-unmask="true">{moment(day).format('DD')}</div>
              </div>
              {
                moment(day).isSame(moment(day).format("YYYY-12-31"),"day") ? (
                  <div className={classes.newYear} data-cy={isCypress() ? 'dateYearBlock' + (parseInt(moment(day).format('YYYY')) + 1) : null} data-clarity-unmask="true">
                    {parseInt(moment(day).format('YYYY')) + 1}
                  </div>
                ) : null
              }
            </div>
          ))
        }
        <div className={`${classes.dateWrapper} ${moment(date).isSame(moment(date).format("YYYY-12-31"),"day") ? 'newYear' : null}`} data-cy={isCypress() ? 'todayBlock' : null}>
          <span data-cy={isCypress() ? 'dateMonthBlock' + moment(date).locale("en").format('MMMM') : null} data-clarity-unmask="true">
            {t(moment(date).locale("en").format('MMMM').toLowerCase())}
          </span>
          <div className={`${classes.date} ${"selected"} ${moment(date).isSame(moment(),'day') ? 'today' : null}`}>
            <span className={classes.day} data-clarity-unmask="true">{t(moment(date).locale("en").format('dd').toLowerCase())}</span>
            <div className={classes.dayCircle} data-clarity-unmask="true">{moment(date).format('DD')}</div>
          </div>
          {
            moment(date).isSame(moment(date).format("YYYY-12-31"),"day") ? (
              <div className={classes.newYear} data-cy={isCypress() ? 'dateYearBlock' + (parseInt(moment(date).format('YYYY')) + 1) : null} data-clarity-unmask="true">
                {parseInt(moment(date).format('YYYY')) + 1}
              </div>
            ) : null
          }
        </div>
        {
          state.daysAfter.map((day: any, key: any) => (
            <div className={`${classes.dateWrapper} ${moment(day).isSame(moment(day).format("YYYY-12-31"),"day") ? 'newYear' : null}`} key={`k_${key}`} data-cy={isCypress() ? 'dateBlock' + moment(day).format("YYYY-MM-DD") : null}>
              {
                moment(day).isSame(moment(day).format("YYYY-MM-01"),"day") ? (
                  <span data-cy={isCypress() ? 'dateMonthBlock' + moment(day).locale("en").format('MMMM') : null} data-clarity-unmask="true">
                    {t(moment(day).locale("en").format('MMMM').toLowerCase())}
                  </span>
                ) : null
              }
              <div className={`${classes.date} ${moment(day).isSame(moment(),'day') ? 'today' : null}`} onClick={() => handleClickDate(day)} data-cy={isCypress() ? 'dateClickableBlock' + moment(day).format("YYYY-MM-DD") : null}>
                <span className={classes.day} data-clarity-unmask="true">{t(moment(day).locale("en").format('dd').toLowerCase())}</span>
                <div className={classes.dayCircle} data-clarity-unmask="true">{moment(day).format('DD')}</div>
              </div>
              {
                moment(day).isSame(moment(day).format("YYYY-12-31"),"day") ? (
                  <div className={classes.newYear} data-cy={isCypress() ? 'dateYearBlock' + (parseInt(moment(day).format('YYYY')) + 1) : null} data-clarity-unmask="true">
                    {parseInt(moment(day).format('YYYY')) + 1}
                  </div>
                ) : null
              }
            </div>
          ))
        }
        <DatePicker date={date} setDate={(date: any) => handleClickDate(date)} dateRange={{start: date, end: date}} />
      </div>
    </div>
  );
}

export default DateCarouselSelect;