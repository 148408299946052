import DateItem from './DateItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../utils/moment';
import months from 'src/constants/months';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { calendarViewModes } from 'src/utils/useCalendar';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting } from 'src/utils/useUser';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { setCalendarDate, setCalendarViewMode } from 'src/store/actions/calendar.actions';
import { setCalendarEventHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../../constants/weekdays';

const useStyles = createUseStyles((theme: any) => ({
  calendarYear: {
    width: '100%',
    height: 'calc(100% - 16px)',
    padding: '8px 0',
  },
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: 'calc(100% - 16px)',
    height: '100%',
    gap: '16px',
    overflow: 'auto',
    maxHeight: '100%',
    padding: '0 8px',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '20px',
    },
  },
  month: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
  },
  monthHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    '& > span': {
      display: 'flex',
      width: 'fit-content',
      backgroundColor: theme.colors.white,
      marginBottom: '8px',
      padding: '4px 8px',
      fontWeight: '500',
      borderRadius: '12px',
      cursor: 'pointer',
    },
    '& > button': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.grey[100],
      },
    }
  },
  weekdayHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ececec',
    borderRadius: '24px 24px 0 0',
    borderBottom: '1px solid #d2d2d2',
    position: 'relative',
    zIndex: theme.zIndex.calendarHeader,
  },
  weekday: {
    width: 'calc(100% / 7)',
    padding: '10px',
    fontSize: '14px',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0',
    },
  },
  days: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type CalendarYearType = {
  className?: any;
};

const CalendarYear: React.FunctionComponent<CalendarYearType> = ({ className }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const userData = useAppSelector((state: any) => state.user);

  const curDate = calendarData.date;
  const isLoading = calendarData.isLoading;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const getFirstDate = useCallback((month: any) => {
    const startOfMonth = moment(curDate).set('month', month).date(0);
    return moment(startOfMonth).subtract(moment(startOfMonth).day(), 'days');
  }, [curDate]);

  const handleAddEvent = (e: any, month: any) => {
    e.preventDefault();
    e.stopPropagation();
    const settings = {
      isOpen: true,
      date: moment().set('month', month).set('year', moment(curDate).year()).startOf('month'),
      dateTime: null,
      eventID: null,
    };
    dispatch(setCalendarEventHandleModal(settings));
  };

  const handleSetMonth = (month: any) => {
    dispatch(setCalendarDate(moment(curDate).set('month', month)));
    dispatch(setCalendarViewMode(calendarViewModes[2]));
  };
  
  return (
    <div className={`${classes.calendarYear} ${className ? className : null}`}>
      <div className={classes.months}>
        {
          months.map((item: any, key: any) => {
            const firstDate = getFirstDate(key);
            return (
              <div className={classes.month} key={`k_${key}`}>
                <div className={classes.monthHeader}>
                  <span onClick={() => handleSetMonth(key)}>{t(item.toLowerCase())}</span>
                  {
                    getUserRole(userData.userObject.roleType) === "director" ? (
                      <IconButton size="medium" onClick={(e: any) => handleAddEvent(e, key)}>
                        <SVG src="plus"/>
                      </IconButton>
                    ) : null
                  }
                </div>
                <div className={classes.weekdayHeader}>
                {
                  weekdays.map((weekday: any, key: any) => (
                    <div className={classes.weekday} key={`k_${key}`}>
                      <p>{t(weekday[0].toLowerCase() + weekday.slice(1))}</p>
                    </div>
                  ))
                }
                </div>
                <div className={classes.days}>
                  {
                    isLoading ? (
                      <div className={classes.loadingWrapper}>
                        <div className={classes.spinner}>
                          <CircularProgress/>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    firstDate && new Array(42).fill('').map((_, key) => {
                      return (
                        <DateItem
                          index={key}
                          key={`k_${key}`}
                          date={moment(firstDate).add(key + firstDayInWeek, 'days')}
                        />
                      );
                    })
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default CalendarYear;