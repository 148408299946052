import moment from 'src/utils/moment';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { setExcuseNotesHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const ExcuseNotes: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const modalsData = useAppSelector((state: any) => state.modals);

  const handleAdd = () => {
    const settings = {
      isOpen: true,
      date: moment(),
      child: null,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };
  
  return (
    <>
      <TopBarButton className={modalsData.excuseNotesHandleModal.isOpen ? 'active' : null} icon={<SVG src="plus"/>} onClick={handleAdd} dataCy="topBarButtonAdd" tooltip={t('create')} disabled={((excusenotesData.viewMode === "class" && moment(excusenotesData.date).isBefore(moment(), 'day')) || modalsData.postCreateModal.isOpen)}/>
    </>
  );
}

export default ExcuseNotes;