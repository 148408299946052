import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import StorageService from 'src/services/storage.service';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { languages } from 'src/constants/languages';
import { setAppData } from 'src/utils/useApp';
import { setCookies } from 'src/store/actions/layout.actions';
import { setCookiesModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { isCypress } from 'src/utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  cookiesBanner: {
    position: 'fixed',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'fit-content',
    maxWidth: 'calc(90% - 40px)',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 20px',
    borderRadius: '6px',
    fontSize: '0.9rem',
    gap: '8px',
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    zIndex: theme.zIndex.cookiesBanner,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: 'calc(90% - 40px)',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: '0 0 70%',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: 'unset',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: '0 0 30%',
    width: '30%',
    '& > button': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: 'unset',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
  },
  description: {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
  link: {
    color: theme.colors.black,
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
}));

const CookiesBanner: React.FunctionComponent = () => {
  
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const languageData = useAppSelector((state: any) => state.language);

  const handleLink = () => {
    const langs: any = languages;
    const url: any = langs.find((lang: any) => lang.acronym === languageData.language).cookiesLink;
    window.open(url);
  };

  const handleConfirm = () => {
    const cookies = ["necessary", "analytics"];
    dispatch(setCookies(cookies));
    setAppData({cookies: cookies});
    createNotification(t('cookies_settings_saved'), 'success');
  };

  const handleSettings = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCookiesModal(settings));
  };

  const handleConfirmNecessary = () => {
    const cookies = ["necessary"];
    dispatch(setCookies(cookies));
    setAppData({cookies: cookies});
    StorageService.deleteAllCookies();
    createNotification(t('cookies_settings_saved'), 'success');
  };

  return (
    <div className={classes.cookiesBanner} data-cy={isCypress() ? 'cookiesBanner' : null}>
      <div className={classes.leftSide}>
        <span className={classes.title} data-cy={isCypress() ? 'cookiesBannerTitle' : null}>
          {t('cookies_consent_title')}
        </span>
        <p className={classes.description} data-cy={isCypress() ? 'cookiesBannerDescription' : null}>
          {t('cookies_consent_description')}
        </p>
        <span className={classes.link} onClick={handleLink} data-cy={isCypress() ? 'cookiesBannerLink' : null}>
          {t('cookies_consent_link')}
        </span>
      </div>
      <div className={classes.rightSide}>
        <NormalButton buttonType='primary' onClick={handleConfirm} dataCy='cookiesBannerConfirm'>
          {t('cookies_consent_confirm')}
        </NormalButton>
        <NormalButton buttonType='secondary' onClick={handleSettings} dataCy='cookiesBannerSettings'>
          {t('cookies_consent_settings')}
        </NormalButton>
        <NormalButton buttonType='secondary' onClick={handleConfirmNecessary} dataCy='cookiesBannerNecessary'>
          {t('cookies_consent_confirm_necessary')}
        </NormalButton>
      </div>
    </div>
  );
}

export default CookiesBanner;