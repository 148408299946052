import React from 'react';
import RenderInline from '../RenderInline';
import RenderInModal from '../RenderInModal';

type RenderAllType = {
  state: any;
  setState: any;
};

const RenderAll: React.FunctionComponent<RenderAllType> = ({
  state,
  setState,
}) => {

  return state.isInModal ? (
    <RenderInModal state={state} setState={setState}/>
  ) : (
    <RenderInline state={state} setState={setState}/>
  );
}

export default RenderAll;