import CalendarEventItem from 'src/components/Items/CalendarEventItem';
import moment from '../../../utils/moment';
import React from 'react';
import theme from '../../../ui/theme';
import { compareDates } from 'src/utils/date';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { weekdays } from 'src/constants/weekdays';
import { useState } from 'src/utils/useState';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  date: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '0px',
    '& > span': {
      fontWeight: 'bold',
    },
    '&.empty': {
      color: '#bcbcbc',
      fontWeight: 'normal',
    },
  },
  today: {
    borderRadius: '100%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    fontWeight: '800',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-4px auto',
  },
  popover: {
    '& .MuiPopover-paper': {
      boxShadow: theme.shadows[1],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[200],
      borderRadius: '12px',
      backgroundColor: theme.colors.white,
    },
  },
  list: {
    display: 'flex',
    width: '200px',
    padding: '8px',
    gap: '4px',
    height: '100%',
    flexDirection: 'column',
    borderRadius: '12px',
    maxHeight: '20vh',
    overflow: 'auto',
    '& > span': {
      fontSize: '11px',
    },
  },
}));

type CalendarEventsItemType = {
  id?: any;
  anchorEl: any;
  onClose: any;
  date: any;
  events: any;
};

const CalendarEventsItem: React.FunctionComponent<CalendarEventsItemType> = ({id = getElementID(), anchorEl, onClose, date = moment(), events = []}) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(Boolean(anchorEl));

  useEffect(() => {
    if(Boolean(anchorEl)) {
      setIsVisible(true);
    }
  }, [anchorEl])

  const handleOnClose = () => {
    onClose();
    setTimeout(() => {
      setIsVisible(false);
    }, theme.transitions.duration.complex);
  };

  return isVisible ? (
    <Popover className={classes.popover} id={id} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleOnClose} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'center', horizontal: 'center' }}>
      <p className={`${classes.date} ${events.length === 0 ? 'empty' : null}`}>
        {t(weekdays[moment(date).isoWeekday() - 1].toLowerCase())}
        <span className={compareDates(date, moment()) ? classes.today : ''}>{date.date()}</span>
      </p>
      <div className={classes.list}>
        {
          events.map((item: any, key: any) => (
            <CalendarEventItem data={item} date={date} overlaping={false} key={`k_${key}`}/>
          ))
        }
        {
          events.length === 0 ? (
            <span>{t('calendar_no_event_this_day')}</span>
          ) : null
        }
      </div>
    </Popover>
  ) : null;
};

export default CalendarEventsItem;