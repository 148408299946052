import BootstrapIconPicker from 'src/components/Forms/BootstrapIconPicker';
import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import RadioGroup from 'src/components/Forms/RadioGroup';
import React from 'react';
import theme from 'src/ui/theme';
import { bootstrapIcons } from 'src/constants/icons';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setStockCategories } from 'src/store/actions/stock.actions';
import { setStockCategoryHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 20px)',
    overflow: 'auto',
    flex: '1 1 auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  bootstrapIconPicker: {
    maxHeight: '300px',
  },
}));

const StockCategoryHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const stockData = useAppSelector((state: any) => state.stock);

  const isCategoryType = false;

  const isEdit = modalsData.stockCategoryHandleModal.categoryID === null ? false : true;
  const categoryID = isEdit ? modalsData.stockCategoryHandleModal.categoryID : null;
  const categoryData = isEdit ? (stockData.categories.filter((category: any) => category.categoryID === categoryID).length === 1 ? stockData.categories.find((category: any) => category.categoryID === categoryID) : null) : null

  const [state, setState] = useStates({
    name: isEdit ? categoryData.name : "",
    icon: isEdit ? categoryData.icon : "",
    type: isEdit ? categoryData.type.toString() : null,
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      categoryID: null,
      schoolID: null,
    };
    dispatch(setStockCategoryHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  const handleSave = () => {
    const payload = {
      "name": state.name,
      "icon": state.icon,
      "type": state.type,
      "schoolID": modalsData.stockCategoryHandleModal.schoolID,
    };
    if(isEdit) {
      stockService && stockService.editCategory(categoryID, payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("stock_category_updated"), "success");
            onCloseModal();
            const newCategories = stockData.categories.map((category: any) => {
              if(category.categoryID === categoryID) {
                return result.data;
              } else {
                return category;
              }
            });
            dispatch(setStockCategories(newCategories));
          } else {
            createNotification(t("stock_category_not_updated"), "error");
          }
        } else {
          createNotification(t("stock_category_not_updated"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_category_not_updated"), "error");
      });
    } else {
      stockService && stockService.createCategory(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("stock_category_added"), "success");
            onCloseModal();
            const newCategories = [...stockData.categories, result.data];
            dispatch(setStockCategories(newCategories));
          } else {
            createNotification(t("stock_category_not_added"), "error");
          }
        } else {
          createNotification(t("stock_category_not_added"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_category_not_added"), "error");
      });
    }
  };

  const categoryTypes: any = [
    {
      name: t('stock_category_type_reservation'),
      value: '1',
      disabled: true,
      dataCy: 'reservationRadio',
    },
    {
      name: t('stock_category_type_orders'),
      value: '2',
      dataCy: 'ordersRadio',
    },
  ];

  const bootstrapIconsList: any = bootstrapIcons.map((icon: any) => {
    return {icon: icon, name: icon, color: theme.colors.black};
  });

  const handleCategoryType = (e: any) => {
    setState("type", e.target.value);
  };

  const handleBootstrapIcon = (value: any) => {
    if(value === state.icon) {
      setState("icon", "");
    } else {
      setState("icon", value);
      setState("isDropdownOpen", false);
    }
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockCategoryHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('stock_category_edit') : t('stock_category_add')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Input label={t('stock_category_name')} name="name" value={isEdit ? categoryData.name : null} onChange={handleInputChange} autoFocus={true}/>
          <Label>{t('stock_category_icon')}</Label>
          <BootstrapIconPicker className={classes.bootstrapIconPicker} items={bootstrapIconsList} value={state.icon} valueCompare={"icon"} buttonSelected={false} buttonInactive={true} buttonOpacity={0.2} onChange={handleBootstrapIcon}/>
          {
            (!isEdit && isCategoryType) ? (
              <>
                <Label>{t('stock_category_type')}</Label>
                <RadioGroup items={categoryTypes} value={state.type} onChange={handleCategoryType}/>
              </>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.name === "" || state.icon === "" || state.type === null} dataCy="saveButton">
            {t('save')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default StockCategoryHandleModal;