import theme from 'src/ui/theme';

const calendarViewModes: any = [
  {
    name: 'day',
    value: 'day',
  },
  {
    name: 'week',
    value: 'week',
  },
  {
    name: 'month',
    value: 'month',
  },
  {
    name: 'year',
    value: 'year',
  },
];

const calendarEventTypes: any = [
  {
    name: 'event',
    value: 'event',
    icon: 'event',
    backgroundColor: theme.colors.primaryBlue[400],
    backgroundColorHover: theme.colors.primaryBlue[500],
    textColor: theme.colors.white,
    visible: ["parent", "teacher", "director"],
  },
  {
    name: 'birthday_child',
    value: 'birthdayChild',
    icon: 'birthday-cake',
    backgroundColor: theme.colors.systemRed[400],
    backgroundColorHover: theme.colors.systemRed[500],
    textColor: theme.colors.white,
    visible: ["parent", "teacher", "director"],
  },
  {
    name: 'birthday_user',
    value: 'birthdayUser',
    icon: 'birthday-cake',
    backgroundColor: theme.colors.systemOrange[400],
    backgroundColorHover: theme.colors.systemOrange[500],
    textColor: theme.colors.white,
    visible: ["teacher", "director"],
  },
  {
    name: 'holiday',
    value: 'holiday',
    icon: 'holiday',
    backgroundColor: theme.colors.systemGreen[400],
    backgroundColorHover: theme.colors.systemGreen[500],
    textColor: theme.colors.white,
    visible: ["parent", "teacher", "director"],
  },
];

export {
  calendarViewModes,
  calendarEventTypes,
};