import CloseButton from 'src/components/Buttons/CloseButton';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import QRCode from 'react-qr-code';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { setSSOLoginQrCodeModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { getAppEnvironmentData } from 'src/utils/useApp';
import { useNavigate } from 'react-router';

interface Props {
  qrReaderWidth: any;
  qrReaderHeight: any;
  isUrl: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: '0px',
    backgroundColor: (props: Props) => {
      if(!props.isUrl) return theme.colors.black;
      else return '';
    },
    overflow: 'visible',
    width: 'fit-content',
    maxWidth: '100%',
    maxHeight: '100%',
    outline: 'none',
  },
  qrWrapper: {
    width: 'auto',
    height: 'auto',
    position: (props: Props) => {
      if(props.isUrl) return 'relative';
      else return '';
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    '& > span': {
      borderRadius: '10px',
      padding: '4px',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
    },
  },
  qrCode: {
    position: 'relative',
    '& > svg': {
      padding: '16px',
      outline: 'none',
      maxWidth: '90vw',
      maxHeight: '90vh',
      borderRadius: '10px',
      backgroundColor: theme.colors.white,
    },
  },
  qrSpinner: {
    position: 'absolute',
    top: 'calc(50% - 64px)',
    left: 'calc(50% - 64px)',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      color: theme.colors.primaryBlue[500]
    },
  },
  closeButton: {
    position: 'absolute',
    top: (props: Props) => {
      if(props.isUrl) return '-24px';
      else return '8px';
    },
    right: (props: Props) => {
      if(props.isUrl) return '-24px';
      else return '8px';
    },
    backgroundColor: theme.colors.white,
    zIndex: '3',
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  cameraButton: {
    position: 'absolute',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    zIndex: '3',
    '&:hover': {
      backgroundColor: theme.colors.grey[200],
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    },
  },
}));

const SSOLoginQrCodeModal: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useStates({
    isLoaded: false,
    url: null,
    code: null,
    width: browserData.width,
    height: browserData.height,
    permission: false,
    devices: [],
    currentDevice: null,
    currentDeviceIndex: null,
  });

  const classes = useStyles({
    qrReaderWidth: state.width,
    qrReaderHeight: state.height,
    isUrl: state.url ? true : false,
  });

  const QrReaderProps: any = {
    scanDelay: 500,
    constraints: { 
      facingMode: 'environment', 
      width: state.width,
      height: state.height,
    },
    containerStyle: {
      position: 'relative',
      zIndex: '2',
      pointerEvents: 'none',
      paddingTop: '0',
      width: state.width,
      height: state.height,
    },
  };
  
  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
    };
    dispatch(setSSOLoginQrCodeModal(settings));
  }, [dispatch]);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleOnScan = (data: any) => {
    if(data !== null) {
      if(data.text.includes(`${getAppEnvironmentData().url}auth/sso/`)) {
        const url = data.text;
        const splited = data.text.split("/");
        const code = splited.slice(-1);
        setState("url", url);
        setState("code", code[0]);
        setTimeout(() => {
          onCloseModal();
          navigate(`/auth/sso/${code[0]}`);
        }, 1000);
      } else {
        createNotification(t('sso_invalid_code'), 'error');
      }
    }
  };

  const handleOnError = useCallback(() => {
    onCloseModal();
    createNotification(t('sso_no_camera_access'), 'error');
  }, [onCloseModal, t]);

  const handleChangeCamera = () => {
    const currentIndex = state.currentDeviceIndex;
    const devices = state.devices;
    const newIndex = (currentIndex + 1) < devices.length ? (currentIndex + 1) : 0;
    setState("currentDevice", devices[newIndex].deviceId);
    setState("currentDeviceIndex", newIndex);
  };

  const handleDevices = useCallback((mediaDevices: any, deviceId?: any) => {
    const devices = mediaDevices.filter(({ kind }: any) => kind === "videoinput").filter((item: any) => !item.label.includes("OBS"));
    setState("devices", devices);
    const devId = deviceId ? deviceId : state.currentDevice;
    const getIndex = devices.filter((item: any) => item.deviceId === devId).length === 0 ? 0 : devices.findIndex((item: any) => item.deviceId === devId);
    setState("currentDeviceIndex", getIndex);
  }, [setState, state.currentDevice]);

  const handleGetDevices = useCallback((stream: any) => {
    let device: any;
    stream.getVideoTracks().forEach((track: any)=> {
      device = track.getSettings();
      track.stop();
    });
    setState("currentDevice", device.deviceId);
    setTimeout(() => {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices: any) => handleDevices(mediaDevices, device.deviceId));
    }, 1000);
  }, [handleDevices, setState]);

  useEffect(() => {
    if(state.devices.length === 0) {
      navigator.mediaDevices.getUserMedia({ video: {
        facingMode: 'environment', 
      } }).then(handleGetDevices).catch(() => {
        return handleOnError();
      });
    }
  }, [handleGetDevices, state.devices, handleOnError], []);

  useEffect(() => {
    if(browserData.width && browserData.height) {
      setState("width", browserData.width);
      setState("height", browserData.height);
    }
  }, [browserData, setState], [browserData.width, browserData.height]);
  
  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} daty-cy={isCypress() ? "SSOGetModal" : null}>
        <div className={classes.qrWrapper}>
          {
            !state.url ? (
              <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
            ) : null
          }
          {
            (!state.isLoaded && !state.url) ? (
              <div className={classes.loading}>
                <CircularProgress className={classes.spinner}/>
              </div>
            ) : null
          }
          {
            state.url ? (
              <div className={classes.qrCode}>
                <QRCode size={256} value={state.url} viewBox={`0 0 256 256`}/>
                <CircularProgress size={128} className={classes.qrSpinner}/>
              </div>
            ) : (
              <>
                {
                  state.currentDevice ? (
                    <QrScanner scanDelay={QrReaderProps.scanDelay} onError={handleOnError} onResult={handleOnScan} deviceId={state.currentDevice} constraints={QrReaderProps.constraints} containerStyle={QrReaderProps.containerStyle}/>
                  ) : null
                }
                {
                  state.devices.length > 1 ? (
                    <IconButton className={classes.cameraButton} onClick={handleChangeCamera} size='xxl'>
                      <SVG src="camera-multiple"/>
                    </IconButton>
                  ) : null
                }
              </>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default SSOLoginQrCodeModal;