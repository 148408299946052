import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  image?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  laptop: {
    width: '100%',
  },
  monitor: {
    width: '85%',
    paddingTop: '47.8125%',
    display: 'block',
    margin: '0 auto',
    borderRadius: '10px 10px 0px 0px',
    padding: '9px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#e0e2e2',
    backgroundColor: theme.colors.alwaysBlack,
    position: 'relative',
  },
  content: {
    position: 'absolute',
    top: '9px',
    left: '9px',
    right: '9px',
    bottom: '9px',
    display: 'flex',
    width: 'calc(100% - 18px)',
    height: 'calc(100% - 18px)',
    backgroundImage: (props: Props) => {
      if(props.image) return `url('${props.image}')`;
      else return '';
    },
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    overflow: 'hidden',
  },
  base: {
    width: '100%',
    paddingTop: '4%',
    backgroundColor: '#e0e2e2',
    display: 'block',
    margin: '0 auto',
    borderRadius: '0px 0px 6px 6px',
  },
}));

type LaptopType = {
  children?: any;
  image?: any;
  className?: any;
};

const Laptop: React.FunctionComponent<LaptopType> = ({ children, image, className }) => {

  const classes = useStyles({
    image: image
  });
  
  return (
  <div className={`${classes.laptop} ${className ? className : null}`}>
    <div className={classes.monitor}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
    <div className={classes.base}/>
  </div>
  );
}

export default Laptop;
