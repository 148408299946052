const t = (key: any) => { return key };

const useQuicklinks = () => {
  
  const quicklinks = {
    parent:  [
      {
        icon: 'browser',
        color: 'grey',
        title: t('twigsee'),
        action: {
          type: 'windowopen',
          target: 'https://twigsee.com/',
        },
      },
      {
        icon: 'premium',
        color: 'green',
        title: t('premium_parent'),
        action: {
          type: 'premiumparent',
        },
      },
      {
        icon: 'bulb',
        color: 'purple',
        title: t('help'),
        action: {
          type: 'knowledgebase',
        },
      },
      {
        icon: 'facebook',
        color: 'blue',
        title: t('facebook'),
        action: {
          type: 'windowopen',
          target: 'https://www.facebook.com/twigseecom',
        },
      },
      {
        icon: 'instagram',
        color: 'pink',
        title: t('instagram'),
        action: {
          type: 'windowopen',
          target: 'https://www.instagram.com/twigsee/',
        },
      },
      {
        icon: 'pinterest',
        color: 'red',
        title: t('pinterest'),
        action: {
          type: 'windowopen',
          target: 'https://cz.pinterest.com/TwigseeApp/',
        },
      },
      {
        icon: 'youtube',
        color: 'brightRed',
        title: t('youtube'),
        action: {
          type: 'windowopen',
          target: 'https://www.youtube.com/@twigsee',
        },
      },
    ],
    teacher:  [
      {
        icon: 'browser',
        color: 'grey',
        title: t('twigsee'),
        action: {
          type: 'windowopen',
          target: 'https://twigsee.com/',
        },
      },
      {
        icon: 'papers',
        color: 'green',
        title: t('worksheets'),
        action: {
          type: 'windowopen',
          target: 'https://www.twigsee.com/pracovni-listy/',
        },
      },
      {
        icon: 'bulb',
        color: 'purple',
        title: t('help'),
        action: {
          type: 'knowledgebase',
        },
      },
      {
        icon: 'facebook',
        color: 'blue',
        title: t('facebook'),
        action: {
          type: 'windowopen',
          target: 'https://www.facebook.com/twigseecom',
        },
      },
      {
        icon: 'instagram',
        color: 'pink',
        title: t('instagram'),
        action: {
          type: 'windowopen',
          target: 'https://www.instagram.com/twigsee/',
        },
      },
      {
        icon: 'pinterest',
        color: 'red',
        title: t('pinterest'),
        action: {
          type: 'windowopen',
          target: 'https://cz.pinterest.com/TwigseeApp/',
        },
      },
      {
        icon: 'youtube',
        color: 'brightRed',
        title: t('youtube'),
        action: {
          type: 'windowopen',
          target: 'https://www.youtube.com/@twigsee',
        },
      },
    ],
    director:  [
      {
        icon: 'browser',
        color: 'grey',
        title: t('twigsee'),
        action: {
          type: 'windowopen',
          target: 'https://twigsee.com/',
        },
      },
      {
        icon: 'management',
        color: 'green',
        title: t('administration'),
        action: {
          type: 'windowopen',
          target: 'https://admin.twigsee.com/',
        },
      },
      {
        icon: 'bulb',
        color: 'purple',
        title: t('help'),
        action: {
          type: 'knowledgebase',
        },
      },
      {
        icon: 'facebook',
        color: 'blue',
        title: t('facebook'),
        action: {
          type: 'windowopen',
          target: 'https://www.facebook.com/twigseecom',
        },
      },
      {
        icon: 'instagram',
        color: 'pink',
        title: t('instagram'),
        action: {
          type: 'windowopen',
          target: 'https://www.instagram.com/twigsee/',
        },
      },
      {
        icon: 'pinterest',
        color: 'red',
        title: t('pinterest'),
        action: {
          type: 'windowopen',
          target: 'https://cz.pinterest.com/TwigseeApp/',
        },
      },
      {
        icon: 'youtube',
        color: 'brightRed',
        title: t('youtube'),
        action: {
          type: 'windowopen',
          target: 'https://www.youtube.com/@twigsee',
        },
      },
    ],
  };
  return quicklinks;
};

export default useQuicklinks;