import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppAccessToken, getAppData } from 'src/utils/useApp';

const savedData = getAppData();
const token: any = getAppAccessToken();

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  mediaLimits: any;
  membership: any;
  order: any;
  schoolSettings: any;
  threadLimits: any;
  userAccess: any;
  userAccessSchools: any;
  userObject: any;
  userSettings: any;
  userStatus: any;
};

const initialState: ArrayModel = {
  mediaLimits: {},
  membership: {},
  order: {},
  schoolSettings: {},
  threadLimits: {},
  userAccess: {},
  userAccessSchools: {},
  userObject: savedData?.user || {},
  userSettings: [],
  userStatus: (savedData?.user && Object.keys(savedData.user).length !== 0 && token && token.length > 0) ? "loggedIn" : "loggedOff",
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setMediaLimits(state, action: PayloadAction<Model>){
      state.mediaLimits = action.payload;
    },
    setMembership(state, action: PayloadAction<Model>){
      state.membership = action.payload;
    },
    setOrder(state, action: PayloadAction<Model>){
      state.order = action.payload;
    },
    setSchoolSettings(state, action: PayloadAction<Model>){
      state.schoolSettings = action.payload;
    },
    setThreadLimits(state, action: PayloadAction<Model>){
      state.threadLimits = action.payload;
    },
    setUserAccess(state, action: PayloadAction<Model>){
      state.userAccess = action.payload;
    },
    setUserAccessSchools(state, action: PayloadAction<Model>){
      state.userAccessSchools = action.payload;
    },
    setUserObject(state, action: PayloadAction<Model>){
      state.userObject = action.payload;
    },
    setUserSettings(state, action: PayloadAction<Model>){
      state.userSettings = action.payload;
    },
    setUserStatus(state, action: PayloadAction<Model>){
      state.userStatus = action.payload;
    },
  }
});

export default slice;