import slice from '../slices/communication.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setCommunicationDrafts = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationDrafts(data));
  }
};

export const setCommunicationList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationList(data));
  }
};

export const updateCommunicationList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateCommunicationList(data));
  }
};

export const addToCommunicationList = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addToCommunicationList(data));
  }
};

export const updateThreadData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.updateThreadData(data));
  }
};

export const deleteThreadData = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.deleteThreadData(data));
  }
};

export const setCommunicationNotificationsCount = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationNotificationsCount(data));
  }
};

export const setCommunicationThreadID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationThreadID(data));
  }
};

export const setCommunicationUserID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationUserID(data));
  }
};

export const setCommunicationViewMode = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationViewMode(data));
  }
};

export const resetCommunicationList = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetCommunicationList());
  }
};