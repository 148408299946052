import Calendar from '../../../components/Calendars/Calendar';
import React from 'react';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { createUseStyles } from 'react-jss';
import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from 'src/hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  calendarPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: '0px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);
  const userAccess = userData.userAccess;

  return !userAccess.calendar ? (
    <Navigate to="/403" state={{ from: location }}/>
  ) : (
    <div className={classes.calendarPage}>
      {
        !userAccess.subscription ? (
          <>
            <Calendar/>
            <SubscriptionBanner service="calendar" isInCenter={true}/>
          </>
        ) : (
          <Calendar/>
        )
      }
    </div>
  );
};

export default PageTemplate;