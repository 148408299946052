import React from 'react';
import { createUseStyles } from 'react-jss';
import { getColor, isLightOrDark } from 'src/utils/useFunctions';

interface Props {
  color?: any;
  isLightOrDark?: any;
  size?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  colorWrapper: {
    display: 'block',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    backgroundColor: (props: Props) => props.color,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: (props: Props) => {
      if(props.isLightOrDark === "light") return theme.colors.black;
      else return theme.colors.white
    },
    borderRadius: '4px',
    margin: '12px 10px',
  },
}));

type ColorInfoType = {
  color: any;
  size?: any;
};

const ColorInfoItem: React.FunctionComponent<ColorInfoType> = ({ color, size = 20 }) => {

  const itemColor = getColor(color);
  const classes = useStyles({
    color: itemColor,
    isLightOrDark: isLightOrDark(itemColor),
    size: size,
  });
  
  return (
    <div className={classes.colorWrapper}/>
  );
}

export default ColorInfoItem;