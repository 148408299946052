import moment from 'src/utils/moment';
import React from 'react';
import { formatRelativeSeconds } from 'src/utils/useFunctions';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

type CountdownType = {
  date: any;
  text: any;
};

const Countdown: React.FunctionComponent<CountdownType> = ({ date, text }) => {

  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(moment(date).diff(moment(), 'second'));

  useEffect(() => {
    let timer = setInterval(() => {
      setSeconds((seconds: any) => {
        if (seconds === 0) {
          clearInterval(timer);
          return 0;
        } else return seconds - 1;
      });
    }, 1000);
  }, [], []);
  
  return t(text, {in: formatRelativeSeconds(seconds, t).toLowerCase()});
}

export default Countdown;