import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormLabel } from '@mui/material';
import { isCypress } from '../../../utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: theme.colors.black,
    fontSize: '14px',
    marginTop: '16px',
    marginBottom: '7px',
    fontWeight: 'bold',
    width: '100%',
  },
}));

type InputType = {
  children: any;
  dataCy?: any;
  className?: any;
};

const Label: React.FunctionComponent<InputType> = ({children, className, dataCy}) => {

  const classes = useStyles();
  
  return (
    <FormLabel className={`${classes.labelWrapper} ${className ? className : null}`} data-cy={isCypress() ? dataCy : null} data-clarity-unmask="true">
      {children}
    </FormLabel>
  );
};

export default Label;