const useTips = () => {
  
  const tips = {
    parent:  [
      {
        icon: 'cog',
        title: 'tips_settings_title',
        text: 'tips_settings_text',
        action: {
          type: 'navigate',
          target: '/settings',
          title: 'tips_settings_action'
        },
      },
      {
        icon: 'addons',
        title: 'tips_addons_title',
        text: 'tips_addons_text',
        action: {
          type: 'navigate',
          target: '/settings/addons',
          title: 'tips_addons_action'
        },
      },
      {
        icon: 'customizations',
        title: 'tips_customizations_title',
        text: 'tips_customizations_text',
        action: {
          type: 'navigate',
          target: '/settings/customizations',
          title: 'tips_customizations_action'
        },
      },
      {
        icon: 'bell-outlined',
        title: 'tips_notifications_title',
        text: 'tips_notifications_text',
        action: {
          type: 'navigate',
          target: '/settings/notifications',
          title: 'tips_notifications_action'
        },
      },
      {
        icon: 'gallery',
        title: 'tips_gallery_title',
        text: 'tips_gallery_text',
        action: {
          type: 'navigate',
          target: '/gallery',
          title: 'tips_gallery_action'
        },
      },
    ],
    teacher:  [
      {
        icon: 'cog',
        title: 'tips_settings_title',
        text: 'tips_settings_text',
        action: {
          type: 'navigate',
          target: '/settings',
          title: 'tips_settings_action'
        },
      },
      {
        icon: 'addons',
        title: 'tips_addons_title',
        text: 'tips_addons_text',
        action: {
          type: 'navigate',
          target: '/settings/addons',
          title: 'tips_addons_action'
        },
      },
      {
        icon: 'customizations',
        title: 'tips_customizations_title',
        text: 'tips_customizations_text',
        action: {
          type: 'navigate',
          target: '/settings/customizations',
          title: 'tips_customizations_action'
        },
      },
      {
        icon: 'draft-outlined',
        title: 'tips_drafts_title',
        text: 'tips_drafts_text',
      },
      {
        icon: 'bell-outlined',
        title: 'tips_notifications_title',
        text: 'tips_notifications_text',
        action: {
          type: 'navigate',
          target: '/settings/notifications',
          title: 'tips_notifications_action'
        },
      },
    ],
    director:  [
      {
        icon: 'cog',
        title: 'tips_settings_title',
        text: 'tips_settings_text',
        action: {
          type: 'navigate',
          target: '/settings',
          title: 'tips_settings_action'
        },
      },
      {
        icon: 'addons',
        title: 'tips_addons_title',
        text: 'tips_addons_text',
        action: {
          type: 'navigate',
          target: '/settings/addons',
          title: 'tips_addons_action'
        },
      },
      {
        icon: 'customizations',
        title: 'tips_customizations_title',
        text: 'tips_customizations_text',
        action: {
          type: 'navigate',
          target: '/settings/customizations',
          title: 'tips_customizations_action'
        },
      },
      {
        icon: 'draft-outlined',
        title: 'tips_drafts_title',
        text: 'tips_drafts_text',
      },
      {
        icon: 'bell-outlined',
        title: 'tips_notifications_title',
        text: 'tips_notifications_text',
        action: {
          type: 'navigate',
          target: '/settings/notifications',
          title: 'tips_notifications_action'
        },
      },
      {
        icon: 'facebook-outlined',
        title: 'tips_facebook_directors_group_title',
        text: 'tips_facebook_directors_group_text',
        action: {
          type: 'windowopen',
          target: 'https://www.facebook.com/groups/924582172706845',
          title: 'tips_facebook_directors_group_action'
        },
      },
    ],
  };
  return tips;
};

export default useTips;