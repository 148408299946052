import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';

interface Props {
  absenceApprovalStatusID?: any;
  size?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '0',
    '&:after': {
      content: `''`,
      position: 'absolute',
      top: '2px',
      left: '2px',
      right: '2px',
      bottom: '2px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: (props: Props) => {
        if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[500];
        else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[500];
        else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[500];
        else return 'transparent';
      },
      borderRadius: '100%',
      zIndex: '1',
    },
  },
  absenceAbsentPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + (props.size / 4) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: 'inherit',
    }, 
  },
  absenceAbsentPartPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (props.size / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (props.size / 2) + 'px',
      width: (props: Props) => props.size + 'px',
      height: (props: Props) => props.size + 'px',
      color: 'inherit',
    }, 
  },
  absenceImageMorning: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%)',
    zIndex: '2',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
  },
  absenceImageAfternoon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translateY(-50%)',
    zIndex: '2',
    width: (props: Props) => ((props.size / 2) - 1) + 'px',
    height: (props: Props) => ((props.size / 2) - 1) + 'px',
  },
  absenceLateMorning: {
    position: 'absolute',
    left: '0px',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
  },
  absenceLateDay: {
    position: 'absolute',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
  },
  absenceLateAfternoon: {
    position: 'absolute',
    right: '0px',
    zIndex: '3',
    bottom: (props: Props) => '-' + (props.size / 4) + 'px',
    '& > svg': {
      backgroundColor: theme.colors.systemRed[500],
      borderRadius: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      width: (props: Props) => ((props.size / 2) - 4) + 'px',
      height: (props: Props) => ((props.size / 2) - 4) + 'px',
      color: theme.colors.white,
      padding: '2px',
    },
  },
}));

type AbsenceInfoType = {
  morningAbsenceStatusID?: any;
  afternoonAbsenceStatusID?: any;
  absenceApprovalStatusID?: any;
  isLate?: any;
  size?: any;
};

const AbsenceInfoItem: React.FunctionComponent<AbsenceInfoType> = ({ morningAbsenceStatusID, afternoonAbsenceStatusID, isLate = false, absenceApprovalStatusID, size = 32 }) => {
  const classes = useStyles({
    absenceApprovalStatusID: absenceApprovalStatusID,
    size: size,
  });
  const configurationData = useAppSelector((state: any) => state.configuration);
  const absenceTypes = configurationData.configuration.absenceTypes;
  const morningType = morningAbsenceStatusID ? (absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === morningAbsenceStatusID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === morningAbsenceStatusID)) : null;
  const afternoonType = afternoonAbsenceStatusID ? (absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === afternoonAbsenceStatusID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === afternoonAbsenceStatusID)) : null;

  return (
    <div className={classes.root}>
      {
        (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null) ? (
          <div className={classes.absenceAbsentPartAM} style={{color: morningType.color}}>
            <CircleIcon/>
            {
              morningType.image ? (
                <img className={classes.absenceImageMorning} src={morningType.image} alt={morningType.name} />
              ) : null
            }
          </div>
        ) : null
      }
      {
        (afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
          <div className={classes.absenceAbsentPartPM} style={{color: afternoonType.color}}>
            <CircleIcon/>
            {
              afternoonType.image ? (
                <img className={classes.absenceImageAfternoon} src={afternoonType.image} alt={afternoonType.name} />
              ) : null
            }
          </div>
        ) : null
      }
      {
        isLate ? (
          <>
            {
              (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null && afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
                <span className={classes.absenceLateDay}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : (morningAbsenceStatusID !== null && morningAbsenceStatusID !== undefined && morningType !== null) ? (
                <span className={classes.absenceLateMorning}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : (afternoonAbsenceStatusID !== null && afternoonAbsenceStatusID !== undefined && afternoonType !== null) ? (
                <span className={classes.absenceLateAfternoon}>
                  <SVG src="exclamation-mark"/>
                </span>  
              ) : null
            }
          </>
        ) : null
      }    
    </div>
  );
}

export default AbsenceInfoItem;