import DateFormat from '../../../utils/dateFormat';
import moment from '../../../utils/moment';
import RadioInput from 'src/components/Forms/RadioInput';
import React from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { getUserRole } from 'src/utils/useUser';
import { isKey } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '800px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  type: {
    fontWeight: '500',
    width: '25%',
    maxWidth: '25%',
    textAlign: 'left',
    '& > span': {
      maxWidth: '100%',
      whiteSpace: 'pre-wrap',
    },
  },  
  meal: {
    paddingLeft: '8px',
    '& > span': {
      maxWidth: '100%',
      whiteSpace: 'pre-wrap',
    },
    width: 'calc(75% - 56px)',
  },
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: '56px',
  },
}));

type MealsTableType = {
  day: any;
  meals: any;
  childID: any;
  schoolID: any;
  customRef: any;
};

const MealsTable: React.FunctionComponent<MealsTableType> = ({day = moment(), schoolID = null, childID = null, meals = [], customRef = null}) => {

  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const mealsService = useAppSelector((state: any) => state.services).mealsService;
  const classes = useStyles();

  const [dayMeals, setDayMeals] = useState(meals);

  const updateMealTable = (mealMenuID: any, type: any, newStatus: any) => {
    const newMeals = dayMeals.map((item: any) => {
      if(item.mealMenuID === mealMenuID) {
        return {...item, ordered: newStatus};
      } else {
        if(item.key.replace(/[0-9]/g, '') === type) {
          return {...item, ordered: false};
        } else {
          return item;
        }
      }
    });
    setDayMeals(newMeals);
  };
  
  const handleChange = (key: any, mealMenuID: any) => {
    const ordered = dayMeals.filter((meal: any) => meal.mealMenuID === mealMenuID).length === 0 ? false : dayMeals.find((meal: any) => meal.mealMenuID === mealMenuID).ordered;
    const newStatus = ordered ? false : true;
    const type = key.replace(/[0-9]/g, '');
    const oldMeals = dayMeals;
    if(newStatus) {
      const payload = {
        date: day,
        mealMenuID: mealMenuID,
        schoolID: schoolID,
        childID: getUserRole(userData.userObject.roleType) === "parent" ? childID : null,
      };
      mealsService && mealsService.createMealOrder(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("meals_ordered"), "success");
          } else {
            createNotification(t("meals_failed_create_order"), "error");
            setDayMeals(oldMeals);
          }
        } else {
          createNotification(t("meals_failed_create_order"), "error");
          setDayMeals(oldMeals);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("meals_failed_create_order"), "error");
        setDayMeals(oldMeals);
      });
    } else {
      mealsService && mealsService.deleteMealOrder(day, getUserRole(userData.userObject.roleType) === "parent" ? childID : schoolID, mealMenuID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("meals_deleted_order"), "success");
          } else {
            createNotification(t("meals_failed_delete_order"), "error");
            setDayMeals(oldMeals);
          }
        } else {
          createNotification(t("meals_failed_delete_order"), "error");
          setDayMeals(oldMeals);
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("meals_failed_delete_order"), "error");
        setDayMeals(oldMeals);
      });
    }
    updateMealTable(mealMenuID, type, newStatus);
  };

  return (
    <div className={classes.tableWrapper} ref={customRef}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th colSpan={3}>
              {DateFormat(day, "daynamemonth", languageData, t)}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            dayMeals.map((meal: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td className={classes.type}>
                  <span data-clarity-unmask="true">{meal.key}</span>
                </td>
                <td className={classes.meal}>
                  <span data-clarity-unmask="true">{meal.name}</span>
                </td>
                <td className={classes.action}>
                  <RadioInput name={day} checked={Boolean(meal.ordered)} checkedIcon={Boolean(meal.canOrder) ? <RadioButtonCheckedIcon/> : <CheckCircleIcon/>} disabled={!Boolean(meal.canOrder)} onClick={() => handleChange(meal.key, meal.mealMenuID)}/>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default MealsTable;