import DayPicker from 'src/components/DatePickers/Days';
import moment from 'src/utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import Select from 'src/components/Forms/Select';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import WeekPicker from 'src/components/DatePickers/Weeks';
import YearPicker from 'src/components/DatePickers/Years';
import { calendarViewModes } from 'src/utils/useCalendar';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { setCalendarDate, setCalendarViewMode } from 'src/store/actions/calendar.actions';
import { setCalendarEventHandleModal, setCalendarFiltersModal, setCalendarSettingsModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  button: {
    minWidth: 'unset',
  },
}));

const Calendar: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const userAccess = userData.userAccess;
  
  const curDate = calendarData.date;
  const viewMode = calendarData.viewMode;
  const isLoading = calendarData.isLoading;

  const filterSchoolID = calendarData.filterSchoolID;
  const filterClassID = calendarData.filterClassID;
  const filterChildID = calendarData.filterChildID;
  const filterUserID = calendarData.filterUserID;
  const filterType = calendarData.filterType;

  const [state, setState] = useStates({
    viewMode: viewMode,
  });

  const isToday = useMemo(() => {
    return moment(curDate).isSame(moment(), viewMode.value);
  }, [curDate, viewMode.value]);

  const getTotalCount = useMemo(() => {
    let count = 0;
    if(filterSchoolID.length > 0) {
      count++;
    }
    if(filterClassID.length > 0) {
      count++;
    }
    if(filterChildID.length > 0) {
      count++;
    }
    if(filterUserID.length > 0) {
      count++;
    }
    if(filterType.length > 0) {
      count++;
    }
    return count;
  }, [filterChildID.length, filterClassID.length, filterSchoolID.length, filterType.length, filterUserID.length]);

  const handleSetViewMode = (value: any) => {
    setState("viewMode", value);
    dispatch(setCalendarViewMode(value));
  };

  const handleSetDate = (value: any) => {
    dispatch(setCalendarDate(moment(value)));
  };

  const handleSetToday = () => {
    dispatch(setCalendarDate(moment()));
  };

  const handleFilters = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCalendarFiltersModal(settings));
  };

  const handleSettings = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCalendarSettingsModal(settings));
  };

  const handleAddEvent = () => {
    const presetDates: any = {
      day: moment(curDate),
      //week: moment(curDate).startOf('isoWeek'),
      //month: moment(curDate).startOf('month'),
      //year: moment(curDate).startOf('year'),
    };
    const settings = {
      isOpen: true,
      date: presetDates[viewMode.value] ? presetDates[viewMode.value] : null,
      dateTime: null,
      eventID: null,
    };
    dispatch(setCalendarEventHandleModal(settings));
  };

  useEffect(() => {
    if(state.viewMode !== viewMode) {
      setState("viewMode", viewMode);
    }
  }, [setState, state.viewMode, viewMode], [viewMode]);

  return (
    <>
      {
        !smallDevice ? (
          <NormalButton className={classes.button} buttonType="secondary" onClick={handleSetToday} disabled={!userAccess.subscription || isToday || isLoading}>
            {t('today')}
          </NormalButton>
        ) : null
      }
      {
        smallDevice ? (
          <TopBarButton className={modalsData.calendarSettingsModal.isOpen ? 'active' : null} icon={<SVG src="cog"/>} onClick={handleSettings} dataCy="topBarButtonSettings" disabled={!userAccess.subscription} tooltip={t('calendar_settings')}/>
        ) : (
          <>
            {
              viewMode.value === "day" ? (
                <DayPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
              ) : null
            }
            {
              viewMode.value === "week" ? (
                <WeekPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
              ) : null
            }
            {
              viewMode.value === "month" ? (
                <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
              ) : null
            }
            {
              viewMode.value === "year" ? (
                <YearPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
              ) : null
            }
            <Select items={calendarViewModes} selected={state.viewMode} setSelected={handleSetViewMode} allowClear={false} useTranslate={true} disabled={!userAccess.subscription || isLoading}/>
          </>
        )
      }
      <TopBarButton className={modalsData.calendarFiltersModal.isOpen ? 'active' : null} icon={<SVG src="filter"/>} onClick={handleFilters} dataCy="topBarButtonFilters" disabled={!userAccess.subscription} tooltip={t('filters')}>
        {
          getTotalCount === 0 ? null : (
            <span>{getTotalCount}</span>
          )
        }
      </TopBarButton>
      {
        (getUserRole(userData.userObject.roleType) === "director" || getUserRole(userData.userObject.roleType) === "teacher") ? (
          <TopBarButton className={modalsData.calendarEventHandleModal.isOpen ? 'active' : null} icon={<SVG src="plus"/>} onClick={handleAddEvent} dataCy="topBarButtonAdd" disabled={!userAccess.subscription} tooltip={t('calendar_add_event')}/>
        ) : null
      }
    </>
  );
}

export default Calendar;