import AuthenticatedImage from '../../Items/AuthenticatedImage';
import DateFormat from '../../../utils/dateFormat';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { a2ab, createFileName } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveAs } from 'file-saver';
import { setAttachmentModal, setImageModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';

interface Props {
  paymentStatusID: any;
};

const useStyles = createUseStyles((theme: any) => ({
  financeCard: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  financeHeader: {
    padding: '0.5rem 0rem 0.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailInformation: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  childInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  childInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60px',
  },
  childImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      minHeight: '40px',
      width: '40px',
      height: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },
  childImage: {
    width: '48px',
    height: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
    minWidth: '48px',
    minHeight: '48px',
    borderRadius: '100%',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
      height: '38px',
      maxWidth: '38px',
      maxHeight: '38px',
      minWidth: '38px',
      minHeight: '38px',
    },
  },
  childName: {
    color: theme.colors.grey[800],
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  paymentStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px 0 0 10px',
    position: 'absolute',
    right: '0',
    backgroundColor: (props: Props) => {
      if(props.paymentStatusID === 1) return theme.colors.systemGreen[100];
      else if(props.paymentStatusID === 2) return theme.colors.systemRed[100];
      else if(props.paymentStatusID === 4) return theme.colors.systemOrange[100];
      else return theme.colors.chip;
    },
    color: (props: Props) => {
      if(props.paymentStatusID === 1) return theme.colors.systemGreen[500];
      else if(props.paymentStatusID === 2) return theme.colors.systemRed[500];
      if(props.paymentStatusID === 4) return theme.colors.systemOrange[500];
      else return theme.colors.black;
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  financeInformation: {
    padding: '0px 30px 20px 30px',
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
    color: theme.colors.grey[800],
    fontSize: '18px',
    maxWidth: '100%',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px 20px 30px',
  },
  item: {
    display: 'flex',
    fontSize: '14px',
    '& > span': {
      fontWeight: 'bold',
      '&::after': {
        content: `':'`,
      }, 
    },
    '& > p': {
      marginLeft: '4px',
    },
  },
  attachments: {
    display: 'flex',
    gap: '8px',
    padding: '0px 30px 20px 30px',
  },
  attachment: {
    display: 'flex',
  },
  normalButton: {
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type ItemsType = {
  payment: any;
};

const FinanceCard: React.FunctionComponent<ItemsType> = ({payment}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const childData = useMemo(() => dataData.children.filter((child: any) => child.childID === payment.childID).length === 1 ? dataData.children.find((child: any) => child.childID === payment.childID) : [], [dataData.children, payment.childID]);  
  const paymentMethods =  useMemo(() => configurationData.configuration.paymentMethods, [configurationData.configuration.paymentMethods]);
  const paymentStatuses = useMemo(() => configurationData.configuration.paymentStatuses, [configurationData.configuration.paymentStatuses]);
  const paymentTypes = useMemo(() => configurationData.configuration.paymentTypes, [configurationData.configuration.paymentTypes]);
  const exportService = useAppSelector((state: any) => state.services).exportService;
  
  const classes = useStyles({
    paymentStatusID: payment.paymentStatusID,
  });

  const handleQrCode = useCallback((qrCodeLink: any) => {
    const settings = {
      isOpen: true,
      thumbLink: qrCodeLink,
      fullsizeLink: qrCodeLink,
    };
    dispatch(setImageModal(settings));
  }, [dispatch]);

  const handleAttachment = useCallback((title: any, name: any, type: any, paymentID: any, schoolID: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportPDF = () => {
      exportService && exportService.exportFinance(type, paymentID, schoolID).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
          type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const settings = {
      isOpen: true,
      title: `${title} <span>${name}</span>`,
      content: null,
      download: null,
    };
    dispatch(setAttachmentModal(settings));
    exportService && exportService.exportFinance(type, paymentID, schoolID).then((result: any) => {
      const buf = a2ab(result.data);
      const buftype = 'application/pdf';
      const blob = new Blob([buf], {
        type: buftype,
      });
      const content = URL.createObjectURL(blob);
      const settings = {
        content: content,
        download: handleExportPDF,
        type: format,
      };
      dispatch(setAttachmentModal(settings));
    }).catch(() => {
      createNotification(t('something_went_wrong'), 'error');
      const settings = {
        isOpen: false,
        title: null,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
    });
  }, [dispatch, exportService, t]);

  const getPaymentIcon = useMemo(() => {
    if(payment.paymentStatusID === 1) return "checkmark-circle";
    else if(payment.paymentStatusID === 2) return "exclamation-mark-circle-outlined";
    else if(payment.paymentStatusID === 4) return "clock-watch";
    else return "exclamation-mark-circle-outlined";
  }, [payment.paymentStatusID]);
  
  return (
    <div className={classes.financeCard}>
      <div className={classes.financeHeader}>
        <div className={classes.detailInformation}>
          <div className={classes.childInformation}>
            <div className={classes.childImageContainer}>
              <AuthenticatedImage className={classes.childImage} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink} isClickable/>
            </div>
            <div className={classes.childInfo}>
              <span className={classes.childName}>
                {childData.displayName}
              </span>
              <span className={classes.date}>
                <SVG src="calendar"/>
                {DateFormat(payment.dateIssued,"default", languageData, t)}
              </span>
            </div>
          </div>
          <div className={classes.paymentStatus}>
            <SVG src={getPaymentIcon}/>
            <span>{paymentStatuses.filter((paymentStatus: any) => paymentStatus.paymentStatusID === payment.paymentStatusID).length === 1 ? paymentStatuses.find((paymentStatus: any) => paymentStatus.paymentStatusID === payment.paymentStatusID).name : ""}</span>
          </div>
        </div>
      </div>
      {
        payment.note ? (
          <div className={classes.financeInformation}>
            <span className={classes.title}>
              {payment.note}
            </span>
          </div>
        ) : null
      }
      <div className={classes.list}>
        <div className={classes.item}>
          <span>{t('payment_id')}</span>
          <p>{payment.paymentID}</p>
        </div>
        <div className={classes.item}>
          <span>{t('payment_date_added')}</span>
          <p>{payment.dateIssued ? DateFormat(payment.dateIssued, "default", languageData, t) : ''}</p>
        </div>
        <div className={classes.item}>
          <span>{t('payment_price')}</span>
          <p>{payment.amountFormatted}</p>
        </div>
        <div className={classes.item}>
          <span>{t('payment_type')}</span>
          <p>{paymentTypes.filter((type: any) => type.paymentTypeID === payment.paymentTypeID).length === 1 ? paymentTypes.find((type: any) => type.paymentTypeID === payment.paymentTypeID).name : ""}</p>
        </div>
        <div className={classes.item}>
          <span>{t('payment_method')}</span>
          <p>{paymentMethods.filter((method: any) => method.paymentMethodID === payment.paymentMethodID).length === 1 ? paymentMethods.find((method: any) => method.paymentMethodID === payment.paymentMethodID).name : ""}</p>
        </div>
        {
          payment.vs ? (
            <div className={classes.item}>
              <span>{t('payment_vs')}</span>
              <p>{payment.vs}</p>
            </div>
          ) : null
        }
        {
          payment.bankAccount ? (
            <div className={classes.item}>
              <span>{t('payment_bank_account')}</span>
              <p>{payment.bankAccount}</p>
            </div>
          ) : null
        }
        {
          payment.datePaid ? (
            <div className={classes.item}>
              <span>{t('payment_date_paid')}</span>
              <p>{DateFormat(payment.datePaid, "default", languageData, t)}</p>
            </div>
          ) : null
        }
      </div>
      {
        payment.attachments.length > 0 ? (
          <div className={classes.attachments}>
            {
              payment.attachments.map((attachment: any, key: any) => (
                <div className={classes.attachment} key={`k_${key}`}>
                {
                  attachment.type === "qr" ? (
                    <NormalButton buttonType="primary" className={classes.normalButton} startIcon={<SVG src="qr-code"/>} onClick={() => handleQrCode(attachment.link)}>
                      {t('payment_qr')}
                    </NormalButton>
                  ) : attachment.type === "invoice" ? (
                    <NormalButton buttonType="secondary" className={classes.normalButton} startIcon={<SVG src={`${"document-"}${attachment.extension}${""}`} children={<SVG src="file"/>}/>} onClick={() => handleAttachment(t('payment_invoice'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                      {t('payment_invoice')}
                    </NormalButton>
                  ) : attachment.type === "receipt" ? (
                    <NormalButton buttonType="secondary" className={classes.normalButton} startIcon={<SVG src={`${"document-"}${attachment.extension}${""}`} children={<SVG src="file"/>}/>} onClick={() => handleAttachment(t('payment_receipt'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                      {t('payment_receipt')}
                    </NormalButton>
                  ) : null
                }
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  );
}

export default FinanceCard;