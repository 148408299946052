import htmlParse from 'html-react-parser';
import PremiumBanner from '../../../components/Layouts/PremiumBanner';
import React from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  photoArchivePage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > div.info-block': {
      boxShadow: 'rgba(0, 0, 0, 0.08) 0px 3px 20px',
      borderRadius: '20px',
      marginBottom: '20px',
      backgroundColor: theme.colors.white,
      padding: '20px',
      minWidth: '300px',
      width: '40vw',
      maxWidth: '100%',
      height: 'fit-content',
      flex: '0 0 fit-content',
      textAlign: 'center',
      '& > strong': {
        color: theme.colors.primaryBlue[500],
        fontSize: '16px',
        fontWeight: 'bold',  
      },
      '& a': {
        color: theme.colors.primaryBlue[500],
      },
      '& > p': {
        fontSize: '14px', 
        marginBottom: '0',
        color: '#7F838B', 
      },
      '& > strong + p': {
        marginTop: '0.25rem', 
      },
      '& > p + p': {
        marginTop: '0.25rem', 
      }
    },
    '& > div.child-block': {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
      width: '40vw',
      maxWidth: '100%',
      textAlign: 'center',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: '1 1 100%',
        alignItems: 'center', 
        justifyContent: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 3px 20px',
        borderRadius: '20px',
        marginBottom: '20px',
        backgroundColor: theme.colors.white,
        padding: '20px',
        '& > strong': {
          color: theme.colors.primaryBlue[500],
          fontSize: '16px',
          fontWeight: 'bold',  
        },
        '& > ul': {
          listStyle: 'none',
          paddingLeft: '0px',
          marginBottom: '0px'
        },
        '& > ul > li > a': {
          fontSize: '14px',
          color: '#7F838B',
        },
      },
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
      }
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  
  return (
    <div className={classes.photoArchivePage}>
    {
      !userData.membership.active ? (
        <PremiumBanner service="photoArchive"/>
      ) : (
        <div className={classes.wrapper}>
          <div className={classes.content}>
            {                         
              userData.membership.active && htmlParse(userData.membership.content)          
            }
          </div>
          <Sidebar/>
        </div> 
      )
    }
    </div>
  );
};

export default PageTemplate;