import BreakBreak from './Break';
import BreakEmployee from './Employee';
import React from 'react';

type ContainerType = {
  mode: any;
  onClose: any;
};

const Break: React.FunctionComponent<ContainerType> = ({ mode, onClose }) => {

  return (
    <>
      {
        mode === "break" ? (
          <BreakBreak onClose={onClose}/>
        ) : mode === "employee" ? (
          <BreakEmployee onClose={onClose}/>
        ) : null
      }
    </>
  );
};

export default Break;