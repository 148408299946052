import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  attachmentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pdfViewer: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
  },
  unsupported: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.colors.white,
    '& > svg': {
      width: '96px',
      height: '96px',
      marginBottom: '5px',
    },
    '& > p': {
      maxWidth: '80%',
      marginBottom: '0',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
}));

type RenderAttachmentType = {
  mediaUrl: any;
  mediaType: any;
};

const RenderAttachment: React.FunctionComponent<RenderAttachmentType> = ({ mediaUrl, mediaType }) => {

  const { t } = useTranslation();
  const classes = useStyles();  

  return (
    <div className={classes.attachmentWrapper}>
      {
        mediaType === "pdf" ? (
          <embed className={classes.pdfViewer} src={`${mediaUrl}#view=fitW&toolbar=0`}/>
        ) : (
          <div className={classes.unsupported}>
            <SVG src={`${"document-"}${mediaType}${""}`} children={<SVG src="file"/>}/>
            <p>{t("cannot_preview")}</p>
          </div>
        )
      }
    </div>  
  );
};

export default RenderAttachment;