import IconButton from '../IconButton';
import NormalButton from '../NormalButton';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useState } from 'src/utils/useState';
import { Popover } from '@mui/material';

interface Props {
  position?: any;
  popoverArrow?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  dropdownWithMenu: {
    display: 'flex',
  },
  button: {
    backgroundColor: '#EEEEEE',
    color: theme.colors.black,
    padding: "5px 5px",
    borderRadius: "100%",
    textTransform: 'unset',
    width: '42px',
    height: '42px',
    minWidth: 'unset',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  dropdownMenu: {
    marginTop: (props: Props) => {
      if(props.popoverArrow && (props.position === 'bottom-left' || props.position === 'bottom-right' || props.position === 'bottom-center')) return '10px';
      else return '';
    },
    marginBottom: (props: Props) => {
      if(props.popoverArrow && (props.position === 'top-left' || props.position === 'top-right' || props.position === 'top-center')) return '10px';
      else return '';
    },
    overflow: 'visible',
  },
  popoverContent: {
    position: 'relative',
    backgroundColor: '#EEEEEE',
    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    '& > button': {
      width: '100%',
      borderRadius: '0px',
      zIndex: '2',
      '&:first-of-type:not(:last-of-type)': {
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      '&:last-of-type:not(:first-of-type)': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
      },
      '&:first-of-type:last-of-type': {
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
      },
    }
  },
  menuButton: {
    '& > span': {
      '& > svg': {
        height: '16px',
        width: '16px',
      },
    }
  },
  arrow: {
    backgroundColor: 'inherit',
    content: `''`,
    display: 'block',
    position: 'absolute',
    width: '12px',
    height: '12px',
    top: (props: Props) => {
      if(props.position === 'bottom-left' || props.position === 'bottom-right' || props.position === 'bottom-center') return '-6px';
      else return '';
    },
    bottom: (props: Props) => {
      if(props.position === 'top-left' || props.position === 'top-right' || props.position === 'top-center') return '-6px';
      else return '';
    },
    transform: 'rotate(45deg)',
    left: 'calc(50% - 6px)',
    zIndex: '1',
  }
}));

type DropdownWithMenuType = {
  className?: any;
  classNameMoreButton?: any;
  classNamePopover?: any;
  classNameMenuButton?: any;
  moreButtonSize?: 'small' | 'medium' | 'large',
  items: any;
  icon?: any;
  onClick?: any;
  children?: any;
  disabled?: boolean;
  position?: 'top-left' | 'top-center' | 'top-right' | 'center-left' | 'center-center' | 'center-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
  dataCy?: string;
  popoverArrow?: boolean;
  isOpen?: any;
  setIsOpen?: any;
};

const DropdownWithMenu: React.FunctionComponent<DropdownWithMenuType> = ({ className, classNameMoreButton, classNamePopover, classNameMenuButton, moreButtonSize, items, onClick, position="bottom-right", disabled = false, dataCy, popoverArrow, isOpen, setIsOpen }) => {

  const classes = useStyles({
    position: position,
    popoverArrow: popoverArrow,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    e.stopPropagation();
    if(!disabled) {
      setAnchorEl(e.currentTarget);
      if(onClick) {
        onClick(true);
      }
    }
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    if(!disabled) {
      setAnchorEl(null);
      if(setIsOpen) {
        setIsOpen(null);
      }
      if(onClick) {
        onClick(false);
      }
    }
  };

  const slotProps = {
    paper: {
      className: classes.dropdownMenu,
    },
  };

  const getPosition: any = () => {
    if(position === 'top-left') {
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        }
      };
    } else if(position === 'center-left') {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        }
      };
    } else if(position === 'bottom-left') {
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        }
      };
    } else if(position === 'top-right') {
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      };
    } else if(position === 'center-right') {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right',
        }
      };
    } else if(position === 'bottom-right') {
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        }
      };
    } else if(position === 'top-center') {
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      };
    } else if(position === 'center-center') {
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'center',
        }
      };
    } else if(position === 'bottom-center') {
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        }
      };
    }
  };

  return items.length > 0 ? (
    <div className={`${classes.dropdownWithMenu} ${className ? className : ''}`}>
      <IconButton className={`${classes.button} ${classNameMoreButton ? classNameMoreButton : ''}`} onClick={handleClick} tabIndex={-1} dataCy={`${dataCy}Expand`} size={moreButtonSize} disabled={disabled}>
        <SVG src="more"/> 
      </IconButton>
      <Popover slotProps={slotProps} open={disabled ? false : (isOpen ? true : Boolean(anchorEl))} anchorEl={isOpen ? isOpen : anchorEl} onClose={handleClose} anchorOrigin={getPosition().anchorOrigin} transformOrigin={getPosition().transformOrigin}>
        <div className={`${classes.popoverContent} ${classNamePopover ? classNamePopover : ''}`}>
          {
            popoverArrow ? (
              <div className={classes.arrow}/>
            ) : null
          }
          {
            items.filter((item: any) => item !== null).map((item: any, key: any) => {
              const itemOnClick = (e: any) => {
                e.stopPropagation();
                e.preventDefault();
                if(item.onClick) {
                  item.onClick();
                }
                if(onClick) {
                  onClick(false);
                }
                setAnchorEl(null);
                if(setIsOpen) {
                  setIsOpen(null);
                }
              };
              return (
                <NormalButton className={`${classes.menuButton} ${classNameMenuButton ? classNameMenuButton : ''}`} type="button" buttonType="white" startIcon={item.icon} onClick={itemOnClick} key={`k_${key}`} tabIndex={-1} disabled={item.disabled} dataCy={item.dataCy}>
                  {item.title}  
                </NormalButton>
              );
            })
          }
        </div>
      </Popover>
    </div>
  ) : null;
};

export default DropdownWithMenu;