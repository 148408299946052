import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import Select from 'src/components/Forms/Select';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setShopItemVariantModal } from '../../../store/actions/modals.actions';
import { setStockCart } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  select: {
    width: '100%',
  },
}));

const ShopItemVariantModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockData = useAppSelector((state: any) => state.stock);
  const schoolCart = stockData.cart;

  const isValid = modalsData.shopItemVariantModal.itemID === null ? false : true;
  const itemID = isValid ? modalsData.shopItemVariantModal.itemID : null;
  const schoolID = isValid ? modalsData.shopItemVariantModal.schoolID : null;
  const itemData = isValid ? (stockData.items.filter((item: any) => item.itemID === itemID).length === 1 ? stockData.items.find((item: any) => item.itemID === itemID) : []) : [];
  
  const [selectedVariant, setSelectedVariant] = useState(null);

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      itemID: null,
      schoolID: null,
    };
    dispatch(setShopItemVariantModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleAddToCart = () => {
    let showNotify = true;
    const cartItems = schoolCart.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : stockData.cart.find((item: any) => item.schoolID === schoolID).items;
    const isExist = cartItems.filter((item: any) => item.itemID === itemData.itemID && item.variantID === selectedVariant.variantID).length === 0 ? false : true;
    let newSchoolCart: any = schoolCart;
    if(isExist) {
      newSchoolCart = newSchoolCart.map((item: any) => {
        if(item.schoolID === schoolID) {
          const newItems = item.items.map((subItem: any) => {
            if(subItem.itemID === itemData.itemID && subItem.variantID === selectedVariant.variantID) {
              const newQuantity = subItem.quantity + 1;
              if(newQuantity > config.MAX_ITEMS_IN_CART) {
                createNotification(t('stock_shop_item_exceeded', {max: config.MAX_ITEMS_IN_CART}), "info");
                showNotify = false;
              }
              return {...subItem, quantity: newQuantity > config.MAX_ITEMS_IN_CART ? config.MAX_ITEMS_IN_CART : newQuantity};
            } else {
              return subItem;
            }
          });
          return { schoolID: schoolID, items: newItems };
        } else {
          return item;
        }
      });
    } else {
      const newItemToCart = {
        itemID: itemData.itemID,
        variantID: selectedVariant.variantID,
        quantity: 1,
      };
      newSchoolCart = newSchoolCart.map((item: any) => {
        if(item.schoolID === schoolID) {
          const newItems = [...item.items, newItemToCart];
          const newItem = { schoolID: schoolID, items: newItems };
          return newItem;
        } else {
          return item;
        }
      });
    }
    const newCart = newSchoolCart;
    dispatch(setStockCart(newCart));
    if(showNotify) createNotification(t('stock_item_added_to_cart'), 'success');
    onCloseModal();
  };

  return isValid ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockItemDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_item')} {itemData.name}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Select className={classes.select} selected={selectedVariant} setSelected={setSelectedVariant} items={itemData.variant}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleAddToCart} disabled={selectedVariant === null} dataCy="addToCartButton">
            {t('stock_add_to_cart')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default ShopItemVariantModal;