import React from 'react';
import SubjectItem from '../SubjectItem';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import MoreSubjectsItem from '../MoreSubjectsItem';

const useStyles = createUseStyles((theme: any) => ({
  subjectBlock: {
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '92px',
    height: '92px',
    minWidth: '92px',
    minHeight: '92px',
    maxWidth: '92px',
    maxHeight: '92px',
    position: 'relative',
    margin: '0 auto',
    '& > div': {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    '& > .subjectWrapper': {
      display: 'flex',
      '& > div': {
        width: '100%',
        height: '100%',
        transition: 'all 0.25s',
      },
    },
    '& > .subjectWrapper2': {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        height: '50%',
        transition: 'all 0.25s',
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
    '& > .subjectWrapper3': {
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        height: '50%',
        transition: 'all 0.25s',
        '&:last-of-type': {
          width: '100%',
        },
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
    '& > .subjectWrapper4': {
      flexWrap: 'wrap',
      '& > div': {
        width: '50%',
        height: '50%',
        transition: 'all 0.25s',
      },
      '& button': {
        width: '32px',
        height: '32px',
      },
    },
  },
  add: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      '& > button': {
        opacity: 1,
      },
    },
  },
  button: {
    display: "flex",
    opacity: "0",
    alignSelf: "center",
    transition: 'opacity 0.25s',
    width: '48px',
    height: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '100%',
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '32px',
      height: '32px',
    },
  },
}));

type SubjectBlockType = {
  day: any;
  hour: any;
};

const SubjectBlock: React.FunctionComponent<SubjectBlockType> = ({day, hour}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const timetableData = useAppSelector((state: any) => state.timetable);

  const timetables = timetableData.data.timetables;
  const settings = timetableData.settings === null ? [] : timetableData.settings;
  const subjects = timetableData.subjects;

  const hoursData = timetables.filter((item: any) => item.hour === hour && item.weekday === day && item.subjectID !== 0 && item.type !== 3 && subjects.filter((subject: any) => subject.subjectID === item.subjectID).length !== 0);

  const handleCreateHour = (day: any, hour: any, type: any) => {
    const settings = {
      isOpen: true,
      weekday: day,
      hour: hour,
      type: type,
      mode: timetableData.type,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  return (
    <div className={classes.subjectBlock}>
      {
        hoursData.length === 0 ? (settings.canEdit && settings.canAdd && timetableData.type !== "break") ? (
          <div className={classes.add} onClick={() => handleCreateHour(day, hour, timetableData.type === "employee" ? 2 : 1)}>
            <IconButton className={classes.button}>
              <SVG src="plus"/>
            </IconButton>
          </div>
        ) : (
          <div/>
        ) : (
          <div className={(settings.canEdit && settings.canMultipleHours && hoursData.length < 4 && timetableData.type !== "break") ? `subjectWrapper${hoursData.length + 1}` : `subjectWrapper${hoursData.length === 1 ? '' : (hoursData.length > 1 && hoursData.length < 5 ? hoursData.length : 4)}`}>
            <>
            {
              !settings.canEdit ? (
                <>
                  {
                    (hoursData.length <= 4) ? (
                      <>
                      {
                        hoursData.map((hourData: any, key: any) => (
                          <SubjectItem size={(settings.canEdit && settings.canMultipleHours && hoursData.length < 4 && timetableData.type !== "break") ? (hoursData.length + 1) : hoursData.length} day={day} hour={hour} hourData={hourData} key={`k_${key}`}/>
                        ))
                      }
                      </>
                    ) : (
                      <>
                        {
                          hoursData.slice(0,3).map((hourData: any, key: any) => (
                            <SubjectItem size={(settings.canEdit && settings.canMultipleHours && hoursData.length < 4 && timetableData.type !== "break") ? (hoursData.length + 1) : hoursData.length} day={day} hour={hour} hourData={hourData} key={`k_${key}`}/>
                          ))
                        }
                        <MoreSubjectsItem hoursData={hoursData} day={day} hour={hour}/>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                {
                  (hoursData.length < 4) ? (
                    <>
                      {
                        hoursData.map((hourData: any, key: any) => (
                          <SubjectItem size={(settings.canEdit && settings.canMultipleHours && hoursData.length < 4 && timetableData.type !== "break") ? (hoursData.length + 1) : hoursData.length} day={day} hour={hour} hourData={hourData} key={`k_${key}`}/>
                        ))
                      }
                      {
                        (settings.canEdit && settings.canAdd && settings.canMultipleHours && timetableData.type !== "break") ? (
                          <div className={classes.add} onClick={() => handleCreateHour(day, hour, timetableData.type === "employee" ? 2 : 1)}>
                            <IconButton className={classes.button}>
                              <SVG src="plus"/>
                            </IconButton>
                          </div>
                        ) : null
                      }
                    </>
                  ) : (
                    <>
                      {
                        hoursData.slice(0,3).map((hourData: any, key: any) => (
                          <SubjectItem size={(settings.canEdit && settings.canMultipleHours && hoursData.length < 4 && timetableData.type !== "break") ? (hoursData.length + 1) : hoursData.length} day={day} hour={hour} hourData={hourData} key={`k_${key}`}/>
                        ))
                      }
                      <MoreSubjectsItem hoursData={hoursData} day={day} hour={hour}/>
                    </>
                  )
                }
                </>
              )
            }
            </>
          </div>
        )
      }
    </div>
  );
}

export default SubjectBlock;