import IconButton from 'src/components/Buttons/IconButton';
import ItemPicker from 'src/components/Forms/ItemPicker';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setTimetableData, setTimetableRoom, setTimetableType, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  roomSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(50% - 32px)',
    boxShadow: theme.shadows[2],
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 32px)',
    },
    '& > span': {
      fontSize: '18px',
      fontWeight: '500',
    },
  },
  itemPicker: {
    maxWidth: 'calc(100% - 64px)',
    padding: '32px',
  },
  backButton: {
    backgroundColor: theme.colors.grey[200],
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    width: '36px',
    height: '36px',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const RoomSelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const schoolID = timetableData.school === null ? null : timetableData.school.schoolID;
  
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const classes = useStyles();

  const list = timetableData.rooms.filter((item: any) => item.type === 1);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const [state, setState] = useStates({
    canClick: true,
  });

  const handleChange = (value: any) => {
    setState("canClick", false);
    dispatch(setTimetableRoom(value));
    dispatch(setTimetableType("room"));
    getHours(value);
  };

  const getHours = useCallback((value: any) => {
    const params = {
      roomID: value.roomID,
      schoolID: schoolID,
      type: [1, 2],
    };
    timetableService && timetableService.listHours(params).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableData(result.data));
          dispatch(setTimetableViewMode("timetableEdit"));
        } else {
          createNotification(t("timetable_failed_load"), "error");
          setState("canClick", true);
        }
      } else {
        createNotification(t("timetable_failed_load"), "error");
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("timetable_failed_load"), "error");
      setState("canClick", true);
    });
  }, [dispatch, t, timetableService, schoolID, setState]);

  const handleBack = () => {
    dispatch(setTimetableViewMode("timetableSelect"));
  };

  return (
    <div className={classes.roomSelectWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.box}>
        <IconButton className={classes.backButton} onClick={handleBack} data-cy={isCypress() ? "backButton" : null}>
          <SVG src="arrow-left"/>
        </IconButton> 
        <span>{t('timetable_select_room')}</span>
        {
          list.length > 0 ? (
            <ItemPicker className={classes.itemPicker} items={list} valueCompare="roomID" onChange={handleChange} disabled={!state.canClick}/>
          ) : null
        }
      </div>
    </div>
  );
};

export default RoomSelect;