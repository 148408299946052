import CircularProgress from '@mui/material/CircularProgress';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useScreenRecorder from 'use-screen-recorder';
import { blobToDataUrl, getSupportedMediaRecorderType } from 'src/utils/useFunctions';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isMobile } from 'react-device-detect';
import { setCameraModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isBase64: any;
  isRecording: any;
  isScreenRecording: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: theme.colors.black,
  },
  cameraInterface: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.black,
    position: 'relative',
  },
  cameraVideo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      color: theme.colors.white,
      fontSize: '24px',
      textAlign: 'center',
    }
  },
  cameraVideoPreview: {
    height: '100%',
    width: 'auto',
    pointerEvents: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    display: (props: Props) => {
      if(props.isBase64) return 'none';
      else return '';
    },
  },
  cameraImage: {
    height: 'auto',
    width: 'auto',
    pointerEvents: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    display: (props: Props) => {
      if(!props.isBase64) return 'none';
      else return '';
    },
  },
  cameraVideoResult: {
    height: '100%',
    width: 'auto',
    pointerEvents: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    display: (props: Props) => {
      if(!props.isBase64) return 'none';
      else return '';
    },
  },
  cameraScreen: {
    height: '100%',
    width: 'auto',
    pointerEvents: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
    display: (props: Props) => {
      if(!props.isBase64) return 'none';
      else return '';
    },
  },
  cameraBar: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    height: 'calc(100% - 32px)',
    width: '120px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 0',
    gap: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      top: 'unset',
      left: '0',
      right: '0',
      bottom: '0',
      width: 'calc(100% - 16px)',
      height: '90px',
      padding: '0 8px',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'unset',
      justifyContent: 'center',
      gap: '16px',
    },
  },
  loading: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  cameraButton: {
    width: '72px',
    height: '72px',
    color: theme.colors.white,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '& svg': {
      color: theme.colors.white,
      width: '70%',
      height: '70%',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(127, 127, 127, 0.25)',
      '& svg': {
        color: theme.colors.grey[570],
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '54px',
      height: '54px',
    },
  },
  cameraShotButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '96px',
    height: '96px',
    color: theme.colors.black,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    borderWidth: '8px',
    borderStyle: 'double',
    borderColor: theme.colors.black,
    '& svg': {
      color: theme.colors.black,
      width: '70%',
      height: '70%',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(127, 127, 127, 0.25)',
    },
    [theme.breakpoints.down('md')]: {
      width: '72px',
      height: '72px',
      top: 'unset',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  cameraRecordButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '96px',
    height: '96px',
    color: theme.colors.white,
    backgroundColor: 'rgba(255, 0, 0, 0.85)',
    borderRadius: (props: Props) => {
      if(props.isRecording) return '0%';
      else return '100%';
    },
    transition: 'border-radius 0.25s',
    '&::before': {
      content:  `''`,
      position: 'absolute',
      top: '8px',
      left: '8px',
      right: '8px',
      bottom: '8px',
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: (props: Props) => {
        if(props.isRecording) return '0%';
        else return '100%';
      },
      transition: 'border-radius 0.25s',
    },
    '& svg': {
      color: theme.colors.black,
      width: '70%',
      height: '70%',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.95)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(127, 127, 127, 0.25)',
    },
    [theme.breakpoints.down('md')]: {
      width: '72px',
      height: '72px',
      top: 'unset',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  cameraScreenButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '96px',
    height: '96px',
    color: theme.colors.white,
    backgroundColor: 'rgba(0, 133, 255, 0.85)',
    borderRadius: (props: Props) => {
      if(props.isScreenRecording) return '0%';
      else return '100%';
    },
    transition: 'border-radius 0.25s',
    '&::before': {
      content:  `''`,
      position: 'absolute',
      top: '8px',
      left: '8px',
      right: '8px',
      bottom: '8px',
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: (props: Props) => {
        if(props.isScreenRecording) return '0%';
        else return '100%';
      },
      transition: 'border-radius 0.25s',
    },
    '& svg': {
      color: theme.colors.black,
      width: '70%',
      height: '70%',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 133, 255, 0.95)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(127, 127, 127, 0.25)',
    },
    [theme.breakpoints.down('md')]: {
      width: '72px',
      height: '72px',
      top: 'unset',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));

const CameraModal: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user); 

  const mediaLimit = modalsData.cameraModal.mediaLimit;
  const mode = modalsData.cameraModal.mode;
  const onSave = modalsData.cameraModal.onSave;
  
  const {
    blob: screenBlob,
    blobUrl: screenBlobUrl,
    resetRecording: resetScreenRecording,
    startRecording: startScreenRecording,
    status: isScreenRecording,
    stopRecording: stopScreenRecording,
  } = useScreenRecorder({audio: true});

  const [state, setState] = useStates({
    isLoaded: false,
    isRecording: false,
    isPlaying: false,
    width: browserData.width,
    height: browserData.height,
    stream: null,
    permission: false,
    devices: [],
    currentDevice: null,
    currentDeviceIndex: null,
    base64: null,
    mediaRecord: null,
    maxLength: userData.mediaLimits[mediaLimit].videoTimeLimit,
  });

  const classes = useStyles({
    isBase64: state.base64 !== null,
    isRecording: state.isRecording,
    isScreenRecording: isScreenRecording === "recording",
  });

  const imageRef: any = useRef(null);
  const videoPreviewRef: any = useRef(null);
  const videoResultRef: any = useRef(null);

  const onCloseModal = useCallback(() => {
    if(state.stream) {
      state.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
    const settings = {
      isOpen: false,
      mode: null,
      mediaLimit: null,
      onSave: null,
    };
    dispatch(setCameraModal(settings));
  }, [dispatch, state.stream]);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleOnError = useCallback(() => {
    onCloseModal();
    createNotification(t('camera_no_access'), 'error');
  }, [onCloseModal, t]);

  const handleChangeCamera = () => {
    setState("isLoaded", false);
    if(state.stream) {
      state.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
    const currentIndex = state.currentDeviceIndex;
    const devices = state.devices;
    const newIndex = (currentIndex + 1) < devices.length ? (currentIndex + 1) : 0;
    setState("currentDevice", devices[newIndex].deviceId);
    setState("currentDeviceIndex", newIndex);
  };

  const getCamera = async (constraints: any) => {
    return await navigator.mediaDevices.getUserMedia(constraints);
  };

  const handleDevices = useCallback((mediaDevices: any, deviceId?: any) => {
    const devices = mediaDevices.filter(({ kind }: any) => kind === "videoinput").filter((item: any) => !item.label.includes("OBS"));
    setState("devices", devices);
    const devId = deviceId ? deviceId : state.currentDevice;
    const getIndex = devices.filter((item: any) => item.deviceId === devId).length === 0 ? 0 : devices.findIndex((item: any) => item.deviceId === devId);
    setState("currentDeviceIndex", getIndex);
  }, [setState, state.currentDevice]);

  const handleGetDevices = useCallback((stream: any) => {
    let device: any;
    stream.getVideoTracks().forEach((track: any)=> {
      device = track.getSettings();
      track.stop();
    });
    setState("currentDevice", device.deviceId);
    setTimeout(() => {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices: any) => handleDevices(mediaDevices, device.deviceId));
    }, 1000);
  }, [handleDevices, setState]);

  const handleGetShot = () => {
    if(state.base64) {
      setState("base64", null);
    } else {
      const video = videoPreviewRef.current;
      const image = imageRef.current;
      const tempCanvas = document.createElement("canvas");
      const tempContext: any = tempCanvas.getContext("2d");
      tempCanvas.width = video.width;
      tempCanvas.height = video.height;
      tempContext.drawImage(video, 0, 0, tempCanvas.width, tempCanvas.height);
      const data = tempCanvas.toDataURL("image/png");
      image.src = data;
      setBase64(data);
    }
  };

  const handleGetVideo = () => {
    if(state.base64) {
      setState("base64", null);
      let videoPreview = videoPreviewRef.current;
      let videoResult = videoResultRef.current;
      videoResult.src = "";
      videoPreview.srcObject = state.stream;
      videoPreview.play();
    } else {
      if(state.isRecording) {
        state.mediaRecorder.addEventListener('dataavailable', (event: any) => {
          blobToDataUrl(event.data, setBase64);
          let videoResult = videoResultRef.current;
          videoResult.src = URL.createObjectURL(event.data);
        })
        state.mediaRecorder.stop();
        setState("isRecording", false);
      } else {
        const mediaRecorder = new MediaRecorder(state.stream, {
          mimeType: getSupportedMediaRecorderType(),
        });
        setState("mediaRecorder", mediaRecorder);
        mediaRecorder.start();
        setState("isRecording", true);
      }
    }
  };

  const handleGetScreen = () => {
    if(state.base64) {
      resetScreenRecording();
      setState("base64", null);
      setState("isScreenRecordTooShort", false);
    } else {
      if(isScreenRecording === "recording") {
        stopScreenRecording();
      } else {
        startScreenRecording();
      }
    }
  };

  const handleVideoControl = () => {
    let videoResult = videoResultRef.current;
    if(state.isPlaying) {
      setState("isPlaying", false);
      videoResult.pause();
    } else {
      setState("isPlaying", true);
      videoResult.addEventListener("ended", () => {
        setState("isPlaying", false);
      });
      videoResult.play();
    }
  };

  const setBase64 = useCallback((data: any) => {
    setState("base64", data);
  }, [setState]);

  const handleSave = (e: any) => {
    e.stopPropagation();
    onSave(state.base64);
    onCloseModal();
  };

  useEffect(() => {
    if(state.devices.length === 0 && (mode === "photo" || mode === "video")) {
      const constraints = {
        audio: true,
        video: {
          facingMode: 'environment', 
          width: { min: 1, ideal: isMobile ? state.height : state.width, max: isMobile ? state.height : state.width },
          height: { min: 1, ideal: isMobile ? state.width : state.height, max: isMobile ? state.width : state.height },
        },
      };
      navigator.mediaDevices.getUserMedia(constraints).then(handleGetDevices).catch(() => {
        return handleOnError();
      });
    }
    if(mode === "screen") {
      setState("isLoaded", true);
    }
  }, [handleGetDevices, state.devices, state.width, state.height, handleOnError, mode, setState], []);

  useEffect(() => {
    if(state.currentDevice) {
      const getCameraStream = async () => {
        const constraints = { 
          audio: true,
          video: {
            deviceId: state.currentDevice,
            width: { min: 1, ideal: isMobile ? state.height : state.width, max: isMobile ? state.height : state.width },
            height: { min: 1, ideal: isMobile ? state.width : state.height, max: isMobile ? state.width : state.height },
          },
        };
        const stream = await getCamera(constraints).catch(() => {
          return handleOnError();
        });
        setState("stream", stream);
      };
      getCameraStream();
    }
  }, [state.currentDevice, state.width, state.height, setState, handleOnError], [state.currentDevice]);

  useEffect(() => {
    if(state.stream) {
      let videoPreview = videoPreviewRef.current;
      let videoResult = videoResultRef.current;
      videoPreview.srcObject = state.stream;
      videoPreview.addEventListener("canplay", () => {
        let width = videoPreview.videoWidth;
        let height = videoPreview.videoHeight;
        if(isNaN(height)) {
          height = width / (4 / 3);
        }
        videoPreview.setAttribute("width", width);
        videoPreview.setAttribute("height", height);
        if(mode === "video") {
          videoResult.setAttribute("width", width);
          videoResult.setAttribute("height", height);
        }
        setState("isLoaded", true);
      }, false);
      videoPreview.play();
    }
  }, [state.stream, setState, mode], [state.stream]);

  useEffect(() => {
    if(mode === "screen" && screenBlob && screenBlobUrl) {
      blobToDataUrl(screenBlob, setBase64);
      let videoResult = videoResultRef.current;
      videoResult.src = screenBlobUrl;
    }
  }, [mode, screenBlob, screenBlobUrl, setBase64], [screenBlob, screenBlobUrl]);

  useEffect(() => {
    if(browserData.width && browserData.height) {
      setState("width", browserData.width);
      setState("height", browserData.height);
    }
  }, [browserData, setState], [browserData.width, browserData.height]);

  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "cameraModal" : null}>
        <div className={classes.cameraInterface}>
          <div className={classes.cameraVideo}>
            {
              !state.isLoaded ? (
                <div className={classes.loading}>
                  <CircularProgress className={classes.spinner}/>
                </div>
              ) : null
            }
            {
              (mode === "photo" || mode === "video") ? (
                <video className={classes.cameraVideoPreview} ref={videoPreviewRef} muted={true} playsInline={true}/>
              ) : null
            }
            {
              mode === "screen" ? (
                <>
                  {
                    !state.base64 ? (
                      <span>{t('screen_record_will_be_here')}</span>
                    ) : null
                  }
                  <video className={classes.cameraScreen} ref={videoResultRef} playsInline={true}/>
                </>
              ) : null
            }
            {
              mode === "photo" ? (
                <img alt='camera' className={classes.cameraImage} ref={imageRef}/>
              ) : null
            }
            {
              mode === "video" ? (
                <video className={classes.cameraVideoResult} ref={videoResultRef} playsInline={true}/>
              ) : null
            }
          </div>
          <div className={classes.cameraBar}>
            <div className={classes.buttons}>
              <IconButton className={classes.cameraButton} onClick={handleClose} tooltip={t('close')} tooltipMaxWidth={400} dataCy="timesButton">
                <SVG src="close"/>
              </IconButton>
              {
                (state.devices.length > 1 && !state.base64 && !state.isRecording && (mode === "photo" || mode === "video")) ? (
                  <IconButton className={classes.cameraButton} onClick={handleChangeCamera} tooltip={t('camera_change')} tooltipMaxWidth={400} disabled={!state.isLoaded}>
                    <SVG src="camera-multiple"/>
                  </IconButton>
                ) : null
              }
            </div>
            {
              mode === "photo" ? (
                <IconButton className={classes.cameraShotButton} onClick={handleGetShot} tooltip={state.base64 ? t('camera_take_shot_again') : t('camera_take_shot')} tooltipMaxWidth={400} disabled={!state.isLoaded}>
                  <SVG src={state.base64 ? "camera-rotate-outlined" : "camera-outlined"}/>
                </IconButton>
              ) : null
            }
            {
              mode === "video" ? (
                <IconButton className={classes.cameraRecordButton} onClick={handleGetVideo} tooltip={state.isRecording === "recording" ? t('camera_stop_video') : state.base64 ? t('camera_take_video_again') : t('camera_take_video')} tooltipMaxWidth={400} disabled={!state.isLoaded}>
                  {
                    state.isRecording ? (
                      <CountdownCircleTimer
                        isPlaying
                        duration={state.maxLength}
                        strokeWidth={0}
                        colors="#FFFFFF"
                        size={0}
                        onComplete={handleGetVideo}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>
                    ) : null
                  }
                </IconButton>
              ) : null
            }
            {
              mode === "screen" ? (
                <IconButton className={classes.cameraScreenButton} onClick={handleGetScreen} tooltip={isScreenRecording === "recording" ? t('camera_stop_screen') : state.base64 ? t('camera_take_screen_again') : t('camera_take_screen')} tooltipMaxWidth={400} disabled={!state.isLoaded}>
                  {
                    isScreenRecording === "recording" ? (
                      <CountdownCircleTimer
                        isPlaying
                        duration={state.maxLength}
                        strokeWidth={0}
                        colors="#FFFFFF"
                        size={0}
                        onComplete={handleGetScreen}
                      >
                        {({ remainingTime }) => remainingTime}
                      </CountdownCircleTimer>
                    ) : null
                  }
                </IconButton>
              ) : null
            }
            <div className={classes.buttons}>
              {
                (state.base64 && (mode === "video" || mode === "screen")) ? (
                  <IconButton className={classes.cameraButton} onClick={handleVideoControl} tooltip={state.isPlaying ? t('video_pause') : t('video_play')} tooltipMaxWidth={400}>
                    <SVG src={state.isPlaying ? "pause" : "play"}/>
                  </IconButton>
                ) : null
              }
              {
                state.base64 ? (
                  <IconButton className={classes.cameraButton} onClick={handleSave} tooltip={t(`camera_use_${mode}`)} tooltipMaxWidth={400}>
                    <SVG src="checkmark"/>
                  </IconButton>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CameraModal;