import * as UserService from 'src/services/user.service';
import addons from 'src/constants/addons';
import config from '../constants/config';
import customizations from 'src/constants/customizations';
import filters from 'src/constants/filters';
import menuItems from 'src/constants/menu';
import setup from 'src/constants/setup';
import { isCypress } from './useCypress';
import { mergeDeepArrays, mergeMenuItems, validateItemRequirements } from './useFunctions';
import { setUserSettings } from 'src/store/actions/user.actions';

const getUserRole = (roleID: number) => {
  let roleName = "nobody";
  if(Object.keys(config.APP_USER_ROLES).filter((role) => parseInt(role) === roleID).length !== 0) {
    roleName = config.APP_USER_ROLES[roleID];
  }
  return roleName;
};

const getUserHomepage = (dispatch: any, dataData: any, layoutData: any, userData: any) => {

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const homepageSettings = getUserSetting(userData.userSettings, "customizations", ["homepage"]);

  const mainMenuItems = menuItems.main.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const mainMenuItemSettings = getUserSetting(userData.userSettings, "customizations", ["mainmenu"]);

  const mainMenuItemsUpdated = mainMenuItemSettings ? mergeMenuItems(mainMenuItems, mainMenuItemSettings) : mainMenuItems;

  const mainMenuItemsAvailable = mainMenuItemsUpdated.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null).filter((item: any) => item.isVisible && item.canBeHome);

  const mainMenuItemsSortable = mainMenuItemsAvailable.map((item: any) => {
    return {
      to: item.to,
    };
  });

  mainMenuItemsSortable.sort((a: any, b: any) => { return a.order - b.order; });

  const getHomepageItem = (item: any) => {
    const itemExist = mainMenuItemsSortable.filter((node: any) => node.to === item).length === 1;
    if(itemExist) {
      return mainMenuItemsSortable.find((node: any) => node.to === item)
    } else {
      const newHomepage = mainMenuItemsSortable[0];
      saveUserSettings(dispatch, userData, "customizations", ["homepage"], newHomepage.to);
      return newHomepage;
    }
  };

  const homepage = (homepageSettings && homepageSettings.length) > 0 ? getHomepageItem(homepageSettings) : mainMenuItemsSortable[0];
  return homepage.to;
};

const createUserObject = (userData: any) => {
  return {
    firstname: userData.firstname,
    surname: userData.surname,
    displayName: userData.displayName,
    userID: userData.userID,
    employeeID: userData.employeeID,
    roleType: userData.roleType,
    public: userData.public,
    photo: userData.photo,
    schoolID: userData.schoolID,
    classID: userData.classID,
  };
};

const createUserSettings = (userData: any, userSettingsFromApi: any) => {

  const addonsDefaultData = addons.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    const subItemsList = item.items.filter((subItem: any) => subItem.roles.includes(getUserRole(userData.userObject.roleType)));
    const values = subItemsList.map((subItem: any) => {
      return { key: subItem.key, value: subItem.value };
    });
    return { key: item.key, value: values};
  });

  const customizationsDefaultData = customizations.map((item: any) => {
    const value = item.items ? (item.value ? (item.items.length > 0 ? item.items : item.value) : item.items) : (item.value ? item.value : "");
    return { key: item.key, value: value};
  });

  const dashboardDefaultData = [
    {
      key: 'widgets',
      value: [
        {
          key: 0,
          type: 'PostForm',
          position: {
            xxxl: {
              x: 0,
              y: 0,
            },
            xxl: {
              x: 0,
              y: 0,
            },
            xl: {
              x: 0,
              y: 0,
            },
            lg: {
              x: 0,
              y: 0,
            },
            md: {
              x: 0,
              y: 0,
            },
            sm: {
              x: 0,
              y: 0,
            },
            xs: {
              x: 0,
              y: 0,
            },
          },
        },
        {
          key: 1,
          type: 'LastPost',
          position: {
            xxxl: {
              x: 7,
              y: 0,
            },
            xxl: {
              x: 7,
              y: 0,
            },
            xl: {
              x: 7,
              y: 0,
            },
            lg: {
              x: 0,
              y: 3,
            },
            md: {
              x: 0,
              y: 3,
            },
            sm: {
              x: 0,
              y: 3,
            },
            xs: {
              x: 0,
              y: 3,
            },
          },
        },
        {
          key: 2,
          type: 'TodayExcuseNotes',
          position: {
            xxxl: {
              x: 0,
              y: 2,
            },
            xxl: {
              x: 0,
              y: 2,
            },
            xl: {
              x: 0,
              y: 2,
            },
            lg: {
              x: 0,
              y: 7,
            },
            md: {
              x: 0,
              y: 7,
            },
            sm: {
              x: 0,
              y: 7,
            },
            xs: {
              x: 0,
              y: 7,
            },
          },
        },
        {
          key: 3,
          type: 'TodaySubstitutions',
          position: {
            xxxl: {
              x: 7,
              y: 4,
            },
            xxl: {
              x: 7,
              y: 4,
            },
            xl: {
              x: 7,
              y: 4,
            },
            lg: {
              x: 0,
              y: 11,
            },
            md: {
              x: 0,
              y: 11,
            },
            sm: {
              x: 0,
              y: 11,
            },
            xs: {
              x: 0,
              y: 1,
            },
          },
        },
        {
          key: 4,
          type: 'TodayAttendance',
          position: {
            xxxl: {
              x: 0,
              y: 6,
            },
            xxl: {
              x: 0,
              y: 6,
            },
            xl: {
              x: 0,
              y: 6,
            },
            lg: {
              x: 0,
              y: 15,
            },
            md: {
              x: 0,
              y: 15,
            },
            sm: {
              x: 0,
              y: 15,
            },
            xs: {
              x: 0,
              y: 15,
            },
          },
        },
      ],
    }
  ];

  const draftDefaultData = [
    {
      key: 'classbook',
      value: [],
    },
    {
      key: 'timeline',
      value: [],
    },
  ];

  const filtersDefaultData = filters.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    const value = item.items ? (item.value ? (item.items.length > 0 ? item.items : item.value) : item.items) : (item.value ? item.value : "");
    return { key: item.key, value: value};
  });

  const setupDefaultData = setup.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    return { key: item.key, value: item.state };
  });

  const keys = [
    {
      name: "addons",
      value: addonsDefaultData,
      autoload: true,
    },
    {
      name: "customizations",
      value: customizationsDefaultData,
      autoload: true,
    },
    {
      name: "dashboard",
      value: dashboardDefaultData,
      autoload: true,
    },
    {
      name: "drafts",
      value: draftDefaultData,
      autoload: true,
    },
    {
      name: "filters",
      value: filtersDefaultData,
      autoload: true,
    },
    {
      name: "setup",
      value: setupDefaultData,
      autoload: true,
    },
  ];
  
  const returnData = keys.map((item: any) => {

    const itemData = getUserSettingCategory(userSettingsFromApi, item.name);
    const defaultValues = item.value;
    const itemValues = itemData ? (itemData.object ? (Array.isArray(itemData.value) ? itemData.value : []) : JSON.parse(Array.isArray(JSON.parse(itemData.value)) ? itemData.value : JSON.stringify([]))) : [];
    const values = itemData ? mergeDeepArrays(defaultValues, itemValues) : defaultValues;
    const isAutoload = item.autoload;

    return {
      key: item.name,
      hash: itemData ? itemData.hash : "",
      value: values,
      autoload: itemData ? itemData.autoload : isAutoload,
      object: itemData ? itemData.object : false,
      ignoreHash: true
    };

  });
  return returnData;
};

const getUserSettingCategory = (userSettings: any, category: string) => {
  const data = userSettings ? (userSettings.filter((item: any) => item.key === category).length > 0 ? userSettings.find((item: any) => item.key === category) : null) : null;
  return data;
};

const updateUserSettingCategory = (userSettings: any, category: string, hash: string) => {
  if(userSettings) {
    const data = userSettings.map((item: any) => {
      if(item.key === category) {
        return {...item, hash: hash, ignoreHash: true};
      } else {
        return item;
      }
    });
    return data;
  } else {
    return null;
  }
};

const getUserSetting = (userSettings: any, category: string, keys: string[]) => {
  if(userSettings) {
    const data = userSettings.filter((item: any) => item.key === category).length > 0 ? userSettings.find((item: any) => item.key === category).value : null;
    if(data) {
      let returnData = data;
      for(let i = 0; i < keys.length; i++) {
        const key = i;
        returnData = Array.isArray(returnData) ? (returnData.filter((item: any) => item.key === keys[key]).length > 0 ? returnData.find((item: any) => item.key === keys[key]).value : null) : null;
      }
      return returnData;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const updateUserSetting = (userSettings: any, category: string, keys: string[], value: any): any => {
  if(userSettings) {
    const updatedSettings = JSON.parse(JSON.stringify(userSettings));
    if(updatedSettings) {
      const categoryItem = updatedSettings.find((item: any) => item.key === category);
      if(categoryItem) {
        let currentData = categoryItem.value;
        for(let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const keyItem = currentData.find((item: any) => item.key === key);
          if((i + 1) === keys.length) {
            if(keyItem) {
              keyItem.value = value;
            } else {
              currentData.push({ key, value });
            }
          }
          currentData = currentData.find((item: any) => item.key === key).value;
        }
        return updatedSettings;
      }
    }
    return null;
  } else {
    return null;
  }
};

const convertUserSettings = (userSettings: any, type: 'stringify' | 'parse') => {
  if(Array.isArray(userSettings)) {
    if(type === "stringify") {
      return userSettings.map((item: any) => {
        return {...item, value: JSON.stringify(item.value)};
      });
    } else {
      return userSettings.map((item: any) => {
        return {...item, value: JSON.parse(item.value)};
      });
    }
  } else if(typeof userSettings === "object") {
    if(type === "stringify") {
      return {...userSettings, value: JSON.stringify(userSettings.value)};
    } else {
      return {...userSettings, value: JSON.parse(userSettings.value)};
    }
  }
};

const saveUserSettings = async (dispatch: any, userData: any, category: string, keys: string[], values: any) => {
  const updateSettings = updateUserSetting(userData.userSettings, category, keys, values);
  const stringifiedSettings = convertUserSettings(getUserSettingCategory(updateSettings, category), "stringify");
  try {
    const result: any = await UserService.setUserSetting(stringifiedSettings);
    if(result && result.data && result.data.hash) {
      const hash = result.data.hash;
      const updatedSettings = updateUserSettingCategory(updateSettings, category, hash);
      dispatch(setUserSettings(updatedSettings));
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  };
};

const saveMultipleUserSettings = async (dispatch: any, userData: any, category: string, items: any) => {
  let updateSettings = userData.userSettings;
  items.forEach((item: any) => {
    const keys = item.keys;
    const values = item.values;
    updateSettings = updateUserSetting(updateSettings, category, keys, values)
  });
  const stringifiedSettings = convertUserSettings(getUserSettingCategory(updateSettings, category), "stringify");
  try {
    const result: any = await UserService.setUserSetting(stringifiedSettings);
    if(result && result.data && result.data.hash) {
      const hash = result.data.hash;
      const updatedSettings = updateUserSettingCategory(updateSettings, category, hash);
      dispatch(setUserSettings(updatedSettings));
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  };
};

const getUpdatedUserSettings = async (dispatch: any, userData: any, category: string) => {
  try {
    const result: any = await UserService.getUserSetting(category);
    if(result && result.data && result.data.hash) {
      const hash = result.data.hash;
      const updatedSettings = updateUserSettingCategory(userData.userSettings, category, hash);
      dispatch(setUserSettings(updatedSettings));
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  };
};

export {
  getUserRole,
  getUserHomepage,
  createUserObject,
  createUserSettings,
  getUserSettingCategory,
  updateUserSettingCategory,
  getUserSetting,
  updateUserSetting,
  convertUserSettings,
  saveUserSettings,
  saveMultipleUserSettings,
  getUpdatedUserSettings,
};