import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import EmojiIcon from 'src/components/Icons/EmojiIcon';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';

interface Props {
  isSelected?: boolean;
  isDisabled?: boolean;
  mode?: any;
  disableClick?: any;
  size?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
    gap: '8px',
    cursor: (props: Props) => props.disableClick ? 'auto' : 'pointer',
    '& > div': {
      '& > div': {
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: (props: Props) => {
          if(props.isSelected && props.mode === "select") return theme.colors.primaryBlue[500];
          else return 'transparent';
        },
        borderRadius: '12px',
        width: 'calc(100% - 6px)',
        height: 'calc(100% - 6px)',
      },
    },
    '& > span': {
      color: (props: Props) => {
        if(props.isSelected && props.mode === "select") return theme.colors.primaryBlue[500];
        else return '#808080';  
      },
      fontWeight: (props: Props) => {
        if(props.isSelected && props.mode === "select") return 'bold';
        else return 'normal';
      },
    }
  },
  avatarWrapper: {
    position: 'relative',
    '& > div': {
      '& > div': {
        backgroundColor: theme.colors.white,
        filter: (props: Props) => {
          if(!props.isSelected && props.mode === "select") return 'grayscale(100%)';
          else return 'grayscale(0%)';
        },
      },
    },
  },
  avatar: {
    display: 'block',
    minWidth: (props: Props) => props.size + 'px',
    width: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',  
    borderRadius: '12px',
    '& > div': {
      backgroundColor: theme.colors.white,
      borderRadius: '0px',
      borderColor: 'unset',
      borderWidth: 'unset',
      borderStyle: 'unset',
    },
  },
  displayName: {
    maxWidth: '100%',
    fontSize: '13px',
    lineHeight: '13px',
    height: '26px',
    textAlign: 'center',
    marginTop: '6px',
  },
  reactionEmoji: {
    width: (props: Props) => (props.size / 2) + 'px',
    height: (props: Props) => (props.size / 2) + 'px',
    backgroundColor: theme.colors.grey[200],
    position: 'absolute',
    right: (props: Props) => '-' + (props.size / 15) + 'px',
    bottom: (props: Props) => '-' + (props.size / 15) + 'px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: (props: Props) => (props.size / 3) + 'px',
  },
}));

type UserInfoType = {
  userID: any;
  isSelected?: boolean;
  isDisabled?: boolean;
  isShowEmoji?: boolean;
  disableClick?: boolean;
  defaultUserData?: any;
  mode?: any;
  onClick?: any;
  size?: any;
};

const UserInfoItem: React.FunctionComponent<UserInfoType> = ({ userID, isSelected, isDisabled, isShowEmoji, disableClick, defaultUserData, mode, onClick, size = 60 }) => {

  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const userData =  getUserData(userID).length === 0 ? (defaultUserData ? defaultUserData : []) : (defaultUserData ? Object.assign({}, defaultUserData, getUserData(userID)) : getUserData(userID));
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);

  const classes = useStyles({
    isSelected: isSelected,
    isDisabled: isDisabled,
    disableClick: disableClick,
    mode: mode,
    size: size,
  });

  const onClickAction = disableClick ? null : onClick;
    
  return (
    <div className={classes.root} onClick={onClickAction}>
      <div className={classes.avatarWrapper}>
        <AuthenticatedImage thumbLink={userData.photo.thumbLink} className={classes.avatar}/>
        {
          (isShowEmoji && reactions.filter((reaction: any) => reaction.emojiID === userData.emojiID).length !== 0) ? (
            <EmojiIcon className={classes.reactionEmoji} emoji={reactions.find((reaction: any) => reaction.emojiID === userData.emojiID).emoji}/>
          ) : null
        }
      </div>
      <Clamp withTooltip lines={2}>
        <span className={classes.displayName}>{userData.displayName}</span>
      </Clamp>
    </div>
  );
};

export default UserInfoItem;