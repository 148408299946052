import ChildSelect from './ChildSelect';
import ColumnsHandler from './ColumnsHandler';
import FinishHandlerSelect from './FinishHandler';
import React from 'react';
import SchoolSelect from './SchoolSelect';
import StartSelect from './StartSelect';
import TemplateSelect from './TemplateSelect';
import Wizard from 'src/components/Layouts/Wizard';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import NormalButton from 'src/components/Buttons/NormalButton';

interface Props {
  containerHeight?: any;
}; 

const useStyles = createUseStyles((theme: any) => ({
  pressReport: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '100%',
    height: (props: Props) => `${props.containerHeight - 104}px`,
  },
  wizard: {
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
      height: '100%',
    },
  },
}));

const PressReport: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);

  const classes = useStyles({
    containerHeight: layoutData.containerHeight,
  });

  const schoolsList = dataData.schools;

  const pageLayouts = [
    {
      name: t('columns'),
      value: "columns",
    },
    {
      name: t('rows'),
      value: "rows",
    }
  ];

  const pageOrientations = [
    {
      name: t('landscape'),
      value: "landscape",
    },
    {
      name: t('portrait'),
      value: "portrait",
    }
  ];

  const pageSizes = [
    {
      name: 'A3',
      value: "a3",
    },
    {
      name: 'A4',
      value: "a4",
    },
    {
      name: 'A5',
      value: 'a5',
    }
  ];

  const pressReportData = {
    pageLayouts: pageLayouts,
    pageOrientations: pageOrientations,
    pageSizes: pageSizes,
  };

  const [state, setState] = useStates({
    currentStep: 1,
    schoolID: dataData.schools.length === 1 ? dataData.schools[0].schoolID : null,
    type: null,
    templateID: null,
    templateFirstTime: false,
    templateData: null,
    children: [],
    allColumns: [],
    columns: [],
    columnsOrdered: [],
    title: "",
    pageLayout: pageLayouts[0],
    pageOrientation: pageOrientations[0],
    pageSize: pageSizes[1],
  });

  const handleSetCurrentStep = (step: any) => {
    setState("currentStep", step);
  };

  const handleBack = () => {
    const step = state.currentStep - 1;
    handleSetCurrentStep(step);
  };

  const handleSetSchool = (schoolID: any) => {
    setState("schoolID", schoolID);
    setState("type", null);
    setState("templateID", null);
    setState("templateFirstTime", null);
    setState("templateData", null);
    setState("children", []);
    setState("columns", []);
    setState("columnsOrdered", []);
    setState("title", "");
    setState("pageLayout", pageLayouts[0]);
    setState("pageOrientation", pageOrientations[0]);
    setState("pageSize", pageSizes[1]);
    setState("currentStep", state.currentStep + 1);
  };

  const handleSetType = (type: any) => {
    setState("type", type);
    setState("templateID", null);
    setState("templateFirstTime", null);
    setState("templateData", null);
    setState("children", []);
    setState("columns", []);
    setState("columnsOrdered", []);
    setState("title", "");
    setState("pageLayout", pageLayouts[0]);
    setState("pageOrientation", pageOrientations[0]);
    setState("pageSize", pageSizes[1]);
    setState("currentStep", state.currentStep + 1);
  };

  const handleSetTemplate = (templateData: any) => {
    if(templateData === null) {
      handleSetType("new");
      setState("currentStep", state.currentStep);
    } else {
      setState("templateFirstTime", true);
      setState("templateID", templateData.templateID);
      setState("templateData", templateData);
      setState("children", []);
      setState("columns", templateData.columns);
      setState("title", templateData.title);
      setState("columnsOrdered", templateData.columns);
      setState("currentStep", state.currentStep + 1);
    }
  };

  const handleSetChildren = (children: any) => {
    setState("children", children);
    if(state.templateFirstTime) {
      setState("templateFirstTime", false);
      setState("currentStep", steps.length);
    } else {
      setState("currentStep", state.currentStep + 1);
    }
  };

  const handleSetColumns = (columns: any) => {
    setState("columns", columns);
    setState("columnsOrdered", columns);
    setState("currentStep", state.currentStep + 1);
  };

  const handleSetOrderedColumns = (columns: any) => {
    setState("columnsOrdered", columns);
    setState("currentStep", state.currentStep + 1);
  };

  const handleState = (name: any, value: any) => {
    setState(name, value);
  };

  const handleSetAllColumns = (columns: any) => {
    setState("allColumns", columns);
  };

  const steps = [
    schoolsList.length > 1 ? {
      label: t("schools"),
      content: <SchoolSelect onSelect={handleSetSchool}/>,
      canSelect: true,
      optional: false,
      showFooter: false,
      footerContent: null,
    } : null,
    {
      label: t('creation'),
      content: <StartSelect onSelect={handleSetType}/>,
      canSelect: state.schoolID !== null,
      optional: false,
      showFooter: true,
      footerContent: (<NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>)
    },
    state.type === "template" ? {
      label: t('template'),
      content: <TemplateSelect schoolID={state.schoolID} templateID={state.templateID} templateData={state.templateData} handleSetAllColumns={handleSetAllColumns} onSelect={handleSetTemplate} onBack={handleBack}/>,
      canSelect: state.type !== null,
      optional: false,
      showFooter: false,
      footerContent: null,
    } : null,
    {
      label: t('children'),
      content: <ChildSelect schoolID={state.schoolID} selectedChildren={state.children} onSelect={handleSetChildren} onBack={handleBack}/>,
      canSelect: (state.type !== null && state.type !== "template") || (state.type === "template" && state.templateID !== null),
      optional: false,
      showFooter: false,
      footerContent: null,
    },
    {
      label: t('columns'),
      content: <ColumnsHandler mode="edit" columns={state.columns} handleSetAllColumns={handleSetAllColumns} onFinish={handleSetColumns} onBack={handleBack}/>,
      canSelect: state.children.length !== 0,
      optional: state.type === "template" && state.templateID !== null,
      showFooter: false,
      footerContent: null,
    },
    {
      label: t('columns_order'),
      content: <ColumnsHandler mode="order" columns={state.columns} allColumns={state.allColumns} columnsOrdered={state.columnsOrdered} onFinish={handleSetOrderedColumns} onBack={handleBack}/>,
      canSelect: state.children.length !== 0 && state.columns.length !== 0,
      optional: state.type === "template" && state.templateID !== null,
      showFooter: false,
      footerContent: null,
    },
    {
      label: t('completion'),
      content: <FinishHandlerSelect state={state} setState={handleState} pressReportData={pressReportData} onBack={handleSetCurrentStep}/>,
      canSelect: state.children.length !== 0 && state.columnsOrdered.length !== 0,
      showFooter: false,
      footerContent: null,
    }
  ];

  const stepsList = steps.filter((item: any) => item !== null);

  const getStepData: any = (key: any) => {
    return stepsList[key];
  };
    
  return (
    <div className={classes.pressReport}>
      <Wizard className={classes.wizard} steps={stepsList} currentStep={state.currentStep} defaultStep={1} onStepChange={handleSetCurrentStep} showFooter={getStepData(state.currentStep - 1).showFooter} footerContent={getStepData(state.currentStep - 1).footerContent}/>
    </div>
  );
};

export default PressReport;