import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button, MobileStepper, Step, StepButton, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStates } from 'src/utils/useState';

interface Props {
  mode?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  wizard: {
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: 'calc(100% - 32px)',
  },
  stepper: {
    padding: '8px 0',
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 90px)',
    flex: '1 1 auto',
  },
  noContentWrapper: {
    display: 'none',
    width: '100%',
    height: 'calc(100% - 90px)',
    flex: '1 1 auto',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  stepVisible: {
    visibility: 'visible',
  },
  stepHidden: {
    visibility: 'hidden',
    display: 'none',
  },
  stepButton: {
    backgroundColor: (props: Props) => {
      if(props.mode === "view") return theme.colors.grey[100];
      else return "";
    },
    borderRadius: '14px',
    '&:not(.Mui-disabled)': {
      '& .MuiStepIcon-root': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
        },
      },
      '& .MuiStepLabel-label': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
          fontWeight: '500',
        },
      },
    },
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        fontWeight: '600',
      }
    },
    '& p': {
      fontSize: '12px',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

type MobileType = {
  steps: any;
  currentStep: number;
  onStepChange: any;
  onComplete?: any;
  canNextStep?: boolean;
  showFooter: boolean;
  footerContent: any;
  className?: any,
};

const Mobile: React.FunctionComponent<MobileType> = ({ steps, currentStep = 1, onStepChange, onComplete, canNextStep, showFooter, footerContent, className }) => {
  
  const { t } = useTranslation();
  
  const [state, setState] = useStates({
    mode: "view",
  });

  const totalSteps = steps.length;

  const activeStep = currentStep - 1;

  const classes = useStyles({
    mode: state.mode,
  });

  const handleStep = (step: any) => {
    if(state.mode === "view") {
      setState("mode", "click");
    } else {
      if(onStepChange) {
        setState("mode", "view");
        onStepChange(step);
      }
    }
  };

  const handleNext = () => {
    const step = currentStep + 1;
    handleStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;
    handleStep(step);
  };

  const getStepData = (key: any) => {
    return steps[key];
  };

  return (
    <div className={`${classes.wizard} ${className ? className : ''}`}>
      <Stepper className={classes.stepper} nonLinear activeStep={activeStep} orientation="vertical" connector={state.mode === "view" ? null : undefined}>
        {
          steps.map((data: any, key: any) => (
            <Step key={`k_${key}`} className={state.mode === "view" ? (key === activeStep ? classes.stepVisible : classes.stepHidden) : ""}>
              <StepButton className={classes.stepButton} optional={data.optional ? (<p>{t('optional')}</p>) : null} color="inherit" onClick={() => handleStep(key + 1)} disabled={state.mode === "click" && !getStepData(key).canSelect}>
                {data.label}
              </StepButton>
            </Step>
          ))
        }
      </Stepper>
      <div className={state.mode === "view" ? classes.contentWrapper : classes.noContentWrapper}>
        {
          steps.map((data: any, key: any) => {
            if(key === activeStep) {
              return (
                <div className={classes.content} key={`k_${key}`}>{data.content}</div>
              );
            } else {
              return null;
            }
          })
        }
      </div>
      {
        showFooter ? (
          <>
          {
              footerContent ? (
                <>
                  {footerContent} 
                </>
              ) : (
                <MobileStepper
                  variant="text"
                  steps={totalSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === totalSteps - 1}>
                      {t('next')}
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      {t('back')}
                    </Button>
                  }
                />
                )
              }
          </>
        ) : null
      }
    </div>
  );
}

export default Mobile;
