import moment from '../../utils/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  currentChild: any;
  childData: any;
  date: any;
  list: any;
  months: any;
  firstAvailableDate: any;
};

const initialState: ArrayModel = {
  currentChild: null,
  childData: {
    attendanceProgram: {days: []},
    credit: 0,
    allDays: [],
    allDates: [],
    lastDay: moment().format("YYYY-MM-DD"),
    holidayDates: [],
    schoolDays: 0,
    workingDays: [],
  },
  date: moment(),
  list: [],
  months: [],
  firstAvailableDate: null,
};

const slice = createSlice({
  name: 'substitutions',
  initialState: initialState,
  reducers: {
    setSubstitutionsCurrentChild(state, action: PayloadAction<Model>) {
      state.currentChild = action.payload;
    },
    setSubstitutionsDate(state, action: PayloadAction<Model>) {
      state.date = action.payload;
    },
    setSubstitutionsFirstAvailableDate(state, action: PayloadAction<Model>) {
      state.firstAvailableDate = action.payload;
    },
    setSubstitutionsList(state, action: PayloadAction<Model>) {
      state.list = state.list.concat(action.payload);
    },
    setSubstitutionsNewList(state, action: PayloadAction<Model>) {
      state.list = action.payload;
    },
    addToSubstitutionsMonths(state, action: PayloadAction<Model>) {
      const childID = action.payload.childID;
      const month = action.payload.month;
      if(state.months.filter((item: any) => item.childID === childID).length > 0) {
        state.months = state.months.map((item: any) => {
          if(item.childID === childID) {
            return {...item, months: item.months.concat(month)};
          } else {
            return item;
          }
        });
      } else {
        state.months = state.months.concat({childID: childID, months: [month]});
      }
    },
    addToSubstitutionsList(state, action: PayloadAction<Model>) {
      const childID = action.payload.childID;
      const dates = action.payload.dates;
      if(state.list.filter((item: any) => item.childID === childID).length === 0) {
        const newData = { childID: childID, dates: dates };
        state.list = state.list.concat(newData);
      } else {
        state.list = state.list.map((item: any) => {
          if(item.childID === childID) {
            return {...item, dates: item.dates.concat(dates)};
          } else {
            return item;
          }
        });
      }
    },
    addSubstitutionsList(state, action: PayloadAction<Model>) {
      action.payload.forEach((data: any) => {
        if(state.list.filter((item: any) => item.childID === data.childID).length === 1) {
          state.list = state.list.map((item: any) => {
            if(item.childID === data.childID) {
              return {...item, dates: [...item.dates, data]};
            } else {
              return item;
            }
          });
        }
      });
    },
    updateSubstitutionsList(state, action: PayloadAction<Model>) {
      const absences = action.payload.absences;
      state.list = state.list.map((data: any) => {
        return {...data, dates: data.dates.map((date: any) => {
          if(absences.length > 0) {
            if(absences.filter((item: any) => item.absenceID === date.absenceID).length === 1) {
              return absences.find((item: any) => item.absenceID === date.absenceID);
            } else {
              return date;
            }
          } else {
            return date;
          }
        })};
      });
    },
    deleteSubstitution(state, action: PayloadAction<Model>) {
      state.list = state.list.filter((data: any) => data.childID === action.payload.childID).map((data: any) => {
        return {...data, dates: data.dates.filter((date: any) => date.substitutionID !== action.payload.substitutionID)};
      });
    },
    setChildData(state, action: PayloadAction<Model>) {
      state.childData = action.payload;
    },
    clearSubstitutionsList(state) {
      state.list = [];
    },
    clearSubstitutionsMonths(state) {
      state.months = [];
    },
  }
});

export default slice;