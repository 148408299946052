import config from '../constants/config';
import moment from './moment';
import months from '../constants/months';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/sk';

const tplaceholder = (text: any) => {
  return text;
};

const languageplaceholder = {
  language: "en"
};

type languageFormatsType = {
  [key: string]: any;
};

const customLanguageFormats: languageFormatsType = {
  'cs': {
    LD: 'D',
    LDM: 'D.M.',
    LDMN: ' D.M.',
    LDMM: 'Do MMMM',
    LYMM: 'MMMM YYYY',  
  },
  'de': {
    LD: 'D',
    LDM: 'D.MM.',
    LDMN: ', D.MM.',
    LDMM: 'MMMM Do',
    LYMM: 'MMMM YYYY',  
  },
  'en': {
    LD: 'D',
    LDM: 'M/D',
    LDMN: ' M/D',
    LDMM: 'Do MMMM',
    LYMM: 'MMMM YYYY',  
  },
  'es': {
    LD: 'D',
    LDM: 'M/D',
    LDMN: ' M/D',
    LDMM: 'Do MMMM',
    LYMM: 'MMMM YYYY', 
  },
  'sk': {
    LD: 'D',
    LDM: 'D.M.',
    LDMN: ' D.M.',
    LDMM: 'Do MMMM',
    LYMM: 'MMMM YYYY',  
  },
}; 

const DateFormat = (date: any, type: any = "default", languageData: any = languageplaceholder, t: any = tplaceholder, timezone = config.APP_TIMEZONE) => {
  const language = languageData.language.toLowerCase();
  const formats: any = {
    default: `${moment(date).tz(timezone).locale(language).format("L")}`,
    defaulttime: `${moment(date).tz(timezone).locale(language).format("L")} ${moment(date).tz(timezone).locale(language).format("LT")}`,
    timeline: `${moment(date).tz(timezone).locale(language).format("LL")}, ${moment(date).tz(timezone).locale(language).format("LT")}`,
    comment: `${moment(date).tz(timezone).locale(language).format("LL")}, ${moment(date).tz(timezone).locale(language).format("LT")}:${moment(date).tz(timezone).locale(language).format("ss")}`,
    day: `${moment(date).tz(timezone).locale(language).format(customLanguageFormats[language]["LD"])}`,
    daynamemonth: `${t(moment(date).tz(timezone).locale("en").format("dddd").toLowerCase())}${moment(date).tz(timezone).locale(language).format(customLanguageFormats[language]["LDMN"])}`,
    daymonth: `${t(moment(date).tz(timezone).locale("en").format("dd").toLowerCase())}${moment(date).tz(timezone).locale(language).format(customLanguageFormats[language]["LDMN"])}`,
    daymonthname: `${moment(date).tz(timezone).locale(language).format(customLanguageFormats[language]["LDMM"])}`,
    yearmonthname: months[moment(date).tz(timezone).month()] ? `${t(months[moment(date).tz(timezone).month()].toLowerCase())} ${moment(date).tz(timezone).format("YYYY")}` : `${t("unknown")} ${moment(date).tz(timezone).format("YYYY")}`,
    time: `${moment(date).tz(timezone).locale(language).format("LT")}`,
    timeseconds: `${moment(date).tz(timezone).locale(language).format("LTS")}`,
    hoursminutes: `${moment.utc(date).tz(timezone).locale(language).format('HH:mm')}`,
    minutesseconds: `${moment.utc(date).tz(timezone).locale(language).format('mm:ss')}`,
    daymonthnameyear: `${moment(date).tz(timezone).locale(language).format("LL")}`,
  };
  let formatedDate = formats[type];
  if(type === "timeline") {
    if(language === "de") {
      formatedDate = formatedDate + " Uhr";
    }
    if(moment().isSame(moment(date),'year')) {
      formatedDate = formatedDate.replace(" " + moment().format("YYYY").toString(),"");
    }
  }
  formatedDate = formatedDate.replace(",,",",");
  return formatedDate;
};

export default DateFormat;