import CircularProgress from '@mui/material/CircularProgress';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import { arrayBufferToBase64, base64toBlob } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { setAttachments } from '../../../../store/actions/medias.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  attachmentWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pdfViewer: {
    width: '80%',
    height: '100%',
    maxWidth: '80%',
  },
  unsupported: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.colors.white,
    '& > svg': {
      width: '96px',
      height: '96px',
      marginBottom: '5px',
    },
    '& > p': {
      maxWidth: '80%',
      marginBottom: '0',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
}));

type RenderAttachmentType = {
  mediaData: any;
  setIsDownloadable: any;
  handleSecureClick: any;
  setMetadata: any;
};

const RenderAttachment: React.FunctionComponent<RenderAttachmentType> = ({ mediaData, setIsDownloadable, handleSecureClick, setMetadata }) => {

  const { t } = useTranslation();
  const mediasData = useAppSelector((state: any) => state.medias);
  const mediaID = useAppSelector((state: any) => state.modals).galleryModal.mediaID;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const dispatch = useAppDispatch();
  const classes = useStyles();  
  const fileType = (mediaData.name).split('.').pop();

  const [state, setState, setStates] = useStates({
    mediaB64: mediasData.attachments.filter((attachment: any) => attachment.id === mediaID).length === 0 ? null : mediasData.attachments.find((attachment: any) => attachment.id === mediaID).base64,
    mediaUrl: null,
    blobStorageUrl: mediasData.attachments.filter((attachment: any) => attachment.id === mediaID).length === 0 ? null : mediasData.attachments.find((attachment: any) => attachment.id === mediaID).blobStorageUrl,
    isAttachmentLoaded: false,
    lastMediaID: null,
    size: null,
    isFailed: false,
  });
    
  useEffect(() => {
    setState("mediaB64", null);
    setState("isAttachmentLoaded", false);
  }, [mediaData, setState], [mediaData]);
  
  useEffect(() => {
    if(!state.isAttachmentLoaded) {
      let contentType = "application/octet-stream";
      if(fileType === "pdf") {
        contentType = "application/pdf";  
      }
      if(state.mediaB64 !== null) {
        const blob = base64toBlob(state.mediaB64, contentType);
        setStates({
          mediaUrl: URL.createObjectURL(blob),
          size: Math.ceil(state.mediaB64.length / 4) * 3,
          isAttachmentLoaded: true,
          lastMediaID: mediaID,
        });
        setIsDownloadable(true);
        handleSecureClick();
      } else {
        timelineService && timelineService.downloadFile(mediaID).then((result: any) => {
          if(result) {
            if(result.data) {
              const base64 = arrayBufferToBase64(result.data);
              const blob = base64toBlob(base64, contentType);
              const responseUrl = result.request.responseURL;
              const attachmentObj = { base64: base64, id: mediaID, blobStorageUrl: responseUrl };
              dispatch(setAttachments(attachmentObj));
              setStates({
                mediaUrl: URL.createObjectURL(blob),
                blobStorageUrl: responseUrl,
                size: Math.ceil(base64.length / 4) * 3,
                isAttachmentLoaded: true,
                lastMediaID: mediaID,
              });
              setIsDownloadable(true);
              handleSecureClick();
            } else {
              setStates({
                isAttachmentLoaded: true,
                isFailed: true,
              });
            }
          } else {
            setStates({
              isAttachmentLoaded: true,
              isFailed: true,
            });
          }
        }).catch(() => {
          setStates({
            isAttachmentLoaded: true,
            isFailed: true,
          });
        });
      }
    }         
  }, [state.isAttachmentLoaded, dispatch, fileType, handleSecureClick, mediaID, setIsDownloadable, setStates, state.mediaB64, timelineService], [state.isAttachmentLoaded]); 

  useEffect(() => {
    if(state.size !== null) {
      const settings = {
        size: state.size,
      };
      setMetadata(settings);
    }
  }, [state.size, setMetadata], [state.size]);
      
  return state.isFailed ? (
    <div className={classes.attachmentWrapper}>
      <div className={classes.unsupported}>
        <SVG src={`${"document-"}${fileType}${""}`} children={<SVG src="file"/>}/>
        <p>{t("cannot_preview")}</p>
      </div>
    </div>
  ) : (state.isAttachmentLoaded && state.lastMediaID === mediaID) ? (
    <div className={classes.attachmentWrapper}>
      {
        fileType === "pdf" ? (
          <embed className={classes.pdfViewer} src={`${state.mediaUrl}#view=fitW&toolbar=0`}/>
        ) : (fileType === "doc" || fileType === "docx" || fileType === "ppt" || fileType === "pptx" || fileType === "xls" || fileType === "xlsx") ? (
          <DocViewer documents={[{ uri: state.blobStorageUrl }]} pluginRenderers={DocViewerRenderers} config={{ header: { disableHeader: true, disableFileName: true } }}/>
        ) : (
          <div className={classes.unsupported}>
            <SVG src={`${"document-"}${fileType}${""}`} children={<SVG src="file"/>}/>
            <p>{t("cannot_preview")}</p>
          </div>
        )
      }
    </div>  
  ) : (
    <div className={classes.attachmentWrapper}>
      <div className={classes.loading}>
        <CircularProgress className={classes.spinner}/>
      </div>
    </div>
  );
};

export default RenderAttachment;