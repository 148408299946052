import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import htmlParse from 'html-react-parser';
import NormalButton from '../../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getDayName, isKey, removeDuplicatesJSON } from 'src/utils/useFunctions';
import { setClassModal, setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { setTimetableData } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
  classes: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  classChip: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[300],
    borderRadius: '50px',
    padding: '6px 12px',
    whiteSpace: 'nowrap',
    color: theme.colors.grey[700],
    fontWeight: '500',
    fontSize: '14px',
    cursor: 'pointer', 
  },
  className: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#9B9EBB',
    fontSize: '14px',  
  },
  classWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  children: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '35px',
      minHeight: '35px',
      width: '35px',
      height: '35px',
      maxWidth: '35px',
      maxHeight: '35px',
    },
    '& > p': {
      fontSize: '12px',
      marginLeft: '8px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  displayName: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  employees: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  employeePhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '6px',
      minWidth: '35px',
      minHeight: '35px',
      width: '35px',
      height: '35px',
      maxWidth: '35px',
      maxHeight: '35px',
    },
    '& > p': {
      fontSize: '12px',
      marginLeft: '8px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  fullname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
}));

type ContainerType = {
  onClose: any;
};

const Basic: React.FunctionComponent<ContainerType> = ({ onClose }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);
  const schoolID = timetableData.school === null ? 0 : timetableData.school.schoolID;
  const employees = timetableData.school === null ? [] : dataData.employees.filter((employee: any) => employee.schoolID === schoolID && employee.enableForTimetable).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID } });
  const defaultChildren = dataData.children.filter((child: any) => child.schoolsID.indexOf(schoolID) !== -1).map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: [schoolID] }; });

  const timetableID = modalsData.timetableHourDetailModal.timetableID;
  const hourData = timetableData.data.timetables.filter((hour: any) => hour.timetableID === timetableID).length === 1 ? timetableData.data.timetables.find((hour: any) => hour.timetableID === timetableID) : [];
  const subjectData = timetableData.subjects.filter((item: any) => item.subjectID === hourData.subjectID).length === 1 ? timetableData.subjects.find((item: any) => item.subjectID === hourData.subjectID) : [];
  const roomData = timetableData.rooms.filter((item: any) => item.roomID === hourData.roomID).length === 1 ? timetableData.rooms.find((item: any) => item.roomID === hourData.roomID) : [];
  const hourEmployees = employees.filter((employee: any) => hourData.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; });
  const hourClasses = removeDuplicatesJSON(hourData.classToChild.map((item: any) => { return { classID: item.classID }; }));
  const hourOnlyClasses = removeDuplicatesJSON(hourData.classToChild.filter((item: any) => !item.childID).map((item: any) => { return { classID: item.classID }; }));
  const hourChildren = removeDuplicatesJSON(hourData.classToChild.filter((item: any) => item.childID).map((item: any) => { return { childID: item.childID }; }));
  const allChildren = removeDuplicatesJSON([hourOnlyClasses.map((item: any) => { return defaultChildren.filter((dataItem: any) => dataItem.classID.indexOf(item.classID) !== -1).map((dataItem: any) => { return { classID: item.classID, childID: dataItem.childID }; }); }).flat(), hourChildren].flat().map((item: any) => { return { classID: item.classID, childID: item.childID }; }));
  const weekday = modalsData.timetableHourDetailModal.weekday;
  const hour = modalsData.timetableHourDetailModal.hour;
  const type = modalsData.timetableHourDetailModal.type;
  const mode = modalsData.timetableHourDetailModal.mode;
  const isEditable = modalsData.timetableHourDetailModal.isEditable;

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      weekday: weekday,
      hour: hour,
      type: type,
      mode: mode,
      timetableID: timetableID,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  const handleDelete = () => {
    timetableService && timetableService.deleteHour(timetableID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("timetable_hour_deleted"), "success");
          onClose();
          const newData = {...timetableData.data, timetables: timetableData.data.timetables.filter((item: any) => item.timetableID !== timetableID)};
          dispatch(setTimetableData(newData));
        } else {
          createNotification(t("timetable_hour_not_deleted"), "error");
        }
      } else {
        createNotification(t("timetable_hour_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_hour_not_deleted"), "error");
    });
  };

  const handleClickClassDetail = (classID: any) => {
    const settings = {
      isOpen: true,
      classID: classID,
    };
    dispatch(setClassModal(settings));
  };

  return hourData.length !== 0 ? (
    <>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{t('timetable_hour_detail')} {subjectData.name} - {t(getDayName(weekday))} {hour}. {t('hour').toLowerCase()}</p>
        </div>
        <CloseButton onClick={onClose} dataCy="timesButton"/>
      </div>
      <div className={classes.body}>
        <div className={classes.item}>
          <p className={classes.title}>
            <SVG src="info-circle-outlined"/>
            {t('timetable_subject')}
          </p>
          <p className={classes.detail}>
            {subjectData.name}
          </p>
        </div>
        {
          roomData.name ? (
            <div className={classes.item}>
              <p className={classes.title}>
                <SVG src="info-circle-outlined"/>
                {t('timetable_room')}
              </p>
              <p className={classes.detail}>
                {roomData.name}
              </p>
            </div>
          ) : null
        }
        {
          hourClasses.length > 0 ? (
            <div className={classes.item}>
              <span className={classes.title}>
                <SVG src="class"/>
                {t('timetable_classes')}
              </span>
              <div className={classes.classes}>
              {
                hourClasses.map((theClass: any, key: any) => (
                  <span className={classes.classChip} onClick={() => handleClickClassDetail(theClass.classID)} key={`k_${key}`}>{htmlParse(getClassData(theClass.classID).name)}</span>
                ))
              }
              </div>
            </div>
          ) : null
        }
        <div className={classes.item}>
          <span className={classes.title}>
            <SVG src="user-circle-outlined"/>
            {t('timetable_children')}
          </span>
          <div className={classes.childrenWrapper}>
            {
              hourClasses.length === 1 ? (
                <div className={classes.children}>
                  {
                    allChildren.map((child: any, key: any) => getChildData(child.childID).length > 0 ? (
                      <div className={classes.childPhoto} key={`k_${key}`}>
                        <AuthenticatedImage thumbLink={getChildData(child.childID).photo.thumbLink}/>
                        <p className={classes.displayName}>{getChildData(child.childID).displayName}</p>
                      </div>
                    ) : null)
                  }
                </div>
              ) : (
                <>
                {
                  hourClasses.map((theClass: any, key: any) => (
                    <div className={classes.classWrapper} key={`k_${key}`}>
                      <span className={classes.className}>{htmlParse(getClassData(theClass.classID).name)}</span>
                      <div className={classes.children}>
                        {
                          allChildren.filter((item: any) => item.classID === theClass.classID).map((child: any, ckey: any) => getChildData(child.childID).length > 0 ? (
                            <div className={classes.childPhoto} key={`k_${ckey}`}>
                              <AuthenticatedImage thumbLink={getChildData(child.childID).photo.thumbLink}/>
                              <p className={classes.displayName}>{getChildData(child.childID).displayName}</p>
                            </div>
                          ) : null)
                        }
                      </div>
                    </div>
                  ))
                }
                </>
              )
            }
          </div>
        </div>
        <div className={classes.item}>
          <span className={classes.title}>
            <SVG src="user-circle-outlined"/>
            {t('timetable_teachers')}
          </span>
          <div className={classes.employees}>
          {
            hourEmployees.map((employee: any, key: any) => (
              <div className={classes.employeePhoto} key={`k_${key}`}>
                <AuthenticatedImage thumbLink={getEmployeeData(employee.employeeID).photo.thumbLink}/>
                <p className={classes.fullname}>{getEmployeeData(employee.employeeID).firstname + ' ' + getEmployeeData(employee.employeeID).surname}</p>
              </div>
            ))
          }
          </div>
        </div>
      </div>
      {
        isEditable ? (
          <div className={classes.footer}>
            <NormalButton buttonType="clear" onClick={handleDelete} dataCy="cancelButton">
              {t("timetable_hour_delete")}
            </NormalButton>
            <NormalButton onClick={handleEdit} dataCy="editButton">
              {t('timetable_hour_edit')}
            </NormalButton>
          </div>
        ) : null
      }
    </>
  ) : null;
};

export default Basic;