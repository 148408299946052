import PickButton from 'src/components/Buttons/PickButton';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  itemPicker: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
  },
}));

type ItemPickerType = {
  items: any;
  value?: any;
  valueCompare: any;
  onChange: any;
  className?: any;
  disabled?: boolean;
};

const ItemPicker: React.FunctionComponent<ItemPickerType> = ({items, value, valueCompare = "value", onChange, className, disabled}) => {

  const classes = useStyles();

  
  return (
    <div className={`${classes.itemPicker} ${className ? className : null}`}>
      {
        items.map((item: any, key: any) => (
        <PickButton
          isPicked={(item && item[valueCompare] && value && value[valueCompare]) ? (item[valueCompare] === value[valueCompare]) : false}
          key={`k_${key}`} 
          onClick={() => onChange(item)} 
          dataCy={`itemPickerButton${item ? item[valueCompare] : key}`} 
          disabled={disabled || item.disabled || ((item && item[valueCompare] && value && value[valueCompare]) ? (item[valueCompare] === value[valueCompare]) : false)}
        >
            {item.name}
          </PickButton>
        ))
      }
    </div>
  );
}

export default ItemPicker;