import * as UserService from '../../../../../services/user.service';
import config from 'src/constants/config';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { setUserObject, setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { createNotification } from 'src/utils/createNotification';
import Label from 'src/components/Forms/Label';
import { CircularProgress } from '@mui/material';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 24px',
    padding: '24px 0',
    width: 'calc(100% - 48px)',
    '& > svg': {
      width: 'auto',
      height: '48px',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    maxHeight: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  switches: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '96px',
    gap: '16px',
    position: 'relative',
    '& > span': {
      fontSize: '24px',
      width: '80%',
      fontWeight: '600',
      marginBottom: '16px',
      color: theme.colors.black,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '80%',
    marginTop: '16px',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    flex: '0 0 50%',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  iconPlaceholder: {
    position: 'relative',
    width: '50%',
    paddingTop: '50%',
    '& > svg': {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%',
      color: theme.colors.primaryBlue[500],
    },
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    '& > p': {
      color: theme.colors.grey[500],
      fontSize: '14px',
    },
  },
  switch: {
    width: '100%',
  },
  loading: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type SectionType = {
  updateSection: any;
  handleNext: any;
};

const Privacy: React.FunctionComponent<SectionType> = ({ updateSection, handleNext }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const icon = "privacy";
  const section = "privacy";

  const [state, setState] = useStates({
    canContinue: true,
  });

  const items: any = {
    public: userData.userObject.public,
  };

  const [values, setValues] = useStates(items);

  const handleSwitch = (name: any, value: any) => {
    setValues(name, value);
  };

  const saveChanges = async () => {
    let payload: any = {};
    Object.keys(values).forEach((key: any) => {
      payload[key] = values[key as keyof typeof values];
    });
    const result = await UserService.changeUserData(payload);
    if(result.status === 200) { 
      let newUser = userData.userObject;
      Object.keys(payload).forEach((key: any) => {
        newUser = {...newUser, [key]: payload[key as keyof typeof values]};
      });
      dispatch(setUserObject(newUser));
      return true;
    } else {
      return false;
    }
  };

  const handleSave = async () => {
    const value = values;
    const oldValue = getUserSetting(userData.userSettings, "setup", [section]);
    const newValue = updateUserSetting(userData.userSettings, "setup", [section], value);
    updateSection(section, false);
    dispatch(setUserSettings(newValue));
    const result = await saveUserSettings(dispatch, userData, "setup", [section], value);
    if(result) {
      return true;
    } else {
      const updateSettings = updateUserSetting(userData.userSettings, "setup", [section], oldValue);
      updateSection(section, true);
      dispatch(setUserSettings(updateSettings));
      return false;
    }
  };

  const handleContinue = async () => {
    if(state.canContinue) {
      setState("canContinue", false);
      const isSaved = await handleSave();
      const isSavedChanges = await saveChanges();
      if(isSaved && isSavedChanges) {
        handleNext();
      } else {
        createNotification(t('something_went_wrong'), 'error');
        setState("canContinue", true);
      }
    }
  };

  return (
    <div className={classes.section}>
      <div className={classes.leftSide}>
        <div className={classes.header}>
          <SVG src={config.APP_LOGO}/>
        </div>
        <div className={classes.switches}>
          <span>{t('setup_privacy')}</span>
          {
            Object.keys(items).map((value: any, key: any) => (
              <div className={classes.switchWrapper} key={`k_${key}`}>
                <Label>{t(value)}</Label>
                <p>
                  {t(`${value}_detail`)}
                </p>
                <Switch className={classes.switch} label={Boolean(values[value as keyof typeof values]) ? t('yes') : t('no')} name={value} checked={Boolean(values[value as keyof typeof values])} onChange={(name: any, val: any) => handleSwitch(name, val)}/>
              </div>
            ))                 
          }
          <div className={classes.buttonWrapper}>
            <NormalButton buttonType="primary" onClick={handleContinue} disabled={!state.canContinue}>
              {t('setup_continue')}
            </NormalButton>
          </div>
          {
            !state.canContinue ? (
              <div className={classes.loading}>
                <div className={classes.spinner}>
                  <CircularProgress size={128}/>
                </div>
              </div>
            ) : null
          }
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.icon}>
          <div className={classes.iconPlaceholder}>
            <SVG src={icon}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;