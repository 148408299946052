import React from 'react';
import RenderClass from '../RenderClass';
import { createUseStyles } from 'react-jss';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  classList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: 'calc(100% - 48px)',
    padding: '24px 24px 14px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px 12px 2px 12px',
      width: 'calc(100% - 24px)',
    },
  },
  classWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

type RenderClassesType = {
  state: any;
  setState: any;
  schoolData: any;
};

const RenderClasses: React.FunctionComponent<RenderClassesType> = ({
  state,
  setState,
  schoolData,
}) => {

  const classes = useStyles();
  const classesList = useMemo(() => state.functions.getClassesBySchoolID(schoolData.schoolID), [schoolData.schoolID, state.functions], [schoolData.schoolID, state.functions, state.isShowArchived, state.search]);

  const getTotalChildren = useMemo(() => (theClass: any) => { return state.functions.getChildrenByClassID(theClass.classID); }, [state.functions], [state.functions, state.isShowArchived, state.search]);

  return (
    <div className={`${classes.classList} ${state.customClasses ? (state.customClasses.classList ? state.customClasses.classList : "") : ""}`}>
      {
      classesList.map((theClass: any, key: any) => {
        const totalChildren = getTotalChildren(theClass);
        return totalChildren.length === 0 ? null : (
          <div className={classes.classWrapper} key={`k_${key}`}>
            <RenderClass state={state} setState={setState} schoolData={schoolData} classData={theClass}/>   
          </div>  
        );
      })
    }
    </div>
  );
}

export default RenderClasses;