import addons from 'src/constants/addons';
import Clamp from 'react-multiline-clamp';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { validateItemRequirements } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  addonsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '8px',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    width: 'calc(100% - 60px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '20px 0px 20px 0px',
      borderRadius: '0px',
    },
  },
  addons: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.chip,
    justifyContent: 'center',
    width: 'calc(100% - 8px)',
    maxWidth: '100%',
    height: 'fit-content',
    gap: '4px',
    margin: '0 4px',
  },
  addonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '256px',
    maxWidth: '100%',
    height: '256px',
    flex: '1 1 350px',
    gap: '4px',
    textTransform: 'unset',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderRadius: '0',
    transition: 'color 0.25s',
    position: 'relative',
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[100],
    },
    '& svg': {
      width: '64px',
      height: '64px',
    },
    '& > p': {
      fontSize: '16px',
      fontWeight: '400',
      color: theme.colors.grey[500],
      width: '50%',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: theme.colors.chip,
    maxWidth: 'calc(100% - 32px)',
    width: 'calc(100% - 32px)',
    margin: '0 16px',
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '8px 0',
    gap: '8px',
    '& > svg': {
      width: '36px',
      height: '36px',
      color: theme.colors.grey[800],
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    gap: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    gap: '4px',
    '& > span': {
      fontSize: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      '& > em': {
        display: 'inline-flex',
        height: 'fit-content',
        fontStyle: 'unset',
        fontSize: '9px',
        padding: '3px 5px',
        borderRadius: '6px',
        backgroundColor: theme.colors.primaryBlue[500],
        color: theme.colors.white,
      },
      '& > span': {
        lineHeight: '1',
      },
    },
    '& > p': {
      fontSize: '12px',
      color: theme.colors.grey[500],
    },
  },
  switch: {
    width: 'fit-content',
    transform: 'scale(1.25)',
  },
  name: {
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
  },
  badge: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '22px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '9px',
    lineHeight: '9px',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
  },
  header: {
    display: 'flex',
    width: 'calc(100% - 8px)',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '0px 4px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > button': {
      width: '36px',
      height: '36px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      width: 'fit-content',
      fontSize: '24px',
      fontWeight: '600',
      height: '36px',
      gap: '4px',
      '& > b': {
        color: theme.colors.primaryBlue[500],
        fontWeight: '700',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        '&:only-child': {
          margin: '0 auto',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
  },
  input: {
    marginLeft: 'auto',
    width: '250px',
  },
  notFound: {
    backgroundColor: theme.colors.white,
  },
}));

const AddonsSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const addonsList: any = addons.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null);

  const { viewmode: customViewMode } = useParams();

  const getCustomViewMode = customViewMode ? (addonsList.filter((item: any) => item.key === customViewMode).length === 0 ? null : addonsList.find((item: any) => item.key === customViewMode).key) : null;

  const isSaving = useRef(false);

  const [state, setState] = useStates({
    viewMode: getCustomViewMode ? getCustomViewMode : null,
    search: "",
    isSaving: false,
  });

  addonsList.sort((a: any, b: any) => { if (t(a.name) < t(b.name)) { return -1; } if (t(a.name) > t(b.name)) { return 1; } return 0;});
  const currentAddon = state.viewMode ? (addonsList.filter((item: any) => item.key === state.viewMode).length > 0 ? addonsList.find((item: any) => item.key === state.viewMode) : []) : [];
  const currentAddonItems = state.viewMode ? currentAddon.items.filter((item: any) => item.isEnabled && item.roles.includes(getUserRole(userData.userObject.roleType))) : [];

  const allAddonsItems = addonsList.map((item: any) => { return item.items.filter((item: any) => item.isEnabled).map((subItem: any) => { return {...subItem, parentKey: item.key}; }); }).flat();
  const searchAddonsItems = allAddonsItems.filter((item: any) => t(item.name).toLowerCase().includes(state.search.toLowerCase()));

  const handleSwitch = async (name: any, value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const oldValue = getUserSetting(userData.userSettings, "addons", [currentAddon.key, name]);
      const newValue = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "addons", [currentAddon.key, name], value);
      if(result) {
        createNotification(t("user_settings_saved"), "success");
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleSwitchSearch = async (parent: any, name: any, value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const oldValue = getUserSetting(userData.userSettings, "addons", [parent, name]);
      const newValue = updateUserSetting(userData.userSettings, "addons", [parent, name], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "addons", [parent, name], value);
      if(result) {
        createNotification(t("user_settings_saved"), "success");
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "addons", [parent, name], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleViewMode = (value: any) => {
    setState("viewMode", value);
  };

  const handleSearch = (name: any, value: any) => {
    setState(name, value);
  };

  useEffect(() => {
    const url = location.pathname.replace("/settings/addons/", "");
    if(url !== "/settings/addons") {
      window.history.replaceState({},'',"/settings/addons");
    }
  }, [location.pathname], []);
  
  return (
    <div className={classes.addonsWrapper}>
      <div className={classes.header}>
        <div className={classes.title}>
          {
            state.viewMode !== null ? (
              <>
                <IconButton onClick={() => handleViewMode(null)}>
                  <SVG src="arrow-left"/>
                </IconButton>
                <span>{t('addons')} <b>{t(currentAddon.name)}</b></span>
              </>
            ) : (
              <span>{t('addons_of_webapp')} <b>{t('twigsee')}</b></span>
            )
          }
        </div>
        {
          state.viewMode === null ? (
            <Input className={classes.input} name="search" onChange={handleSearch} placeholder={t('search_addon')}/>
          ) : null
        }
      </div>
      {
        state.search !== "" ? (
          <div className={classes.settingsWrapper}>
            {
              searchAddonsItems.length > 0 ? (
                <>
                  {
                    searchAddonsItems.map((item: any, key: any) => (
                      <div className={classes.setting} key={`k_${key}`}>
                        {
                          item.icon ? (
                            <SVG src={item.icon}/>
                          ) : null
                        }
                        <div className={classes.content}>
                          <div className={classes.row}>
                            <span>
                              <span>
                                {t(item.name)}
                              </span>
                              {
                                item.isRecommended ? (
                                <em>{t('recommended_by_twigsee')}</em>
                                ) : null
                              }
                            </span>
                            <p>{t(item.desc)}</p>
                          </div>
                          <Switch className={classes.switch} name={item.key} checked={getUserSetting(userData.userSettings, "addons", [item.parentKey, item.key])} onChange={(name: any, val: any) => handleSwitchSearch(item.parentKey, name, val)} disabled={state.isSaving}/>
                        </div>
                      </div>
                    ))
                  }
                </>
              ) : (
                <NotFound className={classes.notFound} text={t('no_addons_found')}/>
              )
            }
          </div>
        ) : (
          <>
            {
              state.viewMode === null ? (
                <div className={classes.addons}>
                  {
                    addonsList.map((item: any, key: any) => {
                      const availableItems = item.items.filter((item: any) => item.isEnabled && item.roles.includes(getUserRole(userData.userObject.roleType)));
                      const enabledItems = availableItems.filter((subItem: any) => getUserSetting(userData.userSettings, "addons", [item.key, subItem.key]));
                      if(availableItems.length > 0) {
                        return (
                          <Button className={classes.addonBox} key={`k_${key}`} onClick={() => handleViewMode(item.key)}>
                            <SVG src={item.icon}/>
                            <span className={classes.name}>{t(item.name)}</span>
                            <Clamp withTooltip={false} lines={3}>
                              <p>
                                {
                                  availableItems.map((subItem: any) => {
                                    return t(subItem.name);
                                  }).join(", ")
                                }
                              </p>
                            </Clamp>
                            {
                              enabledItems.length > 0 ? (
                                <span className={classes.badge}>
                                  {enabledItems.length}
                                </span>
                              ) : null
                            }
                          </Button>
                        );
                      } else {
                        return null;
                      }
                    })
                  }
                </div>
              ) : (
                <div className={classes.settingsWrapper}>
                  {
                    currentAddonItems.map((item: any, key: any) => (
                      <div className={classes.setting} key={`k_${key}`}>
                        {
                          item.icon ? (
                            <SVG src={item.icon}/>
                          ) : null
                        }
                        <div className={classes.content}>
                          <div className={classes.row}>
                            <span>
                              <span>
                                {t(item.name)}
                              </span>
                              {
                                item.isRecommended ? (
                                <em>{t('recommended_by_twigsee')}</em>
                                ) : null
                              }
                            </span>
                            <p>{t(item.desc)}</p>
                          </div>
                          <Switch className={classes.switch} name={item.key} checked={getUserSetting(userData.userSettings, "addons", [currentAddon.key, item.key])} onChange={(name: any, val: any) => handleSwitch(name, val)} disabled={state.isSaving}/>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default AddonsSettings;