import CloseButton from 'src/components/Buttons/CloseButton';
import config from 'src/constants/config';
import DateFormat from 'src/utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import React from 'react';
import semver from 'semver';
import SVG from 'src/components/Images/SvgRenderer';
import versions from 'src/constants/versions';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { onlyUnique } from 'src/utils/useFunctions';
import { setAppData } from 'src/utils/useApp';
import { setChangelogModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: "20px",
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important',
    '&.Mui-disabled': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
    },
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.grey[300],
    padding: '8px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    opacity: '1 !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      margin: '12px 0',
      '& > span': {
        fontSize: '16px',
        fontWeight: '600',
      },
      '& > p': {
        fontSize: '14px',
        marginLeft: 'auto',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: theme.colors.grey[200],
    '& > p': {
      fontSize: '13px',
    }
  },
  list: {
    paddingInlineStart: '20px',
    listStyle: 'none',
    '& > li': {
      '&::before': {
        content: `'\\2022'`, 
        color: theme.colors.primaryBlue[500],
        fontWeight: '800',
        fontSize: '18px',
        display: 'inline-block',
        width: '1rem',
        marginLeft: '-1rem',
      },
      '& > span': {
        fontWeight: '500',
      }
    },
  },
}));

const ChangelogModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation(["translations", "changelog", "suggestions"]);
  const dispatch = useAppDispatch();
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);  
  const userData = useAppSelector((state: any) => state.user);  
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setChangelogModal(settings));
    setAppData({changelogVersion: config.APP_CHANGELOG_VERSION});
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "changelogModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-cy={isCypress() ? 'changelogModalTitle' : null}>{ modalsData.changelogModal.isAutoOpen ? t('changelog_what_is_new') : t('changelog_title')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.accordions}>
            {
              versions().map((item: any, key: any) => {
                const list = userData.userStatus === "loggedIn" ? item.list[getUserRole(userData.userObject.roleType)] : Object.keys(item.list).map((role: any) => { return item.list[role]; }).flat().filter(onlyUnique);
                if(userData.userStatus !== "loggedIn") {
                  list.sort((a: any) => { if(a.includes("nothing_new")) return -1; else return 1; });
                }
                return (
                  <Accordion className={classes.accordion} defaultExpanded={semver.satisfies(item.name, config.APP_VERSION)} key={`k_${key}`} data-cy={isCypress() ? `changelogModalAccordion${item.name}` : null} disabled={!list}>
                    <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                      <span data-clarity-unmask="true">{item.name}</span>
                      <p data-clarity-unmask="true">{DateFormat(moment(item.date, "DD-MM-YYYY"), "default", languageData, t)}</p>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <p>
                        {item.text}
                      </p>
                      {
                        list ? (
                          <ul className={classes.list}>
                            {list.map((listItem: any, lkey: any) => (
                              <li key={`k_${lkey}`}>
                                {listItem.includes("nothing_new") ? (<span>{t(listItem, {ns: 'changelog'})}</span>) : t(listItem, {ns: 'changelog'})}
                              </li>
                            ))}
                          </ul> 
                        ) : null
                      }
                    </AccordionDetails>
                  </Accordion>
                );
              })
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangelogModal;