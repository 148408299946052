import NormalButton from 'src/components/Buttons/NormalButton';
import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback } from 'react';
import StockItemsTable from 'src/components/Tables/StockItemsTable';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { isCypress } from 'src/utils/useCypress';
import { setStockItemHandleModal } from 'src/store/actions/modals.actions';
import { setStockItems, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '16px',
    boxShadow: theme.shadows[2],
    maxHeight: '75vh',
    width: 'fit-content',
    minWidth: 'calc(50% - 32px)',
    maxWidth: 'calc(90% - 32px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
    },
  },
  backButton: {
    backgroundColor: theme.colors.grey[200],
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    width: '36px',
    height: '36px',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '100%',
    '& > h3': {
      display: 'flex',
      gap: '4px',
      '& > span': {
        color: theme.colors.primaryBlue[500],
        display: 'block',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'left',
      },
    },
  },
}));

const Items: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;
  const categoryID = stockData.category === null ? null : stockData.category;

  const [state, setState] = useStates({
    isLoading: false,
    currentPage: 1,
  });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getCategoryData = (categoryID: any) => {
    return stockData.categories.filter((item: any) => item.categoryID === categoryID).length === 0 ? [] : stockData.categories.find((item: any) => item.categoryID === categoryID);
  };

  const handleAdd = () => {
    const settings = {
      isOpen: true,
      itemID: null,
      schoolID: schoolID,
    };
    dispatch(setStockItemHandleModal(settings));
  };

  const handleBack = () => {
    dispatch(setStockViewMode("categorySelect"));
  };

  const getItems = useCallback((page: any) => {
    setState("isLoading", true);
    const settings = {
      schoolID: schoolID,
      categoryID: categoryID,
      limit: 15,
      page: page, 
    };
    stockService && stockService.listItems(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockItems(result.data.items));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_items_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_items_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, categoryID, schoolID, dispatch, setState]);

  const onPaginationChange = (page: any) => {
    setState("currentPage", page);
    getItems(page);
  };

  return (
    <div className={classes.itemsWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.box}>
        <IconButton className={classes.backButton} onClick={handleBack} data-cy={isCypress() ? "backButton" : null}>
          <SVG src="arrow-left"/>
        </IconButton> 
        <div className={classes.form}>
          <h3>
            {t('stock_items')}
            <span>{getCategoryData(categoryID).name}</span>
          </h3>
          <StockItemsTable schoolID={schoolID}/>
          <Pagination currentPage={state.currentPage} total={stockData.itemsCount} limit={15} disabled={state.isLoading} onChange={onPaginationChange}/>
          <NormalButton onClick={handleAdd}>
            {t('stock_item_add')}
          </NormalButton>
        </div>
      </div>
    </div> 
  );
};

export default Items;