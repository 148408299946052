import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { getActiveFilters } from 'src/utils/useFunctions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const Children: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtersData = useAppSelector((state: any) => state.filters);
  const childrenData = useAppSelector((state: any) => state.children);
  const layoutData = useAppSelector((state: any) => state.layout);
  const filtersActiveCount = getActiveFilters(filtersData.filterParams);

  const handleChildrenFiltersClick = () => {
    dispatch(setIsFiltersVisible(!layoutData.isFiltersVisible));
  };

  return (
    <>
      {
        childrenData.viewMode !== "press_report" ? (
          <TopBarButton className={layoutData.isFiltersVisible ? 'active' : null} icon={<SVG src="filter"/>} onClick={handleChildrenFiltersClick} dataCy="topBarButtonFilter" tooltip={t('filters')}>
            {
              filtersActiveCount === 0 ? null : (
                <span>{filtersActiveCount}</span>
              )
            }
          </TopBarButton>
        ) : null
      }
    </>
  );
}

export default Children;