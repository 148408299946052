import config from '../../../../constants/config';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  title: {
    fontWeight: '600',
    color: theme.colors.black,
  },
  subTitle: {
    fontWeight: '500',
    fontSize: '0.75rem',
    marginTop: '0.5rem',
    color: theme.colors.black,
  },
  text: {
    marginTop: '16px',
    marginBottom: '0px',
    color: theme.colors.grey[650],
    fontSize: '14px',
    maxWidth: '90%',
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  subText: {
    marginTop: '8px',
    marginBottom: '0px',
    color: theme.colors.grey[650],
    fontSize: '12px',
    maxWidth: '90%',
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  counts: {
    fontSize: '16px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '8px',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: 'inherit',
      marginRight: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }, 
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '&:not(:first-of-type)': {
      marginTop: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '0 0 100%',
      alignItems: 'center',
    },
  },
}));

const CreatePost: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const modalsData = useAppSelector((state: any) => state.modals);
  const postType = modalsData.infoModal.postType;

  return (
    <>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_title')}
          </span>
          <p className={classes.text}>
            {postType === 1 ? t('create_post_title_description') : t('create_poll_title_description')}  
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_content')}
          </span>
          <p className={classes.text}>
            {t('create_post_content_description')}  
          </p>
        </div>
      </div>
      {
        postType === 2 && (
          <div className={classes.row}>
            <div className={classes.block}>
              <span className={classes.title}>
                {t('create_poll_answers')}
              </span>
              <p className={classes.text}>
                {t('create_poll_answers_description')}  
              </p>
            </div>
            <div className={classes.block}>
              <span className={classes.title}>
                {t('create_poll_multipleanswers')}
              </span>
              <p className={classes.text}>
                {t('create_poll_multipleanswers_description')} 
              </p>
            </div>
          </div>          
        )
      }
      {
        postType === 2 && (
          <div className={classes.row}>
            <div className={classes.block}>
              <span className={classes.title}>
                {t('create_poll_answers_count_limit')}
              </span>
              <p className={classes.text}>
                {t('create_poll_answers_count_limit_description')}  
              </p>
            </div>
            <div className={classes.block}>
              <span className={classes.title}>
                {t('create_poll_answers_time_limit')}
              </span>
              <p className={classes.text}>
                {t('create_poll_answers_time_limit_description')}  
              </p>
            </div>
          </div>          
        )
      }
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_media')}
          </span>
          <p className={classes.text}>
            {t('create_post_media_description')}  
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_media_types')}
          </span>
          <p className={classes.text}>
            {t('create_post_media_types_description')}:  
          </p>
          {
            postType !== 3 ? (
              <>
                <span className={classes.subTitle}>
                  {t('create_post_media_types_photos')}:
                </span>
                <p className={classes.subText}>
                  {config.UPLOAD_EXT_PHOTOS.join(", ")}  
                </p>
                <span className={classes.subTitle}>
                  {t('create_post_media_types_documents')}:
                </span>
                <p className={classes.subText}>
                  {config.UPLOAD_EXT_FILES.join(", ")}  
                </p>
              </>
            ) : (
              <>
                <span className={classes.subTitle}>
                  {t('create_post_media_types_videos')}:
                </span>
                <p className={classes.subText}>
                  {config.UPLOAD_EXT_VIDEOS.join(", ")}  
                </p>
              </>
            )
          }         
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_activity')}
          </span>
          <p className={classes.text}>
            {t('create_post_activity_description')}  
          </p>
        </div>
        <div className={classes.block}>
          <span className={classes.title}>
            {t('create_post_children')}
          </span>
          <p className={classes.text}>
            {t('create_post_children_description')}  
          </p>
        </div>
      </div>
    </>
  );
};  

export default CreatePost;