import Button from '@mui/material/Button';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.alwaysBlack,
    padding: '10px 13px 10px 12px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#A6A6A6',
    color: theme.colors.alwaysWhite,
    width: '215px',
    borderRadius: '10px',
    marginTop: '16px',
    textDecoration: 'none',
    transition: "background-color 0.25s",
    '&:hover': {
      backgroundColor: '#393939',
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      '& > p': {
        margin: '0',
        lineHeight: '1.25',
        textAlign: 'left',
      },
    },
    '&.appleAppStore': {
      '& > div': {
        height: '40px',
      },
      '& svg': {
        width: '32px',
        height: '32px',
        marginRight: '10px',
      },
      '& > span': {
        '& > p': {
          textTransform: 'initial',
          '&:first-of-type': {
            fontSize: '12px',
            fontWeight: '300',
            whiteSpace: 'nowrap',
          },
          '&:last-of-type': {
            fontSize: '22px',
            fontWeight: '500',
          },
        },
      },
    },
    '&.googlePlay': {
      '& > div': {
        height: '40px',
      },
      '& svg': {
        width: '30px',
        height: '35px',
        marginRight: '10px',
      },
      '& > span': {
        '& > p:first-of-type': {
          fontSize: '12px',
          fontWeight: '400',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
        },
        '& > p:last-of-type': {
          fontSize: '22px',
          fontWeight: '500',
          fontFamily: '"Open Sans", sans-serif',
          textTransform: 'initial',
        },
      },
    },
    '&.huaweiAppgallery': {
      '& > div': {
        height: '40px',
      },
      '& svg': {
        width: '35px',
        height: '35px',
        marginRight: '10px',
      },
      '& > span': {
        '& > p:first-of-type': {
          fontSize: '12px',
          fontWeight: '400',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
        },
        '& > p:last-of-type': {
          fontSize: '22px',
          fontWeight: '500',
          textTransform: 'initial',
        },
      },
    },
  },
}));

type DownloadButtonType = {
  className?: any;
  title?: any;
  subtitle?: any;
  link?: any;
  icon?: any;
  tabIndex?: any;
  dataCy?: any;
};

const DownloadButton: React.FunctionComponent<DownloadButtonType> = ({className, title, subtitle, link, icon, tabIndex, dataCy}) => {
  const classes = useStyles();
  
  const handleLink = () => {
    window.open(link);
  };

  return (
    <Button type="button" className={`${classes.root} ${className}`} tabIndex={parseInt(tabIndex)} onClick={handleLink} data-cy={isCypress() ? dataCy : null}>
      <SVG src={icon}/>
      <span>
        <p>{subtitle}</p>
        <p>{title}</p>
      </span>
    </Button>  
  );
};

export default DownloadButton;