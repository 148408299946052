import CheckIcon from '@mui/icons-material/Check';
import htmlParse from 'html-react-parser';
import React, { useCallback } from 'react';
import SVG from '../../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { Tooltip } from '@mui/material';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  classItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }, 
    '&:last-of-type': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },   
  },
  classDetail: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    backgroundColor: theme.colors.white,
    color: theme.colors.grey[700],
    padding: '26px 24px',
    alignItems: 'center',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
    cursor: 'pointer',
  },
  classInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
    color: 'inherit',
  },
  classIcon: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    '& > svg': {
      width: '20px',
      height: '20px',
      color: 'inherit',
      marginRight: '8px',
    },
  },
  className: {
    color: 'inherit',
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  classChildrenCount: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primaryBlue[500],
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: 'auto',
    marginRight: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginRight: '0px',
    },
  },
  classContent: {
    padding: '0',
    backgroundColor: theme.colors.white,
  },
  classCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  },
  classCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: "100%",
    marginRight: '16px',
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      '& svg': {
        opacity: '1',
        color: theme.colors.white,
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  classRadio: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    fontSize: "16px",
    cursor: 'pointer',
  },
  classRadioButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: "100%",
    marginRight: '16px',
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      '& svg': {
        opacity: '1',
        color: theme.colors.white,
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  customWidth: {
    minWidth: "10px !important"
  },
}));

type RenderClassType = {
  state: any;
  setState: any;
  schoolData: any;
  classData: any;
};

const RenderClass: React.FunctionComponent<RenderClassType> = ({
  state,
  setState,
  schoolData,
  classData,
}) => {

  const { t } = useTranslation();

  const classID = useMemo(() => classData.classID, [classData.classID]);
  const classInfo = useMemo(() => state.functions.getClassData(classID), [classID, state.functions], [classID, state.functions, state.isShowArchived, state.search]);

  const isSelectedClass = useMemo(() => (searchData: any) => {
    return state.selectedClasses.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedClasses]);

  const isSelected = useMemo(() => isSelectedClass({ classID: classID }), [isSelectedClass, classID]);
  const totalChildrenCount = useMemo(() => classInfo.childrenID.length, [classInfo]);

  const classes = useStyles();
  
  const handleSelect = useCallback((e: any) => {
    if(state.isDisableClick) return;
    if(state.isAllowOnlyOneClassAtOnce && isSelected) return;
    e.stopPropagation();
    e.preventDefault();
    let newSelectedClasses = [...state.selectedClasses];
    if(isSelected) {
      newSelectedClasses = newSelectedClasses.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ classID: classID }));
    } else {
      const newClass = { classID: classID };
      if(newSelectedClasses.filter((theClass: any) => JSON.stringify(theClass) === JSON.stringify(newClass)).length === 0) {
        newSelectedClasses = [...newSelectedClasses, newClass];
      }
    }
    setState("selectedClasses", newSelectedClasses);
  }, [classID, setState, state, isSelected]);

  const handleClick = useCallback((e: any) => {
    if(state.isDisableClick) return;
    if(!state.isAllowOnlyOneClassAtOnce) return;
    e.stopPropagation();
    e.preventDefault();
    let newSelectedClasses: any = [];
    if(isSelected) {
      newSelectedClasses = newSelectedClasses.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ classID: classID }));
    } else {
      const newClass = { classID: classID };
      if(newSelectedClasses.filter((theClass: any) => JSON.stringify(theClass) === JSON.stringify(newClass)).length === 0) {
        newSelectedClasses = [...newSelectedClasses, newClass];
      }
    }
    setState("selectedClasses", newSelectedClasses);
    if(state.onClickClass) {
      const itemData = {
        school: schoolData,
        class: classData,
      };
      state.onClickClass(e, itemData, newSelectedClasses);
    } 
  }, [classID, setState, state, isSelected, classData, schoolData]);

  return (
    <div className={classes.classItem} data-cy={isCypress() ? `class${classID}` : null}>
      <div className={classes.classDetail} onClick={handleClick} data-cy={isCypress() ? `class${classID}Detail` : null}>
        {
          !state.isAllowOnlyOneClassAtOnce ? (
            <span className={classes.classCheck}>
              <span className={`${classes.classCheckButton} ${isSelectedClass({ classID: classID }) ? 'checked' : '' }`} onClick={handleSelect} data-cy={isCypress() ? `class${classID}Select` : null}>
                <CheckIcon/>
              </span>
            </span>
          ) : (
            <span className={classes.classRadio}>
              <span className={`${classes.classRadioButton} ${isSelectedClass({ classID: classID }) ? 'checked' : '' }`} onClick={handleClick} data-cy={isCypress() ? `class${classID}Radio` : null}>
                <CheckIcon/>
              </span>
            </span>  
          )
        }
        <span className={classes.classInfo}>
          {
            (classInfo.isArchived || !classInfo.active) ? (
              <Tooltip title={classInfo.isArchived ? t('class_archived') : classInfo.active ? null : t('class_inactive')} classes={{ tooltip: classes.customWidth }} arrow>
                <span className={classes.classIcon}>
                  {
                    classInfo.isArchived ? (
                      <SVG src="archived"/>
                    ) : classInfo.active ? null : (
                      <SVG src="inactive"/>
                    )
                  }
                </span>
              </Tooltip>
            ) : null
          }
          <span className={classes.className} data-cy={isCypress() ? 'className' : null} data-clarity-unmask="true">
            {htmlParse(classInfo.name)}
          </span>
          {
            state.isShowChildrenCounts ? (
              <span className={classes.classChildrenCount} data-cy={isCypress() ? 'classChildrenCount' : null} data-clarity-unmask="true">
                ({totalChildrenCount})
              </span>
            ) : null
          }
        </span>
      </div>
    </div>
  ); 
}

export default RenderClass;