import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import SVG from '../../Images/SvgRenderer';
import TabsMenu from '../../Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setClassbookDetailModal, setClassbookHandleModal, setPostDetailModal } from '../../../store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-end",
    gap: '10px',
    '& > p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
    '& > span': {
      fontSize: '14px',
    },
    '& > div': {
      display: "flex",
      alignItems: "center",
      gap: '10px',
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
  },
  buttons: {
    display: 'flex', 
    gap: '0.5rem', 
  },
  button: {
    width: '36px',
    height: '36px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: "20px 20px 40px 20px",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    height: '100%',
  },
  footer: {
    display: "flex",
    gap: '15px',
    padding: "20px",
    justifyContent: "flex-end",
    width: 'calc(100% - 40px)',
    '& > button': {
      width: 'fit-content',
    },
  },
  mediumWidth: {
    width: "120px"
  },
  customWidth: {
    minWidth: "10px !important"
  },
  notesIcon: {
    cursor: "help",
    '& > svg': {
      color: "#FFDC64 !important",
      width: '20px',
      height: '20px',
    },
  },
  visitIcon: {
    cursor: "help",
    '& > svg': {
      color: "#F44336 !important",
      width: '20px',
      height: '20px',
    },
  },
  info: {
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px 10px',
      gap: '5px',
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    position: 'sticky',
    top: '0',
    zIndex: theme.zIndex.tabsWrapper,
    '& > div': {
      width: '100%',
      backgroundColor: theme.colors.white,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '14px',
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '5px',
      marginBottom: '16px',
      fontSize: '12px',
      whiteSpace: 'pre-wrap',
    },
  },
  post: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '14px',
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '5px',
      marginBottom: '16px',
      fontSize: '12px',
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

const ClassbookDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;

  const curClass = modalsData.classbookDetailModal.classID;
  const curDate = modalsData.classbookDetailModal.date;
  const curDates = modalsData.classbookDetailModal.dates;

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const className = getClassData(curClass).name;
  const classbookType = getClassData(curClass).classbookType;
  const isSchoolCare = getClassData(curClass).isSchoolCare;
  const isEditable = !getClassData(curClass).isArchived && getClassData(curClass).active;
  const startDate = (classbookType === "weekly" && curDates) ? moment(curDates[1]).startOf('month').format("YYYY-MM-DD") : moment(curDate).startOf('isoWeek').format("YYYY-MM-DD");
  const endDate = (classbookType === "weekly" && curDates) ? moment(curDates[1]).endOf('month').format("YYYY-MM-DD") : moment(curDate).startOf('isoWeek').add(6, "days").format("YYYY-MM-DD");
  const dateFrom = (classbookType === "weekly" && curDates) ? moment(curDates[0]).format("YYYY-MM-DD") : moment(curDate).format("YYYY-MM-DD");
  const dateTo = (classbookType === "weekly" && curDates) ? moment(curDates[1]).format("YYYY-MM-DD") : moment(curDate).format("YYYY-MM-DD");
  const list = classbookData.list;
  const recordDaily = list.filter((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).length === 0 ? null : (list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.filter((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(curDate).format("YYYY-MM-DD")).length === 0 ? null : list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.find((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(curDate).format("YYYY-MM-DD")));
  const recordWeekly = list.filter((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).length === 0 ? null : (list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.filter((item: any) => moment(item.dateFrom).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD") && moment(item.dateTo).format("YYYY-MM-DD") === moment(dateTo).format("YYYY-MM-DD")).length === 0 ? null : list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.find((item: any) => moment(item.dateFrom).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD") && moment(item.dateTo).format("YYYY-MM-DD") === moment(dateTo).format("YYYY-MM-DD")));
  const record = classbookType === "weekly" ? recordWeekly : recordDaily;

  const [state, setState] = useStates({
    postLists: [],
    notesList: [],
    viewMode: "educational_activities",
  });

  const educational_activities = (isSchoolCare ? [record.notes, record.interestActivities, record.relaxationRecreationalActivities, record.preparationTeaching, record.otherActivities, record.internalNote] : [record.morning, record.afternoon, record.notes]).filter((item: any) => item !== "" && item !== null && item !== undefined);
  const posts = record.postCount;
  const notes = record.noteCount;
  const other = (isSchoolCare ? [] : [record.theme, record.visit]).filter((item: any) => item !== "" && item !== null && item !== undefined);

  const tabsItems: any = [
    {
      name: 'educational_activities',
      value: 'educational_activities',
      badge: educational_activities.length,
      badgeType: 'primary',
      isEnabled: true,
      disabled: educational_activities.length === 0,
    },
    {
      name: 'posts',
      value: 'posts',
      badge: posts.length,
      badgeType: 'primary',
      isEnabled: true,
      disabled: posts.length === 0,
    },
    {
      name: 'notes',
      value: 'notes',
      badge: notes.length,
      badgeType: 'primary',
      isEnabled: true,
      disabled: notes.length === 0,
    },
    {
      name: 'other',
      value: 'other',
      badge: other.length,
      badgeType: 'primary',
      isEnabled: true,
      disabled: other.length === 0,
    },
  ];

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      classID: null,
      date: null,
    };
    dispatch(setClassbookDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      date: curDate,
      dates: classbookType === "weekly" ? [record.dateFrom, record.dateTo] : null,
      classID: curClass,
    };
    dispatch(setClassbookHandleModal(settings)); 
  };

  const handleTimeline = (e: any, postID: any) => {
    e.stopPropagation();
    const settings = {
      isOpen: true,
      postID: postID,
    };
    dispatch(setPostDetailModal(settings)); 
  };

  const setViewMode = (value: any) => {
    setState("viewMode", value);
  };

  useEffect(() => {
    if(posts.length > 0 || notes.length > 0) {
      timelineService && timelineService.listPosts({postID: posts.concat(notes).join(",")}).then((result: any) => {
        const newPosts = result.data.posts;
        let tmpPosts: any = [];
        let tmpNotes: any = [];
        newPosts.forEach((post: any) => {
          if(post.activityTypeID === 15) {
            tmpNotes = [...tmpNotes, {postID: post.postID, title: post.title, created: post.created}];  
          } else {
            tmpPosts = [...tmpPosts, {postID: post.postID, title: post.title, created: post.created}];
          }
        });
        setState("postsList", tmpPosts);
        setState("notesList", tmpNotes);
      });
    }
  }, [posts, notes, setState, timelineService], [posts, notes]);

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "classbookHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>
              {t('classbook')}
            </p>
            <span data-cy={isCypress() ? `className` : null}>
              {htmlParse(className)} 
            </span>
            <div>
              {
                record.notes && (
                  <Tooltip title={record.notes} classes={{ tooltip: classes.customWidth }} arrow>
                    <span className={classes.notesIcon}><SVG src="clipboard"/></span>
                  </Tooltip>
                )
              }
              {
                record.visit && (
                  <Tooltip title={record.visit} classes={{ tooltip: classes.customWidth }} arrow>
                    <span className={classes.visitIcon}><SVG src="briefcase-outlined"/></span>
                  </Tooltip>
                )
              }     
            </div> 
          </div>
          <div className={classes.buttons}>
            {
              isEditable ? (
                <IconButton className={classes.button} onClick={handleEdit} tooltip={t('edit_record')} tooltipPosition='bottom' dataCy="editButton">
                  <SVG src="pencil"/>
                </IconButton>
              ) : null
            }
            <CloseButton className={classes.button} onClick={handleClose} dataCy="timesButton"/>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.info}>
            {
              classbookType === "weekly" ? (
                <div>
                  <span data-cy={isCypress() ? `currentWeek` : null}>{t('week')} {record.week}. ({DateFormat(record.dateFrom, "default", languageData, t)} - {DateFormat(record.dateTo, "default", languageData, t)})</span>
                  <p></p>
                </div>
              ) : (
                <>
                  <div>
                    <span data-cy={isCypress() ? `currentDayMonth` : null}>{DateFormat(moment(curDate), "daymonth", languageData, t)}</span>
                    <p></p>
                  </div>
                  <div>
                    <span>{t('attendance')}</span>
                    <p>
                      <Tooltip title={`${t("morning")}: ${record.attendance ? record.attendance.am : 0} \n${t("afternoon")}: ${record.attendance ? record.attendance.pm : 0} \n${t("absents")}: ${record.absence ? record.absence.withoutApology : 0}`} classes={{ tooltip: classes.mediumWidth }} arrow>
                        <span data-cy={isCypress() ? `attendance` : null}>{record.attendance ? record.attendance.am : 0}/{record.attendance ? record.attendance.pm : 0}/{record.absence ? record.absence.withoutApology : 0}</span>
                      </Tooltip>
                    </p>
                  </div>
                  <div>
                    <span>{t('excuse_notes')}</span>
                    <p data-cy={isCypress() ? `absence` : null}>{record.absence ? record.absence.withApology : 0}</p>
                  </div>
                </>
              )
            }
          </div>
          <div className={classes.tabsWrapper}>
            <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
          </div>
          <div className={classes.detail}>
          {
            state.viewMode === "educational_activities" ? (
              <>
                {
                  (record.morning && record.morning !== "") ? (
                    <>
                      <span>
                        {t('morning_educational_activities')}
                      </span>
                      <p data-cy={isCypress() ? `morning` : null}>
                        {htmlParse(record.morning)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.afternoon && record.afternoon !== "") ? (
                    <>
                      <span>
                        {t('afternoon_educational_activities')}
                      </span>
                      <p data-cy={isCypress() ? `afternoon` : null}>
                        {htmlParse(record.afternoon)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.notes && record.notes !== "") ? (
                    <>
                      <span>
                        {t('additional_notes')}
                      </span>
                      <p data-cy={isCypress() ? `notes` : null}>
                        {htmlParse(record.notes)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.interestActivities && record.interestActivities !== "") ? (
                    <>
                      <span>
                        {t('interest_activities')}
                      </span>
                      <p data-cy={isCypress() ? `interestActivities` : null}>
                        {htmlParse(record.interestActivities)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.relaxationRecreationalActivities && record.relaxationRecreationalActivities !== "") ? (
                    <>
                      <span>
                        {t('relaxation_recreational_activities')}
                      </span>
                      <p data-cy={isCypress() ? `relaxationReacreationalActivities` : null}>
                        {htmlParse(record.relaxationRecreationalActivities)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.preparationTeaching && record.preparationTeaching !== "") ? (
                    <>
                      <span>
                        {t('preparation_teaching')}
                      </span>
                      <p data-cy={isCypress() ? `preparationTeaching` : null}>
                        {htmlParse(record.preparationTeaching)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.otherActivities && record.otherActivities !== "") ? (
                    <>
                      <span>
                        {t('other_activities')}
                      </span>
                      <p data-cy={isCypress() ? `otherActivities` : null}>
                        {htmlParse(record.otherActivities)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.internalNote && record.internalNote !== "") ? (
                    <>
                      <span>
                        {t('internal_note')}
                      </span>
                      <p data-cy={isCypress() ? `internalNote` : null}>
                        {htmlParse(record.internalNote)}
                      </p>
                    </>
                  ) : null
                }
                {
                  educational_activities.length === 0 ? (
                    <p data-cy={isCypress() ? `noresults` : null}>
                      {t('no_results_found')}
                    </p>
                    ) : null
                }
              </>
            ) : state.viewMode === "posts" ? (
              <>
                {
                  posts.length === 0 ? (
                    <p data-cy={isCypress() ? `noresults` : null}>
                      {t('no_results_found')}
                    </p>
                  ) : state.postsList.map((post: any, key: any) => (
                    <div className={classes.post} key={`k_${key}`} data-cy={isCypress() ? `post${post.postID}` : null}>
                      <span className={classes.link} onClick={(e: any) => handleTimeline(e, post.postID)}>
                        {post.title}
                      </span>
                      <p>{DateFormat(moment(post.created), "timeline", languageData, t)}</p>
                    </div>
                  ))
                }
              </>
            )  : state.viewMode === "notes" ? (
              <>
                {
                  notes.length === 0 ? (
                    <p data-cy={isCypress() ? `noresults` : null}>
                      {t('no_results_found')}
                    </p>
                  ) : state.notesList.map((post: any, key: any) => (
                    <div className={classes.post} key={`k_${key}`} data-cy={isCypress() ? `note${post.postID}` : null}>
                      <span className={classes.link} onClick={(e: any) => handleTimeline(e, post.postID)}>
                        {post.title}
                      </span>
                      <p>{DateFormat(moment(post.created), "timeline", languageData, t)}</p>
                    </div>
                  ))
                }
              </>
            ) : state.viewMode === "other" ? (
              <>
                {
                  (record.theme && record.theme !== "") ? (
                    <>
                      <span>
                        {t('theme')}
                      </span>
                      <p data-cy={isCypress() ? `theme` : null}>
                        {htmlParse(record.theme)}
                      </p>
                    </>
                  ) : null
                }
                {
                  (record.visit && record.visit !== "") ? (
                    <>
                      <span>
                        {t('record_visits')}
                      </span>
                      <p data-cy={isCypress() ? `visit` : null}>
                        {htmlParse(record.visit)}
                      </p>
                    </>
                  ) : null
                }
                {
                  other.length === 0 ? (
                    <p data-cy={isCypress() ? `noresults` : null}>
                      {t('no_results_found')}
                    </p>
                    ) : null
                }
              </>
            ) : null
          }
          </div>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleClose} dataCy="closeButton">
            {t('close')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default ClassbookDetailModal;