import * as UserService from '../../../../services/user.service';
import { createUserSettings } from 'src/utils/useUser';
import { setLanguage } from 'src/store/actions/language.actions';
import { setSideContent, setTheme } from 'src/store/actions/layout.actions';
import { setUserSettings } from 'src/store/actions/user.actions';

const getUserTheme = (data: any) => {
  const isCustomizations = data.filter((item: any) => item.key === "customizations").length !== 0;
  if(isCustomizations) {
    const customizationsData = data.find((item: any) => item.key === "customizations").value;
    const isTheme = customizationsData.filter((item: any) => item.key === "theme").length !== 0;
    if(isTheme) {
      return customizationsData.find((item: any) => item.key === "theme").value;
    } else {
      return 'light';
    }
  } else {
    return 'light';
  }
};

const getUserLanguage = (data: any) => {
  const isCustomizations = data.filter((item: any) => item.key === "customizations").length !== 0;
  if(isCustomizations) {
    const customizationsData = data.find((item: any) => item.key === "customizations").value;
    const isLanguage = customizationsData.filter((item: any) => item.key === "language").length !== 0;
    if(isLanguage) {
      return customizationsData.find((item: any) => item.key === "language").value;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getUserSideContent = (data: any) => {
  const isCustomizations = data.filter((item: any) => item.key === "customizations").length !== 0;
  if(isCustomizations) {
    const customizationsData = data.find((item: any) => item.key === "customizations").value;
    const isApp = customizationsData.filter((item: any) => item.key === "app").length !== 0;
    if(isApp) {
      const isSideContent = customizationsData.find((item: any) => item.key === "app").value.filter((item: any) => item.key === "sidecontent").length !== 0;
      if(isSideContent) {
        return customizationsData.find((item: any) => item.key === "app").value.find((item: any) => item.key === "sidecontent").value;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const loadUserSettings = async (dispatch: any, userData: any) => {
  try {
    const result: any = await UserService.getUserSetting();
    if(result && result.data) {
      const userSettingsFromApi = result.data;
      const userSettings = createUserSettings(userData, userSettingsFromApi);
      const theme = getUserTheme(userSettings);
      const language = getUserLanguage(userSettings);
      const sideContent = getUserSideContent(userSettings);
      dispatch(setUserSettings(userSettings));
      if(language) {
        dispatch(setLanguage(language));  
      }
      dispatch(setTheme(theme));
      dispatch(setSideContent(sideContent));
      return userSettings;
    } else {
      return false;
    }
  } catch {
    return false;
  };   
};

export default loadUserSettings;