import config from 'src/constants/config';
import HttpService from '../http.service';

const getPinterestToken = () => {
  return HttpService.get(`pinterest/token`, {}, {});
};

const getPinterestPins = (params: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        if(value) {
          query = query + key + "=" + value + "&";
        }
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`pinterest/pins${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const getPinterestBoards = (params: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        if(value) {
          query = query + key + "=" + value + "&";
        }
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`pinterest/boards${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const getPinterestBoard = (boardID: any) => {
  return HttpService.get(`pinterest/boards/${boardID}?board_id=${boardID}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const getPinterestBoardPins = (boardID: any, params: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + `?board_id=${boardID}&`;
      for (const [key, value] of Object.entries(params)) {
        if(value) {
          query = query + key + "=" + value + "&";
        }
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`pinterest/boards/${boardID}/pins${query ? `${query}`: ""}`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const getPinterestUserAccount = () => {
  return HttpService.get(`pinterest/user_account`, { 'Accept': 'application/ld+json', 'Content-Type': 'application/ld+json' }, {});
};

const getTwigseeBlog = (params: any) => {
  const twigseeUrl = config.WEBSITE;
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?postType=blog";
      for (const [key, value] of Object.entries(params)) {
        if(value) {
          query = query + key + "=" + value + "&";
        }
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.getRoot(twigseeUrl, `feed${query ? `${query}`: ""}`, {}, {});
};

const getTwigseeBlogTags = () => {
  const twigseeUrl = config.WEBSITE;
  return HttpService.getRoot(twigseeUrl, `wp-json/wp/v2/tags?taxonomy=post_tag&lang=cs&per_page=100`, {}, {});
};

export {
  getPinterestToken,
  getPinterestPins,
  getPinterestBoards,
  getPinterestBoard,
  getPinterestBoardPins,
  getPinterestUserAccount,
  getTwigseeBlog,
  getTwigseeBlogTags,
};