import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  childSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    maxHeight: '100%',
    height: '100%',
  },
  childrenSelect: {
    overflow: 'auto',
    borderRadius: '24px',
    height: '100%',
    maxHeight: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& > div:last-of-type': {
        flex: '1 1 auto',
      },
    },
  },
  customContainer: {
    backgroundColor: theme.colors.grey[100],
    maxHeight: '100%',
    overflow: 'auto',
  },
  customTopWrapper: {
    position: 'sticky',
    top: '-0px',
    zIndex: '2',
  },
  footer: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    height: '42px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
}));

type ChildSelectType = {
  schoolID: any;
  selectedChildren: any;
  onSelect: any;
  onBack: any;
};

const ChildSelect: React.FunctionComponent<ChildSelectType> = ({ schoolID, selectedChildren, onSelect, onBack }) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useStates({
    selectedChildren: selectedChildren,
    isReload: Symbol(),
  });

  const handleChangeChildren = (value: any) => {
    setState("selectedChildren", value);
  };

  const handleClear= () => {
    setState("selectedChildren", []);
    setState("isReload", Symbol());
  };

  const handleChange = () => {
    onSelect(state.selectedChildren);
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <div className={classes.childSelectWrapper}>
      <ChildrenSelect
        defaultSchools={[{schoolID: schoolID}]}
        selectedChildren={state.selectedChildren}
        setSelectedChildren={handleChangeChildren}
        mode="select"
        isAllowArchived={false}
        isAllowArchivedToggle={true}
        isAllowInactiveClasses={true}
        isSelectAll={true}
        isSelectInAllClass={true}
        isMultipleSelect={true}
        validateMultipleSchool={true}
        customCombineMessage={t('cannot_combine_children_school')}
        customClasses={{childrenSelect: classes.childrenSelect, container: classes.customContainer, topWrapper: classes.customTopWrapper}}
        isReload={state.isReload}
      />
      <div className={classes.footer}>
        <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
        <div className={classes.buttons}>
          <NormalButton buttonType="clear" onClick={handleClear} disabled={state.selectedChildren.length === 0}>
            {t('clear')}
          </NormalButton>
          <NormalButton buttonType='primary' onClick={handleChange} disabled={state.selectedChildren.length === 0}>
            {t('continue')}
          </NormalButton>
        </div>
      </div>
    </div>
  );
};

export default ChildSelect;