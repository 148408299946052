import CircleIcon from '@mui/icons-material/Circle';
import htmlParse from 'html-react-parser';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  size?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& > span': {
      fontSize: '13px',
      color: theme.colors.grey[650],
    },
  },
  attendanceInfoItem: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    width: (props: Props) => (props.size / 1.75) + 'px',
    height: (props: Props) => (props.size / 1.75) + 'px',
    borderRadius: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: (props: Props) => ((props.size / 1.75) - 2) + 'px',
      height: (props: Props) => ((props.size / 1.75) - 2) + 'px',
      color: 'inherit',
    }, 
  },
  attendanceAbsentPartAM: {
    position: 'relative',
    top: '0px',
    left: (props: Props) => '-' + ((((props.size / 1.75) - 2) / 2) / 2) + 'px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (((props.size / 1.75) - 2) / 2) + 'px',
  },
  attendanceAbsentPartPM: {
    position: 'absolute',
    top: '0px',
    right: '1px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: (props: Props) => (((props.size / 1.75) - 2) / 2) + 'px',
    '& > svg': {
      marginLeft: (props: Props) => '-' + (((props.size / 1.75) - 2) / 2) + 'px',
    },
  },
  attendanceImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '15px',
    height: '15px',
  },
}));

type AttendanceInfoType = {
  morningAttendanceStatusId: any;
  afternoonAttendanceStatusId: any;
  classAttendanceStatus: any;
  text?: any;
  size?: any;
};

const AttendanceInfoItem: React.FunctionComponent<AttendanceInfoType> = ({ morningAttendanceStatusId, afternoonAttendanceStatusId, classAttendanceStatus, text, size = 56 }) => {
  const classes = useStyles({
    size: size,
  });
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.attendanceInfoItem}>
      {
        morningAttendanceStatusId != null ? (
          <div className={classes.attendanceAbsentPartAM} style={{color: classAttendanceStatus?.find((status: any) => status.id === morningAttendanceStatusId)?.color}}>
            <CircleIcon/>
          </div>
        ) : null
      }
      {
        afternoonAttendanceStatusId != null ? (
          <div className={classes.attendanceAbsentPartPM} style={{color: classAttendanceStatus?.find((status: any) => status.id === afternoonAttendanceStatusId)?.color}}>
            <CircleIcon/>
          </div>
        ) : null
      }
      {
        (classAttendanceStatus?.find((status: any) => status.id === morningAttendanceStatusId)?.image || classAttendanceStatus?.find((status: any) => status.id === afternoonAttendanceStatusId)?.image) &&
          (<img className={classes.attendanceImage} src={classAttendanceStatus?.find((status: any) => status.id === morningAttendanceStatusId)?.image || classAttendanceStatus?.find((status: any) => status.id === afternoonAttendanceStatusId)?.image} alt={classAttendanceStatus?.find((status: any) => status.id === morningAttendanceStatusId)?.name || classAttendanceStatus?.find((status: any) => status.id === afternoonAttendanceStatusId)?.name} />)
      }
      </div>
      {
        text ? (
          <span>{htmlParse(text)}</span>
        ) : null
      }
    </div>
  );
}

export default AttendanceInfoItem;