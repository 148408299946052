import MuiPopper from '@mui/material/Popper';
import React from 'react';
import { Collapse, Fade, Grow, Slide, Zoom } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  popper: {
    backgroundColor: 'transparent',
    display: 'flex',
    zIndex: theme.zIndex.popperWrapper,
  },
}));

type PopperType = {
  children: any;
  open?: boolean;
  anchorEl: any;
  component?: any;
  components?: any,
  container?: any,
  disablePortal?: boolean,
  keepMounted?: boolean,
  modifiers?: any,
  placement?: 'auto-end' | 'auto-start' | 'auto' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top',
  popperOption?: any,
  popperRef?: any,
  slotProps?: any,
  slots?: any,
  transition?: any,
  transitionEffect?: 'collapse' | 'fade' | 'grow' | 'slide' | 'zoom',
  className?: any,
};

const Popper: React.FunctionComponent<PopperType> = ({ children, open = false, anchorEl, component, components, container, disablePortal, keepMounted, modifiers, placement = 'top', popperOption, popperRef, slotProps, slots, transition, transitionEffect, className }) => {

  const classes = useStyles();

  const popperContent = ({ TransitionProps }: any) => (
    <>
      {
        transitionEffect === "collapse" ? (
          <Collapse className={className ? className : null} {...TransitionProps} timeout={350}>
            {children}
          </Collapse>
        ) : transitionEffect === "fade" ? (
          <Fade className={className ? className : null} {...TransitionProps} timeout={350}>
            {children}
          </Fade>
        ) : transitionEffect === "grow" ? (
          <Grow className={className ? className : null} {...TransitionProps} timeout={350}>
            {children}
          </Grow>
        ) : transitionEffect === "slide" ? (
          <Slide className={className ? className : null} {...TransitionProps} timeout={350}>
            {children}
          </Slide>
        ) : transitionEffect === "zoom" ? (
          <Zoom className={className ? className : null} {...TransitionProps} timeout={350}>
            {children}
          </Zoom>
        ) : (
          <div className={className ? className : null}>
            {children}
          </div>
        )
      }
    </>
  );

  return (
    <MuiPopper className={classes.popper} open={open} anchorEl={anchorEl} component={component} components={components} container={container} disablePortal={disablePortal} keepMounted={keepMounted} modifiers={modifiers} placement={placement} popperOptions={popperOption} popperRef={popperRef} slotProps={slotProps} slots={slots} transition={transition}>
      {popperContent}
    </MuiPopper>
  );
}

export default Popper;