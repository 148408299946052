import * as UserService from '../../../../../services/user.service';
import Banner from 'src/components/Layouts/Banner';
import NormalButton from '../../../../../components/Buttons/NormalButton';
import React from 'react';
import Switch from 'src/components/Forms/Switch';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../../../utils/useUser';
import { setUserObject } from '../../../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useState, useStates } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  privacyWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  privacy: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    gap: '8px',
  },
  switch: {
    width: '100%',
  },
  banner: {
    width: 'calc(40% - 40px)',
    maxWidth: '100%',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      borderRadius: '0px',
    },
  },
}));

const PrivacySettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);

  const userInfo: any = {
    public: userData.userObject.public,
  };

  const [values, setValues, setAllValues] = useStates(userInfo);
  const [initialValues, setInitialValues] = useState(userInfo);
  const [isEdit, setIsEdit] = useState(false);

  const isChanged = JSON.stringify(values) !== JSON.stringify(initialValues);

  const handleSwitch = (name: any, value: any) => {
    setValues(name, value);
  };

  const handleEdit = () => {
    if(isEdit) {
      setAllValues(initialValues);
    }
    setIsEdit(!isEdit);
  };

  const saveChanges = () => {
    let payload: any = {};
    Object.keys(values).forEach((key: any) => {
      if(values[key as keyof typeof values] !== initialValues[key as keyof typeof values]) {
        payload[key] = values[key as keyof typeof values];
      }
    });
    if(Object.keys(payload).length > 0) {
      UserService && UserService.changeUserData(payload).then((result: any) => {
        if(result.status === 200) { 
          let newUser = userData.userObject;
          Object.keys(payload).forEach((key: any) => {
            newUser = {...newUser, [key]: payload[key as keyof typeof values]};
          });
          dispatch(setUserObject(newUser));
          setInitialValues(values);
          setIsEdit(false);
          createNotification(t('profile_updated'), 'success');
        } else {
          createNotification(t('something_went_wrong'), 'error');
        }
      }).catch((e: any) => {
        createNotification(t('something_went_wrong'), 'error');
      });
    }
  };
  
  return (
    <div className={classes.privacyWrapper}>
      <div className={classes.privacy}>
        {
          (getUserRole(userData.userObject.roleType) === "parent" && isEdit) ? (
            <>
              {
                Object.keys(values).map((value: any, key: any) => (
                  <Switch className={classes.switch} label={Boolean(values[value as keyof typeof values]) ? t('yes') : t('no')} labelTop={t(value)} name={value} checked={Boolean(values[value as keyof typeof values])} onChange={(name: any, val: any) => handleSwitch(name, val)} key={`k_${key}`}/>
                ))                 
              }
              <div className={classes.buttons}>
                <NormalButton buttonType="secondary" onClick={handleEdit}>{t('cancel')}</NormalButton>
                <NormalButton buttonType="primary" onClick={saveChanges} disabled={!isChanged}>{t('save_changes')}</NormalButton>
              </div>
            </>
          ) : (
            <>
              {
                Object.keys(values).map((value: any, key: any) => values[value as keyof typeof values] !== "" ? (
                  <div className={classes.inputWrapper} key={`k_${key}`}>
                    <label>{t(value)}</label>
                    <span>{Boolean(values[value as keyof typeof values]) ? t('yes') : t('no')}</span>
                  </div>
                ) : null)                 
              }
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <NormalButton buttonType="primary" onClick={handleEdit}>{t('edit_settings')}</NormalButton>
                ) : null
              }
            </>
          )
        }
      </div>
      <Banner className={classes.banner} icon={true} type='info'>
        {t(`public_detail`)}
      </Banner>
    </div>
  );
};

export default PrivacySettings;