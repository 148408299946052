import config from 'src/constants/config';
import Resizer from 'react-image-file-resizer';
import { base64toBlob, blobToDataUrl, dataUrlToBase64 } from './useFunctions';

const processImage = async (base64: any, fileName: string, fileType: string) => {
  const getNewImageType = (fileType: string) => {
    if(fileType === "image/png") return "PNG";
    else if(fileType === "image/gif") return "GIF";
    else return config.IMAGE_DEFAULT_FORMAT;
  };
  const getNewFileName = (fileName: string, fileType: string, newFileType: string) => {
    const getOldFileType = fileType.split("/")[1];
    const newFileName = fileName.replace(`.${getOldFileType}`,`.${newFileType.toLowerCase()}`);
    return newFileName;
  };
  const processFile = (file: any, newFileType: string) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, config.IMAGE_MAX_WIDTH,  config.IMAGE_MAX_HEIGHT, newFileType, config.IMAGE_QUALITY, 0, (uri: any) => { resolve(uri); }, "blob");
    });
  };
  const file = base64toBlob(base64, fileType);
  const newFileType = getNewImageType(fileType);
  const newFileName = getNewFileName(fileName, fileType, newFileType);
  const outputBlob: any = await processFile(file, newFileType);
  const newFileSize = outputBlob.size;
  const outputDataUrl = await blobToDataUrl(outputBlob);
  const outputBase64 = dataUrlToBase64(outputDataUrl);
  return [outputBase64, newFileName, newFileSize, `image/${newFileType.toLowerCase()}`];
};

export {
  processImage,
};