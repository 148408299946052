import CloseButton from 'src/components/Buttons/CloseButton';
import FileInput from 'src/components/Forms/FileInput';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setFileInputModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  text: {
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '10px',
  },
}));

const FileInputModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);

  const mediaData = modalsData.fileInputModal.data;
  const mediaLimit = modalsData.fileInputModal.mediaLimit;
  const onChange = modalsData.fileInputModal.onChange;
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      data: null,
      mediaLimit: null,
      onChange: null,
    };
    dispatch(setFileInputModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const [state, setState] = useStates({
    media: mediaData,
    defaultMedia: mediaData,
  });

  const allowedFiles = ["image", "attachment", "video"];
  const refMedia = useRef(state.media);

  const handleMedias = (value: any) => {
    setState("media", value);
    refMedia.current = value;
  };

  const onCancel = () => {
    onCloseModal();
  };

  const onSave = () => {
    onChange(refMedia.current);
    onCloseModal();
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "fileInputModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('attachments')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <FileInput files={state.media} onChange={handleMedias} allowed={allowedFiles} disabled={state.isLoading} mediaLimit={mediaLimit}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType='secondary' onClick={onCancel}>{t('cancel')}</NormalButton>
          <NormalButton buttonType='primary' onClick={onSave} disabled={JSON.stringify(state.defaultMedia) === JSON.stringify(state.media)}>{t('continue')}</NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default FileInputModal;