import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import SchoolButton from 'src/components/Buttons/SchoolButton';

const useStyles = createUseStyles((theme: any) => ({
  schoolsWrapper: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: '16px',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    height: 'fit-content',
    position: 'relative',
    width: 'fit-content',
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
}));

type SchoolsPickerType = {
  schoolsData: any;
  setCurrentSchool: any;
  useDispatch?: any;
  isDisabled?: any;
};

const SchoolsPicker: React.FunctionComponent<SchoolsPickerType> = ({schoolsData = null, setCurrentSchool = null, useDispatch = false, isDisabled = false}) => {
  
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const schools = schoolsData.map((item: any) => { return { schoolID: item.schoolID }; });
  const dispatch = useAppDispatch();

  const getSchoolData = (schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  };

  const setCurSchool = (school: any) => {
    if(useDispatch) {
      dispatch(setCurrentSchool(school));
    } else {
      setCurrentSchool(school);
    }
  };
  const setCurrentSchoolFromList = (schoolID: any) => {
    setCurSchool(getSchoolData(schoolID));
  };

  return (
    <div className={classes.schoolsWrapper}>
      {
        schools.length > 0 ? schools.map((school: any, key: any) => (
          <SchoolButton key={`k_${key}`} schoolID={school.schoolID} onClick={setCurrentSchoolFromList} isWhite={true} disabled={isDisabled}/>
        )) : null
      }
    </div>
  );
}

export default SchoolsPicker;