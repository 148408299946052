import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  startSelectWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.chip,
    justifyContent: 'center',
    width: 'calc(100% - 8px)',
    maxWidth: '100%',
    height: '100%',
    gap: '4px',
    margin: '0 4px',
    overflow: 'auto',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '256px',
    maxWidth: '100%',
    height: '100%',
    flex: '1 1 350px',
    gap: '4px',
    textTransform: 'unset',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderRadius: '0',
    transition: 'color 0.25s',
    position: 'relative',
    '&:hover': {
      color: theme.colors.primaryBlue[500],
      backgroundColor: theme.colors.grey[100],
    },
    '& svg': {
      width: '64px',
      height: '64px',
    },
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    '& > p': {
      fontSize: '16px',
      fontWeight: '400',
      color: theme.colors.grey[500],
      width: '50%',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        width: '80%',
      },
    },
  },
}));

type StartSelectType = {
  onSelect: any;
};

const StartSelect: React.FunctionComponent<StartSelectType> = ({ onSelect }) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (value: any) => {
    onSelect(value);
  };

  return (
    <div className={classes.startSelectWrapper}>
      <Button className={classes.block} onClick={() => handleChange("new")}>
        <SVG src="plus"/>
        <span>
          {t('press_report_create_new')}
        </span>
        <p>
          {t('press_report_create_new_detail')}
        </p>
      </Button>
      <Button className={classes.block} onClick={() => handleChange("template")}>
        <SVG src="pencil"/>
        <span>
          {t('press_report_use_template')}
        </span>
        <p>
          {t('press_report_use_template_detail')}
        </p>
      </Button>
    </div>
  );
};

export default StartSelect;