import slice from '../slices/data.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setDataEmployees = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setEmployees(data));
  }
};
export const setDataEmployeesID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setEmployeesID(data));
  }
};
export const setDataChildren = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildren(data));
  }
};
export const setDataChildrenID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setChildrenID(data));
  }
};
export const setDataClasses = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClasses(data));
  }
};
export const setDataClassesID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassesID(data));
  }
};
export const setDataSchools = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSchools(data));
  }
};
export const setDataSchoolsID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSchoolsID(data));
  }
};
export const setDataUsers = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setUsers(data));
  }
};
export const setDataUsersID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setUsersID(data));
  }
};
export const addDataEmployee = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addEmployee(data));
  }
};
export const addDataChild = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addChild(data));
  }
};
export const addDataClass = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addClass(data));
  }
};
export const addDataSchool = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addSchool(data));
  }
};
export const addDataUser = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addUser(data));
  }
};
export const addDataUserID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.addUserID(data));
  }
};