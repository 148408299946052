import AuthenticatedImage from '../../Items/AuthenticatedImage';
import DateFormat from 'src/utils/dateFormat';
import htmlParse from 'html-react-parser';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  blogItemCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '24px',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: 'calc(100vw - 48px)',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
  },
  image: {
    width: 'auto',
    height: '165px',
    maxWidth: 'unset',
    borderRadius: '6px',
    cursor: 'pointer',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '18px',
    fontWeight: 'bold',
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  detail: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    fontSize: '14px',
    fontWeight: '500',
    color: theme.colors.primaryBlue[300],
  },
  tags: {
    display: 'flex',
    gap: '4px',
    fontWeight: '500',
    '& > span': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.primaryBlue[300],
      borderRadius: '40px',
      color: theme.colors.primaryBlue[300],
      padding: '2.5px 8px',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      textTransform: 'uppercase',
    },
  },
  description: {
    fontSize: '16px',
  },
  button: {
    '& > span': {
      fontWeight: '700',
      textTransform: 'uppercase',
    },
  },
}));

type ItemsType = {
  item: any;
  onClick: any;
};

const BlogCard: React.FunctionComponent<ItemsType> = ({ item, onClick }) => {

  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);
  const { t } = useTranslation();
  const guid = item.guid.replace("https://www.twigsee.com/?p=","");
  const description = item.description.replaceAll("[&#8230;]", "...");

  const handleOnClick = () => {
    onClick(item);
  };

  return (
    <div className={classes.blogItemCard} data-cy={isCypress() ? 'blogCard' + guid : null}>
      <AuthenticatedImage className={classes.image} thumbLink={item.image} onClick={handleOnClick}/>
      <span className={classes.title} data-cy={isCypress() ? 'title' : null} onClick={handleOnClick}>
        {htmlParse(item.title)}
      </span>
      <div className={classes.detail}>
        <span>
          {DateFormat(moment(item.pubDate), "default", languageData, t)}
        </span>
        <span>
          |
        </span>
        <span>
          {htmlParse(item.author)}
        </span>
      </div>
      <div className={classes.tags}>
        {Array.isArray(item.tag) ? (
          <>
            {item.tag.map((subItem: any, key: any) => (
              <span key={`k_${key}`}>{subItem}</span>
            ))}
          </>
        ) : (
          <span>{item.tag}</span>
        )}
      </div>
      <p className={classes.description}>
        {htmlParse(description)}
      </p>
      <NormalButton className={classes.button} buttonType='darkBlueOutlined' onClick={handleOnClick}>
        {t('blog_full_article')}
      </NormalButton>
    </div>
  );
}

export default BlogCard;