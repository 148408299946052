import BootstrapIcon from 'src/components/Icons/BootstrapIcon';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import hexToRgba from 'hex-to-rgba';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isActive: any;
};

const useStyles = createUseStyles((theme: any) => ({
  bootstrapIconPickerWrapper: {
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fit, minmax(72px, 1fr))',
    justifyItems: 'center',
    position: 'relative',
    gap: '16px',
    overflow: (props: Props) => {
      if(props.isActive) return 'auto';
      else return 'hidden';
    },
  },
  buttonWrapper: {
    display: 'flex',
    width: '72px',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    '& > p': {
      marginTop: '4px',
      marginBottom: '0px',
      fontSize: '12px',
      whiteSpace: 'break-spaces',
      maxWidth: '100%',
      textAlign: 'center',
      color: '#CFD2E3',
      transition: 'color 0.25s',
      wordBreak: 'break-word',  
    },
    '&.selected': {
      '& > button': {
        backgroundColor: theme.colors.primaryBlue[500] + ' !important',
      },
      '& > p': {
        color: theme.colors.primaryBlue[500] + ' !important',
      },
    },
    '&.inactive': {
      '& > button': {
        backgroundColor: '#CFD2E3 !important',
        '& > img': {
          filter: 'contrast(0%) brightness(0%) invert(100%)',
        },
      },
      '& > p': {
        color: '#CFD2E3 !important',
      },
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '56px',
    minWidth: '56px',
    minHeight: '56px',
    maxWidth: '56px',
    maxHeight: '56px',
    backgroundColor: '#CFD2E3',
    borderRadius: '16px',
    transition: 'background 0.25s',
  },
  inactiveZone: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.inactiveZone,
    backdropFilter: 'blur(2px)',
  },
}));

type BootstrapIconPickerType = {
  items: any;
  value?: any;
  values?: any;
  valueCompare: any;
  onChange: any;
  className?: any;
  buttonSelected?: boolean;
  buttonInactive?: boolean;
  buttonOpacity?: any;
};

const BootstrapIconPicker: React.FunctionComponent<BootstrapIconPickerType> = ({items, value, values, valueCompare, onChange, className, buttonSelected = true, buttonInactive = false, buttonOpacity = 1}) => {

  const { t } = useTranslation();
  const iconRef: any = useRef(null);
  const iconsScroll: any = useRef(null);
  const browserData = useAppSelector((state: any) => state.browser);

  const [state, setState] = useStates({
    isActive: false,
    count: 0,
  });

  const classes = useStyles({
    isActive: state.isActive,
  });

  useEffect(() => {
    if(iconRef.current && state.isActive) {       
      iconsScroll.current.scrollTo({
        top: iconRef.current.offsetTop - iconsScroll.current.clientHeight + 192,
      });
    }
  }, [iconRef, state.isActive]);

  useEffect(() => {
    const iconsWrapperWidth: any = iconsScroll.current.clientWidth;
    const count = Math.floor(iconsWrapperWidth / 88) * 3;
    setState("count", count);
  }, [browserData.width, setState], [browserData.width]);

  const handleClickAway = () => {
    iconsScroll.current.scrollTo({
      top: -16,
    });
    setState("isActive", false);
  };

  const handleClickActivate = () => {
    setState("isActive", true);
  };

  const selectedItemIndex = items.findIndex((item: any) => item[valueCompare] === value);

  const itemsList = state.isActive ? items : items.slice(Math.round(Math.max(Math.min(selectedItemIndex - state.count / 2, items.length - state.count), 0) / (state.count / 3)) * (state.count / 3), Math.round((Math.max(Math.min(selectedItemIndex - state.count / 2, items.length - state.count), 0) + state.count) / (state.count / 3)) * (state.count / 3));
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${classes.bootstrapIconPickerWrapper} ${className ? className : null}`} ref={iconsScroll}>
        {
          !state.isActive ? (
            <div className={classes.inactiveZone}>
              <NormalButton onClick={handleClickActivate}>
                {t('stock_select_category_icon')}
              </NormalButton>
            </div>
          ) : null
        }
        {
          itemsList.map((item: any, key: any) => (
            <div className={`${classes.buttonWrapper} ${buttonSelected ? (value ? (item[valueCompare] === value ? 'selected' : null) : values ? (values.indexOf(item[valueCompare]) !== -1 ? 'selected' : null) : null) : null} ${buttonInactive ? (value ? (item[valueCompare] !== value ? 'inactive' : null) : values ? (values.indexOf(item[valueCompare]) === -1 ? 'inactive' : null) : null) : null}`} onClick={() => onChange(item[valueCompare])} key={`k_${key}`} data-cy={isCypress() ? "blockPickerButton" + item[valueCompare] : null} ref={(value ? (item[valueCompare] === value ? iconRef : null) : values ? (values.indexOf(item[valueCompare]) !== -1 ? iconRef : null) : null)}>
              <Button className={classes.button} style={{backgroundColor:  hexToRgba(item.color, buttonOpacity)}} >
                {
                  item.icon ? (
                    <BootstrapIcon iconName={item.icon} size={20} color={item.color}/>
                  ) : null
                }            
              </Button>
              <p style={{color: item.color}}>{item.name}</p>
            </div>
          ))
        }
      </div>
    </ClickAwayListener>
  );
}

export default BootstrapIconPicker;