import CommentCard from 'src/components/Cards/CommentCard';
import EmojiPicker from 'src/components/Layouts/EmojiPicker';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { Button, CircularProgress } from '@mui/material';
import { getUserRole, getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, isKey } from 'src/utils/useFunctions';
import { InView } from 'react-intersection-observer';
import { isCypress } from 'src/utils/useCypress';
import { isMobile } from 'react-device-detect';
import { setComments, setCommentsDeleted, setCommentsEdited, setCommentsReplyTo, setCommentsVisibility, updateComments } from 'src/store/actions/comments.actions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { setTimelinePosts } from 'src/store/actions/timeline.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  commentInputDisabled?: any;
  isMobile?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  timelineComments: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flex: '1 1 auto',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      overflow: 'visible',
    },
  },
  commentsList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    padding: "8px 0",
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
    },
  },
  commentCardInView: {
    width: '100%',
    height: 'auto',
  },
  commentBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    justifyContent: 'center',
    gap: '4px',
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 24px)',
    padding: '12px 12px',
    height: '114px',
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      padding: '4px 8px 8px 8px',
      width: 'calc(100% - 16px)',
    },
  },
  commentBoxHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '30px',
    flex: '0 0 30px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
      '& > span': {
        color: theme.colors.primaryBlue[500],
        fontWeight: '600',
      }
    },
    '& > button': {
      marginLeft: 'auto',
      backgroundColor: theme.colors.grey[200],
    },
  },
  commentInputWrap: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  commentInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1 auto%',
    minHeight: '36px',
    fontSize: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: '10px',
    boxSizing: 'border-box',
    backgroundColor: (props: Props) => {
      if(props.commentInputDisabled) return theme.colors.grey[75];
      else return theme.colors.white;
    },
    color: (props: Props) => {
      if(props.commentInputDisabled) return theme.colors.grey[560];
      else return theme.colors.black;
    },
    height: '100%',
  },
  commentInput: {
    fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
    width: '100%',
    flex: '1 1 auto',
    padding: '0px',
    borderWidth: '0',
    borderRadius: "10px 10px 0 0",
    backgroundColor: theme.colors.white,
    color: 'inherit',
    fontSize: 'inherit',
    height: '100%',
    outline: 'none',
    boxSizing: 'inherit',
    overflow: 'hidden',
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '&:read-only': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[150],
    },
    '& > div': {
      borderRadius: 'inherit',
      borderWidth: 'inherit',
      '& > textarea': {
        borderRadius: 'inherit',
        resize: 'none',
        width: '100%',
      },
    },
  },
  commentInputBar: {
    display: 'flex',
  },
  commentInputTools: {
    display: 'flex',
    gap: '8px',
    padding: '2px 4px',
  },
  commentInputToolWrapper: {
    position: 'relative',
  },
  commentInputToolButton: {
    width: '24px',
    height: '24px',
    minWidth: 'unset',
    padding: '0',
    '& > svg': {
      width: '80%',
      height: '80%',
      color: theme.colors.black,
    },
  },
  commentInputSubmit: {
    display: 'flex',
    gap: '8px',
    padding: '2px 4px',
    backgroundColor: (props: Props) => {
      if(props.isMobile) return theme.colors.grey[300];
      else return '';
    },
    borderRadius: (props: Props) => {
      if(props.isMobile) return '0 0 10px 10px';
      else return '';
    },
    width: (props: Props) => {
      if(props.isMobile) return '100%';
      else return '';
    },
    marginLeft: (props: Props) => {
      if(!props.isMobile) return 'auto';
      else return '';
    },
  },
  commentInputSubmitButton: {
    display: 'flex',
    gap: '8px',
    width: '24px',
    height: '24px',
    minWidth: 'unset',
    padding: '0',
    textTransform: 'unset',
    '& > span': {
      fontSize: '80%',
    },
    '& > svg': {
      width: 'auto',
      height: '80%',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 32px)',
    flex: '1 1 calc(100% - 32px)',
    padding: '16px 0px',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    },
  },
  notFoundWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100% - 133px)',
    padding: '10px 0',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '75%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > svg': {
      flex: '1 1 auto',
    },
    '& > p': {
      margin: '0',
    }
  },
}));

type TimelineCommentsType = {
  uniqueID: any;
  autoFocus?: any;
  className?: any;
  classNameCommentsList?: any;
  classNameCommentBox?: any;
};

const TimelineComments: React.FunctionComponent<TimelineCommentsType> = ({ uniqueID, autoFocus, className, classNameCommentsList, classNameCommentBox }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const commentsService = useAppSelector((state: any) => state.services).commentsService;
  const dataData = useAppSelector((state: any) => state.data);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const commentsData = useAppSelector((state: any) => state.comments);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const postData = timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
  const postID = postData.postID;
  const schoolID = postData.schoolID;
  const comments = commentsData.comments;
  const edited = commentsData.edited;
  const visibility = commentsData.visibility;
  const deleted = commentsData.deleted;
  const replyTo = commentsData.replyTo;
  const commentsEnabled = postData.comments.enable;
  const commentsTotal = postData.comments.total;
  const commentInputRef: any = useRef(null);
  const limit = 15;
  const schoolSettings = userData.schoolSettings;
  const isCommentsEnabled = getSchoolSettings(schoolID, 'modules', schoolSettings).comment ? getSchoolSettings(schoolID, 'modules', schoolSettings).comment.enable : false;
  const isSavingSwitch = useRef(false);

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const defaultState = useMemo(() => { return {
    isLoading: true,
    isProcessing: true,
    isEndOfScroll: false,
    isLoadingMore: false,
    total: postData.comments ? (postData.comments.total ? postData.comments.total : 0) : 0,
    page: 0,
    isEmojiPickerOpen: false,
    useEnterToSend: getUserSetting(userData.userSettings, "addons", ["comments", "comment_sendbyenter"]),
    isSavingSwitch: false,
  }}, [postData.comments, userData.userSettings]);

  const [state, setState, setStates] = useStates(defaultState);

  const classes = useStyles({
    commentInputDisabled: state.isProcessing || !isCommentsEnabled || !commentsEnabled,
    isMobile: isMobile,
  });

  const getCommentData = useCallback((commentID: any) => {
    return comments.filter((item: any) => item.commentID === commentID).length === 0 ? [] : comments.find((item: any) => item.commentID === commentID);
  }, [comments]);

  const updatePostCommentCount = useCallback(() => {
    timelineService && timelineService.listPosts({postID: postID}).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.posts) {
            if(result.data.posts.length === 1) {
              const tempPostData = result.data.posts[0];
              const newTimelineData = timelineData.posts.map((post: any) => {
                if(post.postID === postID) {
                  return { ...post, comments: {...post.comments, total: tempPostData.comments.total} };
                } else {
                  return post;
                }
              });
              dispatch(setTimelinePosts(newTimelineData));
            }
          }
        }
      }
    });
  }, [dispatch, postID, timelineData.posts, timelineService]);

  const updatePostComments = useCallback((count: any) => {
    const newTimelineData = timelineData.posts.map((post: any) => {
      if(post.postID === postID) {
        return { ...post, comments: {...post.comments, total: count} };
      } else {
        return post;
      }
    });
    dispatch(setTimelinePosts(newTimelineData));
  }, [dispatch, timelineData.posts, postID]);

  const updateComment = useCallback((data: any) => {
    const newComment = data[0];
    const newThreadID = newComment.threadID;
    const oldComments = comments;
    let newComments: any = [].concat(oldComments, newComment);
    const newTotal = state.total + 1;
    if(newThreadID === 0) {
      setState("total", newTotal);
      updatePostCommentCount();
    } else {
      newComments = newComments.map((item: any) => {
        if(item.commentID === newThreadID) {
          return {...item, countComments: (item.countComments + 1)};
        } else {
          return item;
        }
      });
      setState("total", newTotal);
      updatePostCommentCount();
    }
    newComments.sort((a: any, b: any) => { return a.commentID - b.commentID; });
    dispatch(setComments(newComments));
    updatePostComments(newTotal);
  }, [comments, dispatch, setState, state.total, updatePostComments, updatePostCommentCount]);

  const editComment = useCallback((data: any) => {
    const newComment = data[0];
    const oldComments = comments;
    const newComments = oldComments.map((item: any) => {
      if(item.commentID === newComment.commentID) {
        return newComment;
      } else {
        return item;
      }
    });
    dispatch(setComments(newComments));
  }, [comments, dispatch]);

  const visibilityComment = useCallback((data: any) => {
    const newComment = data[0];
    const oldComments = comments;
    const newComments = oldComments.map((item: any) => {
      if(item.commentID === newComment.commentID) {
        return newComment;
      } else {
        return item;
      }
    });
    dispatch(setComments(newComments));
  }, [comments, dispatch]);

  const deleteComment = useCallback((data: any) => {
    if(data.length === 0) {
      const newTotal = state.total - 1;
      const oldComments = comments;
      const newComments = oldComments.filter((item: any) => item.commentID !== deleted);
      dispatch(setComments(newComments));
      setState("total", newTotal);
      updatePostCommentCount();
      updatePostComments(newTotal);
    } else {
      const newComment = data[0];
      const oldComments = comments;
      const newComments = oldComments.map((item: any) => {
        if(item.commentID === newComment.commentID) {
          return newComment;
        } else {
          return item;
        }
      });
      dispatch(setComments(newComments));
    }
  }, [comments, deleted, dispatch, setState, state.total, updatePostComments, updatePostCommentCount]);

  const loadComments = useCallback((presetState?: any) => {
    const getState = presetState ? presetState : state;
    if(getState.isLoadingMore) return;
    if(getState.isEndOfScroll) return;
    setState("isLoadingMore", true);
    setState("isProcessing", true);
    let currentPage = getState.page;
    currentPage++;
    commentsService && commentsService.listComments(postID, "post", currentPage, limit).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.comments) {
            if(result.data.comments.length === 0) {
              setState("isEndOfScroll", true);
              setState("isLoadingMore", false);
              setTimeout(() => { 
                setState("isLoading", false);
                setState("isProcessing", false);
              }, 500);        
            } else {
              if(result.data.comments.length < limit) setState("isEndOfScroll", true); 
              dispatch(updateComments(result.data.comments));
              updatePostCommentCount();
              setState("page", currentPage);
              setTimeout(() => {  
                setState("isLoadingMore", false);
                setState("isLoading", false);
                setState("isProcessing", false);
              }, 1000); 
            }
          } else {
            createNotification(t("comments_not_loaded"), "error");
          }
        } else {
          createNotification(t("comments_not_loaded"), "error");
        }
      } else {
        createNotification(t("comments_not_loaded"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("comments_not_loaded"), "error");
    });
  }, [commentsService, postID, setState, state, t, dispatch, updatePostCommentCount]);

  const onScrollView = (inView: any) => {
    if(inView) {
      if(!state.isLoadingMore) loadComments();
    }
  };

  const handleComment = useCallback(() => {
    setState("isProcessing", true);
    const text = commentInputRef.current.value;
    if(edited) {
      if(text.length > 0) {
        commentInputRef.current.value = "";
        const payload = {
          text: text,
        };
        commentsService && commentsService.updateComment(edited, payload).then((result: any) => {
          if(result) {
            if(result.data) {
              if(result.data.comments) {
                editComment(result.data.comments);
                dispatch(setCommentsEdited(0));
                createNotification(t("comment_updated"), "success");
                setState("isProcessing", false);
              } else {
                createNotification(t("comment_not_updated"), "error");
                commentInputRef.current.value = text;
                setState("isProcessing", false);
              }
            } else {
              createNotification(t("comment_not_updated"), "error");
              commentInputRef.current.value = text;
              setState("isProcessing", false);
            }
          } else {
            createNotification(t("comment_not_updated"), "error");
            commentInputRef.current.value = text;
            setState("isProcessing", false);
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("comment_not_updated"), "error");
          commentInputRef.current.value = text;
          setState("isProcessing", false);
        });
      }
    } else if(visibility) {
      if(getUserRole(userData.userObject.roleType) === "director") {
        const newVisibility = !getCommentData(visibility).visibility;
        const payload = {
          commentID: visibility,
          visibility: newVisibility,
        };
        commentsService && commentsService.toggleVisibilityComment(payload).then((result: any) => {
          if(result) {
            if(result.data) {
              if(result.data.comments) {
                visibilityComment(result.data.comments);
                dispatch(setCommentsVisibility(0));
                createNotification(newVisibility ? t("comment_showed") : t('comment_hidden'), "success");
                setState("isProcessing", false);
              } else {
                dispatch(setCommentsVisibility(0));
                createNotification(newVisibility ? t("comment_not_showed") : t('comment_not_hidden'), "error");
                commentInputRef.current.value = text;
                setState("isProcessing", false);
              }
            } else {
              dispatch(setCommentsVisibility(0));
              createNotification(newVisibility ? t("comment_not_showed") : t('comment_not_hidden'), "error");
              commentInputRef.current.value = text;
              setState("isProcessing", false);
            }
          } else {
            dispatch(setCommentsVisibility(0));
            createNotification(newVisibility ? t("comment_not_showed") : t('comment_not_hidden'), "error");
            commentInputRef.current.value = text;
            setState("isProcessing", false);
          }
        }).catch((e: any) => {
          dispatch(setCommentsVisibility(0));
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : (newVisibility ? t("comment_not_showed") : t('comment_not_hidden')), "error");
          commentInputRef.current.value = text;
          setState("isProcessing", false);
        });
      } else {
        createNotification(t('not_access'), "error");
        dispatch(setCommentsVisibility(0));
      }
    } else if(deleted) {
      commentsService && commentsService.deleteComment(deleted).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.comments) {
              deleteComment(result.data.comments);
              dispatch(setCommentsDeleted(0));
              createNotification(t("comment_deleted"), "success");
              setState("isProcessing", false);
            } else {
              createNotification(t("comment_not_deleted"), "error");
              commentInputRef.current.value = text;
              setState("isProcessing", false);
            }
          } else {
            dispatch(setCommentsDeleted(0));
            createNotification(t("comment_not_deleted"), "error");
            commentInputRef.current.value = text;
            setState("isProcessing", false);
          }
        } else {
          dispatch(setCommentsDeleted(0));
          createNotification(t("comment_not_deleted"), "error");
          commentInputRef.current.value = text;
          setState("isProcessing", false);
        }
      }).catch((e: any) => {
        dispatch(setCommentsDeleted(0));
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("comment_not_deleted"), "error");
        commentInputRef.current.value = text;
        setState("isProcessing", false);
      });
    } else {
      if(text.length > 0) {
        commentInputRef.current.value = "";
        const payload = {
          postID: postID,
          commentID: replyTo ? replyTo : null,
          text: text,
        };
        commentsService && commentsService.createComment(payload).then((result: any) => {
          if(result) {
            if(result.data) {
              if(result.data.comments) {
                updateComment(result.data.comments);
                dispatch(setCommentsReplyTo(0));
                createNotification(t("comment_added"), "success");
                setState("isProcessing", false);
              } else {
                createNotification(t("comment_not_added"), "error");
                commentInputRef.current.value = text;
                setState("isProcessing", false);
              }
            } else {
              createNotification(t("comment_not_added"), "error");
              commentInputRef.current.value = text;
              setState("isProcessing", false);
            }
          } else {
            createNotification(t("comment_not_added"), "error");
            commentInputRef.current.value = text;
            setState("isProcessing", false);
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("comment_not_added"), "error");
          commentInputRef.current.value = text;
          setState("isProcessing", false);
        });
      } else {
        setState("isProcessing", false);
      }
    } 
  }, [commentsService, deleted, deleteComment, dispatch, editComment, edited, postID, replyTo, t, updateComment, setState, getCommentData, userData.userObject.roleType, visibility, visibilityComment]);

  const handleOnEnter = (_: any, e: any) => {
    if(e.keyCode === 13 && !e.shiftKey) {
      if(state.useEnterToSend) {
        e.stopPropagation();
        e.preventDefault();
        handleComment();
      }
    }
  };

  const handleSwitchUseEnterToSend = async () => {
    if(isSavingSwitch.current === false) {
      isSavingSwitch.current = true;
      setState("isSavingSwitch", true);
      const value = !state.useEnterToSend;
      const oldValue = state.useEnterToSend;
      const newValue = updateUserSetting(userData.userSettings, "addons", ["comments", "comment_sendbyenter"], value);
      dispatch(setUserSettings(newValue));
      setState("useEnterToSend", value);
      const result = await saveUserSettings(dispatch, userData, "addons", ["comments", "comment_sendbyenter"], value);
      if(result) {
        isSavingSwitch.current = false;
        setState("isSavingSwitch", false);
      } else {
        isSavingSwitch.current = false;
        setState("isSavingSwitch", false);
        const updateSettings = updateUserSetting(userData.userSettings, "addons", ["comments", "comment_sendbyenter"], oldValue);
        dispatch(setUserSettings(updateSettings));
        setState("useEnterToSend", oldValue);
        createNotification(t("user_settings_not_saved"), "error");
      }
    }
  };

  const cancelReply = () => {
    commentInputRef.current.value = "";
    dispatch(setCommentsReplyTo(0));
  };

  const cancelEdit = () => {
    commentInputRef.current.value = "";
    dispatch(setCommentsEdited(0));
  };

  const handleEmojiPicker = () => {
    setState("isEmojiPickerOpen", !state.isEmojiPickerOpen);
  };

  const handleOnEmojiClose = () => {
    setState("isEmojiPickerOpen", false);
  };

  const handleOnEmojiClick = (emojiData: any) => {
    const value = commentInputRef.current.value;
    commentInputRef.current.value = value + emojiData.emoji;
  };

  useEffect(() => {
    dispatch(setComments([]));
    if((commentsEnabled && isCommentsEnabled) || commentsTotal > 0) {
      dispatch(setComments([]));
      dispatch(setCommentsEdited(0));
      dispatch(setCommentsVisibility(0));
      dispatch(setCommentsDeleted(0));
      dispatch(setCommentsReplyTo(0));
      setStates(defaultState);
      loadComments(defaultState);
    } else {
      setState("isLoading", false);
      setState("isEndOfScroll", false);
      setState("isLoadingMore", false);
      setState("isProcessing", false);
    }
    return () => {
      dispatch(setComments([]));
      dispatch(setCommentsEdited(0));
      dispatch(setCommentsVisibility(0));
      dispatch(setCommentsDeleted(0));
      dispatch(setCommentsReplyTo(0));
    }
  }, [postID, commentsService, t, setState, setStates, defaultState, commentsTotal, commentsEnabled, isCommentsEnabled, dispatch, state.page, loadComments, postData], [postID]);

  useEffect(() => {
    if(commentInputRef.current) {
      commentInputRef.current.value = "";
    }
  }, []);

  useEffect(() => {
    if(replyTo !== 0) {
      if(commentInputRef.current) {
        commentInputRef.current.value = "";
        commentInputRef.current.focus();
      }
    }
  }, [replyTo], [replyTo]);

  useEffect(() => {
    if(edited !== 0) {
      const text = getCommentData(edited).text;
      if(commentInputRef.current) {
        commentInputRef.current.value = text;
        commentInputRef.current.focus();
      }
    }
  }, [edited, getCommentData, setState], [edited]);

  useEffect(() => {
    if(visibility !== 0) {
      const onAccept = () => {
        handleComment();
      };
      const onDecline = () => {
        dispatch(setCommentsVisibility(0));
      };
      const settings = {
        isOpen: true,
        title: getCommentData(visibility).visibility ? t('comment_hide') : t('comment_show'),
        content: getCommentData(visibility).visibility ? t('comment_hide_confirm') : t('comment_show_confirm'),
        onAccept: onAccept,
        onDecline: onDecline,
      };
      dispatch(setConfirmModal(settings));
    }
  }, [visibility, dispatch, t, handleComment, getCommentData], [visibility]);

  useEffect(() => {
    if(deleted !== 0) {
      const onAccept = () => {
        handleComment();
      };
      const onDecline = () => {
        dispatch(setCommentsDeleted(0));
      };
      const settings = {
        isOpen: true,
        title: t('comment_delete'),
        content: t('comment_delete_confirm'),
        onAccept: onAccept,
        onDecline: onDecline,
      };
      dispatch(setConfirmModal(settings));
    }
  }, [deleted, dispatch, t, handleComment], [deleted]);
 
  return (
    <div className={`${classes.timelineComments} ${className ? className : ''}`} data-cy={isCypress() ? "timelineComments" : null}>
      {
        state.isLoading ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <>
            {
              comments.filter((item: any) => item.threadID === 0).length === 0 ? (
                <div className={classes.notFoundWrapper}>
                  <NotFound className={classes.notFound} text={t('no_comments_here')}/>
                </div>
              ) : (
                <div className={`${classes.commentsList} ${classNameCommentsList ? classNameCommentsList : ''}`}>
                  {
                    comments.filter((item: any) => item.threadID === 0).map((item: any, key: any) => {
                      return (key === comments.filter((item: any) => item.threadID === 0).length - 1 && !state.isEndOfScroll) ? (
                        <InView key={`k_${key}`} className={classes.commentCardInView} onChange={(inView) => onScrollView(inView)}>
                          <CommentCard uniqueID={uniqueID} commentID={item.commentID}/>
                        </InView>
                      ) : (
                        <CommentCard key={`k_${key}`} uniqueID={uniqueID} commentID={item.commentID}/>
                      )
                    })
                  }
                  {
                    (state.isLoadingMore && !state.isEndOfScroll) ? (
                      <div className={classes.loading}>
                        <CircularProgress className={classes.spinner}/>
                      </div>
                    ) : null
                  }
                </div>
              )
            }
          </>
        )
      }
      {
        isCommentsEnabled && commentsEnabled ? (
          <div className={`${classes.commentBox} ${classNameCommentBox ? classNameCommentBox : ''}`}>
            <div className={classes.commentBoxHeader}>
              <span>
                {
                  (!commentsEnabled || !isCommentsEnabled) ? t('comments_add_disabled') : (replyTo === 0 && edited === 0) ? t('comment_add') : (
                    <>
                      {
                        edited !== 0 ? (
                          <>
                            {t('comment_edit_comment')}
                          </>
                        ) : (
                          <>
                            {t('comment_reply_to_comment')}: <span>{getUserData(getCommentData(replyTo).userID).displayName}</span>
                          </>
                        )
                      }
                    </>
                  )
                }
              </span>
              {
                replyTo !== 0 ? (
                  <IconButton tooltip={t('comment_cancel_reply')} tooltipPosition='left' tooltipMaxWidth={400} size='small' onClick={cancelReply}>
                    <SVG src="close"/>
                  </IconButton>
                ) : null
              }
              {
                edited !== 0 ? (
                  <IconButton tooltip={t('comment_cancel_edit')} tooltipPosition='left' tooltipMaxWidth={400} size='small' onClick={cancelEdit}>
                    <SVG src="close"/>
                  </IconButton>
                ) : null
              }
            </div>
            <div className={classes.commentInputWrap}>
              <div className={classes.commentInputWrapper}>
                <Input useName={false} autoFocus={autoFocus} className={classes.commentInput} onKeyDown={handleOnEnter} placeholder={replyTo === 0 ? t('comment_add_placeholder') : t('comment_add_reply_placeholder')} multiline={true} disabled={state.isProcessing || !isCommentsEnabled || !commentsEnabled} customRefInput={commentInputRef}/>
                <div className={classes.commentInputBar}>
                  {
                    (!isMobile && getUserSetting(userData.userSettings, "addons", ["comments", "comment_emoji"])) ? (
                      <div className={classes.commentInputTools}>
                        <div className={classes.commentInputToolWrapper}>
                          <EmojiPicker isOpen={state.isEmojiPickerOpen} setIsClose={handleOnEmojiClose} onEmojiClick={handleOnEmojiClick}/>
                          <Button className={classes.commentInputToolButton} onClick={handleEmojiPicker} disabled={state.isProcessing || !isCommentsEnabled || !commentsEnabled}>
                            <SVG src="emoji"/>
                          </Button>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    !isMobile ? (
                      <div className={classes.commentInputSubmit}>
                        <Switch onChange={handleSwitchUseEnterToSend} label={t('comment_sendbyenter')} checked={state.useEnterToSend} disabled={state.isSavingSwitch || state.isProcessing || !isCommentsEnabled || !commentsEnabled} dataCy="useEnterToSendSwitch"/>
                        <Button className={classes.commentInputSubmitButton} onClick={handleComment} disabled={state.isProcessing || !isCommentsEnabled || !commentsEnabled}>
                          <SVG src="send"/>
                        </Button>
                        </div>
                    ) : null
                  }
                </div>
              </div>
              {
                isMobile ? (
                  <Button className={classes.commentInputSubmitButton} onClick={handleComment} disabled={state.isProcessing || !isCommentsEnabled || !commentsEnabled}>
                    <SVG src="send"/>
                  </Button>
                ) : null
              }
            </div>
          </div>
        ) : null
      }
    </div>
  );
}

export default TimelineComments;