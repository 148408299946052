import config from '../constants/config';
import { createNotification } from './createNotification';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyA8dX-_EHwXVRxb8udy0n_RQQVqK-T3Tvs",
  authDomain: "twigsee-com.firebaseapp.com",
  projectId: "twigsee-com",
  storageBucket: "twigsee-com.appspot.com",
  messagingSenderId: "287086000988",
  appId: "1:287086000988:web:78eadf15a68ec542381f99",
  measurementId: "G-DF587BQG1G"
};

const isNotificationSupported = 'Notification' in window;
const app: any = isNotificationSupported ? initializeApp(firebaseConfig) : null;

let messaging: any = null;

const initializeMessaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser && isNotificationSupported) {
      messaging = getMessaging(app);
    }
  } catch (err) {
    createNotification(err, "error");
  }
};

initializeMessaging();

const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted" && messaging) {
      const currentToken = await getToken(messaging, { vapidKey: config.FIREBASE_KEY });
      if (currentToken) {
        return [permission, currentToken];
      } else {
        return ["failed"];
      }
    } else {
      return ["denied"];
    }
  } catch (err) {
    return err;
  }
};

const onMessageListener = () => 
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
  });

export {
  isNotificationSupported,
  requestPermission,
  onMessageListener
};