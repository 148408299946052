import React from 'react';
import ScrollMenu from '../ScrollMenu';
import SVG from 'src/components/Images/SvgRenderer';
import { createCypressDataText, isCypress } from 'src/utils/useCypress';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  layout?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  tabsMenu: {
    display: "flex",
    flexDirection: 'column',
    width: (props: Props) => {
      if(props.layout === "vertical") return 'fit-content';
      else return '60%';
    },
    height: (props: Props) => {
      if(props.layout === "vertical") return '100%';
      else return '40px';
    },
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: (props: Props) => {
        if(props.layout === "vertical") return 'fit-content';
        else return '80%';
      },
    },
    [theme.breakpoints.down('md')]: {
      width: (props: Props) => {
        if(props.layout === "vertical") return 'fit-content';
        else return '100%';
      },
    },  
  },
  itemsWrapper: {
    position: 'relative',
    zIndex: 1,
    display: "flex",
    flexDirection: (props: Props) => {
      if(props.layout === "vertical") return 'column';
      else return 'row';
    },
    alignItems: (props: Props) => {
      if(props.layout === "vertical") return 'flex-start';
      else return 'center';
    },
    justifyContent: (props: Props) => {
      if(props.layout === "vertical") return 'center';
      else return 'flex-start';
    },
    width: '100%',
    height: '100%',
  },
  items: {
    display: "flex",
    flexDirection: (props: Props) => {
      if(props.layout === "vertical") return 'column';
      else return 'row';
    },
    alignItems: (props: Props) => {
      if(props.layout === "vertical") return 'flex-start';
      else return 'center';
    },
    justifyContent: (props: Props) => {
      if(props.layout === "vertical") return 'flex-start';
      else return 'flex-start';
    },
    gap: '15px',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  bar: {
    position: 'absolute',
    top: (props: Props) => {
      if(props.layout === "vertical") return '0px';
      else return '';
    },
    left: (props: Props) => {
      if(props.layout === "vertical") return '1px';
      else return '0px';
    },
    right: (props: Props) => {
      if(props.layout === "horizontal") return '0px';
      else return '';
    },
    bottom: (props: Props) => {
      if(props.layout === "vertical") return '0px';
      else return '3px';
    },
    width: (props: Props) => {
      if(props.layout === "horizontal") return '100%';
      else return '2px';
    },
    height: (props: Props) => {
      if(props.layout === "horizontal") return '2px';
      else return '100%';
    },
    backgroundColor: theme.colors.chip,
    zIndex: 0,
  },
  item: {
    color: '#003870',
    fontSize: '14px',
    fontWeight: '700',
    cursor: 'pointer',
    position: 'relative',
    padding: '8px 12px',
    transition: 'all 0.25s',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '20px',
    '& > svg': {
      color: 'inherit',
      marginRight: '8px',
      width: '14px',
      height: '14px',
    },
    '& > span': {
      '& > svg': {
        color: theme.colors.yellow[500],
        marginLeft: '8px',
        width: '14px',
        height: '14px',
      },
    },
    '& > em': {
      fontStyle: 'normal',
      marginLeft: '8px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      fontSize: '10px',
      lineHeight: '10px',
      '&.default': {
        color: theme.colors.black,
        backgroundColor: theme.colors.grey[200],
      },
      '&.primary': {
        color: theme.colors.white,
        backgroundColor: theme.colors.primaryBlue[500],
      },
      '&.error': {
        color: theme.colors.white,
        backgroundColor: theme.colors.systemRed[500],
      },
    },
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: (props: Props) => {
        if(props.layout === "vertical") return '0px';
        else return '';
      },
      bottom: (props: Props) => {
        if(props.layout === "horizontal") return '1px';
        else return '0px';
      },
      left: (props: Props) => {
        if(props.layout === "vertical") return '1px';
        else return '0px';
      },
      right: (props: Props) => {
        if(props.layout === "horizontal") return '0px';
        else return '';
      },
      borderBottomWidth: (props: Props) => {
        if(props.layout === "horizontal") return '2px';
        else return '';
      },
      borderBottomStyle: (props: Props) => {
        if(props.layout === "horizontal") return 'solid';
        else return '';
      },
      borderBottomColor: (props: Props) => {
        if(props.layout === "horizontal") return theme.colors.primaryBlue[500];
        else return '';
      },
      borderLeftWidth: (props: Props) => {
        if(props.layout === "vertical") return '2px';
        else return '';
      },
      borderLeftStyle: (props: Props) => {
        if(props.layout === "vertical") return 'solid';
        else return '';
      },
      borderLeftColor: (props: Props) => {
        if(props.layout === "vertical") return theme.colors.primaryBlue[500];
        else return '';
      },
      opacity: '0',
      transition: 'all 0.25s',
    },
    '&.selected': {
      color: theme.colors.primaryBlue[500],
      '&::after': {
        opacity: '1',
      },
    },
    '&.disabled': {
      cursor: 'auto',
      color: theme.colors.grey[570],
      '&::after': {
        borderBottomColor: theme.colors.grey[570],
      },
    },
    '&.offset': {
      marginLeft: 'auto',
    },
    '&:not(.disabled):hover': {
      color: theme.colors.primaryBlue[500],
      '&::after': {
        opacity: '1',
      },
    },
  },
}));

type ItemsType = {
  layout?: 'horizontal' | 'vertical'
  items: any;
  selected: any;
  disabled?: boolean;
  onSelect: any;
  className?: any;
  classNameBar?: any;
};

const TabsMenu: React.FunctionComponent<ItemsType> = ({layout = "horizontal", items, selected, disabled, onSelect, className, classNameBar}) => {

  const classes = useStyles({
    layout: layout,
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const layoutData = useAppSelector((state: any) => state.layout);  
  const userData = useAppSelector((state: any) => state.user);

  const isEmployee = (userData.userObject && userData.userObject.employeeID) ? userData.userObject.employeeID.length > 0 : false;
  const cookiesSettings = layoutData.cookies;  
  const isCookies = cookiesSettings.length !== 0 && !isCypress('cookies');
  const userAccess = userData.userAccess;

  const isVisible = (item: any) => {
    if(item.isEnabled) {
      if(!item.visibleOnlySelected) {
        if(!item.isEmployee && !item.isCookies) {
          return true;
        } else {
          if(item.isEmployee && isEmployee) {
            return true;
          } else if(item.isCookies && isCookies) {
            return true;
          } else {
            return false;
          }
        }
      } else if(item.visibleOnlySelected && item.value === selected) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleOnClick = (item: any) => {
    if(item.redirect) {
      navigate(item.redirect);
      return;
    }
    if(!item.disabled && !disabled) {
      onSelect(item.value);
    }
  }
  
  return (
    <div className={`${classes.tabsMenu} ${className ? className : null}`}>
      <ScrollMenu className={classes.items} classNameWrapper={classes.itemsWrapper} layout={layout} nativeMobileScroll={false}>
        {
          items.filter((item: any) => item !== null).map((item: any, key: any) => (item && isVisible(item)) ? (
            <span className={`${classes.item} ${item.value === selected ? "selected" : null} ${item.offset ? "offset" : null} ${(item.disabled || disabled) ? "disabled" : null}`} onClick={() => handleOnClick(item)} key={`k_${key}`} data-cy={isCypress() ? `tabsMenu${createCypressDataText(item.dataCy ? item.dataCy : item.value)}` : null}>
              {
                item.icon ? (
                  <SVG src={item.icon}/>
                ) : null
              }
              {typeof item.name === "object" ? item.name : t(item.name)}
              {
                item.badge ? (
                  <em className={item.badgeType ? item.badgeType : 'default'}>
                    {item.badge}
                  </em>
                ) : null
              }
              {
                (item.isSubscription && !userAccess.subscription) ? (
                  <span>
                    <SVG src="lock-outlined"/>
                  </span>
                ) : null
              }
            </span>  
          ) : null)
        }
      </ScrollMenu>
      <div className={`${classes.bar} ${classNameBar ? classNameBar : ''}`}/>  
    </div>
  );
}

export default TabsMenu;
