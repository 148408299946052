import Comment from './Comment';
import Media from './Media';
import Modal from '../../../utils/modal';
import Post from './Post';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setReactionDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
}));

const ReactionDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      ID: null,
      type: null,
    };
    dispatch(setReactionDetailModal(settings));
  };
  
  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "reactionDetailModal" : null}>
        {
          modalsData.reactionDetailModal.type === "post" ? (
            <Post/>
          ) : null
        }
        {
          modalsData.reactionDetailModal.type === "media" ? (
            <Media/>
          ) : null
        }
         {
          modalsData.reactionDetailModal.type === "comment" ? (
            <Comment/>
          ) : null
        }
      </div>
    </Modal>
  );
};

export default ReactionDetailModal;