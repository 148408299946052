import CloseButton from 'src/components/Buttons/CloseButton';
import DayPicker from 'src/components/DatePickers/Days';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import Select from 'src/components/Forms/Select';
import WeekPicker from 'src/components/DatePickers/Weeks';
import YearPicker from 'src/components/DatePickers/Years';
import { calendarViewModes } from 'src/utils/useCalendar';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setCalendarDate, setCalendarViewMode } from 'src/store/actions/calendar.actions';
import { setCalendarSettingsModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "600px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    gap: '8px',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    padding: '8px',
  },
  group: {
    display: 'flex',
    gap: '8px',
    width: '100%',
  },
  select: {
    width: '100%',
    flex: '1 1 auto',
  },
  button: {
    minWidth: 'unset',
  },
}));

const CalendarSettingsModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const calendarData = useAppSelector((state: any) => state.calendar);
  const userData = useAppSelector((state: any) => state.user);

  const userAccess = userData.userAccess;

  const curDate = calendarData.date;
  const viewMode = calendarData.viewMode;
  const isLoading = calendarData.isLoading;

  const [state, setState] = useStates({
    viewMode: viewMode,
  });

  const isToday = useMemo(() => {
    return moment(curDate).isSame(moment(), viewMode.value);
  }, [curDate, viewMode.value]);

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setCalendarSettingsModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleSetViewMode = (value: any) => {
    setState("viewMode", value);
    dispatch(setCalendarViewMode(value));
  };

  const handleSetDate = (value: any) => {
    dispatch(setCalendarDate(moment(value)));
  };

  const handleSetToday = () => {
    dispatch(setCalendarDate(moment()));
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "calendarSettingsModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('calendar_settings')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            viewMode.value === "day" ? (
              <DayPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
            ) : null
          }
          {
            viewMode.value === "week" ? (
              <WeekPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
            ) : null
          }
          {
            viewMode.value === "month" ? (
              <MonthPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
            ) : null
          }
          {
            viewMode.value === "year" ? (
              <YearPicker presetDate={moment(curDate)} setDate={handleSetDate} mode="spinner" disabled={!userAccess.subscription || isLoading}/>
            ) : null
          }
          <div className={classes.group}>
            <NormalButton className={classes.button} buttonType="secondary" onClick={handleSetToday} disabled={!userAccess.subscription || isToday || isLoading}>
              {t('today')}
            </NormalButton>
            <Select className={classes.select} items={calendarViewModes} selected={state.viewMode} setSelected={handleSetViewMode} allowClear={false} useTranslate={true} disabled={!userAccess.subscription || isLoading}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarSettingsModal;