import slice from '../slices/diagnostics.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setDiagnoticsChildID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDiagnoticsChildID(data));
  }
};

export const setDiagnoticsType = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDiagnoticsType(data));
  }
};