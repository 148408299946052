import NormalButton from 'src/components/Buttons/NormalButton';
import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import ShopItemButton from 'src/components/Buttons/ShopItemButton';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setShopItemDetailModal } from 'src/store/actions/modals.actions';
import { setStockItems, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  itemsSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    gap: '16px',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  itemsToolbar: {
    display: "flex",
    gap: '10px',
    borderRadius: "24px",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    transition: 'height 0.5s, padding 0.5s',
    height: '42px',
    padding: '9px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    },
    flex: '0 0 42px',
    margin: '0 auto',
  },
  backButton: {
    marginLeft: 'auto',
  },
  itemsWrapper: {
    display: 'flex',
    width: '100%',
    flex: '1 1 calc(100% - 192px)',
    maxHeight: 'calc(100% - 192px)',
  },
  itemsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    width: '100%',
    position: 'relative',
    height: 'fit-content',
  },
  paginationWrapper: {
    display: 'flex',
    flex: '1 0 100px',
    height: '100px',
    alignItems: 'flex-end',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  noresults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      maxWidth: '80%',
    },
  },
}));

const Items: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;
  const categoryID = stockData.category === null ? null : stockData.category;

  const itemsSelectWrapperRef: any = useRef(0);

  const items = stockData.items;
  const tagsList = stockData.tags.filter((item: any) => item.categories && item.categories.length > 0 && item.categories.includes(categoryID));

  const [state, setState] = useStates({
    isLoading: true,
    limitPerPage: 1,
    currentPage: 1,
    tagsID: [],
    isWithoutTax: false,
  });

  const handleBack = () => {
    dispatch(setStockViewMode("categorySelect"));
  };

  const getItems = useCallback((page: any, limit: any, tags: any) => {
    const newTags = tags.map((item: any) => {
      return item.tagID;
    }).join(",");
    setState("isLoading", true);
    const settings = {
      schoolID: schoolID,
      categoryID: categoryID,
      limit: limit,
      page: page, 
      tagID: newTags,
    };
    stockService && stockService.listItems(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockItems(result.data.items));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_items_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_items_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, categoryID, schoolID, dispatch, setState]);

  const onPaginationChange = (page: any) => {
    setState("currentPage", page);
    getItems(page, state.limitPerPage, state.tagsID);
  };

  const handleClickView = (itemID: any) => {
    const settings = {
      isOpen: true,
      itemID: itemID,
      showCart: true,
      schoolID: schoolID,
    };
    dispatch(setShopItemDetailModal(settings)); 
  };
  
  const countLimit = () => {
    let limit = 1;
    if(itemsSelectWrapperRef && itemsSelectWrapperRef.current) {
      const width = itemsSelectWrapperRef.current.clientWidth;
      const height = itemsSelectWrapperRef.current.clientHeight;
      const widthCount = Math.floor(width / 256);
      const heightCount = Math.floor(height / 256);
      const totalCount = (widthCount * heightCount);
      limit = totalCount;
    }
    return limit < 1 ? 1 : limit;
  };

  const preparePage = useCallback((tags?: any) => {
    const getLimit = countLimit();
    setState("limitPerPage", getLimit);
    getItems(1, getLimit, tags ? tags : state.tagsID);
  }, [getItems, setState, state.tagsID]);

  useEffect(() => {
    setTimeout(() => {
      preparePage();
      setState("currentPage", 1);
    }, 100);
  }, [preparePage, setState], [browserData.width, browserData.height]);

  const handleSaveTags = (value: any) => {
    setState("tagsID", value);
    preparePage(value);
  };

  const handleTaxSwitch = () => {
    setState("isWithoutTax", !state.isWithoutTax);
  };

  return (
    <div className={classes.itemsSelectWrapper}>
      <div className={classes.itemsToolbar}>
        {
          tagsList.length > 0 ? (
            <Select inputLabel={t("stock_tags")} items={tagsList} selected={state.tagsID} setSelected={handleSaveTags} width={200} isMultiple={true} allowClear={false} defaultTitle="name"/>
          ) : null
        }
        <NormalButton buttonType={state.isWithoutTax ? "primary" : "secondary"} onClick={handleTaxSwitch}>
          {t('stock_no_tax')}
        </NormalButton>
        <NormalButton className={classes.backButton} buttonType="secondary" onClick={handleBack}>
          {t('back')}
        </NormalButton>
      </div>
      <div className={classes.itemsWrapper} ref={itemsSelectWrapperRef}>
        <div className={classes.itemsGrid}>
          {
            state.isLoading ? (
              <div className={classes.loadingWrapper}>
                <div className={classes.spinner}>
                  <CircularProgress/>
                </div>
              </div>
            ) : null
          }
          {
            items.length === 0 && !state.isLoading ? (
              <div className={classes.noresults}>
                <img src="/resources/images/noresults.png" alt='no_results'/>
                {(t('stock_no_items_found') + '...')}
              </div>
            ) : null
          }
          {
            items.map((item: any, key: any) => {
              const itemData = {...item, isWithoutTax: state.isWithoutTax};
              return (
                <ShopItemButton onClick={() => handleClickView(item.itemID)} image={item.media.length !== 0 && item.media.photos.length > 0 ? item.media.photos[0].thumbLink : null} key={`k_${key}`} disabled={state.isLoading} data={itemData}/>
              )
            })
          }
        </div>
      </div>
      <div className={classes.paginationWrapper}>
        <Pagination currentPage={state.currentPage} total={stockData.itemsCount} limit={state.limitPerPage} disabled={state.isLoading} onChange={onPaginationChange}/>
      </div>
    </div> 
  );
};

export default Items;