import Attendance from './Attendance';
import CloseButton from 'src/components/Buttons/CloseButton';
import CreatePost from './CreatePost';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import Substitutions from './Substitutions';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setInfoModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    padding: "20px",
    '&.loading': {
      display: 'flex',
      justifyContent: 'center',
    },
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px",
  },
}));

const InfoModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      title: null,
      type: null,
      postType: null,
    };
    dispatch(setInfoModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  return (
    <Modal
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "infoModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{modalsData.infoModal.title}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            modalsData.infoModal.type === "attendance" ? (
              <Attendance/>
            ) : null
          }
          {
            modalsData.infoModal.type === "createpost" ? (
              <CreatePost/>
            ) : null
          }
          {
            modalsData.infoModal.type === "substitutions" ? (
              <Substitutions/>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleClose} dataCy="understandButton">
            {t("close")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default InfoModal;