import CircularProgress from '@mui/material/CircularProgress';
import config from 'src/constants/config';
import CountCard from '../../../components/Cards/CountCard';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import FinanceTable from '../../../components/Tables/FinanceTable';
import React, { useCallback } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { createUseStyles } from 'react-jss';
import { onlyUnique } from 'src/utils/useFunctions';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  financeChildItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  cards: {
    display: 'flex',
    gap: '20px',
    maxWidth: 'calc(100% - 32px)',
    padding: '16px',
  },
}));

type FinanceChildItemType = {
  childID: any;
};

const FinanceChildItem: React.FunctionComponent<FinanceChildItemType> = ({ childID }) => {

  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const userData = useAppSelector((state: any) => state.user);
  const paymentService = useAppSelector((state: any) => state.services).paymentService;
  const limit = config.FINANCE_PAYMENTS_LIMIT;
  const currencies = configurationData.configuration.currencies ? configurationData.configuration.currencies : [];
  const userAccess = userData.userAccess;

  const classes = useStyles();

  const [state, setState, setStates] = useStates({
    isLoading: true,
    paymentData: [],
    totals: [],
    children: [],
  });
  
  const loadFinance = useCallback(() => {
    setState("isLoading", true);
    const getFiltersParams = {childID: childID, page: 1, limit: limit};
    paymentService && paymentService.listPayments(getFiltersParams).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.payments) {
            if(result.data.payments.length === 0) {
              setTimeout(() => { 
                setState("isLoading", false);
              }, 500);           
            } else {
              const tempPaymentData = result.data.payments;
              const tempTotals = result.data.totals;
              const tempChildren = result.data.children; 
              setTimeout(() => {  
                setStates({
                  isLoading: false,
                  paymentData: tempPaymentData,
                  totals: tempTotals,
                  children: tempChildren,
                });
              }, 1000); 
            }
          }
        }
      }
    });
  }, [childID, setState, setStates, limit, paymentService]);

  useEffect(() => {
    loadFinance();
  }, [loadFinance], []);

  
  return (
    <div className={classes.financeChildItem}>
      {
        !userAccess.subscription ? (
          <>
            <SubscriptionBanner service="finance" isInCenter={true}/>
          </>
        ) : (
          <>
            {
              (state.isLoading && (state.isLoadingDefault || state.paymentData.length === 0)) ? (
                <div className={classes.spinner}>
                  <CircularProgress/>
                </div>
              ) : (
                <>
                  {
                    state.paymentData.length > 0 ? (
                      <ScrollMenu className={classes.cards} layout="horizontal" nativeMobileScroll={false}>
                        {
                          state.totals.sort(function(a: any, b: any) { if(a.currencyID === b.currencyID) { if (a.type < b.type) { return -1; } if (a.type > b.type) { return 1; } return 0; } else return a.currencyID - b.currencyID; }).map((item: any, key: any) => (
                            <CountCard
                              title={item.type === "total" ? t('payments_total') : t('payments_unpaid')}
                              countLeft={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).symbolLeft : ""}
                              badge={item.totalCount}
                              badgeType={item.type === "total" ? 'primary' : 'error'}
                              chip={state.totals.map((total: any) => { return total.currencyID; }).filter(onlyUnique).length > 1 ? (currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).iso : "") : null}
                              countNumber={item.totalSum}
                              countDecimal={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).decimalPlace : 0}
                              countRight={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).symbolRight : ""}
                              key={`k_${key}`}
                            />
                          ))
                        }
                      </ScrollMenu>
                    ) : null
                  }
                  {
                    state.paymentData.length > 0 ? (
                      <>
                        <FinanceTable childID={childID} showChild={false}/>
                        {
                          state.isLoadingMore && !state.isEndOfScroll ? (
                            <div className={classes.spinner}>
                            <CircularProgress/>
                            </div>
                          ) : null
                        }
                        {
                          state.isEndOfScroll ? ( 
                            <EndOfScroll text={t("no_more_payments")}/>
                          ) : null
                        }
                      </>
                    ) : (
                      <div className={classes.notFound}>
                        <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                        <span>{t('no_payments')}</span>
                        <p>{t('no_payments_found')}</p>
                      </div>
                    )
                  }
                </>
              )
            }  
          </>
        )
      }
    </div>
  );
};

export default FinanceChildItem;