import DateItem from './DateItem';
import moment from '../../../../utils/moment';
import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../../constants/weekdays';

const useStyles = createUseStyles((theme: any) => ({
  calendarDay: {
    width: '100%',
    height: '100%',
  },
  weekdayHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ececec',
    borderBottom: '1px solid #d2d2d2',
    position: 'relative',
    zIndex: theme.zIndex.calendarHeader,
  },
  weekday: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0',
    },
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'calc(100% - 42px)',
    minHeight: 'calc(100% - 42px)',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type CalendarDayType = {
  className?: any;
};

const CalendarDay: React.FunctionComponent<CalendarDayType> = ({ className }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const calendarData = useAppSelector((state: any) => state.calendar);

  const curDate = calendarData.date;
  const isLoading = calendarData.isLoading;

  const todayDateInWeek = useMemo(() => {
    return moment(curDate).isoWeekday() - 1;
  }, [curDate]);
  
  return (
    <div className={`${classes.calendarDay} ${className ? className : null}`}>
      <div className={classes.weekdayHeader}>
        <div className={classes.weekday}>
          <p>{t(weekdaysMin[todayDateInWeek][0].toLowerCase() + weekdaysMin[todayDateInWeek].slice(1))}</p>
        </div>
      </div>
      <div className={classes.dateWrapper}>
        {
          isLoading ? (
            <div className={classes.loadingWrapper}>
              <div className={classes.spinner}>
                <CircularProgress/>
              </div>
            </div>
          ) : null
        }
        <DateItem
          index={0}
          curDate={curDate}
          date={moment(curDate)}
        />
      </div>
    </div>
  );
};

export default CalendarDay;