import HttpService from '../http.service';

const listAbsence = (childID: any, date: any) => {
  return HttpService.get(`absence?childID=${childID}&date=${date}`, {}, {});
};

const listAbsenceRange = (childID: any, dateFrom: any, dateTo: any) => {
  return HttpService.get(`absence?childID=${childID}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, {});
};

const findAbsence = (absenceID: any) => {
  return HttpService.get(`absence?absenceID=${absenceID}`, {}, {});
};

const createAbsence = (payload: any) => {
  return HttpService.post(`absence`, payload, {}, {});
};

const editAbsence = (absenceID: any, payload: any) => {
  return HttpService.put(`absence/${absenceID}`, payload, {}, {});
};

const deleteAbsence = (absenceID: any) => {
  return HttpService.remove(`absence/${absenceID}`, {});
};

const editAbsenceGroup = (absenceGroupID: any, payload: any) => {
  return HttpService.put(`absence/group/${absenceGroupID}`, payload, {}, {});
};

const deleteAbsenceGroup = (absenceGroupID: any) => {
  return HttpService.remove(`absence/group/${absenceGroupID}`, {});
};

export {
  listAbsence,
  listAbsenceRange,
  findAbsence,
  createAbsence,
  editAbsence,
  deleteAbsence,
  editAbsenceGroup,
  deleteAbsenceGroup,
};