import * as AuthService from '../../../services/auth.service';
import React, { useCallback } from 'react';
import { getAppData } from 'src/utils/useApp';
import { setUserStatus } from '../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';

const Logout: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector((state: any) => state.user);
  const handleLogout = useCallback(() => {
    const savedData = getAppData();
    if(userData.userStatus === "loggedIn") {
      const savedUsers = savedData.users ? savedData.users : [];
      dispatch(setDeinitializeUserController(true));
      navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
    } else {
      const savedUsers = savedData.users ? savedData.users : [];
      dispatch(setDeinitializeUserController(true));
      navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
    }
  }, [dispatch, navigate, userData.userStatus]);

  useEffect(() => {
    dispatch(setUserStatus("loggingOff"));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  }, [handleLogout, dispatch], []);
  
  return null;
}

export default Logout;