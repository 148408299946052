import React, { useMemo } from 'react';
import SpeedDialButton from 'src/components/Buttons/SpeedDialButton';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { createUseStyles } from 'react-jss';
import { getActiveFilters } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { resetFilterParams, setFilterParams } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible, setIsSearchVisible } from 'src/store/actions/layout.actions';
import { setPostCreateModal, setWarningModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  speedDialWrapper: {
    position: 'relative',
    width: '38px',
    height: '38px',
  },
  speedDial: {
    position: 'absolute',
    left: '-13px',
  },
  speedDialButton: {
    position: 'relative',
    padding: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    width: '38px',
    height: '38px',
    minWidth: '38px',
    minHeight: '38px',
    maxWidth: '38px',
    maxHeight: '38px',
    backgroundColor: theme.colors.chip,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&[aria-expanded*="true"]': {
      backgroundColor: theme.colors.grey[250],
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.grey[800],
    },
    '& > span:not(.MuiTouchRipple-root)': {
      position: 'absolute',
      top: '-8px',
      right: '-12px',
      width: '22px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '9px !important',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      borderWidth: '3px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
    },
  },
  speedDialActionButton: {
    position: 'relative',
    padding: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    backgroundColor: theme.colors.chip,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.active': {
      backgroundColor: theme.colors.grey[250],
      color: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '24px',
      height: '24px',
      color: theme.colors.grey[800],
    },
  },
}));

const Timeline: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const layoutData = useAppSelector((state: any) => state.layout);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const filtersActiveCount = getActiveFilters(filtersData.filterParams);
  const activeClasses = useMemo(() => dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active === true).length, [dataData.classes]);

  const [state, setState] = useStates({
    isSpeedDialOpen: false,
  });

  const handleTimelineFiltersClick = () => {
    if(layoutData.isSearchVisible) {
      dispatch(setIsSearchVisible(false));
      dispatch(resetFilterParams());
    } 
    dispatch(setIsFiltersVisible(!layoutData.isFiltersVisible));
  };

  const handleTimelineSearchClick = () => {
    if(layoutData.isFiltersVisible) {
      dispatch(setIsFiltersVisible(false));
      dispatch(resetFilterParams());
    }
    if(filtersData.filterParams.search !== null) {
      dispatch(setFilterParams({search: null}));
    }
    dispatch(setIsSearchVisible(!layoutData.isSearchVisible));
  };

  const handleSpeedDial = () => {
    if(activeClasses > 0) {
      setState("isSpeedDialOpen", !state.isSpeedDialOpen);
    } else {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_create_post'),
      };
      dispatch(setWarningModal(settings));
    }
  };

  const handleCreate = (postType: any) => {
    const settings = {
      isOpen: true,
      postType: postType,
    };
    dispatch(setPostCreateModal(settings));
  };

  const speedDialItems = [
    {
      name: t('create_post'),
      icon: <SVG src="post"/>,
      onClick: () => handleCreate(1),
    },
    {
      name: t('create_poll'),
      icon: <SVG src="poll"/>,
      onClick: () => handleCreate(2),
    },
    {
      name: t('create_post_video'),
      icon: <SVG src="video"/>,
      onClick: () => handleCreate(3),
    }
  ];

  return (
    <>
      <TopBarButton className={layoutData.isSearchVisible ? 'active' : null} icon={<SVG src="search"/>} onClick={handleTimelineSearchClick} dataCy="topBarButtonSearch" tooltip={t('search')}/>
      <TopBarButton className={layoutData.isFiltersVisible ? 'active' : null} icon={<SVG src="filter"/>} onClick={handleTimelineFiltersClick} dataCy="topBarButtonFilter" tooltip={t('filters')}>
        {
          filtersActiveCount === 0 ? null : (
            <span>{filtersActiveCount}</span>
          )
        }
      </TopBarButton>
      {
        getUserRole(userData.userObject.roleType) !== "parent" ? (
          <Tooltip title={t('create')} placement='bottom' arrow>
            <div className={classes.speedDialWrapper}>
              <SpeedDialButton className={classes.speedDial} classNameButton={classes.speedDialButton} classNameActionButton={classes.speedDialActionButton} items={speedDialItems} onClick={handleSpeedDial} direction='down' isOpen={state.isSpeedDialOpen} isTooltipOpen={true} disabled={modalsData.postCreateModal.isOpen} transitionDuration={0} dataCy="topBarButtonAdd"/>
            </div>
          </Tooltip>
        ) : null
      }
    </>
  );
}

export default Timeline;