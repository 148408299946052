import DesktopLayout from './layouts/desktop';
import MobileLayout from './layouts/mobile';
import React, { useRef } from 'react';
import useBreakpoint from 'src/utils/useBreakpoint';
import { createUseStyles } from 'react-jss';
import { getAppData } from 'src/utils/useApp';
import { resetCommunicationList, setCommunicationDrafts, setCommunicationThreadID, setCommunicationUserID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { setCurrentMenu, setSideContent } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  communicationPage: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const location = useLocation();
  const classes = useStyles();
  const breakpoint: any = useBreakpoint();
  const navigate = useNavigate();

  const userAccess = userData.userAccess;

  const { tab }: any = useParams();

  const sideContent = layoutData.sideContent;
  const savedData = getAppData();

  const [state, setState] = useStates({
    isReady: false,
    isLoading: true,
    isFirstTimeLoaded: false,
    isLoadingMessages: false,
  });

  const refreshSideContent = useRef(false);

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const layout = layouts[breakpoint];

  const handleIsLoading = (value: any) => {
    setState("isLoading", value);
  };

  const handleIsLoadingMessages = (value: any) => {
    setState("isLoadingMessages", value);
  };

  const handleIsFirstTimeLoaded = (value: any) => {
    setState("isFirstTimeLoaded", value);
  };

  useEffect(() => {
    const url = location.pathname.replace("/communication/", "");
    if(url === "/communication") {
      navigate("/communication/threads");
    }
  }, [tab, location.pathname, navigate], [tab]);

  useEffect(() => {
    if(sideContent === "communication") {
      refreshSideContent.current = true;
      dispatch(setSideContent(null));
    }
    setTimeout(() => {
      dispatch(setCommunicationViewMode(null));
      dispatch(setCommunicationThreadID(null));
      dispatch(setCommunicationUserID(null));
      dispatch(resetCommunicationList());
      dispatch(setCurrentMenu("communication"));
      setState("isReady", true);
    }, 1);
    return () => {
      dispatch(setCurrentMenu("main"));
      dispatch(resetCommunicationList());
      if(refreshSideContent.current === true) {
        dispatch(setSideContent("communication"));
      }
    };
  }, [dispatch, sideContent, setState], []);

  useEffect(() => {
    const drafts = (savedData.twigchat && savedData.twigchat.drafts) ? savedData.twigchat.drafts : [];
    dispatch(setCommunicationDrafts(drafts));
  }, [dispatch, savedData], []);

  return !userAccess.communication ? (
    <Navigate to="/403" state={{ from: location }}/>
  ) :(
    <>
      {
        state.isReady ? (
          <div className={classes.communicationPage}>
            {
              layout === "desktop" ? (
                <DesktopLayout isLoading={state.isLoading} isLoadingMessages={state.isLoadingMessages} isFirstTimeLoaded={state.isFirstTimeLoaded} setIsLoading={handleIsLoading} setIsLoadingMessages={handleIsLoadingMessages} setIsFirstTimeLoaded={handleIsFirstTimeLoaded}/>
              ) : layout === "mobile" ? (
                <MobileLayout isLoading={state.isLoading} isLoadingMessages={state.isLoadingMessages} isFirstTimeLoaded={state.isFirstTimeLoaded} setIsLoading={handleIsLoading} setIsLoadingMessages={handleIsLoadingMessages} setIsFirstTimeLoaded={handleIsFirstTimeLoaded}/>
              ) : null
            }
          </div>
        ) : null
      }
    </>
  );
};

export default PageTemplate;