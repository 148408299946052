import React, { createElement } from 'react';
import useFitText from 'use-fit-text';

type FitTextType = {
  children: any;
  options?: any;
  style?: any,
  className?: any,
  renderAs?: 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const FitText: React.FunctionComponent<FitTextType> = ({ children, options, style, className, renderAs = 'span' }) => {
  
  const { fontSize, ref } = useFitText({logLevel: 'none', ...options});
  
  return createElement(renderAs, {className: className, ref: ref, style: { fontSize, ...style }}, children);
}

export default FitText;
