import MediaGrid from '../MediaGrid';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setTimelineGalleryModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  timelineGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: 'calc(100% - 30px)',
    padding: '15px',
  },
}));

type TimelineGalleryType = {
  uniqueID: any;
  currentMediaID?: any;
  display?: any;
};

const TimelineGallery: React.FunctionComponent<TimelineGalleryType> = ({ uniqueID, currentMediaID, display = "responsive" }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const timelineData = useAppSelector((state: any) => state.timeline);
  const postData = timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
  const photos = postData.photos;
  const files = postData.files;
  const videos = postData.videos;
  const [gridData, setGridData]: any = useState([]);
  
  useEffect(() => {
    const tmpPhotos = photos.map((photo: any) => {
      return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name, favorite: photo.favorite };
    });
    const tmpFiles = files.map((file: any) => {
      return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size, favorite: file.favorite };
    });
    const tmpVideos = videos.map((video: any) => {
      return { mediaID: video.mediaID, type: 'video', thumbLink: video.thumbLink, link: video.link, name: video.name, favorite: video.favorite };
    });
    const tmpGridData = [].concat(tmpPhotos, tmpFiles, tmpVideos).filter((item: any) => item);
    if(tmpGridData.length > 0) {
      setGridData(tmpGridData);
    }
  }, [uniqueID, photos, files, videos, timelineService, dispatch, timelineData], [uniqueID, photos, files, videos]);

  const handleOpenModal = (mediaID: any) => {
    if(postData.authorID !== 0) {
      const settings = {
        isOpen: true,
        mediaID: mediaID,
        mediaList: gridData,
        uniqueID: uniqueID,
      };
      dispatch(setTimelineGalleryModal(settings));  
    }
  };
  
  return (
    <div className={classes.timelineGallery} data-cy={isCypress() ? "timelineCardGallery" : null}>
      <MediaGrid
        data={gridData}
        currentMediaID={currentMediaID}
        display={display}
        width='100%'
        maxWidth='100%'
        onModalOpen={handleOpenModal}
        isClickable={postData.authorID !== 0}
      />
    </div>
  );
}

export default TimelineGallery;