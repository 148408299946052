import CloseButton from 'src/components/Buttons/CloseButton';
import EmployeesInput from 'src/components/Forms/EmployeesInput';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import NormalButton from '../../../../Buttons/NormalButton';
import React from 'react';
import Select from 'src/components/Forms/Select';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getDayName, isKey } from 'src/utils/useFunctions';
import { setTimetableData } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  select: {
    width: '100%',
  },
}));

type ContainerType = {
  onClose: any;
};

const BasicChild: React.FunctionComponent<ContainerType> = ({ onClose }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);
  const employees = timetableData.school === null ? [] : dataData.employees.filter((employee: any) => employee.schoolID === timetableData.school.schoolID && employee.enableForTimetable).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID } });

  const schoolID = timetableData.school === null ? 0 : timetableData.school.schoolID;
  const isEdit = modalsData.timetableHourHandleModal.timetableID === null ? false : true;
  const timetableID = isEdit ? modalsData.timetableHourHandleModal.timetableID : null;
  const hourType = modalsData.timetableHourHandleModal.type;
  const hourData = isEdit ? (timetableData.data.timetables.filter((hour: any) => hour.timetableID === timetableID).length === 1 ? timetableData.data.timetables.find((hour: any) => hour.timetableID === timetableID) : []) : [];
  const subjectData = isEdit? (timetableData.subjects.filter((item: any) => item.subjectID === hourData.subjectID).length === 1 ? timetableData.subjects.find((item: any) => item.subjectID === hourData.subjectID) : null) : null;
  const roomData = isEdit?  (timetableData.rooms.filter((item: any) => item.roomID === hourData.roomID).length === 1 ? timetableData.rooms.find((item: any) => item.roomID === hourData.roomID) : null) : null;
  const hourEmployees = isEdit ? employees.filter((employee: any) => hourData.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; }) : [];
  const weekday = modalsData.timetableHourHandleModal.weekday;
  const hour = modalsData.timetableHourHandleModal.hour;

  const [state, setState] = useStates({
    room: isEdit ? roomData : null,
    subject: isEdit ? subjectData : null,
    isEmployeeSelectOpen: false,
    employees: isEdit ? hourEmployees : [],
  });

  //const subjectEmployees = state.subject === null ? [] : employees.filter((employee: any) => state.subject.teacherID.indexOf(employee.employeeID) !== -1).map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID } });
  const allEmployees = employees.map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID } });

  const handleEmployeesChange = (value: any) => {
    setState("employees", value);
  };

  const handleSave = () => {
    let payload = {
      "type": hourType,
      "weekday": weekday,
      "hour": hour,
      "roomID": state.room ? state.room.roomID : null,
      "subjectID": state.subject.subjectID,
      "classID": null,
      "childID": [timetableData.child.childID],
      "teacherID": state.employees.map((item: any) => { return item.employeeID }),
      "schoolID": schoolID,
    };
    if(isEdit) {
      timetableService && timetableService.editHour(timetableID, payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_hour_updated"), "success");
            onClose();
            const newData = {...timetableData.data, timetables: timetableData.data.timetables.map((timetable: any) => {
              if(timetable.timetableID === timetableID) {
                return result.data;
              } else {
                return timetable;
              }
            })};
            dispatch(setTimetableData(newData));
          } else {
            createNotification(t("timetable_hour_not_updated"), "error");
          }
        } else {
          createNotification(t("timetable_hour_not_updated"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_hour_not_updated"), "error");
      });
    } else {
      timetableService && timetableService.createHour(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_hour_added"), "success");
            onClose();
            const newData = {...timetableData.data, timetables: [...timetableData.data.timetables, result.data]};
            dispatch(setTimetableData(newData));
          } else {
            createNotification(t("timetable_hour_not_added"), "error");
          }
        } else {
          createNotification(t("timetable_hour_not_added"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_hour_not_added"), "error");
      });
    }
  };

  const handleSaveEmployeesModal = (value: any) => {
    const newValue = value.map((employee: any) => { return { employeeID: employee.employeeID, schoolID: employee.schoolID }; });
    setState("employees", newValue);  
  };

  const handleChangeSubject = (value: any) => {
    setState("subject", value);
    //setState("employees", []);
  };

  const handleChangeRoom = (value: any) => {
    setState("room", value);
  };

  const roomsList = timetableData.rooms.filter((item: any) => item.type === hourType);
  const subjectsList = timetableData.subjects.filter((item: any) => item.type === hourType);

  return (
    <>
      <div className={classes.header}>
        <div className={classes.wrapper}>
          <p>{isEdit ? t('timetable_hour_edit') : t('timetable_hour_add')} - {t(getDayName(weekday))} {hour}. {t('hour').toLowerCase()}</p>
        </div>
        <CloseButton onClick={onClose} dataCy="timesButton"/>
      </div>
      <div className={classes.body}>
        <Select className={classes.select} label={t('timetable_subject') + "*"} inputLabel={t("timetable_subject")} items={subjectsList} selected={state.subject} setSelected={handleChangeSubject} width={200} allowClear={false}/>
        {
          roomsList.length > 0 ? (
            <Select className={classes.select} label={t('timetable_room')} inputLabel={t("timetable_room")} items={roomsList} selected={state.room} setSelected={handleChangeRoom} width={200} allowClear={true}/>
          ) : null
        }
        <EmployeesInput label={t('timetable_teachers') + "*"} selectedEmployees={state.employees} setSelectedEmployees={handleEmployeesChange} onClick={() => setState("isEmployeeSelectOpen", true)}/>
        {
          state.isEmployeeSelectOpen ? (
            <EmployeesSelect
              selectedEmployees={state.employees}
              isInModal={true}
              isModalOpen={true}
              defaultEmployees={allEmployees}
              modalTitle="timetable_teachers"
              mode="select"
              modalAllowCancel={false}
              modalAllowClose={true}
              modalAllowClear={true}
              isSelectAll={true}
              isMultipleSelect={true}
              isAllowArchived={false}
              isAllowArchivedToggle={false}
              modalOnClose={() => setState("isEmployeeSelectOpen", false)}
              modalOnSave={handleSaveEmployeesModal}
              modalAllowEmployeesCount={true}
            />
          ) : null
        }
      </div>
      <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={onClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.subject === null || state.employees.length === 0} dataCy="saveButton">
            {t('save')}
          </NormalButton>
        </div>
    </>
  );
};

export default BasicChild;