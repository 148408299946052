import DateFormat from '../../../utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import React, { useCallback } from 'react';
import SVG from '../../Images/SvgRenderer';
import { CircularProgress, Tooltip } from '@mui/material';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setClassbookDetailModal, setClassbookHandleModal, setDetailModal, setPostDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { getUserSetting } from 'src/utils/useUser';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
    marginTop: '16px',
    position: 'relative',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
        '& > svg': {
          width: '16px',
          height: '16px',
          color: theme.colors.primaryBlue[500],
        },  
      },
      '& > div': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
        '& > span': {
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
        },
      },
    },
  },
  tableRowEmpty: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '& > span': {
        display: 'block',
        minHeight: '47px',
      },
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'all 0.25s',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mediumWidth: {
    width: "120px"
  },
  customWidth: {
    minWidth: "10px !important"
  },
  detail: {
    display: 'block !important',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  posts: {
    cursor: 'pointer',
    '& > svg': {
      color: theme.colors.primaryBlue[500] + ' !important',
      width: '24px',
      height: '24px',
    },
  },
  posts_notes: {
    cursor: 'pointer',
    '& > svg': {
      color: "#FFC632 !important",
      width: '24px',
      height: '24px',
    },
  },
  notesIcon: {
    cursor: "help",
    '& > svg': {
      color: "#FFDC64 !important",
      width: '24px',
      height: '24px',
    },
  },
  visitIcon: {
    '& > svg': {
      color: "#F44336 !important",
      width: '24px',
      height: '24px',
    },
    cursor: "help",
  },
  editButton: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: '#5AB8FF',
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));
const ClassbookTable: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const classes = useStyles();

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const currentClass = classbookData.filterClassID ? classbookData.filterClassID[0] : null;
  const currentDate = classbookData.date;
  const isArchived = currentClass === null ? false : (getClassData(currentClass).isArchived || !getClassData(currentClass).active);
  const list = classbookData.list;
  const classbookType = currentClass === null ? "daily" : getClassData(currentClass).classbookType;
  const isSchoolCare = currentClass === null ? "daily" : getClassData(currentClass).isSchoolCare;
  const startDate = classbookType === "weekly" ? moment(currentDate).startOf('month').format("YYYY-MM-DD") : moment(currentDate).startOf('isoWeek').format("YYYY-MM-DD");
  const endDate = classbookType === "weekly" ? moment(currentDate).endOf('month').format("YYYY-MM-DD") : moment(currentDate).startOf('isoWeek').add(6, "days").format("YYYY-MM-DD");

  const currentList = currentClass ? (list.filter((item: any) => item.startDate === startDate && item.endDate === endDate && item.classID === currentClass).length === 0 ? [] : list.find((item: any) => item.startDate === startDate && item.endDate === endDate && item.classID === currentClass).records) : [];

  const count = classbookType === "daily" ? 7 : moment().month(moment().month()).date(1).add(1, 'month').subtract(1, 'day').isoWeek() - moment().month(moment().month()).date(1).isoWeek() + 1;

  const handleClickDetail = (record: any) => {
    const settings = {
      isOpen: true,
      date: record.date,
      dates: classbookType === "weekly" ? [record.dateFrom, record.dateTo] : null,
      classID: currentClass,
    };
    dispatch(setClassbookDetailModal(settings)); 
  };

  const handleDetail = (title: any, record: any) => {
    const settings = {
      isOpen: true,
      title: title,
      content: record,
      zIndex: null,
    };
    dispatch(setDetailModal(settings));
  };

  const handleClickPosts = (posts: any) => {
    const settings = {
      isOpen: true,
      postID: posts,
    };
    dispatch(setPostDetailModal(settings)); 
  };

  const handleClickEdit = (record: any) => {

    const isoWeekDay = moment(record.date).isoWeekday();
    const isImport = isoWeekDay === 1 || record.morning !== "" || record.afternoon !== "" || record.theme !== "" || record.visit !== "" || record.notes !== "" ? false : true;
    const importData = isImport ? currentList[isoWeekDay - 2] : null;
    const isReallyImport = importData !== null ? (importData.morning !== "" || importData.afternoon !== "" || importData.theme !== "" || importData.visit !== "" || importData.notes !== "" ? true : false) : false;

    const settings = {
      isOpen: true,
      date: record.date,
      dates: classbookType === "weekly" ? [record.dateFrom, record.dateTo] : null,
      classID: currentClass,
      isImport: classbookType === "weekly" ? false : isReallyImport,
      importData: classbookType === "weekly" ? false : importData,
    };
    
    dispatch(setClassbookHandleModal(settings)); 
  };

  const getColumnCount = () => {
    if(classbookType === "daily") {
      if(isSchoolCare) return 7;
      else return 8;
    } else if(classbookType === "weekly") {
      if(isSchoolCare) return 7;
      else return 6;
    } else {
      return 6;
    }
  };

  return (
    <div className={classes.tableWrapper}>
      {
        currentList.length === 0 ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : null
      }
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            {
              classbookType === "daily" ? (
                <th>{t('day')}</th>
              ) : (
                <th>{t('week')}</th>
              )
            }
            {
              isSchoolCare ? (
                <>
                  <th>{t('interest_activities')}</th>
                  <th>{t('relaxation_recreational_activities')}</th>
                  <th>{t('preparation_teaching')}</th>
                  <th>{t('other_activities')}</th>
                  <th>{t('internal_note')}</th>
                  <th>{t('posts')}</th>
                </>
              ) : (
                <>
                  {
                    classbookType === "daily" ? (
                      <>
                        <th>{t('attendance')}</th>
                        <th>{t('excuse_notes')}</th>
                      </>
                    ) : null
                  }
                  <th>{t('theme')}</th>
                  <th>{t('morning_educational_activities')}</th>
                  <th>{t('afternoon_educational_activities')}</th>
                  <th>{t('attributes')}</th>
                  <th>{t('posts')}</th>
                </>
              )
            }
            {
              !isArchived ? (
                <th>{t('action')}</th>
              ) : null
            }
          </tr>
        </thead>
        <tbody className={classes.tableBody}>   
        {
          currentList.length > 0 ? (
            <>
            {
              currentList.map((record: any, key: any) => (
                <ContextMenuTrigger id={`classbookRecord_${key}`} className={classes.tableRow} renderTag={"tr"} key={`k_${key}`} dataCy={`record${key + 1}`} disable={!getUserSetting(userData.userSettings, "addons", ["classbook", "classbook_context_menu"])}>
                  {
                    classbookType === "daily" ? (
                      <td data-cy={isCypress() ? `recordInfo` : null}>
                        <span className={classes.link} onClick={() => handleClickDetail(record)} data-clarity-unmask="true">
                          <SVG src="info-circle-outlined"/> {DateFormat(record.date, "daymonth", languageData, t)}
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className={classes.link} onClick={() => handleClickDetail(record)} data-clarity-unmask="true">
                          <SVG src="info-circle-outlined"/> {record.week}. ({DateFormat(record.dateFrom, "default", languageData, t)} - {DateFormat(record.dateTo, "default", languageData, t)})
                        </span>
                      </td>
                    )
                  }
                  {
                    isSchoolCare ? (
                      <>
                        <td data-cy={isCypress() ? `interestActivities` : null} data-clarity-unmask="true">
                          {(record.interestActivities && record.interestActivities !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('interest_activities'),record.interestActivities)}>{record.interestActivities}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `relaxationRecreationalActivities` : null} data-clarity-unmask="true">
                          {(record.relaxationRecreationalActivities && record.relaxationRecreationalActivities !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('relaxation_recreational_activities'),record.relaxationRecreationalActivities)}>{record.relaxationRecreationalActivities}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `preparationTeaching` : null} data-clarity-unmask="true">
                          {(record.preparationTeaching && record.preparationTeaching !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('preparation_teaching'),record.preparationTeaching)}>{record.preparationTeaching}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `otherActivities` : null} data-clarity-unmask="true">
                          {(record.otherActivities && record.otherActivities !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('other_activities'),record.otherActivities)}>{record.otherActivities}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `internalNote` : null} data-clarity-unmask="true">
                          {(record.internalNote && record.internalNote !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('interest_activities'),record.internalNote)}>{record.internalNote}</span>) : null}
                        </td>
                      </>
                    ) : ( 
                      <>
                        {
                          classbookType === "daily" ? (
                            <>
                              <td data-cy={isCypress() ? `attendance` : null} data-clarity-unmask="true">
                                <Tooltip title={`${t("morning")}: ${record.attendance ? record.attendance.am : 0} \n${t("afternoon")}: ${record.attendance ? record.attendance.pm : 0} \n${t("absents")}: ${record.absence ? record.absence.withoutApology : 0}`} classes={{ tooltip: classes.mediumWidth }} arrow>
                                  <span data-clarity-unmask="true">{record.attendance ? record.attendance.am : 0}/{record.attendance ? record.attendance.pm : 0}/{record.absence ? record.absence.withoutApology : 0}</span>
                                </Tooltip>
                              </td>
                              <td data-cy={isCypress() ? `absence` : null} data-clarity-unmask="true">
                                <span data-clarity-unmask="true">{record.absence ? record.absence.withApology : 0}</span>
                              </td>
                            </>
                          ) : null
                        }
                        <td data-cy={isCypress() ? `theme` : null} data-clarity-unmask="true">
                          {(record.theme && record.theme !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('theme'), record.theme)}>{record.theme}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `morning` : null} data-clarity-unmask="true">
                          {(record.morning && record.morning !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('morning_educational_activities'), record.morning)}>{record.morning}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `afternoon` : null} data-clarity-unmask="true">
                          {(record.afternoon && record.afternoon !== "") ? (<span className={classes.detail} onClick={() => handleDetail(t('afternoon_educational_activities'), record.afternoon)}>{record.afternoon}</span>) : null}
                        </td>
                        <td data-cy={isCypress() ? `attributes` : null} data-clarity-unmask="true">
                          <div>
                          {
                            record.notes ? (
                              <Tooltip title={record.notes} classes={{ tooltip: classes.customWidth }} arrow>
                                <span className={classes.notesIcon}>
                                  <SVG src="clipboard"/>
                                </span>
                              </Tooltip>
                            ) : null
                          }
                          {
                            record.visit ? (
                              <Tooltip title={record.visit} classes={{ tooltip: classes.customWidth }} arrow>
                                <span className={classes.visitIcon}>
                                  <SVG src="briefcase-outlined"/>
                                </span>
                              </Tooltip>
                            ) : null
                          }     
                          </div>           
                        </td>
                      </>
                    )
                  }
                  <td data-cy={isCypress() ? `postsNotesCount` : null}>
                    <div>
                      {
                        record.noteCount.length > 0 ? (
                          <span className={classes.posts_notes} onClick={() => handleClickPosts(record.noteCount)} data-clarity-unmask="true">
                            <span data-clarity-unmask="true">{record.noteCount.length}</span>
                            <SVG src="posts-notes"/>
                          </span>
                        ) : null
                      }
                      {
                        record.postCount.length > 0 ? (
                          <span className={classes.posts} onClick={() => handleClickPosts(record.postCount)} data-clarity-unmask="true">
                            <span data-clarity-unmask="true">{record.postCount.length}</span>
                            <SVG src="posts-notes"/>
                          </span>
                        ) : null
                      }
                    </div>
                  </td>
                  {
                    !isArchived ? (
                      <td data-cy={isCypress() ? `edit` : null}>
                        <IconButton className={classes.editButton} tooltip={t('edit_record')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={() => handleClickEdit(record)} dataCy='editButton'>
                          <SVG src="edit"/>
                        </IconButton>
                      </td>
                    ) : null
                  }
                </ContextMenuTrigger>
              ))
            } 
            </>
          ) : (
            <>
              {
                Array(count).fill(0).map((_: any, key: any) => (
                  <tr className={classes.tableRowEmpty} key={`k_${key}`}>
                    {
                      Array(getColumnCount()).fill(0).map((_: any, akey: any) => (
                        <td key={`k_${akey}`}><span/></td>
                      ))
                    }
                    {
                      !isArchived ? (
                        <td><span/></td>
                      ) : null
                    }
                  </tr>
                ))
              }
            </>
          )
        }               
        </tbody>
      </table>
      {
        currentList.length > 0 ? (
          <>
          {
            currentList.map((record: any, key: any) => {
              
              const isEmpty = isSchoolCare ? (record === null ? true : ((!record.interestActivities && !record.relaxationRecreationalActivities && !record.preparationTeaching && !record.otherActivities && !record.internalNote) ? true : false)) : (record === null ? true : ((!record.theme && !record.morning && !record.afternoon && !record.notes && !record.visit) ? true : false));

              return (
                <ContextMenu id={`classbookRecord_${key}`} key={`k_${key}`} preventHideOnScroll={false} hideOnLeave={true}>
                  <ContextMenuItem onClick={() => handleClickDetail(record)}>
                    <SVG src="info-circle-outlined"/>
                    {t('detail_record')}
                  </ContextMenuItem>
                  {
                    record.noteCount.length > 0 ? (
                      <ContextMenuItem className={classes.posts_notes} onClick={() => handleClickPosts(record.noteCount)}>
                        <SVG src="posts-notes"/>
                        {t('view_notes')} <small>({record.noteCount.length})</small>
                      </ContextMenuItem>
                    ) : null
                  }
                  {
                    record.postCount.length > 0 ? (
                      <ContextMenuItem className={classes.posts} onClick={() => handleClickPosts(record.postCount)}>
                        <SVG src="posts-notes"/>
                        {t('view_posts')} <small>({record.postCount.length})</small>
                      </ContextMenuItem>
                    ) : null
                  }
                  {
                    !isArchived ? (
                      <>
                        {
                          isEmpty ? (
                            <ContextMenuItem onClick={() => handleClickEdit(record)}>
                              <SVG src="pencil"/>
                              {t('create_record')}
                            </ContextMenuItem>
                          ) : (
                            <ContextMenuItem onClick={() => handleClickEdit(record)}>
                              <SVG src="pencil"/>
                              {t('edit_record')}
                            </ContextMenuItem>
                          )
                        }
                      </>
                    ) : null
                  }
                </ContextMenu>
              );
            })
          }
          </>
        ) : null
      }
    </div>
  );
}

export default ClassbookTable;