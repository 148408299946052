import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'src/utils/useEffect';

const LeavePageBlocker = ({ when }: any) => {
  const { t } = useTranslation();
  const message = t('page_has_unsaved_changes');

  useEffect(() => {
    if(!when) return () => {}
    
    const beforeUnloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    }

    window.addEventListener('beforeunload', beforeUnloadCallback);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
    }
    
  }, [when, message]);  
  return null;
}

LeavePageBlocker.propTypes = {
  when: PropTypes.bool.isRequired,
}

export default LeavePageBlocker;