import MediaGrid from '../MediaGrid';
import React, { useCallback, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setCommunicationGalleryModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import NotFound from 'src/components/Layouts/NotFound';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

const useStyles = createUseStyles((theme: any) => ({
  communicationThreadGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: 'calc(100% - 30px)',
    padding: '15px',
    borderWidth: '0px',
    borderStyle: 'solid',
    borderColor: 'inherit',
    borderRadius: '24px',
    height: 'calc(100% - 30px)',
    maxHeight: 'calc(100% - 30px)',
    overflow: 'hidden auto',
    '& > div': {
      alignItems: 'flex-start',
      '& > div': {
        height: 'fit-content',
      },
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center !important',
    textAlign: 'center',
    gap: '8px',
    height: 'fit-content',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  notFound: {
    height: 'fit-content',
    alignItems: 'center !important',
  },
}));

type CommunicationThreadGalleryType = {
  threadID: any;
  type?: 'photo' | 'attachment' | 'video';
};

const CommunicationThreadGallery: React.FunctionComponent<CommunicationThreadGalleryType> = ({ threadID, type = "photo" }) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const communicationService = useAppSelector((state: any) => state.services).communicationService;
  
  const [state, setState] = useStates({
    isLoading: true,
    gridData: [],
  });

  const galleryContainerRef: any = useRef(null);
  const gridDataRef = useRef(state.gridData);
  const pageRef = useRef(1);
  const totalCount = useRef(0);

  const setGridData = useCallback((value: any) => {
    setState("gridData", value);
    gridDataRef.current = value;
  }, [setState]);

  const processFiles = useCallback((medias: any) => {
    if(type === "photo") {
      return medias.filter((item: any) => item.type === "photo").map((photo: any) => {
        return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name };
      });
    } else if(type === "attachment") {
      return medias.filter((item: any) => item.type === "attachment").map((file: any) => {
        return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size };
      });
    } else if(type === "video") {
      return medias.filter((item: any) => item.type === "video").map((video: any) => {
        return { mediaID: video.mediaID, type: 'video', thumbLink: video.thumbLink, link: video.link, name: video.name };
      });
    } else {
      return [];
    }
  }, [type]);

  const loadMedias = useCallback((page: any) => {
    setState("isLoading", true);
    const settings = {
      communicationThreadID: threadID,
      type: type,
      limit: 30,
      page: page,
      withCount: 1,
    };
    communicationService && communicationService.listMedias(settings).then((result: any) => {
      if(result && result.data && result.data.media) {
        const processedFiles = processFiles(result.data.media);
        const tmpGridData = processedFiles.filter((item: any) => item);
        if(tmpGridData.length > 0) {
          setGridData([...gridDataRef.current, ...tmpGridData]);
          pageRef.current = page + 1;
          setState("isLoading", false);
        } else {
          setState("isLoading", false);
        }
        if(page === 1) {
          totalCount.current = result.data.countMedias;
        }
      } else {
        setState("isLoading", false);
      }
    }).catch(() => {
      setState("isLoading", false);
    });
  }, [communicationService, setGridData, setState, processFiles, threadID, type]);
  
  useEffect(() => {
    loadMedias(pageRef.current);
  }, [loadMedias, processFiles, setState, dispatch], [threadID]);

  useEffect(() => {
    const galleryContainerRefCurrent = galleryContainerRef.current;
    if(galleryContainerRefCurrent) {
      const handleScroll = () => {
        const scrollTop = galleryContainerRefCurrent.scrollTop;
        const scrollHeight = galleryContainerRefCurrent.scrollHeight;
        const offsetHeight = galleryContainerRefCurrent.offsetHeight;
        if(scrollTop === (scrollHeight - offsetHeight)) {
          if(gridDataRef.current.length < totalCount.current) {
            loadMedias(pageRef.current);
          }
        }
      };
      galleryContainerRefCurrent.addEventListener('scroll', handleScroll);
      return () => {
        if(galleryContainerRefCurrent) {
          galleryContainerRefCurrent.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [loadMedias, galleryContainerRef], []);

  const handleOpenModal = (mediaID: any) => {
    const settings = {
      isOpen: true,
      mediaID: mediaID,
      mediaList: state.gridData,
    };
    dispatch(setCommunicationGalleryModal(settings));  
  };
  
  return (
    <div className={classes.communicationThreadGallery} data-cy={isCypress() ? "communicationThreadGallery" : null} ref={galleryContainerRef}>
      {
        state.gridData.length === 0 ? (
          <>
          {
            state.isLoading ? (
              <div className={classes.loading}>
                <div className={classes.spinner}>
                  <CircularProgress/>
                </div>
              </div>
            ) : (
              <NotFound className={classes.notFound} text={t('nothing_was_found')}/>
            )
          }
          </>
        ) : (
          <MediaGrid
            data={state.gridData}
            display="grid"
            width='100%'
            maxWidth='100%'
            onModalOpen={handleOpenModal}
          />
        )
      }
    </div>
  );
}

export default CommunicationThreadGallery;