import HttpService from '../http.service';

const listMeals = (dateFrom: any, dateTo: any, childID: any) => {
  return HttpService.get(`meal/menu?dateFrom=${dateFrom}&dateTo=${dateTo}&childID=${childID}`, {}, {});
};

const createMealOrder = (payload: any) => {
  return HttpService.post(`meal/menu`, payload, {}, {});
};

const deleteMealOrder = (date: any, childID: any, mealMenuID: any) => {
  return HttpService.remove(`meal/menu?date=${date}&childID=${childID}&mealMenuID=${mealMenuID}`, {});
};

export {
  listMeals,
  createMealOrder,
  deleteMealOrder,
};