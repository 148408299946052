import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { getActiveFilters } from 'src/utils/useFunctions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const Employees: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtersData = useAppSelector((state: any) => state.filters);
  const layoutData = useAppSelector((state: any) => state.layout);
  const filtersActiveCount = getActiveFilters(filtersData.filterParams);

  const handleEmployeesFiltersClick = () => {
    dispatch(setIsFiltersVisible(!layoutData.isFiltersVisible));
  };

  return (
    <>
      <TopBarButton className={layoutData.isFiltersVisible ? 'active' : null} icon={<SVG src="filter"/>} onClick={handleEmployeesFiltersClick} dataCy="topBarButtonFilter" tooltip={t('filters')}>
        {
          filtersActiveCount === 0 ? null : (
            <span>{filtersActiveCount}</span>
          )
        }
      </TopBarButton>
    </>
  );
}

export default Employees;