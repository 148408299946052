import ClassbookTable from 'src/components/Tables/ClassbookTable';
import DateFormat from '../../../../../utils/dateFormat';
import DatePicker from '../../../../../components/DatePickers/Classbook';
import DropdownWithButton from '../../../../../components/Buttons/DropdownWithButton';
import htmlParse from 'html-react-parser';
import moment from '../../../../../utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import React, { useCallback } from 'react';
import Select from '../../../../../components/Forms/Select';
import SVG from '../../../../../components/Images/SvgRenderer';
import Switch from '../../../../../components/Forms/Switch';
import theme from '../../../../../ui/theme';
import { a2ab, createFileName } from 'src/utils/useFunctions';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { saveAs } from 'file-saver';
import { setAttachmentModal } from 'src/store/actions/modals.actions';
import { setClassbookDate, setClassbookFilterClassID, setClassbookFilterSchoolID, setClassbookList } from '../../../../../store/actions/classbook.actions';
import { Skeleton, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    padding: '0 10px',
    width: 'calc(100% - 20px)',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    '& > div': {
      width: '200px',
      flex: '0 0 200px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        flex: 'unset',
      },
    },
  },
  infoBar: {
    display: 'flex',
    gap: '20px',
    fontSize: '16px',
    marginTop: '16px',
    alignItems: 'center',
    padding: '0 10px',
    width: 'calc(100% - 20px)',
    '& > span': {
      fontWeight: 'bold',
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      color: theme.colors.black,
      '& > svg': {
        height: '16px',
        width: '16px',
        color: theme.colors.black,
      },
    },
    '& > p': {
      color: theme.colors.black,
    },
  },
  childrenCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '19px',
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '16px',
    }
  },
  dropdownWithButton: {
    height: '42px',
    justifyContent: 'center',
  },
  filtersSkeleton: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '10px',
    '& > div': {
      width: '200px',
      flex: '0 0 200px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        flex: 'unset',
      },
    },
  },
  dropdownWithButtonSkeleton: {
    display: 'flex',
    gap: '5px',
  },
  infoBarSkeleton: {
    display: 'flex',
    gap: '20px',
    fontSize: '16px',
    marginTop: '16px',
    alignItems: 'center',
    padding: '0 10px',
    width: 'calc(100% - 20px)',
  },
}));

type WeekviewType = {
  filters: any,
};

const Weekview: React.FunctionComponent<WeekviewType> = ({filters}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const classbookService = useAppSelector((state: any) => state.services).classbookService;
  const exportService = useAppSelector((state: any) => state.services).exportService;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const activeClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active).length;
  const inactiveClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === true || !theclass.active).length;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const currentSchool = filters.schoolID ? filters.schoolID[0] : null;
  const currentClass = filters.classID ? filters.classID[0] : null;
  const currentDate = filters.date ? moment(filters.date) : moment();
  const classbookType = currentClass ? getClassData(currentClass).classbookType : "daily";
  const startDate = classbookType === "weekly" ? moment(currentDate).startOf('month') : moment(currentDate).startOf('isoWeek');
  const endDate = classbookType === "weekly" ? moment(currentDate).endOf('month') : moment(currentDate).startOf('isoWeek').add(6, "days");
  const list = classbookData.list;

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const [state, setState] = useStates({
    isReady: false,
    isChange: false,
    isArchived: currentClass === null ? (activeClasses === 0 ? (inactiveClasses > 0 ? true : false) : false) : ((getClassData(currentClass).isArchived || !getClassData(currentClass).active) ? true : false),
  });

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      if(state.isArchived) {
        return theChild;
      } else {
        if(!theChild.isArchived) {
          return theChild;
        } else {
          return null;
        }
      }
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        if(state.isArchived) {
          if(theClass.isArchived) {
            return {...theClass, icon: "archived"};
          } else if(!theClass.active) {
            return {...theClass, icon: "inactive"};
          } else {
            return theClass;
          }
        } else {
          if(!theClass.isArchived && theClass.active) {
            return theClass;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const getAllClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const classList = getClasses().map((theClass: any) => {
    if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
      return theClass;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const showArchivedToggle = () => {
    const countInactive = getAllClasses().map((theClass: any) => {
      if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
        if(theClass.isArchived || !theClass.active) {
          return theClass;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null).length;
    const countActive = getAllClasses().map((theClass: any) => {
      if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
        if(!theClass.isArchived && theClass.active) {
          return theClass;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null).length;
    return (countInactive > 0 && countActive > 0) ? true : false;
  };

  const handleChangeSchool = (value: any) => {
    const newSchool = value.schoolID;
    const newClass = getClasses().filter((theClass: any) => theClass.schoolID === value.schoolID)[0].classID;
    dispatch(setClassbookFilterSchoolID([newSchool]));
    dispatch(setClassbookFilterClassID([newClass]));
    loadRecords(newClass, currentDate, list);
    setState("isArchived", false);
  };

  const handleChangeClass = (value: any) => {
    const newClass = value.classID;
    dispatch(setClassbookFilterClassID([newClass]));
    loadRecords(newClass, currentDate, list);
  };

  const handleChangeDate = (value: any) => {
    dispatch(setClassbookDate(moment(value)));
    loadRecords(currentClass, value, list); 
  };

  const handleArchived = () => {
    if(state.isArchived) {
      if(currentClass.isArchived || !currentClass.active) {
        const newClass = getClasses().filter((theClass: any) => theClass.schoolID === currentSchool && !theClass.isArchived && theClass.active)[0].classID;
        dispatch(setClassbookFilterClassID([newClass]));
        loadRecords(newClass, currentDate, list); 
      };
    }
    setState("isArchived", !state.isArchived);
  };

  const handleExport = (title: any, name: any, type: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportClassbookDefault = () => {
      exportService && exportService.exportClassbook(moment(currentDate).format("YYYY-MM-DD"), currentSchool, currentClass, format).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = format === "pdf" ? 'application/pdf' : 'application/vnd.ms-excel;charset=utf-8';
        const blob = new Blob([buf], {
            type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const handleExportClassbookYear = () => {
      exportService && exportService.exportClassbook(moment(currentDate).format("YYYY-MM-DD"), currentSchool, currentClass, format, "year_export=1").then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = format === "pdf" ? 'application/pdf' : 'application/vnd.ms-excel;charset=utf-8';
        const blob = new Blob([buf], {
            type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const handleExportClassbookMonth = () => {
      exportService && exportService.exportClassbook(moment(currentDate).format("YYYY-MM-DD"), currentSchool, currentClass, format, "month_export=1").then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = format === "pdf" ? 'application/pdf' : 'application/vnd.ms-excel;charset=utf-8';
        const blob = new Blob([buf], {
            type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const getFunction = () => {
      if(type === "year") return handleExportClassbookYear;
      else if(type === "month") return handleExportClassbookMonth;
      else return handleExportClassbookDefault;
    };
    const getCustom = () => {
      if(type === "year") return "year_export=1";
      else if(type === "month") return "month_export=1";
      else return "";
    };
    if(format === "pdf") {
      const settings = {
        isOpen: true,
        title: `${title} <span>${fileName}</span>`,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
      exportService && exportService.exportClassbook(moment(currentDate).format("YYYY-MM-DD"), currentSchool, currentClass, format, getCustom()).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
            type: buftype,
        });
        const content = URL.createObjectURL(blob);
        const settings = {
          content: content,
          download: getFunction(),
          type: format,
        };
        dispatch(setAttachmentModal(settings));
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
        const settings = {
          isOpen: false,
          title: null,
          content: null,
          download: null,
        };
        dispatch(setAttachmentModal(settings));
      });
    } else {
      getFunction()();
    }
  };

  const dropdownButtonItems = [
    {
      title: t('export_pdf'),
      icon: (<SVG src="download"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${DateFormat(moment(startDate),"default", languageData, t)}-${DateFormat(moment(endDate),"default", languageData, t)}`, 'default', 'pdf'),
    },
    classbookType === "weekly" ? {
      title: t('export_pdf_year'),
      icon: (<SVG src="document-pdf"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${t('year')}`, 'year', 'pdf'),
    } : null,
    classbookType === "daily" ? {
      title: t('export_pdf_month'),
      icon: (<SVG src="document-pdf"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${t('month')}`, 'month', 'pdf'),
    } : null,
    {
      title: t('export_xlsx'),
      icon: (<SVG src="document-xlsx"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${DateFormat(moment(startDate),"default", languageData, t)}-${DateFormat(moment(endDate),"default", languageData, t)}`, 'default', 'xlsx'),
    },
    classbookType === "weekly" ? {
      title: t('export_xlsx_year'),
      icon: (<SVG src="document-xlsx"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${t('year')}`, 'year', 'xlsx'),
    } : null,
    classbookType === "daily" ? {
      title: t('export_xlsx_month'),
      icon: (<SVG src="document-xlsx"/>),
      onClick: () => handleExport(t('classbook_export'), `${t('classbook')}-${getClassData(currentClass).name}-${t('month')}`, 'month', 'xlsx'),
    } : null,
  ];

  const uniqueChildren = (children: any) => {
    let uniqueChildrenList: any = [];
    children.forEach((child: any) => {
      if(uniqueChildrenList.indexOf(child.childID) === -1) {
        uniqueChildrenList.push(child.childID);
      }
    });
    return uniqueChildrenList; 
  };

  const loadRecords = useCallback((tempCurClass: any, tempCurDate: any, tempRecordsList: any) => {
    const newDate = moment(tempCurDate).format("YYYY-MM-DD");
    const tempClassBookType = getClassData(tempCurClass).classbookType;
    const tempStartDate = tempClassBookType === "weekly" ? moment(tempCurDate).startOf('month').format("YYYY-MM-DD") : moment(tempCurDate).startOf('isoWeek').format("YYYY-MM-DD");
    const tempEndDate = tempClassBookType === "weekly" ? moment(tempCurDate).endOf('month').format("YYYY-MM-DD") : moment(tempCurDate).startOf('isoWeek').add(6, "days").format("YYYY-MM-DD");
    if(tempRecordsList.filter((item: any) => item.startDate === tempStartDate && item.endDate === tempEndDate && item.classID === tempCurClass).length === 0) {
      classbookService && classbookService.listRecords(tempCurClass, newDate).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.classbookRecords) {
              const payload = {
                classID: tempCurClass,
                dateFrom: tempStartDate,
                dateTo: tempEndDate,
                from: "classbook",
              };
              const recordsList = result.data.classbookRecords;
              timelineService && timelineService.listPosts(payload).then((result: any) => {
                const posts = result.data.posts;
                const recordData = recordsList.map((record: any) => {
                  if(tempClassBookType === "weekly") {
                    return {...record, postCount: posts.filter((post: any) => moment(post.created).isSameOrAfter(moment(record.dateFrom)) && moment(post.created).isSameOrBefore(moment(record.dateTo)) && post.activityTypeID !== 15 && post.postID !== 0).map((post: any) => { return post.postID }), noteCount: posts.filter((post: any) => moment(post.created).isSameOrAfter(moment(record.dateFrom)) && moment(post.created).isSameOrBefore(moment(record.dateTo)) && post.activityTypeID === 15 && post.postID !== 0).map((post: any) => { return post.postID })};
                  } else {
                    return {...record, postCount: posts.filter((post: any) => moment(post.created).format("YYYY-MM-DD") === moment(record.date).format("YYYY-MM-DD") && post.activityTypeID !== 15 && post.postID !== 0).map((post: any) => { return post.postID }), noteCount: posts.filter((post: any) => moment(post.created).format("YYYY-MM-DD") === moment(record.date).format("YYYY-MM-DD") && post.activityTypeID === 15 && post.postID !== 0).map((post: any) => { return post.postID })};
                  }
                });
                const recordsData = {startDate: tempStartDate, endDate: tempEndDate, classID: tempCurClass, records: recordData};
                dispatch(setClassbookList(recordsData)); 
                setState("isReady", true);
              }).catch(() => {
                createNotification(t("classbook_failed_load"), "error");
              });
            } else {
              createNotification(t("classbook_failed_load"), "error");
              setState("isReady", true);
            }
          } else {
            createNotification(t("classbook_failed_load"), "error");
            setState("isReady", true);
            return;
          }
        } else {
          createNotification(t("classbook_failed_load"), "error");
          setState("isReady", true);
          return;
        }
      }).catch(() => {
        createNotification(t("classbook_failed_load"), "error");
        setState("isReady", true);
      });
    } else {
      setState("isReady", true);
    }
  }, [classbookService, dispatch, setState, t, timelineService, getClassData]);

  useEffect(() => {
    loadRecords(currentClass, currentDate, list);
    return () => {
      dispatch(setClassbookList([]));
    };
  }, [dispatch, loadRecords, currentClass, currentDate, list], []);

  return state.isReady ? (
    <>
      <div className={classes.controls}>
        <div className={classes.filters}>
          <Select inputLabel={t("school")} items={schoolList} selected={(schoolList && schoolList.length > 0) ? (currentSchool === null ? schoolList[0] : getSchoolData(currentSchool)) : null} setSelected={handleChangeSchool} width={200} allowClear={false} readonly={schoolList.length === 1} dataCy='school'/>
          <Select inputLabel={t("class")} items={classList} selected={(classList && classList.length > 0) ? (currentClass=== null ? ((classList[0].isArchived || !classList[0].active) ? {...getClassData(classList[0]), icon: "archived"} : getClassData(classList[0])) : ((getClassData(currentClass).isArchived || !getClassData(currentClass).active) ? {...getClassData(currentClass), icon: "archived"} : getClassData(currentClass))) : null} setSelected={handleChangeClass} width={200} allowClear={false} readonly={classList.length === 1} dataCy='class'/>
          {
            classbookType === "weekly" ? (
              <MonthPicker presetDate={currentDate} setDate={handleChangeDate} dataCy='monthPicker'/>
            ) : (
              <DatePicker presetDate={currentDate} setDate={handleChangeDate} dataCy='datePicker'/>
            )
          }
          {
            showArchivedToggle() ? (
              <Switch checked={state.isArchived} onChange={handleArchived} label={t('archived')} dataCy='archivedSwitch'/>
            ) : null
          }
        </div>
        <DropdownWithButton className={classes.dropdownWithButton} items={dropdownButtonItems} dataCy="exportButton"/>
      </div>
      <div className={classes.infoBar}>
        <span data-cy={isCypress() ? 'currentClass' : null} data-clarity-unmask="true">
          {
            (currentClass && getClassData(currentClass).isArchived) ? (
              <SVG src="archived"/>
            ) : (currentClass && !getClassData(currentClass).active) ? (
              <SVG src="inactive"/>
            ) : null
          }
          {currentClass ? htmlParse(getClassData(currentClass).name) : null}
        </span>
        <div className={classes.childrenCount} data-cy={isCypress() ? 'childrenCountWrapper' : null}  data-clarity-unmask="true">
          <SVG src="people"/>
          <span data-cy={isCypress() ? 'childrenCount' : null} data-clarity-unmask="true">{currentClass ? uniqueChildren(getChildren().filter((theChild: any) => theChild.classID.indexOf(currentClass) !== -1)).length : 0}</span>
        </div>
        <p data-cy={isCypress() ? 'currentDateRange' : null} data-clarity-unmask="true">{DateFormat(moment(startDate), "default", languageData, t)} - {DateFormat(moment(endDate), "default", languageData, t)}</p>
      </div>
      <ClassbookTable/>
    </>
  ) : (
    <>
      <div className={classes.controls}>
        <div className={classes.filtersSkeleton}>
          <Skeleton variant="rounded" width={smallDevice ? '100%' : 200} height={42}/>
          <Skeleton variant="rounded" width={smallDevice ? '100%' : 200} height={42}/>
          <Skeleton variant="rounded" width={smallDevice ? '100%' : 200} height={42}/>
          <Skeleton variant="rounded" width={112} height={16}/>
        </div>
        <div className={classes.dropdownWithButtonSkeleton}>
          <Skeleton variant="rounded" width={129} height={42}/>
          <Skeleton variant="rounded" width={52} height={42}/>
        </div>
      </div>
      <div className={classes.infoBarSkeleton}>
        <Skeleton variant="rounded" width={120} height={25}/>
        <Skeleton variant="rounded" width={54} height={29}/>
        <Skeleton variant="rounded" width={180} height={25}/>
      </div>
      <ClassbookTable/>
    </>
  );
};

export default Weekview;