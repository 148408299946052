import PublicLayout from '../layouts/PublicLayout';
import { getAppData, setAppData } from 'src/utils/useApp';
import { setUrlParams } from 'src/store/actions/layout.actions';
import { useAppDispatch } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';

const PublicRoute = ({ children }: { children: JSX.Element }) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const savedData = getAppData();
  const params = useParams();
  const newData: any = {...savedData, lastUrl: location.pathname};
  setAppData(newData);

  useEffect(() => {
    dispatch(setUrlParams(params));
  }, [dispatch, params], [params]);

  return (
    <PublicLayout>
      {children}
    </PublicLayout>
  );
};

export default PublicRoute;