import Currency from 'react-currency-formatter';
import IconButton from 'src/components/Buttons/IconButton';
import React, { useCallback, useMemo } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum, isKey } from 'src/utils/useFunctions';
import { setConfirmModal, setStockItemDetailModal, setStockItemHandleModal } from 'src/store/actions/modals.actions';
import { setStockItems } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '&:only-child': {
        '& > span': {
          justifyContent: 'center',
        },
      },
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  icon: {
    width: '20px',
    paddingLeft: '8px',
  },
  name: {
    fontWeight: '500',
    maxWidth: '300px',
    width: '300px',
    '& > span': {
      display: 'block !important',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
    },
  }, 
  quantity: {
    paddingLeft: '8px',
  }, 
  price: {
    paddingLeft: '8px',
  },
  sku: {
    paddingLeft: '8px',
  }, 
  upc: {
    paddingLeft: '8px',
  }, 
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: 'calc(72px - 16px)',
  },
  button: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: '#5AB8FF',
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

type StockItemsTableType = {
  schoolID: any;
};

const StockItemsTable: React.FunctionComponent<StockItemsTableType> = ({schoolID = null}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const stockData = useAppSelector((state: any) => state.stock);
  const userData = useAppSelector((state: any) => state.user);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const classes = useStyles();

  const schoolSettings = userData.schoolSettings;
  const items = stockData.items;

  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = useMemo(() => { return currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : []}, [configurationData.currencies, currencyID]);
  
  const getItemData = useCallback((itemID: any) => {
    return items.filter((item: any) => item.itemID === itemID).length === 0 ? [] : items.find((item: any) => item.itemID === itemID);
  }, [items]);

  const getPrice = useCallback((itemID: any) => {
    const tax = 1;
    const price = getItemData(itemID).variant.length === 0 ? (getItemData(itemID).price * tax) : (Math.min(...getItemData(itemID).variant.map((item: any) => { return (item.price * tax); })) === Math.max(...getItemData(itemID).variant.map((item: any) => { return (item.price * tax); })) ? getItemData(itemID).variant[0].price : [Math.min(...getItemData(itemID).variant.map((item: any) => { return (item.price * tax); })), Math.max(...getItemData(itemID).variant.map((item: any) => { return (item.price * tax); }))]);
    return price;
  }, [getItemData]);

  const getQuantity = useCallback((itemID: any) => {
    const quantity = getItemData(itemID).quantity === null ? null : (getItemData(itemID).variant.length === 0 ? getItemData(itemID).quantity : getItemData(itemID).variant.map((item: any) => { return item.quantity; }).reduce(handleSum, 0));
    return quantity;
  }, [getItemData]);

  const handleClickDetail = (itemID: any) => {
    const settings = {
      isOpen: true,
      itemID: itemID,
      schoolID: schoolID,
    };
    dispatch(setStockItemDetailModal(settings)); 
  };

  const handleClickEdit = (itemID: any) => {
    const settings = {
      isOpen: true,
      itemID: itemID,
      schoolID: schoolID,
    };
    dispatch(setStockItemHandleModal(settings));
  };


  const handleClickDelete = (itemID: any) => {
    const onAccept = () => {
      stockService && stockService.deleteItem(itemID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("stock_item_deleted"), "success");
            const newItems = stockData.items.map((item: any) => {
              if(item.itemID === itemID) {
                return null;
              } else {
                return item;
              }
            }).filter((item: any) => item !== null);
            dispatch(setStockItems(newItems));
          } else {
            createNotification(t("stock_item_not_deleted"), "error");
          }
        } else {
          createNotification(t("stock_item_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_item_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('stock_item_delete'),
      content: t('stock_item_delete_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th>
              {t('stock_item_name')}
            </th>
            <th>
              {t('stock_item_quantity')}
            </th>
            <th>
              {t('stock_item_price_without_tax')}
            </th>
            <th>
              {t('stock_item_sku')}
            </th>
            <th>
              {t('stock_item_upc')}
            </th>
            <th>
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            items.length > 0 ? items.map((item: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td className={classes.name}>
                  <span>{item.name}</span>
                </td>
                <td className={classes.quantity}>
                  <span>{getQuantity(item.itemID) === null ? t('stock_quantity_unlimited') : getQuantity(item.itemID)}</span>
                </td>
                <td className={classes.price}>
                  <span>
                  {
                    Array.isArray(getPrice(item.itemID)) ? (
                      <>
                        <Currency quantity={parseFloat(getPrice(item.itemID)[0])} currency={getCurrency.iso}/>
                        &nbsp;-&nbsp;
                        <Currency quantity={parseFloat(getPrice(item.itemID)[1])} currency={getCurrency.iso}/>
                      </>
                    ) : (
                      <Currency quantity={parseFloat(getPrice(item.itemID))} currency={getCurrency.iso}/>
                    )
                  }
                  </span>
                </td>
                <td className={classes.sku}>
                  <span>{item.sku}</span>
                </td>
                <td className={classes.upc}>
                  <span>{item.upc}</span>
                </td>
                <td className={classes.action}>
                  <IconButton className={classes.button} onClick={() => handleClickDetail(item.itemID)} tooltip={t('stock_item_detail')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="eye"/>
                  </IconButton>
                  <IconButton className={classes.button} onClick={() => handleClickEdit(item.itemID)} tooltip={t('stock_item_edit')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="edit"/>
                  </IconButton>
                  <IconButton className={classes.button} onClick={() => handleClickDelete(item.itemID)} tooltip={t('stock_item_delete')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="trash-outlined"/>
                  </IconButton>
                </td>
              </tr>
            )) : (
              <tr className={classes.tableRow}>
                <td colSpan={6}>
                  <span>{t('no_stock_items_found')}</span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default StockItemsTable;