import NormalButton from '../../../components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getUserHomepage } from 'src/utils/useUser';
import { setCurrentMenu } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  forbiddenPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: 'calc(100% - 112px)',
    overflow: 'auto',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginTop: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },      
  },
  title: {
    color: '#00A2FF',
    fontSize: '96px',
    fontWeight: '500',
    lineHeight: '96px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '54px',
      lineHeight: '54px',
    }, 
    [theme.breakpoints.down('md')]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '56px',
      lineHeight: '56px',
    },  
  },
  subtitle: {
    color: '#003065',
    fontSize: '48px',
    fontWeight: '400',
    lineHeight: '48px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '40px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '24px',
    }, 
  },
  text: {
    marginTop: '16px',
    fontSize: '26px',
    color: '#013164',
    [theme.breakpoints.down('lg')]: {
      fontSize: '26px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '16px',
    }, 
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginTop: '16px',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },      
  },
  block: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '200px',
    clipPath: 'ellipse(60% 90% at 50% 100%)',
    backgroundColor: '#00A2FF',
    marginTop: 'auto',  
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const fromUrl = (location && location.state && location.state.from) ? location.state.from.pathname : "403";
  const homepage = userData.userStatus === "loggedIn" ? getUserHomepage(dispatch, dataData, layoutData, userData) : "/";
  
  const handleGoBack = (e: any) => {
    e.stopPropagation();
    dispatch(setCurrentMenu("main"));
    navigate(homepage);
  }; 
  
  const handleGoLogin = (e: any) => {
    e.stopPropagation();
    navigate("/auth/login");
  }; 

  useEffect(() => {
    window.history.pushState({}, "", homepage);
    window.history.pushState({}, "", fromUrl);
  }, [homepage, fromUrl, dispatch], []);
  
  return (
    <div className={classes.forbiddenPage}>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>403</h1>
        <h2 className={classes.subtitle}>{t('page_forbidden')}...</h2>
        <p className={classes.text}>{t('page_forbidden_detail')}...</p>
      </div>
      <div className={classes.buttonWrapper}>
        <NormalButton buttonType={"darkBlue"} onClick={handleGoBack} dataCy="goBackButton">
          {t('back_to_app')}
        </NormalButton>
        {
          userData.userStatus !== "loggedIn" ? (
            <NormalButton buttonType={"darkBlueOutlined"} onClick={handleGoLogin} dataCy="loginButton">
              {t('login')}
            </NormalButton> 
          ) : null
        } 
      </div>
      <div className={classes.block}/>
    </div>
  );
};

export default PageTemplate;