import HttpService from '../http.service';

const listMeals = (dateFrom: any, dateTo: any, schoolID: any) => {
  return HttpService.get(`meal/menu?dateFrom=${dateFrom}&dateTo=${dateTo}&schoolID=${schoolID}`, {}, {});
};

const createMealOrder = (payload: any) => {
  return HttpService.post(`meal/menu`, payload, {}, {});
};

const deleteMealOrder = (date: any, schoolID: any, mealMenuID: any) => {
  return HttpService.remove(`meal/menu?date=${date}&schoolID=${schoolID}&mealMenuID=${mealMenuID}`, {});
};

export {
  listMeals,
  createMealOrder,
  deleteMealOrder,
};