import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import StorageService from 'src/services/storage.service';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { languages } from 'src/constants/languages';
import { setAppData } from 'src/utils/useApp';
import { setCookies } from 'src/store/actions/layout.actions';
import { setCookiesModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  detail: {
    display: 'block',
  },
  description: {
    fontSize: '14px',
  },
  link: {
    color: theme.colors.black,
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '4px',
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important', 
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.grey[300],
    padding: '8px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      margin: '12px 0',
      ' & > span': {
        fontSize: '14px',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: theme.colors.grey[200],
    '& > p': {
      fontSize: '13px',
    }
  },
  switch: {
    marginLeft: 'auto',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: '16px',
    gap: '4px',
    '& > button': {
      '&:last-of-type': {
        marginLeft: 'auto',
      },
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& > button': {
        '&:last-of-type': {
          marginLeft: 'unset !important',
        },
      },
    },
  },
}));

const CookiesModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const languageData = useAppSelector((state: any) => state.language);
  const layoutData = useAppSelector((state: any) => state.layout);

  const cookiesSettings = layoutData.cookies;
  
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(cookiesSettings.includes("analytics"));
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
    };
    dispatch(setCookiesModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleLink = () => {
    const langs: any = languages;
    const url: any = langs.find((lang: any) => lang.acronym === languageData.language).cookiesLink;
    window.open(url);
  };

  const handleSwitchNecessary = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSwitchAnalytics = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsAnalyticsEnabled(!isAnalyticsEnabled);
  };

  const handleConfirm = () => {
    const cookies = ["necessary", "analytics"];
    dispatch(setCookies(cookies));
    setAppData({cookies: cookies});
    createNotification(t('cookies_settings_saved'), 'success');
    onCloseModal();
  };

  const handleConfirmNeccesary = () => {
    const cookies = ["necessary"];
    dispatch(setCookies(cookies));
    setAppData({cookies: cookies});
    createNotification(t('cookies_settings_saved'), 'success');
    StorageService.deleteAllCookies();
    onCloseModal();
  };

  const handleConfirmSettings = () => {
    const cookies = isAnalyticsEnabled ? ["necessary", "analytics"] : ["necessary"];
    if(!isAnalyticsEnabled) {
      StorageService.deleteAllCookies();
    }
    dispatch(setCookies(cookies));
    setAppData({cookies: cookies});
    createNotification(t('cookies_settings_saved'), 'success');
    onCloseModal();
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "cookiesModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-cy={isCypress() ? 'cookiesModalTitle' : null}>{t('cookies_consent_settings_title')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.detail}>
            <p className={classes.description} data-cy={isCypress() ? 'cookiesModalDescription' : null}>
              {t('cookies_consent_settings_description')}
              <span className={classes.link} onClick={handleLink} data-cy={isCypress() ? 'cookiesModalLink' : null}>
                {t('cookies_consent_link')}
              </span>
            </p>
          </div>
          <div className={classes.accordions}>
            <Accordion className={classes.accordion} data-cy={isCypress() ? 'cookiesModalNecessaryAccordion' : null}>
              <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                <span>{t('cookies_consent_necessary_title')}</span>
                <Switch className={classes.switch} onClick={handleSwitchNecessary} checked={true} disabled={true} dataCy="cookiesModalNecessarySwitch"/>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <p>
                  {t('cookies_consent_necessary_text')}
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion} data-cy={isCypress() ? 'cookiesModalAnalyticsAccordion' : null}>
              <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                <span>{t('cookies_consent_analytics_title')}</span>
                <Switch className={classes.switch} onClick={handleSwitchAnalytics} checked={isAnalyticsEnabled} dataCy="cookiesModalAnalyticsSwitch"/>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <p>
                  {t('cookies_consent_analytics_text')}
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="primary" onClick={handleConfirm} dataCy="cookiesModalConfirm">
            {t("cookies_consent_confirm_all")}
          </NormalButton>
          <NormalButton buttonType="secondary" onClick={handleConfirmNeccesary} dataCy="cookiesModalNecessary">
            {t("cookies_consent_confirm_necessary")}
          </NormalButton>
          <NormalButton buttonType="secondary" onClick={handleConfirmSettings} dataCy="cookiesModalSave">
            {t("cookies_consent_save_settings")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default CookiesModal;