import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  breakHourBlock: {
      backgroundColor: theme.colors.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[100],
      borderRadius: '12px',
      boxShadow: theme.shadows[2],
      padding: '4px 0px',
      width: '46px',
      textAlign: 'center',
  },
}));

type BreakHourBlockType = {
  hour: any;
  isFirstBreakZero: any;
};

const BreakHourBlock: React.FunctionComponent<BreakHourBlockType> = ({hour, isFirstBreakZero}) => {

  const classes = useStyles();

  return (
    <div className={classes.breakHourBlock}>
      {isFirstBreakZero ? (hour + 1) : hour}.
    </div>
  );
}

export default BreakHourBlock;