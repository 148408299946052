import CommentCard from 'src/components/Cards/CommentCard';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { replaceComment, updateComments } from 'src/store/actions/comments.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  timelineCommentsThreads: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    overflow: 'visible',
    gap: '8px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      overflow: 'unset',
    },
  },
  commentsThreadsList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    height: 'auto',
    maxHeight: '100%',
    padding: "0px 0",
    gap: '8px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
    },
    '& > div': {
      width: '100%',
    },
  },
  commentsThreadsListRepliesWrapper: {
    display: 'flex',
    gap: '8px',
    padding: '8px 16px',
    width: 'calc(100% - 32px)',
  },
  commentsThreadsListRepliesPath: {
    position: 'relative',
    width: '48px',
    minWidth: '48px',
    flex: '0 0 48px',
  },
  loadReplies: {
    display: 'flex',
    gap: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    padding: '12px 0px 6px 0px',
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flex: '1 1 100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  lineVertical: {
    position: 'absolute',
    top: '-6px',
    left: '-34px',
    right: '0',
    width: (props: Props) => {
      if(props.isLoading) return '50%';
      else return '24px';
    },
    height: '24px',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.colors.grey[500],
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.grey[500],
    borderBottomLeftRadius: '24px 24px',
  },
}));

type TimelineCommentsThreadsType = {
  uniqueID: any;
  threadID: any;
};

const TimelineCommentsThreads: React.FunctionComponent<TimelineCommentsThreadsType> = ({ uniqueID, threadID }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const commentsService = useAppSelector((state: any) => state.services).commentsService;
  const commentsData = useAppSelector((state: any) => state.comments);
  const comments = commentsData.comments;
  const commentData = useMemo(() => comments.filter((item: any) => item.commentID === threadID).length === 0 ? [] : comments.find((item: any) => item.commentID === threadID), [comments, threadID]);
  const limit = 15;

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoading: false,
    isLoadingMore: false,
    loaded: 0,
    total: 0,
    page: 0,
  });

  const classes = useStyles({
    isLoading: state.isLoading
  });

  const removeThreads = useCallback(() => {
    const newData = {
      commentID: threadID,
      data: {...commentData, countComments: 0},
    }
    dispatch(replaceComment(newData));
  }, [commentData, dispatch, threadID]);
  
  const loadThreads = useCallback(() => {
    if(state.isLoadingMore) return;
    if(!state.isFirstTime) {
      setState("isLoading", true);
      setState("isLoadingMore", true);
    }
    let currentPage = state.page;
    currentPage++;
    commentsService && commentsService.listComments(threadID, "thread", currentPage, limit).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.comments) {
            if(result.data.comments.length === 0 ) {
              removeThreads();
            } else {
              const resultComments = result.data.comments;
              if(state.isFirstTime && currentPage === 1) {
                if(resultComments.length < 3) {
                  dispatch(updateComments(resultComments));
                  setState("page", currentPage);
                  setState("loaded", state.loaded + resultComments.length);
                }
                setState("total", result.data.countComments);
                setState("isLoading", false);
                setState("isLoadingMore", false);
                setState("isFirstTime", false);
              } else {
                dispatch(updateComments(resultComments));
                setState("page", currentPage);
                setState("loaded", state.loaded + resultComments.length);
                setState("isLoading", false);
                setState("isLoadingMore", false);
              }
            }
          } else {
            createNotification(t("comments_not_loaded"), "error");
          }
        } else {
          createNotification(t("comments_not_loaded"), "error");
        }
      } else {
        createNotification(t("comments_not_loaded"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("comments_not_loaded"), "error");
    });
  }, [commentsService, setState, state.isFirstTime, state.isLoadingMore, state.page, t, threadID, state.loaded, dispatch, removeThreads]);

  useEffect(() => {
    loadThreads();
  }, [loadThreads], [threadID]);
 
  return (
    <div className={classes.timelineCommentsThreads} data-cy={isCypress() ? "timelineCommentsThreads" : null}>
      {
        state.loaded > 0 ? (
          <>
            <div className={classes.commentsThreadsList}>
              {
                comments.filter((item: any) => item.threadID === threadID).map((item: any, key: any) => (
                  <CommentCard key={`k_${key}`} uniqueID={uniqueID} commentID={item.commentID}/>
                ))
              }
            </div>
          </>
        ) : null
      }
      {
        (state.isLoading && state.isLoadingMore && !state.isFirstTime) ? (
          <div className={classes.commentsThreadsList}>
            <span className={classes.lineVertical}/>
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner}/>
            </div>
          </div>
        ) : null
      }
      {
        (!state.isLoading && !state.isLoadingMore && (state.loaded < state.total || state.total === 0)) ? (
          <div className={classes.commentsThreadsList}>
            <span className={classes.lineVertical}/>
            <span className={classes.loadReplies} onClick={loadThreads}>
              <SVG src="more-replies"/>
              {t('comments_load_threads')}
            </span>
          </div>
        ) : null
      }
    </div>
  );
}

export default TimelineCommentsThreads;