import * as UserService from 'src/services/user.service';
import Label from 'src/components/Forms/Label';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import Select from 'src/components/Forms/Select';
import Switch from 'src/components/Forms/Switch';
import { createFirebaseNotification } from 'src/utils/createFirebaseNotification';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppData, setAppData } from 'src/utils/useApp';
import { isKey } from 'src/utils/useFunctions';
import { isNotificationSupported, requestPermission } from 'src/utils/useFirebase';
import { clearFirebaseNotification, setFirebasePermission, setFirebasePosition, setFirebaseTimeout } from 'src/store/actions/firebasenotification.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  notificationsWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  notifications: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 68px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  optionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    gap: '8px',
  },
  hidden: {
    display: 'none',
    pointerEvents: 'none',
  },
  switch: {
    width: '100%',
    '& > label': {
      fontSize: '14px',
    },
  },
  select: {
    flex: 'unset',
    width: '100%',
  },
}));

const NotificationsSettings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const loadingData = useAppSelector((state: any) => state.loading);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const savedData = getAppData();
  const webAppNotifications = savedData.notifications;

  const isBrowserNotificationsSupported = isNotificationSupported;
  const browserNotificationStatus = isNotificationSupported ? Notification.permission : false;
  const webAppNotificationPosition = useAppSelector((state: any) => state.firebasenotification.position);
  const webAppNotificationTimeout = useAppSelector((state: any) => state.firebasenotification.timeout);

  const webAppNotificationsPositions = useCallback(() => [
    {
      value: 'top-left',
      name: t('top_left'),
    },
    {
      value: 'top-center',
      name: t('top_center'),
    },
    {
      value: 'top-right',
      name: t('top_right'),
    },
    {
      value: 'bottom-left',
      name: t('bottom_left'),
    },
    {
      value: 'bottom-center',
      name: t('bottom_center'),
    },
    {
      value: 'bottom-right',
      name: t('bottom_right'),
    },
  ], [t]);

  const webAppNotificationsTimeouts = useCallback(() => [
    {
      value: '0',
      name: t('never'),
    },
    {
      value: '3500',
      name: `3.5 ${t('seconds').toLowerCase()}`,
    },
    {
      value: '5000',
      name: `5 ${t('seconds_more').toLowerCase()}`,
    },
    {
      value: '7500',
      name: `7.5 ${t('seconds').toLowerCase()}`,
    },
    {
      value: '10000',
      name: `10 ${t('seconds_more').toLowerCase()}`,
    },
    {
      value: '30000',
      name: `30 ${t('seconds_more').toLowerCase()}`,
    },
    {
      value: '60000',
      name: `1 ${t('minute').toLowerCase()}`,
    },
  ], [t]);
  
  const [state, setState] = useStates({
    isProcessing: false,
    webAppNotifications: webAppNotifications,
    webAppNotificationPosition: webAppNotificationsPositions().filter((item: any) => item.value === webAppNotificationPosition).length === 0 ? webAppNotificationsPositions()[2] : webAppNotificationsPositions().find((item: any) => item.value === webAppNotificationPosition),
    webAppNotificationTimeout: webAppNotificationsTimeouts().filter((item: any) => item.value === webAppNotificationTimeout).length === 0 ? webAppNotificationsTimeouts()[1] : webAppNotificationsTimeouts().find((item: any) => item.value === webAppNotificationTimeout),
  });

  const webAppNotificationStatus = state.webAppNotifications.permission;

  const updateWebAppNotifications = (data: any) => {
    setState("webAppNotifications", Object.assign(state.webAppNotifications, data));
    setAppData({notifications: data});
  };

  const handleBrowserAppNotification = async () => {
    if(browserNotificationStatus === "default" && isNotificationSupported) {
      setState("isProcessing", true);
      const data: any = await requestPermission();
      const permission = data[0];
      const fcmToken = data[1];
      UserService && UserService.setNotificationFcmToken(fcmToken).then(() => {
        const notifications = {...webAppNotifications, permission: permission, fcmToken: fcmToken};
        updateWebAppNotifications(notifications);
        setState("isProcessing", false);
        dispatch(setFirebasePermission(permission));
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("notification_fcm_token_not_set"), "error");
        setState("isProcessing", false);
        dispatch(setFirebasePermission(permission));
      });
    }
  };

  const handleWebAppNotificationsSwitch = () => {
    setState("isProcessing", true);
    if(webAppNotificationStatus === "granted") {
      handleWebAppNotificationDisable();
    } else {
      handleWebAppNotificationEnable();
    }
  };

  const handleWebAppNotificationEnable = async () => {
    if(isNotificationSupported) {
      const data: any = await requestPermission();
      const permission = data[0];
      const fcmToken = data[1];
      if(permission === 'granted') {
        UserService && UserService.setNotificationFcmToken(fcmToken).then(() => {
          const notifications = {...webAppNotifications, permission: permission, fcmToken: fcmToken};
          updateWebAppNotifications(notifications);
          setState("isProcessing", false);
          dispatch(setFirebasePermission(permission));
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("notification_fcm_token_not_set"), "error");
          setState("isProcessing", false);
          dispatch(setFirebasePermission(permission));
        });
      } else {
        createNotification(t("notification_fcm_token_not_set"), "error");
        dispatch(setFirebasePermission(permission));
      }
    }
  };  

  const handleWebAppNotificationDisable = () => {
    if(isNotificationSupported) {
      const permission = 'denied';
      UserService && UserService.setNotificationFcmToken('').then(() => {
        const notifications = {...webAppNotifications, permission: permission, fcmToken: ''};
        updateWebAppNotifications(notifications);
        setState("isProcessing", false);
        dispatch(setFirebasePermission(permission));
        dispatch(clearFirebaseNotification());
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("notification_fcm_token_not_set"), "error");
        setState("isProcessing", false);
        dispatch(setFirebasePermission('granted'));
      });
    }
  };

  const handleWebAppNotificationsPosition = (position: any) => {
    setState("webAppNotificationPosition", position);
    dispatch(setFirebasePosition(position.value));
    const notifications = {...webAppNotifications, position: position.value};
    updateWebAppNotifications(notifications);
  };

  const handleWebAppNotificationsTimeout = (timeout: any) => {
    setState("webAppNotificationTimeout", timeout);
    dispatch(setFirebaseTimeout(timeout.value));
    const notifications = {...webAppNotifications, timeout: timeout.value};
    updateWebAppNotifications(notifications);
  };

  const handleWebAppNotificationsTest = () => {
    createFirebaseNotification(t('notifications_webapp_test_title'), t('notifications_webapp_test_text'), 'test', typeof userData.userObject.photo === "string" ? userData.userObject.photo : userData.userObject.photo.thumbLink);
  };

  useEffect(() => {
    if(loadingData.isLanguageLoaded) {
      setTimeout(() => {
        const position = webAppNotificationsPositions().filter((item: any) => item.value === state.webAppNotificationPosition.value).length === 0 ? webAppNotificationsPositions()[2] : webAppNotificationsPositions().find((item: any) => item.value === state.webAppNotificationPosition.value);
        const timeout = webAppNotificationsTimeouts().filter((item: any) => item.value === state.webAppNotificationTimeout.value).length === 0 ? webAppNotificationsTimeouts()[1] : webAppNotificationsTimeouts().find((item: any) => item.value === state.webAppNotificationTimeout.value);
        setState("webAppNotificationPosition", position);
        setState("webAppNotificationTimeout", timeout);
      }, 100);
    }
  }, [setState, webAppNotificationsPositions, state.webAppNotificationPosition, webAppNotificationsTimeouts, state.webAppNotificationTimeout, loadingData.isLanguageLoaded], [languageData.language, loadingData.isLanguageLoaded]);

  return (
    <div className={classes.notificationsWrapper}>
      <div className={classes.notifications}>
        <div className={classes.optionWrapper}>
          <Label>{t('notifications_browser')}</Label>
          {
            isBrowserNotificationsSupported ? (
              <>
                {
                  browserNotificationStatus === "granted" ? (
                    <span>
                      {t('notifications_enabled')}
                    </span>
                  ) : browserNotificationStatus === "denied" ? (
                    <span>
                      {t('notifications_disabled')}
                    </span>
                  ) : (
                    <NormalButton onClick={handleBrowserAppNotification} disabled={state.isProcessing}>{state.isProcessing ? t('notifications_browser_hint'): t('notifications_browser_enable')}</NormalButton>
                  )
                }
              </>
            ) : (
              <span>
                {t('notifications_browser_not_supported')}
              </span>
            )
          }
          {
            browserNotificationStatus === "granted" ? (
              <>
                <Switch className={classes.switch} labelLeft={t('notifications_disabled')} labelTop={t('notifications_webapp')} label={t('notifications_enabled')} checked={webAppNotificationStatus === "granted"} onChange={handleWebAppNotificationsSwitch} disabled={state.isProcessing}/>
                <Select className={classes.select} label={t('notifications_webapp_position')} items={webAppNotificationsPositions()} selected={state.webAppNotificationPosition} setSelected={handleWebAppNotificationsPosition} allowClear={false} disabled={webAppNotificationStatus !== "granted"}/>
                <Select className={classes.select} label={t('notifications_webapp_timeout')}  items={webAppNotificationsTimeouts()} selected={state.webAppNotificationTimeout} setSelected={handleWebAppNotificationsTimeout} allowClear={false} disabled={webAppNotificationStatus !== "granted"}/>
                <div className={classes.buttons}>
                  <NormalButton onClick={handleWebAppNotificationsTest} disabled={webAppNotificationStatus !== "granted"}>{t('notifications_webapp_test')}</NormalButton>
                </div>
              </>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default NotificationsSettings;