import EmojiIcon from 'src/components/Icons/EmojiIcon';
import React from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isKey } from 'src/utils/useFunctions';
import { setComments } from 'src/store/actions/comments.actions';
import { setGalleryMedias } from 'src/store/actions/gallery.actions';
import { setTimelineMedias, setTimelinePosts } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';
import { useTranslation } from 'react-i18next';
import moment from 'src/utils/moment';

const useStyles = createUseStyles((theme: any) => ({
  reactionEmoji: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.25s',
    width: '28px',
    height: '28px',
    fontSize: '90%',
    '&.active': {
      backgroundColor: theme.colors.grey[200],
      borderRadius: '100%',
    },
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}));

type ReactionsPopperListType = {
  data: any;
  ID: any;
  type: 'post' | 'media' | 'comment';
  date?: any;
  onReaction?: any;
};

const ReactionsPopperList: React.FunctionComponent<ReactionsPopperListType> = ({ data, ID, type, date, onReaction }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const commentsData = useAppSelector((state: any) => state.comments);
  const configurationData = useAppSelector((state: any) => state.configuration);
  const mediasData = useAppSelector((state: any) => state.gallery).medias;
  const timelineData = useAppSelector((state: any) => state.timeline);
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);
  const reactionsService = useAppSelector((state: any) => state.services).reactionsService;


  const tempUpdatePostReactions = (oldData: any, emojiID: any) => {
    const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
    const postID = postData.postID;
    if(oldData.myEmojiID === emojiID) {
      const newData = timelineData.posts.map((post: any) => {
        if(post.postID === postID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...post, reactions: {...post.reactions, myEmojiID: 0, totals: newTotals} };
        } else {
          return post;
        }
      });
      dispatch(setTimelinePosts(newData));
    } else {
      const newData = timelineData.posts.map((post: any) => {
        if(post.postID === postID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...post, reactions: {...post.reactions, myEmojiID: emojiID, totals: newTotals} };
        } else {
          return post;
        }
      });
      dispatch(setTimelinePosts(newData));
    }
  };

  const updatePostReactions = (reactionsData: any) => {
    const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
    const postID = postData.postID;
    const newTimelineData = timelineData.posts.map((post: any) => {
      if(post.postID === postID) {
        return { ...post, reactions: {...post.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals} };
      } else {
        return post;
      }
    });
    dispatch(setTimelinePosts(newTimelineData));
  };

  const tempUpdateMediaReactions = (oldData: any, emojiID: any) => {
    if(oldData.myEmojiID === emojiID) {
      const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
        if(newMedia.mediaID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: 0, totals: newTotals}};
        } else {
          return newMedia;
        }
      });
      dispatch(setTimelineMedias(newTimelineMediasList));
      const newItems = mediasData.map((dates: any) => {
        if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
          return {...dates, medias: dates.medias.map((media: any) => {
            if(media.mediaID === ID) {
              const newTotals = reactions.map((item: any) => {
                const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
                if(item.emojiID === emojiID) {
                  return { emojiID: item.emojiID, total: totals - 1};
                } else {
                  return { emojiID: item.emojiID, total: totals};
                }
              }).filter((item: any) => item.total !== 0);
              return {...media, reactions: {...media.reactions, myEmojiID: 0, totals: newTotals} };
            } else {
              return media;
            }
          })};
        } else {
          return dates;
        }
      });
      dispatch(setGalleryMedias(newItems));
    } else {
      const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
        if(newMedia.mediaID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: emojiID, totals: newTotals}};
        } else {
          return newMedia;
        }
      });
      dispatch(setTimelineMedias(newTimelineMediasList));
      const newItems = mediasData.map((dates: any) => {
        if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
          return {...dates, medias: dates.medias.map((media: any) => {
            if(media.mediaID === ID) {
              const newTotals = reactions.map((item: any) => {
                const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
                if(item.emojiID === emojiID) {
                  return { emojiID: item.emojiID, total: totals + 1};
                } else if(item.emojiID === oldData.myEmojiID) {
                  return { emojiID: item.emojiID, total: totals - 1};
                } else {
                  return { emojiID: item.emojiID, total: totals};
                }
              }).filter((item: any) => item.total !== 0);
              return {...media, reactions: {...media.reactions, myEmojiID: emojiID, totals: newTotals} };
            } else {
              return media;
            }
          })};
        } else {
          return dates;
        }
      });
      dispatch(setGalleryMedias(newItems));
    }
  };

  const updateMediaReactions = (reactionsData: any) => {
    const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
      if(newMedia.mediaID === ID) {
        return {...newMedia, reactions: {...newMedia.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals}};
      } else {
        return newMedia;
      }
    });
    dispatch(setTimelineMedias(newTimelineMediasList));
    const newItems = mediasData.map((dates: any) => {
      if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
        return {...dates, medias: dates.medias.map((media: any) => {
          if(media.mediaID === ID) {
            return {...media, reactions: {...media.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals} };
          } else {
            return media;
          }
        })};
      } else {
        return dates;
      }
    });
    dispatch(setGalleryMedias(newItems));
  };

  const tempUpdateCommentReactions = (oldData: any, emojiID: any) => {
    if(oldData.myEmojiID === emojiID) {
      const newData = commentsData.comments.map((comment: any) => {
        if(comment.commentID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...comment, reactions: {...comment.reactions, myEmojiID: 0, totals: newTotals} };
        } else {
          return comment;
        }
      });
      dispatch(setComments(newData));
    } else {
      const newData = commentsData.comments.map((comment: any) => {
        if(comment.commentID === ID) {
          const newTotals = reactions.map((item: any) => {
            const totals = oldData.totals.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 0 ? 0 : oldData.totals.find((reaction: any) => reaction.emojiID === item.emojiID).total;
            if(item.emojiID === emojiID) {
              return { emojiID: item.emojiID, total: totals + 1};
            } else if(item.emojiID === oldData.myEmojiID) {
              return { emojiID: item.emojiID, total: totals - 1};
            } else {
              return { emojiID: item.emojiID, total: totals};
            }
          }).filter((item: any) => item.total !== 0);
          newTotals.sort((a: any, b: any) => { return b.total - a.total });
          return { ...comment, reactions: {...comment.reactions, myEmojiID: emojiID, totals: newTotals} };
        } else {
          return comment;
        }
      });
      dispatch(setComments(newData));
    }
  };

  const updateCommentReactions = (reactionsData: any) => {
    const newTimelineCommentsList = commentsData.comments.map((newComment: any) => { 
      if(newComment.commentID === ID) {
        return {...newComment, reactions: {...newComment.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals}};
      } else {
        return newComment;
      }
    });
    dispatch(setComments(newTimelineCommentsList));
  };
  
  const handleEmoji = (emojiID: any) => {
    if(type === 'post') {
      const oldData = data.reactions;
      tempUpdatePostReactions(oldData, emojiID);
      const postData = timelineData.posts.filter((post: any) => post.uniqueID === ID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === ID);
      const postID = postData.postID;
      if(oldData.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(postID, "post").then((result: any) => {
          if(result.status === 200) {
            updatePostReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          postID: postID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updatePostReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_saved"), "error");
            dispatch(setTimelinePosts(oldData));
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
          dispatch(setTimelinePosts(oldData));
        });
      }
    } else if(type === 'media') {
      const oldData = data.reactions;
      tempUpdateMediaReactions(oldData, emojiID);
      if(data.reactions.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(ID, "media").then((result: any) => {
          if(result.status === 200) {
            updateMediaReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          mediaID: ID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updateMediaReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_saved"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      }
    } else if(type === 'comment') {
      const oldData = data.reactions;
      tempUpdateCommentReactions(oldData, emojiID);
      if(data.reactions.myEmojiID === emojiID) {
        reactionsService && reactionsService.deleteReaction(data.commentID, "comment").then((result: any) => {
          if(result.status === 200) {
            updateCommentReactions(result.data);
            createNotification(t("reaction_deleted"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_deleted"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      } else {
        const payload = {
          commentID: data.commentID,
          emojiID: emojiID,
        };
        reactionsService && reactionsService.handleReaction(payload).then((result: any) => {
          if(result.status === 200) {
            updateCommentReactions(result.data);
            createNotification(t("reaction_saved"), "success");
            if(onReaction) onReaction();
          } else {
            createNotification(t("reaction_not_saved"), "error");
          }
        }).catch((e: any) => {
          createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reaction_not_saved"), "error");
        });
      }
    }
  };
  
  return (
    <>
      {
        reactions.map((item: any, key: any) => (
          <EmojiIcon key={`k_${key}`} className={`${classes.reactionEmoji} ${(data.reactions.myEmojiID && data.reactions.myEmojiID !== 0 && data.reactions.myEmojiID === item.emojiID) ? 'active' : ''}`} emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji} tooltipTitle={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).name} tooltipPosition="top" onClick={() => handleEmoji(item.emojiID)}/>
        ))
      }
    </>
  );
}

export default ReactionsPopperList;