import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback } from 'react';
import Select from 'src/components/Forms/Select';
import ShopOrdersTable from 'src/components/Tables/ShopOrdersTable';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { setStockFilterCurrentPage, setStockFilterOrderStatusID, setStockFilterSchoolID, setStockOrders, setStockOrdersCount } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  ordersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    flexDirection: 'column',
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(70% - 32px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '16px 0px',
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '100%',
    '& > h3': {
      display: 'flex',
      gap: '4px',
      '& > span': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  ordersToolbar: {
    display: "flex",
    gap: '10px',
    borderRadius: "24px",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    transition: 'height 0.5s, padding 0.5s',
    height: '42px',
    padding: '9px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    },
    flex: '0 0 42px',
    margin: '0 auto',
  },
  shopOrdersTableWrapper: {
    position: 'relative',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
    borderRadius: '20px',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const Orders: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const userData = useAppSelector((state: any) => state.user);
  const stockService = useAppSelector((state: any) => state.services).stockService;

  const schoolSettings = userData.schoolSettings;
  const userAccessSchools = userData.userAccessSchools;
  const stockOrderStatuses = configurationData.stockOrderStatuses;

  const stockSchoolList = dataData.schools.filter((item: any) => userAccessSchools.shop.includes(item.schoolID) && getSchoolSettings(item.schoolID, "modules", schoolSettings).stock && getSchoolSettings(item.schoolID, "modules", schoolSettings).stock.orders);

  const [state, setState] = useStates({
    isLoading: false,
  });

  useEffect(() => {
    return () => {
      dispatch(setStockOrders([]));
      dispatch(setStockFilterSchoolID([]));
      dispatch(setStockFilterOrderStatusID([]));
      dispatch(setStockFilterCurrentPage(1));
    };
  }, [dispatch], []);

  const getOrders = useCallback((page: any, schoolID?: any, orderStatusID?: any) => {
    setState("isLoading", true);
    const settings = {
      schoolID: (schoolID ? schoolID : stockData.filterSchoolID).map((item: any) => { return item.schoolID; }).join(","),
      userID: userData.userObject.userID,
      orderStatusID: (orderStatusID ? orderStatusID : stockData.filterOrderStatusID).map((item: any) => { return item.orderStatusID; }).join(","),
      limit: 15,
      page: page, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockOrders(result.data.orders));
          dispatch(setStockOrdersCount(result.data.countItems));
          setState("isLoading", false);
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, stockService, userData, stockData, dispatch, setState]);

  const onPaginationChange = (page: any) => {
    dispatch(setStockFilterCurrentPage(page));
    getOrders(page);
  };

  const handleSaveSchoolID = (value: any) => {
    dispatch(setStockFilterSchoolID(value));
    dispatch(setStockFilterCurrentPage(1));
    getOrders(1, value, null);
  };

  const handleSaveOrderStatuses = (value: any) => {
    dispatch(setStockFilterOrderStatusID(value));
    dispatch(setStockFilterCurrentPage(1));
    getOrders(1, null, value);
  };

  return (
    <div className={classes.ordersWrapper}>
      <div className={classes.box}>
        <div className={classes.form}>
          <div className={classes.ordersToolbar}>
            <Select inputLabel={t("stock_order_school")} items={stockSchoolList} selected={stockData.filterSchoolID} setSelected={handleSaveSchoolID} width={200} isMultiple={true} allowClear={false} defaultTitle="name"/>
            <Select inputLabel={t("stock_order_statuses")} items={stockOrderStatuses} selected={stockData.filterOrderStatusID} setSelected={handleSaveOrderStatuses} width={200} isMultiple={true} allowClear={false} defaultTitle="name"/>
          </div>
          <div className={classes.shopOrdersTableWrapper}>
            {
              state.isLoading ? (
                <div className={classes.loadingWrapper}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : null
            }
            <ShopOrdersTable/>
          </div>
          <Pagination currentPage={stockData.filterCurrentPage} total={stockData.ordersCount} limit={15} disabled={state.isLoading} onChange={onPaginationChange}/>
        </div>
      </div>
    </div> 
  );
};

export default Orders;