import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CheckIcon from '@mui/icons-material/Check';
import htmlParse from 'html-react-parser';
import React, { useCallback } from 'react';
import RenderClasses from '../RenderClasses';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, onlyUnique } from 'src/utils/useFunctions';
import { isCypress } from 'src/utils/useCypress';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  schoolItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[0],  
  },
  schoolDetail: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '12px',
    padding: '26px 24px',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'url("/resources/icons/school-large.svg")',
      backgroundPosition: '50% 50%',
      backgroundSize: 'auto 90%',
      backgroundRepeat: 'no-repeat',
      filter: 'invert(100%)',
      opacity: '0.2',
      zIndex: '0',
    },
    '& > .MuiAccordionSummary-content': {
      position: 'relative',
      zIndex: '1',
      margin: '0',
      alignItems: 'center',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
  },
  schoolInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
  },
  schoolName: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.alwaysWhite,
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  schoolCount: {
    color: '#EEEEEE',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: 'auto 10px',
    '&.search': {
      color: '#BFBFBF',
    },   
  },
  schoolContent: {
    padding: '0',
  },
  schoolCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    marginLeft: "auto",
    fontSize: "16px",
    cursor: "pointer",
    '& p': {
      position: 'absolute',
      top: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '10px',
      textTransform: 'uppercase',
      color: theme.colors.white,
      opacity: '0',
      transition: 'opacity 0.25s',
    },
    '& .checked + p': {
      opacity: '1',
    }
  },
  schoolCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: '#359EFF',
    borderRadius: "100%",
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.white,
      '& svg': {
        opacity: '1',
        color: theme.colors.primaryBlue[500],
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  schoolExpander: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    backgroundColor: theme.colors.white,
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.grey[700],
      transition: 'transform 0.25s',
      width: '24px',
      height: '24px',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxWidth: '24px',
      maxHeight: '24px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },      
  },
}));

type RenderSchoolType = {
  state: any;
  setState: any;
  schoolData: any;
};

const RenderSchool: React.FunctionComponent<RenderSchoolType> = ({
  state,
  setState,
  schoolData,
}) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [schoolState, setSchoolState] = useStates({
    isActive: false,
    isContentVisible: false,
  });

  const userData = useAppSelector((state: any) => state.user);
  const count = useMemo(() => state.functions.getSchools().length, [state.functions], [state.functions, state.isShowArchived, state.search]);
  const schoolID = useMemo(() => schoolData.schoolID, [schoolData.schoolID]);
  const schoolInfo = useMemo(() => state.functions.getSchoolData(schoolID), [schoolID, state.functions], [schoolID, state.functions, state.isShowArchived, state.search]);

  const schoolSettings = userData.schoolSettings;

  const isSelectedChild = useMemo(() => (searchData: any) => {
    return state.selectedChildren.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  }, [state.selectedChildren]);
  
  const totalChildren = useMemo(() => state.functions.getChildrenBySchoolID(schoolID), [schoolID, state.functions], [schoolID, state.functions, state.isShowArchived, state.search]);
  const totalSelectedChildren = useMemo(() => state.functions.getClassesBySchoolID(schoolID).map((theClass: any) => {
    return state.functions.getChildrenByClassID(theClass.classID).filter((item: any) => isSelectedChild(state.isSelectInAllClass ? { childID: item.childID } : { childID: item.childID, classID: theClass.classID })).map((item: any) => { return item.childID; });
  }).flat().filter(onlyUnique), [isSelectedChild, schoolID, state.functions, state.isSelectInAllClass], [isSelectedChild, schoolID, state.functions, state.isSelectInAllClass, state.isShowArchived, state.search, state.selectedChildren]);
  
  const toggleActive = useCallback(() => {
    if(!schoolState.isActive && schoolState.isContentVisible) return;
    if(count === 1) return;
    if(schoolState.isActive) {
      setSchoolState("isActive", false);
      setTimeout(() => {
        setSchoolState("isContentVisible", false);
      }, theme.transitions.duration.complex);
    } else {
      setSchoolState("isActive", true);
      setSchoolState("isContentVisible", true);
    }
  }, [count, schoolState.isActive, schoolState.isContentVisible, setSchoolState]);

  const handleSelectAll = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(state.validateAbsence && state.selectedChildren.length !== 0) {
      const oldChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(state.selectedChildren[0].childID).schoolID, "modules", schoolSettings).absence;
      const newChildAbsenceSettings = getSchoolSettings(state.functions.getChildData(totalChildren[0].childID).schoolID, "modules", schoolSettings).absence;
      if(oldChildAbsenceSettings.allowNotes !== newChildAbsenceSettings.allowNotes || oldChildAbsenceSettings.allowDayParts !== newChildAbsenceSettings.allowDayParts) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    if(state.validateMultipleSchool && state.selectedChildren.length !== 0) {
      const oldChildSchool = state.functions.getChildData(state.selectedChildren[0].childID).schoolID;
      const newChildSchool = state.functions.getChildData(totalChildren[0].childID).schoolID;
      if(oldChildSchool !== newChildSchool) {
        const settings = {
          isOpen: true,
          title: t('cannot_combine'),
          content: state.customCombineMessage ? state.customCombineMessage : t('cannot_combine_info'),
        };
        dispatch(setWarningModal(settings));
        return;
      }
    }
    let newSelectedChildren = [...state.selectedChildren];
    if(totalChildren.length === totalSelectedChildren.length) {
      totalChildren.forEach((child: any) => {
        if(state.isSelectInAllClass) {
          newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ childID: child.childID }));
        } else {
          state.functions.getClassesBySchoolID(schoolID).forEach((theClass: any) => {
            newSelectedChildren = newSelectedChildren.filter((item: any) => JSON.stringify(item) !== JSON.stringify({ childID: child.childID, classID: theClass.classID }));
          });
        }
      });
    } else {
      totalChildren.forEach((item: any) => {
        if(state.isSelectInAllClass) {
          const newChild = { childID: item.childID };
          if(newSelectedChildren.filter((child: any) => JSON.stringify(child) === JSON.stringify(newChild)).length === 0) {
            newSelectedChildren = [...newSelectedChildren, newChild];
          }
        } else {
          state.functions.getClassesBySchoolID(schoolID).forEach((theClass: any) => {
            if(state.functions.getChildData(item.childID).classID.includes(theClass.classID)) {
              const newChild = { childID: item.childID, classID: theClass.classID };
              if(newSelectedChildren.filter((child: any) => JSON.stringify(child) === JSON.stringify(newChild)).length === 0) {
                newSelectedChildren = [...newSelectedChildren, newChild];
              }
            }
          });
        }
      });
    }
    setState("selectedChildren", newSelectedChildren);
  }, [schoolID, setState, state.functions, state.isSelectInAllClass, state.selectedChildren, state.validateAbsence, state.validateMultipleSchool, state.customCombineMessage, totalChildren, totalSelectedChildren.length, dispatch, t, schoolSettings]);
  
  useEffect(() => {
    if(state.isReload && state.isCloseAllOnReload) {
      setSchoolState("isActive", false);
      setSchoolState("isContentVisible", false);
    }
  }, [state.isReload, state.isCloseAllOnReload, setSchoolState],  [state.isReload, state.isCloseAllOnReload]);

  const schoolRender = useMemo(() => (
    <Accordion expanded={count === 1 ? true : schoolState.isActive} className={classes.schoolItem}>
      <AccordionSummary className={`${classes.schoolDetail} ${count === 1 ? 'nonclickable' : 'clickable'}`} onClick={toggleActive} data-cy={isCypress() ? `school${schoolID}` : null}>
        <span className={classes.schoolInfo}>
          <span className={classes.schoolName} data-cy={isCypress() ? 'schoolName' : null} data-clarity-unmask="true">
            {htmlParse(schoolInfo.name)}
          </span>
          {
            !state.isDisableTotalCount ? (
              <span className={`${classes.schoolCount} ${state.search ? 'search' : ''}`} data-cy={isCypress() ? 'schoolCount' : null} data-clarity-unmask="true">
                ({state.functions.getClassesBySchoolID(schoolID).length})
              </span>
            ) : null
          }
        </span>
        {
          state.isSelectAll && state.search === '' ? (
            <span className={classes.schoolCheck}>
              <span className={`${classes.schoolCheckButton} ${totalChildren.length === totalSelectedChildren.length ? 'checked' : '' }`} onClick={handleSelectAll} data-cy={isCypress() ? `school${schoolID}SelectAll` : null}>
                <CheckIcon/>
              </span>
              <p>{t("all")}</p>
            </span>
          ) : null
        }
        {
          count === 1 ? null : (
            <span className={`${classes.schoolExpander} ${schoolState.isActive ? 'active' : ''}`} data-cy={isCypress() ? 'schoolExpander' : null}>
              <SVG src="chevron-down"/>
            </span>
          )
        }
      </AccordionSummary>
      <AccordionDetails className={classes.schoolContent}>
        {
          count === 1 || schoolState.isContentVisible ? (
            <RenderClasses state={state} setState={setState} schoolData={schoolData}/>
          ) : null
        }
      </AccordionDetails>
    </Accordion>
  ), [classes.schoolCheck, classes.schoolCheckButton, classes.schoolContent, classes.schoolCount, classes.schoolDetail, classes.schoolExpander, classes.schoolInfo, classes.schoolItem, classes.schoolName, count, handleSelectAll, schoolData, schoolID, schoolInfo.name, schoolState.isActive, schoolState.isContentVisible, setState, state, t, toggleActive, totalChildren.length, totalSelectedChildren.length]);

  return schoolRender; 
}

export default RenderSchool;