import DateFormat from 'src/utils/dateFormat';
import moment from 'src/utils/moment';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  timeBlock: {
    backgroundColor: theme.colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[100],
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    fontSize: '10px',
    fontWeight: 'normal',
    padding: '4px 0px',
    width: '92px',
    textAlign: 'center',
  },
}));

type TimeBlockType = {
  start: any;
  end: any;
};

const TimeBlock: React.FunctionComponent<TimeBlockType> = ({start, end}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);

  return (
    <div className={classes.timeBlock}>
      {DateFormat(moment("1970-01-01 " + start), "hoursminutes", languageData, t)} - {DateFormat(moment("1970-01-01 " + end), "hoursminutes", languageData, t)}
    </div>
  );
}

export default TimeBlock;