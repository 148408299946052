import BlockButton from 'src/components/Buttons/BlockButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  timetableSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  buttons: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    flexWrap: 'wrap',
  },
}));

const TimetableSelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const schoolID = timetableData.school.schoolID;

  const classCount = dataData.classes.filter((item: any) => item.schoolID === schoolID && item.childrenID.length > 0);
  const employeesCount = dataData.employees.filter((item: any) => item.schoolID === schoolID && item.enableForTimetable);
  const childrenCount = dataData.children.filter((item: any) => item.schoolsID.indexOf(schoolID) !== -1);
  const breaks_rooms = timetableData.rooms.filter((item: any) => item.type === 2);
  const classic_rooms = timetableData.rooms.filter((item: any) => item.type === 1);

  const isClassDisabled = classCount.length === 0;
  const isEmployeesDisabled = employeesCount.length === 0;
  const isChildrenDisabled = childrenCount.length === 0;
  const isBreaksDisabled = breaks_rooms.length === 0;
  const isRoomsDisabled = classic_rooms.length === 0;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const setViewMode = (view: any) => {
    dispatch(setTimetableViewMode(view));
  };

  const handleClassInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_classes'),
    };
    dispatch(setWarningModal(settings));
  };

  const handleEmployeesInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_teachers'),
    };
    dispatch(setWarningModal(settings));
  };

  const handleChildrenInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_children'),
    };
    dispatch(setWarningModal(settings));
  };

  const handleBreaksInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_breaks'),
    };
    dispatch(setWarningModal(settings));
  };

  const handleRoomsInfoClick = () => {
    const settings = {
      isOpen: true,
      title: t('function_disabled'),
      content: t('function_limited_timetables_rooms'),
    };
    dispatch(setWarningModal(settings));
  };
  
  return (
    <div className={classes.timetableSelectWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.buttons}>
        <BlockButton icon={<SVG src="class"/>} onClick={() => setViewMode('classSelect')} disabled={isClassDisabled} onInfoIconClick={isClassDisabled ? handleClassInfoClick : null}>
          {t('timetable_classes')}
        </BlockButton>
        <BlockButton icon={<SVG src="employees"/>} onClick={() => setViewMode('employeeSelect')} disabled={isEmployeesDisabled} onInfoIconClick={isEmployeesDisabled ? handleEmployeesInfoClick : null}>
          {t('timetable_teachers')}
        </BlockButton>
        <BlockButton icon={<SVG src="people"/>} onClick={() => setViewMode('childSelect')} disabled={isChildrenDisabled} onInfoIconClick={isChildrenDisabled ? handleChildrenInfoClick : null}>
          {t('timetable_children')}
        </BlockButton>
        <BlockButton icon={<SVG src="clock"/>} onClick={() => setViewMode('breakSelect')} disabled={isBreaksDisabled} onInfoIconClick={isBreaksDisabled ? handleBreaksInfoClick : null}>
          {t('timetable_breaks')}
        </BlockButton>
        <BlockButton icon={<SVG src="rooms"/>} onClick={() => setViewMode('roomSelect')} disabled={isRoomsDisabled} onInfoIconClick={isRoomsDisabled ? handleRoomsInfoClick : null}>
          {t('timetable_rooms')}
        </BlockButton>
        <BlockButton icon={<SVG src="arrow-left"/>} onClick={() => setViewMode('timetableSettings')}>
          {t('back')}
        </BlockButton>
      </div>
    </div>
  );
};

export default TimetableSelect;