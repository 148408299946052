import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { Tooltip } from '@mui/material';
import RelativeDate from '../RelativeDate';
import DateFormat from 'src/utils/dateFormat';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  firebaseNotification: {
    position: 'relative',
    display: 'flex',
    padding: '12px 24px',
    borderRadius: '6px',
    width: 'calc(100% - 48px)',
    backgroundColor: theme.colors.grey[200],
    color: theme.colors.black,
    gap: '8px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '18px',
    },
    '& > p': {
      fontSize: '14px',
    },
  },
  image: {
    position: 'relative',
    width: '48px',
    height: '48px',
    minWidth: '48px',
    minHeight: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
    borderRadius: '6px',
  },
  dateTime: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    fontWeight: '400',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
  tooltip: {
    width: 'fit-content',
    marginTop: '5px !important',
  },
}));

type FirebaseNotificationStaticType = {
  title: any;
  text: any;
  image: any;
  date: any;
};

const FirebaseNotificationStatic: React.FunctionComponent<FirebaseNotificationStaticType> = ({ title, text, image, date }) => {
  
  const classes = useStyles();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  
  return (
    <div className={classes.firebaseNotification} data-cy={isCypress() ? `firebaseNotification` : null}>
      {
        image ? (
          <AuthenticatedImage className={classes.image} thumbLink={image}/>
        ) : null
      }
      <div className={classes.content}>
        <span>{title}</span>
        <p>{text}</p>
      </div>
      <Tooltip title={DateFormat(date, "comment", languageData, t)} classes={{ tooltip: classes.tooltip }} placement='left' arrow={true}>
        <span className={classes.dateTime}>
          <RelativeDate date={date}/>
        </span>
      </Tooltip>
    </div>  
  );
}

export default FirebaseNotificationStatic;