import AbsenceInfoItem from '../../../Items/AbsenceInfoItem';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../utils/moment';
import React from 'react';
import SVG from '../../../Images/SvgRenderer';
import theme from '../../../../ui/theme';
import { compareDates } from '../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { getCalendarBackgroundColor } from 'src/utils/useFunctions';
import { isCypress } from '../../../../utils/useCypress';
import { useAppSelector } from '../../../../hooks/redux-hooks';

interface Props {
  getCalendarBackgroundColor: any,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: 'calc(100% / 7)',
    height: '80px',
    gap: '10px',
    backgroundColor: (props: Props) => props.getCalendarBackgroundColor,
    '&.open': {
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[125],
      '& button': {
        opacity: '1',
      }
    },
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '& .fontDarkGray': {
      color: theme.colors.grey[550],
      fontWeight: 'normal',
    },
    '&:nth-last-of-type(7)': {
      borderRadius: '0px 0px 0px 20px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
    '&:nth-last-of-type(1)': {
      borderRadius: '0px 0px 20px 0px',
      [theme.breakpoints.down('md')]: {
        borderRadius: "0px",
      }, 
    },
  },
  date: {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '0px',
    fontWeight: 'bold',
  },
  addButton: {
    display: "flex",
    opacity: "0",
    alignSelf: "center",
    transition: 'opacity 0.25s',
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '100%',
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
  hiddenButton: {
    display: "none",
  },
  visibleButton: {
    display: "block",
  },
  today: {
    borderRadius: '100%',
    width: '28px',
    height: '28px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    fontWeight: '800',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-4px auto',
  }
}));

type DateItemType = {
  index?: any;
  date?: any;
  onClickExcuse?: any;
  onClickDate?: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, date, onClickExcuse, onClickDate}) => {

  const classes = useStyles({
    getCalendarBackgroundColor: getCalendarBackgroundColor(date, index, theme),
  });

  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const curChild = excusenotesData.currentChild;
  const curDate = excusenotesData.date;
  const list = excusenotesData.list;

  const absenceData = (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? (list.find((data: any) => data.childID === curChild.childID).dates.filter((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")).length === 1 ? (list.find((data: any) => data.childID === curChild.childID).dates.find((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))) : []) : []) : [];

  const handleClick = (e: any) => {
    if(Object.keys(absenceData).length > 0) {
      handleClickExcuse(e);
    } else {
      if(date.isSameOrAfter(moment(),'day') && date.isSameOrBefore(moment().add(180,'days'),'day')) {
        handleClickDate(e);
      }
    }
  };

  const handleClickExcuse = (e: any) => {
    e.stopPropagation();
    onClickExcuse(absenceData.absenceID, absenceData.childID, "both");
  };

  const handleClickDate = (e: any) => {
    e.stopPropagation();
    if(curChild !== null && curChild !== undefined && (curChild.isArchived || !curChild.isInAnyActiveClass)) return;
    if(date.isSameOrAfter(moment(),'day')) onClickDate(date);
  };

  return (curChild !== null && curChild !== undefined) ? (
    <div className={`${classes.root} ${(curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass) ? (Object.keys(absenceData).length > 0 ? 'open' : date.isSameOrAfter(moment(),'day') && date.isSameOrBefore(moment().add(180,'days'),'day') ? 'open' : '') : (Object.keys(absenceData).length > 0 ? 'open' : '')}`} onClick={handleClick} data-cy={isCypress() ? "excuseNotesDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      { 
        compareDates(date, moment()) ? (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`}>
            <span className={classes.today} data-clarity-unmask="true">{date.date()}</span>
          </p>
        ) : (
          <p className={`${classes.date} ${moment(date).month() !== moment(curDate).month() ? 'fontGray' : null} ${(moment(date).day() === 6 || moment(date).day() === 0) ? 'fontDarkGray' : null}`} data-clarity-unmask="true">
            {moment(date).date()}
          </p>
        ) 
      }
      { 
        Object.keys(absenceData).length > 0 ?
          absenceData.absencePartID === 2 ? (
            <AbsenceInfoItem morningAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID}/>          
          ) : absenceData.absencePartID === 3 ? (
            <AbsenceInfoItem afternoonAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID}/>          
          ) : (
            <AbsenceInfoItem morningAbsenceStatusID={absenceData.absenceTypeID} afternoonAbsenceStatusID={absenceData.absenceTypeID} isLate={absenceData.isLate} absenceApprovalStatusID={absenceData.absenceApprovalStatusID}/>           
          )  
        : (date.isSameOrAfter(moment(),'day') && moment(date).isSameOrBefore(moment().add(180,'days'),'day') && curChild !== null && curChild !== undefined && !curChild.isArchived && curChild.isInAnyActiveClass) ? (
          <IconButton className={classes.addButton} data-cy={isCypress() ? "excuseNotesDayAddButton-" + moment(date).format("YYYY-MM-DD") : null} onClick={handleClickDate}>
            <SVG src="plus"/>
          </IconButton>
        ) : null
      }
    </div>
  ) : null;
};

export default DateItem;