import CloseButton from 'src/components/Buttons/CloseButton';
import DatePicker from '../../DatePickers/Filters/';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    minHeight: '60vh',
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
    marginTop: "auto",
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    padding: '20px 20px 0 20px',
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    marginBottom: '0px',
    fontSize: '12px',
    color: theme.colors.black,
    '& + div': {
      '& + button': {
        marginTop: '32px',
      },
    },
  },
  dates: {
    width: "calc(100% - 40px)",
    padding: "20px",
    display: "flex",
    flexDirection: 'row',
    gap: '12px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dateWrapper: {
    flex: '1 1 calc(50% - 12px)',
    maxWidth: '100%',
  },
}));

type ModalType = {
  onSave: any;
  onClose: any;
  selectedDateFrom: any;
  selectedDateTo: any;
};

const FiltersDatesModal: React.FunctionComponent<ModalType> = ({onSave, onClose, selectedDateFrom, selectedDateTo}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useStates({
    dateFrom: selectedDateFrom,
    dateTo: selectedDateTo,
  })
  
  const onCloseModal = () => {
    onClose();
  };
  
  const handleClear = () => {
    setDateFrom(null);
    setDateTo(null);
  };
  
  const handleClearFrom = () => {
    setDateFrom(null);
  };
  
  const handleClearTo = () => {
    setDateTo(null);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleSave = (e: any) => {
    e.stopPropagation();
    onSave(state.dateFrom, state.dateTo);
    onCloseModal();
  };

  const setDateFrom = (value: any) => {
    setState("dateFrom", value);
  };

  const setDateTo = (value: any) => {
    setState("dateTo", value);
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "filtersDatesModal" : null}>
        <div className={classes.header}>
          <p>{t('date')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div>
          <p className={classes.title}>
            <SVG src="clock"/>
            {t('date_selection')}
          </p>
          <div className={classes.dates}>
            <div className={classes.dateWrapper}>
              <p className={classes.subtitle}>
                {t('date_from')}
              </p>
              <DatePicker presetDate={state.dateFrom} setDate={setDateFrom} start presetDateRange={{start: state.dateFrom, end: state.dateTo}} dataCy="datePickerStart"/>
            </div>
            <div className={classes.dateWrapper}>
              <p className={classes.subtitle}>
                {t('date_to')}
              </p>
              <DatePicker presetDate={state.dateTo} setDate={setDateTo} end presetDateRange={{start: state.dateFrom, end: state.dateTo}} dataCy="datePickerEnd"/>
            </div>          
          </div>
        </div>
        <div className={classes.footer}>
          {
            (state.dateFrom != null && state.dateTo == null) ? (
              <NormalButton buttonType="clear" onClick={handleClearFrom} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          {
            (state.dateFrom == null && state.dateTo != null) ? (
              <NormalButton buttonType="clear" onClick={handleClearTo} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          {
            (state.dateFrom != null && state.dateTo != null) ? (
              <NormalButton buttonType="clear" onClick={handleClear} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          <NormalButton buttonType={(selectedDateFrom === state.dateFrom && selectedDateTo === state.dateTo) ? "disabled" : "primary"} disabled={(selectedDateFrom === state.dateFrom && selectedDateTo === state.dateTo)} onClick={handleSave} dataCy="doneButton">
            {t("done")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default FiltersDatesModal;