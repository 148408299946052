import CircularProgress from '@mui/material/CircularProgress';
import config from 'src/constants/config';
import CountCard from '../../../components/Cards/CountCard';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import FinanceCard from '../../../components/Cards/FinanceCard';
import FinanceTable from '../../../components/Tables/FinanceTable';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import Sidebar from 'src/components/Layouts/Sidebar';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import tabs from '../../../constants/tabs';
import TabsMenu from '../../../components/Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { getFiltersKeys, getFiltersKeysArray, onlyUnique } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded, setIsFilterSetupLoaded } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import Navigate from 'src/components/Layouts/Navigate';

interface Props {
  viewMode: any;
};

const useStyles = createUseStyles((theme: any) => ({
  financePage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: (props: Props) => props.viewMode === "cards" ? '60%' : '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    transition: 'width 0.25s ease 0.25s',
    maxWidth: (props: Props) => props.viewMode === "cards" ? 'calc(100% - 240px)' : '100%',
    [theme.breakpoints.down('lg')]: {
      width: (props: Props) => props.viewMode === "cards" ? '80%' : '100%',
      maxWidth: (props: Props) => props.viewMode === "cards" ? '100%' : '100%',
    },   
    [theme.breakpoints.down('md')]: {
      width: (props: Props) => props.viewMode === "cards" ? '100%' : '100%',
      maxWidth: (props: Props) => props.viewMode === "cards" ? '100%' : '100%',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: (props: Props) => props.viewMode === "cards" ? '60%' : '100%',
      transition: 'width 0.25s ease 0.25s',
      [theme.breakpoints.down('lg')]: {
        width: (props: Props) => props.viewMode === "cards" ? '80%' : '100%',
      },  
    },
  },
  financeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  cards: {
    display: 'flex',
    gap: '20px',
    maxWidth: 'calc(100% - 32px)',
    padding: '16px',
  },
  financeCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  financeCardInView: {
    width: '100%',
    height: 'auto',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { tab } = useParams();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const userData = useAppSelector((state: any) => state.user);
  const paymentService = useAppSelector((state: any) => state.services).paymentService;
  const limit = config.FINANCE_PAYMENTS_LIMIT;
  const currencies = configurationData.configuration.currencies ? configurationData.configuration.currencies : [];
  const tabsItems = tabs.finance.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const userAccess = userData.userAccess;
  const childrenWithPayments = dataData.children.filter((item: any) => item.payment && item.payment.totalCount > 0).length > 0;

  const isLoading = useRef(false);

  const [state, setState, setStates] = useStates({
    isFirstTime: true,
    isLoadingDefault: true,
    isLoadingMore: false,
    isEndOfScroll: false,
    paymentData: [],
    totals: [],
    children: [],
    page: 1,
    lastFilterParams: [],
    viewMode: tab ? tab : "cards",
  });

  const classes = useStyles({
    viewMode: state.viewMode,
  });
    
  const loadFinance = useCallback((defaultPayments?: boolean) => {
    if(state.isLoadingMore) return;
    if(state.isEndOfScroll && !defaultPayments) return;
    if(isLoading.current === true) return;
    let currentPage = state.page;
    const oldPayments = defaultPayments ? [] : state.paymentData;
    if(defaultPayments) {
      currentPage = 1;
      setStates({
        isEndOfScroll: false,
        paymentData: [],
        totals: [],
        children: [],
      });
    } else {              
      setStates({
        isEndOfScroll: false,
        isLoadingDefault: false,
        isLoadingMore: true,
      });
      currentPage++;
    } 
    if(currentPage !== state.page || currentPage === 1) {
      const filtersParams = getFiltersKeys(filtersData.filterParams, {});
      const getFiltersParams = {...filtersParams, page: currentPage, limit: limit};
      if(JSON.stringify(state.lastFilterParams) === JSON.stringify(getFiltersParams) && !defaultPayments) return;
      isLoading.current = true;
      setState("lastFilterParams", getFiltersParams);
      paymentService && paymentService.listPayments(getFiltersParams).then((result: any) => {
        if(result && result.data && result.data.payments) {
          const resultPayments = result.data.payments;
          if(resultPayments.length === 0) {
            if(state.page === 1) {
              setStates({
                isEndOfScroll: true,
                isLoadingMore: false,
                paymentData: [],
                totals: [],
                children: [],
                page: currentPage,
              });
            } else {
              setStates({
                isEndOfScroll: true,
                isLoadingMore: false,
                page: currentPage,
              });
            }
            setTimeout(() => { 
              setState("isLoadingDefault", false);
              isLoading.current = false;
            }, 500);           
          } else {
            const tempPaymentData = [].concat(oldPayments, result.data.payments);
            const tempTotals = currentPage === 1 ? result.data.totals : state.totals;
            const tempChildren = currentPage === 1 ? result.data.children : state.children; 
            setState("page", currentPage);
            setTimeout(() => {  
              setStates({
                isLoadingMore: false,
                isLoadingDefault: false,
                isEndOfScroll: result.data.payments.length < limit,
                paymentData: tempPaymentData,
                totals: tempTotals,
                children: tempChildren,
              });
              isLoading.current = false;
            }, 1000); 
          }
        }
      });
    }
  }, [filtersData.filterParams, setState, setStates, state.page, state.lastFilterParams, state.paymentData, state.isEndOfScroll, state.isLoadingMore, state.totals, state.children, limit, paymentService]);

  const setViewMode = useCallback((value: any) => {
    setState("viewMode", value);
    window.history.replaceState({},'',"/finance/" + value);
  }, [setState]);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && isLoading.current === false) {
      loadFinance(true);
    }
  }, [filtersData.filterParams, dispatch, loadFinance, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams, filtersData.isFilterParamsLoaded]);

  useEffect(() => {
    if(tab) {
      if(tabsItems.filter((item: any) => item.value === tab).length === 0) {
        setViewMode(tabsItems[0].value);
      }
    } else {
      window.history.replaceState({},'',"/finance/" + state.viewMode);
    }
  }, [state.viewMode, setViewMode, tabsItems, tab], []);

  const onScrollView = (inView: any) => {
    if(inView) {
      if(!state.isLoadingMore) loadFinance();
    }
  };

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["finance", "finance_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {});
      await saveUserSettings(dispatch, userData, "filters", ["finance"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: true,
        isAllowedPostType: false,
        isAllowedGalleryType: false,
        isAllowedActivityType: false,
        isAllowedChildID: true,
        isAllowedEmployeeID: false,
        isAllowedDate: true,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: false,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: true,
        isAllowedPaymentStatus: true,
        isAllowedPaymentType: true,
        isAllowedCurrencyID: true,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    }
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded, filtersData.isFilterParamsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["finance", "finance_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["finance"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      }
      dispatch(setIsFilterParamsLoaded(true));
    }
    return () => {
      dispatch(resetFilterParams());
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(setIsFilterSetupLoaded(false));
    }
  }, [dispatch, userData.userSettings, filtersData.isFilterParamsLoaded, saveFilters], []);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["finance", "finance_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["finance"]);
        customFilters.forEach((item: any) => {
          dispatch(setFilterParams({[item.key]: item.value}));
        });
        if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
          dispatch(setIsFiltersVisible(true));
        }
      } else {
        dispatch(setFilterParams({}));
      }
      dispatch(setIsFilterParamsLoaded(true));
    }
  }, [dispatch, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return childrenWithPayments ? (
    <div className={classes.financePage}>
      {
        !userAccess.subscription ? (
          <SubscriptionBanner service="finance" isInCenter={true}/>
        ) : (
          <>
            <div className={classes.tabsWrapper}>
              <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
              {
                state.viewMode === "cards" ? (
                  <Sidebar/>
                ) : null
              }
            </div>
            <div className={classes.wrapper}>
              <div className={classes.wrapperColumn}>
                <div className={classes.financeWrapper}>
                  {
                    ((isLoading.current && state.paymentData.length === 0) || (state.isLoadingDefault && state.paymentData.length === 0)) ? (
                      <div className={classes.spinner}>
                        <CircularProgress/>
                      </div>
                    ) : (
                      <>
                      {
                        state.paymentData.length > 0 ? (
                          <ScrollMenu className={classes.cards} layout="horizontal" nativeMobileScroll={false}>
                            {
                              state.totals.sort((a: any, b: any) => { if(a.currencyID === b.currencyID) { if (a.type < b.type) { return -1; } if (a.type > b.type) { return 1; } return 0; } else return a.currencyID - b.currencyID; }).map((item: any, key: any) => (
                                <CountCard
                                  title={item.type === "total" ? t('payments_total') : t('payments_unpaid')}
                                  countLeft={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).symbolLeft : ""}
                                  badge={item.totalCount}
                                  badgeType={item.type === "total" ? 'primary' : 'error'}
                                  chip={state.totals.map((total: any) => { return total.currencyID; }).filter(onlyUnique).length > 1 ? (currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).iso : "") : null}
                                  countNumber={item.totalSum}
                                  countDecimal={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).decimalPlace : 0}
                                  countRight={currencies.filter((currency: any) => currency.currencyID === item.currencyID).length === 1 ? currencies.find((currency: any) => currency.currencyID === item.currencyID).symbolRight : ""}
                                  key={`k_${key}`}
                                />
                              ))
                            }
                          </ScrollMenu>
                        ) : null
                      }
                      {
                        state.viewMode === "cards" ? (
                          <>
                            {
                              state.paymentData.length > 0 ? state.paymentData.map((payment: any, key: any) => {
                                return (key === state.paymentData.length - 1 && !state.isEndOfScroll) ? (
                                  <div className={classes.financeCardWrapper} key={`k_${key}`}>
                                    <InView key={`k_${key}`} className={classes.financeCardInView} onChange={(inView) => onScrollView(inView)}>
                                      <FinanceCard key={`k_${key}`} payment={payment}/>
                                    </InView>
                                    {
                                      (state.isLoadingMore && !state.isEndOfScroll) ? (
                                        <div className={classes.spinner}>
                                          <CircularProgress/>
                                        </div>
                                      ) : null
                                    }
                                    {
                                      (state.isEndOfScroll && state.paymentData.length > 0)? ( 
                                        <EndOfScroll text={t("no_more_payments")}/>
                                      ) : null
                                    }
                                  </div>
                                ) : (
                                  <div className={classes.financeCardWrapper} key={`k_${key}`}>
                                    <FinanceCard key={`k_${key}`} payment={payment}/>
                                  </div>
                                )
                              }) : (
                                <NotFound title={t('no_payments')} text={t('no_payments_found')}/>
                              )
                            }
                          </>
                        ) : (
                          <>
                            {
                              state.paymentData.length > 0 ? (
                                <>
                                  {
                                    state.children.map((child: any, key: any) => (
                                      <FinanceTable childID={child} key={`k_${key}`}/>
                                    ))
                                  }
                                  {
                                    state.isLoadingMore && !state.isEndOfScroll ? (
                                      <div className={classes.spinner}>
                                        <CircularProgress/>
                                      </div>
                                    ) : null
                                  }
                                  {
                                    state.isEndOfScroll ? ( 
                                      <EndOfScroll text={t("no_more_payments")}/>
                                    ) : null
                                  }
                                </>
                              ) : (
                                <NotFound title={t('no_payments')} text={t('no_payments_found')}/>
                              )
                            }
                          </>  
                        )
                      }
                      </>
                    )
                  }  
                </div>
              </div>
              {
                state.viewMode === "cards" ? (
                  <Sidebar/>
                ) : null
              }
            </div>
          </>
        )
      }
    </div>
  ) : (
    <Navigate to="/403" isLoaded={true}/>
  );
};

export default PageTemplate;