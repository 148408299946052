import BasicChild from './Child';
import BasicClass from './Class';
import BasicEmployee from './Employee';
import React from 'react';

type ContainerType = {
  mode: any;
  onClose: any;
};

const Basic: React.FunctionComponent<ContainerType> = ({ mode, onClose }) => {

  return (
    <>
      {
        mode === "class" ? (
          <BasicClass onClose={onClose}/>
        ) : mode === "employee" ? (
          <BasicEmployee onClose={onClose}/>
        ) : mode === "child" ? (
          <BasicChild onClose={onClose}/>
        ) : null
      }
    </>
  );
};

export default Basic;