import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import Input from '../../Forms/Input';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Select from '../../Forms/Select';
import StorageService from 'src/services/storage.service';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import theme from 'src/ui/theme';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { filterNotExistingValues, hasObjectAnyValue, isKey } from 'src/utils/useFunctions';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setClassbookHandleModal, setConfirmModal, setDraftsModal } from '../../../store/actions/modals.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { updateClassbookList } from '../../../store/actions/classbook.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState, useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isFullscreen: any;
  bodyHeight: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "hidden",
    margin: "20px",
    height: '100%',
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    maxWidth: 'calc(100% - 40px)',
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    flex: '0 0 fit-content',
  },
  button: {
    padding: '10px',
    width: '40px',
    height: '40px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
    '&.smallIcon': {
      '& > svg': {
        transform: 'scale(0.8)',
      },
    },
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    height: (props: Props) => {
      if(props.bodyHeight) return props.bodyHeight + 'px';
      else return '100%';
    },
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
  },
  bodyWrapper: {
    width: (props: Props) => {
      if(props.isFullscreen) return '80%';
      else return 'calc(100% - 40px)';
    },
    padding: (props: Props) => {
      if(props.isFullscreen) return '0 0 20px 0';
      else return '0 20px 20px 20px';
    },
    position: 'relative',
    height: 'calc(100% - 40px)',
    '&::after': {
      content: `''`,
      display: 'flex',
      width: '100%',
      height: '20px',
    },
  },
  footer: {
    display: "flex",
    flexDirection: 'column',
    gap: '5px',
    padding: "10px 20px",
    width: 'calc(100% - 40px)',
  },
  footerButtons: {
    display: "flex",
    gap: '15px',
    width: '100%',
    justifyContent: "flex-end",
    flexWrap: 'wrap',
    '& > button': {
      width: 'fit-content',
    },
  },
  item: {
    display: 'flex',
    width: '100%',
  },
  select: {
    width: '100%',
    flex: 'unset',
  },
  importButton: {
    marginRight: 'auto',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoDetail: {
    display: 'flex',
    gap: '4px',
    fontSize: '14px',
    alignItems: 'center',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      '& > svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const ClassbookHandleModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const classbookService = useAppSelector((state: any) => state.services).classbookService;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const curClass = modalsData.classbookHandleModal.classID;
  const curDate = modalsData.classbookHandleModal.date;
  const curDates = modalsData.classbookHandleModal.dates;

  const modalRef: any = useRef(null);
  const headerRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const classbookType = getClassData(curClass).classbookType;
  const isSchoolCare = getClassData(curClass).isSchoolCare;
  const isImport = modalsData.classbookHandleModal.isImport;
  const importData = modalsData.classbookHandleModal.importData;
  const startDate = (classbookType === "weekly" && curDates) ? moment(curDates[1]).startOf('month').format("YYYY-MM-DD") : moment(curDate).startOf('isoWeek').format("YYYY-MM-DD");
  const endDate = (classbookType === "weekly" && curDates) ? moment(curDates[1]).endOf('month').format("YYYY-MM-DD") : moment(curDate).startOf('isoWeek').add(6, "days").format("YYYY-MM-DD");
  const dateFrom = (classbookType === "weekly" && curDates) ? moment(curDates[0]).format("YYYY-MM-DD") : moment(curDate).format("YYYY-MM-DD");
  const dateTo = (classbookType === "weekly" && curDates) ? moment(curDates[1]).format("YYYY-MM-DD") : moment(curDate).format("YYYY-MM-DD");
  const list = classbookData.list;
  const recordDaily = list.filter((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).length === 0 ? null : (list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.filter((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(curDate).format("YYYY-MM-DD")).length === 0 ? null : list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.find((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(curDate).format("YYYY-MM-DD")));
  const recordWeekly = list.filter((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).length === 0 ? null : (list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.filter((item: any) => moment(item.dateFrom).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD") && moment(item.dateTo).format("YYYY-MM-DD") === moment(dateTo).format("YYYY-MM-DD")).length === 0 ? null : list.find((item: any) => item.classID === curClass && moment(item.startDate).format("YYYY-MM-DD") === startDate && moment(item.endDate).format("YYYY-MM-DD") === endDate).records.find((item: any) => moment(item.dateFrom).format("YYYY-MM-DD") === moment(dateFrom).format("YYYY-MM-DD") && moment(item.dateTo).format("YYYY-MM-DD") === moment(dateTo).format("YYYY-MM-DD")));
  const recordDraft = classbookType === "weekly" ? recordWeekly : recordDaily;
  const notesInput: any = useRef({});

  const isDraftEnabled = getUserSetting(userData.userSettings, "addons", ["classbook", "classbook_autosave"]);
  const drafts = getUserSetting(userData.userSettings, "drafts", ["classbook"]);
  const [draft, setDraft, setWholeDraft] = useStates(null);
  const [delayDebounceFn, setDelayDebounceFn] = useState(null);

  const isSaving = useRef(false);
  
  const [state, setState] = useStates({
    isLoaded: getUserSetting(userData.userSettings, "addons", ["classbook", "classbook_record_autoupdate"]) ? false : true,
    draftID: isDraftEnabled ? drafts.length + 1 : null,
    updateRefs: false,
    bodyHeight: null,
    modalIsFullscreen: smallDevice ? true : false,
    canClick: true,
    instructions: [],
    isImported: false,
    isDraftSaving: false,
    isKeepDraftAfterUse: false,
    lastFocused: null,
  });

  const isDraftSaved = drafts.filter((item: any) => item.draftID === state.draftID).length === 1;

  const classes = useStyles({
    isFullscreen: state.modalIsFullscreen,
    bodyHeight: state.bodyHeight,
  });
  
  const getRecord = () => {
    if(classbookType === "daily") {
      if(isSchoolCare) {
        if(recordDraft === null) {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            notes: '',
            interestActivities: '',
            relaxationRecreationalActivities: '',
            preparationTeaching: '',
            otherActivities: '',
            internalNote: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            notes: recordDraft.notes ? recordDraft.notes : "",
            interestActivities: recordDraft.interestActivities ? recordDraft.interestActivities : "",
            relaxationRecreationalActivities: recordDraft.relaxationRecreationalActivities ? recordDraft.relaxationRecreationalActivities : "",
            preparationTeaching: recordDraft.preparationTeaching ? recordDraft.preparationTeaching : "",
            otherActivities: recordDraft.otherActivities ? recordDraft.otherActivities : "",
            internalNote: recordDraft.internalNote ? recordDraft.internalNote : "",
          };
        }
      } else {
        if(recordDraft === null) {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            theme: '',
            morning: '',
            afternoon: '',
            notes: '',
            visit: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            theme: recordDraft.theme ? recordDraft.theme : "",
            morning: recordDraft.morning ? recordDraft.morning : "",
            afternoon: recordDraft.afternoon ? recordDraft.afternoon : "",
            notes: recordDraft.notes ? recordDraft.notes : "",
            visit: recordDraft.visit ? recordDraft.visit : "",
          };
        }
      }
    } else if(classbookType === "weekly") {
      if(isSchoolCare) {
        if(recordDraft === null) {
          return {
            classID: curClass,
            date: moment(curDates[0]).format("YYYY-MM-DD"),
            startDate: moment(curDates[0]).format("YYYY-MM-DD"),
            endDate: moment(curDates[1]).format("YYYY-MM-DD"), 
            week: '',
            notes: '',
            interestActivities: '',
            relaxationRecreationalActivities: '',
            preparationTeaching: '',
            otherActivities: '',
            internalNote: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(recordDraft.dateFrom).format("YYYY-MM-DD"),
            startDate: moment(recordDraft.dateFrom).format("YYYY-MM-DD"),
            endDate: moment(recordDraft.dateTo).format("YYYY-MM-DD"), 
            week: recordDraft.week ? recordDraft.week : "",
            notes: recordDraft.notes ? recordDraft.notes : "",
            interestActivities: recordDraft.interestActivities ? recordDraft.interestActivities : "",
            relaxationRecreationalActivities: recordDraft.relaxationRecreationalActivities ? recordDraft.relaxationRecreationalActivities : "",
            preparationTeaching: recordDraft.preparationTeaching ? recordDraft.preparationTeaching : "",
            otherActivities: recordDraft.otherActivities ? recordDraft.otherActivities : "",
            internalNote: recordDraft.internalNote ? recordDraft.internalNote : "",
          };
        }
      } else {
        if(recordDraft === null) {
          return {
            classID: curClass,
            date: moment(curDates[0]).format("YYYY-MM-DD"),
            startDate: moment(curDates[0]).format("YYYY-MM-DD"),
            endDate: moment(curDates[1]).format("YYYY-MM-DD"), 
            week: '',
            theme: '',
            morning: '',
            afternoon: '',
            notes: '',
            visit: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(recordDraft.dateFrom).format("YYYY-MM-DD"),
            startDate: moment(recordDraft.dateFrom).format("YYYY-MM-DD"),
            endDate: moment(recordDraft.dateTo).format("YYYY-MM-DD"), 
            week: recordDraft.week ? recordDraft.week : "",
            theme: recordDraft.theme ? recordDraft.theme : "",
            morning: recordDraft.morning ? recordDraft.morning : "",
            afternoon: recordDraft.afternoon ? recordDraft.afternoon : "",
            notes: recordDraft.notes ? recordDraft.notes : "",
            visit: recordDraft.visit ? recordDraft.visit : "",
          };
        };
      }
    };
  };

  const getValues = useCallback((customData?: any) => {
    const recordDataValues = customData ? customData : recordDraft;
    if(classbookType === "daily") {
      if(isSchoolCare) {
        if(recordDataValues === null) {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            notes: '',
            interestActivities: '',
            relaxationRecreationalActivities: '',
            preparationTeaching: '',
            otherActivities: '',
            internalNote: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            notes: recordDataValues.notes ? recordDataValues.notes : "",
            interestActivities: recordDataValues.interestActivities ? recordDataValues.interestActivities : "",
            relaxationRecreationalActivities: recordDataValues.relaxationRecreationalActivities ? recordDataValues.relaxationRecreationalActivities : "",
            preparationTeaching: recordDataValues.preparationTeaching ? recordDataValues.preparationTeaching : "",
            otherActivities: recordDataValues.otherActivities ? recordDataValues.otherActivities : "",
            internalNote: recordDataValues.internalNote ? recordDataValues.internalNote : "",
          };
        }
      } else {
        if(recordDataValues === null) {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            theme: '',
            morning: '',
            afternoon: '',
            notes: '',
            visit: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(curDate).format("YYYY-MM-DD"),
            theme: recordDataValues.theme ? recordDataValues.theme : "",
            morning: recordDataValues.morning ? recordDataValues.morning : "",
            afternoon: recordDataValues.afternoon ? recordDataValues.afternoon : "",
            notes: recordDataValues.notes ? recordDataValues.notes : "",
            visit: recordDataValues.visit ? recordDataValues.visit : "",
          };
        }
      }
    } else if(classbookType === "weekly") {
      if(isSchoolCare) {
        if(recordDataValues === null) {
          return {
            classID: curClass,
            date: moment(curDates[0]).format("YYYY-MM-DD"),
            startDate: moment(curDates[0]).format("YYYY-MM-DD"),
            endDate: moment(curDates[1]).format("YYYY-MM-DD"), 
            week: '',
            notes: '',
            interestActivities: '',
            relaxationRecreationalActivities: '',
            preparationTeaching: '',
            otherActivities: '',
            internalNote: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(recordDataValues.dateFrom).format("YYYY-MM-DD"),
            startDate: moment(recordDataValues.dateFrom).format("YYYY-MM-DD"),
            endDate: moment(recordDataValues.dateTo).format("YYYY-MM-DD"), 
            week: recordDataValues.week ? recordDataValues.week : "",
            notes: recordDataValues.notes ? recordDataValues.notes : "",
            interestActivities: recordDataValues.interestActivities ? recordDataValues.interestActivities : "",
            relaxationRecreationalActivities: recordDataValues.relaxationRecreationalActivities ? recordDataValues.relaxationRecreationalActivities : "",
            preparationTeaching: recordDataValues.preparationTeaching ? recordDataValues.preparationTeaching : "",
            otherActivities: recordDataValues.otherActivities ? recordDataValues.otherActivities : "",
            internalNote: recordDataValues.internalNote ? recordDataValues.internalNote : "",
          };
        }
      } else {
        if(recordDataValues === null) {
          return {
            classID: curClass,
            date: moment(curDates[0]).format("YYYY-MM-DD"),
            startDate: moment(curDates[0]).format("YYYY-MM-DD"),
            endDate: moment(curDates[1]).format("YYYY-MM-DD"), 
            week: '',
            theme: '',
            morning: '',
            afternoon: '',
            notes: '',
            visit: '',
          };
        } else {
          return {
            classID: curClass,
            date: moment(recordDataValues.dateFrom).format("YYYY-MM-DD"),
            startDate: moment(recordDataValues.dateFrom).format("YYYY-MM-DD"),
            endDate: moment(recordDataValues.dateTo).format("YYYY-MM-DD"), 
            week: recordDataValues.week ? recordDataValues.week : "",
            theme: recordDataValues.theme ? recordDataValues.theme : "",
            morning: recordDataValues.morning ? recordDataValues.morning : "",
            afternoon: recordDataValues.afternoon ? recordDataValues.afternoon : "",
            notes: recordDataValues.notes ? recordDataValues.notes : "",
            visit: recordDataValues.visit ? recordDataValues.visit : "",
          };
        };
      }
    };
  }, [classbookType, curClass, curDate, curDates, isSchoolCare, recordDraft]);

  const record: any = getRecord();
  const [values, setValues] = useState(getValues());

  const isEmpty = isSchoolCare ? (record === null ? true : ((record.interestActivities === "" && record.relaxationRecreationalActivities === "" && record.preparationTeaching === "" && record.otherActivities === "" && record.internalNote === "") ? true : false)) : (record === null ? true : ((record.theme === "" && record.morning === "" && record.afternoon === "" && record.notes === "" && record.visit === "") ? true : false));
  const isChanged = isSchoolCare ? (record === null ? false : ((record.interestActivities !== values.interestActivities || record.relaxationRecreationalActivities !== values.relaxationRecreationalActivities || record.preparationTeaching !== values.preparationTeaching || record.otherActivities !== values.otherActivities || record.internalNote !== values.internalNote) ? true : false)) : (record === null ? false : ((record.theme !== values.theme || record.morning !== values.morning || record.afternoon !== values.afternoon || record.notes !== values.notes || record.visit !== values.visit) ? true : false));
  const isEmptied = isSchoolCare ? (record === null ? false : ((values.interestActivities === "" && values.relaxationRecreationalActivities === "" && values.preparationTeaching === "" && values.otherActivities === "" && values.internalNote === "") ? true : false)) : (record === null ? false : ((values.theme === "" && values.morning === "" && values.afternoon === "" && values.notes === "" && values.visit === "") ? true : false));
 
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      classID: null,
      date: null,
      dates: null,
      isImport: false,
      importData: null,
    };
    dispatch(setClassbookHandleModal(settings));
  };

  const handleImmediateDraftSave = () => {
    const draftData = StorageService.getTempItem('classbookDraft');
    handleUpdateDrafts([...drafts, { ...draftData, draftID: state.draftID, draftDate: moment(), classbookType: classbookType, isSchoolCare: isSchoolCare }]);
    clearTimeout(delayDebounceFn);
  };

  const handleFullscreen = () => {
    setState("modalIsFullscreen", !state.modalIsFullscreen);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    if(StorageService.existTempItem('classbookDraft')) {
      handleImmediateDraftSave();
    }
    if(isChanged && !isDraftSaved) {
      const settings = {
        isOpen: true,
        title: t('unsaved_changes'),
        content: isEmpty ? t('unsaved_existing_record_warning') : t('unsaved_record_warning'),
        onAccept: onCloseModal,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else {
      onCloseModal();
    }
  };

  const handleImport = (e: any) => {
    e.stopPropagation();
    setValues({
      classID: curClass,
      date: moment(curDate).format("YYYY-MM-DD"),
      theme: importData.theme,
      morning: importData.morning,
      afternoon: importData.afternoon,
      notes: importData.notes,
      visit: importData.visit,
    });
    setState("isImported", true);
  };

  const getSettings = useCallback((customData?: any) => {
    const recordValues = customData ? customData : values;
    if(classbookType === "daily") {
      if(isSchoolCare) {
        return {
          classID: curClass,
          date: moment(curDate).format("YYYY-MM-DD"),
          isSchoolCare: true,
          type: "daily",
          startDate: startDate,
          endDate: endDate,
          notes: values.notes,
          interestActivities: recordValues.interestActivities,
          relaxationRecreationalActivities: recordValues.relaxationRecreationalActivities,
          preparationTeaching: recordValues.preparationTeaching,
          otherActivities: recordValues.otherActivities,
          internalNote: recordValues.internalNote,
        };
      } else {
        return {
          classID: curClass,
          date: moment(curDate).format("YYYY-MM-DD"),
          isSchoolCare: false,
          type: "daily",
          startDate: startDate,
          endDate: endDate,
          theme: recordValues.theme,
          morning: recordValues.morning,
          afternoon: recordValues.afternoon,
          notes: recordValues.notes,
          visit: recordValues.visit,
        };
      }
    } else if(classbookType === "weekly") {
      if(isSchoolCare) {
        return {
          classID: curClass,
          isSchoolCare: true,
          type: "weekly",
          startDate: startDate,
          endDate: endDate,
          dateFrom: moment(record.startDate).format("YYYY-MM-DD"),
          dateTo: moment(record.endDate).format("YYYY-MM-DD"), 
          week: record.week,
          notes: recordValues.notes,
          interestActivities: recordValues.interestActivities,
          relaxationRecreationalActivities: recordValues.relaxationRecreationalActivities,
          preparationTeaching: recordValues.preparationTeaching,
          otherActivities: recordValues.otherActivities,
          internalNote: recordValues.internalNote,
        };
      } else {
        return {
          classID: curClass,
          isSchoolCare: false,
          type: "weekly",
          startDate: startDate,
          endDate: endDate,
          dateFrom: moment(record.startDate).format("YYYY-MM-DD"),
          dateTo: moment(record.endDate).format("YYYY-MM-DD"), 
          week: record.week,
          theme: recordValues.theme,
          morning: recordValues.morning,
          afternoon: recordValues.afternoon,
          notes: recordValues.notes,
          visit: recordValues.visit,
        };
      }
    };
  }, [classbookType, curClass, curDate, endDate, isSchoolCare, record.endDate, record.startDate, record.week, startDate, values]);

  const handleEdit = (e: any) => {
    if(!state.canClick) return;
    setState("canClick", false);
    e.stopPropagation();
    classbookService && classbookService.updateRecord(values).then((result: any) => {
      if(result.status === 201) {
        createNotification(t("classbook_updated"), "success");
        const settings = getSettings();
        dispatch(updateClassbookList(settings));
        setState("canClick", true);
        if(!state.isKeepDraftAfterUse) {
          handleDeleteDraft();
        } else {
          handleImmediateDraftSave();
        }
        onCloseModal();
      } else {
        createNotification(t("something_went_wrong"), "error");
        setState("canClick", true); 
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("something_went_wrong"), "error");
      setState("canClick", true);
    });
  };

  const handleInput = (name: any, value: any) => {
    setValues((prev: any) => {
      const newState = { ...prev, [name]: value };
      saveDraftToStorage(newState);
      setDraft(name, value);
      return newState;
    });
};

const handleInputEnd = (name: any, value: any) => {
  setDraft(name, value);
};

const saveDraftToStorage = (newDraft: any) => {
    StorageService.setTempItem('classbookDraft', newDraft);
};

  const handleInstructions = (value: any) => {
    let notes = values.notes;
    if(notes.endsWith("\"")) {
      notes = notes.substr(0, notes.length - 1)
    }
    notes = `${!notes.startsWith(`${t("edification")}: "`) ? `${t("edification")}: "`: ""}${notes}${notes.length >= 1 ?`\n`: ""}${value.text}${!notes.endsWith("\"") ? `"`: "" }`;
    setValues((prev: any) => ({...prev, notes: notes}));
    notesInput.current.querySelector("textarea").value = notes;
    saveDraftToStorage({ ...values, notes });
  };

  const loadInstructions = useCallback(() => {
    classbookService && classbookService.listSentences().then((result: any) => {
      const sentences = result.data.sentences;
      const all = result.data.sentencesCategories.map((sentence: any) => {
        let temp: any = []
        for(let o of sentences){
          if (o.sentenceCategoryID === sentence.sentenceCategoryID) {
             temp.push({...o})
          }
        }
       return { ...sentence, children: temp}
      });
      setState("instructions", all);
    });
  }, [classbookService, setState]);

  const handleIsKeepDraftAfterUse = () => {
    setState("isKeepDraftAfterUse", !state.isKeepDraftAfterUse);
  };

  const handleDrafts = () => {
    const settings = {
      isOpen: true,
      currentDraftID: state.draftID,
      type: 'classbook',
      classbookType: classbookType,
      isSchoolCare: isSchoolCare,
      onDraftLoad: handleLoadDraft,
    };
    dispatch(setDraftsModal(settings));
  };

  const handleUpdateDrafts = useCallback(async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      const newValue = updateUserSetting(userData.userSettings, "drafts", ["classbook"], value);
      dispatch(setUserSettings(newValue));
      setState("isDraftSaving", true);
      const result = await saveUserSettings(dispatch, userData, "drafts", ["classbook"], value);
      if(result) {
        setState("isDraftSaving", false);
        isSaving.current = false;
        if(state.lastFocused && state.lastFocused.current) {
          setTimeout(() => {
            state.lastFocused.current.focus();
          }, 100);
        }
        StorageService.removeTempItem('classbookDraft');
      } else {
        setState("isDraftSaving", false);
        isSaving.current = false;
        if(state.lastFocused && state.lastFocused.current) {
          setTimeout(() => {
            state.lastFocused.current.focus();
          }, 100);
        }
      }
    }
  }, [dispatch, setState, userData, state.lastFocused]);

  const handleDeleteDraft = useCallback(() => {
    const newDraftData = drafts.filter((item: any) => item.draftID !== state.draftID);
    handleUpdateDrafts(newDraftData);
  }, [drafts, state.draftID, handleUpdateDrafts]);

  const handleLoadDraft = useCallback((data: any) => {
    const newValues = Object.assign(values, data);
    setValues(newValues);
    setWholeDraft(data);
    setState("draftID", data.draftID);
  }, [values, setState, setWholeDraft]);

  const handleOnFocus = (_: any, __: any, ___: any, ref: any) => {
    setState("lastFocused", ref);
  };

  useEffect(() => {
    if(delayDebounceFn) {
      clearTimeout(delayDebounceFn);
    }
    const debouncetimeout = setTimeout(() => {
      const draftData = filterNotExistingValues(draft);
      if(Object.keys(draftData).length > 0 && isDraftEnabled) {
        const newDraft = {...draftData, draftID: state.draftID, draftDate: moment(), classbookType: classbookType, isSchoolCare: isSchoolCare};
        if(hasObjectAnyValue(draftData)) {
          if(drafts.filter((item: any) => item.draftID === state.draftID).length === 0) {
            const newDraftData = [...drafts, newDraft];
            handleUpdateDrafts(newDraftData);
          } else {
            const newDraftData = drafts.map((item: any) => {
              if(item.draftID === state.draftID) {
                return newDraft;
              } else {
                return item;
              }
            });
            handleUpdateDrafts(newDraftData);
          }
        } else {
          const newDraftData = drafts.filter((item: any) => item.draftID !== state.draftID);
          handleUpdateDrafts(newDraftData);
        }
      }
    }, 30000);
    setDelayDebounceFn(debouncetimeout);
  }, [draft, drafts, isDraftEnabled, state.draftID, classbookType, isSchoolCare, handleUpdateDrafts, delayDebounceFn], [draft]);

  useEffect(() => {
    loadInstructions();
  }, [loadInstructions], []);

  useEffect(() => {
    if(modalRef.current && headerRef.current && footerRef.current) {
      const modalHeight = state.modalIsFullscreen ? browserData.height : modalRef.current.clientHeight;   
      const headerHeight = headerRef.current.clientHeight; 
      const footerHeight = footerRef.current.clientHeight; 
      const bodyHeight = modalHeight - headerHeight - footerHeight; 
      setState("bodyHeight", bodyHeight);
    } else {
      if(!state.updateRefs) {
        setState("updateRefs", true);
      }
    }
    if(state.updateRefs) {
      setState("updateRefs", false);
    }
  }, [state.modalIsFullscreen, browserData.height, state.updateRefs, setState], [state.modalIsFullscreen, browserData.height, state.updateRefs]);

  useEffect(() => {
    setState("modalIsFullscreen", smallDevice ? true : false);
  }, [browserData.height, smallDevice, setState], [browserData.height]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (StorageService.existTempItem('classbookDraft')) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const loadRecord = useCallback(() => {
    const date = classbookType === "weekly" ? curDates[0] : curDate;
    classbookService && classbookService.listRecords(curClass, date).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.classbookRecords) {
            if(classbookType === "weekly") {
              const data = result.data.classbookRecords.filter((item: any) => item.dateFrom === date).length === 0 ? null : result.data.classbookRecords.find((item: any) => item.dateFrom === date);
              if(data) {
                const updateData = getSettings(data);
                const updateValues = getValues(data);
                setValues(updateValues);
                dispatch(updateClassbookList(updateData));
                setState("isLoaded", true);
              } else {
                setState("isLoaded", true);
                createNotification(t("classbook_record_failed_update"), "error");
              }
            } else {
              const data = result.data.classbookRecords.filter((item: any) => item.date === date).length === 0 ? null : result.data.classbookRecords.find((item: any) => item.date === date);
              if(data) {
                const updateData = getSettings(data);
                const updateValues = getValues(data);
                setValues(updateValues);
                dispatch(updateClassbookList(updateData));
                setState("isLoaded", true);
              } else {
                setState("isLoaded", true);
                createNotification(t("classbook_record_failed_update"), "error");
              }
            }
          } else {
            setState("isLoaded", true);
            createNotification(t("classbook_record_failed_update"), "error");
          }
        } else {
          setState("isLoaded", true);
          createNotification(t("classbook_record_failed_update"), "error");
        }
      } else {
        setState("isLoaded", true);
        createNotification(t("classbook_record_failed_update"), "error");
        return;
      }
    }).catch(() => {
      setState("isLoaded", true);
      createNotification(t("classbook_record_failed_update"), "error");
    });
  }, [classbookService, classbookType, curClass, curDate, curDates, t, dispatch, getSettings, getValues, setState]);

  useEffect(() => {
    if(!state.isLoaded) {
      loadRecord();
    }
  }, [state.isLoaded, loadRecord], []);

  return (
    <Modal 
      open={true}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      isFullscreen={state.modalIsFullscreen}
    >
      <div className={classes.root} data-cy={isCypress() ? "classbookHandleModal" : null} ref={modalRef}>
        <div className={classes.header} ref={headerRef}>
          <div className={classes.wrapper}>
            <p data-clarity-unmask="true">
              {isEmpty ? t('create_record') : t('edit_record')}
              &nbsp;
              {
                classbookType === "weekly" ? (
                  <>{t('week')} {values.week}. ({DateFormat(values.startDate, "default", languageData, t)} - {DateFormat(values.endDate, "default", languageData, t)})</>
                ) : (
                  <>{DateFormat(moment(curDate),"default",languageData,t)}</>
                )
              }
            </p>
          </div>
          <div className={classes.buttons}>
            {
              isDraftEnabled ? (
                <IconButton className={`${classes.button} smallIcon`} onClick={handleDrafts} data-cy={isCypress() ? "draftsButton" : null} tooltip={t('drafts')} tooltipPosition='bottom'>
                  <SVG src="draft"/>
                </IconButton>
              ) : null
            }
            {
              !smallDevice ? (
                <IconButton className={`${classes.button} smallIcon`} onClick={handleFullscreen} data-cy={isCypress() ? "fullscreenButton" : null} tooltip={state.modalIsFullscreen ? t('mode_window') : t('mode_fullscreen')} tooltipPosition='bottom'>
                  {state.modalIsFullscreen ? (<SVG src="fullscreen-exit"/>) : (<SVG src="fullscreen-enter"/>)}
                </IconButton>
              ) : null
            }
            <CloseButton onClick={handleClose} dataCy="timesButton"/> 
          </div>
        </div>
        <div className={classes.body}>
          {
            state.isLoaded ? (
              <div className={classes.bodyWrapper}>
                {
                  isSchoolCare ? (
                    <>
                      <div className={classes.item}>
                        <Input label={t('additional_notes')} name='notes' multiline={true} value={values.notes} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='additionalNotes' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('interest_activities')} name='interestActivities' multiline={true} value={values.interestActivities} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='interestActivities' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('relaxation_recreational_activities')} name='relaxationRecreationalActivities' multiline={true} value={values.relaxationRecreationalActivities} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='relaxationRecreationalActivities' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('preparation_teaching')} name='preparationTeaching' multiline={true} value={values.preparationTeaching} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='preparationTeaching' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('other_activities')} name='otherActivities' multiline={true} value={values.otherActivities} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='otherActivities' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('internal_note')} name='internalNote' multiline={true} value={values.internalNote} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='internalNote' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classes.item}>
                        <Input label={t('theme')} name='theme' multiline={true} value={values.theme} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} dataCy='theme' disabled={state.isDraftSaving} dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('morning_educational_activities')} name='morning' multiline={true} value={values.morning} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} disabled={state.isDraftSaving} dataCy='morning' dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('afternoon_educational_activities')} name='afternoon' multiline={true} value={values.afternoon} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} disabled={state.isDraftSaving} dataCy='afternoon' dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Select className={classes.select} items={state.instructions} label={t('instructions')} inputLabel={t('instructions')} selected={null} setSelected={handleInstructions} disabled={state.isDraftSaving} dataCy='instructions'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('additional_notes')} name='notes' multiline={true} value={values.notes} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} customRef={notesInput} disabled={state.isDraftSaving} dataCy='notes' dataClarityUnmask='true'/>
                      </div>
                      <div className={classes.item}>
                        <Input label={t('record_visits')} name='visit' multiline={true} value={values.visit} onChange={handleInput} onInputEnd={handleInputEnd} onFocus={handleOnFocus} disabled={state.isDraftSaving} dataCy='visit' dataClarityUnmask='true'/>
                      </div>
                    </>
                  )
                }
              </div>
            ) : (
              <div className={classes.loadingWrapper}>
                <div className={classes.spinner}>
                  <CircularProgress/>
                </div>
              </div>
            )
          }
        </div>
        <div className={classes.footer} ref={footerRef}>
          {
            isDraftEnabled ? (
              <span className={classes.info}>
                <div className={classes.infoDetail}>
                  <SVG src={state.isDraftSaving ? "save" : "info-circle-outlined"}/>
                  {state.isDraftSaving ? t('classbook_autosaving') : t('classbook_autosave_enabled')}
                </div>
                {
                  Object.keys(filterNotExistingValues(draft)).length > 0 ? (
                    <Switch labelLeft={t('keep_draft_after_use')} onChange={handleIsKeepDraftAfterUse} checked={state.isKeepDraftAfterUse} dataCy="isKeepDraftAfterUse"/>
                  ) : null
                }
              </span>
            ) : null
          }
          <div className={classes.footerButtons}>
            {
              (isEmpty && isImport && !state.isImported) ? (
                <NormalButton className={classes.importButton} buttonType="tertiary" onClick={handleImport} dataCy='importPreviousDataButton'>
                  {t('classbook_import_previous_day')}
                </NormalButton>
              ) : null
            }
            <NormalButton buttonType="secondary" onClick={handleClose} dataCy='cancelButton'>
              {t('cancel')}
            </NormalButton>
            <NormalButton disabled={!state.canClick} buttonType={isEmpty ? 'primary' : (isEmptied ? 'clear' : 'primary')} onClick={handleEdit} dataCy={isEmptied ? 'deleteButton' : 'saveButton'}>
              {isEmpty ? t('create') : (isEmptied ? t('remove_record') : t('save_record'))}
            </NormalButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClassbookHandleModal;