import React from 'react';
import { createCypressDataText, isCypress } from 'src/utils/useCypress';
import { createUseStyles } from 'react-jss';
import { OptionGroup as OptionGroupUnstyled } from '@mui/base/OptionGroup';

const useStyles = createUseStyles((theme: any) => ({
  itemGroup: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    cursor: 'auto',
    '& > span': {
      fontWeight: 'bold',
      padding: '4px 12px',
    },
    '& > ul.base-OptionGroup-list': {
      padding: '0',
    },
  },
}));

type RenderOptionGroupType = {
  children: any;
  label: any;
  item: any;
  dataCy?: any;
};

const RenderOptionGroup: React.FunctionComponent<RenderOptionGroupType> = ({children, label, item, dataCy}) => {

  const classes = useStyles();

  return (
    <OptionGroupUnstyled className={classes.itemGroup} label={label} data-cy={isCypress() ? `${dataCy}Group${createCypressDataText(item.toLowerCase())}` : null}>
      {children}
    </OptionGroupUnstyled> 
  );
}

export default RenderOptionGroup;