import Radio from '@mui/material/Radio';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getElementID } from '../../../utils/useUUID';
import { isCypress } from '../../../utils/useCypress';

interface Props {
  disabled: boolean;
};

const useStyles = createUseStyles((theme: any) => ({
  radioWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  radio: {
    color: theme.colors.grey[700],
    '&.Mui-checked': {
      color: theme.colors.primaryBlue[500],
    }
  },
  radioLabel: {
    fontSize: "14px",
    cursor: (props: Props) => {
      if(props.disabled) return 'default';
      else return 'pointer';
    },
    whiteSpace: 'nowrap',
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[700];
      else return '';
    },
  },
}));

type RadioType = {
  id?: any;
  name?: any;
  checked?: any;
  value?: any;
  label?: any;
  checkedIcon? :any;
  icon?: any;
  disabled?: any;
  onChange?: any;
  onClick?: any;
  className?: any;
  dataCy? : any;
};

const RadioInput: React.FunctionComponent<RadioType> = ({id, name, checked, value, label, checkedIcon = <RadioButtonCheckedIcon/>, icon = <RadioButtonUncheckedIcon/>, disabled, onChange, onClick, className, dataCy}) => {

  const classes = useStyles({
    disabled: disabled,
  });
  const radioID = id ? id : getElementID();

  return (
    <div className={`${classes.radioWrapper} ${className ? className : null}`}>
      <Radio id={radioID} name={name ? name : radioID} className={classes.radio} checked={checked} value={value} icon={icon} checkedIcon={checkedIcon} onChange={onChange} onClick={disabled ? null : onClick} disabled={disabled} data-cy={isCypress() ? dataCy : null}/>
      {
        label ? (
          <label htmlFor={radioID} className={classes.radioLabel} data-cy={isCypress() ? dataCy + 'Label' : null}>{label}</label>
        ) : null
      }
    </div>
  );
}

export default RadioInput;