import HttpService from '../http.service';

const listSubstitutions = (childID: any, date: any) => {
  return HttpService.get(`substitution?childID=${childID}&date=${date}`, {}, {});
};

const listSubstitutionsRange = (childID: any, dateFrom: any, dateTo: any) => {
  return HttpService.get(`substitution?childID=${childID}&dateFrom=${dateFrom}&dateTo=${dateTo}`, {}, {});
};

const createSubstitution = (payload: any) => {
  return HttpService.post(`substitution`, payload, {}, {});
};

const editSubstitution = (substitutionID: any, payload: any) => {
  return HttpService.put(`substitution/${substitutionID}`, payload, {}, {});
};

const deleteSubstitution = (substitutionID: any) => {
  return HttpService.remove(`substitution/${substitutionID}`, {});
};

export {
  listSubstitutions,
  listSubstitutionsRange,
  createSubstitution,
  editSubstitution,
  deleteSubstitution,
};