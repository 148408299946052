import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { TreeView as MuiTreeView } from '@mui/x-tree-view/TreeView';
import TreeItem from '../TreeItem';

const useStyles = createUseStyles((theme: any) => ({
  treeView: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
    overflowY: 'auto',
  },
}));

type TreeViewType = {
  items: any;
  collapseIcon?: any;
  expandIcon?: any;
  endIcon?: any;
  parentIcon?: any;
  disableSelection?: boolean;
  disabledItemsFocusable?: boolean;
  isMultiSelect?: boolean;
  selected?: any;
  expanded?: any;
  onNodeFocus?: any;
  onNodeSelect?: any;
  onNodeToggle?: any;
  className?: any,
  classNameItem?: any;
};

const TreeView: React.FunctionComponent<TreeViewType> = ({ items, collapseIcon = "chevron-down", endIcon = "", expandIcon = "chevron-right", parentIcon = "", disableSelection, disabledItemsFocusable, isMultiSelect, expanded, selected, onNodeFocus, onNodeSelect, onNodeToggle, className, classNameItem }) => {
  
  const classes = useStyles();
  
  return (
    <MuiTreeView className={`${classes.treeView} ${className ? className : ''}`} defaultCollapseIcon={<SVG src={collapseIcon}/>} defaultEndIcon={endIcon} defaultExpandIcon={<SVG src={expandIcon}/>} defaultParentIcon={parentIcon} disableSelection={disableSelection} disabledItemsFocusable={disabledItemsFocusable} multiSelect={isMultiSelect} defaultExpanded={expanded} defaultSelected={selected} onNodeFocus={onNodeFocus} onNodeSelect={onNodeSelect} onNodeToggle={onNodeToggle}>
      {
        items.map((item: any, key: any) => (
          <TreeItem key={`k_${key}`} label={item.name} items={item.items} className={classNameItem} disabled={item.disabled}/>
        ))
      }
    </MuiTreeView>
  );
}

export default TreeView;
