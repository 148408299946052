import Drawer from 'src/utils/drawer';
import MobileLayout from 'src/containers/Main/Communication/layouts/mobile';
import React from 'react';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';
import { resetCommunicationList, setCommunicationThreadID, setCommunicationUserID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { setCommunicationDrawer } from 'src/store/actions/drawers.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

interface Props {
  browserHeight: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    backgroundColor: theme.colors.white,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
  },
  paper: {
    width: '25vw',
    height: (props: Props) => (props.browserHeight - 64) + 'px',
    maxHeight: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: (props: Props) => (props.browserHeight - 120) + 'px',
    },
  },
}));

const CommunicationDrawer: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const drawersData = useAppSelector((state: any) => state.drawers);
  const layoutData = useAppSelector((state: any) => state.layout);

  const isOpen = drawersData.communicationDrawer.isOpen;
  const sideContent = layoutData.sideContent;

  const classes = useStyles({
    browserHeight: browserData.height,
  });

  const [state, setState] = useStates({
    isLoading: true,
    isFirstTimeLoaded: false,
    isLoadingMessages: false,
  });
  
  const handleIsLoading = (value: any) => {
    setState("isLoading", value);
  };

  const handleIsLoadingMessages = (value: any) => {
    setState("isLoadingMessages", value);
  };

  const handleIsFirstTimeLoaded = (value: any) => {
    setState("isFirstTimeLoaded", value);
  };

  const onCloseDrawer = () => {
    const settings = {
      isOpen: false,
      position: 'right',
    };
    dispatch(setCommunicationDrawer(settings));
    setTimeout(() => {
      if(sideContent !== "communication") {
        dispatch(resetCommunicationList());
      }
    }, theme.transitions.duration.leavingScreen);
  };

  const handleClose = (e: any, reason: any) => {
    if(reason === "backdropClick" || reason === "escapeKeyDown") return;
    e.stopPropagation();
    onCloseDrawer();
  };

  useEffect(() => {
    if(isOpen) {
      dispatch(setCommunicationViewMode("threads"));
    } else {
      setTimeout(() => {
        if(sideContent !== "communication") {
          dispatch(setCommunicationViewMode(null));
        }
      }, theme.transitions.duration.leavingScreen);
    }
    dispatch(setCommunicationThreadID(null));
    dispatch(setCommunicationUserID(null));
  }, [dispatch, sideContent, isOpen], [isOpen]);
  
  return (
    <Drawer
      classNamePaper={classes.paper}
      position={drawersData.communicationDrawer.position}
      open={isOpen}
      onClose={handleClose}
    >
      <div className={classes.root}>
        <MobileLayout isLoading={state.isLoading} isLoadingMessages={state.isLoadingMessages} isFirstTimeLoaded={state.isFirstTimeLoaded} setIsLoading={handleIsLoading} setIsLoadingMessages={handleIsLoadingMessages} setIsFirstTimeLoaded={handleIsFirstTimeLoaded}/>
      </div>
    </Drawer>
  )
};

export default CommunicationDrawer;
