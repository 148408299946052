import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeDuplicatesJSON } from 'src/utils/useFunctions';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  comments: any;
  edited: any;
  visibility: any;
  deleted: any;
  replyTo: any;
};

const initialState: ArrayModel = {
  comments: [],
  edited: 0,
  visibility: 0,
  deleted: 0,
  replyTo: 0,
};

const slice = createSlice({
    name: 'comments',
    initialState: initialState,
    reducers: {
      setComments(state, action: PayloadAction<Model>){
        state.comments = action.payload;
      },
      updateComments(state, action: PayloadAction<Model>){
        const newData: any = action.payload;
        const newComments: any = removeDuplicatesJSON([].concat(state.comments, newData));
        newComments.sort((a: any, b: any) => { return a.commentID - b.commentID; });
        state.comments = newComments;
      },
      replaceComment(state, action: PayloadAction<Model>){
        const newCommentID = action.payload.commentID;
        const newData: any = action.payload.data;
        const newComments: any = state.comments.map((item: any) => {
          if(item.commentID === newCommentID) {
            return newData;
          } else {
            return item;
          }
        });
        state.comments = newComments;
      },
      setCommentsEdited(state, action: PayloadAction<Model>){
        state.edited = action.payload;
      },
      setCommentsVisibility(state, action: PayloadAction<Model>){
        state.visibility = action.payload;
      },
      setCommentsDeleted(state, action: PayloadAction<Model>){
        state.deleted = action.payload;
      },
      setCommentsReplyTo(state, action: PayloadAction<Model>){
        state.replyTo = action.payload;
      },
    }
});

export default slice;