import AuthenticatedImage from '../../Items/AuthenticatedImage';
import config from 'src/constants/config';
import DateFormat from '../../../utils/dateFormat';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import { a2ab, createFileName } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { saveAs } from 'file-saver';
import { setAttachmentModal, setImageModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
    position: 'relative',
    marginBottom: '20px',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        },     
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      padding: '12px 10px',
    },
  },
  tableRowMore: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        },     
      },
    },
    '& > td': {
      padding: '12px 10px',
      '& > button': {
        margin: '0 auto',
      }
    },
  },
  childInformation: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '& > button': {
      marginLeft: 'auto',
    }
  },
  childInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60px',
  },
  childImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minWidth: '40px',
    minHeight: '40px',
    width: '40px',
    height: '40px',
    maxWidth: '40px',
    maxHeight: '40px',
    marginRight: '10px',
    overflow: 'hidden',
  },
  childImage: {
    width: '38px',
    height: '38px',
    maxWidth: '38px',
    maxHeight: '38px',
    minWidth: '38px',
    minHeight: '38px',
    borderRadius: '100%',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: theme.colors.white,
  },
  childName: {
    color: theme.colors.grey[800],
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  attachment: {
    display: 'inline-flex',
    ' & + div': {
      marginLeft: '8px',
    },
  },
  smallButton: {
    height: '32px',
    minWidth: '0px',
    padding: '5px 12px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  loading: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.tableLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
    borderRadius: '20px',  
  },
}));

type ItemsType = {
  childID: any;
  showChild?: any;
};

const FinanceTable: React.FunctionComponent<ItemsType> = ({ childID, showChild = true}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const languageData = useAppSelector((state: any) => state.language);
  const childData = dataData.children.find((child: any) => child.childID === childID);  
  const paymentMethods =  configurationData.configuration.paymentMethods;
  const paymentStatuses = configurationData.configuration.paymentStatuses;
  const paymentTypes = configurationData.configuration.paymentTypes;
  const exportService = useAppSelector((state: any) => state.services).exportService;
  const paymentService = useAppSelector((state: any) => state.services).paymentService;
  const limit = config.FINANCE_PAYMENTS_LIMIT;

  const [state, setState, setStates] = useStates({
    payments: [],
    page: 1,
    isLoading: true,
    isLoadingMore: false,
    isEndOfLoad: false,
  });

  const handleQrCode = (qrCodeLink: any) => {
    const settings = {
      isOpen: true,
      thumbLink: qrCodeLink,
      fullsizeLink: qrCodeLink,
    };
    dispatch(setImageModal(settings));
  };

  const handleAttachment = (title: any, name: any, type: any, paymentID: any, schoolID: any, format: any) => {
    const fileName = createFileName(name);
    const handleExportPDF = () => {
      exportService && exportService.exportFinance(type, paymentID, schoolID).then((result: any) => {
        const buf = a2ab(result.data);
        const buftype = 'application/pdf';
        const blob = new Blob([buf], {
            type: buftype,
        });
        saveAs(blob, `${fileName}.${format}`);
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    };
    const settings = {
      isOpen: true,
      title: `${title} <span>${name}</span>`,
      content: null,
      download: null,
    };
    dispatch(setAttachmentModal(settings));
    exportService && exportService.exportFinance(type, paymentID, schoolID).then((result: any) => {
      const buf = a2ab(result.data);
      const buftype = 'application/pdf';
      const blob = new Blob([buf], {
          type: buftype,
      });
      const content = URL.createObjectURL(blob);
      const settings = {
        content: content,
        download: handleExportPDF,
        type: format,
      };
      dispatch(setAttachmentModal(settings));
    }).catch(() => {
      createNotification(t('something_went_wrong'), 'error');
      const settings = {
        isOpen: false,
        title: null,
        content: null,
        download: null,
      };
      dispatch(setAttachmentModal(settings));
    });
  };

  const filtersParams: any = useMemo(() => {
    const replaceKeys: any = {};
    const params = Object.entries(filtersData.filterParams).filter((item) => item[1] !== null).map((entry: any) => {
      const key = replaceKeys[entry[0]] || entry[0];
      return { [key]: entry[1] };
    }).reduce((a: any, v: any) => ({ ...a, ...v }), {});
    return params;
  }, [filtersData.filterParams]);

  const loadFinance = useCallback(() => {
    setState("isLoadingMore", true);
    let currentPage = state.page;
    let payments = state.payments;
    const getFiltersParams = {...filtersParams, childID: childID, page: currentPage, limit: limit};
    paymentService && paymentService.listPayments(getFiltersParams).then((result: any) => {
      currentPage++;
      if(result) {
        if(result.data) {
          if(result.data.payments) {
            if(result.data.payments.length === 0) {
              setStates({
                isLoading: false,
                isLoadingMore: false,
                isEndOfLoad: true,
              });       
            } else {
              if(result.data.payments.length < limit) setState("isEndOfLoad", true); 
              const tempPayments = payments.concat(result.data.payments);
              setTimeout(function() {  
                setStates({
                  isLoading: false,
                  isLoadingMore: false,
                  payments: tempPayments,
                  page: currentPage,
                });
              }, 1000); 
            }
          }
        }
      }
    });
  }, [filtersParams, limit, paymentService, setState, setStates, state.page, state.payments, childID]);

  useEffect(() => {
    loadFinance();
  }, [loadFinance], []);

  return (
    <>
      {
        showChild ? (
          <div className={classes.childInformation}>
            <div className={classes.childImageContainer}>
              <AuthenticatedImage className={classes.childImage} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink} isClickable/>
            </div>
            <div className={classes.childInfo}>
              <span className={classes.childName}>
                {childData.displayName}
              </span>
            </div>
          </div>
        ) : null
      }
      <div className={classes.tableWrapper}>
        <table className={classes.tableContainer}>
          <thead className={classes.tableHead}>
            <tr>
              <th>{t('payment_id')}</th>
              <th>{t('payment_date_added')}</th>
              <th>{t('payment_price')}</th>
              <th>{t('payment_type')}</th>
              <th>{t('payment_status')}</th>
              <th>{t('payment_method')}</th>
              <th>{t('payment_note')}</th>
              <th>{t('payment_vs')}</th>
              <th>{t('payment_bank_account')}</th>
              <th>{t('payment_date_paid')}</th>
              <th>{t('action')}</th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
          {
            state.payments.filter((payment: any) => payment.datePaid === "").sort((a: any, b: any) => { if (moment(a.dateIssued).unix() === moment(b.dateIssued).unix()) return b.paymentID - a.paymentID; return moment(b.dateIssued).unix() - moment(a.dateIssued).unix(); }).map((payment: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td>{payment.paymentID}</td>
                <td>{payment.dateIssued ? DateFormat(payment.dateIssued, "default", languageData, t) : ''}</td>
                <td>{payment.amountFormatted}</td>
                <td>{paymentTypes.filter((type: any) => type.paymentTypeID === payment.paymentTypeID).length === 1 ? paymentTypes.find((type: any) => type.paymentTypeID === payment.paymentTypeID).name : ""}</td>
                <td>{paymentStatuses.filter((status: any) => status.paymentStatusID === payment.paymentStatusID).length === 1 ? paymentStatuses.find((status: any) => status.paymentStatusID === payment.paymentStatusID).name : ""}</td>
                <td>{paymentMethods.filter((method: any) => method.paymentMethodID === payment.paymentMethodID).length === 1 ? paymentMethods.find((method: any) => method.paymentMethodID === payment.paymentMethodID).name : false}</td>
                <td>{payment.note}</td>
                <td>{payment.vs}</td>
                <td>{payment.bankAccount}</td>
                <td>{payment.datePaid ? DateFormat(payment.datePaid, "default", languageData, t) : ''}</td>
                <td>
                {
                  payment.attachments.map((attachment: any, akey: any) => (
                    <div className={classes.attachment} key={`k_${akey}`}>
                    {
                      attachment.type === "qr" ? (
                        <NormalButton buttonType="primary" className={classes.smallButton} onClick={() => handleQrCode(attachment.link)}>
                          {t('payment_qr')}
                        </NormalButton>
                      ) : attachment.type === "invoice" ? (
                        <NormalButton buttonType="secondary" className={classes.smallButton} onClick={() => handleAttachment(t('payment_invoice'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                          {t('payment_invoice')}
                        </NormalButton>
                      ) : attachment.type === "receipt" ? (
                        <NormalButton buttonType="secondary" className={classes.smallButton} onClick={() => handleAttachment(t('payment_receipt'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                          {t('payment_receipt')}
                        </NormalButton>
                      ) : null
                    }
                    </div>
                  ))
                }
                </td>
              </tr>
            ))
          }    
          {
            state.payments.filter((payment: any) => payment.datePaid !== "").sort((a: any, b: any) => { if (moment(a.dateIssued).unix() === moment(b.dateIssued).unix()) return b.paymentID - a.paymentID; return moment(b.dateIssued).unix() - moment(a.dateIssued).unix(); }).map((payment: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td>{payment.paymentID}</td>
                <td>{payment.dateIssued ? DateFormat(payment.dateIssued, "default", languageData, t) : ''}</td>
                <td>{payment.amountFormatted}</td>
                <td>{paymentTypes.find((type: any) => type.paymentTypeID === payment.paymentTypeID).name}</td>
                <td>{paymentStatuses.find((status: any) => status.paymentStatusID === payment.paymentStatusID).name}</td>
                <td>{paymentMethods.find((method: any) => method.paymentMethodID === payment.paymentMethodID).name}</td>
                <td>{payment.note}</td>
                <td>{payment.vs}</td>
                <td>{payment.bankAccount}</td>
                <td>{payment.datePaid ? DateFormat(payment.datePaid, "default", languageData, t) : ''}</td>
                <td>
                {
                  payment.attachments.map((attachment: any, akey: any) => (
                    <div className={classes.attachment} key={`k_${akey}`}>
                    {
                      attachment.type === "qr" ? (
                        <NormalButton buttonType="primary" className={classes.smallButton} onClick={() => handleQrCode(attachment.link)}>
                          {t('payment_qr')}
                        </NormalButton>
                      ) : attachment.type === "invoice" ? (
                        <NormalButton buttonType="secondary" className={classes.smallButton} onClick={() => handleAttachment(t('payment_invoice'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                          {t('payment_invoice')}
                        </NormalButton>
                      ) : attachment.type === "receipt" ? (
                        <NormalButton buttonType="secondary" className={classes.smallButton} onClick={() => handleAttachment(t('payment_receipt'), attachment.name, attachment.type, payment.paymentID, payment.schoolID, attachment.extension)}>
                          {t('payment_receipt')}
                        </NormalButton>
                      ) : null
                    }
                    </div>
                  ))
                }
                </td>
              </tr>
            ))
          }      
          {
            state.isLoading ? (
              <tr className={classes.tableRow}>
                <td colSpan={11}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </td>
              </tr>
            ) : null
          }
          {
            (!state.isEndOfLoad && !state.isLoading) ? (
              <tr className={classes.tableRowMore}>
                <td colSpan={11}>
                  <NormalButton className={classes.smallButton} onClick={loadFinance} buttonType='primary' disabled={state.isLoadingMore}>
                    {t('load_more_payments')}
                  </NormalButton>
                </td>
              </tr>
            ) : null
          }           
          </tbody> 
        </table>
        {
          (!state.isLoading && state.isLoadingMore) ? (
            <div className={classes.loading}>
              <div className={classes.spinner}>
                <CircularProgress/>
              </div>
            </div>
          ) : null
        } 
      </div>
    </>
  );
}

export default FinanceTable;