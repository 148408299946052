import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setCommunicationChangeThreadNameModal } from '../../../store/actions/modals.actions';
import { updateThreadData } from 'src/store/actions/communication.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "600px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  text: {
    fontSize: '14px',
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '10px',
  },
}));

const CommunicationChangeThreadNameModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const threadID = modalsData.communicationChangeThreadNameModal.threadID;
  const threadName = modalsData.communicationChangeThreadNameModal.threadName;
  const onChange = modalsData.communicationChangeThreadNameModal.onChange;

  const [state, setState] = useStates({
    newThreadName: threadName,
  })
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      threadID: null,
      threadName: null,
      onChange: null,
    };
    dispatch(setCommunicationChangeThreadNameModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleSave = (e: any) => {
    const settings = {
      name: state.newThreadName,
    };
    communicationService && communicationService.updateThread(threadID, settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data;
          const newData = {
            threadID: threadID,
            data: response,
          };
          dispatch(updateThreadData(newData));
          if(response.lastMessage) {
            const message = {...response.lastMessage, thread: { threadID: threadID} };
            onChange(message);
          }
          createNotification(t("community_thread_renamed"), "success");
          handleClose(e);
        } else {
          createNotification(t("community_thread_failed_rename"), "error");
        }
      } else {
        createNotification(t("community_thread_failed_rename"), "error");
      }
    }).catch(() => {
      createNotification(t("community_thread_failed_rename"), "error");
    });
  };

  const handleCancel = (e: any) => {
    handleClose(e);
  };
  
  const onInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "communicationChangeThreadNameModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('communication_thread_rename')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <p className={classes.text}>
            {t('communication_thread_rename_info')}
          </p>
          <Input name="newThreadName" value={threadName} onChange={onInputChange} maxLength={255} showCounter={true}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleCancel} data-cy={isCypress() ? "cancelButton" : null}>
            {t("cancel")}
          </NormalButton>
          <NormalButton buttonType="primary" onClick={handleSave} data-cy={isCypress() ? "saveButton" : null} disabled={state.newThreadName.length === 0 || state.newThreadName === threadName}>
            {t("save")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default CommunicationChangeThreadNameModal;