import Default from '../components/Default';
import Loading from '../components/Loading';
import New from '../components/New';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import Settings from '../components/Settings';
import Thread from '../components/Thread';
import Threads from '../components/Threads';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  desktopLayout: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 2px)',
    overflow: 'auto',
    flex: '0 0 auto',
    gap: '1px',
    backgroundColor: theme.colors.grey[325],
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    flex: '0 0 calc(20% - 0.5px)',
    maxWidth: 'calc(20% - 0.5px)',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 calc(30% - 0.5px)',
      maxWidth: 'calc(30% - 0.5px)',
    },
    [theme.breakpoints.down('bg')]: {
      flex: '0 0 calc(25% - 0.5px)',
      maxWidth: 'calc(25% - 0.5px)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 24px)',
    padding: '12px',
    '& > span': {
      fontSize: '24px',
      fontWeight: '600',
    },
  },
  inputWrapper: {
    padding: '0 12px',
    width: 'calc(100% - 24px)',
  },
  rightColumn: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flex: '0 0 calc(80% - 0.5px)',
    maxWidth: 'calc(80% - 0.5px)',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 calc(70% - 0.5px)',
      maxWidth: 'calc(70% - 0.5px)',
    },
    [theme.breakpoints.down('bg')]: {
      flex: '0 0 calc(75% - 0.5px)',
      maxWidth: 'calc(75% - 0.5px)',
    },
  },
  buttons: {
    display: 'flex',
    gap: '4px',
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.grey[200],
    },
  },
  backToApp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 50px',
    height: '50px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.grey[325],
    '& > button': {
      display: 'flex',
      gap: '6px',
      width: '95%',
      height: '85%',
      textTransform: 'unset',
      color: theme.colors.black,
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  notFound: {
    textAlign: 'center',
  },
}));

type DesktopLayoutType = {
  isLoading: boolean;
  isLoadingMessages: boolean;
  isFirstTimeLoaded: boolean;
  setIsLoading: any;
  setIsLoadingMessages: any;
  setIsFirstTimeLoaded: any;
};

const DesktopLayout: React.FunctionComponent<DesktopLayoutType> = ({ isLoading, isLoadingMessages, isFirstTimeLoaded, setIsLoading, setIsLoadingMessages, setIsFirstTimeLoaded }) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);
  const { tab: viewMode, param: getThreadID }: any = useParams();

  const userAccess = userData.userAccess;

  const currentThreadID = parseInt(getThreadID);

  return !userAccess.communication ? (
    <NotFound className={classes.notFound} title={t('page_forbidden')} text={t('page_forbidden_detail')}/>
  ) : (
    <div className={classes.desktopLayout}>
      <div className={classes.leftColumn}>
        <Threads currentThreadID={currentThreadID} isLoading={isLoading} isLoadingMessages={isLoadingMessages} isFirstTimeLoaded={isFirstTimeLoaded} setIsLoading={setIsLoading} setIsFirstTimeLoaded={setIsFirstTimeLoaded}/>
      </div>
      <div className={classes.rightColumn}>
        {
          viewMode === "new" ? (
            <New/>
          ) : viewMode === "thread" ? (
            <>
            {
              isFirstTimeLoaded ? (
                <Thread threadID={currentThreadID} isLoading={isLoading} isLoadingMessages={isLoadingMessages} isFirstTimeLoaded={isFirstTimeLoaded} setIsLoading={setIsLoading} setIsLoadingMessages={setIsLoadingMessages} setIsFirstTimeLoaded={setIsFirstTimeLoaded}/>
              ) : (
                <Loading/>
              )
            }
            </>
          ) : viewMode === "settings" ? (
            <Settings/>
          ) : (
            <Default/>  
          )
        }
      </div>
    </div>
  );
};

export default DesktopLayout;