import BootstrapIcon from 'src/components/Icons/BootstrapIcon';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { bootstrapIcons } from 'src/constants/icons';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setStockCategories } from 'src/store/actions/stock.actions';
import { setStockCategoryDetailModal, setStockCategoryHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
}));

const StockCategoryDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const stockData = useAppSelector((state: any) => state.stock);

  const isCategoryType = false;

  const isValid = modalsData.stockCategoryDetailModal.categoryID === null ? false : true;
  const categoryID = isValid ? modalsData.stockCategoryDetailModal.categoryID : null;
  const schoolID = isValid ? modalsData.stockCategoryDetailModal.schoolID : null;
  const categoryData = isValid ? (stockData.categories.filter((category: any) => category.categoryID === categoryID).length === 1 ? stockData.categories.find((category: any) => category.categoryID === categoryID) : null) : null

  const getType = (type: any) => {
    if(type === 1) {
      return t('stock_category_type_reservation');
    } else if(type === 2) {
      return t('stock_category_type_orders');
    } else {
      return '';
    }
  };
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      categoryID: null,
      schoolID: null,
    };
    dispatch(setStockCategoryDetailModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleEdit = () => {
    const settings = {
      isOpen: true,
      categoryID: categoryID,
      schoolID: schoolID,
    };
    dispatch(setStockCategoryHandleModal(settings));
  };

  const handleDelete = () => {
    stockService && stockService.deleteCategory(categoryID).then((result: any) => {
      if(result) {
        if(result.status === 204) {
          createNotification(t("stock_category_deleted"), "success");
          onCloseModal();
          const newCategories = stockData.categories.map((category: any) => {
            if(category.categoryID === categoryID) {
              return null;
            } else {
              return category;
            }
          }).filter((item: any) => item !== null);
          dispatch(setStockCategories(newCategories));
        } else {
          createNotification(t("stock_category_not_deleted"), "error");
        }
      } else {
        createNotification(t("stock_category_not_deleted"), "error");
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_category_not_deleted"), "error");
    });
  };

  return isValid ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockCategoryDetailModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('stock_category')} {categoryData.name}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.item}>
            <p className={classes.title}>
              <SVG src="info-circle-outlined"/>
              {t('stock_category_name')}
            </p>
            <p className={classes.detail}>
              {categoryData.name}
            </p>
          </div>
          {
            (categoryData.icon && bootstrapIcons.indexOf(categoryData.icon) !== -1) ? (
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="info-circle-outlined"/>
                  {t('stock_category_icon')}
                </p>
                <div className={classes.detail}>
                  <BootstrapIcon iconName={categoryData.icon} size={24}/>
                </div>
              </div>
            ) : null
          }
          {
            isCategoryType ? (
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="class"/>
                  {t('stock_category_type')}
                </p>
                <p className={classes.detail}>
                  {getType(categoryData.type)}
                </p>
              </div>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="clear" onClick={handleDelete} dataCy="deleteButton">
            {t("stock_category_delete")}
          </NormalButton>
          <NormalButton onClick={handleEdit} dataCy="editButton">
            {t('stock_category_edit')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  ) : null;
};  

export default StockCategoryDetailModal;