import React from 'react';
import useBreakpoint from 'src/utils/useBreakpoint';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
  },
}));

type SidebarType = {
  className?: any,
  hideFrom?: "xs" | "sm" | "co" | "md" | 'bg' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl',
  isAlwaysVisible?: boolean;
};

const Sidebar: React.FunctionComponent<SidebarType> = ({ className, hideFrom = "lg", isAlwaysVisible }) => {
  
  const breakpoint: any = useBreakpoint();
  const classes = useStyles();
  const layoutData = useAppSelector((state: any) => state.layout);

  const layouts: any = ["xxxxl", "xxxl", "xxl", "xl", "lg", "bg", "md", "co", "sm", "xs"];

  const isHideFrom = layouts.filter((item: any) => item === breakpoint).length === 0 ? false : layouts.findIndex((item: any) => item === breakpoint) >= layouts.findIndex((item: any) => item === hideFrom);

  const isVisible = isAlwaysVisible ? true : (layoutData.sideContent === null && !isHideFrom);

  return isVisible ? (
    <div className={`${classes.sidebar} ${className ? className : null}`}/>
  ) : null;
}

export default Sidebar;