import HttpService from '../http.service';

const listRecords = (classID: any, date: any) => {
  return HttpService.get(`classbook?classID=${classID}&date=${date}`, {}, {});
};

const updateRecord = (payload: any) => {
  return HttpService.post(`classbook`, payload, {}, {});
};

const listSentences = () => {
  return HttpService.get('classbook/sentences', {}, {});
};

export {
  listRecords,
  updateRecord,
  listSentences,
};