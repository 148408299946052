import * as DirectorDataService from '../../../../services/director/data.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import { getUserRole } from 'src/utils/useUser';
import { setDataSchools, setDataSchoolsID } from 'src/store/actions/data.actions';

const loadSchools = async (dispatch: any, userData: any, schoolsID: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") {
    try {
      const result: any = await ParentDataService.listSchools(Array.isArray(schoolsID) ? schoolsID.join(",") : schoolsID);
      if(result && result.data && result.data.schools && result.data.schools.length !== 0) {
        const tempSchools = result.data.schools;
        tempSchools.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.schoolID - b.schoolID; return a.sortOrder - b.sortOrder; });
        const tempSchoolsID = tempSchools.map((item: any) => { return item.schoolID; });
        dispatch(setDataSchools(tempSchools));
        dispatch(setDataSchoolsID(tempSchoolsID));
        return{schools: tempSchools, schoolsID: tempSchoolsID};
      } else {
        return false; 
      }
    } catch {
      return false;   
    };
  } else if(getUserRole(userData.userObject.roleType) === "teacher") {
    try {
      const result: any = await TeacherDataService.listSchools(Array.isArray(schoolsID) ? schoolsID.join(",") : schoolsID);
      if(result && result.data && result.data.schools && result.data.schools.length !== 0) {
        const tempSchools = result.data.schools;
        tempSchools.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.schoolID - b.schoolID; return a.sortOrder - b.sortOrder; });
        const tempSchoolsID = tempSchools.map((item: any) => { return item.schoolID; });
        dispatch(setDataSchools(tempSchools));
        dispatch(setDataSchoolsID(tempSchoolsID));
        return{schools: tempSchools, schoolsID: tempSchoolsID};
      } else {
        return false; 
      }
    } catch {
      return false;   
    };
  } else if(getUserRole(userData.userObject.roleType) === "director") {
    try {
      const result: any = await DirectorDataService.listSchools(Array.isArray(schoolsID) ? schoolsID.join(",") : schoolsID);
      if(result && result.data && result.data.schools && result.data.schools.length !== 0) {
        const tempSchools = result.data.schools;
        tempSchools.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.schoolID - b.schoolID; return a.sortOrder - b.sortOrder; });
        const tempSchoolsID = tempSchools.map((item: any) => { return item.schoolID; });
        dispatch(setDataSchools(tempSchools));
        dispatch(setDataSchoolsID(tempSchoolsID));
        return{schools: tempSchools, schoolsID: tempSchoolsID};
      } else {
        return false; 
      }
    } catch {
      return false;   
    };
  }
};

export default loadSchools;