import RadioInput from '../RadioInput';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  radioGroupWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap',
    gap: '5px',
  },
}));

type RadioGroupType = {
  items: any;
  value: any;
  onChange: any;
  className?: any;
};

const RadioGroup: React.FunctionComponent<RadioGroupType> = ({items, value, onChange, className}) => {

  const classes = useStyles();
  
  return (
    <div className={`${classes.radioGroupWrapper} ${className ? className : null}`}>
      {
        items.map((item: any, key: any) => item !== null ? (
          <RadioInput key={`k_${key}`} name={item.name} label={item.name} checked={value === item.value} value={item.value} onChange={onChange} disabled={item.disabled} dataCy={isCypress() ? item.dataCy : null}/>
        ) : null)
      }
    </div>
  );
}

export default RadioGroup;