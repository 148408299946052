import CloseButton from 'src/components/Buttons/CloseButton';
import FileInput from 'src/components/Forms/FileInput';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React, { useRef } from 'react';
import Select from 'src/components/Forms/Select';
import StockVariantInputGroup from 'src/components/Forms/StockVariantInputGroup';
import Switch from 'src/components/Forms/Switch';
import TabsMenu from 'src/components/Menus/TabsMenu';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import { base64ToArrayBuffer, findDuplicate, getFileType, getSchoolSettings, isKey } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { isCypress } from '../../../utils/useCypress';
import { setStockItemHandleModal } from '../../../store/actions/modals.actions';
import { setStockItems } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  viewMode: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: (props: Props) => {
      if(props.viewMode === "variants") return "1200px";
      else return "800px";
    },
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.25s',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    maxHeight: 'calc(100vh - 20px)',
    overflow: 'auto',
    flex: '1 1 auto',
    padding: "20px",
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '50vh',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: '16px',
    padding: "20px",
  },
  inputs: {
    display: 'flex',
    gap: '16px',
    '& > *': {
      flex: '0 0 calc(50% - 8px)',
    }
  },
  switches: {
    display: 'flex',
    gap: '16px',
    '& > *': {
      flex: '0 0 calc(33% - 8px)',
    }
  },
  select: {
    width: '100%',
  },
  tabs: {
    width: '100% !important',
  },
  tooltipIcon: {
    paddingLeft: '8px',
    width: '14px',
    height: '14px',
  },
}));

const StockItemHandleModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const stockData = useAppSelector((state: any) => state.stock);

  const schoolSettings = userData.schoolSettings;

  const isEdit = modalsData.stockItemHandleModal.itemID === null ? false : true;
  const itemID = isEdit ? modalsData.stockItemHandleModal.itemID : null;
  const itemData = isEdit ? (stockData.items.filter((item: any) => item.itemID === itemID).length === 1 ? stockData.items.find((item: any) => item.itemID === itemID) : null) : null;
  const categoryID = stockData.category === null ? null : stockData.category;
  const schoolID = modalsData.stockItemHandleModal.schoolID;
  const currencyID = getSchoolSettings(schoolID, 'currencyID', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'currencyID', schoolSettings);
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];

  const tagsList = stockData.tags;

  const getMedias = (media: any) => {
    let tempFiles: any = [];
    if(media.files) {
      media.files.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'application/pdf',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    if(media.photos) {
      media.photos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'image/jpeg',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    if(media.videos) {
      media.videos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'video/mp4',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    return tempFiles;
  };

  const getVariants = (variants: any) => {
    const oldVariants = variants.map((item: any) => {
      return {...item, uniqueID: getElementID()};
    });
    const newVariant = [{ name: '', price: isEdit ? itemData.price : 0, quantity: '0', sku: '', upc: '', deleted: false, new: true, uniqueID: getElementID() }];
    return oldVariants.concat(newVariant);
  };

  const [state, setState] = useStates({
    isLoading: false,
    viewMode: "basic",
    name: isEdit ? itemData.name : "",
    quantity: isEdit ? itemData.quantity : 0,
    unlimitedQuantity: isEdit ? (itemData.quantity === null ? true : false) : false,
    price: isEdit ? itemData.price : 0,
    taxRate: isEdit ? itemData.taxRate : 21,
    sku: isEdit ? itemData.sku : "",
    upc: isEdit ? itemData.upc : "",
    tagsID: isEdit ? itemData.tagsID : [],
    isVariants: isEdit ? (itemData.variant.filter((item: any) => item.name !== "").length === 0 ? false : true) : false,
    variant: isEdit ? getVariants(itemData.variant) : [{ name: '', price: isEdit ? itemData.price : 0, quantity: '0', sku: '', upc: '', deleted: false, new: true, uniqueID: getElementID() }],
    active: isEdit ? itemData.active : true,
    media: isEdit ? itemData.media.length === 0 ? [] : getMedias(itemData.media) : [],
  });

  const classes = useStyles({
    viewMode: state.viewMode,
  });

  const duplicateVariants = findDuplicate(state.variant.map((item: any) => { return item.name; }).filter((item: any) => item.length !== 0));

  const tabsItems: any = [
    {
      name: 'stock_item_basic',
      value: 'basic',
      isEnabled: true,
    },
    state.isVariants ? {
      name: 'stock_item_variants',
      value: 'variants',
      badge: duplicateVariants.length === 0 ? (isEdit ? getVariants(state.variant).filter((item: any) => item.name !== '').length : 0) : duplicateVariants.length,
      badgeType: duplicateVariants.length === 0 ? 'primary' : 'error',
      isEnabled: true,
    } : null,
    {
      name: 'stock_item_medias',
      value: 'medias',
      badge: state.media.length,
      badgeType: 'primary',
      isEnabled: true,
    },
    {
      name: 'stock_item_settings',
      value: 'settings',
      isEnabled: true,
    },
  ];

  const refMedia = useRef(state.media);
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      itemID: null,
      schoolID: null,
    };
    dispatch(setStockItemHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    if(name === "price") {
      const newVariants = state.variant.map((item: any) => {
        if(item.name === "") {
          return {...item, price: value};
        } else {
          return item;
        }
      });
      setState("variant", newVariants);
    }
    setState(name, value);
  };

  const handleActive = () => {
    setState("active", !state.active);
  };

  const handleAllowVariants = () => {
    setState("isVariants", !state.isVariants);
    if(state.isVariants) {
      setState("variant", [{ name: '', price: state.price, quantity: 0, deleted: false, new: true, }]);
      setState("price", 0);
      setState("quantity", 0);
    } else {
      setState("price", 0);
      setState("quantity", 0);
    }
  };
  
  const handleUnlimitedQuantity = () => {
    setState("unlimitedQuantity", !state.unlimitedQuantity); 
    if(!state.unlimitedQuantity) {
      setState("quantity", null);
      const newVariants = state.variant.map((item: any) => {
        if(item.name !== "") {
          return {...item, quantity: null};
        } else {
          return item;
        }
      });
      setState("variant", newVariants);
    } else {
      setState("quantity", "0");
      const newVariants = state.variant.map((item: any) => {
        if(item.name !== "") {
          return {...item, quantity: "0"};
        } else {
          return item;
        }
      });
      setState("variant", newVariants);
    }
  };

  const setViewMode = (value: any) => {
    setState("viewMode", value);
  };

  const allowedFiles = ["image", "attachment"];

  const handleMedias = (value: any) => {
    setState("media", value);
    refMedia.current = value;
  };

  const handleSaveTags = (value: any) => {
    const newTags = value.map((item: any) => {
      return item.tagID;
    })
    setState("tagsID", newTags);
  };

  const getTags = (tagsID: any) => {
    let tags = [];
    if(tagsID.length > 0) {
      tags = tagsID.map((item: any) => {
        return getTagData(item);
      });
    }
    return tags;
  };

  const getTagData = (tagID: any) => {
    return stockData.tags.filter((item: any) => item.tagID === tagID).length === 0 ? [] : stockData.tags.find((item: any) => item.tagID === tagID);
  };

  const handleVariants = (value: any) => {
    setState("variant", value);
  };

  const handleSave = (e: any) => {
    if(isEdit) {
      handleEdit(e);
    } else {
      handleCreate(e);
    }
  };

  const handleCreate = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);
    let itemPayload: any = {
      name: state.name,
      categoryID: categoryID,
      schoolID: schoolID,
      quantity: state.unlimitedQuantity ? null : parseInt(state.quantity),
      price: parseFloat(state.price),
      taxRate: parseInt(state.taxRate),
      sku: state.sku,
      upc: state.upc,
      tagsID: state.tagsID,
      variant: state.variant.filter((item: any) => item.name !== ""),
      active: state.active,
    };
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const payload = {
      item: itemPayload,
      media: newMedias,
    };
    stockService && stockService.createItem(payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.itemID) {
              if(result.data.media) {
                if(result.data.media.length > 0) {
                  const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.media.find((theFile: any) => theFile.id === key).uploadUrl};  
                  })
                  handleUploadMedias(result.data.itemID, uploadMedias);
                } else {
                  handleFinish(result.data.itemID);
                }
              } else {
                setState("isLoading", false);
                createNotification(t("stock_item_not_added"), "error");
              }
            } else {
              setState("isLoading", false);
              createNotification(t("stock_item_not_added"), "error");
            }
          } else {
            setState("isLoading", false);
            createNotification(t("stock_item_not_added"), "error");
          }
        } else {
          setState("isLoading", false);
          createNotification(t("stock_item_not_added"), "error");
        }
      }
    }).catch((e: any) => {
      setState("isLoading", false);
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_item_not_added"), "error");
    });
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);
    let postPayload: any = {
      name: state.name,
      categoryID: categoryID,
      schoolID: schoolID,
      quantity: state.unlimitedQuantity ? null : parseInt(state.quantity),
      price: parseFloat(state.price),
      taxRate: parseInt(state.taxRate),
      sku: state.sku,
      upc: state.upc,
      tagsID: state.tagsID,
      variant: state.variant.filter((item: any) => item.name !== ""),
      active: state.active,
    };
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const deletedMedias = state.media.filter((fileData: any) => fileData.status === "deleted").map((fileData: any) => {
      return fileData.mediaID;
    });
    const payload = {
      item: postPayload,
      media: newMedias,
      deletedMediasID: deletedMedias,
    };
    stockService && stockService.editItem(itemID, payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.itemID) {
              if(result.data.media) {
                if(result.data.media.length > 0) {
                  const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.media.find((theFile: any) => theFile.id === key).uploadUrl};  
                  });
                  handleUploadMedias(itemID, uploadMedias);
                } else {
                  handleFinish(itemID);
                }
              } else {
                setState("isLoading", false);
                createNotification(t("stock_item_not_updated"), "error");
              }
            } else {
              setState("isLoading", false);
              createNotification(t("stock_item_not_updated"), "error");
            }
          } else {
            setState("isLoading", false);
            createNotification(t("stock_item_not_updated"), "error");
          }
        } else {
          setState("isLoading", false);
          createNotification(t("stock_item_not_updated"), "error");
        }
      } else {
        setState("isLoading", false);
        createNotification(t("stock_item_not_updated"), "error");
      }
    }).catch((e: any) => {
      setState("isLoading", false);
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_item_not_updated"), "error");
    });
  };

  const handleUploadMedias = (newItemID: any, sentFiles: any) => {
    const file = sentFiles[0];
    const newFiles = sentFiles.filter((fileData: any) => fileData.uid !== file.uid);
    const updateFiles = refMedia.current.map((fileData: any) => {
      if(fileData.uid === file.uid) {
        return {...fileData, status: "uploading"};
      } else {
        return fileData;
      }
    });
    setState("media", updateFiles);
    refMedia.current = updateFiles;
    const reader = new FileReader();
    reader.onload = function() {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(',')[1];
      const arrayBuffer = base64ToArrayBuffer(base64);
      stockService && stockService.uploadFile(file.uploadUrl, arrayBuffer).then((result: any) => {
        if(result) {
          if(result.status === 201) {
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.uid === file.uid) {
                return {...fileData, status: "uploaded"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
            if(newFiles.length === 0) {
              handleFinish(newItemID); 
            } else {
              handleUploadMedias(newItemID, newFiles);  
            }
          }
        }
      }).catch((e: any) => {
        setState("isLoading", false);
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : (isEdit ? t("stock_item_not_updated") : t("stock_item_not_added")), "error");
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setState("media", updateFiles);
        refMedia.current = updateFiles;
      });
    };
    reader.readAsDataURL(file.blob);
  };

  const handleFinish = (newItemID: any) => {
    stockService && stockService.listItems({itemID: newItemID, categoryID: categoryID, schoolID: schoolID}).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.items) {
            if(result.data.items.length === 1) {
              if(isEdit) {
                createNotification(t("stock_item_updated"), "success");
                onCloseModal();
                const newItems = stockData.items.map((item: any) => {
                  if(item.itemID === itemID) {
                    return result.data.items[0];
                  } else {
                    return item;
                  }
                });
                dispatch(setStockItems(newItems));
              } else {
                createNotification(t("stock_item_added"), "success");
                onCloseModal();
                const newItems = [...stockData.items, result.data.items[0]];
                dispatch(setStockItems(newItems));   
              }
            } else {
              setState("isLoading", false);
              createNotification(isEdit ? t("stock_item_not_updated") : t("stock_item_not_added"), "error");
              const updateFiles = refMedia.current.map((fileData: any) => {
                if(fileData.status === "uploaded" || fileData.status === "uploading") {
                  return {...fileData, status: "ready"};
                } else {
                  return fileData;
                }
              });
              setState("media", updateFiles);
              refMedia.current = updateFiles;
            }
          } else {
            setState("isLoading", false);
            createNotification(isEdit ? t("stock_item_not_updated") : t("stock_item_not_added"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          createNotification(isEdit ? t("stock_item_not_updated") : t("stock_item_not_added"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setState("media", updateFiles);
          refMedia.current = updateFiles;
        }
      } else {
        setState("isLoading", false);
        createNotification(isEdit ? t("stock_item_not_updated") : t("stock_item_not_added"), "error");
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setState("media", updateFiles);
        refMedia.current = updateFiles;
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_items_not_loaded"), "error");
    });
  }; 

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "stockItemHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('stock_item_edit') : t('stock_item_add')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <TabsMenu className={classes.tabs} items={tabsItems.filter((item: any) => item !== null)} selected={state.viewMode} onSelect={setViewMode}/>
          {
            state.viewMode === "basic" ? (
              <>
                <Input label={t('stock_item_name')} name="name" value={state.name} onChange={handleInputChange} autoFocus={true}/>
                <div className={classes.inputs}>
                  {
                    !state.isVariants ? (
                      <Input label={t('stock_item_price_without_tax')} type="number" name="price" value={state.price} min={0} append={getCurrency.iso} onChange={handleInputChange}/>
                    ) : null
                  }
                  <Input label={t('stock_item_tax_rate')} type="number" name="taxRate" value={state.taxRate } min={0} max={100} append={"%"} onChange={handleInputChange}/>
                </div>
                <div className={classes.inputs}>
                  <Input label={<>{t('stock_item_sku')}<TooltipIcon className={classes.tooltipIcon} title={t('stock_item_sku_info')} maxWidth={400}/></>} name="sku" value={state.sku} onChange={handleInputChange}/>
                  <Input label={<>{t('stock_item_upc')}<TooltipIcon className={classes.tooltipIcon} title={t('stock_item_upc_info')} maxWidth={400}/></>} name="upc" value={state.upc} onChange={handleInputChange}/>
                </div>
                {
                  !state.unlimitedQuantity && !state.isVariants ? (
                    <div className={classes.inputs}>
                      <Input label={t('stock_item_available_quantity')} name="quantity" type="number" value={state.quantity} min={0} onChange={handleInputChange}/>
                    </div>
                  ) : null
                }
                {
                  tagsList.length > 0 ? (
                    <Select className={classes.select} label={t('stock_item_tags')} inputLabel={t("stock_item_tags")} items={tagsList} selected={getTags(state.tagsID)} setSelected={handleSaveTags} width={200} isMultiple={true} allowClear={false} defaultTitle="name"/>
                  ) : null
                }
              </>
            ) : state.viewMode === "variants" ? (
              <StockVariantInputGroup items={state.variant} onChange={handleVariants} currency={getCurrency.iso} initialPrice={state.price} unlimitedQuantity={state.unlimitedQuantity} disabled={state.isLoading}/>
            ) : state.viewMode === "medias" ? (
              <FileInput files={state.media} onChange={handleMedias} allowed={allowedFiles} disabled={state.isLoading} mediaLimit='timeline'/>
            ) : state.viewMode === "settings" ? (
              <>
                <div className={classes.switches}>
                  <Label>{t('stock_item_active_status')}</Label>
                  <Label>{t('stock_item_variants')}</Label>
                  <Label>{t('stock_item_quantity')}</Label>
                </div>
                <div className={classes.switches}>
                  <Switch checked={state.active} onChange={handleActive} label={t('stock_item_active')} disabled={state.isLoading}/>
                  <Switch checked={state.isVariants} onChange={handleAllowVariants} label={t('stock_item_variants')} disabled={state.isLoading}/>
                  <Switch checked={state.unlimitedQuantity} onChange={handleUnlimitedQuantity} label={t('stock_item_unlimited_quantity')} disabled={state.isLoading}/>
                </div>
              </>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton" disabled={state.isLoading}>
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.name === "" || state.price === "" || state.taxRate === "" || state.quantity === "" || state.isLoading || duplicateVariants.length !== 0} dataCy="saveButton">
            {isEdit ? t("save") : t("create")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default StockItemHandleModal;