import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { setLanguageModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  languageButton: {
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    color: theme.colors.primaryBlue[500],
    backgroundColor: theme.colors.grey[250],
    borderRadius: "50%",
    padding: "10px",
    fontSize: "0.9rem",
    fontWeight: '600',
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
}));

const LanguageButton: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const dispatch = useAppDispatch();
  
  const handleClick = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setLanguageModal(settings));
  };

  return (
    <div className={classes.languageButton}>
      <IconButton className={classes.button} dataCy="languageMenuButton" onClick={handleClick} tooltip={t('change_language')}>
        {languageData.language}
      </IconButton>
    </div>
  );
}

export default LanguageButton;