import ClickAwayListener from '@mui/material/ClickAwayListener';
import EmojiPickerReact, { EmojiStyle } from 'emoji-picker-react';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  offset?: any;
  position?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  emojiPickerWrapper: {
    position: 'absolute',
    bottom: (props: Props) => {
      if(props.position === "left-bottom" || props.position === "right-bottom") return `${props.offset}px`;
      else return '';
    },
    top: (props: Props) => {
      if(props.position === "left-top" || props.position === "right-top") return `${props.offset}px`;
      else return '';
    },
    left: (props: Props) => {
      if(props.position === "left-top" || props.position === "left-bottom") return `${props.offset / 2}px`;
      else return '';
    },
    right: (props: Props) => {
      if(props.position === "right-top" || props.position === "right-bottom") return `${props.offset / 2}px`;
      else return '';
    },
    zIndex: theme.zIndex.popperWrapper,
    '& .epr-emoji-native': {
      fontFamily: `'Noto Color Emoji', sans-serif !important`,
    },
    '& .epr-emoji-category-label': {
      textTransform: 'none',
    },
  },
}));

type EmojiPickerType = {
  isOpen: boolean;
  setIsClose: any;
  onEmojiClick: any;
  suggestedEmojisMode?: 'frequent' | 'recent';
  offset?: number;
  position?: 'left-bottom' | 'right-bottom' | 'left-top' | 'right-top';
};

const EmojiPicker: React.FunctionComponent<EmojiPickerType> = ({ isOpen, setIsClose, onEmojiClick, suggestedEmojisMode = "recent", offset = 24, position = "left-bottom" }) => {
  
  const classes = useStyles({
    offset: offset,
    position: position,
  });
  const { t } = useTranslation();

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const categories: any = [
    {
      category: suggestedEmojisMode ==='frequent' ? 'frequent' : 'suggested',
      name: suggestedEmojisMode === "frequent" ? t('emoji_frequent') : t('emoji_recently_used'),
    },
    {
      category: 'smileys_people',
      name: t('emoji_smileys_people'),
    },
    {
      category: 'animals_nature',
      name: t('emoji_animals_nature'),
    },
    {
      category: 'food_drink',
      name: t('emoji_food_drink'),
    },
    {
      category: 'activities',
      name: t('emoji_activities'),
    },
    {
      category: 'travel_places',
      name: t('emoji_travel_places'),
    },
    {
      category: 'objects',
      name: t('emoji_objects'),
    },
    {
      category: 'symbols',
      name: t('emoji_symbols'),
    },
    {
      category: 'flags',
      name: t('emoji_flags'),
    },
  ];

  const previewConfig = {
    defaultEmoji: "1f60a",
    defaultCaption: '',
    showPreview: false,
  };

  useEffect(() => {
    if(isOpen) {
      setIsEmojiPickerOpen(true);
    }
  }, [isOpen]);  

  const handleClickAway = () => {
    setIsEmojiPickerOpen(false);
    setIsClose();
  };

  const handleOnEmojiClick = (emojiData: any) => {
    onEmojiClick(emojiData);
  };
  
  return isEmojiPickerOpen ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.emojiPickerWrapper}>
        <EmojiPickerReact emojiStyle={EmojiStyle.NATIVE} onEmojiClick={handleOnEmojiClick} searchPlaceholder={t('search')} categories={categories} previewConfig={previewConfig}/>
      </div>
    </ClickAwayListener>
  ) : null;
}

export default EmojiPicker;