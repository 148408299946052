import Button from '@mui/material/Button';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

interface Props {
  selected?: any;
  disabled?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  paginationButton: {
    backgroundColor: (props: Props) => {
      if(props.selected) return theme.colors.primaryBlue[500];
      else if(props.disabled) return theme.colors.grey[325];
      else return "#F0F0F0"; 
    },
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderRadius: '0.5rem',
    borderColor: (props: Props) => {
      if(props.selected) return theme.colors.primaryBlue[500];
      else if(props.disabled) return theme.colors.grey[325];
      else return '#F0F0F0';   
    },   
    padding: '6px 6px',
    minWidth: '40.5px', 
    height: '40.5px',  
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.25s',
    textTransform: 'none',
    textDecoration: 'none',
    cursor: (props: Props) => {
      if(props.disabled || props.selected) return "default";
      else return 'pointer';   
    },
    '& span': {
      fontSize: "14px",
      whiteSpace: "nowrap",
      transition: 'all 0.25s',
      textTransform: 'none',
      textDecoration: 'none',
      color: (props: Props) => {
        if(props.selected) return theme.colors.white;
        else if(props.disabled) return theme.colors.grey[560];
        else return "#000000";
      },
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.selected) return theme.colors.primaryBlue[600];
        else if(props.disabled) return theme.colors.grey[325];
        else return "#F1F1F1";
      },
      borderColor: (props: Props) => {
        if(props.selected) return theme.colors.primaryBlue[600];
        else if(props.disabled) return theme.colors.grey[325];
        else return '#F1F1F1';   
      },  
      '& span': (props: Props) => {
        if(props.selected) return theme.colors.white;
        else if(props.disabled) return theme.colors.grey[560];
        else return "#000000";
      },
    },
  }
}));

type PaginationButtonType = {
  className?: any;
  onClick?: any;
  children?: any;
  selected?: boolean;
  disabled?: boolean;
  tabIndex?: any;
  dataCy?: string;
};

const PaginationButton: React.FunctionComponent<PaginationButtonType> = ({className, onClick, children, selected, disabled, tabIndex = 0, dataCy}) => {

  const classes = useStyles({
    selected,
    disabled,
  });
  
  return (
    <Button type="button" className={`${classes.paginationButton} ${className}`} onClick={!disabled && !selected ? onClick : null} disabled={disabled} tabIndex={parseInt(tabIndex)} data-cy={isCypress() ? dataCy : null}>
      <span>{children}</span>
    </Button>
  )
};

export default PaginationButton;