import React from 'react';
import RenderSchool from '../RenderSchool';
import { createUseStyles } from 'react-jss';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  schoolList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: 'calc(100% - 48px)',
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
      width: 'calc(100% - 24px)',
    },
  },
  schoolWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

type RenderSchoolsType = {
  state: any;
  setState: any;
};

const RenderSchools: React.FunctionComponent<RenderSchoolsType> = ({
  state,
  setState,
}) => {

  const classes = useStyles(); 
  const schoolsList = useMemo(() => state.functions.getSchools(), [state.functions], [state.isShowArchived]);

  const getTotalClasses = useMemo(() => (theSchool: any) => { return state.functions.getClassesBySchoolID(theSchool.schoolID); }, [state.functions], [state.functions, state.isShowArchived, state.search]);
 
  return (
    <div className={`${classes.schoolList} ${state.customClasses ? (state.customClasses.schoolList ? state.customClasses.schoolList : "") : ""}`}>
      {
        schoolsList.map((theSchool: any, key: any) => {
          const totalClasses = getTotalClasses(theSchool);
          return totalClasses.length === 0 ? null : (
            <div className={classes.schoolWrapper} key={`k_${key}`}>
              <RenderSchool state={state} setState={setState} schoolData={theSchool}/>   
            </div>  
          );
        })
      }
    </div>
  );
}

export default RenderSchools;