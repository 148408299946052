import MobileLayout from 'src/containers/Main/Communication/layouts/mobile';
import React from 'react';
import useBreakpoint from 'src/utils/useBreakpoint';
import { getSideContentSize } from 'src/utils/useFunctions';
import { resetCommunicationList, setCommunicationThreadID, setCommunicationUserID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

type SideContentType = {
  className: any,
};

const SideContent: React.FunctionComponent<SideContentType> = ({ className }) => {
  
  const dispatch = useAppDispatch();
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  
  const userAccess = userData.userAccess;
  const breakpoint: any = useBreakpoint();

  const sideContent = layoutData.sideContent;

  const isVisible = getSideContentSize(breakpoint) !== 0;

  const [state, setState] = useStates({
    isLoading: true,
    isFirstTimeLoaded: false,
    isLoadingMessages: false,
  });
  
  const handleIsLoading = (value: any) => {
    setState("isLoading", value);
  };

  const handleIsLoadingMessages = (value: any) => {
    setState("isLoadingMessages", value);
  };

  const handleIsFirstTimeLoaded = (value: any) => {
    setState("isFirstTimeLoaded", value);
  };
  
  useEffect(() => {
    if(sideContent === "communication") {
      dispatch(setCommunicationViewMode(null));
      dispatch(resetCommunicationList());
      setTimeout(() => {
        dispatch(setCommunicationViewMode("threads"));
        dispatch(setCommunicationThreadID(null));
        dispatch(setCommunicationUserID(null));
      }, 1);
    } else {
      dispatch(setCommunicationViewMode(null));
    }
  }, [dispatch, sideContent], [sideContent, isVisible]);

  return isVisible ? (
    <div className={className}>
      {
        (sideContent === "communication" && userAccess.communication) ? (
          <MobileLayout isLoading={state.isLoading} isLoadingMessages={state.isLoadingMessages} isFirstTimeLoaded={state.isFirstTimeLoaded} setIsLoading={handleIsLoading} setIsLoadingMessages={handleIsLoadingMessages} setIsFirstTimeLoaded={handleIsFirstTimeLoaded}/>
        ) : null
      }
    </div>
  ) : null;
}

export default SideContent;