import Button from '@mui/material/Button';
import React from 'react';
import { isCypress } from '../../../utils/useCypress';

type NavigationButtonType = {
  icon?: any;
  className?: any,
  onClick?: any,
  disabled?: boolean,
  tabIndex?: any,
  dataCy?: string
};

const NavigationButton: React.FunctionComponent<NavigationButtonType> = ({ icon, className, onClick, disabled = false, tabIndex = 0, dataCy }) => {
  return (
    <Button className={className} disabled={disabled} onClick={() => !disabled && onClick()} tabIndex={tabIndex} data-cy={isCypress() ? dataCy : null}>
      {icon}
    </Button>
  );
};

export default NavigationButton;