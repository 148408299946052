import Calendar from './Calendar';
import Communication from './Communication';
import Homepage from './Homepage';
import MenuItems from './MenuItems';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useState } from 'react';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import Theme from './Theme';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting } from '../../../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { validateItemRequirements } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  customizationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '16px',
    maxWidth: '100%',
    marginBottom: '20px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: '0px',
      marginBottom: '0px',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const CustomizationSettings: React.FunctionComponent = () => {

  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const { viewmode: customViewMode } = useParams();
  
  const tabsItems = tabs.settings.customization.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType))).map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null);

  const getCustomViewMode = customViewMode ? (tabsItems.filter((item: any) => item.value === customViewMode).length === 0 ? null : tabsItems.find((item: any) => item.value === customViewMode).value) : null;

  const [viewMode, setViewMode] = useState(getCustomViewMode ? getCustomViewMode : tabsItems[0].value);

  useEffect(() => {
    const url = location.pathname.replace("/settings/customizations/", "");
    if(url !== "/settings/customizations") {
      window.history.replaceState({},'',"/settings/customizations");
    }
  }, [location.pathname], []);

  return (
    <div className={classes.customizationWrapper}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={viewMode} onSelect={setViewMode}/>
      </div>
      <div className={classes.boxWrapper}>
        {
          viewMode === "menu_items" ? (
            <MenuItems/>
          ) : viewMode === "homepage" ? (
            <Homepage/>
          ) : viewMode === "theme" ? (
            <Theme/>
          ) : viewMode === "communication" ? (
            <Communication/>
          ) : viewMode === "calendar" ? (
            <Calendar/>
          ) : (
            <NotFound text={t('not_found')}/>
          )
        }
      </div>
    </div>
  );
};

export default CustomizationSettings;