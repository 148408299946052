import Attendance from './Attendance';
import Calendar from './Calendar';
import Children from './Children';
import Contacts from './Contacts';
import Dashboard from './Dashboard';
import Employees from './Employees';
import ExcuseNotes from './ExcuseNotes';
import Finance from './Finance';
import FirebaseNotifications from './FirebaseNotifications';
import Gallery from './Gallery';
import React from 'react';
import Shop from './Shop';
import Substitutions from './Substitutions';
import Timeline from './Timeline';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { IsLocation, IsLocationPart } from 'src/utils/useLocation';
import { isNotificationSupported } from 'src/utils/useFirebase';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { getUserSetting } from 'src/utils/useUser';
import CommunicationButton from './CommunicationButton';

const useStyles = createUseStyles((theme: any) => ({
  toolbarMenu: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

const ToolbarMenu: React.FunctionComponent = () => {
  
  const classes = useStyles();
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const isOpenSubMenu = layoutData.isOpenSubMenu;
  const isOpenMoreMenu = layoutData.isOpenMoreMenu;
  
  const browserNotificationsStatus = isNotificationSupported ? Notification.permission : false;
  const webAppNotificationStatus = useAppSelector((state: any) => state.firebasenotification.permission);

  const isShowToolbarCommunicationButton = getUserSetting(userData.userSettings, "addons", ["communication", "communication_show_toolbar_button"]);

  return (
    <div className={classes.toolbarMenu} data-cy={isCypress() ? `mainLayoutToolbar` : null}>
      {
        (!isOpenSubMenu && !isOpenMoreMenu) ? (
          <>
            {
              IsLocation("/dashboard") ? (
                <Dashboard/>
              ) : null
            }
            {
              IsLocation("/timeline") ? (
                <Timeline/>
              ) : null
            }
            {
              IsLocationPart("/children") ? (
                <Children/>
              ) : null
            }
            {
              IsLocation("/employees") ? (
                <Employees/>
              ) : null
            }
            {
              IsLocation("/excuse-notes") ? (
                <ExcuseNotes/>
              ) : null
            }
            {
              IsLocationPart("/finance") ? (
                <Finance/>
              ) : null
            }
            {
              IsLocation("/gallery") ? (
                <Gallery/>
              ) : null
            }
            {
              IsLocation("/attendance") ? (
                <Attendance/>
              ) : null
            }
            {
              IsLocation("/shop") ? (
                <Shop/>
              ) : null
            }
            {
              IsLocation("/substitutions") ? (
                <Substitutions/>
              ) : null
            }
            {
              IsLocation("/calendar") ? (
                <Calendar/>
              ) : null
            }
            {
              IsLocation("/contacts") ? (
                <Contacts/>
              ) : null
            }
            {
              (isNotificationSupported && (browserNotificationsStatus === "granted" || webAppNotificationStatus === "granted")) ? (
                <FirebaseNotifications/>
              ) : null
            }
            {
              isShowToolbarCommunicationButton && !IsLocationPart("/communication") ? (
                <CommunicationButton/>
              ) : null
            }
          </>
        ) : null
      }
    </div>
  );
}

export default ToolbarMenu;