import DateFormat from '../../../../../utils/dateFormat';
import NormalButton from '../../../../../components/Buttons/NormalButton';
import React from 'react';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  premiumWrapper: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
      '& + p': {
        marginTop: '16px',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  banner: {
    marginTop: '16px',
  },
  title: {
    fontSize: '36px',
    textTransform: 'lowercase',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
  },
  link: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  price: {
    fontSize: '24px',
    color: '#555555',
  },
}));

const PremiumSettings: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const orderService = useAppSelector((state: any) => state.services).orderService;

  const openMoreInfo = () => {
    window.open(userData.membership.infopage);
  };

  const handleOrderPremium = () => {
    const payload = {
      "items": [
        {
          "code": "premium_membership_parent"
        }
      ]
    };
    orderService && orderService.createOrder(payload).then((result: any) => {
      if(result.status === 201) {
        if(result.data) {
          if(result.data.redirectUrl) {
            window.location.href = result.data.redirectUrl;
          }
        }
      }
    }).catch((e: any) => {
      createNotification(t('premium_order_failed'), 'error');
    });
  }; 

  const handleOrderContinue = () => {
    window.location =userData.order.statusPage;
  }; 

  const handleOrderDetail = () => {
    window.open(userData.order.statusPage);
  }; 

  return (
    <div className={classes.premiumWrapper}>
      <div className={classes.box}>
        <span className={classes.title}>
          <span>{t('premium')}</span> {t('parent')}
        </span>
        {
          (userData.membership.active) ? (
            <>
              <p>{t('premium_active')}</p>
              <p>{userData.membership.dateExpiration ? t('premium_active_info', {date: DateFormat(userData.membership.dateExpiration, "default", languageData, t)}) : null}</p>
              {userData.order.statusPage ? (<NormalButton buttonType="primary" onClick={handleOrderDetail}>{t('premium_detail')}</NormalButton>) : null}
              <p>{t('premium_inactive_info')}, <span className={classes.link} onClick={openMoreInfo}>{t('premium_inactive_link').toLowerCase()}</span>.</p>
            </>
          ) : (
            <>
            {
              userData.order.status === "issued" ? (
                <>
                  <p>{t('premium_inprocess')}</p>
                  <p>{t('premium_inprocess_info')}</p>
                  <NormalButton buttonType="primary" onClick={handleOrderContinue}>{t('premium_continue')}</NormalButton>
                  <p>{t('premium_inactive_info')}, <span className={classes.link} onClick={openMoreInfo}>{t('premium_inactive_link').toLowerCase()}</span>.</p>
                </>
              ) : (
                <>
                  <span className={classes.price}>
                    {userData.membership.price}/{t('year').toLowerCase()}
                  </span>
                  <p>{t('premium_inactive')}</p>
                  <p>{t('premium_inactive_info')}, <span className={classes.link} onClick={openMoreInfo}>{t('premium_inactive_link').toLowerCase()}</span>.</p>
                  <NormalButton buttonType="primary" onClick={handleOrderPremium}>{t('premium_order')}</NormalButton>
                </>
              )
            }
            </>
          )
        }
      </div>
    </div>
  );
};

export default PremiumSettings;