import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  image?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  mobile: {
    boxShadow: '0px 0px 0px 2px white',
    backgroundColor: 'hsl(0, 0%, 0%)',
    borderRadius: '2rem',
    padding: '0.5rem',
    width: 'min(250px)',
    aspectRatio: 1/2,
    position: 'relative',
    marginInline: 'auto',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '1rem',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1.3rem',
      backgroundColor: 'hsl(0, 0%, 0%)',
      width: '22%',
      borderRadius: '25px',
      zIndex: '3',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: (props: Props) => {
      if(props.image) return `url('${props.image}')`;
      else return '';
    },
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    overflow: 'hidden',
    borderRadius: '2rem',
  },
}));

type MobileType = {
  children?: any;
  image?: any;
  className?: any;
};

const Mobile: React.FunctionComponent<MobileType> = ({ children, image, className }) => {

  const classes = useStyles({
    image: image
  });
  
  return (
    <div className={`${classes.mobile} ${className ? className : null}`}>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

export default Mobile;
