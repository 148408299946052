import slice from '../slices/stock.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setStockCart = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockCart(data));
  }
};

export const setStockCategories = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockCategories(data));
  }
};

export const setStockCategory = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockCategory(data));
  }
};

export const setStockItems = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockItems(data));
  }
};

export const setStockItemsCount = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockItemsCount(data));
  }
};

export const setStockOrders = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockOrders(data));
  }
};

export const setStockSelectedOrders = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockSelectedOrders(data));
  }
};

export const setStockOrdersCount = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockOrdersCount(data));
  }
};

export const setStockSchool = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockSchool(data));
  }
};

export const setStockTags = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockTags(data));
  }
};

export const setStockViewMode = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockViewMode(data));
  }
};

export const setStockFilterCurrentPage = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterCurrentPage(data));
  }
};

export const setStockFilterOrderID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterOrderID(data));
  }
};

export const setStockFilterSchoolID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterSchoolID(data));
  }
};

export const setStockFilterChildID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterChildID(data));
  }
};

export const setStockFilterUserID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterUserID(data));
  }
};

export const setStockFilterOrderStatusID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockFilterOrderStatusID(data));
  }
};