import Button from '@mui/material/Button';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import EmployeesSelect from '../EmployeesSelect';
import EmployeeInfoItem from 'src/components/Items/EmployeeInfoItem';

const useStyles = createUseStyles((theme: any) => ({
  employeesWrapper: {
    display: 'flex',
    flexDirection: "row",
    gap: '5px',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: theme.shadows[2],
    padding: '13px 12px',
    alignItems: 'flex-start',
    maxWidth: 'calc(100% - 24px)',
    overflow: 'hidden',
    height: 'fit-content',
    position: 'relative',
    width: '100%',
    '&:empty': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    }, 
  },
  employeeWrapper: {
    width: '90px',
    height: '100%',
  },
  employeeMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '90px',
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
  },
  employeeMore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',  
    width: '100%',
    height: '100%',
    borderRadius: '0',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'all 0.25s',
    '&.active': {
      backgroundColor: theme.colors.primaryBlue[600],
      color: theme.colors.white,
    },
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
      color: theme.colors.white,
    },
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type EmployeeListSelectType = {
  currentEmployee: any;
  employeesData: any;
  setCurrentEmployee: any;
  useDispatch?: any;
  width?: any,
  isDisabled: any;
};

const EmployeesListSelect: React.FunctionComponent<EmployeeListSelectType> = ({currentEmployee = null, employeesData = null, setCurrentEmployee = null, useDispatch = false, width = null, isDisabled = false}) => {
  
  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const curEmployee = currentEmployee;
  const employees = employeesData.map((item: any) => { return { employeeID: item.employeeID, schoolID: item.schoolID }; });
  const curEmployeeIndex = (curEmployee === null || curEmployee === undefined) ? 0 : employees.findIndex((employee: any) => employee.employeeID === curEmployee.employeeID);
  const employeesCount: any = Math.floor((width === null || width === undefined) ? browserData.width : width / 95) - 1;
  const employeesVisible: any = (curEmployee === null || curEmployee === undefined) === null ? [] : employees.slice(Math.max(Math.min(curEmployeeIndex - employeesCount / 2, employees.length - employeesCount), 0), Math.max(Math.min(curEmployeeIndex - employeesCount / 2, employees.length - employeesCount), 0) + employeesCount);
  const [isEmployeesSelectOpen, setIsEmployeesSelectOpen] = useState(false);
  const dispatch = useAppDispatch();

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const setCurEmployee = (employee: any) => {
    if(useDispatch) {
      dispatch(setCurrentEmployee(employee));
    } else {
      setCurrentEmployee(employee);
    }
  };

  const setCurrentEmployeeFromModal = (e: any, data: any) => {
    e.stopPropagation();
    setCurEmployee(getEmployeeData(data.employee.employeeID));
  };

  const setCurrentEmployeeFromList = (employee: any) => {
    setCurEmployee(getEmployeeData(employee.employeeID));
  };

  const handleOpenEmployeesSelect = () => {
    setIsEmployeesSelectOpen(true); 
  };

  const handleCloseEmployeesSelect = () => {
    setIsEmployeesSelectOpen(false); 
  };
  
  return curEmployee ? (
    <div className={classes.employeesWrapper}>
      {
        employeesVisible.length > 0 ? employeesVisible.map((employee: any, key: any) => (
          <div className={classes.employeeWrapper} key={`k_${key}`}>
            <EmployeeInfoItem employeeID={employee.employeeID} isSelected={employee.employeeID === curEmployee.employeeID} isDisabled={employee.employeeID === curEmployee.employeeID ? false : isDisabled} onClick={() => setCurrentEmployeeFromList(employee)} disableClick={employee.employeeID === curEmployee.employeeID ? true : false}/>
          </div>
        )) : null
      }
      {
        employees.length > employeesVisible.slice.length ? (
          <div className={classes.employeeMoreWrapper}>
            <Button className={`${classes.employeeMore} ${isEmployeesSelectOpen && 'active'}`} onClick={handleOpenEmployeesSelect}>
              <SVG src="more"/>
            </Button>
          </div>
        ) : null
      }
      {
        isEmployeesSelectOpen ? (
          <EmployeesSelect
            isInModal={true}
            isModalOpen={true}
            modalTitle="employees"
            modalOnClose={handleCloseEmployeesSelect}
            onClickEmployee={setCurrentEmployeeFromModal}
            selectedEmployees={[{employeeID: curEmployee.employeeID}]}
            defaultEmployees={employees}
            isSelectAll={false}
            isSelectInAllSchool={true}
            isMultipleSelect={false}
            isAllowArchivedToggle={employees.filter((item: any) => getEmployeeData(item).isArchived).length !== 0}
            isAllowSearch={true}
            mode="select"
            isDisableClick={isDisabled}
            isAllowOnlyOneEmployeeAtOnce={true}
            modalAllowNoEmployee={true}
            modalAllowEmployeesCount={false}
            modalAllowCancel={true}
            modalAllowClose={true}
          />
        ) : null
      }
    </div>
  ) : null;
}

export default EmployeesListSelect;