import Button from '@mui/material/Button';
import Clamp from 'react-multiline-clamp';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';

interface Props {
  buttonType?: any;
  originalButtonType? : any;
  disabled: any;
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  blockButtonWrapper: {
    position: 'relative',
    '& > div': {
      position: 'absolute',
      top: (props: Props) => (props.size / 32) + 'px',
      right: (props: Props) => (props.size / 32) + 'px',
      cursor: 'pointer',
      color: (props: Props) => {
        if(props.originalButtonType === "blue") return theme.colors.primaryBlue[500];
        else if(props.originalButtonType === "green") return theme.colors.systemGreen[500];
        else if(props.buttonType === "red") return theme.colors.systemRed[500];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[500];
        else return 'none';
      },
      '& > svg': {
        width: (props: Props) => (props.size / 12) + 'px',
        height: (props: Props) => (props.size / 12) + 'px',
      },
    },
  },
  blockButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    minWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',
    borderRadius: (props: Props) => (props.size / 18) + 'px',
    boxShadow: theme.shadows[2],
    gap: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: theme.colors.white,
    color: (props: Props) => {
      if(props.buttonType) return theme.colors.black;
      else return 'none';
    },
    '& svg': {
      width: (props: Props) => (props.size / 4) + 'px',
      height: (props: Props) => (props.size / 4) + 'px',
      color: theme.colors.grey[700],
    },
    '& > span': {
      display: 'block',
      maxWidth: '100%',
      color: (props: Props) => {
        if(props.disabled) return theme.colors.grey[500];
        else if(props.buttonType === "blue") return theme.colors.primaryBlue[500];
        else if(props.buttonType === "green") return theme.colors.systemGreen[500];
        else if(props.buttonType === "red") return theme.colors.systemRed[500];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[500];
        else return 'none';
      },
      fontSize: (props: Props) => (props.size / 12.8) + 'px',
      fontWeight: '500',
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.buttonType === "blue") return theme.colors.primaryBlue[100];
        else if(props.buttonType === "green") return theme.colors.systemGreen[100];
        else if(props.buttonType === "red") return theme.colors.systemRed[100];
        else if(props.buttonType === "orange") return theme.colors.systemOrange[100];
        else return 'none';
      },
    },
  },
}));

type BlockButtonType = {
  className?: any;
  icon?: any;
  buttonType?: 'blue' | 'green' | 'red' | 'orange' | 'disabled';
  onInfoIconClick?: any;
  onClick?: any;
  children?: any;
  dataCy?: string;
  disabled?: boolean;
  size?: any;
  maxLines?: number;
};

const BlockButton: React.FunctionComponent<BlockButtonType> = ({className, buttonType = 'blue', icon, onInfoIconClick, onClick, children, dataCy, disabled, size = 256, maxLines = 1}) => {
  
  const originalButtonType = buttonType;
  if(buttonType === "disabled") disabled = true;
  if(disabled) buttonType = "disabled";

  const classes = useStyles({
    buttonType,
    originalButtonType,
    disabled,
    size
  });

  const handleOnInfoIconClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onInfoIconClick();
  };

  return (
    <div className={classes.blockButtonWrapper}>
      <Button type="button" className={`${classes.blockButton} ${className}`} onClick={onClick} disabled={disabled} tabIndex={-1} data-cy={isCypress() ? dataCy : null}>
        {icon}
        <Clamp withTooltip lines={maxLines}>
          <span>{children}</span>
        </Clamp>
      </Button>
      {
        onInfoIconClick ? (
          <div onClick={handleOnInfoIconClick}>
            <SVG src="info-circle-outlined"/>
          </div>
        ) : null
      }
    </div>
  );
};

export default BlockButton;