import MediaGrid from '../MediaGrid';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setShopGalleryModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  shopGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: 'calc(100% - 30px)',
    padding: '15px',
  },
}));

type ShopGalleryType = {
  itemID: any;
};

const ShopGallery: React.FunctionComponent<ShopGalleryType> = ({ itemID }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stockData = useAppSelector((state: any) => state.stock);
  const itemData = stockData.items.find((item: any) => item.itemID === itemID);
  const photos = itemData.media.photos;
  const files = itemData.media.files;
  const [gridData, setGridData]: any = useState([]);
  
  useEffect(() => {
    const tmpPhotos = photos.map((photo: any) => {
      return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name, favorite: photo.favorite };
    });
    const tmpFiles = files.map((file: any) => {
      return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size, favorite: file.favorite };
    });
    const tmpGridData = [].concat(tmpPhotos, tmpFiles).filter((item: any) => item);
    if(tmpGridData.length > 0) {
      setGridData(tmpGridData);
    }
  }, [itemID, photos, files, dispatch], [itemID, photos, files]);

  const handleOpenModal = (mediaID: any) => {
    const settings = {
      isOpen: true,
      mediaID: mediaID,
      mediaList: gridData,
    };
    dispatch(setShopGalleryModal(settings));  
  };
  
  return (
    <div className={classes.shopGallery} data-cy={isCypress() ? "shopGallery" : null}>
      <MediaGrid
        data={gridData}
        width='100%'
        maxWidth='100%'
        onModalOpen={handleOpenModal}
      />
    </div>
  );
}

export default ShopGallery;