import AuthenticatedImage from '../../../Items/AuthenticatedImage';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import ReactPlayer from 'react-player';
import SVG from '../../../Images/SvgRenderer';
import { arrayBufferToBase64, base64toBlob } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { setVideos } from '../../../../store/actions/medias.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useStates } from '../../../../utils/useState';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  playerWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& > div:first-of-type': {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: '2',
    },
  },
  player: {
    '& > div': {
      backgroundSize: 'contain !important',
      backgroundRepeat: 'no-repeat',
    },
    '& svg': {
      transform: 'scale(2.5)',
      filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))',
      color: theme.colors.white,
    },
  },
  imageFile: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxHeight: '100%',
    height: '100%',
    margin: '0 auto',
    '& > div': {
      position: 'relative',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  },
}));

type RenderVideoType = {
  mediaData: any;
  setIsDownloadable: any;
  handleSecureClick: any;
  setMetadata: any;
};

const RenderVideo: React.FunctionComponent<RenderVideoType> = ({ mediaData, setIsDownloadable, handleSecureClick, setMetadata }) => {
  const mediasData = useAppSelector((state: any) => state.medias);
  const mediaID = useAppSelector((state: any) => state.modals).galleryModal.mediaID;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const contentType = "application/octet-stream";
  const thumbNail = mediasData.images.filter((img: any) => img.url === mediaData.thumbLink).length === 0 ? '/resources/images/video_placeholder.jpg' : mediasData.images.find((img: any) => img.url === mediaData.thumbLink).image;
  
  const [state, setState, setStates] = useStates({
    mediaB64: mediasData.videos.filter((video: any) => video.id === mediaID).length === 0 ? null : mediasData.videos.find((video: any) => video.id === mediaID).base64,
    mediaUrl: null,
    isVideoLoaded: false,
    lastMediaID: null,
    duration: null,
    size: null,
  });
  
  const reactPlayerConfig = {
    file: {
      attributes: {
        controlsList: "nodownload noremoteplayback noplaybackrate",
        disablePictureInPicture: true,
        preload: 'auto',
      }
    }
  };
  
  useEffect(() => {
    setState("mediaB64", null);
    setState("isVideoLoaded", false);
  }, [mediaData, setState], [mediaData]);
  
  useEffect(() => {
    if(!state.isVideoLoaded) {
      if(state.mediaB64 !== null) {
        const blob = base64toBlob(state.mediaB64, contentType);
        setStates({
          mediaUrl: URL.createObjectURL(blob),
          size: Math.ceil(state.mediaB64.length / 4) * 3,
          isVideoLoaded: true,
          lastMediaID: mediaID,
        });
        setIsDownloadable(true);
        handleSecureClick();
      } else {
        timelineService && timelineService.downloadFile(mediaID).then((result: any) => {
          if(result) {
            if(result.data) {
              const base64 = arrayBufferToBase64(result.data);
              const blob = base64toBlob(base64, contentType);
              const videoObj = { base64: base64, id: mediaID };
              dispatch(setVideos(videoObj));
              setStates({
                mediaUrl: URL.createObjectURL(blob),
                size: Math.ceil(base64.length / 4) * 3,
                isVideoLoaded: true,
                lastMediaID: mediaID,
              });
              setIsDownloadable(true);
              handleSecureClick();
            } else {
              setStates({
                isVideoLoaded: true,
                isFailed: true,
              });
            }
          } else {
            setStates({
              isVideoLoaded: true,
              isFailed: true,
            });
          }
        }).catch(() => {
          setStates({
            isVideoLoaded: true,
            isFailed: true,
          });
        });
      }
    }         
  }, [state.isVideoLoaded, dispatch, handleSecureClick, mediaID, setIsDownloadable, setStates, state.mediaB64, timelineService], [state.isVideoLoaded]); 

  const onReady = (e: any) => {
    setState("duration", e.player.getDuration());
  };

  useEffect(() => {
    if(state.duration !== null && state.size !== null) {
      const settings = {
        duration: state.duration,
        size: state.size,
      };
      setMetadata(settings);
    }
  }, [state.duration, state.size, setMetadata], [state.duration, state.size]);
      
  return (state.isVideoLoaded && state.lastMediaID === mediaID) ? (
    <ReactPlayer onReady={onReady} width='100%' height='100%' className={classes.player} url={state.mediaUrl} controls={true} playing={false} playsinline={true} playIcon={<SVG src={`${"play"}`}/>} config={reactPlayerConfig}/>
  ) : (
    <div className={classes.playerWrapper}>
      <div className={classes.loading}>
        <CircularProgress className={classes.spinner}/>
      </div>
      <AuthenticatedImage thumbLink={thumbNail} preferQuality="thumb" className={classes.imageFile} useLoader={true}/>
    </div>
  );
};

export default RenderVideo;