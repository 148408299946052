import React, { useCallback } from 'react';
import theme from 'src/ui/theme';
import Tooltip from '@mui/material/Tooltip';
import { createUseStyles } from 'react-jss';
import { getDayName, isLightOrDark } from 'src/utils/useFunctions';
import { setTimetableHourDetailModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  color: any;
  isLightOrDark: any;
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  subject: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: (props: Props) => props.color,
    color: (props: Props) => {
      if(props.isLightOrDark(props.color) === "light") return '#000000';
      else return '#FFFFFF';
    },
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    padding: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    width: (props: Props) => {
      if(props.size === 1) return 'calc(100% - 16px)';
      else if(props.size === 2) return 'calc(100% - 12px)';
      else if(props.size === 3) return 'calc(100% - 8px)';
      else if(props.size === 4) return 'calc(100% - 8px)';
      else return '0px';
    },
    height: (props: Props) => {
      if(props.size === 1) return 'calc(100% - 16px)';
      else if(props.size === 2) return 'calc(100% - 12px)';
      else if(props.size === 3) return 'calc(100% - 8px)';
      else if(props.size === 4) return 'calc(100% - 8px)';
      else return '0px';
    },
  },
  roomName: {
    position: 'absolute',
    maxWidth: '100%',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    top: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    left: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    right: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    fontSize: (props: Props) => {
      if(props.size === 1) return '10px';
      else if(props.size === 2) return '10px';
      else if(props.size === 3) return '8px';
      else if(props.size === 4) return '8px';
      else return '0px';
    },
  },
  subjectName: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    left: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    right: (props: Props) => {
      if(props.size === 1) return '8px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    fontSize: (props: Props) => {
      if(props.size === 1) return '28px';
      else if(props.size === 2) return '16px';
      else if(props.size === 3) return '14px';
      else if(props.size === 4) return '12px';
      else return '0px';
    },
  },
  teachers: {
    position: 'absolute',
    bottom: (props: Props) => {
      if(props.size === 1) return '8px';
      else return 'unset';
    },
    left: (props: Props) => {
      if(props.size === 1) return '8px';
      else return 'unset';
    },
    right: (props: Props) => {
      if(props.size === 1) return '8px';
      else return 'unset';
    },
    display: (props: Props) => {
      if(props.size === 1) return 'block';
      else return 'none';
    },
    maxWidth: '100%',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: (props: Props) => {
      if(props.size === 1) return '10px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
    lineHeight: (props: Props) => {
      if(props.size === 1) return '10px';
      else if(props.size === 2) return '6px';
      else if(props.size === 3) return '4px';
      else if(props.size === 4) return '4px';
      else return '0px';
    },
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.colors.primaryBlue[500],
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipArrow: {
    color: theme.colors.primaryBlue[500],
  },
}));

type SubjectItemType = {
  hourData: any;
  day: any;
  hour: any;
  size: any;
};

const SubjectItem: React.FunctionComponent<SubjectItemType> = ({hourData, day, hour, size}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const settings = timetableData.settings === null ? [] : timetableData.settings;
  const rooms = timetableData.rooms;
  const subjects = timetableData.subjects;
  const timetableSettings = timetableData.settings === null ? [] : timetableData.settings;
  const roomID = hourData.roomID;
  const subjectID = hourData.subjectID;
  const type = hourData.type;
  const timetableID = hourData.timetableID;
  const teacherID = hourData.teacherID;

  const subjectData = subjects.filter((item: any) => item.subjectID === subjectID).length === 1 ? subjects.find((item: any) => item.subjectID === subjectID) : [];
  const roomData = rooms.filter((item: any) => item.roomID === roomID).length === 1 ? rooms.find((item: any) => item.roomID === roomID) : [];

  const getEmployeeData = useCallback((employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  }, [dataData.employees]);

  const getEmployeeName = (employeeID: any) => {
    return (getEmployeeData(employeeID).firstname ? getEmployeeData(employeeID).firstname : "") + " " + (getEmployeeData(employeeID).surname ? getEmployeeData(employeeID).surname : "");
  };

  const getEmployeeAbbr = (employeeID: any) => {
    return (getEmployeeData(employeeID).firstname ? getEmployeeData(employeeID).firstname : "").substr(0,2) + (getEmployeeData(employeeID).surname ? getEmployeeData(employeeID).surname : "").substr(0,2);
  };

  const classes = useStyles({
    color: subjectData.color ? subjectData.color : theme.colors.black,
    isLightOrDark: isLightOrDark,
    size: size <= 4 ? size : 4,
  });

  const handleDetailHour = (day: any, hour: any, type: any) => {
    const settings = {
      isOpen: true,
      weekday: day,
      hour: hour,
      type: type,
      timetableID: timetableID,
      mode: timetableData.type,
      isEditable: timetableSettings.canEdit,
      isFirstBreakZero: false,
    };
    dispatch(setTimetableHourDetailModal(settings));
  };

  const tooltipContent = (
    <>
      <b>{subjectData.name} - {t(getDayName(day))} {hour}. {t('hour').toLowerCase()}</b>
      <span>
        {t('timetable_teachers')}:&nbsp;
        <b>
          {
            teacherID.map((teacher: any) => {
              return getEmployeeName(teacher);
            }).join(", ")
          }
        </b>
      </span>
      {
        roomData.name ? (
          <span>
            {t('timetable_room')}:&nbsp;
            <b>
              {
                roomData.name
              }
            </b>
          </span>
        ) : null
      }
    </>
  );

  return subjects.filter((item: any) => item.subjectID === subjectID).length === 1 ? (
    <div className={classes.subject} onClick={() => handleDetailHour(day, hour, type)}>
      <Tooltip title={tooltipContent} classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} placement='right-start' disableHoverListener={!settings.subjectPopup} disableInteractive={true} arrow>
        <div className={classes.inner}>
          <span className={classes.roomName}>{roomData.shortName}</span>
          <span className={classes.subjectName}>{subjectData.shortName}</span>
          <span className={classes.teachers}>
            {
              teacherID.map((teacher: any) => {
                return getEmployeeAbbr(teacher);
              }).join(",")
            }
          </span>
        </div>
      </Tooltip>
    </div>
  ) : null;
}

export default SubjectItem;