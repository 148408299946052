import CloseButton from 'src/components/Buttons/CloseButton';
import htmlParse from 'html-react-parser';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import RenderAttachment from './RenderAttachment';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setAttachmentModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  browserWidth: any;
  browserHeight: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: (props: Props) => props.browserWidth - 80 + 'px',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    height: (props: Props) => props.browserHeight - 80 + 'px',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flex: '1 1 100%',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      display: "flex",
      fontWeight: "bold",
      marginBottom: "0",
      flex: '1 1 100%',
      '& > span': {
        fontWeight: '400',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
  },
  preview: {
    width: '100%',
    maxWidth: '100%',
    height: (props: Props) => props.browserHeight - 200 + 'px',
    marginTop: '16px',
    overflow: 'hidden',
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '8px',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

const AttachmentModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const modalsData = useAppSelector((state: any) => state.modals);

  const title = modalsData.attachmentModal.title;
  const content = modalsData.attachmentModal.content;
  const type = modalsData.attachmentModal.type;
  const canDownload = modalsData.attachmentModal.download;
  const canPrint = modalsData.attachmentModal.isPrintable;

  const classes = useStyles({
    browserWidth: browserData.width,
    browserHeight: browserData.height,
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      title: null,
      content: null,
      download: null,
      type: null,
      isPrintable: null,
    };
    dispatch(setAttachmentModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleDownload = () => {
    canDownload();
  };

  const handlePrint = () => {
    const printWindow: any = window.open(`${content}#toolbar=0`, '_blank');
    printWindow.focus();
    printWindow.print();
    return true;
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "attachmentModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{modalsData.attachmentModal.title ? htmlParse(title) : ''}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <div className={classes.preview}>
            {
              content === null ? (
                <div className={classes.loading}>
                  <CircularProgress className={classes.spinner}/>
                </div>
              ) : (
                <RenderAttachment mediaUrl={content} mediaType={type}/>
              )
            }
          </div>
        </div>
        <div className={classes.footer}>
          {
            canPrint !== null ? (
              <NormalButton buttonType="secondary" onClick={handlePrint} data-cy={isCypress() ? "printButton" : null}>
                {t("print")}
              </NormalButton>
            ) : null
          }
          {
            canDownload !== null ? (
              <NormalButton buttonType="primary" onClick={handleDownload} data-cy={isCypress() ? "downloadButton" : null}>
                {t("download")}
              </NormalButton>
            ) : null
          }
        </div>
      </div>
    </Modal>
  )
};  

export default AttachmentModal;
