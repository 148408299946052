import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';

interface Props {
  position?: any;
  timeout?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  firebaseNotification: {
    position: 'relative',
    display: 'flex',
    padding: '12px 24px',
    marginLeft: (props: Props) => {
      if(props.position === 'top-left' || props.position === 'bottom-left') return '16px';
      else return '';
    },
    marginRight: (props: Props) => {
      if(props.position === 'top-right' || props.position === 'bottom-right') return '16px';
      else return '';
    },
    borderRadius: '6px',
    cursor: 'pointer',
    width: 'fit-content',
    maxWidth: 'calc(20vw - 48px)',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: theme.colors.alwaysWhite,
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(90vw - 48px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '18px',
    },
    '& > p': {
      fontSize: '14px',
    },
  },
  image: {
    position: 'relative',
    width: '48px',
    height: '48px',
    minWidth: '48px',
    minHeight: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
    borderRadius: '6px',
  },
  counter: {
    display: 'block',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '5px',
  },
  bar: {
    display: (props: Props) => {
      if(parseInt(props.timeout) !== 0) return 'block';
      else return 'none';
    },
    height: '100%',
    backgroundColor: theme.colors.alwaysWhite,
    transitionProperty: 'width',
    transitionDuration: (props: Props) => props.timeout + 'ms',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    maxWidth: '100%',
    width: '100%',
    borderRadius: '0 0 6px 6px',
    '&.active': {
      width :'0%',
    },  
  }, 
}));

type FirebaseNotificationType = {
  date: any;
  title: any;
  text: any;
  image: any;
};

const FirebaseNotification: React.FunctionComponent<FirebaseNotificationType> = ({ date, title, text, image }) => {
  
  const positionSelector = useAppSelector((state: any) => state.firebasenotification.position);
  const timeoutSelector = useAppSelector((state: any) => state.firebasenotification.timeout);
  const currentDate = Date.now();
  const expire = parseInt(date) + parseInt(timeoutSelector);

  const [state, setState] = useStates({
    position: positionSelector,
    timeout: timeoutSelector,
    isVisible: parseInt(timeoutSelector) === 0 ? true : (expire > currentDate ? true : false),
    isAnimated: false,
  });

  const classes = useStyles({
    position: state.position,
    timeout: state.timeout,
  });

  const handleOnClick = () => {
    handleRemove();  
  };  
  
  const handleRemove = () => {
    setState("isVisible", false);
  };
  
  useEffect(() => {
    if(parseInt(state.timeout) !== 0) {
      setTimeout(function() {
        setState("isVisible", false);
      }, state.timeout);
      setState("isAnimated", true);
    }
  }, [state.timeout, setState], []);

  useEffect(() => {
    setState("position", positionSelector);
  }, [setState, positionSelector], [positionSelector]);
  
  return state.isVisible ? (
    <div className={classes.firebaseNotification} onClick={handleOnClick} data-cy={isCypress() ? `firebaseNotification` : null}>
      {
        image ? (
          <AuthenticatedImage className={classes.image} thumbLink={image}/>
        ) : null
      }
      <div className={classes.content}>
        <span>{title}</span>
        <Clamp lines={4}>
          <p>{text}</p>
        </Clamp>
      </div>
      <div className={classes.counter}>
        <span className={`${classes.bar} ${state.isAnimated ? 'active' : null}`}/>
      </div>
    </div>  
  ) : null;
}

export default FirebaseNotification;