import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Clamp from 'react-multiline-clamp';
import config from '../../../constants/config';
import Countdown from 'src/components/Layouts/Countdown';
import DateFormat from '../../../utils/dateFormat';
import hexToRgba from 'hex-to-rgba';
import htmlParse from 'html-react-parser';
import moment from '../../../utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import ReactionsList from 'src/components/Reactions/List';
import ReactionsPopper from 'src/components/Reactions/Popper';
import ReactionsPopperList from 'src/components/Reactions/PopperList';
import Skeleton from '@mui/material/Skeleton';
import SVG from '../../../components/Images/SvgRenderer';
import TimelineGallery from '../../Gallery/TimelineGallery';
import TimelinePoll from '../../Polls/TimelinePoll';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum, isKey, onlyUnique } from 'src/utils/useFunctions';
import { getAppData, setAppData } from 'src/utils/useApp';
import { getUserRole, getUserSetting } from '../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setChildrenModal, setConfirmModal, setPostCreateModal, setTimelineCommentDetailModal } from '../../../store/actions/modals.actions';
import { deleteTimelinePost, updateTimelinePost, updateTimelinePostAttribute } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface Props {
  activityTypeWidthParam?: any,
  activityTypeBackgroundColorParam?: any,
  activityTypeColorParam?: any,
  isPropagated?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  timelineCard: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: (props: Props) => {
      if(props.isPropagated) return theme.shadows[4];
      else return theme.shadows[2];
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timelineHeader: {
    padding: '1rem 0rem 0.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailInformation: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  authorInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  authorInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '60px',
  },
  authorImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      minHeight: '40px',
      width: '40px',
      height: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },
  authorImage: {
    width: '48px',
    height: '48px',
    maxWidth: '48px',
    maxHeight: '48px',
    minWidth: '48px',
    minHeight: '48px',
    borderRadius: '12px',
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
      height: '38px',
      maxWidth: '38px',
      maxHeight: '38px',
      minWidth: '38px',
      minHeight: '38px',
    },
  },
  authorName: {
    color: theme.colors.grey[800],
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  activityType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px 0 0 10px',
    position: 'absolute',
    right: '0',
    transition: 'right 0.25s',
    backgroundColor: (props: Props) => props.activityTypeBackgroundColorParam,
    color: (props: Props) => props.activityTypeColorParam,
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px',
      right: (props: Props) => '-' + (props.activityTypeWidthParam - 35).toString() + 'px',
      '& > span': {
        fontSize: '10px',
        opacity: '0',
        transition: 'opacity 0.25s',
      },
      '&.active': {
        right: '0',
        '& > span': {
          opacity: '1',
        },
      },
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  timezone: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '18px',
      height: '18px',
      marginRight: '4px',
      marginLeft: '-2px',
      marginTop: '-2px',
    },
  },
  timelineContent: {
    padding: '0.5rem 30px 20px 30px',
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
    color: theme.colors.grey[800],
    fontSize: '18px',
    maxWidth: '100%',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
  },
  description: {
    display: 'block',
    color: theme.colors.grey[650],
    fontSize: '14px',
    marginTop: '24px',
    position: "relative",
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    marginBottom: '16px',
    width: '100%',  
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
  },
  spinner: {
    '& svg': {
      width: '116px',
      height: '116px',
      [theme.breakpoints.down('sm')]: {
        width: '68px',
        height: '68px',
      },
      color: "#888FAE",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  },
  timelineBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  childrenListWrapper: {
    position: 'relative',
    padding: '0 0.5rem 0 1rem',
    width: "auto",
    marginLeft: '0',
    display: "inline-flex",
    alignItems: 'center',
    cursor: "pointer",
    order: '1',
  },
  childrenCount: {
    '& > div:first-of-type:not(:only-child)': {
      zIndex: '2',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      right: '0px',
      top: '-10px',
      width: '22px',
      height: '22px',
      backgroundColor: '#D4D8EC',
      color: theme.colors.white,
      fontSize: '10px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
      letterSpacing: '0px',
    },
    '& > div:not(:first-of-type):not(:only-child):not(:last-of-type)': {
      marginLeft: '-16px !important',
    },
  },
  childrenCountFix: {
    '& > div:not(:only-child):not(:last-of-type)': {
      marginLeft: '-16px !important',
    },
  },
  childWrapper: {
    border: '0 !important',
    backgroundColor: 'transparent',
    width: '38px',
    height: '38px',
  },
  childPhoto: {
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px', 
    borderRadius: '100%',
    backgroundColor: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > div': {
      borderWidth: '0px',
    },
  },
  childNames: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer',
    order: '2',
    '& > span': {
      color: '#D4D8EC',
      fontSize: '12px',
      fontWeight: 'normal',
      maxWidth: '100px',
      display: 'flex',
    },
    '& > span + span': {
      marginLeft: '5px',
    },
    '& > span:last-of-type': {
      letterSpacing: '0px', 
    },
    [theme.breakpoints.down('sm')]: {
      order: '3',
      width: '100%',
      padding: '0.5rem 0 0.5rem 1rem',
    },
  },
  viewWrapper: {
    padding: '0 0.5rem 0.5rem 1rem',
    width: "auto",
    marginLeft: 'auto',
    marginRight: '0',
    display: "inline-flex",
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    order: '3',
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },
  childrenSeen: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    marginLeft: "10px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    '& svg': {
      width: '18px',
      height: '18px',
      color: theme.colors.primaryBlue[500],
    },
    '& p': {
      margin: "0",
      marginLeft: "5px",
      marginRight: "2px !important",
      color: theme.colors.primaryBlue[500],
    }
  },
  childrenUnseen: {
    borderRadius: "20px",
    marginLeft: "10px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& svg': {
      width: '18px',
      height: '18px',
      color: theme.colors.grey[550],
    },
    '& p': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.grey[550],
    }
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: '600',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  preloadWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  star: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(0,0,0,0.1)',
    color: theme.colors.yellow[500],
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    borderRadius: '100%',
    opacity: "1",
    cursor: 'default',
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '1',
  }, 
  timelineInformation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 30px 8px 30px',
    width: 'calc(100% - 60px)',
    '&:empty': {
      display: 'none',
    },
  },
  comments: {
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: 'auto',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  timelineButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 30px 20px 30px',
    width: 'calc(100% - 60px)',
    gap: '8px',
    '& > button': {
      position: 'relative',
    },
  },
  timelineButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
  refreshButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
    marginLeft: 'auto',
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

type ItemsType = {
  uniqueID: any;
  className?: any;
  customRef?: any;
  displaySkeleton?: boolean;
  disableGallery?: boolean;
  disablePoll?: boolean;
  disableInformations?: boolean;
  disableInformationReactions?: boolean;
  disableInformationComments?: boolean;
  disableButtons?: boolean;
  disableButtonReaction?: boolean;
  disableButtonComment?: boolean;
  disableButtonSave?: boolean;
  disableButtonRepost?: boolean;
  disableButtonEdit?: boolean;
  disableButtonDelete?: boolean;
  disableContextMenu?: boolean;
};

const TimelineCard: React.FunctionComponent<ItemsType> = ({ uniqueID, className, displaySkeleton = false, disableGallery, disablePoll, disableInformations, disableInformationReactions, disableInformationComments, disableButtons, disableButtonReaction, disableButtonComment, disableButtonSave, disableButtonRepost, disableButtonEdit, disableButtonDelete, disableContextMenu, customRef }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const schoolSettings = userData.schoolSettings;
  const postData = useMemo(() => timelineData.posts.filter((post: any) => post.uniqueID === uniqueID).length === 0 ? [] : timelineData.posts.find((post: any) => post.uniqueID === uniqueID), [timelineData.posts, uniqueID]);
  const postID = postData.postID;
  const schoolTimezone = useMemo(() => postData.length !== 0 ? (getSchoolSettings(postData.schoolID, 'timeZone', schoolSettings) === null ? config.APP_TIMEZONE : getSchoolSettings(postData.schoolID, 'timeZone', schoolSettings)) : config.APP_TIMEZONE, [postData, schoolSettings]);
  const schoolReactions = useMemo(() => postData.length !== 0 ? (getSchoolSettings(postData.schoolID, 'modules', schoolSettings) === null ? false : getSchoolSettings(postData.schoolID, 'modules', schoolSettings).reaction ? (getSchoolSettings(postData.schoolID, 'modules', schoolSettings).reaction.enable && getSchoolSettings(postData.schoolID, 'modules', schoolSettings).reaction.enableFor.posts) : false) : false, [postData, schoolSettings]);
  const schoolComments = useMemo(() => postData.length !== 0 ? (getSchoolSettings(postData.schoolID, 'modules', schoolSettings) === null ? false : getSchoolSettings(postData.schoolID, 'modules', schoolSettings).comment ? (getSchoolSettings(postData.schoolID, 'modules', schoolSettings).comment.enable) : false) : false, [postData, schoolSettings]);
  const authorData = useMemo(() => postData.length !== 0 ? (dataData.users.filter((user: any) => user.userID === postData.authorID).length === 0 ? dataData.users.find((user: any) => user.userID === 0) : dataData.users.find((user: any) => user.userID === postData.authorID)) : dataData.users.find((user: any) => user.userID === 0), [dataData.users, postData]);
  const activityTypeEl: any = useRef({clientWidth: 0});
  const activityTypes = useMemo(() => configurationData.configuration.activityTypes, [configurationData.configuration.activityTypes]);
  const activityType = useMemo(() => postData.length !== 0 ? (activityTypes.filter((activity: any) => activity.activityTypeID === postData.activityTypeID).length === 0 ? null : activityTypes.find((activity: any) => activity.activityTypeID === postData.activityTypeID)) : null, [activityTypes, postData]);
  const activityTypeBackgroundColor = useMemo(() => activityType ? hexToRgba(activityType.color, '0.2') : '', [activityType]);
  const activityTypeColor = useMemo(() => activityType ? activityType.color : '', [activityType]);
  const children = useMemo(() => postData.length !== 0 ? postData.children : [] , [postData]);
  const hasAttributes = useMemo(() => postData.length !== 0 ? (postData.attributes && postData.attributes.length !== 0 ? true : false) : false, [postData]);
  const isFavorite = useMemo(() => postData.length !== 0 ? (postData.attributes && postData.attributes.length !== 0 ? (postData.attributes.find((attribute: any) => attribute.name === "favorite").value) : false) : false, [postData]);

  const [state, setState] = useStates({
    isLoaded: false,
    activityTypeActive: false,
    activityTypeWidth: 0,
    childrenData: [],
    classesData: [],
    isAnyChildrenArchived: false,
    isAnyClassArchivedOrInactive: false,
    reloadingPostDate: null,
  });

  const classes = useStyles({
    activityTypeWidthParam: state.activityTypeWidth,
    activityTypeBackgroundColorParam: activityTypeBackgroundColor,
    activityTypeColorParam: activityTypeColor,
    isPropagated: postData.authorID === 0,
  });
  
  const handleClickRepost = useCallback(() => {
    const savedData = getAppData();
    if(savedData.savedPost && savedData.savedPost !== null && typeof savedData.savedPost === "object") {
      setAppData({savedPost: null});
    }
    if(modalsData.postCreateModal.isOpen) {
      const openRepostModal = () => {
        const settings = {
          isOpen: false,
          uniqueID: null,
          postID: null,
          postType: null,
          title: null,
          repost: false,
        };
        dispatch(setPostCreateModal(settings));
        setTimeout(function() {
          const settings = {
            isOpen: true,
            uniqueID: postData.uniqueID,
            postID: postData.postID,
            repost: true,
          };
          dispatch(setPostCreateModal(settings));
        }, 100);
      };
      const settings = {
        isOpen: true,
        title: t('already_opened_post'),
        content: t('already_opened_post_warning'),
        onAccept: openRepostModal,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else {
      const settings = {
        isOpen: true,
        uniqueID: postData.uniqueID,
        postID: postData.postID,
        repost: true,
      };
      dispatch(setPostCreateModal(settings));
    }
  }, [dispatch, modalsData.postCreateModal.isOpen, postData.postID, postData.uniqueID, t]);

  const handleClickEdit = useCallback(() => {
    const savedData = getAppData();
    if(savedData.savedPost && savedData.savedPost !== null && typeof savedData.savedPost === "object") {
      setAppData({savedPost: null});
    }
    if(modalsData.postCreateModal.isOpen) {
      const openEditModal = () => {
        const settings = {
          isOpen: false,
          uniqueID: null,
          postID: null,
          postType: null,
          title: null,
          repost: false,
        };
        dispatch(setPostCreateModal(settings));
        setTimeout(function() {
          const settings = {
            isOpen: true,
            uniqueID: uniqueID,
            postID: postID,
            repost: false,
          };
          dispatch(setPostCreateModal(settings));
        }, 100);
      };
      const settings = {
        isOpen: true,
        title: t('already_opened_post'),
        content: t('already_opened_post_warning'),
        onAccept: openEditModal,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else {
      const settings = {
        isOpen: true,
        uniqueID: uniqueID,
        postID: postID,
        repost: false,
      };
      dispatch(setPostCreateModal(settings));
    }
  }, [dispatch, modalsData.postCreateModal.isOpen, postID, uniqueID, t]);

  const handleClickDelete = useCallback(() => {
    const deletePost = () => {
      timelineService && timelineService.deletePost(postID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("post_deleted"), "success");
            dispatch(deleteTimelinePost(postID));
          } else {
            createNotification(t("post_not_deleted"), "error");
          }
        } else {
          createNotification(t("post_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("post_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('post_delete'),
      content: t('post_delete_confirm'),
      onAccept: deletePost,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  }, [dispatch, postID, t, timelineService])
  
  useEffect(() => {
    setState("isLoaded", false);
    if(children.length > 0) {
      const newChildren = children.map((item: any) => {
        if(dataData.children.filter((theChild: any) => theChild.childID === item.childID).length === 1) {
          const childData = dataData.children.find((theChild: any) => theChild.childID === item.childID);
          return {...childData, classID: childData.classID.concat(item.classID), postSeen: item.postSeen ? item.postSeen : false, pollAnswers: item.pollAnswers ? item.pollAnswers : []};
        } else {
          return null;
        }
      }).filter((item: any) => item !== null);
      newChildren.sort((a: any, b: any) => { if(a.displayName === b.displayName) return a.childID - b.childID; return a.displayName > b.displayName ? 1 : -1; });
      const allClasses = children.map((item: any) => {
        return item.classID.map((theClass: any) => {
          if(dataData.classes.filter((findClass: any) => findClass.classID === theClass).length === 1) {
            return theClass;
          } else {
            return null;
          }
        }).filter((item: any) => item !== null);
      }).flat().filter(onlyUnique);
      const newClasses = allClasses.map((item: any) => {
        if(dataData.classes.filter((findClass: any) => findClass.classID === item).length === 1) {
          const classData = dataData.classes.find((theClass: any) => theClass.classID === item);
          return classData;
        } else {
          return null;
        }
      }).filter((item: any) => item !== null);
      setState("isAnyChildrenArchived", newChildren.filter((theChild: any) => theChild.isArchived).length === 0 ? false : true);
      setState("isAnyClassArchivedOrInactive", newClasses.filter((theClass: any) => theClass.isArchived || !theClass.active).length === 0 ? false : true);
      setState("childrenData", newChildren);
      setState("classesData", newClasses);
    }      
    setState("isLoaded", true);
  }, [uniqueID, dataData.children, dataData.classes, children, setState], [uniqueID]);
  
  useEffect(() => {
    if(state.isLoaded && activityTypeEl && activityTypeEl.current) {
      const activityTypeCurrentWidth: any = activityTypeEl.current.clientWidth;
      setState("activityTypeWidth", activityTypeCurrentWidth);
      setState("activityTypeActive", false);
    }  
  }, [state.isLoaded, browserData.width, languageData.language, setState], [state.isLoaded, browserData.width, languageData.language]);
  
  const getSeenStatus = useMemo(() => (status: any) => {
    let count = 0;
    let uniqueChildren: any = [];
    children.forEach((child: any) => {
      if(uniqueChildren.indexOf(child.childID) === -1) {
        if(child.postSeen === status) {
          uniqueChildren.push(child.childID);
          count++;
        }
      }  
    });
    return count;
  }, [children]);

  const handleActivityActive = useCallback(() => {
    setState("activityTypeActive", !state.activityTypeActive);
  }, [setState, state.activityTypeActive]);
  
  const handleOpenChildrenModal = useCallback(() => {
    const handleCloseChildrenModal = () => {
      const settings = {
        isOpen: false,
        modalTitle: null,
        modalOnClose: null,
        defaultClasses: null,
        defaultClassesData: null,
        defaultChildren: null,
        defaultChildrenData: null,
        mode: null,
        isAllowArchived: null,
        isShowChildrenViews: null,
        isShowChildrenArchived: null,
      };
      dispatch(setChildrenModal(settings));
    };
    const settings = {
      isOpen: true,
      modalTitle: getUserRole(userData.userObject.roleType) === "parent" ? 'list_of_children' : (config.HIDDEN_ACTIVITY_TYPES.indexOf(postData.activityTypeID) === -1 ? 'reading_post' : 'list_of_children'),
      modalOnClose: handleCloseChildrenModal,
      defaultClasses: state.classesData.map((item: any) => { return { classID: item.classID, schoolID: item.schoolID }; }),
      defaultClassesData: state.classesData,
      defaultChildren: state.childrenData.map((item: any) => { return { childID: item.childID, classID: item.classID, schoolID: item.schoolID }; }),
      defaultChildrenData: state.childrenData,
      mode: config.HIDDEN_ACTIVITY_TYPES.indexOf(postData.activityTypeID) === -1 ? "view" : "detail",
      isAllowArchived: state.isAnyChildrenArchived || state.isAnyClassArchivedOrInactive,
      isShowChildrenViews: config.HIDDEN_ACTIVITY_TYPES.indexOf(postData.activityTypeID) === -1 ? true : false,
      isShowChildrenArchived: getUserRole(userData.userObject.roleType) === "parent",
    };
    dispatch(setChildrenModal(settings));  
  }, [dispatch, postData.activityTypeID, state.childrenData, state.classesData, state.isAnyChildrenArchived, state.isAnyClassArchivedOrInactive, userData.userObject.roleType]);

  const handleCommentDetailModal = () => {
    const settings = {
      isOpen: true,
      uniqueID: uniqueID,
      addComment: false,
    };
    dispatch(setTimelineCommentDetailModal(settings));   
  };

  const handleCommentAddModal = () => {
    const settings = {
      isOpen: true,
      uniqueID: uniqueID,
      addComment: true,
    };
    dispatch(setTimelineCommentDetailModal(settings));   
  };

  const handleReloadPost = (count: any) => {
    timelineService && timelineService.listPosts({postID: postID}).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.posts) {
            if(result.data.posts.length === 1) {
              const newPostData = result.data.posts[0];
              dispatch(updateTimelinePost(newPostData));
              if(newPostData.authorID === userData.userObject.userID && !newPostData.isEditable) {
                const newCount = count + 1;
                if(count < 5) {
                  setState("reloadingPostDate", Date.now() + 10000);
                  setState("reloadingPostCount", newCount);
                  setTimeout(() => {
                    setState("reloadingPostDate", 'process');
                    setTimeout(() => {
                      handleReloadPost(newCount);
                    }, 500);
                  }, 9900);
                } else {
                  setState("reloadingPostCount", 0);
                  setState("reloadingPostDate", null);
                }
              } else {
                setState("reloadingPostDate", null);
              }
            } else {
              setState("reloadingPostDate", null);
              createNotification(t("something_went_wrong"), "error");
            }
          } else {
            setState("reloadingPostDate", null);
            createNotification(t("something_went_wrong"), "error");
          }
        } else {
          setState("reloadingPostDate", null);
          createNotification(t("something_went_wrong"), "error");
        }
      } else {
        setState("reloadingPostDate", null);
        createNotification(t("something_went_wrong"), "error");
      }
    }).catch(() => {
      setState("reloadingPostDate", null);
      createNotification(t("something_went_wrong"), "error");
    });
  };

  const handleClickReloadPost = () => {
    if(!state.reloadingPostDate) {
      setState("reloadingPostDate", 'process');
      setTimeout(() => {
        handleReloadPost(0);
      }, 500);
    }
  };
  
  const updateAttribute = (attr: any, value: any) => {
    const data = {
      postID: postID,
      attribute: {
        name: attr,
        value: value,
      },
    };
    dispatch(updateTimelinePostAttribute(data));
  };

  const handleFavorite = () => {
    const newValue = !isFavorite;
    updateAttribute("favorite", newValue);
    const payload = {
      attributes: [
        {
          name: 'favorite',
          value: newValue,
        },
      ],
    };
    timelineService && timelineService.setAttribute(postID, payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute("favorite", !newValue);
    });
  };

  return (state.isLoaded && !displaySkeleton) ? (
    <>
      <ContextMenuTrigger id={`timelineCard_${uniqueID}`} className={`${classes.timelineCard} ${className ? className : ''}`} renderTag={"div"} dataCy={`timelineCard${uniqueID}`} customRef={customRef} disable={disableContextMenu || isMobile || !getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_context_menu"])}>
        <div className={classes.timelineHeader}>
          <div className={classes.detailInformation}>
          {
            authorData ? (
              <div className={classes.authorInformation}>
                <div className={classes.authorImageContainer}>
                  <AuthenticatedImage className={classes.authorImage} thumbLink={authorData.photo.thumbLink} fullsizeLink={authorData.photo.fullsizeLink} dataCy="authorPhoto" isClickable/>
                </div>
                <div className={classes.authorInfo}>
                  <span className={classes.authorName} data-cy={isCypress() ? "timelineCardAuthorName" : null}>
                    {authorData.displayName ? authorData.displayName : config.APP_NAME}
                  </span>
                  <span data-clarity-unmask="true" className={classes.date} data-cy={isCypress() ? "timelineCardDateCreated" : null}>
                    <SVG src="clock"/>
                    {DateFormat(postData.created,"timeline", languageData, t, schoolTimezone)}
                  </span>
                  {
                    moment(postData.created).tz(schoolTimezone).format("YYYYMMDDHHmmss") !== moment(postData.created).tz(config.APP_TIMEZONE).format("YYYYMMDDHHmmss") ? (
                      <span className={classes.timezone}>
                        <SVG src="warning"/>
                        {t('timezone_different')}
                      </span>
                    ) : null
                  }
                </div>
              </div>
            ): null
          }
          {
            activityType ? (
              <div className={`${classes.activityType} ${state.activityTypeActive ? 'active' : null}`} onClick={handleActivityActive} ref={activityTypeEl} data-cy={isCypress() ? "timelineCardActivityType" : null}>
                {
                  activityType.image ? (
                    <img src={activityType.image} alt={activityType.name}/>
                  ) : null
                }
                <span>{activityType.name}</span>
              </div>
            ) : null
          }      
          </div>
        </div>
        <div className={classes.timelineBox}>
        {
          children.length > 0 ? (
            <div className={classes.childrenListWrapper} onClick={handleOpenChildrenModal} data-cy={isCypress() ? "timelineCardChildrenList" : null}>
              <AvatarGroup total={state.childrenData.length} className={state.childrenData.length <= 3 ? classes.childrenCountFix : classes.childrenCount}>
              {
                state.childrenData.slice(0, 3).map((child: any, key: any) => (
                  <Avatar className={classes.childWrapper} key={`k_${key}`}>
                    <AuthenticatedImage className={classes.childPhoto} thumbLink={child.photo.thumbLink} dataCy="childPhoto"/>
                  </Avatar>
                ))
              }
              </AvatarGroup>
            </div>
          ) : null
        }
        {
          children.length > 0 ? (
          <div className={classes.childNames} onClick={handleOpenChildrenModal} data-cy={isCypress() ? "timelineCardChildrenListNames" : null}>
            {
              state.childrenData.slice(0, 3).map((child: any, key: any) => (
                <span key={`k_${key}`}>
                <Clamp lines={1}>
                  <span>{child.displayName}</span>
                </Clamp>
                {key < state.childrenData.length - 1 && key !== 2 ? (',') : null}
                </span> 
              ))
            }
            {
              state.childrenData.length > 3 ? (<span data-clarity-unmask="true">+{state.childrenData.length - 3}</span>) : null
            }
          </div>
          ) : null
        }
        {
          (children.length > 0 && getUserRole(userData.userObject.roleType) !== "parent" && config.HIDDEN_ACTIVITY_TYPES.indexOf(postData.activityTypeID) === -1) ? (
            <div className={classes.viewWrapper} onClick={handleOpenChildrenModal} data-cy={isCypress() ? "timelineCardChildrenViews" : null}>
              <span className={classes.childrenSeen}>
                <SVG src="eye"/>
                <p data-clarity-unmask="true">{getSeenStatus(true)}</p>
              </span>
              <span className={classes.childrenUnseen}>
                <SVG src="eye-slash"/>
                <p data-clarity-unmask="true">{getSeenStatus(false)}</p>
              </span>
            </div>        
          ) : null
        }
        </div>
        <div className={classes.timelineContent}>
          <span className={classes.title} data-cy={isCypress() ? "timelineCardTitle" : null}>
            {postData.title}
          </span>
          {
            (postData.description && postData.description !== "") ? (
              <span className={classes.description} data-cy={isCypress() ? "timelineCardDescription" : null}>
                <Clamp
                  lines={4}
                  maxLines={100}
                  withToggle
                  showMoreElement={({ toggle }: any) => (
                    <div className={classes.showToggleWrapper}>
                      <span className={classes.showToggle} onClick={toggle}>
                        {t('show_more')}
                      </span>
                    </div>
                  )}
                  showLessElement={({ toggle }: any) => (
                    <div className={classes.showToggleWrapper}>
                      <span className={classes.showToggle} onClick={toggle}>
                        {t('show_less')}
                      </span>
                    </div>
                  )}
                >
                  {htmlParse(postData.description)}
                </Clamp>
              </span>
            ) : null
          } 
        </div>
        {
          (!disableGallery && (postData.photos.length !== 0 || postData.files.length !== 0 || postData.videos.length !== 0)) ? (
            <TimelineGallery uniqueID={uniqueID}/>
          ) : null
        }
        {
          (!disablePoll && postData.postType === 2) ? (
            <TimelinePoll uniqueID={uniqueID}/>
          ) : null
        } 
        {
          !disableInformations ? (
            <div className={classes.timelineInformation}>
              {
                (!disableInformationReactions && ((postData.reactions && postData.reactions.enable && postData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (postData.reactions && postData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0))) ? (
                  <ReactionsList data={postData} ID={uniqueID} type='post'/>
                ) : null
              } 
              {
                (!disableInformationComments && ((postData.comments && postData.comments.enable && postData.comments.total > 0) || (postData.comments && postData.comments.total > 0))) ? (
                  <span data-clarity-unmask="true" className={classes.comments} onClick={handleCommentDetailModal}>
                    {postData.comments.total} {postData.comments.total === 1 ? (t('comment')).toLowerCase() : (postData.comments.total === 2 || postData.comments.total === 3 || postData.comments.total === 4) ? (t('comments')).toLowerCase() : (t('comments_more')).toLowerCase()}
                  </span>
                ) : null
              } 
            </div>
          ) : null
        }
        {
          !disableButtons ? (
            <div className={classes.timelineButtons}>
              {
                (postData.reactions && postData.reactions.enable && schoolReactions && !disableButtonReaction) ? (
                  <ReactionsPopper data={postData} ID={uniqueID} type='post' classNameReactionButton={classes.timelineButton}/>
                ) : null
              }
              {
                (postData.comments && postData.comments.enable && schoolComments && !disableButtonComment) ? (
                  <NormalButton className={classes.timelineButton} buttonType="secondary" startIcon={<SVG src="comment"/>} onClick={handleCommentAddModal}>
                    {t('comments_action')}
                  </NormalButton>
                ) : null
              }
              {
                (hasAttributes && !disableButtonSave) ? (
                  <NormalButton className={classes.timelineButton} buttonType={isFavorite ? "saved" : "secondary"} startIcon={<SVG src={isFavorite ? "bookmark-heart" : "bookmark"}/>} onClick={handleFavorite}>
                    {isFavorite ? t('saved') : t('save')}
                  </NormalButton>
                ) : null
              }
              {
                (getUserRole(userData.userObject.roleType) !== "parent" && postData.isEditable) ? (
                  <>
                    {
                      (!disableButtonRepost && getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_repost_button"])) ? (
                        <NormalButton className={classes.timelineButton} buttonType="secondary" startIcon={<SVG src="repost"/>} onClick={handleClickRepost}>
                          {t('repost')}
                        </NormalButton>
                      ) : null
                    }
                    {
                      !disableButtonEdit ? (
                        <NormalButton className={classes.timelineButton} buttonType="secondary" startIcon={<SVG src="edit"/>} onClick={handleClickEdit}>
                          {t('edit')}
                        </NormalButton>
                      ) : null
                    }
                    {
                      (!disableButtonDelete && getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_delete_button"])) ? (
                        <NormalButton className={classes.timelineButton} buttonType="secondary" startIcon={<SVG src="trash-outlined"/>} onClick={handleClickDelete}>
                          {t('delete')}
                        </NormalButton>
                      ) : null
                    }
                  </>
                ) : null
              }
              {
                (postData.authorID === userData.userObject.userID && !postData.isEditable && moment().diff(moment(postData.created), "minute") <= 10) ? (
                  <NormalButton className={classes.refreshButton} buttonType="primary" startIcon={<SVG src="reload"/>} onClick={handleClickReloadPost} disabled={state.reloadingPostDate !== null}>
                    {
                      state.reloadingPostDate === null ? t('refresh_status') : state.reloadingPostDate === "process" ? t('refresh_status_process') : (
                        <Countdown date={state.reloadingPostDate} text='refresh_status_info'/>
                      )
                    }
                  </NormalButton>
                ) : null
              }
            </div>
          ) : null
        }
      </ContextMenuTrigger>
      <ContextMenu id={`timelineCard_${uniqueID}`} preventHideOnScroll={false} hideOnLeave={true}>
        {
          (postData.reactions && postData.reactions.enable && schoolReactions && !disableButtonReaction) ? (
            <ContextMenuItem disableHover={true}>
              <ReactionsPopperList data={postData} ID={uniqueID} type='post'/>
            </ContextMenuItem>
          ) : null
        }
        {
          (postData.comments && postData.comments.enable && schoolComments && !disableButtonComment) ? (
            <>
             {
              postData.comments.total === 0 ? (
                <ContextMenuItem onClick={handleCommentAddModal}>
                  <SVG src="comment"/>
                  {t('comment_add')}
                </ContextMenuItem>
              ) : (
                <ContextMenuItem onClick={handleCommentDetailModal}>
                  <SVG src="comment"/>
                  {t('view_comments')} <small>({postData.comments.total})</small>
                </ContextMenuItem>
              )
             }
            </>
          ) : null
        }
        <ContextMenuItem onClick={handleOpenChildrenModal}>
          <SVG src="people"/>
          {t('view_children')} <small>({postData.children.length})</small>
        </ContextMenuItem>
        {
          (hasAttributes && !disableButtonSave) ? (
            <ContextMenuItem onClick={handleFavorite}>
              <SVG src={isFavorite ? "bookmark-heart" : "bookmark"}/>
              {isFavorite ? t('saved') : t('save')}
            </ContextMenuItem>
          ) : null
        }
        {
          (getUserRole(userData.userObject.roleType) !== "parent" && postData.isEditable) ? (
            <>
              {
                !disableButtonRepost ? (
                  <ContextMenuItem onClick={handleClickRepost}>
                    <SVG src="plus"/>
                    {t('repost')}
                  </ContextMenuItem>
                ) : null
              }
              {
                !disableButtonEdit ? (
                  <ContextMenuItem onClick={handleClickEdit}>
                    <SVG src="edit"/>
                    {t('edit')}
                  </ContextMenuItem>
                ) : null
              }
            </>
          ) : null
        }
      </ContextMenu>
    </>
  ) : (
    <div className={`${classes.timelineCard} ${className ? className : ''}`}>
      <div className={classes.timelineHeader}>
        <div className={classes.authorInformation}>
          <div className={classes.authorImageContainer}>
              <div className={classes.preloadWrapper}>
                <Skeleton variant="circular" animation="wave" width={50} height={50}/>
              </div>
          </div>
          <div className={classes.authorInfo}>
            <div className={classes.authorName}>
              <div className={classes.preloadWrapper}>
                <Skeleton variant="text" animation="wave" width={100} height={14}/>
              </div>
            </div>
            <div className={classes.date}>
              <div className={classes.preloadWrapper}>
                <Skeleton variant="text" animation="wave" width={100} height={10}/>
              </div>
            </div>
          </div>
        </div>      
      </div>
      <div className={classes.timelineBox}>
        <div className={classes.childrenListWrapper} style={{cursor: 'auto'}}>
          <div className={classes.preloadWrapper}>
            <AvatarGroup>
            {
              (displaySkeleton ? [1] : children.slice(0, 3)).map((_: any, key: any) => (
                <Avatar className={classes.childWrapper} key={`k_${key}`}>
                  <Skeleton key={`k_${key}`} variant="circular" animation="wave" width={38} height={38}/>
                </Avatar>
              ))
            }
            </AvatarGroup>
          </div>            
        </div>
      </div>
      <div className={classes.timelineContent}>
        <div className={classes.title}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="text" animation="wave" width={150} height={20}/>
          </div>
        </div>
         <div className={classes.description}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="rectangular" animation="wave" width={350} height={50}/>
          </div>
        </div>      
      </div>
      <div className={classes.timelineButtons}>
        <Skeleton variant="rectangular" animation="wave" width={90} height={30}/>
      </div>
    </div>
  );
}

export default TimelineCard;