import slice from '../slices/medias.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setAttachments = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAttachments(data));
  }
};

export const setImages = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setImages(data));
  }
};

export const setSvgs = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSvgs(data));
  }
};

export const setVideos = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setVideos(data));
  }
};

export const resetAttachments = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetAttachments());
  }
};

export const resetImages = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetImages());
  }
};

export const resetSvgs = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetSvgs());
  }
};

export const resetVideos = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.resetVideos());
  }
};