import * as ParentOrderService from '../../../../services/parent/order.service';
import { getUserRole } from 'src/utils/useUser';
import { setOrder } from 'src/store/actions/user.actions';

const loadPremium = async (dispatch: any, userData: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") {
    try {
      const result: any = await ParentOrderService.getOrder();
      if(result && result.data && result.data.order) {
        const orderData = result.data.order;
        dispatch(setOrder(orderData));
        return orderData;
      } else {
        return false;
      }
    } catch (e: any) {
      if(e.response && e.response.data && e.response.data.message === "Order not found.") {
        const orderData = {
          path: "",
          status: "not-issued",
          statusPage: "",
        };
        dispatch(setOrder(orderData));
        return orderData;
      } else {
        return false;
      }
    }
  } else {
    const orderData = {
      path: "",
      status: "not-applicable",
      statusPage: "",
    };
    return orderData;
  }
};

export default loadPremium;