import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  viewMode: any;
  selectedChildren: any;
};

const initialState: ArrayModel = {
  viewMode: 'cards',
  selectedChildren: [],
};

const slice = createSlice({
  name: 'children',
  initialState: initialState,
  reducers: {
    setChildrenViewMode(state, action: PayloadAction<Model>){
      state.viewMode = action.payload;
    },
    setChildrenSelectedChildren(state, action: PayloadAction<Model>){
      state.selectedChildren = action.payload;
    },
  }
});

export default slice;