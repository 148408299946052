import BlockButton from 'src/components/Buttons/BlockButton';
import BootstrapIcon from 'src/components/Icons/BootstrapIcon';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { bootstrapIcons } from 'src/constants/icons';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setStockCategory, setStockItems, setStockItemsCount, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  categorySelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  buttons: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '0 24px',
    maxWidth: 'calc(100% - 48px)',
  },
}));

const CategorySelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;

  const categories = stockData.categories;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const [isLoading, setIsLoading] = useState(false);

  const getItems = useCallback((categoryID: any) => {
    setIsLoading(true);
    const settings = {
      schoolID: schoolID,
      categoryID: categoryID,
      limit: 15,
      page: 1, 
    };
    stockService && stockService.listItems(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockCategory(categoryID));
          dispatch(setStockItems(result.data.items));
          dispatch(setStockItemsCount(result.data.countItems));
          dispatch(setStockViewMode("items"));
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
          setIsLoading(false);
        }
      } else {
        createNotification(t("stock_items_not_loaded"), "error");
        setIsLoading(false);
      }
    }).catch(() => {
      createNotification(t("stock_items_not_loaded"), "error");
      setIsLoading(false);
    });
  }, [t, stockService, schoolID, dispatch]);

  const handleBack = () => {
    dispatch(setStockViewMode("stockSettings"));
  };

  return (
    <div className={classes.categorySelectWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.buttons}>
        {
          categories.map((category: any, key: any) => (
            <BlockButton icon={(category.icon && bootstrapIcons.indexOf(category.icon) !== -1) ? (<BootstrapIcon iconName={category.icon}/>) : null} onClick={() => getItems(category.categoryID)}  key={`k_${key}`} disabled={isLoading}>
              {category.name}
            </BlockButton>
          ))
        }
        <BlockButton icon={<SVG src="arrow-left"/>} onClick={handleBack} disabled={isLoading}>
          {t('back')}
        </BlockButton>
      </div>
    </div>
  );
};

export default CategorySelect;