import * as UserService from '../../../../services/user.service';
import moment from 'src/utils/moment';
import { getUserRole } from 'src/utils/useUser';
import { setDataClassesID, setDataSchoolsID } from 'src/store/actions/data.actions';
import { setMediaLimits, setMembership, setSchoolSettings, setUserObject, setUserAccess, setUserAccessSchools, setThreadLimits } from 'src/store/actions/user.actions';
import { getMaxLimits, handlePositiveSum } from 'src/utils/useFunctions';
import { setCommunicationNotificationsCount } from 'src/store/actions/communication.actions';

const loadUser = async (dispatch: any) => {
  try {
    const result: any = await UserService.getUserData();
    if(result && result.data) {
      const userData = result.data.user;
      if(userData) {
        const schoolSettings = userData.schoolSetting;
        const userAccess = {
          bakalariUrl: schoolSettings.filter((item: any) => item.bakalariLoginUrl && item.modules.bakalariLoginUrl !== "").length > 0,
          calendar: schoolSettings.filter((item: any) => item.modules.calendar && item.modules.calendar.enable).length > 0,
          club: getUserRole(userData.roleType) === "parent" ? schoolSettings.filter((item: any) => item.modules.club && item.modules.club.enable && item.modules.club.visibleForParent).length > 0 : schoolSettings.filter((item: any) => item.modules.club && item.modules.club.enable).length > 0,
          communication: schoolSettings.filter((item: any) => item.modules.communication && item.modules.communication.enable).length > 0,
          mealmenuUrl: schoolSettings.filter((item: any) => item.mealmenuUrl && item.modules.mealmenuUrl !== "").length > 0,
          mealMenu: schoolSettings.filter((item: any) => item.modules.mealMenu && item.modules.mealMenu.enable).length > 0,
          shop: getUserRole(userData.roleType) === "parent" ? schoolSettings.filter((item: any) => item.modules.stock && item.modules.stock.enable && (item.modules.stock.catalog || item.modules.stock.orders)).length > 0 : false,
          stock: getUserRole(userData.roleType) === "parent" ? false : schoolSettings.filter((item: any) => item.modules.stock && item.modules.stock.enable).length > 0,
          subscription: schoolSettings.filter((item: any) => item.subscription === "PREMIUM" || item.subscription === "PRO").length > 0,
          substitutions: schoolSettings.filter((item: any) => item.modules.substitutions && item.modules.substitutions.enable).length > 0,
          timetable: (getUserRole(userData.roleType) === "parent" || getUserRole(userData.roleType) === "teacher") ? schoolSettings.filter((item: any) => item.modules.timetable && item.modules.timetable.enable && item.modules.timetable.slots).length > 0 : schoolSettings.filter((item: any) => item.modules.timetable && item.modules.timetable.enable).length > 0,
        };
        const userAccessSchools = {
          bakalariUrl: schoolSettings.filter((item: any) => item.bakalariLoginUrl && item.modules.bakalariLoginUrl !== "").map((item: any) => { return item.schoolID; }),
          calendar: schoolSettings.filter((item: any) => item.modules.calendar && item.modules.calendar.enable).map((item: any) => { return item.schoolID; }),
          club: getUserRole(userData.roleType) === "parent" ? schoolSettings.filter((item: any) => item.modules.club && item.modules.club.enable && item.modules.club.visibleForParent).map((item: any) => { return item.schoolID; }) : schoolSettings.filter((item: any) => item.modules.club && item.modules.club.enable).map((item: any) => { return item.schoolID; }),
          communication: schoolSettings.filter((item: any) => item.modules.communication && item.modules.communication.enable).map((item: any) => { return item.schoolID; }),
          mealmenuUrl: schoolSettings.filter((item: any) => item.mealmenuUrl && item.modules.mealmenuUrl !== "").map((item: any) => { return item.schoolID; }),
          mealMenu: schoolSettings.filter((item: any) => item.modules.mealMenu && item.modules.mealMenu.enable).map((item: any) => { return item.schoolID; }),
          shop: getUserRole(userData.roleType) === "parent" ? schoolSettings.filter((item: any) => item.modules.stock && item.modules.stock.enable && (item.modules.stock.catalog || item.modules.stock.orders)).map((item: any) => { return item.schoolID; }) : [],
          stock: getUserRole(userData.roleType) === "parent" ? [] : schoolSettings.filter((item: any) => item.modules.stock && item.modules.stock.enable).map((item: any) => { return item.schoolID; }),
          subscription: schoolSettings.filter((item: any) => item.subscription === "PREMIUM" || item.subscription === "PRO").map((item: any) => { return item.schoolID; }),
          substitutions: schoolSettings.filter((item: any) => item.modules.substitutions && item.modules.substitutions.enable).map((item: any) => { return item.schoolID; }),
          timetable: (getUserRole(userData.roleType) === "parent" || getUserRole(userData.roleType) === "teacher") ? schoolSettings.filter((item: any) => item.modules.timetable && item.modules.timetable.enable && item.modules.timetable.slots).map((item: any) => { return item.schoolID; }) : schoolSettings.filter((item: any) => item.modules.timetable && item.modules.timetable.enable).map((item: any) => { return item.schoolID; }),
        };
        const time = moment();
        const mediaLimits = getMaxLimits(schoolSettings, "mediaLimit");
        const threadLimits = getMaxLimits(schoolSettings, "threadLimit");
        const communicationNotificationsCount = schoolSettings.map((item: any) => {
          if(item && item.modules && item.modules.communication && item.modules.communication.unreadThreads) {
            return item.modules.communication.unreadThreads;
          } else {
            return 0;
          }
        }).reduce(handlePositiveSum, 0);
        dispatch(setMediaLimits(mediaLimits));
        dispatch(setThreadLimits(threadLimits));
        dispatch(setCommunicationNotificationsCount(communicationNotificationsCount));
        dispatch(setMembership(userData.membership));
        dispatch(setSchoolSettings(schoolSettings));
        dispatch(setUserObject(userData));
        dispatch(setUserAccess(userAccess));
        dispatch(setUserAccessSchools(userAccessSchools));
        dispatch(setDataClassesID(userData.classID));
        dispatch(setDataSchoolsID(userData.schoolID));
        return { time: time, userData: userData, classesID: userData.classID, schoolsID: userData.schoolID, userAccess: userAccess, membership: userData.membership };
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch {
    return false;
  };   
};

export default loadUser;