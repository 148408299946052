import * as DirectorDataService from '../../../../services/director/data.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import { createUserObject, getUserRole } from 'src/utils/useUser';
import { setDataUsers, setDataUsersID } from 'src/store/actions/data.actions';

const getService = (userData: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") return ParentDataService;
  else if(getUserRole(userData.userObject.roleType) === "teacher") return TeacherDataService;
  else if(getUserRole(userData.userObject.roleType) === "director") return DirectorDataService;
  else return false;
};

const loadUsers = async (dispatch: any, userData: any) => {
  if(getService(userData)) {
    const service: any = getService(userData);
    try {
      const result: any = await service.listUsers();
      if(result && result.data && result.data.users && result.data.users.length !== 0) {
        const response = result.data.users;
        const merged = response.filter((item: any) => item.userID === userData.userObject.userID).length === 0 ? [...response, createUserObject(userData.userObject)] : response;
        const users = merged.map((item: any) => { return { ...item, schoolsID: Array.isArray(item.schoolID) ? item.schoolID : [] }; });
        users.sort((a: any, b: any) => {
          const nameA = a.displayName ? a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.displayName;
          const nameB = b.displayName ? b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.displayName;
          const firstnameA = a.firstname? a.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.firstname;
          const lastnameA = a.surname? a.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.surname;
          const firstnameB = b.firstname? b.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.firstname;
          const lastnameB = b.surname? b.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.surname;
          if(nameA === nameB) return a.userID - b.userID;
          else {
            if(lastnameA === lastnameB) {
              return firstnameA > firstnameB ? 1 : -1;
            } else {
              return lastnameA > lastnameB ? 1 : -1;
            }
          }
        });
        const usersID = users.map((item: any) => { return item.userID; });
        dispatch(setDataUsers(users));
        dispatch(setDataUsersID(usersID));
        return {users: users, usersID: usersID};              
      } else {
        return false;
      }
    } catch {
      return false;
    };
  } 
};

export default loadUsers;