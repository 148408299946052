import config from 'src/constants/config';
import DateFormat from '../../../../../utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../../utils/moment';
import React, { useCallback, useMemo } from 'react';
import RenderItem from '../RenderItem';
import SVG from '../../../../../components/Images/SvgRenderer';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { removeDuplicates } from 'src/utils/useFunctions';
import { setGalleryMedias, setGallerySelected, setGalleryZipSelected, setGalleryZipState } from '../../../../../store/actions/gallery.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-component';

interface Props {
  isFiltersActive: boolean;
  membershipActive: any;
};

const useStyles = createUseStyles((theme: any) => ({
  dateGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    width: 'calc(100% - 48px)',
    pointerEvents: (props: Props) => {
      if(!props.membershipActive) return 'none';
      else return '';
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'calc(44px - 8px)',
    position: (props: Props) => {
      if(!props.isFiltersActive) return 'sticky';
      else return '';
    },
    top: (props: Props) => {
      if(!props.isFiltersActive) return '0px';
      else return '';
    },
    zIndex: '1',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.primaryBlue[500]
  },
  count: {
    fontSize: '12px',
  },
  section: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 3px 20px',
    padding: '8px 8px',
    '&:empty': {
      display: 'none',
    },
  },
  select: {
    padding: '0',
    height: 'fit-content',
    backgroundColor: theme.colors.white,
    '& > svg': {
      width: '24px',
      height: '24px',
      color: '#666666',
    },
  },
  button: {
    backgroundColor: '#EEEEEE',
    width: '36px',
    height: '36px',
  },
  favorite: {
    color: theme.colors.yellow[500],
  },
  masonryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  masonry: {
    width: '100%',
    maxWidth: '100%',
  },
}));

type RenderGroupType = {
  date: any;
};

const RenderGroup: React.FunctionComponent<RenderGroupType> = ({date}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtersData = useAppSelector((state: any) => state.filters);
  const galleryData = useAppSelector((state: any) => state.gallery);
  const languageData = useAppSelector((state: any) => state.language);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  const allMedias = useMemo(() => galleryData.medias, [galleryData.medias]);
  const medias = useMemo(() => galleryData.medias.filter((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).length === 0 ? [] : ((galleryData.medias.find((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).medias === null || galleryData.medias.find((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).medias === undefined) ? [] : galleryData.medias.find((item: any) => moment(item.date).format("YYYY-MM") === moment(date).format("YYYY-MM")).medias), [galleryData.medias, date]);
  const selected = useMemo(() => galleryData.selected, [galleryData.selected]);
  const allMediasID = useMemo(() => medias.map((media: any) => { return media.mediaID; }), [medias]);

  const zipState = galleryData.zipState;
 
  const classes = useStyles({
    isFiltersActive: layoutData.isFiltersVisible,
    membershipActive: userData.membership.active,
  });

  const getGroupSelected = useMemo(() => {
    return selected.filter((item: any ) => allMediasID.indexOf(item) !== -1);
  }, [allMediasID, selected]);
  
  const canDownload = getGroupSelected.length > 1 && getGroupSelected.length <= config.GALLERY_DOWNLOADS_LIMIT;

  const updateAttribute = useCallback((mediasID: any, attr: any, value: any) => {
    let newItems = allMedias;
    mediasID.forEach((mediaID: any) => {
      newItems = newItems.map((dates: any) => {
        if(moment(dates.date).format("YYYY-MM") === moment(date).format("YYYY-MM")) {
          return {...dates, medias: dates.medias.map((media: any) => {
            if(media.mediaID === mediaID) {
              return {...media, attributes: media.attributes.map((attribute: any) => {
                if(attribute.name === attr) {
                  return {...attribute, value: value};
                } else {
                  return attribute;
                }
              })};
            } else {
              return media;
            }
          })};
        } else {
          return dates;
        }
      });
    });
    dispatch(setGalleryMedias(newItems));
  }, [allMedias, date, dispatch]);

  const isSelectedAll = useMemo(() => {
    let anyMissing = false;
    allMediasID.forEach((mediaID: any) => {
      if(!anyMissing) {
        if(selected.indexOf(mediaID) === -1) {
          anyMissing = true;
        }
      }
    });
    return !anyMissing;
  }, [allMediasID, selected]);

  const isSelectedAny = useMemo(() => {
    let anySelected = false;
    allMediasID.forEach((mediaID: any) => {
      if(!anySelected) {
        if(selected.indexOf(mediaID) !== -1) {
          anySelected = true;
        }
      }
    });
    return anySelected;
  }, [allMediasID, selected]);

  const isFavoritedAnySelected = useMemo(() => {
    let anyFavoritedSelected = false;
    allMediasID.forEach((mediaID: any) => {
      if(!anyFavoritedSelected) {
        if(selected.indexOf(mediaID) !== -1) {
          if(medias.filter((item: any) => item.mediaID === mediaID && item.attributes.find((attribute: any) => attribute.name === "favorite").value === true).length !== 0) {
            anyFavoritedSelected = true;
          }
        }
      }
    });
    return anyFavoritedSelected;
  }, [allMediasID, selected, medias]);

  const isHideAnySelected = useMemo(() => {
    let anyHideSelected = false;
    allMediasID.forEach((mediaID: any) => {
      if(!anyHideSelected) {
        if(selected.indexOf(mediaID) !== -1) {
          if(medias.filter((item: any) => item.mediaID === mediaID && item.attributes.find((attribute: any) => attribute.name === "hide").value === true).length !== 0) {
            anyHideSelected = true;
          }
        }
      }
    });
    return anyHideSelected;
  }, [allMediasID, selected, medias]);

  const handleSelectAll = useCallback(() => {
    if(!userData.membership.active) return;
    let newSelected: any = selected;
    if(isSelectedAll) {
      newSelected = newSelected.filter((media: any) => allMediasID.indexOf(media) === -1);
    } else {
      newSelected = newSelected.concat(allMediasID);
    }
    const allSelected = removeDuplicates(newSelected);
    dispatch(setGallerySelected(allSelected));
  }, [allMediasID, dispatch, isSelectedAll, selected, userData.membership.active]);

  const handleFavoriteAll = useCallback(() => {
    if(!userData.membership.active) return;
    const newValue = !isFavoritedAnySelected;
    updateAttribute(selected, "favorite", newValue);
    const payload = {
      attributes: [
        {
          name: 'favorite',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(selected.join(","), payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute(selected, "favorite", newValue);
    });
  }, [galleryService, isFavoritedAnySelected, selected, t, updateAttribute, userData.membership.active]);

  const handleHideAll = useCallback(() => {
    if(!userData.membership.active) return;
    const newValue = !isHideAnySelected;
    updateAttribute(selected, "hide", newValue);
    const payload = {
      attributes: [
        {
          name: 'hide',
          value: newValue,
        },
      ],
    };
    galleryService && galleryService.setAttribute(selected.join(","), payload).catch(() => {
      createNotification(t("something_went_wrong"), "error");
      updateAttribute(selected, "hide", newValue);
    });
  }, [galleryService, isHideAnySelected, selected, t, updateAttribute, userData.membership.active]);

  const handleDownloadAll = useCallback(() => {
    dispatch(setGalleryZipSelected(getGroupSelected));
    dispatch(setGalleryZipState("start"));
  }, [getGroupSelected, dispatch]);
  
  return medias.length > 0 ? (
    <div className={classes.dateGroup} data-cy={isCypress() ? 'galleryMonth-' + moment(date).format("YYYY-MM") : null}>
      <div className={classes.actions}>
        <div className={classes.section} data-cy={isCypress() ? 'galleryMonthInfo-' + moment(date).format("YYYY-MM") : null}>
          <IconButton className={classes.select} onClick={handleSelectAll} data-cy={isCypress() ? 'galleryMonthSelectButton' : null}>
            <SVG src={isSelectedAll ? "checkmark-circle" : "circle-outlined"}/>
          </IconButton>
          <span className={classes.title} data-cy={isCypress() ? 'galleryMonthName' : null}>{DateFormat(moment(date), "yearmonthname", languageData, t)}</span>
          <span className={classes.count} data-cy={isCypress() ? 'galleryMonthCount' : null}>({getGroupSelected.length > 0 ? getGroupSelected.length + "/" : null}{medias.length})</span>
        </div>
        <div className={classes.section} data-cy={isCypress() ? 'galleryMonthActions-' + moment(date).format("YYYY-MM") : null}>
          {
            isSelectedAny ? (
              <>
                {
                  (!filtersData.filterParams.galleryFavorite && !filtersData.filterParams.galleryHide) ? (
                    <>
                      <IconButton className={`${classes.button} ${classes.favorite}`} onClick={handleFavoriteAll} data-cy={isCypress() ? 'galleryMonthFavoriteAllButton' : null}>
                        <SVG src={isFavoritedAnySelected ? "star-filled" : "star"}/>
                      </IconButton>
                      <IconButton className={classes.button} onClick={handleHideAll} data-cy={isCypress() ? 'galleryMonthHideAllButton' : null}>
                        <SVG src={isHideAnySelected ? "eye" : "eye-slash"}/>
                      </IconButton>
                    </>
                  ) : (
                    <>
                    {
                     filtersData.filterParams.galleryFavorite ? (
                        <IconButton className={`${classes.button} ${classes.favorite}`} onClick={handleFavoriteAll} data-cy={isCypress() ? 'galleryMonthFavoriteAllButton' : null}>
                          <SVG src={isFavoritedAnySelected ? "star-filled" : "star"}/>
                        </IconButton>
                       ) : null
                    }
                    {
                     filtersData.filterParams.galleryHide ? (
                        <IconButton className={classes.button} onClick={handleHideAll} data-cy={isCypress() ? 'galleryMonthHideAllButton' : null}>
                          <SVG src={isHideAnySelected ? "eye" : "eye-slash"}/>
                        </IconButton>
                       ) : null
                    }
                    </>
                  )
                }
                <Tooltip title={!canDownload ? t('gallery_download_zip_limit', {limit: config.GALLERY_DOWNLOADS_LIMIT}) : null}>
                  <span>
                    <IconButton className={classes.button} onClick={handleDownloadAll} disabled={zipState === "running" || !canDownload} data-cy={isCypress() ? 'galleryMonthDownloadAllButton' : null}>
                      <SVG src="download"/>
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ) : null
          }
        </div>
      </div>
      <div className={classes.masonryWrapper}>
        <Masonry className={classes.masonry} options={{columnWidth: 367, gutter: 16, fitWidth: true, horizontalOrder: true}}>
          {
            medias.map((item: any, key: any) => (
              <RenderItem date={date} mediaID={item.mediaID} key={`k_${key}`}/>
            ))
          }
        </Masonry>
      </div>
    </div>
  ) : null;
};

export default RenderGroup;