import moment from '../../../../utils/moment';
import React, { useCallback, useMemo } from 'react';
import { compareDates } from '../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { handleSum } from 'src/utils/useFunctions';
import { isCypress } from 'src/utils/useCypress';
import { setWarningModal } from '../../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  weekNumberMode?: boolean;
  isWeekNumber?: boolean;
  date?: any;
  workingDays?: any;
  allDates?: any;
  holidayDates?: any;
  schoolDays?: any;
  lastDay?: any;
  attendanceProgramDays?: any;
  substitutionsList: any;
  parseDay: any;
  getDateCredit: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.range': {
      backgroundColor: '#fffaca',
    },
    '&.start': {
      backgroundColor: '#fffaca',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '&.end': {
      backgroundColor: '#fffaca',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    '&.single': {
      backgroundColor: '#fffaca',
    },
    '&.weekNumber': {
      backgroundColor: '#ececec',
      borderRadius: 'none',
      '& span': {
        color: '#bcbcbc',
      }
    }
  },
  disabled: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    cursor: 'not-allowed',
    justifyContent: 'center',
    alignItems: 'center',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.weekNumber': {
      borderRadius: '0%',
      '& span': {
        backgroundColor: '#ececec',
        color: theme.colors.black,
        fontWeight: 'normal',
      }
    }
  },
  date: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: (props: Props) => !props.isWeekNumber ?  '0.65rem' : '0.5rem',
    color: (props: Props) => props.isWeekNumber ? '#BCBCBC' : (props.getDateCredit() > 0 && moment(props.date).isAfter(moment(), 'days') && moment(props.date).isSameOrBefore(moment(props.lastDay).add(props.schoolDays,'days'), 'days') && (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length === 0 || (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length !== 0 && props.attendanceProgramDays.find((day: any) => day.day === props.parseDay(moment(props.date).day())).attendanceType !== "all")) && props.workingDays.indexOf(props.parseDay(moment(props.date).day())) !== -1 && props.holidayDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.allDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.substitutionsList) ? theme.colors.black: '#BCBCBC',
    cursor: (props: Props) => props.isWeekNumber ? 'auto' : (props.getDateCredit() > 0 && moment(props.date).isAfter(moment(), 'days') && moment(props.date).isSameOrBefore(moment(props.lastDay).add(props.schoolDays,'days'), 'days') && (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length === 0 || (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length !== 0 && props.attendanceProgramDays.find((day: any) => day.day === props.parseDay(moment(props.date).day())).attendanceType !== "all")) && props.workingDays.indexOf(props.parseDay(moment(props.date).day())) !== -1 && props.holidayDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.allDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.substitutionsList) ? "pointer": "not-allowed",
    backgroundColor: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? '#F6F6F6' : theme.colors.white, 
    fontWeight: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? 'normal' : 'bold', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '100%',
    },
    '&:hover::before': {
      border:  (props: Props) => (props.getDateCredit() > 0 && moment(props.date).isAfter(moment(), 'days') && moment(props.date).isSameOrBefore(moment(props.lastDay).add(props.schoolDays,'days'), 'days') && (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length === 0 || (props.attendanceProgramDays.filter((day: any) => day.day === props.parseDay(moment(props.date).day())).length !== 0 && props.attendanceProgramDays.find((day: any) => day.day === props.parseDay(moment(props.date).day())).attendanceType !== "all")) && props.workingDays.indexOf(props.parseDay(moment(props.date).day())) !== -1 && props.holidayDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.allDates.indexOf(props.parseDay(moment(props.date).day())) === -1 && props.substitutionsList) ? '1px solid ' + theme.colors.primaryBlue[500]: "",
    },
    '&.today::before': {
      backgroundColor: '#E5F4FF',
    },
    '&.today': {
      fontWeight: 'bold',
    },
    '&.selected::before': {
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '&.selected': {
      color: theme.colors.white,
      fontWeight: 'bold',
    },
    '& p': {
      position: 'relative',
      zIndex: '2',
      marginBottom: '0',
      color: 'inherit',
    },
  },
}));

type DateItemType = {
  index?: any;
  weekNumberMode?: any;
  date?: any;
  setDate?: any;
  isLocked?: any;
  currentSelectedDate?: any;
  disabled?: boolean;
  handleClose: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, weekNumberMode, date, setDate, currentSelectedDate, disabled, handleClose}) => {
  let isWeekNumber = useMemo(() => index % 8 === 0, [index]);
  if(!weekNumberMode) isWeekNumber = false;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const substitutionsData = useAppSelector((state: any) => state.substitutions);

  const curChild = substitutionsData.currentChild;
  const list = substitutionsData.list;

  const substitutionData = (list.length > 0 && curChild !== null && curChild !== undefined) ? (list.filter((data: any) => data.childID === curChild.childID).length > 0 ? (list.find((data: any) => data.childID === curChild.childID).dates.filter((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")).length === 1 ? (list.find((data: any) => data.childID === curChild.childID).dates.find((data: any) => moment(data.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))) : {}) : {}) : {};

  const parseDay = (day: any) => {
    if(day === 0) return 7;
    else return day;
  };

  const getDateCredit = useCallback(() => {
    const allDays = substitutionsData.childData.allDays;
    const credits = allDays.filter((item: any) => item.substitutionRemaining && moment(item.date).isBetween(moment(date).subtract(substitutionsData.childData.schoolDays, 'days'), moment(date).add(substitutionsData.childData.schoolDays, 'days')) && moment(item.date).isBefore(moment(date),'day')).map((item: any) => {
      return item.substitutionRemaining;
    }).reduce(handleSum, 0);
    return credits;
  }, [date, substitutionsData.childData.allDays, substitutionsData.childData.schoolDays]);

  const classes = useStyles({
    weekNumberMode: weekNumberMode,
    isWeekNumber: isWeekNumber,
    date: date,
    workingDays: substitutionsData.childData.workingDays,
    lastDay: substitutionsData.childData.lastDay,
    allDates: substitutionsData.childData.allDates,
    holidayDates: substitutionsData.childData.holidayDates,
    schoolDays: substitutionsData.childData.schoolDays,
    attendanceProgramDays: substitutionsData.childData.attendanceProgram.days,
    substitutionsList: Object.keys(substitutionData).length === 0 ? true : false,
    parseDay: parseDay,
    getDateCredit: getDateCredit,
  });

  const handleClick = () => {
    if(!isWeekNumber) {
      if(moment(date).isAfter(moment(), 'days') && moment(date).isSameOrBefore(moment(substitutionsData.childData.lastDay).add(substitutionsData.childData.schoolDays,'days'), 'days')) {
        if(getDateCredit() > 0 && (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length === 0 || (substitutionsData.childData.attendanceProgram.days.filter((day: any) => day.day === parseDay(moment(date).day())).length !== 0 && substitutionsData.childData.attendanceProgram.days.find((day: any) => day.day === parseDay(moment(date).day())).attendanceType !== "all")) && substitutionsData.childData.workingDays.indexOf(parseDay(moment(date).day())) !== -1 && substitutionsData.childData.holidayDates.indexOf(parseDay(moment(date).day())) === -1 && substitutionsData.childData.allDates.indexOf(parseDay(moment(date).day())) === -1 && Object.keys(substitutionData).length === 0) {
          setDate(date);
          handleClose();
        } else {
          const settings = {
            isOpen: true,
            title: t('function_limited'),
            content: t('function_limited_substitutions_datepicker_days'),
          };
          dispatch(setWarningModal(settings));
        }
      } else {
        const settings = {
          isOpen: true,
          title: t('function_limited'),
          content: t('function_limited_substitutions_datepicker', {days: substitutionsData.childData.schoolDays}),
        };
        dispatch(setWarningModal(settings));
      }
    }
  };

  return (
    <div className={`${(disabled) ? `${classes.root} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && 'selected'}` : `${classes.disabled} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && 'selected'}`}`} onClick={handleClick} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      <span className={`${classes.date} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(compareDates(moment(), moment(date))) && !isWeekNumber && 'today'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && !isWeekNumber &&  'selected'} ${!disabled ? "disabled": ""}`}>
        <p onClick={handleClick} data-clarity-unmask="true">{(weekNumberMode && isWeekNumber) ? moment(date).week() : moment(date).date()}</p>
      </span>
    </div>
  );
};

export default DateItem;