import CheckIcon from '@mui/icons-material/Check';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useState } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
  },
  typesContainer: {
    width: "calc(100% - 40px)",
    padding: "20px",
    display: "flex",
    flexDirection: 'column',
    gap: '12px',
  },
  typeItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '24px',
    padding: '20px 24px',
    textTransform: 'unset',
    '& > svg': {
      color: theme.colors.grey[700],
      width: '30px',
      height: '30px',
    },
    '& > span:first-of-type': {
      color: theme.colors.grey[700],
      fontSize: '18px',
      fontWeight: '500',
      marginLeft: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },    
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0',
    marginLeft: 'auto',
    '& > span.check': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.primaryBlue[500],
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.primaryBlue[500],
      marginRight: '0px',
      marginLeft: 'auto',
      borderRadius: '100%',
      '& > svg': {
        color: theme.colors.white,
        transform: 'scale(0.7)'
      },
    },
    '& > span.nocheck': {
      display: 'block',
      backgroundColor: theme.colors.white,
      minWidth: '23px',
      minHeight: '23px',
      width: '23px',
      height: '23px',
      maxWidth: '23px',
      maxHeight: '23px',
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[350],
      marginRight: '0px',
      marginLeft: 'auto',
      borderRadius: '100%',
    },
  },
}));

type ModalType = {
  onSave: any;
  onClose: any;
  selected: any;
};

const FiltersPollsModal: React.FunctionComponent<ModalType> = ({onSave, onClose, selected}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedPoll, setSelectedPoll] = useState(selected);
  
  const onCloseModal = () => {
    onClose();
  };
  
  const handleToggleItem = (pollVoted: any) => {
    if(selectedPoll === pollVoted) {
      setSelectedPoll(null);  
    } else {
      setSelectedPoll(pollVoted);
    }
  };
  
  const handleClear = (e: any) => {
    e.stopPropagation();
    setSelectedPoll(null);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleSave = (e: any) => {
    e.stopPropagation();
    onSave(selectedPoll);
    onCloseModal();
  };
  
  return (
    <Modal
      open={true} 
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "filtersPollsModal" : null}>
        <div className={classes.header}>
          <p>{t('polls')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div>
          <div className={classes.typesContainer}> 
            <Button className={classes.typeItem} onClick={() => handleToggleItem(true)} data-cy={isCypress() ? "postTypeButtonPost" : null}>
              <SVG src="polls-voted"/>
              <span>{t('voted')}</span>
              <div className={classes.status}>
                {
                  (selectedPoll != null && selectedPoll) ? (
                    <span className="check">
                      <CheckIcon/>
                    </span>
                  ) : (
                    <span className="nocheck"/>
                  )
                }
              </div>    
            </Button>
            <Button className={classes.typeItem} onClick={() => handleToggleItem(false)} data-cy={isCypress() ? "postTypeButtonPoll" : null}>
              <SVG src="polls-not-voted"/>
              <span>{t('not_voted')}</span>
              <div className={classes.status}>
                {
                  (selectedPoll != null && !selectedPoll) ? (
                    <span className="check">
                      <CheckIcon/>
                    </span>
                  ) : (
                    <span className="nocheck"/>
                  )
                }
              </div>    
            </Button>
          </div>
        </div>
        <div className={classes.footer}>
          {
            selectedPoll != null ? (
              <NormalButton buttonType="clear" onClick={handleClear} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          <NormalButton buttonType={selected === selectedPoll ? "disabled" : "primary"} disabled={selected === selectedPoll} onClick={handleSave} dataCy="doneButton">
            {t("done")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default FiltersPollsModal;