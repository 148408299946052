import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  contactCard: {
    display: 'flex',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: 'calc(100% - 48px)',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    padding: '24px',
    height: 'calc(140px - 48px)',
    width: 'calc(100% - 48px)',
    gap: '8px',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    flex: '0 1 80%',
  },
  imageBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 20%',
  },
  contactsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0px 8px',
  },
  employeeImage: {
    width: '80px',
    height: '80px',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    position: 'relative',
    borderRadius: "12px",
  },
  employeePosition: {
    fontSize: '18px',
    display: 'inline-flex',
    fontWeight: '500',
    color: theme.colors.grey[700],
  },
  employeeName: {
    fontSize: '16px',
    display: 'block',
    fontWeight: '600',
    color: theme.colors.black,
  },
  employeeContact: {
    fontSize: '14px',
    display: 'inline-flex',
    color: theme.colors.primaryBlue[500],
    textDecoration: 'none',
  },
}));

type ItemsType = {
  employeeID: any;
};

const ContactCard: React.FunctionComponent<ItemsType> = ({employeeID}) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const employeeData = dataData.employees.find((employee: any) => employee.employeeID === employeeID);
  
  return (
    <div className={classes.contactCard} data-cy={isCypress() ? 'contactCard' + employeeData.employeeID : null}>
      <div className={classes.infoBlock}>
        {
          employeeData.employeePosition ? (
            <span className={classes.employeePosition}>{employeeData.employeePosition}</span>
          ) : null
        }
        <span className={classes.employeeName}>{employeeData.firstname} {employeeData.surname}</span>
        <div className={classes.contactsBlock}>
          {
            employeeData.workEmail !== "" ? (
              <a className={classes.employeeContact} href={`mailto:${employeeData.workEmail}`}>{employeeData.workEmail}</a>
            ) : null
          }
          {
            employeeData.workPhonePrefix + employeeData.workPhoneNumber !== "" ? (
              <a className={classes.employeeContact} href={`tel:+${employeeData.workPhonePrefix + employeeData.workPhoneNumber}`}>+{employeeData.workPhonePrefix + employeeData.workPhoneNumber}</a>
            ) : null
          }
        </div>
      </div>
      <div className={classes.imageBlock}>
        {
          employeeData.photo ? (
            <AuthenticatedImage className={classes.employeeImage} thumbLink={employeeData.photo.thumbLink} fullsizeLink={employeeData.photo.fullsizeLink} preferQuality="fullsize" isClickable={true}/>
          ) : null
        }
      </div>
    </div>
  );
}

export default ContactCard;