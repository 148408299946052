import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from 'src/utils/dateFormat';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setConfirmModal, setDraftsModal } from '../../../store/actions/modals.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { yesOrNo } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  detail: {
    display: 'block',
  },
  description: {
    fontSize: '14px',
  },
  link: {
    color: theme.colors.black,
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '4px',
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important', 
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.grey[300],
    padding: '8px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      margin: '6px 0',
      alignItems: 'center',
      ' & > span': {
        fontSize: '14px',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: theme.colors.grey[200],
    '& > p': {
      fontSize: '13px',
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    '& > span': {
      fontSize: '16px',
      fontWeight: '500',
    },
    '& > p': {
      fontSize: '13px',
      marginBottom: '0',
    },
  },
  buttons: {
    display: 'flex',
    gap: '4px',
    marginLeft: 'auto',
  },
  smallButton: {
    cursor: 'pointer',
    width: '36px',
    height: '36px',
    '& > svg': {
      color: '#5AB8FF',
      width: '18px',
      height: '18px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '4px',
  },
}));

const DraftsModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);

  const type = modalsData.draftsModal.type;
  const classbookType = modalsData.draftsModal.classbookType;
  const isSchoolCare = modalsData.draftsModal.isSchoolCare;
  const onDraftLoad = modalsData.draftsModal.onDraftLoad;
  const drafts = getUserSetting(userData.userSettings, "drafts", [type]);

  const activityTypes = configurationData.configuration.activityTypes;

  const isSaving = useRef(false);

  const getAvailableDrafts = () => {
    if(type === "classbook") {
      return drafts.filter((item: any) => item.classbookType === classbookType && item.isSchoolCare === isSchoolCare);
    } else {
      return drafts;
    }
  };

  const getActivityTypeData = (activityTypeID: any) => {
    return activityTypes.filter((activity: any) => activity.activityTypeID === activityTypeID).length === 0 ? null : activityTypes.find((activity: any) => activity.activityTypeID === activityTypeID);
  };

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const getPostType = (postTypeID: any) => {
    if(postTypeID === 3) return t('post_video');
    else if(postTypeID === 2) return t('poll');
    else return t('post');
  };

  const availableDrafts = getAvailableDrafts();
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      currentDraftID: null,
      type: null,
      classbookType: null,
      isSchoolCare: null,
      onDraftLoad: null,
    };
    dispatch(setDraftsModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleUpdateDrafts = useCallback(async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      const oldValue = drafts;
      const newValue = updateUserSetting(userData.userSettings, "drafts", [type], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "drafts", [type], value);
      if(result) {
        isSaving.current = false;
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "drafts", [type], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("draft_not_deleted"), "error");
        isSaving.current = false;
      }
    }
  }, [dispatch, drafts, t, userData, type]);

  const handleLoadDraft = (e: any, data: any) => {
    e.stopPropagation();
    e.preventDefault();
    onDraftLoad(data);
    onCloseModal();
  };

  const handleDeleteDraft = (e: any, draftID: any) => {
    e.stopPropagation();
    e.preventDefault();
    const deletePost = () => {
      const newDraftData = drafts.filter((item: any) => item.draftID !== draftID);
      handleUpdateDrafts(newDraftData);
    };
    const settings = {
      isOpen: true,
      title: t('draft_delete'),
      content: t('draft_delete_confirm'),
      onAccept: deletePost,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "conceptModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-cy={isCypress() ? 'conceptModalTitle' : null}>{t('drafts')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            availableDrafts.length > 0 ? (
              <div className={classes.accordions}>
                {
                  availableDrafts.map((item: any, key: any) => (
                    <Accordion className={classes.accordion} data-cy={isCypress() ? 'draftAccordion' + item.draftID : null} key={`k_${key}`}>
                      <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                        <span>{t('draft_template_info', {draftID: item.draftID, date: DateFormat(moment(item.draftDate), "timeline", languageData, t)})}</span>
                        <div className={classes.buttons}>
                          <IconButton className={classes.smallButton} tooltip={t("use_draft")} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={(e: any) => handleLoadDraft(e, item)} dataCy={'draftAccordionLoad' + item.draftID}>
                            <SVG src="pencil"/>
                          </IconButton>
                          <IconButton className={classes.smallButton} tooltip={t("delete_draft")} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={(e: any) => handleDeleteDraft(e, item.draftID)} dataCy={'draftAccordionDelete' + item.draftID}>
                            <SVG src="trash"/>
                          </IconButton>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        {
                          type === "classbook" ? (
                            <>
                              {
                                (item.theme && item.theme !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('theme')}
                                    </span>
                                    <p data-cy={isCypress() ? 'themeValue' : null}>{item.theme}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.morning && item.morning !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('morning_educational_activities')}
                                    </span>
                                    <p data-cy={isCypress() ? 'morningValue' : null}>{item.morning}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.afternoon && item.afternoon !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('afternoon_educational_activities')}
                                    </span>
                                    <p data-cy={isCypress() ? 'afternoonValue' : null}>{item.afternoon}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.notes && item.notes !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('additional_notes')}
                                    </span>
                                    <p data-cy={isCypress() ? 'notesValue' : null}>{item.notes}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.visit && item.visit !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('record_visits')}
                                    </span>
                                    <p data-cy={isCypress() ? 'visitValue' : null}>{item.visit}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.interestActivities && item.interestActivities !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('interest_activities')}
                                    </span>
                                    <p data-cy={isCypress() ? 'interestActivitiesValue' : null}>{item.interestActivities}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.relaxationRecreationalActivities && item.relaxationRecreationalActivities !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('relaxation_recreational_activities')}
                                    </span>
                                    <p data-cy={isCypress() ? 'relaxationRecreationalActivitiesValue' : null}>{item.relaxationRecreationalActivities}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.preparationTeaching && item.preparationTeaching !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('preparation_teaching')}
                                    </span>
                                    <p data-cy={isCypress() ? 'preparationTeachingValue' : null}>{item.preparationTeaching}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.otherActivities && item.otherActivities !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('other_activities')}
                                    </span>
                                    <p data-cy={isCypress() ? 'otherActivitiesValue' : null}>{item.otherActivities}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.internalNote && item.internalNote !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('internal_note')}
                                    </span>
                                    <p data-cy={isCypress() ? 'internalNoteValue' : null}>{item.internalNote}</p>
                                  </div>
                                ) : null
                              }
                            </>
                          ) : type === "timeline" ? (
                            <>
                              <div className={classes.formWrapper}>
                                <span>
                                  {t('post_type')}
                                </span>
                                <p data-cy={isCypress() ? 'postTypeValue' : null}>{getPostType(item.postType)}</p>
                              </div>
                              {
                                (item.title && item.title !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('title')}
                                    </span>
                                    <p data-cy={isCypress() ? 'titleValue' : null}>{item.title}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.description && item.description !== "") ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('post_content')}
                                    </span>
                                    <p data-cy={isCypress() ? 'descriptionValue' : null}>{item.description}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.answers && Array.isArray(item.answers) && item.answers.filter((item: any) => item.answer !== "").length !== 0) ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('answers')}
                                    </span>
                                    {
                                      item.multipleAnswers ? (
                                        <p data-cy={isCypress() ? 'multipleAnswersValue' : null}>{t('multiple_answers')}</p>
                                      ) : null
                                    }
                                    <p data-cy={isCypress() ? 'answersValue' : null}>{item.answers.filter((item: any) => item.answer !== "").map((subItem: any) => { return `${subItem.answer} ${item.limitAnswers ? `(${subItem.limit.toString()})` : null}`; }).join(", ")}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.timelimitPoll && item.pollLimit) ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('answers_limit')}
                                    </span>
                                    <p data-cy={isCypress() ? 'pollLimitValue' : null}>{DateFormat(moment(item.pollLimit).toString(),"defaulttime", languageData, t)}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.activityType && item.activityType !== null) ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('activity_type')}
                                    </span>
                                    <p data-cy={isCypress() ? 'activityTypeValue' : null}>{getActivityTypeData(item.activityType).name}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.selectedChildren && item.selectedChildren.length !== 0) ? (
                                  <div className={classes.formWrapper}>
                                    <span>
                                      {t('children')}
                                    </span>
                                    <p data-cy={isCypress() ? 'selectedChildrenValue' : null}>{item.selectedChildren.map((item: any) => { return getChildData(item.childID).displayName; }).join(", ")}</p>
                                  </div>
                                ) : null
                              }
                              {
                                (item.modules && Object.keys(item.modules).length !== 0) ? (
                                  <>
                                    {
                                      item.modules.reactions !== undefined && item.modules.reactions !== null ? (
                                        <div className={classes.formWrapper}>
                                          <span>
                                            {t('reactions')}
                                          </span>
                                          <p data-cy={isCypress() ? 'reactionsValue' : null}>{t(yesOrNo(item.modules.reactions))}</p>
                                        </div>
                                      ) : null
                                    }
                                    {
                                      item.modules.comments !== undefined && item.modules.comments !== null ? (
                                        <div className={classes.formWrapper}>
                                          <span>
                                            {t('comments')}
                                          </span>
                                          <p data-cy={isCypress() ? 'commentsValue' : null}>{t(yesOrNo(item.modules.comments))}</p>
                                        </div>
                                      ) : null
                                    }
                                  </>
                                ) : null
                              }
                            </>
                          ) : null
                        }
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </div>
            ) : (
              <NotFound text={t('no_drafts_found')}/>
            )
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="draftsModalClose">
            {t("cancel")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default DraftsModal;