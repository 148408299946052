import IconButton from 'src/components/Buttons/IconButton';
import Popover from '@mui/material/Popover';
import React, { useRef } from 'react';
import SVG from '../../Images/SvgRenderer';
import Spinner from '../../Forms/Spinner';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import moment from '../../../utils/moment';
import months from 'src/constants/months';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px', 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  datePickerInput: {
    position: "relative",
    borderRadius: "12px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    minWidth: 'calc(100% - 27px)',
    maxWidth: 'calc(100% - 27px)',
    height: "40px",
    backgroundColor: theme.colors.white,
    padding: "0px 10px 0px 15px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.grey[325],
    '&:hover': {
      cursor: "pointer",
    },
    '&.disabled': {
      cursor: 'auto',
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      '& svg': {
        color: theme.colors.grey[560],
      },
    },
  },
  selectedDate: {
    marginBottom: '0 !important',
    fontSize: '0.75rem',
    cursor: "pointer"
  },
  calendarWrapper: {
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      boxShadow: theme.shadows[2],
    },
  },
  calendar: {
    position: "relative",
    width: "280px",
    maxHeight: "300px",
    maxWidth: '100%',
    backgroundColor: theme.colors.white,
    zIndex: "9999",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: '10px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: 'hidden',
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '5px 20px',
    '& p': {
      marginBottom: '0',
      fontWeight: 'bold',
    }
  },
  dateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingTop: '8px',
    maxHeight: '246px',
    overflow: 'auto',
  },
  navigator: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '68px',
    '& > button': {
      color: theme.colors.primaryBlue[500],
      '& > svg': {
        width: '20px',
        height: '20px',
      },
      '&: disabled': {
        color: theme.colors.grey[560],
      },
    },
  },
  icons: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: '-2px',
  },
  close: {
    height: '16px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: "16px",
      height: "16px",
    },
  },
  datePick: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&.disabled': {
      color: theme.colors.grey[560],
      cursor: 'auto',
    },
  },
  dayBlock: {
    display: 'flex',
    width: 'calc(100% / 4)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '44px',
    fontSize: '14px',
    cursor: 'pointer',
    color: '#393939',
    backgroundColor: 'transparent',
    transition: 'background-color 0.25s, color 0.25s',
    '&:hover': {
      backgroundColor: '#E6E6E6',
    },
    '&.active': {
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      cursor: 'default',
    },
  },
}));

type DatePickerType = {
  label?: any;
  presetDate?: any;
  setDate?: any;
  presetDateRange?: any;
  disabled?: any;
  mode?: any;
  showDetails?: any;
  dataCy?: any;
};

const DayPicker: React.FunctionComponent<DatePickerType> = ({ label, presetDate, setDate, disabled = false, mode = "input", showDetails = true, dataCy }) => {
  
  const date = presetDate == null ? moment() : presetDate;
  const { t } = useTranslation();

  const [state, setState] = useStates({
    currentYearMonth: moment(presetDate ? presetDate : ""),
    anchorEl: null
  });

  const daysAnchors: any = useRef([]);
  const daysScroll: any = useRef(null);

  useEffect(() => {
    setState("currentYearMonth", moment(presetDate));
  }, [presetDate, state.anchorEl, setState, date], [state.anchorEl]);

  const classes = useStyles();

  const days = moment(state.currentYearMonth).daysInMonth();

  const cancelSelection = (e: any) => {
    setDate(moment());
    e.stopPropagation();
    e.preventDefault();
  }

  const handleClick = (e: any) => {
    if(!disabled) {
      setState("anchorEl", e.currentTarget);
      setTimeout(() => {
        const currentDay = daysAnchors.current[moment(date).date()];
        if(daysAnchors.current[moment(date).date()]) {
          daysScroll.current.scrollTo({
            top: currentDay.offsetTop - daysScroll.current.clientHeight + 110,
          });
        }
      }, 100);
    }
  };

  const handleClose = () => {
    if(!disabled) {
      setState("anchorEl", null);
    }
  };

  const handlePrevMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).subtract(1, 'month'));
  };

  const handleNextMonth = (e: any) => {
    e.stopPropagation();
    setState("currentYearMonth", moment(state.currentYearMonth).add(1, 'month'));
  };
  
  const handlePrevDay = (e: any) => {
    e.stopPropagation();
    setDate(moment(date).subtract(1, 'day'));
  };

  const handleNextDay = (e: any) => {
    e.stopPropagation();
    setDate(moment(date).add(1, 'day'));
  };

  const handleSetDay = (e: any, day: any) => {
    e.stopPropagation();
    setDate(moment().set("date", day).set('month', moment(state.currentYearMonth).month()).set('year', moment(state.currentYearMonth).year()));
    handleClose();
  };
  
  return (
    <>
      <div className={classes.wrapper}>
        {
          label ? (
            <label>
              {label}
            </label>
          ) : null
        }
        {
          mode === "input" ? (
            <div className={`${classes.datePickerInput} ${disabled ? 'disabled' : null}`} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
              <p className={classes.selectedDate} data-clarity-unmask="true">
                {
                  (presetDate === null || date === null) ? (
                    <span style={{color: "#787878"}}>{disabled ? t('not_allowed') : t('select_day')}</span>
                  ) : (
                    `${moment(date).date()}. ${showDetails ? `${t(months[moment(date).month()].toLowerCase()).toLowerCase()} ${moment(date).year()}` : ''}`
                  )
                }</p>
              <div className={classes.icons}>
              { 
                (presetDate !== null && !moment(presetDate).isSame(moment(), 'day') && !disabled) ? (
                  <span className={classes.close} onClick={cancelSelection}>
                    <SVG src="close"/>
                  </span>
                ) : null
              }
              </div>
            </div>
          ) : mode === "spinner" ? (
            <Spinner prevVisible={true} nextVisible={true} onClickPrev={handlePrevDay} onClickNext={handleNextDay} disabled={disabled}>
              <span className={`${classes.datePick} ${disabled ? 'disabled' : null}`} onClick={handleClick} data-cy={isCypress() ? dataCy : null} data-clarity-unmask="true">
                {moment(date).date()}. {showDetails ? `${t(months[moment(date).month()].toLowerCase()).toLowerCase()} ${moment(date).year()}` : ''}
              </span>
            </Spinner>
          ) : null
        }
      </div>
      <Popover className={classes.calendarWrapper} open={Boolean(state.anchorEl)} anchorEl={state.anchorEl} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: mode === 'input' ? 'right' : 'center'}} transformOrigin={{vertical: 'top', horizontal: mode === 'input' ? 'right' : 'center'}}>
        <div className={classes.calendar}>
          <div className={classes.calendarHeader}>
            <p data-clarity-unmask="true">{t(months[moment(state.currentYearMonth).month()].toLowerCase())} {moment(state.currentYearMonth).year()}</p>
            <div className={classes.navigator}>
              <IconButton onClick={handlePrevMonth} data-cy={isCypress() ? 'prevMonthButton' : null}>
                <SVG src="chevron-left"/>
              </IconButton>
              <IconButton onClick={handleNextMonth} dataCy='nextMonthButton'>
                <SVG src="chevron-right"/>
              </IconButton>
            </div>
          </div>
          <div className={classes.dateWrapper} data-cy={isCypress() ? 'calendar' : null} ref={daysScroll}>
            {
              Array.from('x'.repeat(days)).map((i: any, key: any) => (
                <div className={`${classes.dayBlock} ${(key + 1) === moment(date).date() && moment(state.currentYearMonth).month() === moment(date).month() && moment(state.currentYearMonth).year() === moment(date).year() ? 'active' : null}`} key={`k_${key}`} onClick={(e: any) => (key + 1) === moment(date).date() && moment(state.currentYearMonth).month() === moment(date).month() && moment(state.currentYearMonth).year() === moment(date).year() ? null : handleSetDay(e, (key + 1))} ref={(el: any) => (daysAnchors.current[(key + 1)] = el)} data-clarity-unmask="true">
                  {(key + 1)}.
                </div>
              ))
            }  
          </div>
        </div>
      </Popover>
    </>
  );
};

export default DayPicker;