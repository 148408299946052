import { getAppData } from 'src/utils/useApp';
import { getUserRole } from 'src/utils/useUser';
import { setPostCreateModal } from 'src/store/actions/modals.actions';

const loadOpenedModals = (dispatch: any, userData: any) => {
  const savedData = getAppData();
  if(getUserRole(userData.userObject.roleType) === "teacher" || getUserRole(userData.userObject.roleType) === "director") {
    if(savedData.savedPost && savedData.savedPost !== null && typeof savedData.savedPost === "object") {
      const settings = {
        isOpen: true,
      };
      dispatch(setPostCreateModal(settings));
    }
    return true;
  } else {
    return true;
  }
};

export default loadOpenedModals;