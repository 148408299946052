import * as DirectorDataService from '../../../../services/director/data.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import { getUserRole } from 'src/utils/useUser';
import { setDataEmployees, setDataEmployeesID } from 'src/store/actions/data.actions';

const loadEmployees = async (dispatch: any, userData: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") {
    try {
      const result: any = await ParentDataService.listEmployees();
      if(result && result.data && result.data.employees) {
        const employees = result.data.employees;
        employees.sort((a: any, b: any) => {
          const nameA = a.displayName ? a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.displayName;
          const nameB = b.displayName ? b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.displayName;
          const firstnameA = a.firstname? a.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.firstname;
          const lastnameA = a.surname? a.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.surname;
          const firstnameB = b.firstname? b.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.firstname;
          const lastnameB = b.surname? b.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.surname;
          if(nameA === nameB) return a.employeeID - b.employeeID;
          else {
            if(lastnameA === lastnameB) {
              return firstnameA > firstnameB ? 1 : -1;
            } else {
              return lastnameA > lastnameB ? 1 : -1;
            }
          }
        });
        const employeesID = employees.map((item: any) => { return item.employeeID });
        dispatch(setDataEmployees(employees));
        dispatch(setDataEmployeesID(employeesID));
        return {employees: employees, employeesID: employeesID};                       
      } else {
        return false;
      }
    } catch {
      return false;  
    };
  } else if(getUserRole(userData.userObject.roleType) === "teacher") {
    try {
      const result: any = await TeacherDataService.listEmployees();
      if(result && result.data && result.data.employees) {
        const employees = result.data.employees;
        employees.sort((a: any, b: any) => {
          const nameA = a.displayName ? a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.displayName;
          const nameB = b.displayName ? b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.displayName;
          const firstnameA = a.firstname? a.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.firstname;
          const lastnameA = a.surname? a.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.surname;
          const firstnameB = b.firstname? b.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.firstname;
          const lastnameB = b.surname? b.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.surname;
          if(nameA === nameB) return a.employeeID - b.employeeID;
          else {
            if(lastnameA === lastnameB) {
              return firstnameA > firstnameB ? 1 : -1;
            } else {
              return lastnameA > lastnameB ? 1 : -1;
            }
          }
        });
        const employeesID = employees.map((item: any) => { return item.employeeID });
        dispatch(setDataEmployees(employees));
        dispatch(setDataEmployeesID(employeesID));
        return {employees: employees, employeesID: employeesID};                       
      } else {
        return false;
      }
    } catch {
      return false;  
    };
  } else if(getUserRole(userData.userObject.roleType) === "director") {
    try {
      const result: any = await DirectorDataService.listEmployees();
      if(result && result.data && result.data.employees) {
        const employees = result.data.employees;
        employees.sort((a: any, b: any) => {
          const nameA = a.displayName ? a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.displayName;
          const nameB = b.displayName ? b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.displayName;
          const firstnameA = a.firstname? a.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.firstname;
          const lastnameA = a.surname? a.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : a.surname;
          const firstnameB = b.firstname? b.firstname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.firstname;
          const lastnameB = b.surname? b.surname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : b.surname;
          if(nameA === nameB) return a.employeeID - b.employeeID;
          else {
            if(lastnameA === lastnameB) {
              return firstnameA > firstnameB ? 1 : -1;
            } else {
              return lastnameA > lastnameB ? 1 : -1;
            }
          }
        });
        const employeesID = employees.map((item: any) => { return item.employeeID });
        dispatch(setDataEmployees(employees));
        dispatch(setDataEmployeesID(employeesID));
        return {employees: employees, employeesID: employeesID};                       
      } else {
        return false;
      }
    } catch {
      return false;  
    };
  } 
};

export default loadEmployees;