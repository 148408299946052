import CloseButton from 'src/components/Buttons/CloseButton';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import RadioGroup from 'src/components/Forms/RadioGroup';
import React from 'react';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setTimetableRoomHandleModal } from '../../../store/actions/modals.actions';
import { setTimetableRooms } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    '& > .twitter-picker': {
      width: 'calc(100% - 2px) !important',
      boxShadow: 'none !important',
      borderColor: 'rgb(232, 232, 232) !important',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      borderRadius: '10px !important',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '16px',
  },
}));

const TimetableRoomHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const timetableData = useAppSelector((state: any) => state.timetable);

  const isEdit = modalsData.timetableRoomHandleModal.roomID === null ? false : true;
  const roomID = isEdit ? modalsData.timetableRoomHandleModal.roomID : null;
  const roomData = isEdit ? (timetableData.rooms.filter((room: any) => room.roomID === roomID).length === 1 ? timetableData.rooms.find((room: any) => room.roomID === roomID) : null) : null
  
  const [state, setState] = useStates({
    name: isEdit ? roomData.name : "",
    abbr: isEdit ? roomData.shortName : "",
    type: isEdit ? roomData.type.toString() : null,
  });
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      roomID: null,
      schoolID: null,
    };
    dispatch(setTimetableRoomHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  const handleSave = () => {
    const payload = {
      "name": state.name,
      "shortName": state.abbr,
      "type": state.type,
      "schoolID": modalsData.timetableRoomHandleModal.schoolID,
    };
    if(isEdit) {
      timetableService && timetableService.editRoom(roomID, payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_room_updated"), "success");
            onCloseModal();
            const newRooms = timetableData.rooms.map((room: any) => {
              if(room.roomID === roomID) {
                return result.data;
              } else {
                return room;
              }
            });
            dispatch(setTimetableRooms(newRooms));
          } else {
            createNotification(t("timetable_room_not_updated"), "error");
          }
        } else {
          createNotification(t("timetable_room_not_updated"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_room_not_updated"), "error");
      });
    } else {
      timetableService && timetableService.createRoom(payload).then((result: any) => {
        if(result) {
          if(result.data) {
            createNotification(t("timetable_room_added"), "success");
            onCloseModal();
            const newRooms = [...timetableData.rooms, result.data];
            dispatch(setTimetableRooms(newRooms));
          } else {
            createNotification(t("timetable_room_not_added"), "error");
          }
        } else {
          createNotification(t("timetable_room_not_added"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_room_not_added"), "error");
      });
    }
  };

  const roomsTypes: any = [
    {
      name: t('timetable_room'),
      value: '1',
      dataCy: 'roomRoomRadio',
    },
    {
      name: t('timetable_break_room'),
      value: '2',
      dataCy: 'breakRoomRadio',
    },
  ];

  const handleRoomType = (e: any) => {
    setState("type", e.target.value);
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "timetableRoomHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('timetable_room_edit') : t('timetable_room_add')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          <Input label={t('timetable_room_name')} name="name" value={isEdit ? roomData.name : null} onChange={handleInputChange}/>
          <Input label={t('timetable_room_abbr')} name="abbr" value={isEdit ? roomData.shortName : null} onChange={handleInputChange}/>
          <Label>{t('timetable_room_type')}</Label>
          <RadioGroup items={roomsTypes} value={state.type} onChange={handleRoomType}/>
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
            {t("cancel")}
          </NormalButton>
          <NormalButton onClick={handleSave} disabled={state.name === "" || state.abbr === "" || state.type === null} dataCy="saveButton">
            {t('save')}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default TimetableRoomHandleModal;