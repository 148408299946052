import slice from '../slices/calendar.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setCalendarDate = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarDate(data));
  }
};

export const setCalendarViewMode = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarViewMode(data));
  }
};

export const setCalendarIsLoading = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarIsLoading(data));
  }
};

export const setCalendarEvents = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarEvents(data));
  }
};

export const setCalendarHoverEvent = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarHoverEvent(data));
  }
};

export const setCalendarFilterSchoolID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFilterSchoolID(data));
  }
};

export const setCalendarFilterClassID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFilterClassID(data));
  }
};

export const setCalendarFilterChildID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFilterChildID(data));
  }
};

export const setCalendarFilterUserID = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFilterUserID(data));
  }
};

export const setCalendarFilterType = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarFilterType(data));
  }
};