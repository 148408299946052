import DateItem from './DateItem';
import moment from '../../../../utils/moment';
import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { moveLastToFirstInArray } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from '../../../../constants/weekdays';

const useStyles = createUseStyles((theme: any) => ({
  calendarWeek: {
    width: '100%',
    height: '100%',
  },
  weekdayHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: '42px 1fr',
    height: '42px',
    position: 'relative',
    zIndex: theme.zIndex.calendarHeader,
  },
  weekday: {
    backgroundColor: '#ececec',
    borderBottom: '1px solid #d2d2d2',
    width: '100%',
    minWidth: '240px',
    padding: '10px 0',
    fontSize: '14px',
    '& p': {
      width: '100%',
      textAlign: 'center',
      marginBottom: '0',
    },
  },
  dateWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    height: 'calc(100% - 42px)',
    minHeight: '130px',
    width: '100%',
  },
  loadingWrapper: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: theme.zIndex.calendarLoading,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(128,128,128,0.25)',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type CalendarWeekType = {
  className?: any;
};

const CalendarWeek: React.FunctionComponent<CalendarWeekType> = ({ className }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const calendarData = useAppSelector((state: any) => state.calendar);
  const userData = useAppSelector((state: any) => state.user);

  const curDate = calendarData.date;
  const isLoading = calendarData.isLoading;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const firstDate = useMemo(() => {
    return moment(curDate).isoWeekday(0);
  }, [curDate]);

  return (
    <div className={`${classes.calendarWeek} ${className ? className : null}`}>
      <div className={classes.weekdayHeader}>
        {
          weekdays.map((weekday: any, key: any) => (
            <div className={classes.weekday} key={`k_${key}`}>
              <p>{t(weekday[0].toLowerCase() + weekday.slice(1))}</p>
            </div>
          ))
        }
      </div>
      <div className={classes.dateWrapper}>
        {
          isLoading ? (
            <div className={classes.loadingWrapper}>
              <div className={classes.spinner}>
                <CircularProgress/>
              </div>
            </div>
          ) : null
        }
        {
          firstDate && new Array(7).fill('').map((_, key) => {
            return (
              <DateItem
                index={key}
                key={`k_${key}`}
                curDate={curDate}
                date={moment(firstDate).add(key + firstDayInWeek, 'days')}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default CalendarWeek;