import slice from '../slices/services.slice';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { ThunkAction } from '@reduxjs/toolkit';

export const actions = slice.actions;

export const setAbsenceService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAbsenceService(data));
  }
};

export const setAttendanceService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setAttendanceService(data));
  }
};

export const setCalendarService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCalendarService(data));
  }
};

export const setClassbookService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClassbookService(data));
  }
};

export const setClubsService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setClubsService(data));
  }
};

export const setCommentsService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommentsService(data));
  }
};

export const setCommunicationService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setCommunicationService(data));
  }
};

export const setDataService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setDataService(data));
  }
};

export const setExportService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setExportService(data));
  }
};

export const setGalleryService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setGalleryService(data));
  }
};

export const setInspirationService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setInspirationService(data));
  }
};

export const setMealsService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setMealsService(data));
  }
};

export const setOrderService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setOrderService(data));
  }
};

export const setPaymentService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setPaymentService(data));
  }
};

export const setReactionsService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setReactionsService(data));
  }
};

export const setStockService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setStockService(data));
  }
};

export const setSubstitutionsService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setSubstitutionsService(data));
  }
};

export const setTimelineService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimelineService(data));
  }
};

export const setTimetableService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setTimetableService(data));
  }
};

export const setWagesService = (data: any): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async(dispatch) => { 
    dispatch(actions.setWagesService(data));
  }
};