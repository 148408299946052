import IconButton from 'src/components/Buttons/IconButton';
import React, { createElement, useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import widgets from '../../../constants/widgets';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { LastPost, LastPostTitle } from '../../../components/Widgets/LastPost';
import { PostForm, PostFormTitle } from 'src/components/Widgets/PostForm';
import { recalculateKeys } from 'src/utils/useFunctions';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { setConfirmModal, setDashboardHandleWidgetModal } from 'src/store/actions/modals.actions';
import { setDashboardWidgets } from 'src/store/actions/dashboard.actions';
import { setIsDashboardEditable } from 'src/store/actions/layout.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { t } from 'i18next';
import { TodayAttendance, TodayAttendanceTitle } from '../../../components/Widgets/TodayAttendance';
import { TodayExcuseNotes, TodayExcuseNotesTitle } from 'src/components/Widgets/TodayExcuseNotes';
import { TodaySubstitutions, TodaySubstitutionsTitle } from 'src/components/Widgets/TodaySubstitutions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

interface Props {
  isEditable?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  dashboardPage: {
    display: 'flex',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    position: 'relative',
  },
  dashboard: {
    width: '100%',
    height: '100%',
  },
  widgetFrameWrapper: {
    position: 'relative',
    padding: '4px',
    height: 'fit-content',
    boxSizing: 'border-box',
    display: (props: Props) => {
      if(props.isEditable) return 'flex';
      else return '';
    },
    alignItems: (props: Props) => {
      if(props.isEditable) return 'center';
      else return '';
    },
    backgroundColor: (props: Props) => {
      if(props.isEditable) return 'rgba(255, 255, 255, 0.5)';
      else return '';
    },
    borderWidth: (props: Props) => {
      if(props.isEditable) return '2px';
      else return '0px';
    },
    cursor: (props: Props) => {
      if(props.isEditable) return 'move';
      else return '';
    },
    borderStyle: 'dashed',
    borderColor: theme.colors.black,
    borderRadius: '14px',
    '& > span.react-resizable-handle': {
      padding: '0 !important',
      '&.react-resizable-handle-s': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'ns-resize',
        bottom: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '5px',
      },
      '&.react-resizable-handle-w': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'ew-resize',
        top: '0',
        bottom: '0',
        left: '0',
        width: '5px',
        height: '100%',
      },
      '&.react-resizable-handle-e': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'ew-resize',
        top: '0',
        bottom: '0',
        right: '0',
        width: '5px',
        height: '100%',
      },
      '&.react-resizable-handle-n': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'ns-resize',
        top: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '5px',
      },
      '&.react-resizable-handle-sw': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'nesw-resize',
        bottom: '0',
        left: '0',
        width: '5px',
        height: '5px',
      },
      '&.react-resizable-handle-nw': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'nwse-resize',
        top: '0',
        left: '0',
        width: '5px',
        height: '5px',
      },
      '&.react-resizable-handle-se': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'nwse-resize',
        bottom: '0',
        right: '0',
        width: '5px',
        height: '5px',
      },
      '&.react-resizable-handle-ne': {
        visibility: (props: Props) => {
          if(props.isEditable) return 'visible';
          else return 'hidden';
        },
        position: 'absolute',
        cursor: 'nesw-resize',
        top: '0',
        right: '0',
        width: '5px',
        height: '5px',
      },
    },
  },
  widgetFrame: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    borderRadius: '14px',
    overflow: 'hidden',
    width: '100%',
    maxHeight: '100%',
  },
  widgetFrameHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 16px',
    minHeight: '52px',
    height: '52px',
    backgroundColor: theme.colors.grey[100],
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      alignItems: 'center',
      fontSize: '16px',
      fontWeight: '500',
      '& > span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px',
        backgroundColor: theme.colors.primaryBlue[500],
        color: theme.colors.white,
        borderRadius: '100%',
        '& > svg': {
          width: '14px',
          height: '14px',
        },
      },
    },
    '& > div': {
      marginLeft: 'auto',
      '& > button': {
        '& > svg': {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
  widgetFrameContent: {
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
  },
  addButton: {
    order: '1',
    '& > span.MuiButton-startIcon': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
  sizeFull: {
    width: '100%',
    flex: '0 0 100%',
  },
  sizeHalf: {
    width: 'calc((100% / 2) - (16px / 2))',
    flex: '0 0 calc((100% / 2) - (16px / 2))',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  sizeThird: {
    width: 'calc((100% / 3) - (16px / 1.45))',
    flex: '0 0 calc((100% / 3) - (16px / 1.45))',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
  sizeQuarter: {
    width: 'calc((100% / 4) - (16px / 1.3))',
    flex: '0 0 calc((100% / 4) - (16px / 1.3))',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flex: '0 0 100%',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const dashboardData = useAppSelector((state: any) => state.dashboard);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];
  const defaultUserWidgets = getUserSetting(userData.userSettings, "dashboard", ["widgets"]);
  const userWidgets = dashboardData.widgets;

  const isSaving = useRef(false);
  const dashboardRef: any = useRef(null);

  const classes: any = useStyles({
    isEditable: layoutData.isDashboardEditable,
  });

  const [state, setState] = useStates({
    width: 0,
    breakpoint: "xs",
  });

  const getWidgetData = (type: any) => {
    const widgetExist = widgets.filter((item: any) => item.key === type).length = 1;
    if(widgetExist) {
      return widgets.find((item: any) => item.key === type);
    } else {
      return [];
    }
  };

  const widgetTypes: any = {
    PostForm: PostForm,
    LastPost: LastPost,
    TodayExcuseNotes: TodayExcuseNotes,
    TodaySubstitutions: TodaySubstitutions,
    TodayAttendance: TodayAttendance,
  };

  const widgetTitles: any = {
    PostForm: PostFormTitle,
    LastPost: LastPostTitle,
    TodayExcuseNotes: TodayExcuseNotesTitle,
    TodaySubstitutions: TodaySubstitutionsTitle,
    TodayAttendance: TodayAttendanceTitle,
  };

  const getWidgetType = (type: any) => {
    if(widgetTypes[type]) {
      return widgetTypes[type];
    } else {
      return null;
    }
  };

  const getWidgetTitle = (type: any) => {
    if(widgetTitles[type]) {
      return widgetTitles[type];
    } else {
      return null;
    }
  };

  const handleEditWidget = (e: any, key: any) => {
    e.stopPropagation();
    e.preventDefault();
    const settings = {
      isOpen: true,
      widgetKey: key,
    };
    dispatch(setDashboardHandleWidgetModal(settings));
  };

  const handleRemoveWidget = (e: any, key: any) => {
    e.stopPropagation();
    e.preventDefault();
    const onAccept = () => {
      const newWidgets = recalculateKeys(userWidgets.filter((item: any) => item.key !== key));
      dispatch(setDashboardWidgets(newWidgets));
    };
    const settings = {
      isOpen: true,
      title: t('remove_widget'),
      content: t('remove_widget_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleLayoutChange = (layout: any) => {
    const breakpoint = state.breakpoint;
    const newUserWidgetsData = userWidgets.map((item: any) => {
      const widgetLayoutData = layout.filter((subItem: any) => subItem.i === `k_${item.key}`).length === 1 ? layout.find((subItem: any) => subItem.i === `k_${item.key}`) : []; 
      const size = {
        [breakpoint]: {
          width: widgetLayoutData.w,
          height: widgetLayoutData.h,
          minWidth: widgetLayoutData.minW,
          minHeight: widgetLayoutData.minH,
          maxWidth: widgetLayoutData.maxW,
          maxHeight: widgetLayoutData.maxH
        }
      };
      const position = {
        [breakpoint]: {
          x: widgetLayoutData.x,
          y: widgetLayoutData.y,
        }
      };
      const sizes = {...item.size, ...size};
      const positions = {...item.position, ...position};
      return {...item, position: positions, size: sizes};
    });
    console.log("new", newUserWidgetsData);
    dispatch(setDashboardWidgets(newUserWidgetsData));
  };

  const handleUpdateDashboard = useCallback(async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      const oldValue = defaultUserWidgets;
      const newValue = updateUserSetting(userData.userSettings, "dashboard", ["widgets"], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "dashboard", ["widgets"], value);
      if(result) {
        isSaving.current = false;
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "dashboard", ["widgets"], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("dashboard_not_saved"), "error");
        isSaving.current = false;
      }
    }
  }, [dispatch, defaultUserWidgets,  userData]);

  useEffect(() => {
    dispatch(setDashboardWidgets(defaultUserWidgets));
    return () => {
      dispatch(setIsDashboardEditable(false));
      dispatch(setDashboardWidgets([]));
    }
  }, [dispatch, userData.userSettings, defaultUserWidgets], []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleUpdateDashboard(userWidgets);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [handleUpdateDashboard, userWidgets], [userWidgets]);

  const handleBreakpointChange = (newBreakpoint: any, newCols: any) => {
    console.log("breakpoint", newBreakpoint);
    console.log("newCols", newCols);
  };

  useEffect(() => {
    if(dashboardRef && dashboardRef.current) {
      setState("width", dashboardRef.current.clientWidth);
    }
  }, [setState], [browserData.width, browserData.height, dashboardRef.current]);

  return (userWidgets && userWidgets.length > 0) ? (
    <div className={classes.dashboardPage} ref={dashboardRef}>
      <ResponsiveGridLayout
        className={classes.dashboard}
        breakpoints={{ xxxl: theme.breakpoints.dashboard.xxxl, xxl: theme.breakpoints.dashboard.xxl, xl: theme.breakpoints.dashboard.xl, lg: theme.breakpoints.dashboard.lg, md: theme.breakpoints.dashboard.md, sm: theme.breakpoints.dashboard.sm, xs: theme.breakpoints.dashboard.xs }}
        cols={{ xxxl: 12, xxl: 12, xl: 12, lg: 10, md: 8, sm: 6, xs: 6 }}
        width={state.width - 48}
        rowHeight={80}
        compactType={null}
        isDraggable={layoutData.isDashboardEditable}
        isResizable={layoutData.isDashboardEditable}
        draggableCancel='.nodrag'
        margin={[16, 16]}
        onBreakpointChange={handleBreakpointChange}
        onLayoutChange={handleLayoutChange}
      >
        {
          userWidgets.map((item: any, key: any) => {

            const breakpoint = state.breakpoint;
            const type = item.type;
            const widgetData: any = getWidgetData(type);
            const widgetType = getWidgetType(type);
            const widgetTitle = getWidgetTitle(type);
            const mergedData = {...widgetData, ...item};
            const gridData = {w: mergedData.size[breakpoint].width, h: mergedData.size[breakpoint].height, x: mergedData.position[breakpoint].x, y: mergedData.position[breakpoint].y, minW: widgetData.size[breakpoint].minWidth, minH: widgetData.size[breakpoint].minHeight, maxW: widgetData.size[breakpoint].maxWidth, maxH: widgetData.size[breakpoint].maxHeight, resizeHandles: availableHandles, isBounded: true};
            const settings = (mergedData.settings && mergedData.settings.length > 0) ? mergedData.settings.map((subItem: any) => { return {key: subItem.key, value: subItem.value}}) : [];

            return widgetType ? (
              <div className={classes.widgetFrameWrapper} key={`k_${key}`} data-grid={gridData}>
                <div className={classes.widgetFrame}>
                  <div className={classes.widgetFrameHeader}>
                    <span>
                      <span>
                        <SVG src={mergedData.icon}/>
                      </span>
                      {widgetTitle ? createElement(widgetTitle, { settings: settings }) : t(mergedData.title)}  
                    </span>
                    {
                      layoutData.isDashboardEditable ? (
                        <div className='nodrag'>
                          {
                            (mergedData.settings && mergedData.settings.length > 0) ? (
                              <IconButton tooltip={t('edit_widget')} onClick={(e: any) => handleEditWidget(e, mergedData.key)}>
                                <SVG src="cog"/>
                              </IconButton>
                            ) : null
                          }
                          <IconButton tooltip={t('remove_widget')} onClick={(e: any) => handleRemoveWidget(e, mergedData.key)}>
                            <SVG src="trash-outlined"/>
                          </IconButton>
                        </div>
                      ) : null
                    }
                  </div>
                  <div className={classes.widgetFrameContent}>
                    {createElement(widgetType, { settings: settings })}
                  </div>
                </div>
              </div>
            ) : null;
          })
        }
      </ResponsiveGridLayout>
    </div>
  ) : null;
};

export default PageTemplate;