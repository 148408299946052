import moment from '../../../../utils/moment';
import React, { useMemo } from 'react';
import { compareDates } from '../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';

interface Props {
  weekNumberMode?: boolean,
  isWeekNumber?: boolean,
  date?: any,
  dateRange?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.weekNumber': {
      borderRadius: '0%',
      '& span': {
        backgroundColor: '#ececec',
        color: theme.colors.black,
        fontWeight: 'normal',
      }
    }
  },
  date: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: (props: Props) => !props.isWeekNumber ?  '12px' : '11px',
    color: (props: Props) => !props.isWeekNumber ? theme.colors.black : '#BCBCBC',
    backgroundColor: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? '#F6F6F6' : theme.colors.white, 
    fontWeight: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? 'normal' : 'bold', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: (props: Props) => !props.isWeekNumber ? 'pointer' : 'auto',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '100%',
    },
    '&:hover::before': {
      border:  (props: Props) => !props.isWeekNumber && '1px solid ' + theme.colors.primaryBlue[500],
    },
    '&.today::before': {
      backgroundColor: '#E5F4FF',
    },
    '&.today': {
      color: theme.colors.black,
      fontWeight: 'bold',
    },
    '&.selected::before': {
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '&.selected': {
      color: theme.colors.white,
      fontWeight: 'bold',
    },
    '& p': {
      position: 'relative',
      zIndex: '2',
      marginBottom: '0',
      color: 'inherit',
    },
  },
}));

type DateItemType = {
  index?: any;
  weekNumberMode?: any;
  date?: any;
  setDate?: any;
  currentSelectedDate?: any;
  dateRange?: any;
  handleClose: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, weekNumberMode, date, setDate, currentSelectedDate, dateRange, handleClose}) => {
  let isWeekNumber = useMemo(() => index % 8 === 0, [index]);
  if(!weekNumberMode) isWeekNumber = false;
  
  const classes = useStyles({ weekNumberMode, isWeekNumber, date, dateRange });

  const handleClick = (e: any) => {
    if (!isWeekNumber){
      setDate(date);
      handleClose();
    }
  };

  return (
    <div className={`${classes.root} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && 'selected'}}`} onClick={handleClick} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      <span className={`${classes.date} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(compareDates(moment(), date)) && !isWeekNumber && 'today'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && !isWeekNumber &&  'selected'}`}>
        <p onClick={handleClick} data-clarity-unmask="true">{(weekNumberMode && isWeekNumber) ? date.week() : date.date()}</p>
      </span>
    </div>
  );
};

export default DateItem;