import React from 'react';

const useState = (defaultState?: any) => {
  const initState: any = defaultState !== undefined ? defaultState : undefined;
  const [state, setState]: any = React.useState(initState);
  return [state, setState];
};

const useStates = (defaultState?: any) => {
  const initState: any = typeof defaultState === "object" ? defaultState : {};
  const [state, updateState]: any = React.useState(initState);

  const setState = (key: string, value: any) => {
    updateState((prev: any) => ({...prev, [key]: value}));
  };

  const setStates = (items: any) => {
    Object.keys(items).forEach((item: any) => {
      updateState((prev: any) => ({...prev, [item]: items[item]}));
    });
  };
  
  return [state, setState, setStates];
};

export {
  useState,
  useStates
};