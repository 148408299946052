const minLength = 8;
const lowerCaseLetters = /[a-z]/g;
const upperCaseLetters = /[A-Z]/g;
const numbers = /[0-9]/g;
const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line

const validatePassword = (password: any) => {
  const isValidate = password.length >= minLength && password.match(lowerCaseLetters) && password.match(upperCaseLetters) && password.match(numbers);
  return isValidate;
};

const validateEmail = (email: any) => {
  const isValidate = email.toLowerCase().match(emailRegex);
  return isValidate;
};

const getPasswordWeakness = (password: any) => {
  let weakness: any = [];
  if(password.length < minLength) {
    weakness = [...weakness, "length"];
  }
  if(!password.match(lowerCaseLetters)) {
    weakness = [...weakness, "lowercase"];
  }
  if(!password.match(upperCaseLetters)) {
    weakness = [...weakness, "uppercase"];
  }
  if(!password.match(numbers)) {
    weakness = [...weakness, "number"];
  }
  return weakness;
};

export {
  validatePassword,
  validateEmail,
  getPasswordWeakness
};