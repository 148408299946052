import IconButton from '../IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  closeButton: {
    padding: '10px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '16px',
      height: '16px',
    },
    '&:disabled': {
      '& > svg': {
        color: theme.colors.grey[75],
      }
    },
  },
}));

type CloseButtonType = {
  className?: any;
  onClick?: any;
  dataCy?: string;
  disabled?: boolean;
};

const CloseButton: React.FunctionComponent<CloseButtonType> = ({className, onClick, dataCy, disabled}) => {
  
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <IconButton className={`${classes.closeButton} ${className}`} onClick={onClick} disabled={disabled} tooltip={t('Zavřít')} tooltipPosition='bottom' dataCy={dataCy}>
      <SVG src="close"/>
    </IconButton>
  );
};

export default CloseButton;