import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import config from '../../../constants/config';
import React, { useEffect, useRef, useState } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { arrayBufferToBase64, base64ToArrayBuffer, blobToDataUrl, formatFileSize } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { setPhotoChangeModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  fileItem: {
    margin: "10px 10px",
  },
  wrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    maxWidth: '80px',
  },
  imageWrapper: {
    borderRadius: "10px",
    maxWidth: "80px",
    width: "fit-content",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  captionName: {
    maxWidth: '80px',
    fontSize: "13px",
    lineHeight: '13px',
    minHeight: '16px',
    height: 'fit-content',
    maxHeight: '26px',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    marginTop: '10px',
    marginBottom: '0',
  },
  captionSize: {
    fontSize: "10px",
    color: theme.colors.grey[550],
    marginTop: '10px',
    textAlign: 'center',
    maxWidth: '80px',
    width: '100%',
    display: 'block',
  },
  editButton: {
    position: "absolute",
    bottom: "-10px",
    right: "-10px",
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '100%',
    width: '16px',
    height: '16px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    zIndex: '3',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& svg': {
      width: '12px',
      height: '12px',
      color: theme.colors.white,
    }
  },
  deleteButton: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '100%',
    width: '16px',
    height: '16px',
    padding: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    zIndex: '3',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& svg': {
      width: '12px',
      height: '12px',
      color: theme.colors.white,
    }
  },
  media: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px',
    height: '80px',
    '&.file': {
      backgroundColor: theme.colors.grey[350],
      color: '#888FAE',
      '& > svg': {
        width: '64px',
        height: '64px',
      },
    },
  },
  photo: {
    width: 'auto',
    height: '80px',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  photoResponsive: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  videoWrapper: {
    width: '80px',
    height: '80px',
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    zIndex: '1',  
  },
  video: {
    width: 'auto',
    height: '80px',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  videoResponsive: {
    width: 'auto',
    height: '80px',
    maxHeight: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  videoOverlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.65)',
    color: theme.colors.white,
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.65)',
    color: theme.colors.white,
    zIndex: '2',
  },
  warningBar: {
    backgroundColor: theme.colors.systemOrange[500],
    color: theme.colors.white,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',  
    padding: '0.5rem 1rem',
    borderRadius: '14px',
    marginTop: '1rem',
    fontSize: '14px',
    position: 'relative',
    overflow: 'hidden',
  },
}));

type FileItemType = {
  fileInfo: any;
  handleEditFile?: any;
  handleRemoveFile?: any;
  showCaption?: boolean;
  showSize?: boolean;
  disabled?: boolean;
  className?: any;
  mediaLimit?: 'calendar' | 'communication' | 'timeline';
};

const FileItem: React.FunctionComponent<FileItemType> = ({ fileInfo, handleEditFile, handleRemoveFile, showCaption = true, showSize = true, disabled, className, mediaLimit = "timeline" }) => {
  
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const canvasRef: any = useRef(null);
  const videoRef: any = useRef(null);
  const [videoLength, setVideoLength]: any = useState(null);
  const { t } = useTranslation();
  const userData = useAppSelector((state: any) => state.user);
  const [fileBlob, setFileBlob]: any = useState(null);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;

  const videoMaxLength = userData.mediaLimits[mediaLimit].videoTimeLimit;

  const handleDelete = () => {
    handleRemoveFile();
  };

  const handleEditImage = (key: any) => {
    if(fileInfo.status === "hosted") {
      timelineService && timelineService.downloadFile(fileInfo.mediaID).then((result: any) => {
        if(result) {
          if(result.data) {
            const base64 = arrayBufferToBase64(result.data);
            const file = {...fileInfo, base64: base64, raw: base64ToArrayBuffer(base64)};
            const handleOnSave = (photoData: any) => {
              handleEditFile(key, photoData);
            };
            const settings = {
              isOpen: true,
              photo: file,
              onSave: handleOnSave,
              title: t('photo_crop'),
              aspect: undefined,
            };
            dispatch(setPhotoChangeModal(settings));
          }
        }
      });
    } else {
      const file = {...fileInfo, base64: fileBlob.split(",")[1], raw: base64ToArrayBuffer(fileBlob.split(",")[1])};
      const handleOnSave = (photoData: any) => {
        handleEditFile(key, photoData);
      };
      const settings = {
        isOpen: true,
        photo: file,
        onSave: handleOnSave,
        title: t('photo_crop'),
        aspect: undefined,
      };
      dispatch(setPhotoChangeModal(settings));
    }
  };
  
  useEffect(() => {
    setTimeout(function() {
      let video: any = videoRef.current;
      if(video != null) {
        if(video.duration === Infinity) {
          video.currentTime = 1e101;
          video.ontimeupdate = function () {
              this.ontimeupdate = () => {
                return;
              }
              video.currentTime = 0;
              setVideoLength(video.duration);
              return;
            }
        } else {
          setVideoLength(video.duration);
        }
      }
    }, 1000);
  }, [videoRef]);

  useEffect(() => {
    if(canvasRef.current !== null && fileBlob !== null) {
      const ctx = canvasRef.current.getContext("2d");
      let image = new Image();
      image.onload = function() {
        canvasRef.current.setAttribute("width",image.width + 'px');
        canvasRef.current.setAttribute("height",image.height + 'px');
        ctx.drawImage(image, 0, 0, image.width, image.height);
      };
      image.src = fileBlob;
    }
  }, [fileBlob, canvasRef]);

  useEffect(() => {
    if(fileInfo.blob) {
      blobToDataUrl(fileInfo.blob, setFileBlob);
    }
  }, [fileInfo.blob]);
    
  return (
    <div className={`${classes.fileItem} ${className ? className : null}`}>
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          {
            fileInfo.blob ? (
                <div className={`${classes.media} ${config.UPLOAD_FORMATS_PHOTOS.indexOf(fileInfo.type) !== -1 ? 'photo' : null} ${config.UPLOAD_FORMATS_FILES.indexOf(fileInfo.type) !== -1 ? 'file' : null} ${config.UPLOAD_FORMATS_VIDEOS.indexOf(fileInfo.type) !== -1 ? 'video' : null}`}>
                  {
                    (fileInfo.status !== "ready" && fileInfo.status !== "hosted") ? (
                      <div className={classes.overlay}>
                        {
                          fileInfo.status === "uploading" ? (
                            <CloudQueueIcon/> 
                          ) : null
                        }
                        {
                          fileInfo.status === "uploaded" ? (
                            <CloudDoneIcon/>  
                          ) : null
                        }
                      </div>
                    ) : null
                  }                
                  {
                    config.UPLOAD_FORMATS_PHOTOS.indexOf(fileInfo.type) !== -1 ? (
                      <canvas className={classes.photoResponsive} ref={canvasRef}/>
                    ) : null
                  }
                  {
                    config.UPLOAD_FORMATS_FILES.indexOf(fileInfo.type) !== -1 ? (
                      <SVG src={`document-${(fileInfo.name).split('.').pop()}`} children={<SVG src="file"/>}/>                 
                    ) : null
                  }
                  {
                    config.UPLOAD_FORMATS_VIDEOS.indexOf(fileInfo.type) !== -1 ? (
                      <div className={classes.videoWrapper}>
                        {
                          fileBlob !== null ? (
                            <video className={classes.videoResponsive} ref={videoRef} preload="metadata">
                              <source src={fileBlob} type={fileInfo.type}/>
                            </video>
                          ) : null
                        }
                        {
                          (!fileInfo.uploading && !fileInfo.uploaded) ? (
                            <div className={classes.videoOverlay}>
                              <SVG src="play"/>
                            </div>
                          ) : null
                        }
                      </div>               
                    ) : null
                  }
                </div>
            ) : (
                <div className={`${classes.media} ${config.UPLOAD_FORMATS_PHOTOS.indexOf(fileInfo.type) !== -1 ? 'photo' : null} ${config.UPLOAD_FORMATS_FILES.indexOf(fileInfo.type) !== -1 ? 'file' : null} ${config.UPLOAD_FORMATS_VIDEOS.indexOf(fileInfo.type) !== -1 ? 'video' : null}`}> 
                  {
                    (fileInfo.status !== "ready" && fileInfo.status !== "hosted") ? (
                      <div className={classes.overlay}>
                        {
                          fileInfo.status === "uploading" ? (
                            <CloudQueueIcon/> 
                          ) : null
                        }
                        {
                          fileInfo.status === "uploaded" ? (
                            <CloudDoneIcon/>  
                          ) : null
                        }
                      </div>
                    ) : null
                  }        
                  {
                    config.UPLOAD_FORMATS_PHOTOS.indexOf(fileInfo.type) !== -1 ? (
                      <AuthenticatedImage className={classes.photo} thumbLink={fileInfo.thumbLink} isResponsive={false} />
                    ) : config.UPLOAD_FORMATS_FILES.indexOf(fileInfo.type) !== -1 ? (
                      <SVG src={`document-${(fileInfo.name).split('.').pop()}`} children={<SVG src="file"/>}/>
                    ) : config.UPLOAD_FORMATS_VIDEOS.indexOf(fileInfo.type) !== -1 ? (
                      <div className={classes.videoWrapper}>
                        <AuthenticatedImage className={classes.photo} thumbLink={fileInfo.thumbLink} />
                        {
                          !fileInfo.uploaded ? (
                            <div className={classes.videoOverlay}>
                              <SVG src="play"/>
                            </div>
                          ) : null
                        }
                      </div>               
                    ) : null
                  }
                </div>
            )
          }
        </div> 
        {
          (config.UPLOAD_FORMATS_PHOTOS.indexOf(fileInfo.type) !== -1 && !disabled && handleEditFile) ? (
            <div className={classes.editButton} onClick={handleEditImage}>
              <SVG src="pencil"/>
            </div>
          ) : null
        }                                  
        {
          (!disabled && handleRemoveFile) ? (
            <div className={classes.deleteButton} onClick={handleDelete}>
              <SVG src="close"/>
            </div>
          ) : null
        }
      </div>
      {
        showCaption ? (
          <Clamp withTooltip lines={2}>
            <span className={classes.captionName}>{fileInfo.name}</span>
          </Clamp>  
        ) : null
      }
      {
        (fileInfo.size && fileInfo.size > 0 && showSize) ? (
          <span className={classes.captionSize}>{formatFileSize(fileInfo.size)}</span>
        ) : null
      }
      {
        (Math.round(videoLength) > videoMaxLength) ? (
          <div className={classes.warningBar}>
            {t('video_is_too_long', {timeLimit: videoMaxLength})}.
          </div>
        ) : null
      }
    </div>
  );
};

export default FileItem;