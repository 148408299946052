import config from 'src/constants/config';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { getActiveFilters } from 'src/utils/useFunctions';
import { setGalleryZipSelected, setGalleryZipState } from 'src/store/actions/gallery.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const Gallery: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtersData = useAppSelector((state: any) => state.filters);
  const galleryData = useAppSelector((state: any) => state.gallery);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const filtersActiveCount = getActiveFilters(filtersData.filterParams);
  const selected = galleryData.selected;
  const zipState = galleryData.zipState;
  const canDownload = selected.length > 1 && selected.length <= config.GALLERY_DOWNLOADS_LIMIT;

  const handleGalleryFiltersClick = () => {
    dispatch(setIsFiltersVisible(!layoutData.isFiltersVisible));
  };

  const handleGalleryDownloadAllClick = () => {
    dispatch(setGalleryZipSelected(selected));
    dispatch(setGalleryZipState("start"));
  };

  return (
    <>
      {
        selected.length > 0 ? (
          <>
            <Tooltip title={!canDownload ? t('gallery_download_zip_limit', {limit: config.GALLERY_DOWNLOADS_LIMIT}) : null}>
              <span>
                <TopBarButton icon={<SVG src="download"/>} disabled={zipState === "running" || !canDownload} onClick={handleGalleryDownloadAllClick} dataCy="topBarButtonDowloadAll" tooltip={t('gallery_download_all')}>
                  {
                    selected.length === 0 ? null : (
                      <span>{selected.length}</span>
                    )
                  }
                </TopBarButton>
              </span>
            </Tooltip>
          </>
        ) : null
      }
      <TopBarButton className={layoutData.isFiltersVisible ? 'active' : null} icon={<SVG src="filter"/>} disabled={!userData.membership.active} onClick={handleGalleryFiltersClick} dataCy="topBarButtonFilter" tooltip={t('filters')}>
        {
          filtersActiveCount === 0 ? null : (
            <span>{filtersActiveCount}</span>
          )
        }
      </TopBarButton>
    </>
  );
}

export default Gallery;