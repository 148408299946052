import Label from '../Label';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getElementID } from 'src/utils/useUUID';
import { isCypress } from 'src/utils/useCypress';
import { Switch as MuiSwitch } from '@mui/base/Switch';

interface Props {
  checked: any;
  disabled: any;
  size: any;
};

const useStyles = createUseStyles((theme: any) => ({
  switchWrapper: {
    display: "flex",
    alignItems: "center",
    gap: '10px',
    cursor: (props: Props) => {
      if(props.disabled) return 'not-allowed';
      else return 'pointer';
    },
  },
  switchLabel: {
    fontSize: "12px",
    color: theme.colors.black,
    cursor: (props: Props) => {
      if(props.disabled) return 'not-allowed';
      else return 'pointer';
    },
  },
  switchRoot: {
    fontSize: '0',
    position: 'relative',
    display: 'inline-block',
    width: (props: Props) => {
      if(props.size === 'small') return '28px';
      else return '40px';
    },
    height: (props: Props) => {
      if(props.size === 'small') return '16px';
      else return '24px';
    },
    borderRadius: '16px',
    backgroundColor: (props: Props) => {
      if(props.checked) return theme.colors.primaryBlue[500];
      else return theme.colors.white;
    },
    opacity: (props: Props) => {
      if(props.disabled) {
        if(props.checked) return '0.4';
        else return '0.7';
      } else return '1';
    },
    cursor: (props: Props) => {
      if(props.disabled) return 'not-allowed';
      else return 'pointer';
    },
  },
  switchInput: {
    cursor: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    opacity: '0',
    zIndex: '1',
    margin: '0',
  },
  switchThumb: {
    display: 'block',
    width: (props: Props) => {
      if(props.size === 'small') return '12px';
      else return '16px';
    },
    height: (props: Props) => {
      if(props.size === 'small') return '12px';
      else return '16px';
    },
    top: (props: Props) => {
      if(props.size === 'small') return '2px';
      else return '4px';
    },
    left: (props: Props) => {
      if(props.checked) {
        if(props.size === 'small') return '14px';
        else return '20px';
      }
      else {
        if(props.size === 'small') return '2px';
        else return '4px';
      }
    },
    boxShadow: theme.shadows[0],
    borderRadius: '16px',
    backgroundColor: (props: Props) => {
      if(props.checked) return theme.colors.white;
      else if(props.disabled) return theme.colors.grey[500];
      else return theme.colors.grey[700];
    },
    position: 'relative',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '120ms',
  },
  switchTrack: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: (props: Props) => {
      if(props.checked) return theme.colors.primaryBlue[500];
      else if(props.disabled) return theme.colors.grey[500];
      else return theme.colors.grey[700];
    },
    borderRadius: '16px',
    opacity: (props: Props) => {
      if(props.disabled) return '0.5';
      else return '1';
    },
    backgroundColor: (props: Props) => {
      if(props.checked) return theme.colors.primaryBlue[500];
      else if(props.disabled) return theme.colors.white;
      else return theme.colors.white;
    },
  },
}));

type SwitchType = {
  name?: any;
  id?: any;
  checked: boolean;
  onChange?: any;
  onClick? :any;
  label?: any;
  labelLeft?: any;
  labelTop?: any;
  disabled?: any;
  className?: any;
  size?: 'small' | 'large',
  dataCy?: any;
};

const Switch: React.FunctionComponent<SwitchType> = ({name, id = getElementID(), checked, onChange, onClick, label, labelLeft, labelTop, disabled, className, size = "small", dataCy}) => {

  const classes = useStyles({
    checked: checked,
    disabled: disabled,
    size: size,
  });

  const handleOnChange = (e: any) => {
    if(disabled) return;
    if(name) {
      onChange(name, !checked, e);
    } else {
      onChange(!checked, e);
    }
  };

  const handleOnClick = (e: any) => {
    if(disabled) return;
    if(onClick) onClick(e);
  };

  const slotProps = {
    root: {
      className: classes.switchRoot,
    },
    input: {
      id: id,
      className: classes.switchInput,
      checked: checked ? true : false,
      disabled: disabled,
      'data-cy': (dataCy && isCypress()) ? `${dataCy}Input` : null,
    },
    thumb: {
      className: classes.switchThumb,
    },
    track: {
      className: classes.switchTrack,
    },
  };
  
  return (
    <>
      {
        labelTop ? (
          <Label>{labelTop}</Label>
        ) : null
      }
      <div className={`${classes.switchWrapper} ${className ? className : null}`} onClick={handleOnClick} data-cy={isCypress() ? `${dataCy}Wrapper` : null}>
        {
          labelLeft ? (
            <label htmlFor={id} className={classes.switchLabel}>
              {labelLeft}
            </label>
          ) : null
        }
        <MuiSwitch
          className={classes.switchRoot}
          checked={checked}
          onChange={handleOnChange}
          disabled={disabled}
          slotProps={slotProps}
          data-cy={isCypress() ? dataCy : null}
        />
        {
          label ? (
            <label htmlFor={id} className={classes.switchLabel}>
              {label}
            </label>
          ) : null
        }
      </div>
    </>
  );
}

export default Switch;