import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { removeDuplicatesJSON } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly?: any;
  disabled?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  usersInputWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  usersTopWrapper: {
    display: 'flex',
    gap: '10px',
    marginTop: '16px',
    marginBottom: '7px',
    alignItems: 'center',
    '&:empty': {
      display: 'none',
    },
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  usersInput: {
    position: "relative",
    width: "calc(100% - 22px)",
    height: '32px',
    minHeight: "20px",
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: "10px",
    backgroundColor: (props: Props) => {
      if(props.disabled) return theme.colors.grey[75];
      else return theme.colors.white;
    },
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[560];
      else return '';
    },
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "4px 10px",
    cursor: (props: Props) => {
      if(props.readonly || props.disabled) return "auto";
      else return "pointer";
    },
    maxHeight: '200px',
    overflow: 'auto',
    gap: '14px',
    '&.disabled': {
      backgroundColor: theme.colors.grey[75],
      color: theme.colors.grey[560],
      cursor: 'auto',
    },
    '& > span': {
      fontSize: '14px',
    }
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    padding: "5px",
    height: "32px",
    backgroundColor: "#E1F1FF !important",
    color: theme.colors.primaryBlue[500] + ' !important',
    fontWeight: "bold",
    position: 'relative',
    cursor: 'pointer',
    gap: '5px',
    '& > span': {
      maxWidth: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '2px',
    },
    '& > div': {
      width: '18px',
      height: '18px',
      borderRadius: '6px',
      backgroundColor: theme.colors.white,
      position: 'relative',
    },
    '& > svg': {
      width: '20px',
      height:'20px',
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: '50%',
    right: '-16px',
    transform: 'translateY(-50%)',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    transition: 'background-color 0.25s',
    width: '22px',
    height: '22px',
    minWidth: '22px',
    minHeight: '22px',
    maxWidth: '22px',
    maxHeight: '22px',
    padding: '0',
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& > svg': {
      width: '10px',
      height: '10px',
    },
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[560],
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      color: 'inherit',
    },
  },
  usersCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    height: 'fit-content',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '14px',
      height: '14px',
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '14px',
    }
  },
}));

type InputType = {
  label?: any;
  showSelectLabel?: boolean;
  showSelectLabelIcon?: boolean;
  selectLabel?: any;
  selectedUsers: any;
  setSelectedUsers: any;
  isShowUsersCount?: any;
  isDeletable?: boolean;
  dataCy?: any;
  onClick?: any;
  disabled?: boolean;
  readonly?: boolean;
  className?: any;
  classNameWrapper?: any;
  classNameChip?: any;
};

const UsersInput: React.FunctionComponent<InputType> = ({label, showSelectLabel = true, showSelectLabelIcon = true, selectLabel, selectedUsers, setSelectedUsers, isShowUsersCount, isDeletable = true, onClick, disabled = false, readonly = false, className, classNameWrapper, classNameChip}) => {
  
  const classes = useStyles({
    readonly: readonly,
    disabled: disabled,
  });

  const dataData = useAppSelector((state: any) => state.data);
  const { t } = useTranslation();

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const handleDelete = (userID: any) => {
    const newUsers = selectedUsers.filter((theUser: any) => theUser.userID !== userID);
    setSelectedUsers(newUsers);
  };

  return (
    <div className={`${classes.usersInputWrapper} ${classNameWrapper ? classNameWrapper : null}`}>
      <div className={classes.usersTopWrapper}>
        {
          label ? (
            <label>
              {label}
            </label>
          ) : null
        }
        {
          (isShowUsersCount !== null && isShowUsersCount !== undefined) ? (
            <span className={classes.usersCount}>
              <SVG src="users"/>
              <span data-clarity-unmask="true">{isShowUsersCount === "unique" ? removeDuplicatesJSON(selectedUsers.map((item: any) => { return { userID: item.userID }; })).length : selectedUsers.length}</span>
            </span>
          ) : null
        }
      </div>
      <div className={`${classes.usersInput} ${className ? className : null}`} onClick={(disabled || readonly) ? null : onClick} data-cy={isCypress() ? "employeesInput" : null}>
        {
          removeDuplicatesJSON(selectedUsers.map((item: any) => { return { userID: item.userID }; })).length > 0 ? removeDuplicatesJSON(selectedUsers.map((item: any) => { return { userID: item.userID }; })).map((user: any, key: any) => (
            <Chip
              className={`${classes.chip} ${classNameChip ? classNameChip : null}`}
              size="small"
              key={`k_${key}`}
              icon={(<><AuthenticatedImage thumbLink={getUserData(user.userID).photo.thumbLink}/></>)}
              label={getUserData(user.userID).firstname + ' ' + getUserData(user.userID).surname}
              onDelete={() => handleDelete(user.userID)}
              color="primary"
              disabled={disabled}
              deleteIcon={(!disabled && !readonly && isDeletable) ? (
                <Button className={classes.deleteIcon}>
                  <SVG src="close"/>
                </Button>
              ) : (
                <></>
              )}
            />
          )) : (
            <>
              {
                showSelectLabel ? (
                  <span className={classes.placeholder}>
                    {
                      showSelectLabelIcon ? (
                        <SVG src="users"/>
                      ) : null
                    }
                    {selectLabel ? selectLabel : t('select_users')}
                  </span>
                ) : null
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default UsersInput;