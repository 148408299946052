import ClassCard from '../../Cards/ClassCard';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setClassModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    maxWidth: '100vw',
    maxHeight: '90vh',
    overflow: "visible",
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    backgroundColor: theme.colors.white,
    zIndex: '2',
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  wrapper: {
    width: "500px",
    maxWidth: 'calc(90vw - 20px)',
    overflow: "auto",
    padding: '10px',
    maxHeight: 'calc(90vh - 40px)',
  },
}));

const ClassDetailModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const classID = modalsData.classModal.classID;
  
  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      classID: null,
    };
    dispatch(setClassModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "classDetailModal" : null}>
        <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
        <div className={classes.wrapper}>
          <ClassCard classID={classID}/>
        </div>
      </div>
    </Modal>
  );
};

export default ClassDetailModal;