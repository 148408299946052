import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { setFirebaseNotificationsDrawer } from 'src/store/actions/drawers.actions';

const FirebaseNotifications: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const drawersData = useAppSelector((state: any) => state.drawers);
  const firebaseNotificationList = useAppSelector((state: any) => state.firebasenotification.list);
  const webAppNotificationStatus = useAppSelector((state: any) => state.firebasenotification.permission);

  const notificationsCount = firebaseNotificationList.length;
  const isOpen = drawersData.firebaseNotificationsDrawer.isOpen;

  const handleFirebaseNotificationsClick = () => {
    const settings = {
      isOpen: !isOpen,
      position: 'right',
    };
    dispatch(setFirebaseNotificationsDrawer(settings));
  };

  return (
    <>
      <TopBarButton className={isOpen ? 'active' : ''} icon={<SVG src={webAppNotificationStatus === 'granted' ? "bell" : "bell-slash"}/>} onClick={handleFirebaseNotificationsClick} dataCy="topBarButtonFirebaseNotifications" tooltip={t('notifications')} disabled={webAppNotificationStatus !== 'granted'}>
      {
          (notificationsCount !== 0 && webAppNotificationStatus === 'granted') ? (
            <span>{notificationsCount}</span>
          ) : null
      }
      </TopBarButton>
    </>
  );
}

export default FirebaseNotifications;