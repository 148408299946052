import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from 'src/utils/dateFormat';
import Modal from '../../../utils/modal';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setCommunicationMessageViewedModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "400px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "10px",
    maxHeight: 'calc(100vh - 300px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  user: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '8px',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    textTransform: 'unset',
    fontSize: '14px',
    borderRadius: '14px',
    padding: '6px 8px',
    maxWidth: 'calc(100% - 16px)',
    '& > div': {
      '&:first-of-type': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '32px',
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        backgroundColor: theme.colors.grey[200],
        borderRadius: '12px',
        position: 'relative',
      },
    },
    '& > span': {
      '&:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        '& > span': {
          display: 'block',
          maxWidth: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& > p': {
          fontSize: '12px',
          color: theme.colors.grey[500],
          lineHeight: '12px',
        },
      },
    },
  },
  avatar: {
    position: 'relative',
    width: '46px',
    height: '46px',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: '12px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      minWidth: 'unset',
      maxWidth: 'unset',
      minHeight: 'unset',
      maxHeight: 'unset',
    },
  },
}));

const CommunicationMessageViewedModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);

  const users = modalsData.communicationMessageViewedModal.users;

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      users: null,
    };
    dispatch(setCommunicationMessageViewedModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "communicationMessageViewedModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p>{t('communication_message_read')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            users.map((item: any, key: any) => {

              const userData = getUserData(item.userID);
              const userLastSeenDate = item.lastSeenDate;

              return userData ? (
                <div className={classes.user} key={`k_${key}`}>
                  <AuthenticatedImage className={classes.avatar} thumbLink={userData.photo.thumbLink}/>
                  <span>
                    <span>{userData.displayName}</span>
                    <p>{DateFormat(userLastSeenDate, "timeline", languageData, t)}</p>
                  </span>
                </div>
              ) : null
            })
          }
        </div>
      </div>
    </Modal>
  );
};

export default CommunicationMessageViewedModal;