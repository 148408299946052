import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import Currency from 'react-currency-formatter';
import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setClubDetailModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  userRole: any;
  visibleForParent: any;
};

const useStyles = createUseStyles((theme: any) => ({
  clubCard: {
    display: 'flex',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: 'calc(100% - 48px)',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    padding: '24px',
    height: 'calc(140px - 48px)',
    width: 'calc(100% - 48px)',
    gap: '8px',
    borderBottomWidth: (props: Props) => {
      if(props.userRole === "director") return '3px';
      else return '';
    },
    borderBottomStyle: (props: Props) => {
      if(props.userRole === "director") return 'solid';
      else return '';
    },
    borderBottomColor: (props: Props) => {
      if(props.userRole === "director") {
        if(props.visibleForParent) return theme.colors.systemGreen[500];
        else return theme.colors.systemRed[500];
      }
      else return '';
    },
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    flex: '0 1 80%',
    width: '80%',
  },
  buttonBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '0 0 20%',
    '& > button': {
      color: theme.colors.primaryBlue[500],
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  contactsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0px 8px',
  },
  employeeImage: {
    width: '80px',
    height: '80px',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    position: 'relative',
    borderRadius: "12px",
  },
  clubTeacher: {
    fontSize: '12px',
    display: 'inline-flex',
    fontWeight: '500',
    color: theme.colors.grey[700],
  },
  clubPrice: {
    fontSize: '18px',
    display: 'block',
    fontWeight: '500',
    color: theme.colors.grey[700],
  },
  clubName: {
    fontSize: '16px',
    display: 'block',
    fontWeight: '600',
    color: theme.colors.black,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  childPhoto: {
    width: '16px',
    height: '16px',
    minWidth: '16px',
    minHeight: '16px',
    maxWidth: '16px',
    maxHeight: '16px', 
    backgroundColor: theme.colors.white,
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      '& > div': {
        position: 'relative',
      },
    },    
  },
  customWidth: {
    minWidth: "10px !important"
  },
  childrenCount: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '14px',
    gap: '4px',
    position: 'absolute',
    top: '12px',
    right: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
    '& > svg': {
      width: '16px',
      height: '16px',
      [theme.breakpoints.down('sm')]: {
        width: '11px',
        height: '11px',
      },
    },
  },
}));

type ItemsType = {
  data: any;
};

const ClubCard: React.FunctionComponent<ItemsType> = ({data}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration).configuration;
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const classes = useStyles({
    userRole: getUserRole(userData.userObject.roleType),
    visibleForParent: data.visibleForParent,
  });

  const currencyID = data.currencyID;
  const getCurrency = currencyID ? (configurationData.currencies.filter((currency: any) => currency.currencyID === currencyID).length === 0 ? [] : configurationData.currencies.find((currency: any) => currency.currencyID === currencyID)) : [];
  
  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const clubChildren = getUserRole(userData.userObject.roleType) === "parent" ? data.children.filter((item: any) => item.state !== 3 && getChildData(item.childID).length !== 0) : data.children.filter((item: any) => getChildData(item.childID).length !== 0);

  const childData = clubChildren.length === 1 ? clubChildren[0] : [];

  const handleViewDetail = () => {
    const settings = {
      isOpen: true,
      clubID: data.clubID,
    };
    dispatch(setClubDetailModal(settings));
  };

  return (
    <div className={classes.clubCard} data-cy={isCypress() ? 'clubCard' + data.clubID : null}>
      <div className={classes.infoBlock}>
        <span className={classes.clubTeacher}>{data.teacherName}</span>
        <span className={classes.clubName}>{data.name}</span>
        <span className={classes.clubPrice}>
          <Currency quantity={parseFloat(data.price)} currency={getCurrency.iso}/>  
        </span>
      </div>
      <div className={classes.buttonBlock}>
        <IconButton onClick={handleViewDetail} tooltip={t('club_detail')} tooltipMaxWidth={200}>
          <SVG src="info-circle-outlined"/>
        </IconButton>
      </div>
      {
        clubChildren.length > 0 ? (
          <span className={classes.childrenCount}>
            {
              clubChildren.length === 1 ? (
                <Tooltip title={getChildData(childData.childID).displayName} classes={{ tooltip: classes.customWidth }} arrow>
                  <div className={classes.childPhoto}>
                    <AuthenticatedImage thumbLink={getChildData(childData.childID).photo.thumbLink}/>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <SVG src="people"/> {clubChildren.length}
                </>
              )
            }
          </span>
        ) : null
      }
    </div>
  );
}

export default ClubCard;