import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Step, StepButton, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  showFooter?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  wizard: {
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
    padding: '16px',
    width: 'calc(100% - 32px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: 'calc(100% - 32px)',
  },
  stepper: {
    padding: '8px 0',
    width: '100%',
    minHeight: '58px',
    flex: '1 0 auto',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: (props: Props) => {
      if(props.showFooter) return 'calc(100% - 156px)';
      else return 'calc(100% - 90px)';
    },
    flex: '1 1 auto',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  stepButton: {
    '&:not(.Mui-disabled)': {
      '& .MuiStepIcon-root': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
        },
      },
      '& .MuiStepLabel-label': {
        '&:not(.Mui-active)': {
          color: theme.colors.grey[800],
          fontWeight: '500',
        },
      },
    },
    '& .MuiStepLabel-label': {
      '&.Mui-active': {
        fontWeight: '600',
      }
    },
    '& p': {
      fontSize: '12px',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '42px',
    alignItems: 'center',
    gap: '16px',
  },
}));

type DesktopType = {
  steps: any;
  currentStep: number;
  onStepChange: any;
  onComplete?: any;
  canNextStep?: boolean;
  showFooter?: boolean;
  footerContent: any;
  className?: any,
};

const Desktop: React.FunctionComponent<DesktopType> = ({ steps, currentStep = 1, onStepChange, onComplete, canNextStep, showFooter = true, footerContent, className }) => {
  
  const { t } = useTranslation();
  
  const totalSteps = steps.length;

  const activeStep = currentStep - 1;

  const isLastStep = activeStep === totalSteps - 1;

  const classes = useStyles({
    showFooter: showFooter,
  });

  const handleStep = (step: any) => {
    if(onStepChange) {
      onStepChange(step);
    }
  };

  const handleNext = () => {
    const step = currentStep + 1;
    handleStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;
    handleStep(step);
  };

  const getStepData = (key: any) => {
    return steps[key];
  };

  return (
    <div className={`${classes.wizard} ${className ? className : ''}`}>
      <Stepper className={classes.stepper} nonLinear activeStep={activeStep}>
        {
          steps.map((data: any, key: any) => (
            <Step key={`k_${key}`}>
              <StepButton className={classes.stepButton} optional={data.optional ? (<p>{t('optional')}</p>) : null} onClick={() => handleStep(key + 1)} disabled={key === activeStep || !getStepData(key).canSelect}>
                {data.label}
              </StepButton>
            </Step>
          ))
        }
      </Stepper>
      <div className={classes.contentWrapper}>
        {
          steps.map((data: any, key: any) => {
            if(key === activeStep) {
              return (
                <div className={classes.content} key={`k_${key}`}>{data.content}</div>
              );
            } else {
              return null;
            }
          })
        }
      </div>
      {
        showFooter ? (
          <div className={classes.footer}>
            {
              footerContent ? (
                <>
                  {footerContent} 
                </>
              ) : (
                <>
                  <NormalButton buttonType='secondary' disabled={activeStep === 0} onClick={handleBack}>
                    {t('previous_step')}
                  </NormalButton>
                  <NormalButton buttonType='primary' disabled={isLastStep} onClick={handleNext}>
                    {t('next_step')}
                  </NormalButton>
                </>
              )
            }
          </div>
        ) : null
      }
    </div>
  );
}

export default Desktop;
