const t = (key: any) => { return key };

const widgets = [
  {
    key: 'PostForm',
    title: t('create_post'),
    icon: 'pencil-outlined',
    size: {
      xxxl: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 4,
      },
      xxl: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 4,
      },
      xl: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 4,
      },
      lg: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 10,
        maxHeight: 4,
      },
      md: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 8,
        maxHeight: 4,
      },
      sm: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 6,
        maxHeight: 4,
      },
      xs: {
        width: 6,
        height: 2,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 6,
        maxHeight: 4,
      },
    },
    roles: [
      "teacher",
      "director"
    ],
  },
  {
    key: 'LastPost',
    title: t('last_post'),
    icon: 'post',
    size: {
      xxxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 12,
      },
      xxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 12,
      },
      xl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 12,
        maxHeight: 12,
      },
      lg: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 10,
        maxHeight: 12,
      },
      md: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 8,
        maxHeight: 10,
      },
      sm: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 6,
        maxHeight: 8,
      },
      xs: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 2,
        maxWidth: 6,
        maxHeight: 6,
      },
    },
    settings: [
      {
        key: "countOfPost",
        name: t("count_of_post"),
        value: 1,
        type: ["input", "number", "1", "10"],
      },
    ],
    roles: [
      "parent",
      "teacher",
      "director"
    ],
  },
  {
    key: 'TodayExcuseNotes',
    title: t('today_excuse_notes'),
    icon: 'calendar-excuse-notes',
    size: {
      xxxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      lg: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 10,
        maxHeight: 7,
      },
      md: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 8,
        maxHeight: 6,
      },
      sm: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
      xs: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
    },
    roles: [
      "parent",
      "teacher",
      "director"
    ],
  },
  {
    key: 'TodaySubstitutions',
    title: t('today_substitutions'),
    icon: 'calendar-substitutions',
    size: {
      xxxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      lg: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 10,
        maxHeight: 7,
      },
      md: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 8,
        maxHeight: 6,
      },
      sm: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
      xs: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
    },
    roles: [
      "parent",
      "teacher",
      "director"
    ],
  },
  {
    key: 'TodayAttendance',
    title: t('today_attendance'),
    icon: 'calendar-attendance',
    size: {
      xxxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xxl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      xl: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 12,
        maxHeight: 8,
      },
      lg: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 10,
        maxHeight: 7,
      },
      md: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 8,
        maxHeight: 6,
      },
      sm: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
      xs: {
        width: 6,
        height: 4,
        minWidth: 6,
        minHeight: 4,
        maxWidth: 6,
        maxHeight: 5,
      },
    },
    roles: [
      "parent",
      "teacher",
      "director"
    ],
  },
];

export default widgets;