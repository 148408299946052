import Input from '../Input';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  inputGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap',
    width: 'fit-content', 
    gap: '20px',
  },
  inputWrapper: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    gap: '5px',
  },
  limitInput: {
    width: '100px !important',
  },
}));

type PollInputGroupType = {
  items: any;
  showLimits: any;
  label?: any;
  onChange: any;
  className?: any;
  disabled?: boolean;
};

const PollInputGroup: React.FunctionComponent<PollInputGroupType> = ({items, showLimits, label, onChange, className, disabled}) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const handleOnChange = (value: any, type: any, key: any) => {
    let newItems: any;
    newItems = items.map((item: any, itemKey: any) => {
      if(itemKey === key) {
        const newValue = type === "limit" ? (value < 0 ? 0 : value) : value;
        return {...item, [type]: newValue};
      } else {
        return item;
      }
    });
    if(key !== 0 && type === "answer") {
      if(value.length === 0) {
        newItems = newItems.filter((item: any, itemKey: any) => itemKey !== key);
      }
    }
    if(key === (items.length - 1) && type === "answer") {
      if(value.length > 0) {
        newItems = [...newItems, {limit: 0, answer: ''}];
      }
    }
    onChange(newItems);
  };
  
  return (
    <div className={`${classes.inputGroupWrapper} ${className ? className : null}`}>
      {
        label ? (
          <label>
            {label}
          </label>
        ) : null
      }
      <div className={classes.wrapper}>
      {
        items.map((item: any, key: any) => (
          <div className={classes.inputWrapper} key={`k_${key}`}>
            <Input placeholder={t('answer') + " " + (key + 1) + (key === 0 ? "*" : "")} value={item.answer} onChange={(name: any, value: any) => handleOnChange(value, "answer", key)} disabled={disabled}/>
            {
              showLimits ? (
                <Input type="number" className={classes.limitInput} placeholder={t('answer_limit')} value={item.limit} min={0} onChange={(name: any, value: any) => handleOnChange(value, "limit", key)} disabled={disabled}/>
              ) : null
            }
          </div>
        ))
      }
      </div>
    </div>
  );
}

export default PollInputGroup;