import ImageRenderer from 'src/components/Images/ImageRenderer';
import React from 'react';

type AuthenticatedImageType = {
  thumbLink?: any,
  fullsizeLink?: any,
  preferQuality?: any,
  isResponsive?: any,
  className?: any,
  isClickable?: any,
  useLoader?: any,
  onClick?: any,
  useThumb?: any,
  onLoad?: any,
  style?: any,
  customAccessToken?: any;
  dataCy?: any;
};

const AuthenticatedImage: React.FunctionComponent<AuthenticatedImageType> = ({ thumbLink, fullsizeLink, preferQuality, isResponsive, className, isClickable, useThumb, useLoader, onClick, onLoad, style, customAccessToken, dataCy }) => {
  
  return (
    <ImageRenderer
      thumbLink={thumbLink}
      fullsizeLink={fullsizeLink}
      preferQuality={preferQuality}
      isResponsive={isResponsive}
      className={`${className} ${isClickable && 'pointer'}`}
      onClick={onClick}
      useLoader={useLoader}
      isClickable={isClickable}
      useThumb={useThumb}
      onLoad={onLoad}
      style={style}
      customAccessToken={customAccessToken}
      dataCy={dataCy}
    />
  );
}

export default AuthenticatedImage;
