import ClubCard from 'src/components/Cards/ClubCard';
import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback } from 'react';
import Select from 'src/components/Forms/Select';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { setClubFilterCurrentPage, setClubFilterSchoolID, setClubItems, setClubItemsCount } from 'src/store/actions/club.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  clubsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    padding: '24px 24px',
    overflow: 'auto',
    maxHeight: '100%',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  clubsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    height: 'fit-content',
    width: '100%',
    maxWidth: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      height: '16px',
      width: '100%',
    },
  },
  clubCardWrapper: {
    width: 'calc(16.6666666667% - 16px)',
    [theme.breakpoints.down('xxxl')]: {
      width: 'calc(20% - 16px)',
    },
    [theme.breakpoints.down('xxl')]: {
      width: 'calc(25% - 16px)',
    },
    [theme.breakpoints.down('xl')]: {
      width: 'calc(33% - 8px)',
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(33% - 12px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 8px)',
      borderRadius: "0px",
      '& > div': {
        borderRadius: "0px",
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    maxWidth: '100vw',
    borderRadius: '24px',
    height: '140px',
    display: 'flex',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  notFound: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  clubsToolbar: {
    display: "flex",
    gap: '10px',
    borderRadius: "24px",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    transition: 'height 0.5s, padding 0.5s',
    height: '42px',
    padding: '9px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: "0px",
    },
    flex: '0 0 42px',
    margin: '0 auto',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: '1 1 auto',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    gap: '4px',
    '& > span': {
      fontWeight: '600',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      '& > span': {
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        fontSize: '12px',
        [theme.breakpoints.down('md')]: {
          fontSize: '10px',
        },
        '& > span': {
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '100%',
        },
      },
    },
  },
  isVisible: {
    backgroundColor: theme.colors.systemGreen[500],
  },
  isNotVisible: {
    backgroundColor: theme.colors.systemRed[500],
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clubData = useAppSelector((state: any) => state.club);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const location = useLocation();
  const clubsService = useAppSelector((state: any) => state.services).clubsService;

  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;

  const clubSchoolList = dataData.schools.filter((item: any) => userAccessSchools.club.includes(item.schoolID));
  const limit = 16;

  const [state, setState] = useStates({
    school: clubSchoolList[0],
    isLoading: true,
  });

  const getClubs = useCallback((page: any, schoolID?: any) => {
    setState("isLoading", true);
    const settings = {
      schoolID: schoolID ? schoolID.schoolID : clubData.filterSchoolID.schoolID,
      limit: limit,
      page: page, 
    };
    clubsService && clubsService.listClubs(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setClubItems(result.data.items));
          dispatch(setClubItemsCount(result.data.countItems));
          setState("isLoading", false);
        } else {
          createNotification(t("club_not_loaded"), "error");
          setState("isLoading", false);
        }
      } else {
        createNotification(t("club_not_loaded"), "error");
        setState("isLoading", false);
      }
    }).catch(() => {
      createNotification(t("club_not_loaded"), "error");
      setState("isLoading", false);
    });
  }, [t, clubsService, setState, dispatch, clubData.filterSchoolID]);

  useEffect(() => {
    getClubs(1, state.school);
    return () => {
      dispatch(setClubItems([]));
      dispatch(setClubItemsCount(0));
    };
  }, [dispatch, getClubs, state.school], []);

  const handleSaveSchoolID = (value: any) => {
    dispatch(setClubFilterSchoolID(value));
    setState("school", value);
    getClubs(1, value);
  };

  const onPaginationChange = (page: any) => {
    dispatch(setClubFilterCurrentPage(page));
    getClubs(page);
  };

  return !userAccess.club ? (
    <Navigate to="/403" state={{ from: location }}/>
  ) : (
    <>
      {
        !userAccess.subscription ? (
          <SubscriptionBanner service="club" isInCenter={true}/>
        ) : (
          <>
            <div className={classes.clubsPage}>
              <div className={classes.clubsToolbar}>
                <Select inputLabel={t("school")} items={clubSchoolList} selected={state.school} setSelected={handleSaveSchoolID} width={200} allowClear={false} defaultTitle="name" disabled={clubSchoolList.length === 1}/>
                {
                  getUserRole(userData.userObject.roleType) === "director" ? (
                    <div className={classes.legend}>
                      <span>{t('club_visible_for_parent')}:</span>
                      <div>
                        <span>
                          <span className={classes.isVisible}/> {t('yes')}
                        </span>
                        <span>
                          <span className={classes.isNotVisible}/> {t('no')}
                        </span>
                      </div>
                    </div>
                  ) : null
                }
              </div>
              <div className={classes.clubsList}>
                {
                  state.isLoading ? (
                    <div className={classes.loading}>
                      <CircularProgress className={classes.spinner}/>
                    </div>
                  ) : (
                    <>
                      {
                        clubData.items.length > 0 ? clubData.items.filter((item: any) => (getUserRole(userData.userObject.roleType) === "parent" && item.visibleForParent) || getUserRole(userData.userObject.roleType) !== "parent").map((data: any, key: any) => (
                          <div className={classes.clubCardWrapper} key={`k_${key}`}>
                            <ClubCard data={data}/>
                          </div>
                        )) : (
                          <div className={classes.notFound}>
                            <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                            <span>{t('no_results')}</span>
                            <p>{t('no_results_found')}</p>
                          </div>
                        )    
                      }
                      <Pagination currentPage={clubData.filterCurrentPage} total={clubData.itemsCount} limit={limit} disabled={state.isLoading} onChange={onPaginationChange}/>
                    </>
                  )
                }
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default PageTemplate;