import { DependencyList, useEffect } from 'react';

export function useDebounce(
  fn: any,
  waitTime: number,
  deps?: DependencyList,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps); // eslint-disable-line
};