import BlockPicker from '../../Forms/BlockPicker';
import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: '10px',
    padding: "24px",
  },
  activityContainer: {
    width: "calc(100% - 40px)",
    padding: "20px",
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fit, minmax(56px, 1fr))',
    justifyItems: 'center',
    gap: '32px',
    transition: '0.5s',
    cursor: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 'calc(50vh - 70px)',
  },
  count: {
    backgroundColor: theme.colors.white,
    color: '#EB4D63 !important',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px !important',
    lineHeight: '12px',
    fontWeight: '600',
  },
}));

type ModalType = {
  onSave: any;
  onClose: any;
  selected: any;
};

const FiltersActivityTypesModal: React.FunctionComponent<ModalType> = ({onSave, onClose, selected}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const [selectedActivities, setSelectedActivities] = useState(selected);
  const activityTypes = configurationData.configuration.activityTypes.filter((activity: any) => activity.visibleInFilter);
  
  const onCloseModal = () => {
    onClose();
  };
  
  const handleToggleItem = (activityTypeID: any) => {
    let tempSelectedActivities = selectedActivities;
    if(tempSelectedActivities.indexOf(activityTypeID) === -1) {
      tempSelectedActivities = [...tempSelectedActivities, activityTypeID];   
    } else {
      tempSelectedActivities = tempSelectedActivities.filter((id: any) => id !== activityTypeID);
    }
    setSelectedActivities(tempSelectedActivities);
  };
  
  const handleClear = (e: any) => {
    e.stopPropagation();
    setSelectedActivities([]);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleSave = (e: any) => {
    e.stopPropagation();
    onSave(selectedActivities);
    onCloseModal();
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "filtersActivityTypesModal" : null}>
        <div className={classes.header}>
          <p>{t('activity')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div>
        <BlockPicker className={classes.activityContainer} items={activityTypes} values={selectedActivities} valueCompare={"activityTypeID"} buttonSelected={false} buttonInactive={true} buttonOpacity={0.2} onChange={handleToggleItem}/>
        </div>
        <div className={classes.footer}>
          {
            selectedActivities.length > 0 ? (
              <NormalButton buttonType="clear" startIcon={<span className={classes.count}>{selectedActivities.length}</span>} onClick={handleClear} dataCy="clearButton">
                {t("clear_selection")}
              </NormalButton>
            ) : null
          }
          <NormalButton buttonType={JSON.stringify(selected) === JSON.stringify(selectedActivities) ? "disabled" : "primary"} disabled={JSON.stringify(selected) === JSON.stringify(selectedActivities)} onClick={handleSave} dataCy="doneButton">
            {t("done")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};  

export default FiltersActivityTypesModal;