import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppData, setAppData } from 'src/utils/useApp';
import { langs } from '../../constants/languages';

const savedData = getAppData();

let appLanguage = "EN";
const navigatorLanguage = (((navigator?.language)?.split("-"))[0])?.toUpperCase();
if(savedData?.language) {
  appLanguage = savedData?.language;
} else {
  if(langs.indexOf(navigatorLanguage) !== -1) {
    appLanguage = navigatorLanguage;
  } else {
    appLanguage = "EN";
  }
  setAppData({language: appLanguage}); 
}

interface Model {
  [key: string]: any;
}

interface ArrayModel {
  isFirstLoaded: any;
  language: any;
}

const initialState: ArrayModel = {
  isFirstLoaded: false,
  language: savedData.language ? savedData.language : appLanguage,
};

const slice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    setIsLanguageFirstLoaded(state, action: PayloadAction<Model>){
      state.isFirstLoaded = action.payload;
    },
    setLanguage(state, action: PayloadAction<Model>){
      state.language = action.payload;
    },
  }
});

export default slice;