import IconButton from '../IconButton';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  topBarButton: {
    position: 'relative',
    padding: '0px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    width: '38px',
    height: '38px',
    minWidth: '38px',
    minHeight: '38px',
    maxWidth: '38px',
    maxHeight: '38px',
    backgroundColor: theme.colors.chip,
    color: theme.colors.grey[800],
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.active': {
      backgroundColor: theme.colors.grey[250],
      color: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '20px',
      height: '20px',
    },
    '& > span:not(.MuiTouchRipple-root)': {
      position: 'absolute',
      top: '-8px',
      right: '-12px',
      width: '22px',
      height: '22px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '9px !important',
      lineHeight: '9px !important',
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      borderWidth: '3px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
    },
    '& > p': {
      position: 'absolute',
      top: '-4px',
      right: '-6px',
      width: '12px',
      height: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.primaryBlue[500],
      borderWidth: '3px',
      borderStyle: 'solid',
      borderColor: theme.colors.white,
      borderRadius: '100%',
    },
  },
}));

type TopBarButtonType = {
  className?: any;
  icon?: any;
  onClick?: any;
  tooltip?: any;
  tooltipPosition?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  tooltipArrow?: boolean;
  tooltipMaxWidth?: any;
  children?: any;
  dataCy?: string;
  disabled?: boolean;
};

const TopBarButton: React.FunctionComponent<TopBarButtonType> = ({className, icon, onClick, tooltip, tooltipPosition = "bottom", tooltipArrow = true, tooltipMaxWidth = '150', children, dataCy, disabled}) => {
  
  const classes = useStyles();

  return (
    <IconButton className={`${classes.topBarButton} ${className}`} tooltip={tooltip} tooltipPosition={tooltipPosition} tooltipArrow={tooltipArrow} tooltipMaxWidth={tooltipMaxWidth} onClick={onClick} disabled={disabled} dataCy={dataCy}>
      {icon}
      {children}  
    </IconButton>
  );
};

export default TopBarButton;