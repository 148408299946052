import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppData } from 'src/utils/useApp';

const savedData = getAppData();

interface Model {
  title?: string;
  text?: string;
  status?: 'default' | 'test',
  image?: string;
  date?: number;
};

interface ArrayModel {
  list: Model[];
  position: any;
  timeout: any;
  permission: any;
};

const initialState: ArrayModel = {
  list: [],
  position: savedData.notifications ? (savedData.notifications.position ? savedData.notifications.position : 'top-right') : 'top-right',
  timeout: savedData.notifications ? (savedData.notifications.timeout ? savedData.notifications.timeout : 3500) : 3500,
  permission: savedData.notifications ? (savedData.notifications.permission ? savedData.notifications.permission : 'default') : 'default'
};

const defaultState: ArrayModel = {
  list: [],
  position: 'top-right',
  timeout: 3500,
  permission: 'default'
};

const slice = createSlice({
  name: 'firebasenotification',
  initialState: initialState,
  reducers: {
    setFirebaseNotifications(state, action: PayloadAction<Model>) {
      state.list = [...state.list, action.payload];
    },
    clearFirebaseNotifications(state) {
      state.list = [];
    },
    setFirebaseNotificationsPosition(state, action: PayloadAction<Model>) {
      state.position = action.payload;
    },
    setFirebaseNotificationsTimeout(state, action: PayloadAction<Model>) {
      state.timeout = action.payload;
    },
    setFirebaseNotificationsPermission(state, action: PayloadAction<Model>) {
      state.permission = action.payload;
    },
    resetFirebaseNotifications(state) {
      state.list = defaultState.list;
      state.position = defaultState.position;
      state.timeout = defaultState.timeout;
      state.permission = defaultState.permission;
    },
  }
});

export default slice;