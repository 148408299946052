import EmojiPicker from 'src/components/Layouts/EmojiPicker';
import FileItem from 'src/components/Items/FileItem';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getAppData, setAppData } from 'src/utils/useApp';
import { getUserSetting } from 'src/utils/useUser';
import { isMobile } from 'react-device-detect';
import { setCommunicationDrafts } from 'src/store/actions/communication.actions';
import { setFileInputModal } from 'src/store/actions/modals.actions';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';

interface Props {
  isMobile?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  chatBottomBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    padding: '8px 0px',
    fontSize: '14px',
    borderRadius: '0',
    boxSizing: 'border-box',
    backgroundColor: 'inherit',
    color: theme.colors.black,
    gap: '4px',
    flex: '1 1 46px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.grey[325],
  },
  chatBottomBarHeader: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    padding: '0px 8px 4px 8px',
    height: '30px',
    flex: '0 0 30px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
    },
    '& > button': {
      marginLeft: 'auto',
      backgroundColor: theme.colors.grey[200],
    },
  },
  chatInputWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    padding: '0px 8px',
    fontSize: 'inherit',
    borderRadius: 'inherit',
    boxSizing: 'border-box',
    backgroundColor: 'inherit',
    color: 'inherit',
    gap: '8px',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '10px',
    flex: '1 1 auto',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  chatInput: {
    fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
    width: '100%',
    padding: '0px',
    borderWidth: '0',
    borderRadius: "10px",
    backgroundColor: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    height: '100%',
    outline: 'none',
    boxSizing: 'inherit',
    overflow: 'hidden',
    maxWidth: '100%',
    '& > div': {
      borderRadius: 'inherit',
      borderWidth: 'inherit',
      color: 'inherit',
      backgroundColor: 'inherit',
      '& > textarea': {
        borderRadius: 'inherit',
        resize: 'none',
        width: '100%',
        color: 'inherit',
        backgroundColor: 'inherit',
        '&:disabled': {
          color: theme.colors.grey[560],
          backgroundColor: theme.colors.grey[75],
        },
        '&:read-only': {
          color: theme.colors.grey[560],
          backgroundColor: theme.colors.grey[150],
        },
      },
    },
  },
  chatInputBar: {
    display: 'flex',
  },
  chatInputTools: {
    display: 'flex',
    gap: '8px',
    padding: '2px 4px',
  },
  chatInputToolWrapper: {
    position: 'relative',
  },
  chatInputToolButton: {
    width: '32px',
    height: '32px',
    minWidth: 'unset',
    padding: '0',
    '& > svg': {
      width: '80%',
      height: '80%',
      color: theme.colors.black,
    },
  },
  chatInputSubmit: {
    display: 'flex',
    gap: '8px',
    padding: '2px 4px',
    backgroundColor: (props: Props) => {
      if(props.isMobile) return theme.colors.grey[300];
      else return '';
    },
    borderRadius: (props: Props) => {
      if(props.isMobile) return '10px';
      else return '';
    },
    width: (props: Props) => {
      if(props.isMobile) return '100%';
      else return '';
    },
    marginLeft: (props: Props) => {
      if(!props.isMobile) return 'auto';
      else return '';
    },
  },
  chatInputSubmitButton: {
    display: 'flex',
    gap: '8px',
    width: '32px',
    height: '32px',
    minWidth: 'unset',
    padding: '0',
    textTransform: 'unset',
    '& > span': {
      fontSize: '80%',
    },
    '& > svg': {
      width: '80%',
      height: '80%',
    },
  },
  mediaList: {
    display: 'flex',
    padding: '7.5px 10px 7.5px 10px',
    flexWrap: 'nowrap',
    overflow: 'auto',
    gap: '8px',
    maxWidth: 'calc(100% - 28px)',
  },
  fileItem: {
    margin: '0',
    borderRadius: '24px',
    backgroundColor: theme.colors.white,
    padding: '4px 8px',
    minWidth: '80px',
  },
}));

type ChatBottomBarType = {
  currentThreadID?: any;
  className?: any;
  message: any;
  setMessage: any;
  messages?: any;
  media: any;
  setMedia: any;
  onSubmit: any;
  editMessageID?: any;
  setEditMessageID?: any;
  disabled?: boolean;
  chatInputRef: any;
};

const ChatBottomBar: React.FunctionComponent<ChatBottomBarType> = ({ currentThreadID, className, message, setMessage, messages = [], media, setMedia, onSubmit, editMessageID, setEditMessageID, disabled, chatInputRef }) => {

  const dispatch = useAppDispatch();
  const communicationData = useAppSelector((state: any) => state.communication);
  const userData = useAppSelector((state: any) => state.user);

  let delayDebounceFn: any;

  const textareaRows = useRef(1);

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const classes = useStyles({
    isMobile: isMobile,
  });

  const [state, setState] = useStates({
    textareaRows: textareaRows.current,
    isEmojiPickerOpen: false,
    textareaLength: 0,
    canSaveDraft: true,
  });

  const useEnterToSend = getUserSetting(userData.userSettings, "addons", ["communication", "communication_sendbyenter"]);

  const handleEmojiPicker = () => {
    setState("isEmojiPickerOpen", !state.isEmojiPickerOpen);
  };

  const handleOnEmojiClose = () => {
    setState("isEmojiPickerOpen", false);
  };

  const handleOnEmojiClick = (emojiData: any) => {
    const value = chatInputRef.current.value;
    setMessage(value + emojiData.emoji);
  };

  const handleMediaChange = (data: any) => {
    setMedia(data);
  };

  const handleSaveDraft = (value: any) => {
    if(!state.canSaveDraft) return;
    const savedData = getAppData();
    const twigChatData = savedData.twigchat ? savedData.twigchat : {};
    const newDraft = {
      threadID: currentThreadID,
      value: value,
    };
    const drafts = communicationData.drafts;
    const isDraftExist = drafts.filter((item: any) => item.threadID === currentThreadID).length === 1;
    const newDrafts = (isDraftExist ? drafts.map((item: any) => {
      if(item.threadID === currentThreadID) {
        return newDraft;
      } else {
        return item;
      }
    }) : [...drafts, newDraft]).filter((item: any) => item.value.length !== 0);
    dispatch(setCommunicationDrafts(newDrafts));
    setAppData({twigchat: {...twigChatData, drafts: newDrafts}});
  };

  const handleClearDraft = () => {
    const savedData = getAppData();
    const twigChatData = savedData.twigchat ? savedData.twigchat : {};
    const drafts = communicationData.drafts;
    const newDrafts = drafts.filter((item: any) => item.threadID !== currentThreadID);
    dispatch(setCommunicationDrafts(newDrafts));
    setAppData({twigchat: {...twigChatData, drafts: newDrafts}});
    setState("canSaveDraft", true);
  };

  const handleMedia = () => {
    const settings = {
      isOpen: true,
      data: media,
      mediaLimit: "communication",
      onChange: handleMediaChange
    };
    dispatch(setFileInputModal(settings));
  };

  const handleDeleteMedia = (fileKey: any) => {
    const newFiles = media.map((file: any, key: any) => {
      if(key === fileKey) {
        if(file.status === "hosted") {
          return {...file, status: "deleted"};
        } else {
          return {...file, status: "canceled", raw: null};
        }
      } else {
        return file;
      }
    });
    handleMediaChange(newFiles);
  };

  const handleOnChange = (value: any) => {
    clearTimeout(delayDebounceFn);
    delayDebounceFn = setTimeout(() => {
      setState("textareaLength", value.length);
      if(chatInputRef.current) {
        setMessage(chatInputRef.current.value);
      }
    }, value.length === 0 ? 1 : 100);
  };

  const handleOnEnter = (value: any, e: any) => {
    if(e.keyCode === 13 && !e.shiftKey) {
      if(useEnterToSend) {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit();
      }
    } else {
      handleOnChange(value);
    }
  };

  const handleDraft = () => {
    if(currentThreadID) {
      if(chatInputRef.current) {
        handleSaveDraft(chatInputRef.current.value);
      }
    }
  };

  const handleSubmit = () => {
    setState("canSaveDraft", false);
    clearTimeout(delayDebounceFn);
    handleOnChange("");
    const data = {
      value: chatInputRef.current.value,
      media: media,
    };
    onSubmit(data);
    setState("textareaRows", 1);
    if(currentThreadID) {
      setTimeout(() => {
        handleClearDraft();
      }, 1000);
    }
  };

  const handleCancelEdit = () => {
    setEditMessageID(null);
    setMessage("");
    setMedia([]);
  };
 
  const editMessageText = editMessageID ? messages.filter((item: any) => item.messageID === editMessageID).length === 0 ? "" : messages.find((item: any) => item.messageID === editMessageID).text : "";

  const cantSend = editMessageID ? (message.length === 0 && media.filter((fileData: any) => fileData.status === "hosted").length === 0) || (message === editMessageText && media.filter((fileData: any) => fileData.status === "ready" || fileData.status === "deleted").length === 0) : message.length === 0 && media.filter((fileData: any) => fileData.status === "ready" || fileData.status === "deleted").length === 0;

  return (
    <div className={`${classes.chatBottomBar} ${className ? className : null}`}>
      {
        editMessageID ? (
          <div className={classes.chatBottomBarHeader}>
              <span>
                {t('communication_edit_message')}
              </span>
              <IconButton tooltip={t('communication_cancel_edit_message')} tooltipPosition='left' tooltipMaxWidth={400} size='small' onClick={handleCancelEdit}>
                <SVG src="close"/>
              </IconButton>
            </div>
        ) : null
      }
      <div className={classes.chatInputWrapper}>
        <div className={classes.chatInputBar}>
          <div className={classes.chatInputTools}>
            <div className={classes.chatInputToolWrapper}>
              <Button className={classes.chatInputToolButton} onClick={handleMedia} disabled={disabled}>
                <SVG src="file-attachment"/>
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.inputWrapper}>
          {
            media.filter((file: any) => file.status !== "deleted" && file.status !== "canceled").length > 0 ? (
              <div className={classes.mediaList}>
                {
                  media.map((file: any, key: any) => (file.status !== "deleted" && file.status !== "canceled") ? (
                    <FileItem className={classes.fileItem} key={`k_${key}`} fileInfo={file} showCaption={false} showSize={false} handleRemoveFile={() => handleDeleteMedia(key)}/>
                  ) : null)
                }
              </div>
            ) : null
          }
          <Input value={message} useName={false} autoFocus={layout === "desktop"} autoRows={true} className={classes.chatInput} onInputEnd={handleDraft} onKeyUp={handleOnChange} onChange={handleOnChange} onKeyDown={handleOnEnter} placeholder={'Aa'} multiline={true} rows={state.textareaRows} inputDelay={1000} customRefInput={chatInputRef} disabled={disabled}/>
        </div>
        <div className={classes.chatInputBar}>
          {
            (!isMobile && getUserSetting(userData.userSettings, "addons", ["communication", "communication_emoji"])) ? (
              <div className={classes.chatInputTools}>
                <div className={classes.chatInputToolWrapper}>
                  <EmojiPicker isOpen={state.isEmojiPickerOpen} position='right-bottom' setIsClose={handleOnEmojiClose} onEmojiClick={handleOnEmojiClick}/>
                  <Button className={classes.chatInputToolButton} onClick={handleEmojiPicker} disabled={disabled}>
                    <SVG src="emoji"/>
                  </Button>
                </div>
              </div>
            ) : null
          }
          <div className={classes.chatInputSubmit}>
            <Button className={classes.chatInputSubmitButton} onClick={handleSubmit} disabled={disabled || cantSend}>
              <SVG src="send"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBottomBar;