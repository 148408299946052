import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CircleIcon from '@mui/icons-material/Circle';
import DateFormat from '../../../utils/dateFormat';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  substitutionCard: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    padding: '16px',
    width: '100%',
    maxWidth: 'calc(100% - 32px)',
    gap: '24px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  leftSide: {
    display: 'flex',
  },
  wrapper: {
    position: 'relative',
  },
  child: {
    display: 'flex',
    width: '56px',
    height: '56px',
    borderRadius: '16px',
    position: 'relative',
    backgroundColor: theme.colors.white,
    '& > div': {
      borderWidth: 'unset',
      borderStyle: 'unset',
      borderColor: 'unset',  
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '5px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: '600',
      gap: '5px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
    '& > div': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      '& > p': {
        fontSize: '16px',
        color: '#A5A6BB',
      },
    },
  },
  partOfDay: {
    position: 'relative',
    width: '16px',
    minWidth: '16px',
    height: '16px',
    minHeight: '16px',
  },
  partAM: {
    position: 'relative',
    top: '0px',
    left: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '8px',
    '& > svg': {
      width: '16px',
      height: '16px',
      color: 'inherit',
    }, 
  },
  partPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '8px',
    '& > svg': {
      marginLeft: '-8px',
      width: '16px',
      height: '16px',
      color: 'inherit',
    }, 
  },
  chevronSide: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    paddingLeft: '16px',
    marginLeft: 'auto',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

type ItemsType = {
  substitution: any;
  onClick: any;
  className?: any;
};

const SubstitutionCard: React.FunctionComponent<ItemsType> = ({substitution, onClick, className}) => {

  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const childData = dataData.children.find((child: any) => child.childID === substitution.childID);  
  const languageData = useAppSelector((state: any) => state.language);
  const substitutionData = substitutionsData.list.filter((item: any) => item.childID === substitution.childID).length === 1 ? substitutionsData.list.find((item: any) => item.childID === substitution.childID).dates.filter((item: any) => item.substitutionID === substitution.substitutionID).length === 1 ? substitutionsData.list.find((item: any) => item.childID === substitution.childID).dates.find((item: any) => item.substitutionID === substitution.substitutionID) : {} : {};  
  const classData = substitutionData.classID ? dataData.classes.find((theClass: any) => theClass.classID === substitutionData.classID) : [];
  const classes = useStyles();

  return Object.keys(substitutionData).length > 0 ? (
    <div className={`${classes.substitutionCard} ${className ? className : ''}`} onClick={onClick}>
      <div className={classes.leftSide}>
        <div className={classes.wrapper}>
          <AuthenticatedImage className={classes.child} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink}/>
        </div>
      </div>
      <div className={classes.rightSide}>
        {
          substitutionData.classID ? (
            <span>
              <SVG src="class"/>
              {classData.name}
            </span>
          ) : null
        }
        <div>
          <span className={classes.partOfDay}>
            <div className={classes.partAM} style={{color: (substitutionData.substitutionPartID === 1 || substitutionData.substitutionPartID === 2) ? '#BFC0D9' : '#ECEDF8'}}>
              <CircleIcon/>
            </div>
            <div className={classes.partPM} style={{color: (substitutionData.substitutionPartID === 1 || substitutionData.substitutionPartID === 3) ? '#BFC0D9' : '#ECEDF8'}}>
              <CircleIcon/>
            </div>
          </span>
          <p>{DateFormat(substitutionData.date, "default", languageData, t)}</p>
        </div>
      </div>
      <div className={classes.chevronSide}>
        <SVG src={substitutionData.isEditable ? "pencil" : "chevron-right"}/>
      </div>
    </div>
  ) : null;
}

export default SubstitutionCard;