import moment from '../../utils/moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  date: any;
  selectedChildren: any;
};

const initialState: ArrayModel = {
  date: moment(),
  selectedChildren: [],
};

const slice = createSlice({
  name: 'attendance',
  initialState: initialState,
  reducers: {
    setAttendanceDate(state, action: PayloadAction<Model>){
      state.date = action.payload;
    },
    setAttendanceSelectedChildren(state, action: PayloadAction<Model>){
      state.selectedChildren = action.payload;
    },
  }
});

export default slice;