import store from '../store';
import { setNotification } from '../store/actions/notification.actions';

interface createNotificationType {
  text: any;
  type?: "default" | "info" | "success" | "warning" | "error";
  timeout?: number;
};

const createNotification = (text: any, type: createNotificationType['type'] = "default", timeout: createNotificationType['timeout'] = 3500) => {
  store.dispatch(setNotification({ text, type, timeout, date: Date.now() }));
};

export {
  createNotification,
};