import Desktop from './Desktop';
import Mobile from './Mobile';
import React from 'react';
import useBreakpoint from 'src/utils/useBreakpoint';

type WizardType = {
  steps: any;
  currentStep: number;
  defaultStep?: number;
  onStepChange: any;
  onComplete?: any;
  canNextStep?: boolean;
  showFooter?: boolean;
  footerContent?: any;
  className?: any,
};

const Wizard: React.FunctionComponent<WizardType> = ({ steps, currentStep = 1, onStepChange, onComplete, canNextStep = true, showFooter = true, footerContent, className }) => {

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const layout = layouts[breakpoint];
  
  return (
    <>
      {
        layout === "mobile" ? (
          <Mobile steps={steps} currentStep={currentStep} onStepChange={onStepChange} onComplete={onComplete} canNextStep={canNextStep} showFooter={showFooter} footerContent={footerContent} className={className}/>
        ) : (
          <Desktop steps={steps} currentStep={currentStep} onStepChange={onStepChange} onComplete={onComplete} canNextStep={canNextStep} showFooter={showFooter} footerContent={footerContent} className={className}/>
        )
      }
    </>
  );
}

export default Wizard;
