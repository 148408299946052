import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
  hourBlock: {
      backgroundColor: theme.colors.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[100],
      borderRadius: '12px',
      boxShadow: theme.shadows[2],
      padding: '4px 0px',
      width: '92px',
      textAlign: 'center',
  },
}));

type HourBlockType = {
  hour: any;
};

const HourBlock: React.FunctionComponent<HourBlockType> = ({hour}) => {

  const classes = useStyles();

  return (
    <div className={classes.hourBlock}>
      {hour}.
    </div>
  );
}

export default HourBlock;