import React from 'react';
import EmployeeInfoItem from 'src/components/Items/EmployeeInfoItem';

type RenderEmployeeType = {
  state: any;
  setState: any;
  schoolData?: any;
  employeeData: any;
  size: any;
};

const RenderEmployee: React.FunctionComponent<RenderEmployeeType> = ({
  state,
  setState,
  schoolData,
  employeeData,
  size,
}) => {
  const schoolID = schoolData.schoolID;
  const employeeID = employeeData.employeeID;
  const searchData = state.isSelectInAllSchool ? { employeeID: employeeID } : { employeeID: employeeID, schoolID: schoolID };

  const defaultEmployeeData = state.functions.getEmployeeData(employeeID);
  const isSelected = state.selectedEmployees.filter((item: any) => JSON.stringify(item) === JSON.stringify(searchData)).length !== 0;
  const isDisabled = state.isAllowOnlyOneEmployeeAtOnce ? (isSelected ? true : false) : false;
  
  const handleOnClickEmployee = (e: any) => {
    e.preventDefault();
    if(state.isDisableClick) return;
    if(state.isAllowOnlyOneEmployeeAtOnce && isSelected) return;
    let newSelectedEmployees = state.isAllowOnlyOneEmployeeAtOnce ? [] : state.selectedEmployees;
    const itemData = {
      school: schoolData,
      employee: employeeData,
    }
    if(isSelected) {
      newSelectedEmployees = newSelectedEmployees.filter((item: any) => JSON.stringify(item) !== JSON.stringify(searchData));
      setState("selectedEmployees", newSelectedEmployees);
    } else {
      newSelectedEmployees = [...newSelectedEmployees, searchData];
      setState("selectedEmployees", newSelectedEmployees);
    }        
    if(state.onClickEmployee) state.onClickEmployee(e, itemData, newSelectedEmployees); 
  };

  return (
    <EmployeeInfoItem employeeID={employeeID} isSelected={isSelected} isDisabled={isDisabled} isVisibleArchived={state.isShowEmployeesArchived} defaultEmployeeData={defaultEmployeeData} size={size} useModal={state.isEmployeeModal} disableClick={state.isDisableClick} onClick={handleOnClickEmployee} />
  );
}

export default RenderEmployee;