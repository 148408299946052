import appEnvironment from 'src/constants/environment';
import config from 'src/constants/config';
import ReactGA from 'react-ga4';
import StorageService from 'src/services/storage.service';
import getUserInfo from '../UserInfo';
import { clarity } from 'clarity-js';
import { getAppData } from 'src/utils/useApp';
import { getUserRole } from 'src/utils/useUser';
import { productFruits } from 'product-fruits';

const loadTracking = (userData: any, languageData: any, windowHandler: any, location: any) => {

    const environment = config.APP_ENVIRONMENT;
    const productFruitsCode = appEnvironment[environment].productFruitsCode;
    const clarityID = appEnvironment[environment].clarityID;
    const gaIDs = appEnvironment[environment].gaID;
    const savedData = getAppData();
    const cookies = savedData.cookies ? savedData.cookies : [];

    if(cookies.includes("analytics")) {
      const userInfo = getUserInfo(userData.userObject, location);
      if(productFruitsCode !== null && windowHandler.productFruitsIsReady === undefined) {
        productFruits.init(productFruitsCode, languageData.language.toLowerCase(), userInfo, { disableLocationChangeDetection: true });
      }
      if(clarity && clarityID !== null) {
        clarity.consent();
        const userType = "logged".toString();
        const userEmail = userInfo.email ? userInfo.email.toString().toLowerCase() : "";
        const userID = userInfo.username ? userInfo.username.toString() : "";
        const userRole = userInfo.role ? userInfo.role.toString() : "";
        const isPremiumParent = ((userInfo.props && userInfo.props.premiumParent) ? true : false).toString();
        const isPremiumSchool = ((userInfo.props && userInfo.props.premiumSchool) ? true : false).toString();
        const appVersion = (userInfo.props && userInfo.props.version) ? userInfo.props.version.toString() : "";
        const appApiServer = (userInfo.props && userInfo.props.apiServer) ? userInfo.props.apiServer.toString() : "";
        const appEnvironment = (userInfo.props && userInfo.props.environment) ? userInfo.props.environment.toString() : "";
        const appBranch = (userInfo.props && userInfo.props.branch) ? userInfo.props.branch.toString() : "";
        clarity.identify(userEmail, undefined, undefined, userID);
        StorageService.setCookie('userID', userID, 1);
        StorageService.setCookie('userEmail', userEmail, 1);
        StorageService.setCookie('userType', userType, 1);
        StorageService.setCookie('userRole', userRole, 1);
        if(getUserRole(userData.userObject.roleType) === "parent") {
          StorageService.setCookie('isPremiumParent', isPremiumParent, 1);
        }
        StorageService.setCookie('isPremiumSchool', isPremiumSchool, 1);
        StorageService.setCookie('version', appVersion, 1);
        if(userInfo.props.environment !== "production") {
          StorageService.setCookie('apiServer', appApiServer, 1); 
          StorageService.setCookie('environment', appEnvironment, 1); 
        }
        if(userInfo.props.environment === "review") {
          StorageService.setCookie('branch', appBranch, 1); 
        }
        clarity.set('userID', userID);
        clarity.set('userEmail', userEmail);
        clarity.set('userType', userType);
        clarity.set('userRole', userRole);
        if(getUserRole(userData.userObject.roleType) === "parent") {
          clarity.set('isPremiumParent', isPremiumParent);
        }
        clarity.set('isPremiumSchool', isPremiumSchool);
        clarity.set('version', appVersion);
        if(userInfo.props.environment !== "production") {
          clarity.set('apiServer', appApiServer);
          clarity.set('environment', appEnvironment);
        }
        if(userInfo.props.environment === "review") {
          clarity.set('branch', appBranch);
        }
        setTimeout(() => {
          StorageService.deleteCookie('userID');
          StorageService.deleteCookie('userEmail');
          StorageService.deleteCookie('userType');
          StorageService.deleteCookie('userRole');
          if(getUserRole(userData.userObject.roleType) === "parent") {
            StorageService.deleteCookie('isPremiumParent');
          }
          StorageService.deleteCookie('isPremiumSchool');
          StorageService.deleteCookie('version');
          if(userInfo.props.environment !== "production") {
            StorageService.deleteCookie('apiServer');
            StorageService.deleteCookie('environment');
          }
          if(userInfo.props.environment === "review") {
            StorageService.deleteCookie('branch');
          }
        }, 3000);
      }
      if(gaIDs !== null) {
        ReactGA.set(userInfo);
      }
    }
    return true;
  };

export default loadTracking;