import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import { setInfoModal, setSubstitutionsHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import moment from 'src/utils/moment';
import { getUserRole } from 'src/utils/useUser';

const Substitutions: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalsData = useAppSelector((state: any) => state.modals);
  const substitutionsData = useAppSelector((state: any) => state.substitutions);
  const userData = useAppSelector((state: any) => state.user);

  const handleSubstitutionsInfo = () => {
    const settings = {
      isOpen: true,
      title: t('about_substitutions'),
      type: 'substitutions',
    };
    dispatch(setInfoModal(settings));
  };

  const handleAdd = () => {
    const settings = {
      isOpen: true,
      date: moment(substitutionsData.firstAvailableDate),
      child: substitutionsData.currentChild,
    };
    dispatch(setSubstitutionsHandleModal(settings));
  };

  return (
    <>
      <TopBarButton className={modalsData.infoModal.isOpen ? 'active' : null} icon={<SVG src="question-mark-circle"/>} onClick={handleSubstitutionsInfo} dataCy="topBarButtonInfo" tooltip={t('help')}/>
      {
        getUserRole(userData.userObject.roleType) === "parent" ? (
          <TopBarButton className={modalsData.substitutionsHandleModal.isOpen ? 'active' : null} icon={<SVG src="plus"/>} onClick={handleAdd} dataCy="topBarButtonAdd" tooltip={t('create')} disabled={substitutionsData.childData.credit <= 0 || substitutionsData.firstAvailableDate === null}/>
        ) : null
      }
    </>
  );
}

export default Substitutions;