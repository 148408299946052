import Button from '@mui/material/Button';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getElementID } from '../../../utils/useUUID';
import { isCypress } from '../../../utils/useCypress';

const useStyles = createUseStyles((theme: any) => ({
  buttonGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: 'fit-content', 
    gap: '40px',
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.chip,
    width: '58px',
    height: '58px',
    minWidth: '58px',
    minHeight: '58px',
    maxWidth: '58px',
    maxHeight: '58px',
    borderRadius: '100%',
    borderWidth: '0px',
    color: theme.colors.grey[800],
    '&.selected': {
      backgroundColor: theme.colors.grey[250],
      color: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '20px',
      height: '19px',
    },
  },
  buttonLabel: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.black,
    fontSize: '12px',
    marginBottom: '7px',
    fontWeight: '500',
    width: '100%',
    cursor: 'pointer',
    marginTop: '10px',
    '&.selected': {
      cursor: 'default',
    },
  },
}));

type ButtonGroupType = {
  items: any;
  value: any;
  label?: any;
  onChange: any;
  className?: any;
  disabled?: any;
};

const ButtonGroup: React.FunctionComponent<ButtonGroupType> = ({items, value, label, onChange, className, disabled}) => {

  const classes = useStyles();
  
  return (
    <div className={`${classes.buttonGroupWrapper} ${className ? className : null}`}>
      {
        label ? (
          <label>
            {label}
          </label>
        ) : null
      }
      <div className={classes.wrapper}>
      {
        items.map((item: any, key: any) => {
          const uid = getElementID();
          return (
            <div className={classes.buttonWrapper} key={`k_${key}`}>
              <Button id={uid} className={`${classes.button} ${value === item.value ? 'selected' : null}`} value={item.value} onClick={() => onChange(item.value)} disabled={(value === item.value ? true : item.disabled) || disabled} data-cy={isCypress() ? item.dataCy : null}>
                <SVG src={item.icon}/>
              </Button>
              <label htmlFor={uid} className={`${classes.buttonLabel} ${value === item.value ? 'selected' : null}`}>{item.name}</label>
            </div>
          );
        })
      }
      </div>
    </div>
  );
}

export default ButtonGroup;