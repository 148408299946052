import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CircleIcon from '@mui/icons-material/Circle';
import DateFormat from '../../../utils/dateFormat';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  absenceApprovalStatusID: any;
};

const useStyles = createUseStyles((theme: any) => ({
  absenceCard: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    padding: '16px',
    width: '100%',
    maxWidth: 'calc(100% - 32px)',
    gap: '24px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  leftSide: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  reason: {
    display: 'flex',
    width: '72px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '56px',
      height: '56px',
      backgroundColor: '#CFD2E3',
      borderRadius: '16px',
      transition: 'background 0.25s',
      '& > img': {
        width: '20px',
        height: '20px',  
      },    
    },
  },
  smallChild: {
    position: 'absolute',
    right: '-6px',
    bottom: '-6px',
    width: '24px',
    height: '24px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
    backgroundColor: theme.colors.white,
    '& > div': {
      borderWidth: 'unset',
      borderStyle: 'unset',
      borderColor: 'unset',  
    },
  },
  childWrapper: {
    position: 'relative',
  },
  child: {
    display: 'flex',
    width: '56px',
    height: '56px',
    borderRadius: '16px',
    position: 'relative',
    backgroundColor: theme.colors.white,
    '& > div': {
      borderWidth: 'unset',
      borderStyle: 'unset',
      borderColor: 'unset',  
    },
  },
  smallReason: {
    position: 'absolute',
    right: '-6px',
    bottom: '-6px',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      backgroundColor: '#CFD2E3',
      borderRadius: '16px',
      transition: 'background 0.25s',
      '& > img': {
        width: '16px',
        height: '16px',  
      },
    },    
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '5px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: '600',
      gap: '5px',
      '& > svg': {
        width: '20px',
        height: '20px',
        '--circle-color': theme.colors.alwaysWhite,
        '--ellipse-color': theme.colors.systemRed[500],
        '--path-first-color': theme.colors.systemRed[500],
        '--path-last-color': theme.colors.alwaysWhite,
      }
    },
    '& > div': {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      '& > p': {
        fontSize: '16px',
        color: '#A5A6BB',
      },
    },
  },
  partOfDay: {
    position: 'relative',
    width: '16px',
    minWidth: '16px',
    height: '16px',
    minHeight: '16px',
  },
  partAM: {
    position: 'relative',
    top: '0px',
    left: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '8px',
    '& > svg': {
      width: '16px',
      height: '16px',
      color: 'inherit',
    }, 
  },
  partPM: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '8px',
    '& > svg': {
      marginLeft: '-8px',
      width: '16px',
      height: '16px',
      color: 'inherit',
    }, 
  },
  chevronSide: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    paddingLeft: '16px',
    marginLeft: 'auto',
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
  approvalStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '2px 8px',
    borderRadius: '10px',
    width: 'fit-content',
    backgroundColor: (props: Props) => {
      if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[100];
      else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[100];
      else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[100];
      else return '';
    },
    color: (props: Props) => {
      if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[500];
      else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[500];
      else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[500];
      else return '';
    },
  },
}));

type ItemsType = {
  absence: any;
  onClick: any;
  className?: any;
};

const ExcuseNoteCard: React.FunctionComponent<ItemsType> = ({absence, onClick, className}) => {

  const { t } = useTranslation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const childData = dataData.children.find((child: any) => child.childID === absence.childID);  
  const languageData = useAppSelector((state: any) => state.language);
  const absenceData = excusenotesData.list.filter((item: any) => item.childID === absence.childID).length > 0 ? (excusenotesData.list.find((item: any) => item.childID === absence.childID).dates.filter((item: any) => item.absenceID === absence.absenceID).length > 0 ? excusenotesData.list.find((item: any) => item.childID === absence.childID).dates.find((item: any) => item.absenceID === absence.absenceID) : {}) : {};  
  const absenceTypes = configurationData.configuration.absenceTypes;
  const absenceType = absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === absenceData.absenceTypeID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === absenceData.absenceTypeID);
  const absenceApprovalStatuses = configurationData.configuration.absenceApprovalStatuses ? configurationData.configuration.absenceApprovalStatuses : [];
  const absenceApprovalStatus = absenceApprovalStatuses.length > 0 ? (absenceApprovalStatuses.filter((absenceApprovalStatusData: any) => absenceApprovalStatusData.absenceApprovalStatusID === absenceData.absenceApprovalStatusID).length === 0 ? absenceApprovalStatuses[0] : absenceApprovalStatuses.find((absenceApprovalStatusData: any) => absenceApprovalStatusData.absenceApprovalStatusID === absenceData.absenceApprovalStatusID)) : null;
  
  const classes = useStyles({
    absenceApprovalStatusID: absenceData.absenceApprovalStatusID,
  });

  return Object.keys(absenceData).length > 0 ? (
    <div className={`${classes.absenceCard} ${className ? className : ''}`} onClick={onClick}>
      {
        excusenotesData.viewMode === "class" ? (
          <>
            <div className={classes.leftSide}>
              <div className={classes.wrapper}>
                <div className={classes.childWrapper}>
                  <AuthenticatedImage className={classes.child} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink}/>
                  <div className={classes.smallReason}>
                    <span style={{backgroundColor: absenceType.color}}>
                    {
                      absenceType.image ? (
                        <img src={absenceType.image} alt={absenceType.name}/>
                      ) : null
                    }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rightSide}>
              {
                (absenceData.absenceApprovalStatusID && absenceData.absenceApprovalStatusID !== 0 && absenceApprovalStatuses.length > 0) ? (
                  <div className={classes.approvalStatus}>
                    {absenceApprovalStatus.name}
                  </div> 
                ) : null
              }
              <span>
                {
                  absenceData.isLate ? (
                    <SVG src="late"/>
                  ) : null
                }
                {childData.displayName}
              </span>
              <div>
                <p>{absenceType.name}</p>
              </div>
            </div>
            <div className={classes.chevronSide}>
              <SVG src={absenceData.isEditable ? "pencil" : "chevron-right"}/>
            </div>
          </>
        ) : (
          <>
            <div className={classes.leftSide}>
              <div className={classes.wrapper}>
                <div className={classes.reason}>
                  <span style={{backgroundColor: absenceType.color}}>
                  {
                    absenceType.image ? (
                      <img src={absenceType.image} alt={absenceType.name}/>
                    ) : null
                  }
                  </span>
                  <AuthenticatedImage className={classes.smallChild} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink}/>
                </div>
              </div>
            </div>
            <div className={classes.rightSide}>
              {
                (absenceData.absenceApprovalStatusID && absenceData.absenceApprovalStatusID !== 0) ? (
                  <div className={classes.approvalStatus}>
                    {absenceApprovalStatus.name}
                  </div> 
                ) : null
              }
              <span>
                {
                  absenceData.isLate ? (
                    <SVG src="late"/>
                  ) : null
                }
                {absenceType.name}
              </span>
              <div>
                <span className={classes.partOfDay}>
                  <div className={classes.partAM} style={{color: (absenceData.absencePartID === 1 || absenceData.absencePartID === 2) ? '#9B8AFF' : '#C4BBFF'}}>
                    <CircleIcon/>
                  </div>
                  <div className={classes.partPM} style={{color: (absenceData.absencePartID === 1 || absenceData.absencePartID === 3) ? '#9B8AFF' : '#C4BBFF'}}>
                    <CircleIcon/>
                  </div>
                </span>
                <p>{DateFormat(absenceData.date, "default", languageData, t)}</p>
              </div>
            </div>
            <div className={classes.chevronSide}>
              <SVG src={absenceData.isEditable ? "pencil" : "chevron-right"}/>
            </div>
          </>
        )
      }
    </div>
  ) : null;
}

export default ExcuseNoteCard;
