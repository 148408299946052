import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useRef } from 'react';
import RenderAll from './RenderAll';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  schoolsSelect: {
    width: '100%',
    '&.inModal': {
      display: 'none',
    },  
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type SchoolsSelectType = {
  onClickSchool?: any;
  setSelectedSchools?: any;
  selectedSchools?: any;
  defaultSchools?: any;
  defaultSchoolsData?: any;
  isInModal?: boolean;
  isModalOpen?: boolean;
  isMultipleSelect?: boolean;
  mode?: string;
  isSchoolModal?: boolean;
  isDisableTotalCount?: boolean;
  isDisableClick?: boolean;
  isAllowSearch?: boolean;
  isAllowOnlyOneSchoolAtOnce?: boolean;
  modalTitle?: string;
  modalOnClose?: any;
  modalOnSave?: any;
  modalCloseOnSave?: boolean;
  modalAllowSchoolsCount?: boolean;
  modalAllowCancel?: boolean;
  modalAllowClose?: boolean;
  modalAllowClear?: boolean;
  modalAllowNoSchool?: boolean;
  customSort?: any;
  customClasses?: any;
  customNoResults?: any;
  isReload?: any;
};

const SchoolsSelect: React.FunctionComponent<SchoolsSelectType> = ({
  onClickSchool,
  setSelectedSchools,
  selectedSchools,
  defaultSchools,
  defaultSchoolsData,
  isInModal = false,
  isModalOpen = false,
  isMultipleSelect = false,
  mode = "detail",
  isSchoolModal = false,
  isDisableTotalCount = false,
  isDisableClick = false,
  isAllowSearch = true,
  isAllowOnlyOneSchoolAtOnce = false,
  modalTitle = "",
  modalOnClose,
  modalOnSave,
  modalCloseOnSave = true,
  modalAllowSchoolsCount = true,
  modalAllowCancel = false,
  modalAllowClose = false,
  modalAllowClear = false,
  modalAllowNoSchool = false,
  customSort,
  customClasses,
  customNoResults,
  isReload = false,
}) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const schoolsData = useMemo(() => dataData.schools.map((item: any) => {
    let returnItem = item;
    if(defaultSchoolsData) {
      const isMoreData = defaultSchoolsData.filter((more: any) => more.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isMoreData) {
        const moreData = defaultSchoolsData.find((more: any) => more.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, moreData);
      }
    }
    if(customSort) {
      const isSortData = customSort.filter((sort: any) => sort.schoolID === returnItem.schoolID).length === 1 ? true : false;
      if(isSortData) {
        const sortData = customSort.find((sort: any) => sort.schoolID === returnItem.schoolID);
        returnItem = Object.assign({}, returnItem, sortData);
      }
    }
    return returnItem;
  }), [dataData.schools, defaultSchoolsData, customSort]);

  const defaultSchoolList = useMemo(() => (defaultSchools ? defaultSchools : dataData.schools).map((item: any) => { return { schoolID: item.schoolID }; }), [dataData.schools, defaultSchools]);

  const getSchoolData = useCallback((schoolID: any) => {
    return schoolsData.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : schoolsData.find((item: any) => item.schoolID === schoolID);
  }, [schoolsData]);

  const getSchools = useCallback(() => {
    let list = defaultSchoolList;
    if(stateRef.current.search !== "") {
      list = list.filter((item: any) => getSchoolData(item.schoolID).name.toLowerCase().includes(stateRef.current.search));
    }
    list.sort((a: any, b: any) => {
      if (getSchoolData(a.schoolID).customSortOrder !== undefined && getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return getSchoolData(a.schoolID).customSortOrder - getSchoolData(b.schoolID).customSortOrder;
      } else if (getSchoolData(a.schoolID).customSortOrder !== undefined) {
        return -1;
      } else if (getSchoolData(b.schoolID).customSortOrder !== undefined) {
        return 1;
      } else {
        return 0;
      }
    });
    return list;
  }, [defaultSchoolList, getSchoolData]);

  const defaultState: any = useMemo(() => {
    return {
      data: {
        schools: defaultSchoolList,
      },
      reloadAll: true,
      isLoadedAll: false,
      search: '',
      onClickSchool: onClickSchool,
      selectedSchools: selectedSchools ? selectedSchools : [],
      defaultSelectedSchools: selectedSchools ? selectedSchools : [],
      setSelectedSchools: setSelectedSchools,
      defaultSchools: defaultSchools,
      defaultSchoolsData: defaultSchoolsData,
      isInModal: isInModal,
      isModalOpen: isModalOpen,
      isMultipleSelect: isMultipleSelect,
      mode: mode,
      isSchoolModal: isSchoolModal,
      isDisableTotalCount: isDisableTotalCount,
      isDisableClick: isDisableClick,
      isAllowSearch: isAllowSearch,
      isAllowOnlyOneSchoolAtOnce: isAllowOnlyOneSchoolAtOnce,
      modalTitle: modalTitle,
      modalOnClose: modalOnClose,
      modalOnSave: modalOnSave,
      modalCloseOnSave: modalCloseOnSave,
      modalAllowSchoolsCount: modalAllowSchoolsCount,
      modalAllowCancel: modalAllowCancel,
      modalAllowClose: modalAllowClose,
      modalAllowClear: modalAllowClear,
      modalAllowNoSchool: modalAllowNoSchool,
      customClasses: customClasses,
      customNoResults: customNoResults,
      functions: {
        getSchoolData: getSchoolData,
        getSchools: getSchools,
      },
    }
  }, [customClasses, customNoResults, defaultSchoolList, defaultSchools, defaultSchoolsData, getSchoolData, getSchools, isAllowOnlyOneSchoolAtOnce, isAllowSearch, isSchoolModal, isDisableClick, isDisableTotalCount, isInModal, isModalOpen, isMultipleSelect, modalAllowCancel, modalAllowSchoolsCount, modalAllowClear, modalAllowClose, modalAllowNoSchool, modalCloseOnSave, modalOnClose, modalOnSave, modalTitle, mode, onClickSchool, selectedSchools, setSelectedSchools]);
  
  const [state, setState, setStates] = useStates(defaultState);

  const handleState = useCallback((name: any, value: any) => {
    if(Array.isArray(name) && Array.isArray(value)) {
      let stateHolder = state;
      let newObjects = {};
      name.forEach((item: any, index: any) => {
        stateHolder = {...stateHolder, [item]: value[index]};
        const newObject = {
          [item]: value[index],
        };
        newObjects = Object.assign(newObject, newObjects, newObject);
      });
      stateRef.current = stateHolder;
      setStates(newObjects);
    } else {
      stateRef.current = {...state, [name]: value};
      setState(name, value);
    }
  }, [state, setState, setStates]); 

  const stateRef = useRef(state);

  useEffect(() => {
    let stateNames: any = [];
    let stateValues: any = [];
    stateNames = ["isLoadedAll"];
    stateValues = [true]; 
    handleState(stateNames, stateValues);
  }, [handleState], []);

  useEffect(() => {
    return () => {
      if(setSelectedSchools) setSelectedSchools(stateRef.current.selectedSchools);
    }
  }, [setSelectedSchools], []);

  useEffect(() => {
    if(setSelectedSchools) setSelectedSchools(stateRef.current.selectedSchools);
  }, [setSelectedSchools], [stateRef.current.selectedSchools]);

  useEffect(() => {
    if(state.isDisableClick !== isDisableClick) {
      handleState("isDisableClick", isDisableClick);
    }
    if(isReload) {
      const newDefaultState = {...defaultState, isLoadedAll: true};
      setStates(newDefaultState);
      stateRef.current = newDefaultState;
    }
  }, [state, handleState, defaultState, setStates, isDisableClick, isReload], [isDisableClick, isReload]);

  return (
    <div className={`${classes.schoolsSelect} ${isInModal ? 'inModal' : null} ${customClasses ? (customClasses.schoolsSelect ? customClasses.schoolsSelect : "") : ""}`}>
      {
        (!state.isLoadedAll && !state.isInModal) ? (
          <div className={classes.loading}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : (
          <RenderAll state={state} setState={handleState}/>
        )
      }
    </div>
  );
}

export default SchoolsSelect;