import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  configuration: any;
  pinterestToken: any;
};

const initialState: ArrayModel = {
  configuration: {},
  pinterestToken: null,
};

const slice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    setConfiguration(state, action: PayloadAction<Model>){
      state.configuration = action.payload;
    },
    updateConfiguration(state, action: PayloadAction<Model>){
      Object.assign(state.configuration, action.payload);
    },
    setPinterestToken(state, action: PayloadAction<Model>){
      state.pinterestToken = action.payload;
    },
  }
});

export default slice;