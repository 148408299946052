import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import React from 'react';
import RenderMenuItem from './RenderMenuItem';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { validateItemRequirements } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setIsOpenMoreMenu, setIsOpenSubMenu } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import LogoutMenuItem from './LogoutMenuItem';

interface Props {
  isStandalone: boolean;
};

const useStyles = createUseStyles((theme: any) => ({
  bottomMenu: {
    display: 'none',  
    position: 'fixed',
    zIndex: theme.zIndex.bottomBar,
    bottom: '0px',
    left: '0px',
    right: '0px',
    height: '56px',
    maxWidth: 'calc(100% - 20px)',
    width: '100%',
    padding: (props: Props) => {
      if(props.isStandalone) return '0 10px 10px 10px';
      else return '0 10px';
    },
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    justifyContent: 'flex-start !important',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '64px',
      flex: '1 1 60px',
      padding: '0px 3px',
      width: '100%',
      maxWidth: 'unset',
      color: theme.colors.grey[500],
      '& > span': {
        fontSize: '12px',
        whiteSpace: 'nowrap',
        maxWidth: '105%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        opacity: '1',
      },
      '&.active': {
        color: theme.colors.primaryBlue[500],
      },
      '&.active.locked': {
        '& > svg': {
          color: theme.colors.yellow[500],
        },
      },
      '&.active.not-visible': {
        color: theme.colors.grey[400],
      },
      '& > svg': {
        color: 'inherit',
        width: '20px',
        height: '20px',
        '& path': {
          color: 'inherit',
        },
      },
    },
  },
  submenu: {
    position: 'fixed',
    top: '60px',
    left: '0',
    right: '0',
    bottom: (props: Props) => {
      if(props.isStandalone) return '66px';
      else return '56px';
    },
    backgroundColor: theme.colors.white,
    display: 'none',
    zIndex: theme.zIndex.bottomBarMenu,
    width: '100%',
    flexDirection: 'column',
    '& > span': {
      fontSize: '24px',
      fontWeight: '900',
      color: theme.colors.grey[800],
      padding: '12px 26px',
    },
    '&.active': {
      display: 'flex',
    },
  },
  submenuItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    overflowY: 'auto',
    width: 'calc(100% - 52px)',
    height: 'calc(100% - 24px)',
    maxHeight: 'calc(100% - 24px)',
    padding: '12px 26px 24px 26px',
  },
  submenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg:not(.chevron)': {
      marginLeft: '24px',
    },
    '& > span': {
      marginLeft: '18px',
      fontSize: '16px',
    },
    '& > svg.chevron': {
      marginRight: '24px',
      marginLeft: 'auto',
    },
  },
  moremenu: {
    position: 'fixed',
    top: '60px',
    left: '0',
    right: '0',
    bottom: (props: Props) => {
      if(props.isStandalone) return '66px';
      else return '56px';
    },
    backgroundColor: theme.colors.white,
    display: 'none',
    zIndex: theme.zIndex.bottomBarMenu,
    width: '100%',
    flexDirection: 'column',
    '& > span': {
      fontSize: '24px',
      fontWeight: '900',
      color: theme.colors.grey[800],
      padding: '12px 26px',
    },
    '&.active': {
      display: 'flex',
    },
  },
  moremenuItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    overflowY: 'auto',
    width: 'calc(100% - 52px)',
    height: 'calc(100% - 24px)',
    maxHeight: 'calc(100% - 24px)',
    padding: '12px 26px 24px 26px',
  },
  moremenuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '64px',
    minHeight: '64px',
    maxHeight: '64px',
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#E0E4F5',
    borderRadius: '16px',
    color: theme.colors.grey[500],
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    padding: '0',
    '& > svg:not(.chevron)': {
      marginLeft: '24px',
    },
    '& > span': {
      marginLeft: '18px',
      fontSize: '16px',
    },
  },
  chevron: {
    marginRight: '24px',
    marginLeft: 'auto',
  }
}));

type ItemsType = {
  items: any;
  customRef: any;
  dataCy?: any;
};

const BottomMenu: React.FunctionComponent<ItemsType> = ({items, customRef, dataCy}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const userObject = userData.userObject;
  const userSettings = userData.userSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;
  const userMembership = userData.membership;

  const [state, setState] = useStates({
    count: 0,
  });

  const isOpenMoreMenu = layoutData.isOpenMoreMenu;

  const classes = useStyles({
    isStandalone: browserData.isStandalone,
  });

  const menuItems = items.map((item: any) => {
    return validateItemRequirements(item, { dataData: dataData, isCypress: isCypress, layoutData: layoutData, userAccess: userAccess, userAccessSchools: userAccessSchools, userMembership: userMembership, userObject: userObject, userSettings: userSettings, getUserRole: getUserRole, getUserSetting: getUserSetting});
  }).filter((item: any) => item !== null && (item.isVisible || (!item.isVisible && item.to === location.pathname)));
  
  menuItems.sort((a: any, b: any) => { return a.order - b.order; });

  useEffect(() => {
    dispatch(setIsOpenSubMenu(""));
    dispatch(setIsOpenMoreMenu(false));
  }, [location, dispatch], [location]);
  
  useEffect(() => {
    let size = 20;
    let tempCount = 0;
    menuItems.forEach(() => {
      size = size + 60 + 5;
      if(size < browserData.width) tempCount++;
    });
    if(menuItems.length !== tempCount) {
      tempCount--;
    }
    setState("count", tempCount);   
  }, [browserData.width, menuItems, setState], [browserData.width]);
  
  const handleMoreClick = () => {
    dispatch(setIsOpenSubMenu(""));
    dispatch(setIsOpenMoreMenu(true));
  };

  const isSelectedItemInMoreMenu = menuItems.length !== state.count ? (menuItems.filter((node: any) => location.pathname === node.to || location.pathname.includes(node.to)).length !== 0 ? (menuItems.slice(0, state.count).filter((node: any) => location.pathname === node.to || location.pathname.includes(node.to)).length === 0 ? true : false) : false) : false;

  return (
    <div className={classes.bottomMenu} ref={customRef} data-cy={isCypress() ? dataCy : null}>
      {
        state.count === menuItems.length && menuItems.map((node: any, key: any) => (
          <RenderMenuItem node={node} isMore={false} parent={null} key={`k_${key}`}/>   
        ))
      }
      {
        state.count !== menuItems.length && menuItems.slice(0, state.count).map((node: any, key: any) => (
          <RenderMenuItem node={node} isMore={false} parent={null} key={`k_${key}`}/>   
        ))
      }
    {
      state.count !== menuItems.length ? (
        <>
          <BottomNavigationAction label={t('more')} icon={<SVG src="more"/>} className={(isOpenMoreMenu || isSelectedItemInMoreMenu) ? 'active' : 'inactive'} onClick={handleMoreClick} data-cy={isCypress() ? "bottomBarMenuMore" : null}/>
          <div className={`${classes.moremenu} ${isOpenMoreMenu && 'active'}`}>
            <span>{t('more')}</span>
            <div className={classes.moremenuItems}>
              {
                menuItems.slice(state.count, menuItems.length).map((node: any, key: any) => (
                  <RenderMenuItem node={node} isMore={true} parent={null} key={`k_${key}`}/>
                ))
              }
              <LogoutMenuItem/>
            </div>
          </div>
        </> 
      ) : null
    }
    </div>
  );
}

export default BottomMenu;