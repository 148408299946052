import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { setTimetableHourHandleModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import BreakItem from '../BreakItem';

const useStyles = createUseStyles((theme: any) => ({
  breakBlock: {
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    fontSize: '32px',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '46px',
    height: '46px',
    minWidth: '46px',
    minHeight: '46px',
    maxWidth: '46px',
    maxHeight: '46px',
    position: 'relative',
    margin: '0 auto',
    overflow: 'hidden',
    '&:hover': {
      '& > button': {
        opacity: 1,
      },
    },
  },
  add: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      '& > button': {
        opacity: 1,
      },
    },
  },
  button: {
    display: "flex",
    opacity: "0",
    alignSelf: "center",
    transition: 'opacity 0.25s',
    width: '24px',
    height: '24px',
    maxWidth: '24px',
    maxHeight: '24px',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBlue[500],
    borderRadius: '100%',
    '&:hover': {
      color: theme.colors.white,
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '& > svg': {
      width: '12px',
      height: '12px',
    },
  },
}));

type BreakBlockType = {
  day: any;
  hour: any;
  isFirstBreakZero: any;
};

const BreakBlock: React.FunctionComponent<BreakBlockType> = ({day, hour, isFirstBreakZero}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const timetableData = useAppSelector((state: any) => state.timetable);

  const timetables = timetableData.data.timetables;
  const settings = timetableData.settings === null ? [] : timetableData.settings;

  const rooms = timetableData.rooms.filter((item: any) => item.type === 2);

  const breakData = timetables.filter((item: any) => item.hour === hour && item.weekday === day && item.type === 3).length === 1 ? timetables.find((item: any) => item.hour === hour && item.weekday === day && item.type === 3) : [];

  const handleCreateHour = (day: any, hour: any, type: any) => {
    const settings = {
      isOpen: true,
      weekday: day,
      hour: hour,
      type: type,
      mode: timetableData.type,
      isFirstBreakZero: isFirstBreakZero,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  return (
    <div className={classes.breakBlock}>
      {
        breakData.length === 0 ? (settings.canEdit && settings.canAdd && rooms.length > 0 && (timetableData.type === "break" || timetableData.type === "employee")) ? (
          <div className={classes.add} onClick={() => handleCreateHour(day, hour, 3)}>
            <IconButton className={classes.button}>
              <SVG src="plus"/>
            </IconButton>
          </div>
        ) : (
          <div/>
        ) : (
          <BreakItem day={day} hour={hour} breakData={breakData} isFirstBreakZero={isFirstBreakZero}/>
        )
      }
    </div>
  );
}

export default BreakBlock;