export const languages = [
  {
    lan: "Czech",
    nativeLan: "Čeština",
    acronym: "CS",
    flag: "cz",
    knowledgeBaseLink: "https://knowledge.twigsee.com/knowledge-base-tag/faq-webova-aplikace/",
    cookiesLink: "https://www.twigsee.com/soubory-cookies/",
    personalDataProtectionLink: "https://www.twigsee.com/prohlaseni-o-ochrane-osobnich-udaju/",
    premiumParentLink: "https://www.twigsee.com/premium-rodic/",
  },
  {
    lan: "German",
    nativeLan: "Deutsch",
    acronym: "DE",
    flag: "de",
    knowledgeBaseLink: "https://knowledge.twigsee.com/de/knowledge-base-tag/faq-web-anwendung/",
    cookiesLink: "https://www.twigsee.com/en/cookie-files/",
    personalDataProtectionLink: "https://www.twigsee.com/de/datenschutzerklarung/",
    premiumParentLink: "https://www.twigsee.com/de/premium-eltern-account/",
  },
  {
    lan: "English",
    nativeLan: "English",
    acronym: "EN",
    flag: "gb",
    knowledgeBaseLink: "https://knowledge.twigsee.com/en/knowledge-base-tag/faq-web-application/",
    cookiesLink: "https://www.twigsee.com/en/cookie-files/",
    personalDataProtectionLink: "https://www.twigsee.com/en/declaration-of-the-protection-of-personal-data/",
    premiumParentLink: "https://www.twigsee.com/en/premium-parent/",
  },
  {
    lan: "Spanish",
    nativeLan: "Español",
    acronym: "ES",
    flag: "es",
    knowledgeBaseLink: "https://knowledge.twigsee.com/es/knowledge-base-tag/faq-aplicacion-web/",
    cookiesLink: "https://www.twigsee.com/en/cookie-files/",
    personalDataProtectionLink: "https://www.twigsee.com/es/declaracion-sobre-la-proteccion-de-datos-personales/",
    premiumParentLink: "https://www.twigsee.com/es/padre-premium/",
  },
  {
    lan: "Slovak",
    nativeLan: "Slovenčina",
    acronym: "SK",
    flag: "sk",
    knowledgeBaseLink: "https://knowledge.twigsee.com/sk/knowledge-base-tag/faq-webova-aplikacia/",
    cookiesLink: "https://www.twigsee.com/sk/subory-cookies/",
    personalDataProtectionLink: "https://www.twigsee.com/sk/prehlasenie-o-ochrane-osobnych-udajov/",
    premiumParentLink: "https://www.twigsee.com/sk/premium-rodic-sk/",
  },
];

export const langs = ["CS","DE","EN","ES","SK"];