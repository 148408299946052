import IconButton from 'src/components/Buttons/IconButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setCookiesModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  cookiesButton: {
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    color: theme.colors.primaryBlue[500],
    backgroundColor: theme.colors.grey[250],
    borderRadius: "50%",
    padding: "10px",
    fontSize: "0.9rem",
    fontWeight: '600',
    width: "40px",
    height: "40px",
    cursor: "pointer",
    '& > svg': {
      width: '24px',
      height: '24px',
    },
  },
}));

const CookiesButton: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const layoutData = useAppSelector((state: any) => state.layout);
  const cookiesSettings = layoutData.cookies;

  const handleClick = () => {
    const settings = {
      isOpen: true,
    };
    dispatch(setCookiesModal(settings));
  };

  return (cookiesSettings.length !== 0 && !isCypress('cookies')) ? (
    <div className={classes.cookiesButton}>
      <IconButton className={classes.button} dataCy="cookiesMenuButton" onClick={handleClick} tooltip={t('cookies')}>
        <SVG src="cookie"/>
      </IconButton>
    </div>
  ) : null;
}

export default CookiesButton;