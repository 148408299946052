import * as icons from 'react-bootstrap-icons';
import { createUseStyles } from 'react-jss';
import { InView } from 'react-intersection-observer';
import { useStates } from 'src/utils/useState';

interface IconType extends icons.IconProps {
  iconName: keyof typeof icons;
};

const useStyles = createUseStyles((theme: any) => ({
  bootstrapIcon: {
    display: 'inline-flex',
  },
}));

const BootstrapIcon: React.FunctionComponent<IconType> = ({iconName, ...props}) => {

  const BootstrapIcon = icons[iconName];
  const classes = useStyles();
  
  const [state, setState] = useStates({
    isInView: false,
  });

  const updateView = (inView: any) => {
    setState("isInView", inView);
  };

  return (
    <InView className={classes.bootstrapIcon} onChange={(inView) => updateView(inView)}>
      {
        state.isInView ? (
          <BootstrapIcon {...props} />
        ) : null
      }
    </InView>
  );
};

export default BootstrapIcon;