import DatePicker from '../../../../../components/DatePickers/Classbook';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../../../utils/moment';
import NormalButton from '../../../../../components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import Select from '../../../../../components/Forms/Select';
import SVG from '../../../../../components/Images/SvgRenderer';
import Switch from '../../../../../components/Forms/Switch';
import { CircularProgress } from '@mui/material';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setClassbookDate, setClassbookFilterClassID, setClassbookFilterSchoolID, setClassbookList } from '../../../../../store/actions/classbook.actions';
import { setClassbookHandleModal, setPostDetailModal } from '../../../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(60% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginTop: '16px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    position: 'relative',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: '10px',
      '& > div': {
        flex: 'unset !important',
      },
      '& > h2': {
        fontSize: '16px',
        fontWeight: '800',
        maxWidth: '80%',
        textAlign: 'center',
      },
      '& > h3': {
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '5px',
        maxWidth: '80%',
        textAlign: 'center',
        '& > svg': {
          width: '16px',
          height :'16px',
        }, 
      },
      '&.buttons': {
        gap: '0.25rem',
        '& > button': {
          zoom: '70%',
        },
      },
      [theme.breakpoints.down('sm')]: {
        flex: 'unset',
        width: '100%',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(80% - 60px)',
    },  
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      flexDirection: 'column',
      gap: '1rem',
      borderRadius: '0px',
    },
  },
  headerButtons: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    display: 'flex !important',
    flexDirection: 'row !important',
    gap: '0.25rem !important',
    width: 'auto !important',
    justifyContent: 'flex-end !important',
    [theme.breakpoints.down('sm')]: {
      width: 'unset !important',
      flexDirection: 'column !important',
    },
    '& > button': {
      '& svg': {
        color: theme.colors.primaryBlue[500],
        width: '24px',
        height: '24px',
      },
    },  
  },
  childrenCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '20px',
      height: '19px',
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '16px',
    }
  },
  infoPanel: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: 'calc(60% - 60px)',
    maxWidth: '100%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    position: 'relative',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 0 calc(100% / 4)',
      maxWidth: 'calc(100% / 4)',
      [theme.breakpoints.down('md')]: {
        flex: '1 0 calc(100% / 2)',
        maxWidth: 'calc(100% / 2)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        maxWidth: '50%',
        flex: 'unset',
      },  
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '100%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },  
        '& > strong': {
          fontSize: '16px',
          fontWeight: '700',
          width: '100%',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        },
        '& > ul': {
          listStyleType: 'none',
          paddingLeft: '0',
          marginTop: '0.5rem',
          '& > li': {
            fontSize: '13px',
            [theme.breakpoints.down('sm')]: {
              fontSize: '10px',
            },
            '& > strong': {
              fontWeight: '500',
              whiteSpace: 'break-spaces',
            },
          },
        },
        '& > span': {
          margin: '0.75rem auto 0 auto',
          width: 'fit-content',
          fontSize: '1.25rem',
          fontWeight: '500',
          cursor: 'pointer',
          color: theme.colors.primaryBlue[500] + ' !important',
          '&:hover': {
            textDecoration: 'underline',
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            fontSize: '1rem',
          },
        },
        '& > p': {
          margin: '0.75rem auto 0 auto',
          textAlign: 'center',
          fontSize: '1.25rem',
          fontWeight: '500',
          [theme.breakpoints.down('sm')]: {
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            fontSize: '1rem',
          },
        },
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(80% - 60px)',
    },  
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    marginBottom: '20px',
    padding: '20px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    gap: '1rem',
    '& > p': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '0',
      maxWidth: '100%',
      '& > img': {
        maxWidth: '100%',
      },
      '& > button': {
        marginTop: '0.5rem',
      },
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(80% - 60px)',
    },  
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 60px)',
      borderRadius: '0px',
    },
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    '& > h4': {
      fontSize: '16px',
    },
    '& > p': {
      fontSize: '13px',
      marginBottom: '0',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: '100%',
    paddingTop: '16px',
  },
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type DayviewType = {
  filters: any,
};

const Dayview: React.FunctionComponent<DayviewType> = ({filters}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const classbookService = useAppSelector((state: any) => state.services).classbookService;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;

  const activeClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active).length;
  const inactiveClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === true || !theclass.active).length;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);


  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const currentSchool = filters.schoolID ? filters.schoolID[0] : null;
  const currentClass = filters.classID ? filters.classID[0] : null;
  const currentDate = filters.date ? moment(filters.date) : moment();
  const isSchoolCare = currentClass ? getClassData(currentClass).isSchoolCare : false;
  const startDate = moment(currentDate).startOf('isoWeek');
  const endDate = moment(currentDate).startOf('isoWeek').add(6, "days");
  const list = classbookData.list;
  const currentList = currentClass ? (list.filter((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD")).length === 0 ? [] : list.filter((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD"))[0].records) : [];
  const record = currentList.length !== 0 ? (list.filter((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD")).length === 0 ? {} : list.filter((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD")).length === 0 ? null : (list.find((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD")).records.filter((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(currentDate).format("YYYY-MM-DD")).length === 0 ? null : list.find((item: any) => item.classID === currentClass && moment(item.startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD") && moment(item.endDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD")).records.find((item: any) => moment(item.date).format("YYYY-MM-DD") === moment(currentDate).format("YYYY-MM-DD")))) : {};

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const [state, setState] = useStates({
    isReady: false,
    isChange: false,
    isArchived: currentClass === null ? (activeClasses === 0 ? (inactiveClasses > 0 ? true : false) : false) : ((getClassData(currentClass).isArchived || !getClassData(currentClass).active) ? true : false),
  });

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      if(state.isArchived) {
        return theChild;
      } else {
        if(!theChild.isArchived) {
          return theChild;
        } else {
          return null;
        }
      }
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.filter((item: any) => item.classbookType === "daily").map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        if(state.isArchived) {
          if(theClass.isArchived) {
            return {...theClass, icon: "archived"};
          } else if(!theClass.active) {
            return {...theClass, icon: "inactive"};
          } else {
            return theClass;
          }
        } else {
          if(!theClass.isArchived && theClass.active) {
            return theClass;
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const getAllClasses = () => {
    return listClasses.filter((item: any) => item.classbookType === "daily").map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const classList = getClasses().map((theClass: any) => {
    if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
      return theClass;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const showArchivedToggle = () => {
    const countInactive = getAllClasses().map((theClass: any) => {
      if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
        if(theClass.isArchived || !theClass.active) {
          return theClass;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null).length;
    const countActive = getAllClasses().map((theClass: any) => {
      if((currentSchool === null && theClass.schoolID === schoolList[0].schoolID) || (currentSchool !== null && theClass.schoolID === currentSchool)) {
        if(!theClass.isArchived && theClass.active) {
          return theClass;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter((value: any) => value !== null).length;
    return (countInactive > 0 && countActive > 0) ? true : false;
  };


  const handleChangeSchool = (value: any) => {
    const newSchool = value.schoolID;
    const newClass = getClasses().filter((theClass: any) => theClass.schoolID === value.schoolID)[0].classID;
    dispatch(setClassbookFilterSchoolID([newSchool]));
    dispatch(setClassbookFilterClassID([newClass]));
    loadRecords(newClass, currentDate, list);
    setState("isArchived", false);
  };

  const handleChangeClass = (value: any) => {
    const newClass = value.classID;
    dispatch(setClassbookFilterClassID([newClass]));
    loadRecords(newClass, currentDate, list);
  };

  const handleChangeDate = (value: any) => {
    dispatch(setClassbookDate(moment(value)));
    loadRecords(currentClass, value, list); 
  };

  const handleArchived = () => {
    if(state.isArchived) {
      if(getClassData(currentClass).isArchived || !getClassData(currentClass).active) {
        const newClass = getClasses().filter((theClass: any) => theClass.schoolID === currentSchool && !theClass.isArchived && theClass.active)[0].classID;
        dispatch(setClassbookFilterClassID([newClass]));
        loadRecords(newClass, currentDate, list); 
      };
    }
    setState("isArchived", !state.isArchived);
  };

  const handleChange = () => {
    setState("isChange", !state.isChange);
  };

  const uniqueChildren = (children: any) => {
    let uniqueChildrenList: any = [];
    children.forEach((child: any) => {
      if(uniqueChildrenList.indexOf(child.childID) === -1) {
        uniqueChildrenList.push(child.childID);
      }
    });
    return uniqueChildrenList; 
  };

  const loadRecords = useCallback((tempCurClass: any, tempCurDate: any, tempRecordsList: any) => {
    const newDate = moment(tempCurDate).format("YYYY-MM-DD");
    const tempStartDate = moment(tempCurDate).startOf('isoWeek').format("YYYY-MM-DD");
    const tempEndDate = moment(tempCurDate).startOf('isoWeek').add(6, "days").format("YYYY-MM-DD");
    if(tempRecordsList.filter((item: any) => item.startDate === tempStartDate && item.endDate === tempEndDate && item.classID === tempCurClass).length === 0) {
      classbookService && classbookService.listRecords(tempCurClass, newDate).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.classbookRecords) {
              const payload = {
                classID: tempCurClass,
                dateFrom: tempStartDate,
                dateTo: tempEndDate,
                from: "classbook",
              };
              const recordsList = result.data.classbookRecords;
              timelineService && timelineService.listPosts(payload).then((result: any) => {
                const posts = result.data.posts;
                const recordData = recordsList.map((record: any) => {
                  return {...record, postCount: posts.filter((post: any) => moment(post.created).format("YYYY-MM-DD") === moment(record.date).format("YYYY-MM-DD") && post.activityTypeID !== 15 && post.postID !== 0).map((post: any) => { return post.postID }), noteCount: posts.filter((post: any) => moment(post.created).format("YYYY-MM-DD") === moment(record.date).format("YYYY-MM-DD") && post.activityTypeID === 15 && post.postID !== 0).map((post: any) => { return post.postID })};
                });
                const recordsData = {startDate: tempStartDate, endDate: tempEndDate, classID: tempCurClass, records: recordData};
                dispatch(setClassbookList(recordsData)); 
                setState("isReady", true);
              }).catch(() => {
                createNotification(t("classbook_failed_load"), "error");
              });
            } else {
              createNotification(t("classbook_failed_load"), "error");
              setState("isReady", true);
            }
          } else {
            createNotification(t("classbook_failed_load"), "error");
            setState("isReady", true);
            return;
          }
        } else {
          createNotification(t("classbook_failed_load"), "error");
          setState("isReady", true);
          return;
        }
      }).catch(() => {
        createNotification(t("classbook_failed_load"), "error");
        setState("isReady", true);
      });
    } else {
      setState("isReady", true);
    }
  }, [classbookService, dispatch, setState, t, timelineService]);

  const handleRecord = () => {

    const isoWeekDay = moment(record.date).isoWeekday();
    const isImport = isoWeekDay === 1 || record.morning !== "" || record.afternoon !== "" || record.theme !== "" || record.visit !== "" || record.notes !== "" ? false : true;
    const importData = isImport ? currentList[isoWeekDay - 2] : null;
    const isReallyImport = importData !== null ? (importData.morning !== "" || importData.afternoon !== "" || importData.theme !== "" || importData.visit !== "" || importData.notes !== "" ? true : false) : false;

    const settings = {
      isOpen: true,
      date: moment(currentDate).format("YYYY-MM-DD"),
      classID: currentClass,
      isImport: isSchoolCare ? false : isReallyImport,
      importData: isSchoolCare ? false : importData,
    };
    dispatch(setClassbookHandleModal(settings)); 
  };

  const handleClickPosts = (posts: any) => {
    const settings = {
      isOpen: true,
      postID: posts,
    };
    dispatch(setPostDetailModal(settings)); 
  };

  useEffect(() => {
    loadRecords(currentClass, currentDate, list);
    return () => {
      dispatch(setClassbookList([]));
    };
  }, [classList, currentClass, currentDate, currentSchool, dispatch, list, loadRecords, schoolList], []);

  return state.isReady ? (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerButtons}>
          <IconButton tooltip={`${state.isChange ? t("close") : t("change_school_class")}`} tooltipPosition='left' onClick={handleChange} dataCy='filter'>
            {
              state.isChange ? (
                <SVG src="close"/>
              ) : (
                <SVG src="filter"/>
              )
            }
          </IconButton>
        </div>
        <div>
          <DatePicker presetDate={currentDate} setDate={handleChangeDate} mode="spinner" dataCy='datePicker'/>
          <h2 data-cy={isCypress() ? 'currentSchool' : null}>{htmlParse(getSchoolData(currentSchool).name)}</h2>
          <h3 data-cy={isCypress() ? 'currentClass' : null}>
            {(currentClass && getClassData(currentClass).isArchived) ? (<SVG src="archived"/>) : (currentClass && !getClassData(currentClass).active) ? (<SVG src="inactive"/>) : null}
            {htmlParse(getClassData(currentClass).name)}
            <span className={classes.childrenCount} data-cy={isCypress() ? 'childrenCountWrapper' : null}>
              <SVG src="people"/>
              <span data-cy={isCypress() ? 'childrenCount' : null}>{uniqueChildren(getChildren().filter((theChild: any) => theChild.classID.indexOf(currentClass) !== -1)).length}</span>
            </span>
          </h3>
        </div>
        {
          state.isChange && (
            <div>
              <Select inputLabel={t("school")} items={schoolList} selected={(schoolList && schoolList.length > 0) ? (currentSchool === null ? schoolList[0] : getSchoolData(currentSchool)) : null} setSelected={handleChangeSchool} width={200} allowClear={false} readonly={schoolList.length === 1} dataCy='school'/>
              <Select inputLabel={t("class")} items={classList} selected={(classList && classList.length > 0) ? (currentClass=== null ? ((classList[0].isArchived || !classList[0].active) ? {...classList[0], icon: "archived"} : classList[0]) : ((getClassData(currentClass).isArchived || !getClassData(currentClass).active) ? {...currentClass, icon: "archived"} : getClassData(currentClass))) : null} setSelected={handleChangeClass} width={200} allowClear={false} readonly={classList.length === 1} dataCy='class'/>
              {
                showArchivedToggle() ? (
                  <Switch checked={state.isArchived} onChange={handleArchived} label={t('archived')} dataCy='archivedSwitch'/>
                ) : null
              }
            </div>
          )
        }
      </div>
      {
        record !== null ? (
          <div className={classes.infoPanel}>
            {
              !isSchoolCare ? (
                <>
                  <div>
                    <div>
                      <strong data-cy={isCypress() ? 'absence' : null}>{t('excuse_notes')}</strong>
                      <ul>
                        <li data-cy={isCypress() ? 'absenceWithApology' : null}>
                          <strong>{t('excused_absence')}</strong>: {(record.absence && record.absence.withApology) ? record.absence.withApology : 0}
                        </li>
                        <li data-cy={isCypress() ? 'absenceWithoutApology' : null}>
                          <strong>{t('excused_not_absence')}</strong>: {(record.absence && record.absence.withoutApology) ? record.absence.withoutApology : 0}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div>
                      <strong data-cy={isCypress() ? 'attendance' : null}>{t('attendance')}</strong>
                      <ul>
                        <li data-cy={isCypress() ? 'attendanceAM' : null}>
                          <strong>{t('morning')}</strong>: {(record.attendance && record.attendance.am) ? record.attendance.am : 0}
                        </li>
                        <li data-cy={isCypress() ? 'attendancePM' : null}>
                          <strong>{t('afternoon')}</strong>: {(record.attendance && record.attendance.pm) ? record.attendance.pm : 0}
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : null
            }
            <div>
              <div>
                <strong data-cy={isCypress() ? 'posts' : null}>{t('posts')}</strong>
                {
                  ((record.postCount && record.postCount.length === 0) || currentList.length === 0) ? (
                    <p data-cy={isCypress() ? 'posts' : null}>0</p>
                  ) : (
                    <span onClick={() => handleClickPosts(record.postCount ? record.postCount : 0)} data-cy={isCypress() ? 'postsCount' : null}>
                      {record.postCount ? record.postCount.length : 0}
                    </span>
                  )
                }
              </div>
            </div>
            <div>
              <div>
                <strong data-cy={isCypress() ? 'notes' : null}>{t('notes')}</strong>
                {
                  ((record.noteCount && record.noteCount.length === 0) || currentList.length === 0) ? (
                    <p data-cy={isCypress() ? 'notesCount' : null}>0</p>
                  ) : (
                    <span onClick={() => handleClickPosts(record.noteCount ? record.noteCount : 0)} data-cy={isCypress() ? 'notesCount' : null}>
                      {record.noteCount ? record.noteCount.length : 0}
                    </span>
                  )
                }
              </div>
            </div>
          </div>
        ) : null
      }
      <div className={classes.detail}>
        {
          currentList.length === 0 ? (
            <CircularProgress className={classes.spinner}/>
          ) : (
            <>
              {
                record === null ? (
                  <p>
                    <img src="/resources/images/noresults.png" alt='no_results'/>
                    {t('no_record_for_day')}
                    {
                      (!getClassData(currentClass).isArchived && getClassData(currentClass).active) ? (
                        <NormalButton buttonType="primary" onClick={handleRecord}>
                          {t('create_new_record')}
                        </NormalButton>
                      ) : null
                    }
                  </p>
                ) : (
                  <>
                  {
                    (isSchoolCare ? (record.interestActivities === "" && record.relaxationRecreationalActivities && record.preparationTeaching && record.otherActivities && record.internalNote) : (record.theme === "" && record.morning === "" && record.afternoon === "" && record.notes === "" && record.visit === "")) ? (
                        <p data-cy={isCypress() ? 'noRecord' : null}>
                          <img src="/resources/images/noresults.png" alt='no_results'/>
                          {t('no_record_for_day')}
                          {
                            (!getClassData(currentClass).isArchived && getClassData(currentClass).active) ? (
                              <NormalButton buttonType="primary" onClick={handleRecord} dataCy='createRecordButton'>
                                {t('create_new_record')}
                              </NormalButton>
                            ) : null
                          }
                        </p>
                      ) : (
                        <>
                          {
                            (record.theme && record.theme !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('theme')}
                                </h4>
                                <p data-cy={isCypress() ? 'themeValue' : null}>{record.theme}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.morning && record.morning !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('morning_educational_activities')}
                                </h4>
                                <p data-cy={isCypress() ? 'morningValue' : null}>{record.morning}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.afternoon && record.afternoon !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('afternoon_educational_activities')}
                                </h4>
                                <p data-cy={isCypress() ? 'afternoonValue' : null}>{record.afternoon}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.notes && record.notes !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('additional_notes')}
                                </h4>
                                <p data-cy={isCypress() ? 'notesValue' : null}>{record.notes}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.visit && record.visit !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('record_visits')}
                                </h4>
                                <p data-cy={isCypress() ? 'visitValue' : null}>{record.visit}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.interestActivities && record.interestActivities !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('interest_activities')}
                                </h4>
                                <p data-cy={isCypress() ? 'interestActivitiesValue' : null}>{record.interestActivities}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.relaxationRecreationalActivities && record.relaxationRecreationalActivities !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('relaxation_recreational_activities')}
                                </h4>
                                <p data-cy={isCypress() ? 'relaxationRecreationalActivitiesValue' : null}>{record.relaxationRecreationalActivities}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.preparationTeaching && record.preparationTeaching !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('preparation_teaching')}
                                </h4>
                                <p data-cy={isCypress() ? 'preparationTeachingValue' : null}>{record.preparationTeaching}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.otherActivities && record.otherActivities !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('other_activities')}
                                </h4>
                                <p data-cy={isCypress() ? 'otherActivitiesValue' : null}>{record.otherActivities}</p>
                              </div>
                            ) : null
                          }
                          {
                            (record.internalNote && record.internalNote !== "") ? (
                              <div className={classes.formWrapper}>
                                <h4>
                                  {t('internal_note')}
                                </h4>
                                <p data-cy={isCypress() ? 'internalNoteValue' : null}>{record.internalNote}</p>
                              </div>
                            ) : null
                          }
                          {
                            (!getClassData(currentClass).isArchived && getClassData(currentClass).active) ? (
                              <div className={classes.buttons}>
                                {
                                  (record.theme !== "" || record.morning !== "" || record.afternoon !== "" || record.notes !== "" || record.visit !== "") ? (
                                    <NormalButton buttonType="primary" onClick={handleRecord} dataCy='editRecordButton'>
                                      {t('edit_record')}
                                    </NormalButton>
                                  ) : (
                                    <NormalButton buttonType="primary" onClick={handleRecord} disabled={(record.theme !== "" || record.morning !== "" || record.afternoon !== "" || record.notes !== "" || record.visit !== "") ? false : true} dataCy='createRecordButton'>
                                      {t('create_record')}
                                    </NormalButton>
                                  )
                                }
                              </div>
                            ) : null
                          }
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>
  ) : (
    <div className={classes.loadingWrapper}>
      <div className={classes.spinnerWrapper}>
        <CircularProgress/>
      </div>
    </div>
  );
};

export default Dayview;