import React from 'react';
import { createUseStyles } from 'react-jss';
import { Tooltip as MuiTooltip } from '@mui/material';
import { isMobile } from 'react-device-detect';

interface Props {
  maxWidth: any;
  multiline: any;
};

const useStyles = createUseStyles(() => ({
  tooltipCustom: {
    maxWidth: (props: Props) => props.maxWidth + 'px',
    whiteSpace: (props: Props) => {
      if(props.multiline) return 'pre-line';
      else return '';
    },
  },
}));

type TooltipType = {
  title?: any;
  position?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  arrow?: boolean;
  maxWidth?: any;
  multiline?: boolean;
  children?: any;
  className?: any;
  disableTouchListener?: boolean;
  disableHoverListener?: boolean;
  disableInteractive?: boolean;
  disableOnMobile?: boolean;
};

const Tooltip: React.FunctionComponent<TooltipType> = ({ title, position = "right", arrow = true, maxWidth = '100', multiline = false, children, className, disableTouchListener, disableHoverListener, disableInteractive, disableOnMobile}) => {

  const classes = useStyles({
    maxWidth: maxWidth,
    multiline: multiline,
  });

  return (
    <MuiTooltip title={title} classes={{ tooltip: `${classes.tooltipCustom} ${className ? className : null}` }} placement={position} arrow={arrow} open={(disableOnMobile && isMobile) ? false : undefined} disableTouchListener={disableTouchListener} disableHoverListener={disableHoverListener} disableInteractive={disableInteractive}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;