import * as DirectorInspirationService from '../../../../services/director/inspiration.service';
import * as ParentInspirationService from '../../../../services/parent/inspiration.service';
import * as TeacherInspirationService from '../../../../services/teacher/inspiration.service';
import httpResult from 'src/constants/httpresult';
import { getUserRole } from 'src/utils/useUser';
import { setPinterestToken } from 'src/store/actions/configuration.actions';

const pinterestToken = async (dispatch: any, userData: any) => {

  const inspirationServices: any = {
    parent: ParentInspirationService,
    teacher: TeacherInspirationService,
    director: DirectorInspirationService,
  };

  const inspirationService = inspirationServices[getUserRole(userData.userObject.roleType)];

  try {
    const result: any = await inspirationService.getPinterestToken();
    if(result && result.status === httpResult.SUCCESS) {
      const response = result.data;
      const token = response.token;
      dispatch(setPinterestToken(token));
      return true;
    } else {
      return true;
    }
  } catch (e: any) {
    return true; 
  };
};

export default pinterestToken;