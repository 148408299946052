import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Clamp from 'react-multiline-clamp';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import hexToRgba from 'hex-to-rgba';
import Hotkeys from 'react-hot-keys';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import NavigationButton from '../../Buttons/NavigationButton';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import ReactionsList from 'src/components/Reactions/List';
import ReactionsPopper from 'src/components/Reactions/Popper';
import RenderAttachment from './RenderAttachment';
import RenderPhoto from './RenderPhoto';
import RenderVideo from './RenderVideo';
import SVG from '../../Images/SvgRenderer';
import TabsMenu from 'src/components/Menus/TabsMenu';
import TimelineGallery from 'src/components/Gallery/TimelineGallery';
import { base64toBlob, formatFileSize, getSchoolSettings, handleSum, removeDuplicatesJSON, removeParamsFromUrl } from 'src/utils/useFunctions';
import { Button, CircularProgress, Skeleton } from '@mui/material';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { saveAs } from 'file-saver';
import { setChildCardModal, setTimelineGalleryModal } from '../../../store/actions/modals.actions';
import { setFilterParams } from '../../../store/actions/filters.actions';
import { setGalleryMedias } from 'src/store/actions/gallery.actions';
import { setIsFiltersVisible } from '../../../store/actions/layout.actions';
import { setTimelineMedias, setTimelinePosts } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  activityTypeBackgroundColorParam?: any;
  activityTypeColorParam?: any;
  browserHeight?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "0px",
    backgroundColor: theme.colors.alwaysBlack,
    overflow: "auto",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: (props: Props) => props.browserHeight + 'px',
    maxHeight: (props: Props) => props.browserHeight + 'px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper: {
    display: 'flex',
    gap: '12px',
    flex: '0 0 auto',
  },
  previewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    "& > button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0px",
      outline: "none",
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: theme.colors.grey[400],
      minWidth: "48px",
      minHeight: "48px",
      width: "48px",
      height: "48px",
      maxWidth: "48px",
      maxHeight: "48px",
      padding: '0',
      fontSize: "20px",
      cursor: "pointer",
      opacity: "1",
      transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
      zIndex: '3',
      transform: 'translateY(-50%)',
      '& svg': {
        width: '24px',
        height: '24px',
      },
      "&:first-of-type": {
        position: "absolute",
        top: "50%",
        left: "0px",
        borderRadius: "0px 100% 100% 0px",
      },
      "&:last-of-type": {
        position: "absolute",
        top: "50%",
        right: "0px",
        borderRadius: "100% 0px 0px 100%",
      },
      "&:hover": {
        backgroundColor: 'rgba(110, 113, 145, 0.9)',
        color: theme.colors.grey[400],
      },
      "&:disabled": {
        opacity: "0",
        pointerEvents: "none",
      },
    },
  },
  preview: {
    width: 'calc(100% - 128px)',
    maxWidth: 'calc(100% - 128px)',
    height: 'calc(100vh - 160px)',
    margin: '16px 0',
    overflow: 'hidden',
    position: 'relative',
    flex: '1 0 calc(70% - 128px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  button: {
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
    '&.Mui-disabled': {
      '& svg': {
        filter: 'grayscale(100%)',
      }
    },
  },
  sidebar: {
    backgroundColor: theme.colors.white,
    flex: '0 0 30%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 32px)',
    maxHeight: 'calc(100% - 32px)',
    width: 'calc(100% - 32px)',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      maxHeight: 'unset',
    },
  },
  authorInformation: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    '& .square img': {
      width: '100%',
      height: '100%',
    },
    '& .width img': {
      height: '100%',
    },
    '& .height img': {
      width: '100%',
    },
  },
  authorInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60px',
  },
  authorImageContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minWidth: '60px',
    minHeight: '60px',
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      minHeight: '40px',
      width: '40px',
      height: '40px',
      maxWidth: '40px',
      maxHeight: '40px',
    },
  },
  authorImage: {
    width: '48px',
    height: '48px',
    borderRadius: '12px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
      height: '38px',
    },
  },
  authorName: {
    color: theme.colors.grey[800],
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.25s',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    '&:hover': {
      color: theme.colors.primaryBlue[500],
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  infobox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "0.5rem",
  },
  childrenbox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "0.5rem",
    overflow: 'auto',
    flex: '1 1 auto',
  },
  medias: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflowY: 'auto',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '4px',
    }
  },
  moreWrapper: {
    position: 'relative',
    marginLeft: 'auto',
    display: 'flex',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '15px',
    paddingBottom: '0.5rem',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    '& > svg': {
      width: '17px',
      height: '17px',
    }
  },
  tabs: {
    width: 'calc(100% - 32px) !important',
    padding: '0 16px',
  },
  mediaTypeBadge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '6px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: '#EEEEEE',
    '& img': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    '& svg': {
      width: '12px',
      height: '12px',
      marginRight: '5px',
    },
  },
  activityType: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px',
    width: 'fit-content',
    backgroundColor: (props: Props) => props.activityTypeBackgroundColorParam,
    color: (props: Props) => props.activityTypeColorParam,
    fontSize: '10px',
    cursor: 'pointer',
    '& > img': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  childrenListWrapper: {
    position: 'relative',
    width: "auto",
    marginLeft: '0',
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5rem',
    overflow: 'auto',
  },
  child: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    fontSize: '14px',
    cursor: "pointer",
    maxWidth: 'calc(20% - 0.5rem)',
    width: '15%',
    padding: '8px',
    borderRadius: '24px',
    transition: 'all 0.25s',
    height: '92px',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(20% - 0.5rem)',
      width: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(25% - 0.5rem)',
      width: '25%',
    },
  },
  childPhoto: {
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    backgroundColor: theme.colors.white,
    position: 'relative',
  },
  childDisplayName: {
    maxWidth: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '6px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
  starButton: {
    width: '42px',
    height: '42px',
    minWidth: 'unset',
    borderRadius: '100%',
    backgroundColor: '#EEEEEE',
    color: theme.colors.black,
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&.active': {
      '& svg': {
        color: theme.colors.yellow[500], 
      },
    },
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
    '& > *': {
      flex: '1 1 calc(0.25rem)',
      '& > span': {
        [theme.breakpoints.down('sm')]: {
          zoom: '85%',
        },
      },
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customWidth: {
    minWidth: "10px !important"
  },
  closeButton: {
    position: 'fixed',
    top: '0.5rem',
    left: '0.5rem',
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
  },
  mediaInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginLeft: '8px',
    padding: '0',
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '13px',
      '& > span': {
        color: theme.colors.grey[700],
        fontWeight: '600',
        whiteSpace: 'nowrap',
      },
      '& > p': {
        fontSize: '11px',
      },
    },
  },
  toolbar: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '8px',
    display: 'flex',
    gap: '8px',
    '& > button': {
      color: theme.colors.white,
      transition: 'background-color 0.25s',
      backgroundColor: 'rgba(255,255,255,0)',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
      '&:disabled': {
        color: '#4C4C4C',
      },
    },
  },
  reactions: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
      '& > span': {
        textDecoration: 'underline',
      },
    },
  },
  reactionsAvatarGroup: {
    '&:empty': {
      display: 'none',
    }
  },
  reaction: {
    width: '24px',
    height: '24px',
    fontSize: '90%',
    backgroundColor: theme.colors.grey[200],
  },
  totalReactions: {
    fontSize: '12px',
  },
  reactionsPopperWrapper: {
    backgroundColor: theme.colors.white,
    borderRadius: '14px',
    boxShadow: theme.shadows[3],
    display: 'flex',
    fontSize: '22px',
    gap: '8px',
    padding: '6px 8px',
    position: 'relative',
    zIndex: theme.zIndex.popperWrapper,
  },
  reactionsWrapper: {
    position: 'relative',
    minWidth: '120px',
    '& > button': {
      width: '100%',
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  reactionEmoji: {
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.25s',
    padding: '4px',
    '&.active': {
      backgroundColor: theme.colors.grey[200],
      borderRadius: '100%',
    },
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

const TimelineGalleryModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const mediasData = useAppSelector((state: any) => state.medias);
  const timelineMediasData = useAppSelector((state: any) => state.timeline).medias;
  const galleryMediasData = useAppSelector((state: any) => state.gallery).medias;
  const configurationData = useAppSelector((state: any) => state.configuration);
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const mediasList = modalsData.timelineGalleryModal.mediaList;
  const mediaID = modalsData.timelineGalleryModal.mediaID;
  const uniqueID = modalsData.timelineGalleryModal.uniqueID;
  const getPostData = useMemo(() => timelineData.posts.find((post: any) => post.uniqueID === uniqueID), [timelineData.posts, uniqueID]);
  const postData = useMemo(() => { return {...getPostData}; }, [getPostData]);

  const [state, setState] = useStates({
    isDownloadable: false,
    isLoaded: false,
    isLoadedMedia: false,
    isFullscreen: false,
    isFirst: false,
    isLast: false,
    lastLocation: "",
    metadata: {},
    canClick: true,
    zoomLevel: (1).toFixed(2),
    viewMode: 'children',
  });

  const allMediasData = timelineMediasData;
  const basicMediaData: any = mediasList.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : mediasList.find((media: any) => media.mediaID === mediaID);
  const mediaData: any = allMediasData.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : allMediasData.find((media: any) => media.mediaID === mediaID);
  const mediaIndex: any = mediasList.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : mediasList.findIndex((media: any) => media.mediaID === mediaID);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authorData = useMemo(() => postData ? (dataData.users.filter((user: any) => user.userID === postData.authorID).length === 0 ? dataData.users.find((user: any) => user.userID === 0) : dataData.users.find((user: any) => user.userID === postData.authorID)) : dataData.users.find((user: any) => user.userID === 0), [dataData.users, postData]);
  const activityTypes = useMemo(() => configurationData.configuration.activityTypes, [configurationData.configuration.activityTypes]);
  const activityType = useMemo(() => postData ? (activityTypes.filter((activity: any) => activity.activityTypeID === postData.activityTypeID).length === 0 ? null : activityTypes.find((activity: any) => activity.activityTypeID === postData.activityTypeID)) : null, [activityTypes, postData]);
  const activityTypeBackgroundColor = useMemo(() => activityType ? hexToRgba(activityType.color, '0.2') : '', [activityType]);
  const activityTypeColor = useMemo(() => activityType ? activityType.color : '', [activityType]);
  const children = useMemo(() => postData ? dataData.children.filter((child: any) => Object.values(postData.children.map((item: any) => { return item.childID; })).indexOf(child.childID) > -1) :  [], [dataData.children, postData]);
  const schoolSettings = userData.schoolSettings;
  const schoolReactions = useMemo(() => mediaData ? (getSchoolSettings(mediaData.schoolID, 'modules', schoolSettings) === null ? false : getSchoolSettings(mediaData.schoolID, 'modules', schoolSettings).reaction ? (getSchoolSettings(mediaData.schoolID, 'modules', schoolSettings).reaction.enable && getSchoolSettings(mediaData.schoolID, 'modules', schoolSettings).reaction.enableFor.medias) : false) : mediaData, [mediaData, schoolSettings]);

  const classes = useStyles({
    activityTypeBackgroundColorParam: activityTypeBackgroundColor,
    activityTypeColorParam: activityTypeColor,
    browserHeight: browserData.height,
  });

  const isFavorite = mediaData === null ? null : mediaData.favorite;

  const tabsItems: any = [
    {
      name: 'children',
      value: 'children',
      badge: children.length,
      badgeType: 'primary',
      isEnabled: true,
    },
    mediasList.length > 1 ? {
      name: 'media',
      value: 'media',
      badge: mediasList.length,
      badgeType: 'primary',
      isEnabled: true,
    } : null,
  ].filter((item: any) => item !== null);

  const onClose = useCallback(()  => {
    const settings = {
      isOpen: false,
      date: null,
      mediaID: null,
      uniqueID: null,
    };
    dispatch(setTimelineGalleryModal(settings));   
  }, [dispatch]);
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  }; 
  
  const handleDownload = () => {
    if(state.isDownloadable) {
      const fileType = (mediaData.name).split('.').pop();
      if(mediaData.type === "photo") {
        const imageData = mediasData.images.find((img: any) => img.url === removeParamsFromUrl(basicMediaData.fullsizeLink)).base64;
        const blob = base64toBlob(imageData, "image/" + fileType);
        saveAs(blob, mediaData.name);
      } else if(mediaData.type === "video") {
        const videoData = mediasData.videos.find((video: any) => video.id === mediaID).base64;
        const blob = base64toBlob(videoData, "video/" + fileType);
        saveAs(blob, mediaData.name);
      } else if(mediaData.type === "attachment") {
        const attachmentData = mediasData.attachments.find((attachment: any) => attachment.id === mediaID).base64;
        const blob = base64toBlob(attachmentData, "application/octet-stream");
        saveAs(blob, mediaData.name);
      }
    }
  };
  
  const updateAttribute = (value: any) => {
    const newItems = galleryMediasData.map((dates: any) => {
      return {...dates, medias: dates.medias.map((media: any) => {
        if(media.mediaID === mediaID) {
          return {...media, attributes: media.attributes.map((attribute: any) => {
            if(attribute.name === 'favorite') {
              return {...attribute, value: value};
            } else {
              return attribute;
            }
          })};
        } else {
          return media;
        }
      })};
    });
    dispatch(setGalleryMedias(newItems));
  };

  const handleFavoriteButton = (e: any) => {
    if(state.canClick) {
      setState("canClick", false);
      let newValue = true;
      if(mediaData.favorite) {
        newValue = false;
      }
      const payload = {
        attributes: [
          {
            name: 'favorite',
            value: newValue
          }  
        ]
      }
      galleryService && galleryService.setAttribute(mediaID, payload).then((result: any) => {
        if(result.status === 200) {
          const newMediaList = mediasList.map((newMedia: any) => { 
            if(newMedia.mediaID === mediaID) {
              return {...newMedia, favorite: newValue};
            } else {
              return newMedia;
            }
          });
          const newTimelineMediasList = timelineData.medias.map((newMedia: any) => { 
            if(newMedia.mediaID === mediaID) {
              return {...newMedia, favorite: newValue};
            } else {
              return newMedia;
            }
          });
          dispatch(setTimelineGalleryModal({mediaList: newMediaList}));
          dispatch(setTimelineMedias(newTimelineMediasList));
          let newTimelineData = timelineData.posts.map((x: any) => { return {...x}});
          newTimelineData.forEach((post: any) => {
            if(post.uniqueID === modalsData.timelineGalleryModal.uniqueID) {
              if(mediaData.type === "photo") {
                post.photos = post.photos.map((media: any) => { return {...media, favorite: media.mediaID === mediaID ? newValue : media.favorite}; });
              }
              if(mediaData.type === "video") {
                post.videos = post.videos.map((media: any) => { return {...media, favorite: media.mediaID === mediaID ? newValue : media.favorite}; });
              }
              if(mediaData.type === "attachment") {
                post.files = post.files.map((media: any) => { return {...media, favorite: media.mediaID === mediaID ? newValue : media.favorite}; });
              }
            }
          });
          dispatch(setTimelinePosts(newTimelineData));
          if(galleryMediasData.length > 0) {
            updateAttribute(newValue);
          }
          setState("canClick", true);
        }
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
      });
    }
  };

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose], []);
  
  useEffect(() => {
    if(state.lastLocation !== "") {
      onClose();
    } else {
      setState("lastLocation", location.pathname);
    }
  }, [location, onClose, setState, state.lastLocation], [location]);
  
  useEffect(() => {
    setState("isLoaded", true);
    if(timelineData.medias.filter((item: any) => item.mediaID === mediaID).length === 0) {
      const filter = {
        mediaID: mediaID,
        limit: mediasList.length,
      };
      timelineService && timelineService.listMedias(filter).then((result: any) => {
        const resultMedias = result.data.media;
        if(result.data.media.length > 0) {
          dispatch(setTimelineMedias(removeDuplicatesJSON([].concat(timelineData.medias, resultMedias))));
          setState("isLoadedMedia", true);
        } else {
          createNotification(t('something_went_wrong'), 'error');
          onClose();
        }
      }).catch(() => {
        createNotification(t('something_went_wrong'), 'error');
        onClose();
      });
    } else {
      setState("isLoadedMedia", true);
    }
    if(mediaIndex !== null) {
      if(mediaIndex === 0) {
        setState("isFirst", true); 
      } else {
        setState("isFirst", false);
      }
      if(mediaIndex === mediasList.length - 1) {
        setState("isLast", true); 
      } else {
        setState("isLast", false);
      }
    }
  }, [mediaID, mediaIndex, setState, dispatch, mediasList, timelineData.medias, timelineService, onClose, t], [mediaID]);
  
  const handleSecureClick = () => {
    setState("canClick", true);
  };

  const handleChildrenClick = (child: any) => {  
    const settings = {
      isOpen: true,
      childID: child.childID,
    };
    dispatch(setChildCardModal(settings));
  }; 
  
  const keyPress = (keyName: any, e: any, handle: any) => {
    if(keyName === "left") {
      prevMedia();
    } else if(keyName === "right") {
      nextMedia();
    }
  };

  const prevMedia = () => {
    if(state.canClick && mediaIndex !== 0) {
      setState("canClick", false);
      setState("isLoadedMedia", false);
      const newMediaID = mediasList[mediaIndex - 1].mediaID;
      dispatch(setTimelineGalleryModal({mediaID: newMediaID}));
    }
  };
  
  const nextMedia = () => {
    if(state.canClick && mediaIndex !== mediasList.length - 1) {
      setState("canClick", false);
      setState("isLoadedMedia", false);
      const newMediaID = mediasList[mediaIndex + 1].mediaID;
      dispatch(setTimelineGalleryModal({mediaID: newMediaID}));
    }
  };

  const handleAuthorNameClick = () => {
    navigate('/timeline');
    dispatch(setFilterParams({authorID: [authorData.userID]}));
    setTimeout(() => {
      dispatch(setFilterParams({authorID: [authorData.userID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleActivityTypeClick = () => {
    onClose();
    dispatch(setFilterParams({activityTypeID: [activityType.activityTypeID]}));
    setTimeout(() => {
      dispatch(setFilterParams({activityTypeID: [activityType.activityTypeID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleIsDownloadAble = (value: any) => {
    setState("isDownloadable", value);
  };

  const handleMetadata = (value: any) => {
    setState("metadata", value);
  };

  const handleZoomPlus = () => {
    if(state.zoomLevel < 2) {
      setState("zoomLevel", (parseFloat(state.zoomLevel) + 0.2).toFixed(2));
    }
  };

  const handleZoomMinus = () => {
    if(state.zoomLevel > 1) {
      setState("zoomLevel", (parseFloat(state.zoomLevel) - 0.2).toFixed(2));
    }
  };

  const handleSideFullscreen = () => {
    setState("isFullscreen", !state.isFullscreen);
  };

  const setViewMode = (value: any) => {
    setState("viewMode", value);
  };

  return basicMediaData === null ? null : (
    <Modal
      open={true} 
      onClose={onCloseModal}
      disableEscapeKeyDown={false}
    >
      <div className={classes.root} data-cy={isCypress() ? "timelineGalleryModal" : null}>
        <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
        <Hotkeys 
          keyName="left,right,space" 
          onKeyDown={keyPress}
        >
          {
            state.isLoaded ? (
              <div className={classes.previewWrapper}>
                <div className={classes.toolbar}>
                  {
                      mediaData?.type === "photo" ? (
                      <>
                      <IconButton disabled={state.zoomLevel >= (2).toFixed(2)} onClick={handleZoomPlus} data-cy={isCypress() ? 'timelineGalleryModalZoomPlusButton' : null}>
                        <SVG src="zoom-plus"/>
                      </IconButton>
                      <IconButton disabled={state.zoomLevel === (1).toFixed(2)} onClick={handleZoomMinus} data-cy={isCypress() ? 'timelineGalleryModalZoomMinusButton' : null}>
                        <SVG src="zoom-minus"/>
                      </IconButton>
                      </>
                    ) : null
                  }
                  <IconButton onClick={handleSideFullscreen} data-cy={isCypress() ? 'timelineGalleryModalFullscreenButton' : null}>
                    {
                      !state.isFullscreen ? (
                        <SVG src="fullscreen-enter"/>
                      ) : (
                        <SVG src="fullscreen-exit"/>
                      )
                    }
                  </IconButton>
                </div>
                <div className={classes.preview}>
                  {
                    mediaData?.type === "photo" ? (
                      <RenderPhoto mediaData={basicMediaData} zoomLevel={state.zoomLevel} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata}/>
                    ) : mediaData?.type === "video" ? (
                      <RenderVideo mediaData={basicMediaData} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata}/>
                    ) : mediaData?.type === "attachment" ? (
                      <RenderAttachment mediaData={basicMediaData} setIsDownloadable={handleIsDownloadAble} handleSecureClick={handleSecureClick} setMetadata={handleMetadata}/>
                    ) : (
                      <div>error</div>
                    )
                  }
                </div>
                {
                  mediasList.length > 1 ? (
                    <NavigationButton icon={<SVG src="chevron-left"/>} onClick={prevMedia} disabled={state.isFirst} dataCy="timelineGalleryModalPrevButton"/>
                  ) : null
                }
                {
                  mediasList.length > 1 ? (
                    <NavigationButton icon={<SVG src="chevron-right"/>} onClick={nextMedia} disabled={state.isLast} dataCy="timelineGalleryModalNextButton"/>
                  ) : null
                }
              </div>
            ) : (
              <div className={classes.preview}>
                <div className={classes.loading}>
                  <CircularProgress className={classes.spinner}/>
                </div>
              </div>
            )
          }
          {
            !state.isFullscreen ? (
              <div className={classes.sidebar}>
                {
                  state.isLoaded ? (
                    <>
                      <div className={classes.authorInformation}> 
                        <div className={classes.authorImageContainer}>
                          <AuthenticatedImage className={classes.authorImage} thumbLink={authorData.photo.thumbLink} fullsizeLink={authorData.photo.fullsizeLink} isClickable/>
                        </div>
                        <div className={classes.authorInfo}>
                          <span className={classes.authorName} onClick={handleAuthorNameClick} data-cy={isCypress() ? "timelineGalleryModalAuthorButton" : null}>
                            {authorData.displayName}
                          </span>
                          <span className={classes.date} data-cy={isCypress() ? 'timelineGalleryModalDateCreated' : null}>
                            <SVG src="clock"/>
                            {DateFormat(postData.created,"timeline",languageData, t)}
                          </span>
                        </div>
                        <div className={classes.moreWrapper}>
                          {
                            getUserRole(userData.userObject.roleType) === "parent" ? (
                              <Button className={`${classes.starButton} ${isFavorite ? 'active' : null}`} type="button" onClick={handleFavoriteButton} data-cy={isCypress() ? "timelineGalleryModalFavoriteButton" : null}>
                                <SVG src={isFavorite ? "star-filled" : "star"}/>
                              </Button>
                            ) : null
                          }
                        </div>
                      </div>
                      <div className={classes.infobox}>
                        <span className={classes.title}>
                          <SVG src="info-circle-outlined"/>
                          {t('media_info')}:
                        </span>          
                      {
                        (state.isLoadedMedia && mediaData) ? (
                          <>
                            <ul className={classes.mediaInfo}>
                              <li>
                                <span>{t('media_name')}:</span>
                                <p>{mediaData.name}</p>
                              </li>
                              {
                                mediaData.type === "photo" ? (
                                  <>
                                    <li>
                                      <span>{t('media_type')}:</span>
                                      <p className={classes.mediaTypeBadge} data-cy={isCypress() ? "timelineGalleryModalMediaType" : null}>
                                        <SVG src="image"/> {t("photo")}
                                      </p>
                                    </li>
                                    {
                                      Object.keys(state.metadata).length > 0 ? (
                                        <>
                                          <li>
                                            <span>{t('media_resolution')}:</span>
                                            <p>{state.metadata.width}x{state.metadata.height}px</p>
                                          </li>
                                          <li>
                                            <span>{t('media_size')}:</span>
                                            <p>{formatFileSize(state.metadata.size)}</p>
                                          </li>
                                        </>
                                      ) : null
                                    }
                                    
                                  </>
                                ) : null
                              }
                              {
                                mediaData.type === "video" ? (
                                  <>
                                    <li>
                                      <span>{t('media_type')}:</span>
                                      <p className={classes.mediaTypeBadge} data-cy={isCypress() ? "timelineGalleryModalMediaTypeButton" : null}>
                                        <SVG src="video"/> {t("video")}
                                      </p>
                                    </li>
                                    {
                                      Object.keys(state.metadata).length > 0 ? (
                                        <>
                                          <li>
                                            <span>{t('media_duration')}:</span>
                                            <p>{DateFormat(state.metadata.duration * 1000, "minutesseconds", languageData, t)}</p>
                                          </li>
                                          <li>
                                            <span>{t('media_size')}:</span>
                                            <p>{formatFileSize(state.metadata.size)}</p>
                                          </li>
                                        </>
                                      ) : null
                                    }
                                    
                                  </>
                                ) : null
                              }
                              {
                                mediaData.type === "attachment" ? (
                                  <>
                                    <li>
                                      <span>{t('media_type')}:</span>
                                      <p className={classes.mediaTypeBadge} data-cy={isCypress() ? "timelineGalleryModalMediaTypeButton" : null}>
                                        <SVG src="file"/> {t("file")}
                                      </p>
                                    </li>
                                    {
                                      Object.keys(state.metadata).length > 0 ? (
                                        <>
                                          <li>
                                            <span>{t('media_size')}:</span>
                                            <p>{formatFileSize(state.metadata.size)}</p>
                                          </li>
                                        </>
                                      ) : null
                                    }
                                  </>
                                ) : null
                              }
                            </ul>
                          </>
                        ) : (
                          <>     
                            <ul className={classes.mediaInfo}>
                              <li>
                                <Skeleton variant="text" animation="wave" width={150} height={20}/>
                              </li>
                              <li>
                                <Skeleton variant="text" animation="wave" width={150} height={20}/>
                              </li>
                              <li>
                                <Skeleton variant="text" animation="wave" width={150} height={20}/>
                              </li>
                              <li>
                                <Skeleton variant="text" animation="wave" width={150} height={20}/>
                              </li>
                            </ul>
                          </>
                        )
                      }
                      </div>
                      <div className={classes.infobox}>
                        <span className={classes.title}>
                          <SVG src="activity-type"/>
                          {t('activity_type')}:
                        </span>          
                        {
                          activityType ? (
                            <div className={classes.activityType} onClick={handleActivityTypeClick} data-cy={isCypress() ? "timelineGalleryModalActivityTypeButton" : null}>
                              <img src={activityType.image} alt={activityType.name}/>
                              {activityType.name}
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        tabsItems.length > 0 ? (
                          <TabsMenu className={classes.tabs} items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
                        ) : null
                      }
                      {
                        state.viewMode === 'children' ? (
                          <div className={classes.childrenbox}> 
                            {
                              children.length > 0 ? (
                                <div className={classes.childrenListWrapper}>
                                  {
                                    children.map((child: any, key: any) => (
                                      <div className={classes.child} key={`k_${key}`} onClick={() => handleChildrenClick(child)} data-cy={isCypress() ? "timelineGalleryModalChildButton" + key : null}>
                                        <AuthenticatedImage className={classes.childPhoto} thumbLink={child.photo.thumbLink}/>
                                        <Clamp withTooltip lines={2}>
                                          <span className={classes.childDisplayName}>{child.displayName}</span>
                                        </Clamp> 
                                      </div>
                                    ))
                                  }
                                </div>
                              ) : null
                            }
                          </div>
                        ) : null
                      }
                      {
                        state.viewMode === 'media' ? (
                          <div className={classes.medias}>
                            <TimelineGallery uniqueID={uniqueID} currentMediaID={mediaID} display="grid"/>
                          </div>
                        ) : null
                      }
                      <div className={classes.bottom}>   
                        {
                          (mediaData && ((mediaData.reactions && mediaData.reactions.enable && mediaData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (mediaData.reactions && mediaData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0))) ? (
                            <ReactionsList data={mediaData} ID={mediaID} type='media'/>
                          ) : null
                        } 
                        <div className={classes.buttons}>
                          {
                            (mediaData && mediaData.reactions && mediaData.reactions.enable && schoolReactions) ? (
                              <ReactionsPopper className={classes.reactionsWrapper} data={mediaData} ID={mediaID} type='media'/>
                            ) : null
                          }
                          {
                            state.isDownloadable ? (
                              <NormalButton buttonType='chip' startIcon={<SVG src="download"/>} onClick={handleDownload} dataCy="timelineGalleryModalDownloadMediaButton">
                                {t("download")}
                              </NormalButton>
                            ) : null
                          }
                        </div>   
                      </div>
                    </>  
                  ) : (
                    <>
                      <div className={classes.authorInformation}> 
                        <div className={classes.authorImageContainer}>
                          <Skeleton className={classes.authorImage} variant="circular" animation="wave"/>
                        </div>
                        <div className={classes.authorInfo}>
                          <span className={classes.authorName}>
                            <Skeleton variant="text" animation="wave" width={150} height={25}/>
                          </span>
                          <span className={classes.date}>
                            <Skeleton variant="text" animation="wave" width={100} height={15}/>
                          </span>
                        </div>
                      </div>
                      <div className={classes.infobox}>
                        <span className={classes.title}>
                          <Skeleton variant="circular" animation="wave" width={17} height={17}/>
                          <Skeleton variant="text" animation="wave" width={150} height={25}/>
                        </span>          
                        <ul className={classes.mediaInfo}>
                          <li>
                            <Skeleton variant="text" animation="wave" width={150} height={20}/>
                          </li>
                          <li>
                            <Skeleton variant="text" animation="wave" width={150} height={20}/>
                          </li>
                          <li>
                            <Skeleton variant="text" animation="wave" width={150} height={20}/>
                          </li>
                          <li>
                            <Skeleton variant="text" animation="wave" width={150} height={20}/>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.infobox}>
                        <span className={classes.title}>
                          <Skeleton variant="circular" animation="wave" width={17} height={17}/>
                          <Skeleton variant="text" animation="wave" width={150} height={25}/>
                        </span>          
                        <Skeleton variant="rectangular" animation="wave" width={90} height={32}/>
                      </div>
                      <div className={classes.childrenbox}>
                        <span className={classes.title}>
                          <Skeleton variant="circular" animation="wave" width={17} height={17}/>
                          <Skeleton variant="text" animation="wave" width={150} height={25}/>
                        </span>   
                      </div>
                      <div className={classes.bottom}>
                        <div className={classes.buttons}>
                          <Skeleton variant="rectangular" animation="wave" height={42}/>
                          <Skeleton variant="rectangular" animation="wave" height={42}/>
                        </div>   
                      </div>
                    </>
                  )
                }
              </div>
            ) : null
          }
        </Hotkeys>
      </div>
    </Modal>
  );
};

export default TimelineGalleryModal;