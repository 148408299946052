import HttpService from '../http.service';

const listRooms = (schoolID: any) => {
  return HttpService.get(`timetable/room?schoolID=${schoolID}`, {}, {});
};

const createRoom = (payload: any) => {
  return HttpService.post(`timetable/room`, payload, {}, {});
};

const editRoom = (roomID: any, payload: any) => {
  return HttpService.put(`timetable/room/${roomID}`, payload, {}, {});
};

const deleteRoom = (roomID: any) => {
  return HttpService.remove(`timetable/room/${roomID}`, {});
};

const listSubjects = (schoolID: any) => {
  return HttpService.get(`timetable/subject?schoolID=${schoolID}`, {}, {});
};

const createSubject = (payload: any) => {
  return HttpService.post(`timetable/subject`, payload, {}, {});
};

const editSubject = (subjectID: any, payload: any) => {
  return HttpService.put(`timetable/subject/${subjectID}`, payload, {}, {});
};

const deleteSubject = (subjectID: any) => {
  return HttpService.remove(`timetable/subject/${subjectID}`, {});
};

const listHours = (params?: any) => {
  let query = "";
  if(params) {
    if(Object.keys(params).length > 0) {
      query = query + "?";
      for (const [key, value] of Object.entries(params)) {
        query = query + key + "=" + value + "&";
      }
      query = query.substring(0, query.length - 1);
    }
  }
  return HttpService.get(`timetable${query ? `${query}`: ""}`, {}, {});
};

const createHour = (payload: any) => {
  return HttpService.post(`timetable`, payload, {}, {});
};

const editHour = (timetableID: any, payload: any) => {
  return HttpService.put(`timetable/${timetableID}`, payload, {}, {});
};

const deleteHour = (timetableID: any) => {
  return HttpService.remove(`timetable/${timetableID}`, {});
};

export {
  listRooms,
  createRoom,
  editRoom,
  deleteRoom,
  listSubjects,
  createSubject,
  editSubject,
  deleteSubject,
  listHours,
  createHour,
  editHour,
  deleteHour,
};