import Button from '@mui/material/Button';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useAppSelector } from 'src/hooks/redux-hooks';
import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';

interface Props {
  buttonType?: any;
  disabled: any;
  size: any;
  isWhite: any;
}

const useStyles = createUseStyles((theme: any) => ({
  schoolButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
    gap: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.25s',
    backgroundColor: (props: Props) => {
      if(props.isWhite) return theme.colors.white;
      else return theme.colors.grey[200];
    },
    width: (props: Props) => props.size + 'px',
    height: (props: Props) => props.size + 'px',
    minWidth: (props: Props) => props.size + 'px',
    minHeight: (props: Props) => props.size + 'px',
    maxWidth: (props: Props) => props.size + 'px',
    maxHeight: (props: Props) => props.size + 'px',
    borderRadius: (props: Props) => (props.size / 18) + 'px',
    color: (props: Props) => {
      if(props.buttonType) return theme.colors.black;
      else return 'none';
    },
    textTransform: 'unset',
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
    height: '80%',
    '& > img': {
      maxWidth: '80%',
      maxHeight: '80%',
      width: 'unset',
      height: 'unset',
    },
  },
}));

type SchoolButtonType = {
  className?: any;
  schoolID: any;
  onClick?: any;
  dataCy?: string;
  disabled?: boolean;
  size?: any;
  isWhite?: any;
};

const SchoolButton: React.FunctionComponent<SchoolButtonType> = ({className, schoolID, onClick, dataCy, disabled, size = 256, isWhite}) => {
  
  const dataData = useAppSelector((state: any) => state.data);

  let buttonType = "default";
  if(buttonType === "disabled") disabled = true;
  if(disabled) buttonType = "disabled";

  const classes = useStyles({
    buttonType: buttonType,
    disabled: disabled,
    size: size,
    isWhite: isWhite,
  });

  const handleOnClick = () => {
    onClick(schoolID);
  };

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  return (
    <Button type="button" className={`${classes.schoolButton} ${className}`} onClick={handleOnClick} disabled={disabled} tabIndex={-1} data-cy={isCypress() ? dataCy : null}>
      {
        getSchoolData(schoolID).logo ? (
          <AuthenticatedImage className={classes.image} isResponsive={false} thumbLink={getSchoolData(schoolID).logo.thumbLink} fullsizeLink={getSchoolData(schoolID).logo.fullsizeLink}/>
        ) : null
      }
      <span>{getSchoolData(schoolID).name}</span>
    </Button>
  );
};

export default SchoolButton;