const StorageService = {
  setItem: (key: any, data: any) => {
    if(typeof data === 'object') {
      localStorage.setItem(key, JSON.stringify(data));
    } else {
      localStorage.setItem(key, data);
    }
  },
  setTempItem: (key: any, data: any) => {
    if(typeof data === 'object') {
      sessionStorage.setItem(key, JSON.stringify(data));
    } else {
      sessionStorage.setItem(key, data);
    }
  },
  getItem: (key: any) => {
    let item: any = localStorage.getItem(key) ? localStorage.getItem(key) : null;
    if(item !== null) {
      try {
        item = JSON.parse(item) ? JSON.parse(item) : {};
      } catch (e: any) {
        return item ? item : "";
      }
    }
    return item;
  },
  getTempItem: (key: any) => {
    let item: any = sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
    if(item !== null) {
      try {
        item = JSON.parse(item) ? JSON.parse(item) : {};
      } catch (e: any) {
        return item ? item : "";
      }
    }
    return item;
  },
  existItem: (key: any) => {
    return localStorage.getItem(key) === null ? false : true;
  },
  existTempItem: (key: any) => {
    return sessionStorage.getItem(key) === null ? false : true;
  },
  removeItem: (key: any) => {
    localStorage.removeItem(key);
  },
  removeTempItem: (key: any) => {
    sessionStorage.removeItem(key);
  },
  getCookie(name: any) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) ===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },
  setCookie(name: any, value: any, days: any) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  deleteCookie(name: any) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
  deleteAllCookies: () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  },
};

export default StorageService;