const setup = [
  {
    key: 'language',
    state: null,
    enabled: true,
    roles: [
      "parent",
      "teacher",
      "director",
    ],
  },
  /*
  {
    key: 'theme',
    state: null,
        enabled: true,
    roles: [
      "parent",
      "teacher",
      "director",
    ],
  },
  */
  {
    key: 'app',
    state: null,
    enabled: true,
    roles: [
      "parent",
      "teacher",
      "director",
    ],
  },
  {
    key: 'privacy',
    state: null,
    enabled: true,
    roles: [
      "parent",
    ],
  },
  {
    key: 'classbook',
    state: null,
    enabled: true,
    roles: [
      "teacher",
      "director",
    ],
  },
];

export default setup;