import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import htmlParse from 'html-react-parser';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { removeDuplicatesJSON } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly?: any;
  disabled?: any;
  maxRows?: any;
  isDeletable?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  classesInputWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  classesTopWrapper: {
    display: 'flex',
    gap: '10px',
    marginTop: '16px',
    marginBottom: '7px',
    alignItems: 'center',
    '&:empty': {
      display: 'none',
    },
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  classesInput: {
    position: "relative",
    width: "calc(100% - 22px)",
    maxHeight: (props: Props) => `${(props.maxRows * 42) - 16}px`,
    height: 'fit-content',
    minHeight: "24px",
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: "10px",
    backgroundColor: (props: Props) => {
      if(props.disabled) return theme.colors.grey[75];
      else return theme.colors.white;
    },
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[560];
      else return '';
    },
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "8px 10px",
    cursor: (props: Props) => {
      if(props.readonly || props.disabled) return "auto";
      else return "pointer";
    },
    overflow: 'auto',
    gap: '10px',
    '& > span': {
      fontSize: '14px',
    }
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    padding: "5px",
    height: "25px",
    backgroundColor: "#E1F1FF !important",
    color: theme.colors.primaryBlue[500] + ' !important',
    fontWeight: "bold",
    position: 'relative',
    cursor: 'pointer',
    gap: '5px',
    fontSize: '14px',
    '& > span': {
      maxWidth: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '2px',
      paddingRight: (props: Props) => {
        if(props.isDeletable) return '5px';
        else return '2px';
      },
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: '-8px',
    right: '-12px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    transition: 'background-color 0.25s',
    width: '22px',
    height: '22px',
    minWidth: '22px',
    minHeight: '22px',
    maxWidth: '22px',
    maxHeight: '22px',
    padding: '0',
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& > svg': {
      width: '10px',
      height: '10px',
    },
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[560],
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      color: 'inherit',
    },
  },
  classesCount: {
    backgroundColor: theme.colors.grey[250],
    borderRadius: "20px",
    padding: "2px 5px",
    display: "flex",
    alignItems: "center",
    height: 'fit-content',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
      width: '14px',
      height: '14px',
    },
    '& > span': {
      marginBottom: "0",
      marginLeft: "5px",
      color: theme.colors.primaryBlue[500],
      fontWeight: "bold",
      fontSize: '14px',
    }
  },
}));

type InputType = {
  label?: any;
  selectLabel?: any;
  selectedClasses: any;
  setSelectedClasses: any;
  isShowClassesCount?: any;
  isDeletable?: boolean;
  dataCy?: any;
  onClick?: any;
  disabled?: boolean;
  readonly?: boolean;
  maxRows?: number;
  className?: any;
  classNameWrapper?: any;
  classNameChip?: any;
};

const ClassesInput: React.FunctionComponent<InputType> = ({label, selectLabel, selectedClasses, setSelectedClasses, isShowClassesCount, isDeletable = true, onClick, disabled = false, readonly = false, maxRows = 1, className, classNameWrapper, classNameChip}) => {
  
  const classes = useStyles({
    readonly: readonly,
    disabled: disabled,
    maxRows: maxRows,
    isDeletable: isDeletable,
  });
  
  const dataData = useAppSelector((state: any) => state.data);
  const { t } = useTranslation();

  const handleDelete = (classID: any) => {
    const newChildren = selectedClasses.filter((item: any) => item.classID !== classID);
    setSelectedClasses(newChildren);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? {} : dataData.classes.find((item: any) => item.classID === classID);
  };

  return (
    <div className={`${classes.classesInputWrapper} ${classNameWrapper ? classNameWrapper : null}`}>
      <div className={classes.classesTopWrapper}>
        {
          label ? (
            <label>
              {label}
            </label>
          ) : null
        }
        {
          (isShowClassesCount !== null && isShowClassesCount !== undefined) ? (
            <span className={classes.classesCount}>
              <SVG src="people"/>
              <span data-clarity-unmask="true">{isShowClassesCount === "unique" ? removeDuplicatesJSON(selectedClasses.map((item: any) => { return { classID: item.classID }; })).length : selectedClasses.length}</span>
            </span>
          ) : null
        }
      </div>
      <div className={`${classes.classesInput} ${className ? className : null}`} onClick={(disabled || readonly) ? null : onClick} data-cy={isCypress() ? "classesInput" : null}>
        {
          removeDuplicatesJSON(selectedClasses.map((item: any) => { return { classID: item.classID }; })).length > 0 ? removeDuplicatesJSON(selectedClasses.map((item: any) => { return { classID: item.classID }; })).map((theClass: any, key: any) => Object.keys(getClassData(theClass.classID)).length > 0 ? (
            <Chip
              className={`${classes.chip} ${classNameChip ? classNameChip : null}`}
              size="small"
              key={`k_${key}`}
              label={htmlParse(getClassData(theClass.classID).name)}
              onDelete={() => handleDelete(theClass.classID)}
              color="primary"
              disabled={disabled}
              deleteIcon={(!disabled && !readonly && isDeletable) ? (
                <Button className={classes.deleteIcon}>
                  <SVG src="close"/>
                </Button>
              ) : (
                <></>
              )}
            />
          ) : null) : (
            <span className={classes.placeholder}>
              <SVG src="class"/>
              {selectLabel ? selectLabel : t('select_classes')}
            </span>
          )
        }
      </div>
    </div>
  );
};

export default ClassesInput;