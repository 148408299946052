import IconButton from 'src/components/Buttons/IconButton';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback } from 'react';
import StockCategoriesTable from 'src/components/Tables/StockCategoriesTable';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setStockCategoryHandleModal } from 'src/store/actions/modals.actions';
import { setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  categoriesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '16px',
    boxShadow: theme.shadows[2],
    maxHeight: '85vh',
    width: 'fit-content',
    minWidth: 'calc(50% - 32px)',
    maxWidth: 'calc(90% - 32px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
    },
  },
  backButton: {
    backgroundColor: theme.colors.grey[200],
    transition: 'background-color 0.25s',
    boxShadow: theme.shadows[2],
    padding: '10px',
    width: '36px',
    height: '36px',
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxHeight: '100%',
  },
}));

const Categories: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const handleAdd = () => {
    const settings = {
      isOpen: true,
      categoryID: null,
      schoolID: schoolID,
    };
    dispatch(setStockCategoryHandleModal(settings));
  };

  const handleBack = () => {
    dispatch(setStockViewMode("stockSettings"));
  };

  return (
    <div className={classes.categoriesWrapper}>
      <span>{getSchoolData(schoolID).name}</span>
      <div className={classes.box}>
        <IconButton className={classes.backButton} onClick={handleBack} data-cy={isCypress() ? "backButton" : null}>
          <SVG src="arrow-left"/>
        </IconButton> 
        <div className={classes.form}>
          <h3>{t('stock_categories')}</h3>
          <StockCategoriesTable schoolID={schoolID}/>
          <NormalButton onClick={handleAdd}>
            {t('stock_category_add')}
          </NormalButton>
        </div>
      </div>
    </div> 
  );
};

export default Categories;