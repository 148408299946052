import Basic from './Basic';
import Break from './Break';
import Modal from '../../../utils/modal';
import React from 'react';
import Teacher from './Teacher';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setTimetableHourHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
}));

const TimetableHourHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const hourType = modalsData.timetableHourHandleModal.type;
  const mode = modalsData.timetableHourHandleModal.mode;

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      timetableID: null,
      weekday: null,
      hour: null,
      type: null,
      mode: null,
      timetableData: null,
      isFirstBreakZero: null,
    };
    dispatch(setTimetableHourHandleModal(settings));
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "timetableHourHandleModal" : null}>
        {
          hourType === 1 ? (
            <Basic mode={mode} onClose={handleClose}/>
          ) : hourType === 2 ? (
            <Teacher onClose={handleClose}/>
          ) : hourType === 3 ? (
            <Break mode={mode} onClose={handleClose}/>
          ) : null
        }
      </div>
    </Modal>
  );
};

export default TimetableHourHandleModal;