import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  attachmentModal: any;
  calendarEventHandleModal: any;
  calendarFiltersModal: any;
  calendarGalleryModal: any;
  calendarSettingsModal: any;
  cameraModal: any;
  classModal: any;
  classbookDetailModal: any;
  classbookHandleModal: any;
  clubDetailModal: any;
  communicationChangeThreadNameModal: any;
  communicationGalleryModal: any;
  communicationMessageViewedModal: any;
  confirmModal: any;
  cookiesModal: any;
  dashboardHandleWidgetModal: any;
  dashboardSettingsModal: any;
  detailModal: any;
  diagnosticsModal: any;
  draftsModal: any;
  employeeModal: any;
  excuseNotesDetailModal: any;
  excuseNotesHandleModal: any;
  fileInputModal: any;
  galleryModal: any;
  changelogModal: any;
  childCardModal: any;
  childDetailModal: any;
  childrenModal: any;
  imageModal: any;
  infoModal: any;
  itemPickerModal: any;
  languageModal: any;
  photoChangeModal: any;
  postCreateModal: any;
  postDetailModal: any;
  pressReportTemplateHandleModal: any;
  reactionDetailModal: any;
  shoppingCartModal: any;
  shopItemDetailModal: any;
  shopItemVariantModal: any;
  shopOrderDetailModal: any;
  shopOrderStatusModal: any;
  shopGalleryModal: any;
  schoolUrlsModal: any;
  ssoGetModal: any;
  ssoLoginQrCodeModal: any;
  stockCategoryDetailModal: any;
  stockCategoryHandleModal: any;
  stockItemDetailModal: any;
  stockItemHandleModal: any;
  stockTagDetailModal: any;
  stockTagHandleModal: any;
  substitutionsDetailModal: any;
  substitutionsHandleModal: any;
  timelineCommentDetailModal: any;
  timelineGalleryModal: any;
  timelinePollDetailModal: any;
  timetableHourDetailModal: any;
  timetableHourHandleModal: any;
  timetableSubjectDetailModal: any;
  timetableSubjectHandleModal: any;
  timetableRoomDetailModal: any;
  timetableRoomHandleModal: any;
  warningModal: any;
  wageDetailModal: any;
};

const initialState: ArrayModel = {
  attachmentModal: {
    isOpen: false,
    title: null,
    content: null,
    download: null,
    type: null,
    isPrintable: null,
  },
  calendarEventHandleModal: {
    isOpen: false,
    date: null,
    dateTime: null,
    eventID: null,
  },
  calendarFiltersModal: {
    isOpen: false,
  },
  calendarGalleryModal: {
    isOpen: false,
    mediaID: null,
    mediaList: [],
  },
  calendarSettingsModal: {
    isOpen: false,
  },
  cameraModal: {
    isOpen: false,
    mode: null,
    mediaLimit: null,
    onSave: null,
  },
  classModal: {
    isOpen: false,
    classID: null,
  },
  classbookDetailModal: {
    isOpen: false,
    date: null,
    classID: null,
  },
  classbookHandleModal: {
    isOpen: false,
    date: null,
    classID: null,
    isImport: false,
    importData: null,
  },
  clubDetailModal: {
    isOpen: false,
    clubID: null,
  },
  communicationChangeThreadNameModal: {
    isOpen: false,
    threadID: null,
    threadName: null,
    onChange: null,
  },
  communicationGalleryModal: {
    isOpen: false,
    mediaID: null,
    mediaList: [],
  },
  communicationMessageViewedModal: {
    isOpen: false,
    users: null,
  },
  confirmModal: {
    isOpen: false,
    title: null,
    content: null,
    onAccept: null,
    onDecline: null,
  },
  cookiesModal: {
    isOpen: false,
  },
  dashboardHandleWidgetModal: {
    isOpen: false,
    widgetKey: null,
  },
  dashboardSettingsModal: {
    isOpen: false,
  },
  detailModal: {
    isOpen: false,
    title: null,
    content: null,
    zIndex: null,
  },
  diagnosticsModal: {
    isOpen: false,
    childID: null,
  },
  draftsModal: {
    isOpen: false,
    currentDraftID: null,
    type: null,
    classbookType: null,
    isSchoolCare: null,
    onDraftLoad: null,
  },
  employeeModal: {
    isOpen: false,
    employeeID: null,
  },
  excuseNotesDetailModal: {
    isOpen: false,
    absenceID: null,
    childID: null,
    type: null,
    readOnly: false,
  },
  excuseNotesHandleModal: {
    isOpen: false,
    date: null,
    absenceID: null,
    absenceGroupID: null,
    child: null,
  },
  fileInputModal: {
    isOpen: false,
    data: null,
    mediaLimit: null,
    onChange: null,
  },
  galleryModal: {
    isOpen: false,
    date: null,
    mediaID: null,
  },
  changelogModal: {
    isOpen: false,
    isAutoOpen: false,
  },
  childCardModal: {
    isOpen: false,
    childID: null,
  },
  childDetailModal: {
    isOpen: false,
    childID: null,
  },
  childrenModal: {
    isOpen: false,
    modalTitle: null,
    modalOnClose: null,
    defaultClasses: null,
    defaultClassesData: null,
    defaultChildren: null,
    defaultChildrenData: null,
    mode: null,
    isAllowArchived: null,
    isShowChildrenViews: null,
    isShowChildrenArchived: null,
  },
  imageModal: {
    isOpen: false,
    thumbLink: null,
    fullsizeLink: null,
  },
  infoModal: {
    isOpen: false,
    title: null,
    type: null,
    postType: null,
  },
  itemPickerModal: {
    isOpen: false,
    items: [],
    value: null,
    valueCompare: "value",
    onChange: null,
    closeOnChange: true,
    title: "",
  },
  languageModal: {
    isOpen: false,
  },
  photoChangeModal: {
    isOpen: false,
    photo: null,
    onSave: null,
    title: null,
    aspect: null,
  },
  postCreateModal: {
    isOpen: false,
    uniqueID: null,
    postID: null,
    title: null,
    postType: null,
    repost: false,
  },
  postDetailModal: {
    isOpen: false,
    postID: null,
  },
  pressReportTemplateHandleModal: {
    isOpen: false,
    templateID: null,
    allColumns: [],
    columns: [],
    schoolID: null,
    title: null,
    public: null,
    name: null,
  },
  reactionDetailModal: {
    isOpen: false,
    ID: null,
    type: null,
  },
  shoppingCartModal: {
    isOpen: false,
  },
  shopItemDetailModal: {
    isOpen: false,
    itemID: null,
    variantID: null,
    showCart: false,
    schoolID: null,
  },
  shopItemVariantModal: {
    isOpen: false,
    itemID: null,
    schoolID: null,
  },
  shopOrderDetailModal: {
    isOpen: false,
    isNewCreated: false,
    orderID: null,
    schoolID: null,
  },
  shopOrderStatusModal: {
    isOpen: false,
    onClose: null,
    orderID: null,
  },
  shopGalleryModal: {
    isOpen: false,
    mediaID: null,
    mediaList: [],
  },
  schoolUrlsModal: {
    isOpen: false,
    urls: [],
    title: "",
  },
  ssoGetModal: {
    isOpen: false,
    type: '',
  },
  ssoLoginQrCodeModal: {
    isOpen: false,
  },
  stockCategoryDetailModal: {
    isOpen: false,
    categoryID: null,
    schoolID: null,
  },
  stockCategoryHandleModal: {
    isOpen: false,
    categoryID: null,
    schoolID: null,
  },
  stockItemDetailModal: {
    isOpen: false,
    itemID: null,
    schoolID: null,
  },
  stockItemHandleModal: {
    isOpen: false,
    itemID: null,
    schoolID: null,
  },
  stockTagDetailModal: {
    isOpen: false,
    tagID: null,
    schoolID: null,
  },
  stockTagHandleModal: {
    isOpen: false,
    tagID: null,
    schoolID: null,
  },
  substitutionsDetailModal: {
    isOpen: false,
    substitutionID: null,
    childID: null,
    readOnly: false,
  },
  substitutionsHandleModal: {
    isOpen: false,
    date: null,
    substitutionID: null,
    child: null,
  },
  timelineCommentDetailModal: {
    isOpen: false,
    uniqueID: null,
    addComment: false,
  }, 
  timelineGalleryModal: {
    isOpen: false,
    mediaID: null,
    mediaList: [],
    uniqueID: null,
  },
  timelinePollDetailModal: {
    isOpen: false,
    uniqueID: null,
  }, 
  timetableHourDetailModal: {
    isOpen: false,
    timetableID: null,
    weekday: null,
    hour: null,
    type: null,
    isEditable: null,
    isFirstBreakZero: null,
  },
  timetableHourHandleModal: {
    isOpen: false,
    timetableID: null,
    weekday: null,
    mode: null,
    hour: null,
    type: null,
    isFirstBreakZero: null,
  },
  timetableSubjectDetailModal: {
    isOpen: false,
    subjectID: null,
    schoolID: null,
  },
  timetableSubjectHandleModal: {
    isOpen: false,
    subjectID: null,
    schoolID: null,
  },
  timetableRoomDetailModal: {
    isOpen: false,
    roomID: null,
    schoolID: null,
  },
  timetableRoomHandleModal: {
    isOpen: false,
    roomID: null,
    schoolID: null,
  },
  warningModal: {
    isOpen: false,
    title: null,
    content: null,
  },
  wageDetailModal: {
    isOpen: false,
    employeeID: null,
    schoolID: null,
  },
};

const slice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    setAttachmentModal(state, action: PayloadAction<Model>) {
      state.attachmentModal = Object.assign(state.attachmentModal, action.payload);
    },
    setCalendarEventHandleModal(state, action: PayloadAction<Model>) {
      state.calendarEventHandleModal = Object.assign(state.calendarEventHandleModal, action.payload);
    },
    setCalendarFiltersModal(state, action: PayloadAction<Model>) {
      state.calendarFiltersModal = Object.assign(state.calendarFiltersModal, action.payload);
    },
    setCalendarGalleryModal(state, action: PayloadAction<Model>) {
      state.calendarGalleryModal = Object.assign(state.calendarGalleryModal, action.payload);
    },
    setCalendarSettingsModal(state, action: PayloadAction<Model>) {
      state.calendarSettingsModal = Object.assign(state.calendarSettingsModal, action.payload);
    },
    setCameraModal(state, action: PayloadAction<Model>) {
      state.cameraModal = Object.assign(state.cameraModal, action.payload);
    },
    setClassModal(state, action: PayloadAction<Model>) {
      state.classModal = Object.assign(state.classModal, action.payload);
    },
    setClassbookDetailModal(state, action: PayloadAction<Model>) {
      state.classbookDetailModal = Object.assign(state.classbookDetailModal, action.payload);
    },
    setClassbookHandleModal(state, action: PayloadAction<Model>) {
      state.classbookHandleModal = Object.assign(state.classbookHandleModal, action.payload);
    },
    setClubDetailModal(state, action: PayloadAction<Model>) {
      state.clubDetailModal = Object.assign(state.clubDetailModal, action.payload);
    },
    setCommunicationChangeThreadNameModal(state, action: PayloadAction<Model>) {
      state.communicationChangeThreadNameModal = Object.assign(state.communicationChangeThreadNameModal, action.payload);
    },
    setCommunicationGalleryModal(state, action: PayloadAction<Model>) {
      state.communicationGalleryModal = Object.assign(state.communicationGalleryModal, action.payload);
    },
    setCommunicationMessageViewedModal(state, action: PayloadAction<Model>) {
      state.communicationMessageViewedModal = Object.assign(state.communicationMessageViewedModal, action.payload);
    },
    setConfirmModal(state, action: PayloadAction<Model>) {
      state.confirmModal = Object.assign(state.confirmModal, action.payload);
    },
    setCookiesModal(state, action: PayloadAction<Model>) {
      state.cookiesModal = Object.assign(state.cookiesModal, action.payload);
    },
    setDashboardHandleWidgetModal(state, action: PayloadAction<Model>) {
      state.dashboardHandleWidgetModal = Object.assign(state.dashboardHandleWidgetModal, action.payload);
    },
    setDashboardSettingsModal(state, action: PayloadAction<Model>) {
      state.dashboardSettingsModal = Object.assign(state.dashboardSettingsModal, action.payload);
    },
    setDetailModal(state, action: PayloadAction<Model>) {
      state.detailModal = Object.assign(state.detailModal, action.payload);
    },
    setDiagnosticsModal(state, action: PayloadAction<Model>) {
      state.diagnosticsModal = Object.assign(state.diagnosticsModal, action.payload);
    },
    setDraftsModal(state, action: PayloadAction<Model>) {
      state.draftsModal = Object.assign(state.draftsModal, action.payload);
    },
    setEmployeeModal(state, action: PayloadAction<Model>) {
      state.employeeModal = Object.assign(state.employeeModal, action.payload);
    },
    setExcuseNotesDetailModal(state, action: PayloadAction<Model>) {
      state.excuseNotesDetailModal = Object.assign(state.excuseNotesDetailModal, action.payload);
    },
    setExcuseNotesHandleModal(state, action: PayloadAction<Model>) {
      state.excuseNotesHandleModal = Object.assign(state.excuseNotesHandleModal, action.payload);
    },
    setFileInputModal(state, action: PayloadAction<Model>) {
      state.fileInputModal = Object.assign(state.fileInputModal, action.payload);
    },
    setGalleryModal(state, action: PayloadAction<Model>) {
      state.galleryModal = Object.assign(state.galleryModal, action.payload);
    },
    setChangelogModal(state, action: PayloadAction<Model>) {
      state.changelogModal = Object.assign(state.changelogModal, action.payload);
    },
    setChildCardModal(state, action: PayloadAction<Model>) {
      state.childCardModal = Object.assign(state.childCardModal, action.payload);
    },
    setChildDetailModal(state, action: PayloadAction<Model>) {
      state.childDetailModal = Object.assign(state.childDetailModal, action.payload);
    },
    setChildrenModal(state, action: PayloadAction<Model>) {
      state.childrenModal = Object.assign(state.childrenModal, action.payload);
    },
    setImageModal(state, action: PayloadAction<Model>) {
      state.imageModal = Object.assign(state.imageModal, action.payload);
    },
    setInfoModal(state, action: PayloadAction<Model>) {
      state.infoModal = Object.assign(state.infoModal, action.payload);
    },
    setItemPickerModal(state, action: PayloadAction<Model>) {
      state.itemPickerModal = Object.assign(state.itemPickerModal, action.payload);
    },
    setLanguageModal(state, action: PayloadAction<Model>) {
      state.languageModal = Object.assign(state.languageModal, action.payload);
    },
    setPhotoChangeModal(state, action: PayloadAction<Model>) {
      state.photoChangeModal = Object.assign(state.photoChangeModal, action.payload);
    },
    setPostCreateModal(state, action: PayloadAction<Model>) {
      state.postCreateModal = Object.assign(state.postCreateModal, action.payload);
    },
    setPostDetailModal(state, action: PayloadAction<Model>) {
      state.postDetailModal = Object.assign(state.postDetailModal, action.payload);
    },
    setPressReportTemplateHandleModal(state, action: PayloadAction<Model>) {
      state.pressReportTemplateHandleModal = Object.assign(state.pressReportTemplateHandleModal, action.payload);
    },
    setReactionDetailModal(state, action: PayloadAction<Model>) {
      state.reactionDetailModal = Object.assign(state.reactionDetailModal, action.payload);
    },
    setShoppingCartModal(state, action: PayloadAction<Model>) {
      state.shoppingCartModal = Object.assign(state.shoppingCartModal, action.payload);
    },
    setShopItemDetailModal(state, action: PayloadAction<Model>) {
      state.shopItemDetailModal = Object.assign(state.shopItemDetailModal, action.payload);
    },
    setShopItemVariantModal(state, action: PayloadAction<Model>) {
      state.shopItemVariantModal = Object.assign(state.shopItemVariantModal, action.payload);
    },
    setShopOrderDetailModal(state, action: PayloadAction<Model>) {
      state.shopOrderDetailModal = Object.assign(state.shopOrderDetailModal, action.payload);
    },
    setShopOrderStatusModal(state, action: PayloadAction<Model>) {
      state.shopOrderStatusModal = Object.assign(state.shopOrderStatusModal, action.payload);
    },
    setShopGalleryModal(state, action: PayloadAction<Model>) {
      state.shopGalleryModal = Object.assign(state.shopGalleryModal, action.payload);
    },
    setSchoolUrlsModal(state, action: PayloadAction<Model>) {
      state.schoolUrlsModal = Object.assign(state.schoolUrlsModal, action.payload);
    },
    setSSOGetModal(state, action: PayloadAction<Model>) {
      state.ssoGetModal = Object.assign(state.ssoGetModal, action.payload);
    },
    setSSOLoginQrCodeModal(state, action: PayloadAction<Model>) {
      state.ssoLoginQrCodeModal = Object.assign(state.ssoLoginQrCodeModal, action.payload);
    },
    setStockCategoryDetailModal(state, action: PayloadAction<Model>) {
      state.stockCategoryDetailModal = Object.assign(state.stockCategoryDetailModal, action.payload);
    },
    setStockCategoryHandleModal(state, action: PayloadAction<Model>) {
      state.stockCategoryHandleModal = Object.assign(state.stockCategoryHandleModal, action.payload);
    },
    setStockItemDetailModal(state, action: PayloadAction<Model>) {
      state.stockItemDetailModal = Object.assign(state.stockItemDetailModal, action.payload);
    },
    setStockItemHandleModal(state, action: PayloadAction<Model>) {
      state.stockItemHandleModal = Object.assign(state.stockItemHandleModal, action.payload);
    },
    setStockTagDetailModal(state, action: PayloadAction<Model>) {
      state.stockTagDetailModal = Object.assign(state.stockTagDetailModal, action.payload);
    },
    setStockTagHandleModal(state, action: PayloadAction<Model>) {
      state.stockTagHandleModal = Object.assign(state.stockTagHandleModal, action.payload);
    },
    setSubstitutionsDetailModal(state, action: PayloadAction<Model>) {
      state.substitutionsDetailModal = Object.assign(state.substitutionsDetailModal, action.payload);
    },
    setSubstitutionsHandleModal(state, action: PayloadAction<Model>) {
      state.substitutionsHandleModal = Object.assign(state.substitutionsHandleModal, action.payload);
    },
    setTimelineCommentDetailModal(state, action: PayloadAction<Model>) {
      state.timelineCommentDetailModal = Object.assign(state.timelineCommentDetailModal, action.payload);
    },
    setTimelineGalleryModal(state, action: PayloadAction<Model>) {
      state.timelineGalleryModal = Object.assign(state.timelineGalleryModal, action.payload);
    },
    setTimelinePollDetailModal(state, action: PayloadAction<Model>) {
      state.timelinePollDetailModal = Object.assign(state.timelinePollDetailModal, action.payload);
    },
    setTimetableHourDetailModal(state, action: PayloadAction<Model>) {
      state.timetableHourDetailModal = Object.assign(state.timetableHourDetailModal, action.payload);
    },
    setTimetableHourHandleModal(state, action: PayloadAction<Model>) {
      state.timetableHourHandleModal = Object.assign(state.timetableHourHandleModal, action.payload);
    },
    setTimetableSubjectDetailModal(state, action: PayloadAction<Model>) {
      state.timetableSubjectDetailModal = Object.assign(state.timetableSubjectDetailModal, action.payload);
    },
    setTimetableSubjectHandleModal(state, action: PayloadAction<Model>) {
      state.timetableSubjectHandleModal = Object.assign(state.timetableSubjectHandleModal, action.payload);
    },
    setTimetableRoomDetailModal(state, action: PayloadAction<Model>) {
      state.timetableRoomDetailModal = Object.assign(state.timetableRoomDetailModal, action.payload);
    },
    setTimetableRoomHandleModal(state, action: PayloadAction<Model>) {
      state.timetableRoomHandleModal = Object.assign(state.timetableRoomHandleModal, action.payload);
    },
    setWarningModal(state, action: PayloadAction<Model>) {
      state.warningModal = Object.assign(state.warningModal, action.payload);
    },
    setWageDetailModal(state, action: PayloadAction<Model>) {
      state.wageDetailModal = Object.assign(state.wageDetailModal, action.payload);
    },
    resetPostCreateModal(state) {
      state.postCreateModal = initialState.postCreateModal;
    },
    resetAllModals(state) {
      state.attachmentModal = initialState.attachmentModal;
      state.calendarEventHandleModal = initialState.calendarEventHandleModal;
      state.calendarFiltersModal = initialState.calendarFiltersModal;
      state.calendarGalleryModal = initialState.calendarGalleryModal;
      state.calendarSettingsModal = initialState.calendarSettingsModal;
      state.cameraModal = initialState.cameraModal;
      state.classModal = initialState.classModal;
      state.classbookDetailModal = initialState.classbookDetailModal;
      state.classbookHandleModal = initialState.classbookHandleModal;
      state.clubDetailModal = initialState.clubDetailModal;
      state.communicationGalleryModal = initialState.communicationGalleryModal;
      state.communicationChangeThreadNameModal = initialState.communicationChangeThreadNameModal;
      state.communicationMessageViewedModal = initialState.communicationMessageViewedModal;
      state.confirmModal = initialState.confirmModal;
      state.cookiesModal = initialState.cookiesModal;
      state.dashboardHandleWidgetModal = initialState.dashboardHandleWidgetModal;
      state.dashboardSettingsModal = initialState.dashboardSettingsModal;
      state.detailModal = initialState.detailModal;
      state.diagnosticsModal = initialState.diagnosticsModal;
      state.draftsModal = initialState.draftsModal;
      state.employeeModal = initialState.employeeModal;
      state.excuseNotesDetailModal = initialState.excuseNotesDetailModal;
      state.excuseNotesHandleModal = initialState.excuseNotesHandleModal;
      state.galleryModal = initialState.galleryModal;
      //state.changelogModal = initialState.changelogModal;
      state.childCardModal = initialState.childCardModal;
      state.childDetailModal = initialState.childDetailModal;
      state.childrenModal = initialState.childrenModal;
      state.imageModal = initialState.imageModal;
      state.infoModal = initialState.infoModal;
      state.itemPickerModal = initialState.itemPickerModal;
      state.languageModal = initialState.languageModal;
      state.photoChangeModal = initialState.photoChangeModal;
      //state.postCreateModal = initialState.postCreateModal;
      state.postDetailModal = initialState.postDetailModal;
      state.reactionDetailModal = initialState.reactionDetailModal;
      state.shoppingCartModal = initialState.shoppingCartModal;
      state.shopItemDetailModal = initialState.shopItemDetailModal;
      state.shopItemVariantModal = initialState.shopItemVariantModal;
      state.shopOrderDetailModal = initialState.shopOrderDetailModal;
      state.shopOrderStatusModal = initialState.shopOrderStatusModal;
      state.shopGalleryModal = initialState.shopGalleryModal;
      state.schoolUrlsModal = initialState.schoolUrlsModal;
      state.ssoGetModal = initialState.ssoGetModal;
      state.stockCategoryDetailModal = initialState.stockCategoryDetailModal;
      state.stockCategoryHandleModal = initialState.stockCategoryHandleModal;
      state.stockItemDetailModal = initialState.stockItemDetailModal;
      state.stockItemHandleModal = initialState.stockItemHandleModal;
      state.stockTagDetailModal = initialState.stockTagDetailModal;
      state.stockTagHandleModal = initialState.stockTagHandleModal;
      state.substitutionsDetailModal = initialState.substitutionsDetailModal;
      state.substitutionsHandleModal = initialState.substitutionsHandleModal;
      state.timelineGalleryModal = initialState.timelineGalleryModal;
      state.timelinePollDetailModal = initialState.timelinePollDetailModal;
      state.timetableHourDetailModal = initialState.timetableHourDetailModal;
      state.timetableHourHandleModal = initialState.timetableHourHandleModal;
      state.timetableSubjectDetailModal = initialState.timetableSubjectDetailModal;
      state.timetableSubjectHandleModal = initialState.timetableSubjectHandleModal;
      state.timetableRoomDetailModal = initialState.timetableRoomDetailModal;
      state.timetableRoomHandleModal = initialState.timetableRoomHandleModal;
      //state.warningModal = initialState.warningModal;
    },
  }
});

export default slice;