import BlockButton from 'src/components/Buttons/BlockButton';
import BootstrapIcon from 'src/components/Icons/BootstrapIcon';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { bootstrapIcons } from 'src/constants/icons';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setStockCategory, setStockItems, setStockItemsCount, setStockSchool, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import NormalButton from 'src/components/Buttons/NormalButton';

const useStyles = createUseStyles((theme: any) => ({
  categorySelectWrapper: {
    display: 'flex',
    width: 'fit-content',
    gap: '16px',
    flexWrap: 'wrap',
    padding: '0 24px',
    maxWidth: 'calc(100% - 48px)',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
  noresults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      maxWidth: '80%',
    },
    '& > button': {
      marginTop: '16px',
    },
  },
}));

const CategorySelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const schoolID = stockData.school === null ? null : stockData.school.schoolID;

  const list = dataData.schools.filter((item: any) => item.stock && item.stock.enable);

  const categories = stockData.categories;

  const [isLoading, setIsLoading] = useState(false);

  const getItems = useCallback((categoryID: any) => {
    setIsLoading(true);
    const settings = {
      schoolID: schoolID,
      categoryID: categoryID,
      limit: 15,
      page: 1, 
    };
    stockService && stockService.listItems(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockCategory(categoryID));
          dispatch(setStockItems([]));
          dispatch(setStockItemsCount(result.data.countItems));
          dispatch(setStockViewMode("items"));
        } else {
          createNotification(t("stock_items_not_loaded"), "error");
          setIsLoading(false);
        }
      } else {
        createNotification(t("stock_items_not_loaded"), "error");
        setIsLoading(false);
      }
    }).catch(() => {
      createNotification(t("stock_items_not_loaded"), "error");
      setIsLoading(false);
    });
  }, [t, stockService, schoolID, dispatch]);

  const handleBack = () => {
    dispatch(setStockViewMode("schoolSelect"));
    dispatch(setStockSchool(null));
  };

  return (
    <div className={classes.categorySelectWrapper}>
      {
        categories.length === 0 ? (
          <div className={classes.noresults}>
            <img src="/resources/images/noresults.png" alt='no_results'/>
            {(t('stock_no_categories_found') + '...')}
            <NormalButton buttonType="primary" onClick={handleBack} disabled={isLoading}>
              {t('back')}
            </NormalButton>
          </div>
        ) : null
      }
      {
        categories.map((category: any, key: any) => (
          <BlockButton icon={(category.icon && bootstrapIcons.indexOf(category.icon) !== -1) ? (<BootstrapIcon iconName={category.icon}/>) : null} onClick={() => getItems(category.categoryID)}  key={`k_${key}`} disabled={isLoading}>
            {category.name}
          </BlockButton>
        ))
      }
      {
        (list.length > 1 && categories.length !== 0) ? (
          <BlockButton icon={<SVG src="school"/>} onClick={handleBack} disabled={isLoading}>
            {t('change_school')}
          </BlockButton>
        ) : null
      }
    </div>
  );
};

export default CategorySelect;