import * as DirectorDataService from '../../../../services/director/data.service';
import * as ParentDataService from '../../../../services/parent/data.service';
import * as TeacherDataService from '../../../../services/teacher/data.service';
import { getUserRole } from 'src/utils/useUser';
import { setDataClasses, setDataClassesID } from 'src/store/actions/data.actions';

const loadClasses = async (dispatch: any, userData: any, classesID: any) => {

    if(getUserRole(userData.userObject.roleType) === "parent") {
      try {
        const result: any = await ParentDataService.listClasses(Array.isArray(classesID) ? classesID.join(",") : classesID);
        if(result && result.data && result.data.classes && result.data.classes.length !== 0) {
          const tempClasses = result.data.classes.map((item: any) => { return { ...item, schoolsID: [item.schoolID] }; });
          tempClasses.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.classID - b.classID; return a.sortOrder - b.sortOrder; });
          const tempClassesID = tempClasses.map((item: any) => { return item.classID; });
          dispatch(setDataClasses(tempClasses));
          dispatch(setDataClassesID(tempClassesID));
          return {classes: tempClasses, classesID: tempClassesID};
        } else {
          return false; 
        }
      } catch {
        return false;   
      };    
    } else if(getUserRole(userData.userObject.roleType) === "teacher") {
      try {
        const result: any = await TeacherDataService.listClasses(Array.isArray(classesID) ? classesID.join(",") : classesID);
        if(result && result.data && result.data.classes && result.data.classes.length !== 0) {
          const tempClasses = result.data.classes.map((item: any) => { return { ...item, schoolsID: [item.schoolID] }; });
          tempClasses.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.classID - b.classID; return a.sortOrder - b.sortOrder; });
          const tempClassesID = tempClasses.map((item: any) => { return item.classID; });
          dispatch(setDataClasses(tempClasses));
          dispatch(setDataClassesID(tempClassesID));
          return {classes: tempClasses, classesID: tempClassesID};
        } else {
          return false; 
        }
      } catch {
        return false;   
      };  
    } else if(getUserRole(userData.userObject.roleType) === "director") {
      try {
        const result: any = await DirectorDataService.listClasses();
        if(result && result.data && result.data.classes && result.data.classes.length !== 0) {
          const tempClasses = result.data.classes.map((item: any) => { return { ...item, schoolsID: [item.schoolID] }; });
          tempClasses.sort((a: any, b: any) => { if (a.sortOrder === b.sortOrder) return a.classID - b.classID; return a.sortOrder - b.sortOrder; });
          const tempClassesID = tempClasses.map((item: any) => { return item.classID; });
          dispatch(setDataClasses(tempClasses));
          dispatch(setDataClassesID(tempClassesID));
          return {classes: tempClasses, classesID: tempClassesID};
        } else {
          return false; 
        }
      } catch {
        return false;   
      };  
    }
};

export default loadClasses;